import React, {Component} from "react";
import {Button, Modal, Progress, Table} from "reactstrap";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import * as actionTypes from "../../../Store/actions/actionTypes/policyTypes"

class OrderDocumentsTab extends Component {
    state = {
        uploading: false,
        uploadPercent: 0,
        documentTypes: {
            'DOCUMENT_DOG_PHOTO_FRONT': {'owner': 'order'},
            'DOCUMENT_DOG_PHOTO_BACK': {'owner': 'order'},
            'DOCUMENT_DOG_PHOTO_RIGHT': {'owner': 'order'},
            'DOCUMENT_DOG_PHOTO_LEFT': {'owner': 'order'},
            'DOCUMENT_DOG_PHOTO_TOP': {'owner': 'order'}
        },
        documents: this.props.uploadedDocuments
    }
    selectedDocumentType = '';
    pageId = 0;
    owner = '';
    localImage = '';

    handleClick() {
        this.refs.fileUploader.click();
    }

    detectImage = (e) => {
        this.localImage = this.detectPdf(e.target.files[0].name) ? require("../../../assets/img/icons/pdf.png") : URL.createObjectURL(e.target.files[0]);
        e.target.files[0] &&
        this.fileToBase64(e.target.files[0]).then((result) => {
            this.pageId === 0 ? this.uploadFile(result) : this.replaceFile(result);
            this.refs.fileUploader.value = "";
        });
    };

    replaceFile(base64) {
        const data = new FormData();
        data.append("data", base64);
        data.append("page", this.pageId);
        data.append("document_owner", this.owner);
        this.setState({
            uploading: true,
        });
        AxiosReactClient.postRequest(`replaceDocument/${this.props.order_id}`, data, {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    uploadPercent: percentCompleted,
                });
            },
        })
            .then((res) => {
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
                this.updateDocumentUrl(
                    this.pageId,
                    this.localImage
                );

            })
            .catch((err) => {
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
            });
    }

    uploadFile(base64) {
        const data = new FormData();
        data.append("data", base64);
        data.append("type", this.selectedDocumentType);
        data.append("document_owner", this.owner);
        this.setState({
            uploading: true,
        });
        AxiosReactClient.postRequest(`uploadDocument/${this.props.order_id}`, data, {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    uploadPercent: percentCompleted,
                });
            },
        })
            .then((res) => {
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
                this.updateDocumentUrl(
                    res.data.data.document_page_id,
                    this.localImage
                );

            })
            .catch((err) => {
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
            });
    }

    fileToBase64 = (file) => {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = function (event) {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });
    };

    detectPdf(url) {
        let fileType = url.split(".").slice(-1)[0];
        return fileType === 'pdf'
    }

    updateDocumentUrl(id, url) {
        let allDocs = {...this.state.documents};
        let pages = [];
        pages.push({
            id: id,
            url: url
        });
        if (allDocs.hasOwnProperty(this.selectedDocumentType)) {
            allDocs[this.selectedDocumentType]['pages'] = pages;
        }
        else {
            allDocs[this.selectedDocumentType] = {
                owner: this.owner,
                pages: pages
            }
        }
        this.setState({
            documents: allDocs
        })
    }

    parseDocumentTypeToLabel(documentType) {
        return documentType.replace('DOCUMENT_', '').replaceAll('_', ' ');
    }


    render() {
        return (
            <>
                <div className="table-responsive ">
                    <Table className="align-items-center table-flush" hover responsive>
                        <thead className="thead-light">
                        <tr>
                            <th style={{width: '20%'}}>Document type</th>
                            <th style={{width: '20%'}}>Attached To</th>
                            <th style={{width: '20%'}}>Document</th>
                            <th style={{width: '40%'}}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.documentTypes && Object.keys(this.state.documentTypes).map((document_type, index) => {
                            return (
                                <tr>
                                    <td className="table-user">
                                        <h4 className="font-weight-bold">
                                            {this.parseDocumentTypeToLabel(document_type)}
                                        </h4>
                                    </td>
                                    <td className="table-user">
                                        <h4 className="font-weight-bold">
                                            {this.state.documentTypes[document_type].owner.toUpperCase()}
                                        </h4>
                                    </td>
                                    <td>
                                        {this.state.documents.hasOwnProperty(document_type) ? this.state.documents[document_type].pages.map((page, index) => {
                                            return (
                                                <a href={page.url} target={"_blank"}><img src={page.url}
                                                                                          width={"50px"} height={"50px"}
                                                                                          style={{borderRadius: '50%'}}/></a>
                                            );
                                        }) : <span>Not Uploaded</span>}
                                    </td>
                                    <td>
                                        {!(this.state.uploading && this.selectedDocumentType === document_type) && (this.state.documents.hasOwnProperty(document_type) ? <Button className="shadow-none" size="sm"
                                                contained={"true"}
                                                color={"primary"}
                                                type="button" onClick={e => {
                                            this.selectedDocumentType = document_type;
                                            this.pageId = this.state.documents[document_type].pages[0].id;
                                            this.owner = this.state.documents[document_type].owner;
                                            this.handleClick();
                                        }}>
                                            Replace
                                        </Button> : <Button className="shadow-none" size="sm"
                                                            contained={"true"}
                                                            color={"primary"}
                                                            type="button" onClick={e => {
                                            this.selectedDocumentType = document_type;
                                            this.owner = this.state.documentTypes[document_type].owner;
                                            this.pageId = 0;
                                            this.handleClick();
                                        }}>
                                            Upload
                                        </Button>)}
                                        {this.state.uploading && this.selectedDocumentType === document_type &&
                                        <div className={"mt-1"}><Progress value={this.state.uploadPercent}
                                                                          color={"green"}
                                                                          max={"100"}></Progress></div>}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                    <input
                        type="file"
                        accept="image/*,.pdf"
                        hidden
                        id="file"
                        ref="fileUploader"
                        onChange={this.detectImage}
                    />

                </div>


                <Modal
                    className="modal-dialog-centered"
                    size="lg"
                    isOpen={this.state.addressDialog}
                    toggle={() => {
                        this.setState({
                            addressDialog: !this.state.addressDialog
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>Remark</h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                addressDialog: false
                            })
                        }}/>
                    </div>
                    <hr className={"text-dark m-0 p-0"}/>

                    <div className="modal-footer date-modal-footer ">
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            size="sm"
                            onClick={() => {
                                this.setState({
                                    addressDialog: false
                                })
                                // this.saveRemark(this.props.match.params.id, {remark: this.state.remarkText})
                            }}
                        >
                            Done</Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        policyHolderDetailsLoading: state.policies.policyHolderDetailsLoading,
        policyHolderDetailsData: state.policies.policyHolderDetailsData,

    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        updatePolicyHolderData: (policyHolderDetailsData) =>
            dispatch({
                type: actionTypes.GET_POLICY_HOLDER_DETAILS_SUCCESS,
                policyHolderDetailsData: policyHolderDetailsData,
            }),

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderDocumentsTab);