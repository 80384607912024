import * as actionTypes from '../actions/actionTypes/partnerTypes';
import {
    updateObject
} from '../utility';


const initialState = {
    error: null,

    partnerDealerListLoading: false,
    partnerDealerCardsLoading: false,
    partnerDealerFilterListLoading: false,

    partnerDealerListData: null,
    partnerDealerCardsData: null,
    totalDealerListCount: null,

    partnerDealerFilterListData: null,
};


//  START 
const partDealerListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerDealerListLoading: true
    });
};
const partDealerCardsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerDealerCardsLoading: true
    });
};
const partnerDealerFilterListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerDealerFilterListLoading: true
    });
};


//  SUCCESS 
const partDealerListSuccess = (state, action) => {
    return updateObject(state, {
        partnerDealerListData: action.data,
        totalDealerListCount: action.count,
        error: null,
        partnerDealerListLoading: false
    });
};
const partDealerCardsSuccess = (state, action) => {
    return updateObject(state, {
        partnerDealerCardsData: action.data,
        error: null,
        partnerDealerCardsLoading: false
    });
};
const partnerDealerFilterListSuccess = (state, action) => {
    return updateObject(state, {
        partnerDealerFilterListData: action.data,
        error: null,
        partnerDealerFilterListLoading: false
    });
};


//  FAIL 

const partDealerListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerDealerListLoading: false
    });
};
const partDealerCardsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerDealerCardsLoading: false
    });
};
const partnerDealerFilterListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerDealerFilterListLoading: false
    });
};




const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PARTNER_DEALER_LIST_START:
            return partDealerListStart(state, action);

        case actionTypes.GET_PARTNER_DEALER_CARDS_START:
            return partDealerCardsStart(state, action);

        case actionTypes.GET_PARTNER_DEALER_FILTER_LIST_START:
            return partnerDealerFilterListStart(state, action);




        case actionTypes.GET_PARTNER_DEALER_LIST_SUCCESS:
            return partDealerListSuccess(state, action);

        case actionTypes.GET_PARTNER_DEALER_CARDS_SUCCESS:
            return partDealerCardsSuccess(state, action);

        case actionTypes.GET_PARTNER_DEALER_FILTER_LIST_SUCCESS:
            return partnerDealerFilterListSuccess(state, action);




        case actionTypes.GET_PARTNER_DEALER_LIST_FAIL:
            return partDealerListFail(state, action);

        case actionTypes.GET_PARTNER_DEALER_CARDS_FAIL:
            return partDealerCardsFail(state, action);

        case actionTypes.GET_PARTNER_DEALER_FILTER_LIST_FAIL:
            return partnerDealerFilterListFail(state, action);




        default:
            return state;
    }
};

export default reducer;
