export const GET_PAYOUT_LIST_START = 'GET_PAYOUT_LIST_START';
export const GET_PAYOUT_HISTORY_START = 'GET_PAYOUT_HISTORY_START';
export const GET_PAYOUT_PRODUCT_FILTER_LIST_START = 'GET_PAYOUT_PRODUCT_FILTER_LIST_START';
export const GET_PAYOUT_STATUS_FILTER_LIST_START = 'GET_PAYOUT_STATUS_FILTER_LIST_START';
export const GET_PAYOUT_CARDS_START = 'GET_PAYOUT_CARDS_START';


export const GET_PAYOUT_LIST_SUCCESS = 'GET_PAYOUT_LIST_SUCCESS';
export const GET_PAYOUT_HISTORY_SUCCESS = 'GET_PAYOUT_HISTORY_SUCCESS';
export const GET_PAYOUT_PRODUCT_FILTER_LIST_SUCCESS = 'GET_PAYOUT_PRODUCT_FILTER_LIST_SUCCESS';
export const GET_PAYOUT_STATUS_FILTER_LIST_SUCCESS = 'GET_PAYOUT_STATUS_FILTER_LIST_SUCCESS';
export const GET_PAYOUT_CARDS_SUCCESS = 'GET_PAYOUT_CARDS_SUCCESS';


export const GET_PAYOUT_LIST_FAIL = 'GET_PAYOUT_LIST_FAIL';
export const GET_PAYOUT_HISTORY_FAIL = 'GET_PAYOUT_HISTORY_FAIL';
export const GET_PAYOUT_PRODUCT_FILTER_LIST_FAIL = 'GET_PAYOUT_PRODUCT_FILTER_LIST_FAIL';
export const GET_PAYOUT_STATUS_FILTER_LIST_FAIL = 'GET_PAYOUT_STATUS_FILTER_LIST_FAIL';
export const GET_PAYOUT_CARDS_FAIL = 'GET_PAYOUT_CARDS_FAIL';
