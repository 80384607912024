import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Shimmer from "../../../../components/CustomUi/Shimmer/Shimmer"
import * as actions from '../../../../Store/actions';
import Can from '../../../../config/Casl/Can'
import * as permissions from '../../../../config/Casl/permissions';
import {
    Card,
    CardBody,
    CardTitle,
    Badge,
    Row,
    Col,
    Button,
    Container
} from "reactstrap";

class ViewRole extends Component {
    state = {
        permissionListBlue: ["List Dealers", "Filter Dealers By Date", "Show Dealers"],
        permissionListGray: ["Update Dealers", "Remarks Dealers"],
        roleId: ''
    };
    componentDidMount() {
        this.setState({
            roleId: this.props.match.params.id
        })
        this.props.onGetRoleIdData(this.props.match.params.id);
    }
    handleTagsinput = tagsinput => {
        this.setState({ tagsinput });
    };
    changeString = (str) => {
        var a = str.indexOf(" ");
        var temp = str.substring(a + 1);
        return temp;
    }
    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Card className="m-2">
                        <CardBody>
                            <Row className="m-0 p-0">
                                <Col>
                                    <CardTitle className="mt-0 mr-auto m-0" tag="h3">
                                        {this.props.roleIdData ? this.props.roleIdData.name : <Shimmer />}
                                    </CardTitle>
                                </Col>
                                <Col className="text-right" xs="4">
                                    <Can I={permissions.ROLES.update} a={permissions.ROLES.subject}>
                                        <Button
                                            color="primary"
                                            className="btn-icon"
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault()
                                                this.props.history.push('/admin/roles/' + this.state.roleId + "/edit")
                                            }}
                                            size="sm"
                                        >
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-edit" />
                                            </span>
                                            Edit
                                    </Button>
                                    </Can>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="m-2">
                        <CardBody>
                            <CardTitle className="mb-3" tag="h3">
                                Permissions
                            </CardTitle>
                            <hr />
                            {this.props.roleIdData && this.props.roleIdData.permissions && this.props.roleIdData.permissions.map((group, index) => {
                                return <div className="mb-4" key={Math.random(100)}>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example4cols1Input">
                                        {group.groupName}
                                    </label>
                                    <br />
                                    {group.permissions && group.permissions.map((permission, index) => {
                                        return <Badge key={Math.random(100)}
                                            className="badge cursor-pointer" pill color="primary"
                                            onClick={e => e.preventDefault()}
                                        >{this.changeString(permission.display_name)}</Badge>
                                    })}
                                </div>
                            })}
                        </CardBody>
                    </Card>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        roleIdDataLoading: state.roles.roleIdDataLoading,
        roleIdData: state.roles.roleIdData,
        error: state.roles.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetRoleIdData: (id) => dispatch(actions.getRoleIdData(id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewRole);