export const options = {
    fields: [
        {
            reference_key: 'response_vehicle_category',
            title: 'Vehicle Category'
        },
        {
            reference_key: 'response_vehicle_type',
            title: 'Vehicle Type'
        },
        {
            reference_key: 'response_registration_number',
            title: 'Vehicle Registration Number'
        },
        {
            reference_key: 'response_vehicle_manufacturer',
            title: 'Vehicle Manufacturer'
        },
        {
            reference_key: 'response_vehicle_model',
            title: 'Vehicle Model'
        },
        {
            reference_key: 'response_vehicle_variant',
            title: 'Vehicle Variant'
        },
        {
            reference_key: 'response_gst_number',
            title: 'GST Number'
        },
        {
            reference_key: 'response_start_date',
            title: 'Policy Start Date'
        },
        {
            reference_key: 'response_end_date',
            title: 'Policy End Date'
        },
        {
            reference_key: 'response_previous_expiry_date',
            title: 'Previous Expiry Date'
        },
        {
            reference_key: 'response_vehicle_invoice_date',
            title: 'Vehicle Invoice Date'
        }
    ]
};
