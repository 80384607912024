import React, { Component,Fragment} from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardTitle,
    CardBody,
    CardHeader,
    Container,
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Table,
    Col,
  } from "reactstrap";

export default class DealerPolicies extends Component{

    state = {
        hideFilters: false
      }
      options = ["All", "A", "B", "C"]
    
    
      render() {
        return (
          <Fragment>
            <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="4" xs="6">
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => {
                          e.preventDefault()
                          this.props.history.push('/admin/dealers/')                    
                    }}>
                        Dealers
                          </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        Policies
                          </a>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                {/* <Col className="text-right" lg="8" xs="6">
    
                                <Button
                                    className="btn-round btn-icon mr-4"
                                    color="success"
                                    href="#pablo"
                                    id="tooltip28070728"
                                    onClick={e => {
                                        e.preventDefault()
                                        this.props.history.push('/admin/roles/create')
                                    }}
                                    size="sm"
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-user-plus" />
                                    </span>
                                    <span className="btn-inner--text">Create</span>
                                </Button>
                                <UncontrolledTooltip delay={0} target="tooltip28070728">
                                    Add New Role</UncontrolledTooltip>
                            </Col> */}
              </Row>
            </div>
            <Card >
              <div className="py-4 table-responsive">
                <div >
                  <Row className="px-3">
                    {/* <Col xs={12} sm={6}>
                          <ListFilter />
                        </Col> */}
                    <Col>
                      <div
                        className="float-right"
                      >
                        <Button className="btn-icon btn-2" size="sm" outline color="secondary" type="button" onClick={e => {
                          this.setState({
                            hideFilters: !this.state.hideFilters
                          })
                        }}>
                          <span className="btn-inner--icon">
                            <i class="fas fa-filter"></i>
                          </span>
                        </Button>
    
                      </div>
                    </Col>
                  </Row>
                  <Row className="bg-light px-3 py-2" hidden={this.state.hideFilters}>
                    <Col className="p-1 px-3 text-muted">
                      <small>
                        <i class="fas fa-filter"></i>
                        <input type="text" placeholder="Type here" className="ml-2 bg-light border-0 text-muted" />
                      </small>
                    </Col>
                    <Col className="p-1 text-right px-3">
                      {/* <CustomDropdown name="Product" options={this.options} />
                      <CustomDropdown name="Partner"  options={this.options}  />
                      <CustomDropdown name="Provider"  options={this.options} /> */}
                    </Col>
                  </Row>
                </div>
    
    
                <Table className="align-items-center table-flush" hover responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Author</th>
                      <th>Created at</th>
                      <th>Product</th>
                      <th>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="cursor-pointer" onClick={(e) => {
                      e.preventDefault()
                      this.props.history.push('/admin/dealers/policies/' + 56)
                      console.log("clicked");
    
                    }}>
                      <td className="table-user">
                        <b>John Michael</b>
                      </td>
                      <td>
                        <span className="text-muted">10/09/2018</span>
                      </td>
                      <td>
                        <a
                          className="font-weight-bold"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Argon Dashboard PRO
                        </a>
                      </td>
                      <td>
                        <label className="custom-toggle">
                          <input defaultChecked type="checkbox" />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </td>
                    </tr>
                    <tr className="cursor-pointer">
                      <td className="table-user">
                        <b>Alex Smith</b>
                      </td>
                      <td>
                        <span className="text-muted">08/09/2018</span>
                      </td>
                      <td>
                        <a
                          className="font-weight-bold"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Argon Design System
                        </a>
                      </td>
                      <td>
                        <label className="custom-toggle">
                          <input type="checkbox" />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </td>
                    </tr>
                    <tr className="cursor-pointer">
                      <td className="table-user">
                        <b>Samantha Ivy</b>
                      </td>
                      <td>
                        <span className="text-muted">30/08/2018</span>
                      </td>
                      <td>
                        <a
                          className="font-weight-bold"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Black Dashboard
                        </a>
                      </td>
                      <td>
                        <label className="custom-toggle">
                          <input type="checkbox" />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </td>
                    </tr>
                    <tr className="cursor-pointer">
                      <td className="table-user">
                        <b>John Michael</b>
                      </td>
                      <td>
                        <span className="text-muted">10/09/2018</span>
                      </td>
                      <td>
                        <a
                          className="font-weight-bold"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Argon Dashboard PRO
                        </a>
                      </td>
                      <td>
                        <label className="custom-toggle">
                          <input defaultChecked type="checkbox" />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </td>
                    </tr>
                    <tr className="cursor-pointer">
                      <td className="table-user">
                        <b>John Michael</b>
                      </td>
                      <td>
                        <span className="text-muted">10/09/2018</span>
                      </td>
                      <td>
                        <a
                          className="font-weight-bold"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Argon Dashboard PRO
                        </a>
                      </td>
                      <td>
                        <label className="custom-toggle">
                          <input defaultChecked type="checkbox" />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </Table>
    
              </div>
            </Card>
            </Container>
          </Fragment>
        );
      }
    }
    