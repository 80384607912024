import * as actionTypes from './actionTypes/partnerTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'


// START
export const getPartnerPoliciesListStart = () => {
    return {
        type: actionTypes.GET_PARTNER_POLICIES_LIST_START
    };
};
export const getPartnerPolicyCardsStart = () => {
    return {
        type: actionTypes.GET_PARTNER_POLICIES_CARDS_START
    };
};

// SUCCESS
export const getPartnerPoliciesListSuccess = (data, count) => {
    return {
        type: actionTypes.GET_PARTNER_POLICIES_LIST_SUCCESS,
        data: data,
        count: count
    };
};
export const getPartnerPolicyCardsSuccess = (data) => {
    return {
        type: actionTypes.GET_PARTNER_POLICIES_CARDS_SUCCESS,
        data: data
    };
};


// FAIL
export const getPartnerPoliciesListFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_POLICIES_LIST_FAIL,
        error: error
    };
};
export const getPartnerPolicyCardsFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_POLICIES_CARDS_FAIL,
        error: error
    };
};



// ACTION
export const getPartnerPoliciesListData = (params) => {
    return dispatch => {
        dispatch(getPartnerPoliciesListStart());

        AxiosReactClient.getRequest('partners/policy', params).then(response => {
                // console.log(response);

                dispatch(getPartnerPoliciesListSuccess(response.data.data.policies, response.data.data.count));
            })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getPartnerPoliciesListFail(err.response.data.error));
                else
                    dispatch(getPartnerPoliciesListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};

export const getPartnerPolicyCardsData = (params) => {
    return dispatch => {
        dispatch(getPartnerPolicyCardsStart());

        AxiosReactClient.getRequest('partners/policy/cards', params).then(response => {
                // console.log(response);

                dispatch(getPartnerPolicyCardsSuccess(response.data.data.cards));
            })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getPartnerPolicyCardsFail(err.response.data.error));
                else
                    dispatch(getPartnerPolicyCardsFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};