import React, { Component, Fragment } from 'react';
import Can from '../../../../config/Casl/Can'
import * as permissions from '../../../../config/Casl/permissions';
import { connect } from "react-redux";
import * as actions from '../../../../Store/actions';
import Spinner from "../../../../components/Spinner/Spinner";
import {
    Button,
    Card,
    Table,
    Breadcrumb,
    Container,
    BreadcrumbItem,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";

class Roles extends Component {
    rolesList = []
    componentDidMount() {
        this.props.onGetRolesData();
    }
    render() {
        this.rolesList = this.props.rolesData ? this.props.rolesData.roles : []
        return (
            <Fragment>
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="4" xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-2"
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-user" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Roles
                                    </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="8" xs="6">
                                <Can I={permissions.ROLES.store} a={permissions.ROLES.subject}>
                                    <Button
                                        className="btn-round btn-icon mr-2"
                                        color="success"
                                        href="#pablo"
                                        id="tooltip28070728"
                                        onClick={e => {
                                            e.preventDefault()
                                            this.props.history.push('/admin/roles/create')
                                        }}
                                        size="sm"
                                    >
                                        <span className="btn-inner--icon mr-1">
                                            <i className="fas fa-user-plus" />
                                        </span>
                                        <span className="btn-inner--text">Create</span>
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="tooltip28070728">
                                        Add New Role</UncontrolledTooltip>
                                </Can>
                            </Col>
                        </Row>
                    </div>
                    <Card className="m-2" >
                        <Table className="align-items-center table-flush" hover responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Permissions</th>
                                    <th className="text-right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.rolesDataLoading && <tr >
                                        <td colSpan="12">
                                            <Spinner></Spinner>
                                        </td>
                                    </tr>
                                }
                                {this.rolesList.map((role, index) => {
                                    return <tr key={Math.random(100)}>
                                        <td className="table-user">
                                            <b>{role.id}</b>
                                        </td>
                                        <td>
                                            <span className="text-muted">{role.name}</span>
                                        </td>
                                        <td>
                                            <b>{role.permissionCount}</b>
                                        </td>
                                        <td className="text-right">
                                            <Can I={permissions.ROLES.show} a={permissions.ROLES.subject}>
                                                <Button
                                                    color="secondary" outline type="button"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.props.history.push('/admin/roles/' + role.id)
                                                    }}
                                                    size="sm"
                                                >
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="fas fa-eye" />
                                                    </span>
                                                    <span className="btn-inner--text">View</span>
                                                </Button>
                                            </Can>
                                            <Can I={permissions.ROLES.update} a={permissions.ROLES.subject}>
                                                <Button
                                                    className="btn-round btn-icon"
                                                    outline
                                                    color="primary"
                                                    id="tooltip-edit"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.props.history.push('/admin/roles/' + role.id + "/edit")
                                                    }}
                                                    size="sm"
                                                >
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="fas fa-edit" />
                                                    </span>
                                                    <span className="btn-inner--text">Edit</span>
                                                </Button>
                                                <UncontrolledTooltip delay={0} target="tooltip-edit">
                                                    Edit Role</UncontrolledTooltip>
                                            </Can>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Card>
                </Container>
            </Fragment>
        );
    }
}



const mapStateToProps = state => {
    return {
        rolesDataLoading: state.roles.rolesDataLoading,

        rolesData: state.roles.rolesData,


        error: state.roles.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetRolesData: () => dispatch(actions.getRolesData()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Roles);