import { buildPolicyTermOptions } from '../helpers/comparison';

export const initialComparisonFormState = {
  annual_income: '',
  dob: '',
  gender: '',
  is_smoker: '',
  occupation: '',
  mobile: '',
  name: '',
  pincode: '',
  hasError: false
};

export const errorMessageComparison = {
  annual_income: '',
  dob: '',
  gender: '',
  is_smoker: '',
  occupation: '',
  mobile: '',
  name: '',
  pincode: ''
};

export const touchFieldsComparison = {
  annual_income: false,
  dob: false,
  gender: false,
  is_smoker: false,
  occupation: false,
  mobile: false,
  name: false,
  pincode: false
};

export const options = {
  annualIncome: [
    {title: "4-8 L", value: "4-8"},
    {title: "9-15 L", value: "9-15"},
    {title: "16-25 L", value: "16-25"},
    {title: "26-40 L", value: "26-40"},
    {title: "40+ L", value: "40"}
  ],
  coverage: [
    {title: "25 Lakhs", value: 2500000},
    {title: "50 Lakhs", value: 5000000},
    {title: "75 Lakhs", value: 7500000},
    {title: "1 Crore", value: 10000000},
    {title: "1.5 Crore", value: 15000000},
    {title: "2 Crores", value: 20000000}
  ],
  gender: [
    {title: "Male", value: "male"},
    {title: "Female", value: "female"}
  ],
  yesNo: [
    {title: "Yes", value: true},
    {title: "No", value: false}
  ],
  occupation: [
    {title: "Salaried", value: "salaried"},
    {title: "Self Employed", value: "self_employed"}
  ],
  policyTerm: buildPolicyTermOptions()
};

export const mapping = {
  sumAssured: {
    2500000: '25 Lakhs',
    5000000: '50 Lakhs',
    7500000: '75 Lakhs',
    10000000: '1 Crore',
    15000000: '1.5 Crore',
    20000000: '2 Crores'
  },
  gender: {
    'male': 'Male',
    'female': 'Female'
  },
  isSmoker: {
    'true': 'Yes',
    'false': 'No'
  },
  smoker: {
    'true': 'Smoker',
    'false': 'Non Smoker'
  },
  occupation: {
    'salaried': 'Salaried',
    'self_employed': 'Self Employed'
  },
  annualIncome: {
    '4-8': '4-8 Lakhs',
    '9-15': '9-15 Lakhs',
    '16-25': '16-25 Lakhs',
    '26-40': '26-40 Lakhs',
    '40': '40+ L'
  },
  paymentFrequency: {
    'monthly': 'Month',
    'yearly': 'Year'
  }
};

export const cycleProductList=[11,19,22,31,40];