import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import "./utilities/StringEn"
import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";

import * as actions from './Store/actions/index';
import './App.css';

class App extends Component {
    path = '/';
    componentDidMount() {
        this.props.onTryAutoSignup();
        this.path = this.props.location.pathname;
        this.props.onGetPermissionsData();
    }
    getPath = (path) => {
        switch (path) {
            case '/auth/login':
                return '/admin/dashboard/home';
            case '/':
                return '/admin/dashboard/home';
            case '/admin':
                return path + '/dashboard/home';
            case '/admin/':
                return path + 'dashboard/home';
            case '/admin/dashboard':
                return path + '/home';
            case '/admin/dashboard/':
                return path + 'home';
            default:
                return path;
        }
    }
    render() {
        let routes = (
            <Switch>
                <Route path="/auth" render={props => <AuthLayout {...props} />} />
                <Redirect from="*" to="/auth/login" />
            </Switch>
        );

        if (this.props.isAuthenticated) {
            routes = (
                <Switch>
                    <Route path="/admin" render={props => <AdminLayout {...props} />} />
                    <Redirect from="*" to={this.getPath(this.path)} />
                </Switch>
            );
        }

        return (
            <div>
                {routes}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        onGetPermissionsData: () => dispatch(actions.getPermissionsData()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
