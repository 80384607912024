import React from "react";
import './Shimmer.css';

const Shimmer = () => (
    <div className="d-inline">
        <span className="shine dot"></span>
        <span className="shine dot"></span>
        <span className="shine dot"></span>
    </div>
);
export default Shimmer;
