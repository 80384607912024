export const options = {
    fields: [
        {
            reference_key: 'response_booking_ref',
            title: 'PNR Number'
        },
        {
            reference_key: 'response_ticket_no',
            title: 'Ticket Number'
        },
        {
            reference_key: 'response_geoscope_text',
            title: 'Geoscope'
        },
        {
            reference_key: 'response_start_date',
            title: 'Trip Start Date'
        },
        {
            reference_key: 'response_end_date',
            title: 'Trip End Date'
        },
        {
            reference_key: 'response_travel_days',
            title: 'Travel Days'
        },
        {
            reference_key: 'response_country_of_travel_text',
            title: 'Country of Travel'
        },
        {
            reference_key: 'response_from_location_text',
            title: 'Location From'
        },
        {
            reference_key: 'response_to_location_text',
            title: 'Location To'
        },
        {
            reference_key: 'response_sum_assured_currency',
            title: 'Sum Insured Currency'
        },
        {
            reference_key: 'response_sum_assured',
            title: 'Sum Insured'
        },
        {
            reference_key: 'response_passport_number',
            title: 'Passport Number'
        },
        {
            reference_key: 'response_visa_type_text',
            title: 'Visa Type'
        },
        {
            reference_key: 'response_legs',
            title: 'Number of Legs of Trip'
        },
        {
            reference_key: 'response_departure_date_time',
            title: 'Departure Date Time'
        },
        {
            reference_key: 'response_flight_number',
            title: 'Flight Number'
        }
    ]
};
