
// INSURANCE PROVIDERS
export const GET_INSURANCE_PROVIDERS_START = 'GET_INSURANCE_PROVIDERS_START';
export const GET_PRODUCT_PARTNETS_START = 'GET_PRODUCT_PARTNETS_START';
export const POST_ADD_FLOAT_START = 'POST_ADD_FLOAT_START';


export const GET_INSURANCE_PROVIDERS_SUCCESS = 'GET_INSURANCE_PROVIDERS_SUCCESS';
export const GET_PRODUCT_PARTNETS_SUCCESS = 'GET_PRODUCT_PARTNETS_SUCCESS';
export const POST_ADD_FLOAT_SUCCESS = 'POST_ADD_FLOAT_SUCCESS';


export const GET_INSURANCE_PROVIDERS_FAIL = 'GET_INSURANCE_PROVIDERS_FAIL';
export const GET_PRODUCT_PARTNETS_FAIL = 'GET_PRODUCT_PARTNETS_FAIL';
export const POST_ADD_FLOAT_FAIL = 'POST_ADD_FLOAT_FAIL';
