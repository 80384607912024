import React from 'react';

var Spinner = require('react-spinkit');
var dots = "...";

class Loader extends React.Component {
    render() {
        return <div
            className="left-0 right-0 top-0 bottom-0 position-absolute d-flex justify-content-center align-items-center bg-transparent-50 z-index-100">
            <div className="text-center">
                <Spinner name='ball-clip-rotate' color="#da5e59"/>
                <p className="m-0">{this.props.msg + dots}</p>
            </div>
        </div>
    }
}

export default Loader;
