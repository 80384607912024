import * as actionTypes from '../actions/actionTypes/partnerTypes';
import {updateObject} from '../utility';

const initialState = {
    error: null,

    partnerFinanceListLoading: false,
    partnerFinanceCardsLoading: false,
    productFinanceFilterListLoading: false,
    partnerFinanceFilterListLoading: false,

    partnerFinanceListData: null,
    partnerFinanceCardsData: null,
    totalFinanceListCount: null,

    productFinanceFilterListData: null,
    partnerFinanceFilterListData: null,
};


//  START 
const partFinanceListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerFinanceListLoading: true
    });
};
const partFinanceCardsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerFinanceCardsLoading: true
    });
};
const productFinanceFilterListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        productFinanceFilterListLoading: true
    });
};
const partnerFinanceFilterListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerFinanceFilterListLoading: true
    });
};



//  SUCCESS 
const partFinanceListSuccess = (state, action) => {
    return updateObject(state, {
        partnerFinanceListData: action.data,
        totalFinanceListCount: action.count,
        error: null,
        partnerFinanceListLoading: false
    });
};
const partFinanceCardsSuccess = (state, action) => {
    return updateObject(state, {
        partnerFinanceCardsData: action.data,
        error: null,
        partnerFinanceCardsLoading: false
    });
};
const productFinanceFilterListSuccess = (state, action) => {
    return updateObject(state, {
        productFinanceFilterListData: action.data,
        error: null,
        productFinanceFilterListLoading: false
    });
};
const partnerFinanceFilterListSuccess = (state, action) => {
    return updateObject(state, {
        partnerFinanceFilterListData: action.data,
        error: null,
        partnerFinanceFilterListLoading: false
    });
};


//  FAIL 

const partFinanceListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerFinanceListLoading: false
    });
};
const partFinanceCardsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerFinanceCardsLoading: false
    });
};
const productFinanceFilterListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        productFinanceFilterListLoading: false
    });
};
const partnerFinanceFilterListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerFinanceFilterListLoading: false
    });
};




const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PARTNER_FINANCE_LIST_START:
            return partFinanceListStart(state, action);

        case actionTypes.GET_PARTNER_FINANCE_CARDS_START:
            return partFinanceCardsStart(state, action);

        case actionTypes.GET_PRODUCT_FINANCE_FILTER_LIST_START:
            return productFinanceFilterListStart(state, action);

        case actionTypes.GET_PARTNER_FINANCE_FILTER_LIST_START:
            return partnerFinanceFilterListStart(state, action);

            


        case actionTypes.GET_PARTNER_FINANCE_LIST_SUCCESS:
            return partFinanceListSuccess(state, action);

        case actionTypes.GET_PARTNER_FINANCE_CARDS_SUCCESS:
            return partFinanceCardsSuccess(state, action);

        case actionTypes.GET_PRODUCT_FINANCE_FILTER_LIST_SUCCESS:
            return productFinanceFilterListSuccess(state, action);

        case actionTypes.GET_PARTNER_FINANCE_FILTER_LIST_SUCCESS:
            return partnerFinanceFilterListSuccess(state, action);




        case actionTypes.GET_PARTNER_FINANCE_LIST_FAIL:
            return partFinanceListFail(state, action);

        case actionTypes.GET_PARTNER_FINANCE_CARDS_FAIL:
            return partFinanceCardsFail(state, action);

        case actionTypes.GET_PRODUCT_FINANCE_FILTER_LIST_FAIL:
            return productFinanceFilterListFail(state, action);

        case actionTypes.GET_PARTNER_FINANCE_FILTER_LIST_FAIL:
            return partnerFinanceFilterListFail(state, action);




        default:
            return state;
    }
};

export default reducer;
