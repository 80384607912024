import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Can from '../../../../config/Casl/Can'
import * as permissions from '../../../../config/Casl/permissions';
import * as actions from '../../../../Store/actions';
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Container,
    Form,
    Input,
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Col,
} from "reactstrap";

class ViewUser extends Component {
    state = {
        permissionListBlue: ["List Dealers", "Filter Dealers By Date", "Show Dealers"],
        permissionListGray: ["Update Dealers", "Remarks Dealers"],
        roleName: ''
    };
    constructor(props) {
        super(props)
        this.props.onGetUserInfo(props.match.params.id);
        this.props.onGetUserPermissions(props.match.params.id);
        this.props.onGetRolesData()
    }
    handleTagsinput = tagsinput => {
        this.setState({ tagsinput });
    };
    changeString = (str) => {
        var a = str.indexOf(" ");
        var temp = str.substring(a + 1);
        return temp;
    }
    render() {
        return (
            <Fragment>
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="4" xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-2"
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-user" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            User Info
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="8" xs="6">
                            </Col>
                        </Row>
                    </div>
                    <Row className="m-0 p-0">
                        <Col className="p-2" xl="8">
                            <Card >
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">{this.props.userInfo && this.props.userInfo.name}</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">

                                            <Can I={permissions.USERS.update} a={permissions.ROLES.subject}>
                                                <Button
                                                    color="primary"
                                                    className="btn-icon"
                                                    href="#pablo"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.props.history.push('/admin/users/' + this.props.match.params.id + "/edit")
                                                    }}
                                                    size="sm"
                                                >
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="fas fa-edit" />
                                                    </span>
                                                    Edit
                                            </Button>
                                            </Can>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            User information
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Name
                                                        </label>
                                                        <Input disabled
                                                            value={this.props.userInfo ? this.props.userInfo.name : ''}
                                                            id="input-username"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Email address
                                                    </label>
                                                        <Input disabled
                                                            id="input-email"
                                                            value={this.props.userInfo ? this.props.userInfo.email : ''}
                                                            type="email"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Phone
                                                    </label>
                                                        <Input disabled
                                                            value={this.props.userInfo?.mobile != null ? this.props.userInfo.mobile : ''}
                                                            id="input-first-name"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                            Role
                                                    </label>
                                                        <Input disabled
                                                            value={this.props.userInfo ? this.props.userInfo.role_name : ''}
                                                            id="input-last-name"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-phone"
                                                        >
                                                            Status
                                                        </label>
                                                        {this.props.userInfo?.is_active &&
                                                        <Row className='ml-1'>
                                                            <span className="text-muted">{this.props.userInfo?.is_active == '1' ? 'Active' : 'In Active'}</span>
                                                        </Row>}
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6"></Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="p-2" xl="4">
                            <Card >
                                <CardBody>
                                    <CardTitle className="mb-3" tag="h3">
                                        Permissions
                                    </CardTitle>
                                    <hr className="my-4" />
                                    {this.props.userPermissions && this.props.userPermissions.map((group, index) => {
                                        return <div key={Math.random(200)} className="mb-4">
                                            <label
                                                className="form-control-label"
                                                htmlFor="example4cols1Input">
                                                {group.groupName}
                                            </label>
                                            <br />
                                            {group.permissions && group.permissions.map((permission, index) => {
                                                return <Badge key={Math.random(100)}
                                                    className="badge cursor-pointer" pill color="primary"
                                                    onClick={e => e.preventDefault()}
                                                >{this.changeString(permission.display_name)}</Badge>
                                            })}
                                        </div>
                                    })}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfoLoading: state.roles.userInfoLoading,

        userInfo: state.roles.userInfo,
        userPermissionLoading: state.roles.userPermissionLoading,
        userPermissions: state.roles.userPermissions,
        roleIdData: state.roles.roleIdData,

        error: state.roles.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetRolesData: () => dispatch(actions.getRolesData()),
        onGetRoleIdData: (id) => dispatch(actions.getRoleIdData(id)),
        onGetUserInfo: (id) => dispatch(actions.getUserInfoForViewing(id)),
        onGetUserPermissions: (id) => dispatch(actions.getUserPermissions(id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);