import * as actionTypes from '../actions/actionTypes/partnerTypes';
import {
    updateObject
} from '../utility';


const initialState = {
    error: null,

    partnerClaimListLoading: false,
    partnerClaimCardsLoading: false,
    partnerClaimFilterListLoading: false,

    partnerClaimListData: null,
    partnerClaimCardsData: null,
    totalClaimListCount: null,

    partnerClaimFilterListData: null,
};


//  START 
const partClaimListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerClaimListLoading: true
    });
};
const partClaimCardsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerClaimCardsLoading: true
    });
};
const partnerClaimFilterListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerClaimFilterListLoading: true
    });
};


//  SUCCESS 
const partClaimListSuccess = (state, action) => {
    return updateObject(state, {
        partnerClaimListData: action.data,
        totalClaimListCount: action.count,
        error: null,
        partnerClaimListLoading: false
    });
};
const partClaimCardsSuccess = (state, action) => {
    return updateObject(state, {
        partnerClaimCardsData: action.data,
        error: null,
        partnerClaimCardsLoading: false
    });
};
const partnerClaimFilterListSuccess = (state, action) => {
    return updateObject(state, {
        partnerClaimFilterListData: action.data,
        error: null,
        partnerClaimFilterListLoading: false
    });
};


//  FAIL 
const partClaimListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerClaimListLoading: false
    });
};
const partClaimCardsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerClaimCardsLoading: false
    });
};
const partnerClaimFilterListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerClaimFilterListLoading: false
    });
};




const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PARTNER_CLAIM_LIST_START:
            return partClaimListStart(state, action);

        case actionTypes.GET_PARTNER_CLAIM_CARDS_START:
            return partClaimCardsStart(state, action);

        case actionTypes.GET_PARTNER_CLAIM_FILTER_LIST_START:
            return partnerClaimFilterListStart(state, action);




        case actionTypes.GET_PARTNER_CLAIM_LIST_SUCCESS:
            return partClaimListSuccess(state, action);

        case actionTypes.GET_PARTNER_CLAIM_CARDS_SUCCESS:
            return partClaimCardsSuccess(state, action);

        case actionTypes.GET_PARTNER_CLAIM_FILTER_LIST_SUCCESS:
            return partnerClaimFilterListSuccess(state, action);




        case actionTypes.GET_PARTNER_CLAIM_LIST_FAIL:
            return partClaimListFail(state, action);

        case actionTypes.GET_PARTNER_CLAIM_CARDS_FAIL:
            return partClaimCardsFail(state, action);

        case actionTypes.GET_PARTNER_CLAIM_FILTER_LIST_FAIL:
            return partnerClaimFilterListFail(state, action);




        default:
            return state;
    }
};

export default reducer;
