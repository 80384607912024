import * as actionTypes from './actionTypes/dealerTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'


// START
export const getDealerDealerListStart = () => {
    return {
        type: actionTypes.GET_DEALER_DEALER_LIST_START,
    };
};
export const getDealerDealerCardsStart = () => {
    return {
        type: actionTypes.GET_DEALER_DEALER_CARDS_START,
    };
};
export const getDealerDetailsViewStart = () => {
    return {
        type: actionTypes.GET_DEALER_DETAILS_VIEW_START,
    };
};
export const getDealerDetailsViewCardStart = () => {
    return {
        type: actionTypes.GET_DEALER_DETAILS_VIEW_CARD_START,
    };
};
export const getKamListStart = () => {
    return {
        type: actionTypes.GET_KAM_LIST_START,
    };
};
export const getDealerViewProductListStart = () => {
    return {
        type: actionTypes.GET_DEALER_VIEW_PRODUCT_LIST_START,
    };
};
export const getBrandListStart = () => {
    return {
        type: actionTypes.GET_BRAND_LIST_START,
    };
};
export const getDealerBrandListStart = () => {
    return {
        type: actionTypes.GET_DEALER_BRAND_LIST_START,
    };
};
export const getAdditionalContactListStart = () => {
    return {
        type: actionTypes.GET_ADDITIONAL_CONTACT_LIST_START,
    };
};
export const getDealerDetailsHistoryStart = () => {
    return {
        type: actionTypes.GET_DEALER_DETAILS_HISTORY_START,
    };
};
export const getDealerInvoicingModelStart = () => {
    return {
        type: actionTypes.GET_DEALER_INVOICING_MODEL_START,
    };
};


// SUCCESS
export const getDealerDealerListSuccess = (data, count) => {
    return {
        type: actionTypes.GET_DEALER_DEALER_LIST_SUCCESS,
        data: data,
        count: count,

    };
};
export const getDealerDealerCardsSuccess = (data) => {
    return {
        type: actionTypes.GET_DEALER_DEALER_CARDS_SUCCESS,
        data: data,
    };
};
export const getDealerDetailsViewSuccess = (data, policies, transactions, products, policyCount, transactionCount) => {
    return {
        type: actionTypes.GET_DEALER_DETAILS_VIEW_SUCCESS,
        data: data,
        policies: policies,
        transactions: transactions,
        products: products,
        policyCount: policyCount,
        transactionCount: transactionCount
    };
};
export const getDealerDetailsViewCardSuccess = (data) => {
    return {
        type: actionTypes.GET_DEALER_DETAILS_VIEW_CARD_SUCCESS,
        data: data,
    };
};
export const getKamListSuccess = (data) => {
    return {
        type: actionTypes.GET_KAM_LIST_SUCCESS,
        data: data,
    };
};
export const getDealerViewProductListSuccess = (data) => {
    return {
        type: actionTypes.GET_DEALER_VIEW_PRODUCT_LIST_SUCCESS,
        data: data,
    };
};
export const getBrandListSuccess = (data) => {
    return {
        type: actionTypes.GET_BRAND_LIST_SUCCESS,
        data: data,
    };
};
export const getDealerBrandListSuccess = (data) => {
    return {
        type: actionTypes.GET_DEALER_BRAND_LIST_SUCCESS,
        data: data,
    };
};
export const getAdditionalContactListSuccess = (data) => {
    return {
        type: actionTypes.GET_ADDITIONAL_CONTACT_LIST_SUCCESS,
        data: data,
    };
};
export const getDealerDetailsHistorySuccess = (data, count) => {
    return {
        type: actionTypes.GET_DEALER_DETAILS_HISTORY_SUCCESS,
        data: data,
        count: count,
    };
};
export const getDealerInvoicingModelSuccess = (data, count) => {
    return {
        type: actionTypes.GET_DEALER_INVOICING_MODEL_SUCCESS,
        data: data,
        count: count,
    };
};

// FAIL
export const getDealerDealerListFail = (error) => {
    return {
        type: actionTypes.GET_DEALER_DEALER_LIST_FAIL,
        error: error
    };
};
export const getDealerDealerCardsFail = (error) => {
    return {
        type: actionTypes.GET_DEALER_DEALER_CARDS_FAIL,
        error: error
    };
};
export const getDealerDetailsViewFail = (error) => {
    return {
        type: actionTypes.GET_DEALER_DETAILS_VIEW_FAIL,
        error: error
    };
};
export const getDealerDetailsViewCardFail = (error) => {
    return {
        type: actionTypes.GET_DEALER_DETAILS_VIEW_CARD_FAIL,
        error: error
    };
};
export const getKamListFail = (error) => {
    return {
        type: actionTypes.GET_KAM_LIST_FAIL,
        error: error
    };
};
export const getDealerViewProductListFail = (error) => {
    return {
        type: actionTypes.GET_DEALER_VIEW_PRODUCT_LIST_FAIL,
        error: error
    };
};
export const getBrandListFail = (error) => {
    return {
        type: actionTypes.GET_BRAND_LIST_FAIL,
        error: error
    };
};
export const getDealerBrandListFail = (error) => {
    return {
        type: actionTypes.GET_DEALER_BRAND_LIST_FAIL,
        error: error
    };
};
export const getAdditionalContactListFail = (error) => {
    return {
        type: actionTypes.GET_ADDITIONAL_CONTACT_LIST_FAIL,
        error: error
    };
};
export const getDealerDetailsHistoryFail = (error) => {
    return {
        type: actionTypes.GET_DEALER_DETAILS_HISTORY_FAIL,
        error: error,
    };
};
export const getDealerInvoicingModelFail = (error) => {
    return {
        type: actionTypes.GET_DEALER_INVOICING_MODEL_FAIL,
        error: error,
    };
};

export const removeProductFromDealer = (product_id) => {
    return {
        type: actionTypes.REMOVE_PRODUCT_FROM_DEALER,
        id: product_id,
    };
};


// ACTION
export const getDealerDealerListData = (filter, params) => {
    return dispatch => {
        dispatch(getDealerDealerListStart());
        AxiosReactClient.getRequest('dealers', params).then(response => {
            dispatch(getDealerDealerListSuccess(response.data.data.dealers, response.data.data.totalCount));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getDealerDealerListFail(err.response.data.error));
                else
                    dispatch(getDealerDealerListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getDealerDealerCardsData = (params) => {
    return dispatch => {
        dispatch(getDealerDealerCardsStart());
        AxiosReactClient.getRequest('dealers/cards', params).then(response => {
            dispatch(getDealerDealerCardsSuccess(response.data.data.cards));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getDealerDealerCardsFail(err.response.data.error));
                else
                    dispatch(getDealerDealerCardsFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getKamListData = () => {
    return dispatch => {
        dispatch(getKamListStart());
        AxiosReactClient.getRequest('dealers/kam').then(response => {
            dispatch(getKamListSuccess(response.data.data.kams));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getKamListFail(err.response.data.error));
                else
                    dispatch(getKamListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getDealerDetailsViewData = (id, params) => {
    return dispatch => {
        dispatch(getDealerDetailsViewStart());
        AxiosReactClient.getRequest('dealers/' + id, params).then(response => {
            dispatch(getDealerDetailsViewSuccess(response.data.data.dealershipDetails, response.data.data.policies, response.data.data.transactions, response.data.data.products, response.data.data.policy_count, response.data.data.transaction_count));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getDealerDetailsViewFail(err.response.data.error));
                else
                    dispatch(getDealerDetailsViewFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getDealerDetailsViewCardData = (id) => {
    return dispatch => {
        dispatch(getDealerDetailsViewCardStart());
        AxiosReactClient.getRequest('dealers/' + id + '/cards').then(response => {
            dispatch(getDealerDetailsViewCardSuccess(response.data.data.cards));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getDealerDetailsViewCardFail(err.response.data.error));
                else
                    dispatch(getDealerDetailsViewCardFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getDealerViewProductListData = () => {
    return dispatch => {
        dispatch(getDealerViewProductListStart());
        AxiosReactClient.getRequest('filter/product/list/').then(response => {
            dispatch(getDealerViewProductListSuccess(response.data.data.product));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getDealerViewProductListFail(err.response.data.error));
                else
                    dispatch(getDealerViewProductListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getBrandListData = () => {
    return dispatch => {
        dispatch(getBrandListStart());
        AxiosReactClient.getRequest('filter/brands/list/').then(response => {
            dispatch(getBrandListSuccess(response.data.data.brands));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getBrandListFail(err.response.data.error));
                else
                    dispatch(getBrandListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getDealerBrandListData = (id) => {
    return dispatch => {
        dispatch(getDealerBrandListStart());
        AxiosReactClient.getRequest('dealers/' + id + '/brands').then(response => {
            dispatch(getDealerBrandListSuccess(response.data.data.dealer_brands));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getDealerBrandListFail(err.response.data.error));
                else
                    dispatch(getDealerBrandListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getAdditionalContactListData = (id) => {
    return dispatch => {
        dispatch(getAdditionalContactListStart());
        AxiosReactClient.getRequest('dealers/' + id + '/additional-number').then(response => {
            dispatch(getAdditionalContactListSuccess(response.data.data.additional_numbers));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getAdditionalContactListFail(err.response.data.error));
                else
                    dispatch(getAdditionalContactListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getDealerDetailsHistoryData = (id, params) => {
    return dispatch => {
        dispatch(getDealerDetailsHistoryStart());
        AxiosReactClient.getRequest('dealers/history/' + id, params).then(response => {
            dispatch(getDealerDetailsHistorySuccess(response.data.data.Dealerhistory, response.data.data.count));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getDealerDetailsHistoryFail(err.response.data.error));
                else
                    dispatch(getDealerDetailsHistoryFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getDealerInvoicingModelData = (id, params) => {
    return dispatch => {
        dispatch(getDealerInvoicingModelStart());
        AxiosReactClient.getRequest('dealers/' + id + '/invoiceModel', params).then(response => {
            dispatch(getDealerInvoicingModelSuccess(response.data.data.invoice_model));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getDealerInvoicingModelFail(err.response.data.error));
                else
                    dispatch(getDealerInvoicingModelFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};

export const getFilteredViewData = (id, params) => {
    return dispatch => {
        dispatch(getDealerDetailsViewStart());
        AxiosReactClient.getRequest(`dealers/export/${id}`, params).then(response => {
            dispatch(getFilterTransactionSuccess(response.data.data.transactions??[], response.data.data.transaction_count??0))
        })
        .catch(err => {
            if (err.response)
                dispatch(getDealerDetailsViewFail(err.response.data?.error));
            else
                dispatch(getDealerDetailsViewFail({
                    code: 502,
                    message: err.message
                }));
        });
    };
};

export const getFilterTransactionSuccess = (transactions, transactionCount) => {
    return {
        type: actionTypes.GET_FILTER_VIEW_SUCCESS,
        transactions: transactions,
        transactionCount: transactionCount
    };
};