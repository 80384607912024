import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const Popup = (props) => {
    const {
        className,
        forgotPassword,
        closeHandler,
        form,
        title,
        footerClose
      } = props;

   
    
    const [modal, setModal] = useState(forgotPassword);
    const toggle = () => {
        setModal(!modal)
        closeHandler();
    };

   
   
    return (
        <div>
           
            <Modal isOpen={modal} toggle={toggle} className={className} centered={true}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                {form}
                </ModalBody>
                <ModalFooter>
                   {footerClose && <Button color="secondary" onClick={toggle}>Cancel</Button>}
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default Popup;