import * as actionTypes from '../actions/actionTypes/dealerTypes';
import { updateObject } from '../utility';


const initialState = {
    error: null,

    dealerDealerListLoading: false,
    dealerDealerCardsLoading: false,
    dealerDetailsViewLoading: false,
    dealerDetailsViewCardLoading: false,
    kamListLoading: false,
    dealerViewProductListLoading: false,
    brandListLoading: false,
    dealerBrandListLoading: false,
    additionalContactsListLoading: false,
    dealerDetailsHistoryLoading: false,
    invoicingModelLoading: false,


    dealerDealerListData: null,
    dealerDealerCardsData: null,
    totaldealerListCount: null,
    dealerDetailsViewData: null,
    dealerDetailsViewCardData: null,
    kamListData: null,
    dealerViewProductListData: null,
    brandListData: null,
    dealerBrandListData: null,
    additionalContactListData: null,
    dealerDetailsHistoryData: null,
    policyCount: null,
    transactionCount: null,
    invoicingModelData: null,
    filteredTransactions: [],
    filteredTransactionCount: 0

};

//  START 
const delrDealerListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        dealerDealerListLoading: true
    });
};
const delrDealerCardsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        dealerDealerCardsLoading: true
    });
};
const delrDealerDetailsViewStart = (state, action) => {
    return updateObject(state, {
        error: null,
        dealerDetailsViewLoading: true
    });
};
const delrDealerDetailsViewCardStart = (state, action) => {
    return updateObject(state, {
        error: null,
        dealerDetailsViewCardLoading: true
    });
};
const delrKamListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        kamListLoading: true
    });
};
const delrProductListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        dealerViewProductListLoading: true
    });
};
const delrBrandListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        brandListLoading: true
    });
};
const delrDealerBrandListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        dealerBrandListLoading: true
    });
};
const delrAdditionalContactListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        additionalContactsListLoading: true
    });
};
const delrDetailsHistoryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        dealerDetailsHistoryLoading: true
    });
};
const delrInvoicingModelStart = (state, action) => {
    return updateObject(state, {
        error: null,
        invoicingModelLoading: true
    });
};

//  SUCCESS 
const delrDealerListSuccess = (state, action) => {
    return updateObject(state, {
        dealerDealerListData: action.data,
        totalDealerListCount: action.count,
        error: null,
        dealerDealerListLoading: false
    });
};
const delrDealerCardsSuccess = (state, action) => {
    return updateObject(state, {
        dealerDealerCardsData: action.data,
        error: null,
        dealerDealerCardsLoading: false
    });
};
const delrDealerDetailsViewSuccess = (state, action) => {
    return updateObject(state, {
        dealerDetailsViewData: action.data,
        dealerDetailsViewPolicies: action.policies,
        dealerDetailsViewTransactions: action.transactions,
        dealerDetailsViewProducts: action.products,
        policyCount: action.policyCount,
        transactionCount: action.transactionCount,
        error: null,
        dealerDetailsViewLoading: false
    });
};

const filterTransactionsSuccess = (state, action) => {
    return updateObject(state, {
        filteredTransactions: action.transactions,
        filteredTransactionCount: action.transactionCount,
        error: null,
        dealerDetailsViewLoading: false
    });
};
const delrDealerDetailsViewCardSuccess = (state, action) => {
    return updateObject(state, {
        dealerDetailsViewCardData: action.data,
        error: null,
        dealerDetailsViewCardLoading: false
    });
};
const delrKamListSuccess = (state, action) => {
    return updateObject(state, {
        kamListData: action.data,
        error: null,
        kamListLoading: false
    });
};
const delrProductListSuccess = (state, action) => {
    return updateObject(state, {
        dealerViewProductListData: action.data,
        error: null,
        dealerViewProductListLoading: false
    });
};
const delrBrandListSuccess = (state, action) => {
    return updateObject(state, {
        brandListData: action.data,
        error: null,
        brandListLoading: false
    });
};
const delrDealerBrandListSuccess = (state, action) => {
    return updateObject(state, {
        dealerBrandListData: action.data,
        error: null,
        dealerBrandListLoading: false
    });
};
const delrAdditionalContactListSuccess = (state, action) => {
    return updateObject(state, {
        additionalContactListData: action.data,
        error: null,
        additionalContactsListLoading: false
    });
};
const delrDetailsHistorySuccess = (state, action) => {
    return updateObject(state, {
        dealerDetailsHistoryData: action.data,
        historyCount: action.count,
        error: null,
        dealerDetailsHistoryLoading: false
    });
};
const delrInvoicingModelSuccess = (state, action) => {
    return updateObject(state, {
        invoicingModelData: action.data,
        historyCount: action.count,
        error: null,
        invoicingModelLoading: false
    });
};

//  FAIL 
const delrDealerListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dealerDealerListLoading: false
    });
};
const delrDealerCardsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dealerDealerCardsLoading: false
    });
};
const delrDealerDetailsViewFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dealerDetailsViewLoading: false
    });
};
const delrDealerDetailsViewCardFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dealerDetailsViewCardLoading: false
    });
};
const delrKamListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        kamListLoading: false
    });
};
const delrProductListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dealerViewProductListLoading: false
    });
};
const delrBrandListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        brandListLoading: false
    });
};
const delrDealerBrandListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dealerBrandListLoading: false
    });
};
const delrAdditionalContactListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        additionalContactsListLoading: false
    });
};
const delrDetailsHistoryFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dealerDetailsHistoryLoading: false
    });
};
const delrInvoicingModelFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        invoicingModelLoading: false
    });
};

const removeProductFromDealer = (state, action) => {
    return updateObject(state, {
        dealerDetailsViewProducts: state.dealerDetailsViewProducts.filter(val => {
            return val.id !== action.id;
        })
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
       case actionTypes.GET_DEALER_DEALER_LIST_START:
            return delrDealerListStart(state, action);

        case actionTypes.GET_DEALER_DEALER_CARDS_START:
            return delrDealerCardsStart(state, action);

        case actionTypes.GET_DEALER_DETAILS_VIEW_START:
            return delrDealerDetailsViewStart(state, action);

        case actionTypes.GET_DEALER_DETAILS_VIEW_CARD_START:
            return delrDealerDetailsViewCardStart(state, action);

        case actionTypes.GET_KAM_LIST_START:
            return delrKamListStart(state, action);

        case actionTypes.GET_DEALER_VIEW_PRODUCT_LIST_START:
            return delrProductListStart(state, action);

        case actionTypes.GET_BRAND_LIST_START:
            return delrBrandListStart(state, action);

        case actionTypes.GET_DEALER_BRAND_LIST_START:
            return delrDealerBrandListStart(state, action);

        case actionTypes.GET_ADDITIONAL_CONTACT_LIST_START:
            return delrAdditionalContactListStart(state, action);

        case actionTypes.GET_DEALER_DETAILS_HISTORY_START:
            return delrDetailsHistoryStart(state, action);

        case actionTypes.GET_DEALER_INVOICING_MODEL_START:
            return delrInvoicingModelStart(state, action);



        case actionTypes.GET_DEALER_DEALER_LIST_SUCCESS:
            return delrDealerListSuccess(state, action);

        case actionTypes.GET_DEALER_DEALER_CARDS_SUCCESS:
            return delrDealerCardsSuccess(state, action);

        case actionTypes.GET_DEALER_DETAILS_VIEW_SUCCESS:
            return delrDealerDetailsViewSuccess(state, action);

        case actionTypes.GET_DEALER_DETAILS_VIEW_CARD_SUCCESS:
            return delrDealerDetailsViewCardSuccess(state, action);

        case actionTypes.GET_KAM_LIST_SUCCESS:
            return delrKamListSuccess(state, action);

        case actionTypes.GET_DEALER_VIEW_PRODUCT_LIST_SUCCESS:
            return delrProductListSuccess(state, action);

        case actionTypes.GET_BRAND_LIST_SUCCESS:
            return delrBrandListSuccess(state, action);

        case actionTypes.GET_DEALER_BRAND_LIST_SUCCESS:
            return delrDealerBrandListSuccess(state, action);

        case actionTypes.GET_ADDITIONAL_CONTACT_LIST_SUCCESS:
            return delrAdditionalContactListSuccess(state, action);

        case actionTypes.GET_DEALER_DETAILS_HISTORY_SUCCESS:
            return delrDetailsHistorySuccess(state, action);

        case actionTypes.GET_DEALER_INVOICING_MODEL_SUCCESS:
            return delrInvoicingModelSuccess(state, action);



        case actionTypes.GET_DEALER_DEALER_LIST_FAIL:
            return delrDealerListFail(state, action);

        case actionTypes.GET_DEALER_DEALER_CARDS_FAIL:
            return delrDealerCardsFail(state, action);

        case actionTypes.GET_DEALER_DETAILS_VIEW_FAIL:
            return delrDealerDetailsViewFail(state, action);

        case actionTypes.GET_DEALER_DETAILS_VIEW_CARD_FAIL:
            return delrDealerDetailsViewCardFail(state, action);

        case actionTypes.GET_KAM_LIST_FAIL:
            return delrKamListFail(state, action);

        case actionTypes.GET_DEALER_VIEW_PRODUCT_LIST_FAIL:
            return delrProductListFail(state, action);

        case actionTypes.GET_BRAND_LIST_FAIL:
            return delrBrandListFail(state, action);

        case actionTypes.GET_DEALER_BRAND_LIST_FAIL:
            return delrDealerBrandListFail(state, action);

        case actionTypes.GET_ADDITIONAL_CONTACT_LIST_FAIL:
            return delrAdditionalContactListFail(state, action);

        case actionTypes.GET_DEALER_DETAILS_HISTORY_FAIL:
            return delrDetailsHistoryFail(state, action);

        case actionTypes.GET_DEALER_INVOICING_MODEL_FAIL:
            return delrInvoicingModelFail(state, action);

        case actionTypes.REMOVE_PRODUCT_FROM_DEALER:
            return removeProductFromDealer(state, action);
        case actionTypes.GET_FILTER_VIEW_SUCCESS:
            return filterTransactionsSuccess(state, action);



        default:
            return state;
    }
};

export default reducer;

