import * as actionTypes from '../actions/actionTypes/providerTypes';
import {
    updateObject
} from '../utility';



const initialState = {
    error: null,

    insuranceProvidersLoading: false,
    productPartnersLoading: false,
    addingFloat: false,

    insuranceProvidersList: null,
    productPartnersList: null,
    nonPartner: null,
    addFloatResponse: null,
};

//  START 
const incProvidersStart = (state, action) => {
    return updateObject(state, {
        error: null,
        insuranceProvidersLoading: true
    });
};
const productPartnersStart = (state, action) => {
    return updateObject(state, {
        error: null,
        productPartnersLoading: true
    });
};

const addFloatStart = (state, action) => {
    return updateObject(state, {
        error: null,
        addingFloat: true
    });
};

//  SUCCESS 

const incProvidersSuccess = (state, action) => {
    return updateObject(state, {
        insuranceProvidersList: action.data,
        error: null,
        insuranceProvidersLoading: false
    });
};


const productPartnersSuccess = (state, action) => {
    return updateObject(state, {
        productPartnersList: action.data,
        nonPartner: action.nonPartner,
        error: null,
        productPartnersLoading: false
    });
};
const addFloatSuccess = (state, action) => {
    return updateObject(state, {
        addFloatResponse: action.data,
        error: null,
        addingFloat: false
    });
};

//  FAIL 

const incProvidersFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        insuranceProvidersLoading: false
    });
};

const productPartnersFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        productPartnersLoading: false
    });
};
const addFloatFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        addingFloat: false
    });
};




const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INSURANCE_PROVIDERS_START:
            return incProvidersStart(state, action);
        case actionTypes.GET_PRODUCT_PARTNETS_START:
            return productPartnersStart(state, action);
        case actionTypes.POST_ADD_FLOAT_START:
            return addFloatStart(state, action);


        case actionTypes.GET_INSURANCE_PROVIDERS_SUCCESS:
            return incProvidersSuccess(state, action);
        case actionTypes.GET_PRODUCT_PARTNETS_SUCCESS:
            return productPartnersSuccess(state, action);
        case actionTypes.POST_ADD_FLOAT_SUCCESS:
            return addFloatSuccess(state, action);


        case actionTypes.GET_INSURANCE_PROVIDERS_FAIL:
            return incProvidersFail(state, action);
        case actionTypes.GET_PRODUCT_PARTNETS_FAIL:
            return productPartnersFail(state, action);
        case actionTypes.POST_ADD_FLOAT_FAIL:
            return addFloatFail(state, action);


        default:
            return state;
    }
};

export default reducer;