import React from 'react'
import { Dropdown, Image } from 'semantic-ui-react'

const DropdownCustom = (props) => (
    <Dropdown
        trigger={props.trigger}
        defaultOpen={false}
        options={props.options}
        pointing={props.position}
        icon={null}
        value={props.value}
        selectOnBlur={false}
        onChange={(e, value)=>{
            props.onClickOption(e, value)
        }}
    />
)

export default DropdownCustom