import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Notification = ({ autoClose = 5000 }) => {
  return (
    <ToastContainer position="top-right" autoClose={autoClose} />
  );
};

export default Notification;