import React, {Component} from "react";
import {Badge, Button, Card, CardBody, Container, Modal, Table} from "reactstrap";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import * as actionTypes from "../../../Store/actions/actionTypes/policyTypes"

class DocumentsTab extends Component {
    state = {
        pageId: null,
        documentId: '',
        salariedDocumentOptions: [
            {key: "", value: "Select"},
            {key: "SalarySlip", value: "Latest 3 months of Salary Slips"},
            {key: "ITR_2", value: "2 years of ITR / Form 16"},
            {key: "bankStatement", value: "6 months of Bank statement showing salary credit"},
        ],
        nonSalariedDocumentOptions: [
            {key: "", value: "Select"},
            {key: "ITR_3", value: "3 years of ITR / Form 16"},
            {key: "computation", value: "3 years of Computation of Income"},
        ],
        addressProofOptions: [
            {key: "", value: "Select"},
            {key: "UtilityBill", value: "Utility Bill"},
            {key: "VoterID", value: "Voter ID"},
            {key: "DrivingLicense", value: "Driving License"},
            {key: "Passport", value: "Passport"},
            {key: "RentAgreement", value: "Rent Agreement (Current)"},
            {key: "EmployerAllotmentLetter", value: "Employer Allotment Letter"},
            {key: "PropertyTaxReceipt", value: "Property or Municipal tax receipt "},
            {key: "BankPostStatement", value: "Bank statement / Post office account statement (within six months)"},
        ],
        incomeProof: this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag ? this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag : "",
        bankProof: this.props.documentData?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]?.tag ? this.props.documentData?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]?.tag : "",
        addressProof: this.props.documentData?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag ? this.props.documentData?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag : "",
        numberOfDocs: this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "SalarySlip" ? 3 : this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_2" ? 2 :
            this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "bankStatement" ? 6 : this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "computation" ? 3 :
                this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_3" ? 3 : null,
        pdfUrl: require('../../../assets/img/icons/pdf.png'),
        salaried: this.props.documentData?.response_occupation === 'salaried' || false,
        secondDoc: false,
        thirdDoc: false,
        chipOptions: [
            {title: "Yes", value: "yes"},
            {title: "No", value: "no"},
        ],
        selectedChip: this.props.documentData?.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]?.tag ? "no" : "yes",
        hideSubmit: !this.props.documentData?.payment_successful ? true : (!!this.props.documentData?.is_documentation_complete),
        aadharCount: this.props.documentData?.uploaded_documents["DOCUMENT_ID"] ? this.props.documentData?.uploaded_documents["DOCUMENT_ID"]?.pages.length : 0,
        addressDialog: false

    }
    isDocComplete = false;
    handleBankChange = (e) => {

        this.setState({
            bankProof: e.target.value,
            selectedBankOptionText: e.target.value === "cancelledCheque" ? "Cancelled Cheque" : e.target.value === "passbook" ? "Passbook Front Page" : e.target.value === "statement" ? "Bank Statement" : "",

            numberOfBankDocs: e.target.value === "cancelledCheque" ? 1 : null,
        });
    }
    handleIncomeChange = (e) => {
        this.setState({
            incomeProof: e.target.value,
            selectedIncomeOptionText: ((this.props.documentData?.response_occupation !== "salaried") ? this.state.nonSalariedDocumentOptions.map((option, index) => {
                    return e.target.value === option.key ? option.value : ""
                }) :
                this.state.salariedDocumentOptions.map((option, index) => {
                    return e.target.value === option.key ? option.value : ""
                })),
            numberOfDocs: e.target.value === "SalarySlip" ? 3 : e.target.value === "ITR_2" ? 2 :
                e.target.value === "bankStatement" ? 6 : e.target.value === "computation" ? 3 :
                    e.target.value === "ITR_3" ? 3 : null,
        });
    }
    handleAddressChange = (e) => {
        this.setState({
            addressProof: e.target.value,
            selectedOptionText: this.state.addressProofOptions.map((option, index) => {
                return e.target.value === option.key ? option.value : ""
            })
        });
    }

    getDocUrl(docArray, id) {
        let doc = docArray?.find((doc) => {
            return doc.id == id;
        });
        let url = doc?.url;
        let splitUrl = url?.split(".");
        let assetUrl = splitUrl?.slice(-1)[0];
        return assetUrl === 'pdf' ? this.state.pdfUrl : doc?.url;
    }

    getDocDownLoadUrl(docArray, id) {
        let doc = docArray?.find((doc) => {
            return doc.id == id;
        });
        let url = doc?.url;
        let splitUrl = url?.split(".");
        let assetUrl = splitUrl?.slice(-1)[0];
        return doc?.url;
    }

    addSpace(string) {
        string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
        string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        return string.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    handleClick(id, tag) {
        if (typeof id === "number") {
            this.setState(
                {
                    pageId: id,
                    fileExists: true
                },
                () => {
                    this.refs.fileUploader.click();
                }
            );
        } else {
            this.setState(
                {
                    pageId: id,
                    documentId: id,
                    tag: tag,
                    fileExists: false
                },
                () => {
                    this.refs.fileUploader.click();
                }
            );
        }
    }

    detectImage = (e) => {
        this.setState({
            imageAsset: this.detectPdf(e.target.files[0].name) ? require("../../../assets/img/icons/pdf.png") : URL.createObjectURL(e.target.files[0]),
        })
        e.target.files[0] &&
        this.fileToBase64(e.target.files[0]).then((result) => {
            if (this.state.fileExists) {
                this.replaceFile(result);
            } else {
                this.uploadFile(result);
            }
            this.refs.fileUploader.value = "";
        });
    };

    replaceFile(base64) {
        const data = new FormData();
        data.append("data", base64);
        data.append("page", this.state.pageId);
        this.setState({
            uploading: true,
        });
        AxiosReactClient.postRequest(`replaceDocument/${this.props.order_id}`, data, {
            headers: {
                "content-type": "application/x-www-form-urlencoded",
            },
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    uploadPercent: percentCompleted,
                });
            },
        })
            .then((res) => {
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
                this.updateDocumentUrl(
                    this.state.pageId,
                    // res.data.data.path
                    this.state.imageAsset,
                    'replace'
                );

            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
            });
    }

    uploadFile(base64) {
        const data = new FormData();
        data.append("data", base64);
        data.append("type", this.state.documentId);
        if (this.state.documentId === "DOCUMENT_ADDRESS_PROOF") {
            data.append("tag", this.state.tag);
        }
        if (this.state.documentId === "DOCUMENT_INCOME_PROOF") {
            data.append("tag", this.state.tag);
        }
        if (this.state.documentId === "DOCUMENT_BANK_DETAILS_PROOF") {
            data.append("tag", this.state.tag);
        }
        this.setState({
            uploading: true,
        });
        AxiosReactClient.postRequest(`uploadDocument/${this.props.order_id}`, data, {
            headers: {
                "content-type": "application/x-www-form-urlencoded",
            },
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    uploadPercent: percentCompleted,
                });
            },
        })
            .then((res) => {
                if (this.state.documentId === "DOCUMENT_ID") {
                    this.setState({
                        aadharCount: this.state.aadharCount+1
                    },()=>{
                        if(this.state.aadharCount ===2){
                            this.setState({
                                addressDialog: true
                            })
                        }
                    })
                }
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
                this.updateDocumentUrl(
                    res.data.data.document_page_id,
                    // res.data.data.path
                    this.state.imageAsset,
                    'upload'
                );

            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    uploading: false,
                    uploadPercent: 0,
                });
            });
    }

    fileToBase64 = (file) => {
        return new Promise((resolve) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });
    };

    detectPdf(url) {
        let fileType = url.split(".").slice(-1)[0];
        return fileType === 'pdf'
    }

    updateDocumentUrl(id, url, origin) {
        let allDocs = {...this.props.documentData.uploaded_documents};
        let docs = (allDocs[this.state.documentId] && allDocs[this.state.documentId]["pages"]) ? allDocs[this.state.documentId]["pages"]
            : [];
        if (origin === 'upload') {
            docs.push({
                id: id,
                url: url,
            });
        } else {
            let index = docs.indexOf({
                id: id,
                url: url,
            });
            docs[index + 1] = {
                id: id,
                url: url,
            };
        }
        allDocs = {...allDocs, [this.state.documentId]: {"pages": docs}};
        let data = {
            ...this.props.documentData,
            uploaded_documents: allDocs,
        };
        this.props.updatePolicyHolderData(data);
        this.isDocumentationComplete();
    }

    documentationComplete() {

        AxiosReactClient.postRequest(
            `orders/${this.props.order_id}/documentationComplete`,
            {}
        )
            .then((res) => {
                this.setState({
                    hideSubmit: true
                })

            })
            .catch((err) => {

                console.log(err);
            });
    }


    isDocumentationComplete() {
        let complete = true;
        if (!this.props.documentData) {
            complete = false;
            return;
        }
        if (!this.props.documentData.uploaded_documents) {
            complete = false;
            return;
        }
        if (
            !this.props.documentData.uploaded_documents["DOCUMENT_USER_PHOTO"] || !this.props.documentData.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"] || this.props.documentData.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"].length < 1
        )
            complete = false;

        if (
            !this.props.documentData.uploaded_documents["DOCUMENT_PAN"] || !this.props.documentData.uploaded_documents["DOCUMENT_PAN"]["pages"] || this.props.documentData.uploaded_documents["DOCUMENT_PAN"]["pages"].length < 1
        )
            complete = false;

        if (
            !this.props.documentData.uploaded_documents["DOCUMENT_ID"] || !this.props.documentData.uploaded_documents["DOCUMENT_ID"]["pages"] || this.props.documentData.uploaded_documents["DOCUMENT_ID"]["pages"].length < 2
        )
            complete = false;

        if (this.props.documentData?.response_sum_assured_life !== "0") {
            if (
                !!(this.props.documentData && this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"] && this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"]) ?
                    // !(!this.state.incomeProof || !this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag) ?
                    this.state.incomeProof === "ITR_2" || this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_2" ? this.props.documentData && this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"] && this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"]?.length < 2 :
                        (this.state.incomeProof === "SalarySlip" || this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "SalarySlip" ||
                            this.state.incomeProof === "ITR_3" || this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "ITR_3" ||
                            this.state.incomeProof === "computation" || this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag === "computation") ? this.props.documentData && this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"] && this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"]?.length < 3 :
                            this.props.documentData && this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"] && this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"].length < 1
                    : true
            )
                complete = false;

            if (
                !this.props.documentData.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"] || !this.props.documentData.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"] || this.props.documentData.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"].length < 1
            )
                complete = false;

        }

        if (this.state.selectedChip === "no") {

            if (
                !this.props.documentData.uploaded_documents["DOCUMENT_ADDRESS_PROOF"] || !this.props.documentData.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"] || this.props.documentData.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"].length < 1
            )

                complete = false;

        }
        this.isDocComplete = complete;

    }

    componentDidMount() {
        this.isDocumentationComplete();
        // if(this.state.aadharCount === 2){
        //     this.setState({
        //         addressDialog: true
        //     })
        // }
    }

    handleChipChange(option) {
        this.setState({
            selectedChip: option
        })
    }


    render() {
        this.isDocumentationComplete();
        let userPhoto = {};
        let userPhotoId = {};
        let addressProof = {};
        let addressProofId = {};
        let panCard = {};
        let panCardId = {};
        let incomeProof = {};
        let incomeProofId = {};
        let incomeProofId1 = {};
        let incomeProofId2 = {};
        let bankDetails = {};
        let bankDetailsId = {};
        let aadharCard = {};
        let aadharCardId = {};
        let aadharCardId1 = {};
        userPhoto = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_USER_PHOTO"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"];
        userPhotoId = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_USER_PHOTO"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"][0] &&
            this.props.documentData.uploaded_documents["DOCUMENT_USER_PHOTO"]["pages"][0].id;
        aadharCard = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"]["pages"];
        aadharCardId = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"]["pages"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"]["pages"][0] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"]["pages"][0].id;
        aadharCardId1 = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"]["pages"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"]["pages"][1] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ID"]["pages"][1].id;
        addressProof = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_ADDRESS_PROOF"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"];
        addressProofId = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_ADDRESS_PROOF"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"][0] &&
            this.props.documentData.uploaded_documents["DOCUMENT_ADDRESS_PROOF"]["pages"][0].id;
        panCard = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_PAN"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_PAN"]["pages"];
        panCardId = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_PAN"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_PAN"]["pages"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_PAN"]["pages"][0] &&
            this.props.documentData.uploaded_documents["DOCUMENT_PAN"]["pages"][0].id;
        incomeProof = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"];
        incomeProofId = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"][0] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"][0].id;
        incomeProofId1 = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"][1] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"][1].id;
        incomeProofId2 = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"][2] &&
            this.props.documentData.uploaded_documents["DOCUMENT_INCOME_PROOF"]["pages"][2].id;
        bankDetails = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"];
        bankDetailsId = this.props.documentData &&
            this.props.documentData.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"] &&
            this.props.documentData.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"][0] &&
            this.props.documentData.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]["pages"][0].id;
        return (
            <>
                <div className="table-responsive ">
                    <Table className="align-items-center table-flush" hover responsive>
                        <thead className="thead-light">
                        <tr>
                            <th>PROJECT</th>
                            <th>Document type</th>
                            <th>Document</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="table-user">
                                <h4 className="font-weight-bold">
                                    Customer Photo
                                </h4>
                            </td>
                            <td>

                            </td>
                            <td>
                                {userPhoto && userPhoto.map((doc, index) => {
                                    return (
                                        <img src={this.getDocUrl(userPhoto, doc.id)}
                                             width={"50px"} height={"50px"} style={{borderRadius: '50%'}}/>
                                    );
                                })}
                            </td>
                            <td>
                                <Button className="shadow-none" size="sm"
                                        contained={"true"}
                                        color={"primary"}
                                        type="button" onClick={e => {
                                    this.handleClick(userPhotoId ? userPhotoId : 'DOCUMENT_USER_PHOTO')
                                    this.setState({
                                        documentId: 'DOCUMENT_USER_PHOTO',
                                        pageId: userPhotoId
                                    })
                                }}>
                                    {userPhoto ? "Replace" : "Upload"}
                                </Button>

                                {userPhoto && userPhoto.map((doc, index) => {
                                    return (
                                        <Button className="shadow-none" size="sm"
                                                outline
                                                color={"primary"}
                                                type="button"
                                                onClick={e => {
                                                    window.open(this.getDocDownLoadUrl(userPhoto, doc.id))
                                                }}>
                                            Download
                                        </Button>
                                    );
                                })}
                            </td>
                        </tr>

                        <tr>
                            <td className="table-user">
                                <h4 className="font-weight-bold mt-0">
                                    Aadhar Card
                                </h4>
                            </td>
                            <td className={"m-auto"}>
                                {/*<div className="form-group m-auto">*/}
                                {/*    <h4>*/}
                                {/*        <b>Is provided address<br/> same address as on<br/> Aadhar card?</b><br/>*/}
                                {/*    </h4>*/}
                                {/*    {this.state.chipOptions.map((option) => {*/}
                                {/*        return <Badge className="btn-icon btn-2 rounded-20 p-3 my-1 px-4 cursor-pointer"*/}
                                {/*                      color={option.value === this.state.selectedChip ? "info" : "primary"}*/}
                                {/*                      key={option.value} onClick={(e) => {*/}
                                {/*            this.handleChipChange(option.value)*/}
                                {/*        }}>*/}
                                {/*            {option.title}*/}
                                {/*        </Badge>*/}
                                {/*    })}*/}
                                {/*</div>*/}

                            </td>
                            <td>
                                {aadharCard && aadharCard.map((doc, index) => {
                                    return (<>
                                            <img src={this.getDocUrl(aadharCard, doc.id)}
                                                 width={"50px"} height={"50px"}
                                                 style={{borderRadius: '50%', padding: "5px"}}/>
                                            <br/>
                                        </>
                                    );
                                })}
                            </td>
                            <td>
                                <div className={"d-flex"}>
                                    <div className={"pr-2"}>
                                        <div className={"mt-2 mb-4"}>
                                            <Button className="shadow-none" size="sm"
                                                    contained={"true"}
                                                    color={"primary"}
                                                    type="button" onClick={e => {
                                                this.handleClick(aadharCardId ? aadharCardId : 'DOCUMENT_ID')
                                                this.setState({
                                                    documentId: 'DOCUMENT_ID',
                                                    pageId: aadharCardId
                                                })
                                            }}>
                                                {aadharCard && aadharCard[0] ? "Replace" : "Upload"}
                                            </Button>
                                        </div>
                                        <div className={"my-3"}>
                                            <Button className="shadow-none" size="sm"
                                                    contained={"true"}
                                                    color={"primary"}
                                                    type="button" onClick={e => {
                                                this.handleClick(aadharCardId1 ? aadharCardId1 : 'DOCUMENT_ID')
                                                this.setState({
                                                    documentId: 'DOCUMENT_ID',
                                                    pageId: aadharCardId,
                                                    secondDoc: true
                                                })
                                            }}>
                                                {aadharCard && aadharCard[1] ? "Replace" : "Upload"}
                                            </Button>
                                        </div>
                                    </div>
                                    <div>
                                        {aadharCard && aadharCard.map((doc, index) => {
                                            return (
                                                <div className={"mt-2 mb-4"}>
                                                    <Button className="shadow-none" size="sm"
                                                            outline
                                                            color={"primary"}
                                                            type="button"
                                                            onClick={e => {
                                                                window.open(this.getDocDownLoadUrl(aadharCard, doc.id))
                                                            }}>
                                                        Download
                                                    </Button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </td>
                        </tr>

                        {this.state.selectedChip === "no" &&
                        <tr>
                            <td className="table-user">
                                <h4 className="font-weight-bold">
                                    Address Proof
                                </h4>
                            </td>
                            <td className={"m-auto"}>
                                <div className="form-group m-auto">
                                    <select className="form-control"
                                            id="exampleFormControlSelect1"
                                            value={this.state.addressProof}
                                            onChange={(e) => {
                                                this.handleAddressChange(e)
                                            }}
                                    >
                                        {this.state.addressProofOptions.map((option, index) => {
                                            return <option value={option.key}
                                                           key={index + 'ghshd'}>{option.value}</option>
                                        })}

                                    </select>
                                </div>
                            </td>
                            <td>
                                {addressProof && addressProof.map((doc, index) => {
                                    return (
                                        <img src={this.getDocUrl(addressProof, doc.id)}
                                             width={"50px"} height={"50px"} style={{borderRadius: '50%'}}/>
                                    );
                                })}
                            </td>
                            <td>
                                <Button className="shadow-none" size="sm"
                                        contained={"true"}
                                        color={"primary"}
                                        type="button" onClick={e => {
                                    this.handleClick(addressProofId ? addressProofId : 'DOCUMENT_ADDRESS_PROOF', this.state.addressProof)
                                    this.setState({
                                        documentId: 'DOCUMENT_ADDRESS_PROOF',
                                        pageId: addressProofId
                                    })
                                }}>
                                    {addressProof ? "Replace" : "Upload"}
                                </Button>
                                {addressProof && addressProof.map((doc, index) => {
                                    return (
                                        <Button className="shadow-none" size="sm"
                                                outline
                                                color={"primary"}
                                                type="button"
                                                onClick={e => {
                                                    window.open(this.getDocDownLoadUrl(addressProof, doc.id))
                                                }}>
                                            Download
                                        </Button>
                                    );
                                })}

                            </td>
                        </tr>
                        }
                        <tr>
                            <td className="table-user">
                                <h4 className="font-weight-bold">
                                    PAN Card
                                </h4>
                            </td>
                            <td>
                            </td>
                            <td>
                                {panCard && panCard.map((doc, index) => {
                                    return (
                                        <img src={this.getDocUrl(panCard, doc.id)}
                                             width={"50px"} height={"50px"} style={{borderRadius: '50%'}}/>
                                    );
                                })}
                            </td>
                            <td>
                                <Button className="shadow-none" size="sm"
                                        contained={"true"}
                                        color={"primary"}
                                        type="button" onClick={e => {
                                    this.handleClick(panCardId ? panCardId : 'DOCUMENT_PAN')
                                    this.setState({
                                        documentId: 'DOCUMENT_PAN',
                                        pageId: panCardId
                                    })
                                }}>
                                    {panCard ? "Replace" : "Upload"}
                                </Button>
                                {panCard && panCard.map((doc, index) => {
                                    return (
                                        <Button className="shadow-none" size="sm"
                                                outline
                                                color={"primary"}
                                                type="button"
                                                onClick={e => {
                                                    window.open(this.getDocDownLoadUrl(panCard, doc.id))
                                                }}>
                                            Download
                                        </Button>
                                    );
                                })}

                            </td>
                        </tr>

                        <tr>
                            <td className="table-user">
                                <h4 className="font-weight-bold mt-0">
                                    Income Proof
                                </h4>
                            </td>
                            <td className={"m-auto"}>
                                <div className="form-group m-auto">
                                    {/*<h4>*/}
                                    {/*    {this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag && this.addSpace(this.props.documentData?.uploaded_documents["DOCUMENT_INCOME_PROOF"]?.tag + '')}*/}
                                    {/*</h4>*/}
                                    {this.state.salaried ?
                                        <select className="form-control"
                                                id="exampleFormControlSelect1"
                                                value={this.state.incomeProof}
                                                onChange={(e) => {
                                                    this.handleIncomeChange(e)
                                                }}
                                        >
                                            {this.state.salariedDocumentOptions.map((option, index) => {
                                                return <option value={option.key}
                                                               key={index + 'ghshd'}>{option.value}</option>
                                            })}

                                        </select>
                                        :
                                        <select className="form-control"
                                                id="exampleFormControlSelect1"
                                                value={this.state.incomeProof}
                                                onChange={(e) => {
                                                    this.handleIncomeChange(e)
                                                }}
                                        >
                                            {this.state.nonSalariedDocumentOptions.map((option, index) => {
                                                return <option value={option.key}
                                                               key={index + 'ghshd'}>{option.value}</option>
                                            })}

                                        </select>
                                    }

                                </div>
                                {/*<div className={"my-3"}>*/}
                                {/*    <h5 className={"text-light"}>*/}
                                {/*        June Salary Slip*/}
                                {/*    </h5>*/}
                                {/*</div>*/}
                                {/*<div className={"my-3"}>*/}
                                {/*    <h5 className={"text-light"}>*/}
                                {/*        July Salary Slip*/}
                                {/*    </h5>*/}
                                {/*</div>*/}
                                {/*<div className={"my-3"}>*/}
                                {/*    <h5 className={"text-light"}>*/}
                                {/*        August Salary Slip*/}
                                {/*    </h5>*/}
                                {/*</div>*/}
                            </td>
                            <td>
                                {incomeProof && incomeProof.map((doc, index) => {
                                    return (<>
                                            <img src={this.getDocUrl(incomeProof, doc.id)}
                                                 width={"50px"} height={"50px"}
                                                 style={{borderRadius: '50%', padding: "5px"}}/>
                                            <br/>
                                        </>
                                    );
                                })}
                                {/*{incomeProof && incomeProof[0] && <>*/}
                                {/*<img src={this.getDocUrl(incomeProof, incomeProof[0].id)}*/}
                                {/*     width={"50px"} height={"50px"}*/}
                                {/*     style={{borderRadius: '50%', padding: "5px"}}/>*/}
                                {/*    <br/>*/}
                                {/*    </>*/}
                                {/*}*/}
                                {/*{incomeProof && incomeProof[1] && <>*/}
                                {/*<img src={this.getDocUrl(incomeProof, incomeProof[1].id)}*/}
                                {/*     width={"50px"} height={"50px"}*/}
                                {/*     style={{borderRadius: '50%', padding: "5px"}}/>*/}
                                {/*<br/>*/}
                                {/*</>*/}
                                {/*}*/}
                                {/*{incomeProof && incomeProof[1] && <>*/}
                                {/*<img src={this.getDocUrl(incomeProof, incomeProof[2].id)}*/}
                                {/*     width={"50px"} height={"50px"}*/}
                                {/*     style={{borderRadius: '50%', padding: "5px"}}/>*/}
                                {/*    <br/>*/}
                                {/*</>*/}
                                {/*}*/}
                            </td>
                            <td>
                                <div className={"d-flex"}>
                                    <div className={"pr-2"}>
                                        {(this.state.numberOfDocs === 3 || this.state.numberOfDocs === 2 || this.state.numberOfDocs === 6) &&
                                        <div className={"mt-2 mb-4"}>
                                            <Button className="shadow-none" size="sm"
                                                    contained={"true"}
                                                    color={"primary"}
                                                    type="button" onClick={e => {
                                                this.handleClick(incomeProofId ? incomeProofId : 'DOCUMENT_INCOME_PROOF', this.state.incomeProof)
                                                this.setState({
                                                    documentId: 'DOCUMENT_INCOME_PROOF',
                                                    pageId: incomeProofId
                                                })
                                            }}>
                                                {incomeProof && incomeProof[0] ? "Replace" : "Upload"}
                                            </Button>
                                        </div>}
                                        {(this.state.numberOfDocs === 3 || this.state.numberOfDocs === 2 || this.state.numberOfDocs === 6) &&
                                        <div className={"my-3"}>
                                            <Button className="shadow-none" size="sm"
                                                    contained={"true"}
                                                    color={"primary"}
                                                    type="button" onClick={e => {
                                                this.handleClick(incomeProofId1 ? incomeProofId1 : 'DOCUMENT_INCOME_PROOF', this.state.incomeProof)
                                                this.setState({
                                                    documentId: 'DOCUMENT_INCOME_PROOF',
                                                    pageId: incomeProofId,
                                                    secondDoc: true
                                                })
                                            }}>
                                                {incomeProof && incomeProof[1] ? "Replace" : "Upload"}
                                            </Button>
                                        </div>}
                                        {(this.state.numberOfDocs === 3 || this.state.numberOfDocs === 6) &&
                                        <div className={"mb-3 mt-4"}>
                                            <Button className="shadow-none" size="sm"
                                                    contained={"true"}
                                                    color={"primary"}
                                                    type="button" onClick={e => {
                                                this.handleClick(incomeProofId2 ? incomeProofId2 : 'DOCUMENT_INCOME_PROOF', this.state.incomeProof)
                                                this.setState({
                                                    documentId: 'DOCUMENT_INCOME_PROOF',
                                                    pageId: incomeProofId,
                                                    thirdDoc: true
                                                })
                                            }}>
                                                {incomeProof && incomeProof[2] ? "Replace" : "Upload"}
                                            </Button>
                                        </div>}
                                    </div>
                                    <div>
                                        {incomeProof && incomeProof.map((doc, index) => {
                                            return (
                                                <div className={"mt-2 mb-4"}>
                                                    <Button className="shadow-none" size="sm"
                                                            outline
                                                            color={"primary"}
                                                            type="button"
                                                            onClick={e => {
                                                                window.open(this.getDocDownLoadUrl(incomeProof, doc.id))
                                                            }}>
                                                        Download
                                                    </Button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td className="table-user">
                                <h4 className="font-weight-bold">
                                    Bank Details
                                </h4>
                            </td>
                            <td className={"m-auto"}>
                                <div className="form-group m-auto">
                                    {/*<h4>*/}
                                    {/*    {this.props.documentData?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]?.tag && this.addSpace(this.props.documentData?.uploaded_documents["DOCUMENT_BANK_DETAILS_PROOF"]?.tag + '')}*/}
                                    {/*</h4>*/}
                                    <select className="form-control"
                                            id="exampleFormControlSelect1"
                                            value={this.state.bankProof}
                                            onChange={(e) => {
                                                this.handleBankChange(e)
                                            }}
                                    >
                                        <option>Select</option>
                                        <option value="cancelledCheque">Cancelled Cheque</option>
                                        <option value="passbook">Passbook Front Page</option>
                                        <option value="statement">Bank Statement</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                {bankDetails && bankDetails.map((doc, index) => {
                                    return (
                                        <img src={this.getDocUrl(bankDetails, doc.id)}
                                             width={"50px"} height={"50px"} style={{borderRadius: '50%'}}/>
                                    );
                                })}
                            </td>
                            <td>
                                <Button className="shadow-none" size="sm"
                                        contained={"true"}
                                        color={"primary"}
                                        type="button" onClick={e => {
                                    this.handleClick(bankDetailsId ? bankDetailsId : 'DOCUMENT_BANK_DETAILS_PROOF', this.state.bankProof)
                                    this.setState({
                                        documentId: 'DOCUMENT_BANK_DETAILS_PROOF',
                                        pageId: bankDetailsId
                                    })
                                }}>
                                    {bankDetails ? "Replace" : "Upload"}
                                </Button>
                                {bankDetails && bankDetails.map((doc, index) => {
                                    return (
                                        <Button className="shadow-none" size="sm"
                                                outline
                                                color={"primary"}
                                                type="button"
                                                onClick={e => {
                                                    window.open(this.getDocDownLoadUrl(bankDetails, doc.id))
                                                }}>
                                            Download
                                        </Button>
                                    );
                                })}

                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <input
                        type="file"
                        accept="image/*,.pdf"
                        hidden
                        id="file"
                        ref="fileUploader"
                        onChange={this.detectImage}
                    />
                    {this.isDocComplete && !this.state.hideSubmit &&
                    <div className={"d-flex justify-content-center mt-3"}>
                        <Button className="shadow-none" size="lg"
                                contained={"true"}
                                color={"primary"}
                                type="button"
                                onClick={e => {
                                    this.documentationComplete()
                                }}>
                            Submit
                        </Button>
                    </div>
                    }

                </div>


                <Modal
                    className="modal-dialog-centered"
                    size="lg"
                    isOpen={this.state.addressDialog}
                    toggle={() => {
                        this.setState({
                            addressDialog: !this.state.addressDialog
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>Remark</h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                addressDialog: false
                            })
                        }}/>
                    </div>
                    <hr className={"text-dark m-0 p-0"}/>

                    <div className="modal-body text-center">
                        <div className="form-group m-auto">
                            <h4>
                                <b>Is provided address same address as on Aadhar card?</b>
                            </h4>
                            {this.state.chipOptions.map((option) => {
                                return <Badge className="btn-icon btn-2 rounded-20 p-3 my-1 px-4 cursor-pointer"
                                              color={option.value === this.state.selectedChip ? "info" : "primary"}
                                              key={option.value} onClick={(e) => {
                                    this.handleChipChange(option.value)
                                }}>
                                    {option.title}
                                </Badge>
                            })}
                        </div>

                    </div>
                    <div className="modal-footer date-modal-footer ">
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            size="sm"
                            onClick={() => {
                                this.setState({
                                    addressDialog: false
                                })
                                // this.saveRemark(this.props.match.params.id, {remark: this.state.remarkText})
                            }}
                        >
                            Done</Button>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        policyHolderDetailsLoading: state.policies.policyHolderDetailsLoading,
        policyHolderDetailsData: state.policies.policyHolderDetailsData,

    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        updatePolicyHolderData: (policyHolderDetailsData) =>
            dispatch({
                type: actionTypes.GET_POLICY_HOLDER_DETAILS_SUCCESS,
                policyHolderDetailsData: policyHolderDetailsData,
            }),

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTab);