import React, { Component, Fragment } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from "reactstrap";

class ScrollableDataCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCol: 'right',
        }
        this.handleShadow = this.handleShadow.bind(this)
    }
    handleShadow = (col) => {
        this.setState({
            selectedCol: col
        })
    }
    render() {
        return (
            <Fragment>
                <div className="d-flex">
                    <Card className="card-pricing  border-0 text-center mb-4 w-400 shadow-none bg-transparent 0">
                        <CardHeader className="bg-transparent d-flex justify-content-start align-items-center pl-0">
                            <h4 className="text-uppercase ls-1 mb-0 pl-0">
                                Matrices
                            </h4>
                            <div className="position-absolute right-3">
                                <Button className="btn-icon btn-2 p-2 px-3" color="primary" type="button">
                                    <span className="btn-inner--icon text-white">
                                        <i className="fas fa-plus" />
                                    </span>
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody className="text-nowrap px-0 ">
                            <ul className="list-unstyled d-flex align-items-start m-0">
                                <li>
                                    <span className="text-random">
                                        <b>Sales (Offline)</b>
                                    </span>
                                </li>
                            </ul>
                            <ul className="list-unstyled custom-border-card1 ">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Cycle
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Eyewear
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Mobile ADLD/Screen damage
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                M Care
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Total No of Merchants
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Active Merchants
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                New Onboarded Merchants
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                New Onboarded Active Merchants
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Sales By New Onboarded Merchants
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Policies Sold/ Merchant
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="list-unstyled d-flex align-items-start m-0">
                                <li>
                                    <span className="text-random">
                                        <b>Sales (Digital)</b>
                                    </span>
                                </li>
                            </ul>
                            <ul className="list-unstyled custom-border-card1 ">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Rynox Gears (Biker Insurance)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                RNFI
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Paymonk
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Myipay
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Alpha Vector (Cycle Insurance)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Roadgods (Bag Insurance)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                MakeMyTrip (Best Price Insurance)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Mufubu
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Balance Hero
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Novex
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="list-unstyled d-flex align-items-start m-0">
                                <li>
                                    <span className="text-random">
                                        <b>Customer Service</b>
                                    </span>
                                </li>
                            </ul>
                            <ul className="list-unstyled custom-border-card1 ">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                #Claims till date (Monthly)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                #Closed Claims (Monthly)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                #Claims (Weekly)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                #Renewals
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                #Inhouse Average Claim Approval Time (Theft)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                #Partner Average Claim Approval Time (Theft)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                #Inhouse Average Claim Approval Time (Damage)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                #Partner Claim Approval Time (Damage)
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                #Claims Ratings
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="list-unstyled d-flex align-items-start m-0">
                                <li>
                                    <span className="text-random">
                                        <b>Seller App</b>
                                    </span>
                                </li>
                            </ul>
                            <ul className="list-unstyled custom-border-card1 ">
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                # Active sellers
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                # Sales Through App
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Sales % by App
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <span className="pl-2 text-sm ">
                                                Dealers selling by App %
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </CardBody>
                        <CardFooter className="bg-transparent">

                        </CardFooter>
                    </Card>
                    <div className="d-flex  custom-scroll ">
                        <Card className={"card-pricing bg-color-a border-0 text-center mb-4 w-250 rounded-0 " + (this.state.selectedCol === 'left' && " current-week-shadow")} onClick={(e) => {
                            e.preventDefault()
                            this.handleShadow('left')
                        }}>
                            <CardHeader className="bg-transparent px-2">
                                <h4 className="text-uppercase ls-1 text-random mb-0">
                                    08-OCT'19 - 15-OCT'19 
                            </h4>
                            </CardHeader>
                            <CardBody className="d-flex justify-content-between scroll-content text-nowrap px-0" style={{ overflowX: "scroll" }}>
                                <div className=" w-100">
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676 <i className={this.state.selectedCol === 'left' && "fa fa-arrow-down text-danger"}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </CardBody>
                            <CardFooter className="bg-transparent">

                            </CardFooter>
                        </Card>
                        <Card className={"card-pricing bg-color-b border-0 text-center mb-4 w-250 rounded-0 " + (this.state.selectedCol === 'right' && " current-week-shadow")} onClick={(e) => {
                            e.preventDefault()
                            this.handleShadow('right')
                        }}>
                            <CardHeader className="bg-transparent px-2">
                                <h4 className="text-uppercase ls-1 text-random mb-0">
                                    15-OCT'19 - 22-OCT'19
                                </h4>
                            </CardHeader>
                            <CardBody className="d-flex justify-content-between scroll-content text-nowrap px-0" style={{ overflowX: "scroll" }}>
                                <div className=" w-100">
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54677 <i className={this.state.selectedCol === 'right' && "fa fa-arrow-up text-success"}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </CardBody>
                            <CardFooter className="bg-transparent">

                            </CardFooter>
                        </Card>
                        <Card className="card-pricing bg-color-c border-0 text-center mb-4 w-200 rounded-0 ml-4">
                            <CardHeader className="bg-transparent px-2">
                                <h4 className="text-uppercase ls-1 text-random mb-0">
                                    October
                                </h4>
                            </CardHeader>
                            <CardBody className="d-flex justify-content-between scroll-content text-nowrap px-0" style={{ overflowX: "scroll" }}>
                                <div className=" w-100">
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled d-flex align-items-start m-0">
                                        <li>
                                            <span >

                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled mt-4 text-center custom-border-card">
                                        <li >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <span className=" text-sm text-random">
                                                        54676
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </CardBody>
                            <CardFooter className="bg-transparent">

                            </CardFooter>
                        </Card>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default ScrollableDataCards