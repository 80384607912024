import React, { Component, Fragment } from 'react';
import AxiosReactClient from '../../../../utilities/AxiosRestClient';
import * as actions from '../../../../Store/actions';
import { connect } from "react-redux";
import Loader from '../../../../components/CustomUi/Loader/Loader';

import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Container,
    Form,
    Input,
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Col,
} from "reactstrap";

const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

class EditUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            roleId: '',
            roleId1: '',
            permissionIdsList: [],
            showLoader: false,
            notification: null,
            roleName: "",
            groupNameArray: [],
            is_active: ""
        };
    }
    permissionObjectArray = [];
    componentDidMount() {
        this.props.onGetRolesData();
        this.props.onGetUserInfo(this.props.match.params.id);
        this.props.onGetUserPermissions(this.props.match.params.id);
    }
    removePermissionElement = (permission) => {
        this.setState({
            permissionIdsList: this.spliceArray(this.state.permissionIdsList, [permission.id])
        });
    }
    addPermissionElement = (permission) => {
        this.setState({
            permissionIdsList: this.concatArray([permission.id], this.state.permissionIdsList)
        })
    }
    concatArray(arr1, arr2) {
        var uniqueArray = [];
        let tempArr = arr1.concat(arr2);
        // Loop through array values
        for (var value of tempArr) {
            if (uniqueArray.indexOf(value) === -1) {
                uniqueArray.push(value);
            }
        }
        return uniqueArray;
    }
    spliceArray(original, remove) {
        return original.filter(value => !remove.includes(value));
    }
    addGroupPermissions = (permissionArray, groupName) => {
        this.setState({
            permissionIdsList: this.concatArray(this.state.permissionIdsList, permissionArray.map(({ id }) => { return id })),
            groupNameArray: this.concatArray(this.state.groupNameArray, [groupName])
        })
    }
    removeGroupPermissions = (permissionArray, groupName) => {
        this.setState({
            permissionIdsList: this.spliceArray(this.state.permissionIdsList, permissionArray.map(({ id }) => { return id })),
            groupNameArray: this.spliceArray(this.state.groupNameArray, [groupName])
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            name: nextProps.userInfo ? nextProps.userInfo.name : "",
            email: nextProps.userInfo ? nextProps.userInfo.email : "",
            phone: nextProps.userInfo && nextProps.userInfo.mobile ? nextProps.userInfo.mobile : "",
            roleName: nextProps.roleIdData ? nextProps.roleIdData.name : "",
            is_active: nextProps.userInfo ? nextProps.userInfo.is_active : ""
        })

        let idArray = [];
        nextProps.userPermissions && nextProps.userPermissions.forEach(group => {
            group.permissions.forEach(permission => {
                idArray.push(permission.id)
            });
        })

        if (nextProps.roleIdData && nextProps.roleIdData.permissions) {
            idArray = []
            nextProps.roleIdData.permissions.forEach(group => {
                group.permissions.forEach(permission => {
                    idArray.push(permission.id)
                });
            })
        }
        this.setState({
            permissionIdsList: []
        }, () => {
            this.setState({
                permissionIdsList: idArray
            })
        })
        if (!this.state.roleId) {
            this.state.roleId = this.props.userInfo ? this.props.userInfo.new_role_id : '';
        }
    }
    getPermissions = (id) => {
        this.props.onGetRoleIdData(id);
    }
    handleTagsinput = tagsinput => {
        this.setState({ tagsinput });
    };
    submitUserData = () => {
        if (this.state.name.trim() === '') {
            this.showMsg("Please enter user name", 'danger');
            return
        }
        if (this.state.email.trim() === '') {
            this.showMsg("Please enter user email", 'danger');
            return
        }
        if (!this.state.roleId) {
            this.showMsg("Please select a role", 'danger');
            return
        }
        if (this.state.permissionIdsList.length < 1) {
            this.showMsg("Please add at least 1 permission", 'danger');
            return
        }
        this.setState({
            showLoader: true
        })
        AxiosReactClient.putRequest('v2/users/' + this.props.match.params.id, {
            name: this.state.name,
            role_id: this.state.roleId,
            mobile: this.state.phone,
            email: this.state.email,
            permission_id: this.state.permissionIdsList,
            is_active: this.state.is_active
        }).then(response => {
            this.setState({
                showLoader: false
            })
            this.showMsg("User Updated successfully", "success")
            this.props.history.push('/admin/users/' + this.props.match.params.id)
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                })
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                }
                else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    }
    changeString = (str) => {
        var a = str.indexOf(" ");
        var temp = str.substring(a + 1);
        return temp;
    }
    render() {
        this.rolesList = this.props.rolesData ? this.props.rolesData.roles : []
        if (!this.props.permissionsDataLoading && this.props.permissionsData) {
            this.permissionObjectArray = [].concat(this.props.permissionsData.permissions);
        }
        return (
            <Fragment>
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="4" xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-2"
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-user" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Edit User
                                    </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="8" xs="6">
                            </Col>
                        </Row>
                    </div>
                    <Row className="m-0 p-0">
                        <Col className="p-2" xl="8">
                            <Card >
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">User Details</h3>
                                        </Col>
                                        <Col className="text-right">
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.submitUserData()
                                                }}
                                                size="mds"
                                            >

                                                Update
                                        </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="position-relative">
                                    {this.state.showLoader && <Loader msg={"Loading"} />}
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            User information
                                    </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Name
                                                    </label>
                                                        <Input
                                                            id="input-username"
                                                            value={this.state.name}
                                                            type="text"
                                                            onChange={e => {
                                                                this.setState({
                                                                    name: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Email address
                                                    </label>
                                                        <Input
                                                            value={this.state.email}
                                                            id="input-email"
                                                            type="email"
                                                            onChange={e => {
                                                                this.setState({
                                                                    email: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-phone"
                                                        >
                                                            Phone
                                                    </label>
                                                        <Input
                                                            value={this.state.phone}
                                                            id="input-phone"
                                                            onChange={e => {
                                                                if ((e.target.value == '' || e.target.value.match(phoneRegex) && e.target.value.length < 15)) {
                                                                    this.setState({
                                                                        phone: e.target.value
                                                                    })
                                                                }
                                                            }}
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {!this.props.userInfoLoading && <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="select_role"
                                                        >
                                                            Role
                                                        </label>
                                                        <Input id="select_role" value={this.state.roleId} onChange={e => {
                                                            this.setState({
                                                                roleId: e.target.value,
                                                                roleId1: e.target.value
                                                            })
                                                            this.getPermissions(e.target.value)
                                                        }} type="select">
                                                            {this.rolesList.map((role, index) => {
                                                                return <option key={"role_" + index} value={role.id} >{role.name}</option>
                                                            })
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                </Col>}
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-phone"
                                                        >
                                                            Status
                                                        </label>
                                                        <Row className='ml-1'>
                                                            <div className="form-check form-check-inline">
                                                                <label className="form-check-label text-muted" htmlFor="inlineRadioActive">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="is_active" id="inlineRadioActive"
                                                                        value="1"
                                                                        checked={this.state.is_active == "1"}
                                                                        onChange={e => {
                                                                           this.setState({
                                                                               is_active: e.target.value
                                                                           })
                                                                        }}
                                                                    /> Active
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <label className="form-check-label text-muted" htmlFor="inlineRadioInActive">
                                                                    <input className="form-check-input" type="radio"
                                                                        name="is_active" id="inlineRadioInActive"
                                                                        value="0"
                                                                        checked={this.state.is_active == "0"}
                                                                        onChange={e => {
                                                                           this.setState({
                                                                               is_active: e.target.value
                                                                           })
                                                                        }}
                                                                    /> In Active
                                                                </label>
                                                            </div>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6"></Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="p-2" xl="4">
                            <Card >
                                <CardBody>
                                    <CardTitle className="mb-3" tag="h3">
                                        Permissions
                                    </CardTitle>
                                    {this.permissionObjectArray.map((group, index) => {
                                        return <div key={Math.random(100)} className="mb-4">
                                            <label
                                                className="form-control-label cursor-pointer"
                                                htmlFor="example4cols1Input" >
                                                <span
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.state.groupNameArray.includes(group.groupName) ? this.removeGroupPermissions(group.permissions, group.groupName) : this.addGroupPermissions(group.permissions, group.groupName);
                                                    }}
                                                >
                                                    {group.groupName}
                                                </span>
                                            </label>
                                            <br />
                                            {group.permissions && group.permissions.map((permission, index) => {
                                                return <Badge key={Math.random(100)} id={"career-gray" + index}
                                                    className={`badge-md cursor-pointer tag-behaviour m-1 ` + (this.state.permissionIdsList && this.state.permissionIdsList.includes(permission.id) ? " background-primary text-white" : " opacity-7 ")}
                                                    pill color="secondary"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.state.permissionIdsList.includes(permission.id) ? this.removePermissionElement(permission) : this.addPermissionElement(permission)
                                                    }}
                                                >{this.changeString(permission.display_name)}
                                                    <span className="btn-inner--icon ml-1">
                                                        {(this.state.permissionIdsList && this.state.permissionIdsList.includes(permission.id)) ?
                                                            <i className="fas fa-times" />
                                                            :
                                                            <i className="fas fa-plus" />
                                                        }
                                                    </span>
                                                </Badge>
                                            })}
                                        </div>
                                    })}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        rolesDataLoading: state.roles.rolesDataLoading,
        userInfoLoading: state.roles.userInfoLoading,
        userPermissionLoading: state.roles.userPermissionLoading,
        permissionsDataLoading: state.roles.permissionsDataLoading,
        roleIdDataLoading: state.roles.roleIdDataLoading,


        rolesData: state.roles.rolesData,
        userInfo: state.roles.userInfo,
        userPermissions: state.roles.userPermissions,
        roleIdData: state.roles.roleIdData,
        permissionsData: state.roles.permissionsData,


        error: state.roles.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetPermissionsData: () => dispatch(actions.getPermissionsData()),
        onGetRolesData: () => dispatch(actions.getRolesData()),
        onGetUserInfo: (id) => dispatch(actions.getUserInfoForViewing(id)),
        onGetUserPermissions: (id) => dispatch(actions.getUserPermissions(id)),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetRoleIdData: (id) => dispatch(actions.getRoleIdData(id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUsers);