import React, { Component, Fragment } from 'react';

// import { connect } from "react-redux";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col
} from "reactstrap";


class Policy extends Component {

  render() {
    const { data } = this.props;
    return (
      <Fragment>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="4" xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>

                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => {
                      e.preventDefault()
                      this.props.history.push('/admin/dealers/all')
                    }
                    }>
                      Dealers
                          </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => {
                      e.preventDefault()
                      this.props.history.push('/admin/dealers/policies/')
                    }}>
                      Policies
                      </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <h5 className="text-primary d-inline font-weight-400">
                      View
                    </h5>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              {/* <Col className="text-right" lg="8" xs="6">

                            <Button
                                className="btn-round btn-icon mr-4"
                                color="success"
                                href="#pablo"
                                id="tooltip28070728"
                                onClick={e => {
                                    e.preventDefault()
                                    this.props.history.push('/admin/roles/create')
                                }}
                                size="sm"
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-user-plus" />
                                </span>
                                <span className="btn-inner--text">Create</span>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tooltip28070728">
                                Add New Role</UncontrolledTooltip>
                        </Col> */}
            </Row>
          </div>
          <Row className="card-wrapper">
            <Col lg="8">
              <Row>
                <Col lg="12">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h5 className="h5 mb-0 font-weight-400">Policy ID: {this.props.match.params.id}</h5>
                        </Col>
                        <Col className="text-right" xs="4">
                          <h5 className="text-muted mb-0 h5">
                            Filed On:
                         {/* {data.created} */}
                          </h5>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Name :
                            <span className="text-dark text-sm font-weight-300 ml-2">
                              {/* {data.name}   */}
                            </span>
                          </h6>
                        </Col>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Contact :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              {/* {data.contact} */}
                            </span>
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Email :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              {/* {data.email} */}
                            </span>
                          </h6>
                        </Col>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Provider :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              {/* {data.provider} */}
                            </span>
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Product :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              {/* {data.product} */}
                            </span>
                          </h6>
                        </Col>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Status :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              {/* {data.remarks} */}
                            </span>
                          </h6>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="12">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h5 className="h5 mb-0 font-weight-400">Product Details</h5>
                        </Col>
                        <Col className="text-right" xs="4">
                          <h5 className="text-muted mb-0 h5">
                            Product ID: 5756
                      </h5>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Dealership Name :
                            <span className="text-dark text-sm font-weight-300 ml-2">
                              Subahsh Beniwal
                            </span>
                          </h6>
                        </Col>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Invoice Amount :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              657
                            </span>
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Invoice Date :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              17 Oct 2019
                            </span>
                          </h6>
                        </Col>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Product :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              Cycle Insurance
                            </span>
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Product Premium :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              545
                            </span>
                          </h6>
                        </Col>
                        <Col md="6">
                          <h6 className="text-muted mb-3 h5">Brand Id :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                              20
                            </span>
                          </h6>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg="4">
              <Card>
                <CardHeader>
                  <h5 className="h5 mb-0 font-weight-400">Customer Details</h5>
                </CardHeader>
                <CardBody>

                  <h6 className="text-muted mb-3 h5">Name :
                            <span className="text-dark text-sm font-weight-300 ml-2">
                      {/* {data.name}   */}
                    </span>
                  </h6>

                  <h6 className="text-muted mb-3 h5">Contact :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                      {/* {data.contact} */}
                    </span>
                  </h6>


                  <h6 className="text-muted mb-3 h5">Email :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                      {/* {data.email} */}
                    </span>
                  </h6>

                  <h6 className="text-muted mb-3 h5">Provider :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                      {/* {data.provider} */}
                    </span>
                  </h6>

                  <h6 className="text-muted mb-3 h5">Product :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                      {/* {data.product} */}
                    </span>
                  </h6>

                  <h6 className="text-muted mb-3 h5">Status :
                          <span className="text-dark text-sm font-weight-300 ml-2">
                      {/* {data.remarks} */}
                    </span>
                  </h6>

                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
// const mapStateToProps = state => {
//   return {

//       dashClaimPartnerViewData: state.dashboard.dashClaimPartnerViewData,

//       dashClaimPartnerViewLoading : state.dashboard.dashClaimPartnerViewLoading,

//       error: state.dashboard.error,
//   };
// };
export default Policy;