import * as actionTypes from '../actions/actionTypes/financeTypes';
import { updateObject } from '../utility';


const initialState = {
    error: null,

    payoutListLoading: false,
    payoutHistoryLoading: false,
    productListLoading: false,
    payoutStatusListLoading: false,
    payoutCardsLoading: false,

    payoutListData: null,
    totalRecords: null,
    payoutHistoryData: null,
    productListData: null,
    payoutStatusListData: null,
    payoutCardsData: null,
}


//START
const payoutListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        payoutListLoading: true
    });
};
const payoutHistoryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        payoutHistoryLoading: true,
        payoutHistoryData: null
    });
};
const payoutProductListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        productListLoading: true
    });
};
const payoutStatusListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        payoutStatusListLoading: true
    });
};
const payoutCardsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        payoutCardsLoading: true
    });
};

//SUCCESS
const payoutListSuccess = (state, action) => {
    return updateObject(state, {
        payoutListData: action.data,
        totalRecords: action.total,
        error: null,
        payoutListLoading: false
    });
};
const payoutHistorySuccess = (state, action) => {
    return updateObject(state, {
        payoutHistoryData: action.data,
        error: null,
        payoutHistoryLoading: false
    });
};
const payoutProductListSuccess = (state, action) => {
    return updateObject(state, {
        productListData: action.data,
        error: null,
        productListLoading: false
    });
};
const payoutStatusListSuccess = (state, action) => {
    return updateObject(state, {
        payoutStatusListData: action.data,
        error: null,
        payoutStatusListLoading: false
    });
};
const payoutCardsSuccess = (state, action) => {
    return updateObject(state, {
        payoutCardsData: action.data,
        error: null,
        payoutCardsLoading: false
    });
};

//FAIL
const PayoutListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        payoutListLoading: false
    });
};

const payoutHistoryFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        payoutHistoryLoading: false
    });
};
const payoutProductListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        productListLoading: false
    });
};
const payoutStatusListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        payoutStatusListLoading: false
    });
};
const payoutCardsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        payoutCardsLoading: false
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PAYOUT_LIST_START:
            return payoutListStart(state, action);

        case actionTypes.GET_PAYOUT_HISTORY_START:
            return payoutHistoryStart(state, action);

        case actionTypes.GET_PAYOUT_PRODUCT_FILTER_LIST_START:
            return payoutProductListStart(state, action);

        case actionTypes.GET_PAYOUT_STATUS_FILTER_LIST_START:
            return payoutStatusListStart(state, action);

        case actionTypes.GET_PAYOUT_CARDS_START:
            return payoutCardsStart(state, action);



        case actionTypes.GET_PAYOUT_LIST_SUCCESS:
            return payoutListSuccess(state, action);

        case actionTypes.GET_PAYOUT_HISTORY_SUCCESS:
            return payoutHistorySuccess(state, action);

        case actionTypes.GET_PAYOUT_PRODUCT_FILTER_LIST_SUCCESS:
            return payoutProductListSuccess(state, action);

        case actionTypes.GET_PAYOUT_STATUS_FILTER_LIST_SUCCESS:
            return payoutStatusListSuccess(state, action);

        case actionTypes.GET_PAYOUT_CARDS_SUCCESS:
            return payoutCardsSuccess(state, action);



        case actionTypes.GET_PAYOUT_LIST_FAIL:
            return PayoutListFail(state, action);

        case actionTypes.GET_PAYOUT_HISTORY_FAIL:
            return payoutHistoryFail(state, action);

        case actionTypes.GET_PAYOUT_PRODUCT_FILTER_LIST_FAIL:
            return payoutProductListFail(state, action);

        case actionTypes.GET_PAYOUT_STATUS_FILTER_LIST_FAIL:
            return payoutStatusListFail(state, action);

        case actionTypes.GET_PAYOUT_CARDS_FAIL:
            return payoutCardsFail(state, action);


        default:
            return state;
    }
};

export default reducer;

