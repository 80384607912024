
// DASHBOARD ACTION TYPES
export const GET_DASHBOARD_POLICIES_START = 'GET_DASHBOARD_POLICIES_START';
export const GET_DASHBOARD_LEADS_START = 'GET_DASHBOARD_LEADS_START';
export const GET_DASHBOARD_CLAIMS_START = 'GET_DASHBOARD_CLAIMS_START';
export const GET_DASHBOARD_REVINUE_START = 'GET_DASHBOARD_REVINUE_START';
export const GET_DASHBOARD_DEALERS_START = 'GET_DASHBOARD_DEALERS_START';
export const GET_DASHBOARD_POLICIES_LINEGRAPH_START = 'GET_DASHBOARD_POLICIES_LINEGRAPH_START';
export const GET_DASHBOARD_CLAIMS_BARCHART_START = 'GET_DASHBOARD_CLAIMS_BARCHART_START';
export const GET_DASHBOARD_PRODUCT_LIST_START = 'GET_DASHBOARD_PRODUCT_LIST_START';
export const GET_DASHBOARD_DEALER_LIST_START = 'GET_DASHBOARD_DEALER_LIST_START';
export const GET_DASHBOARD_CLAIMS_PIECHART_START = 'GET_DASHBOARD_CLAIMS_PIECHART_START';
export const GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_START = 'GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_START';
export const GET_DASHBOARD_PRODUCT_LIST_REVENUE_START = 'GET_DASHBOARD_PRODUCT_LIST_REVENUE_START';
export const GET_DASHBOARD_CLAIM_PARTNER_VIEW_START = 'GET_DASHBOARD_CLAIM_PARTNER_VIEW_START';


export const GET_DASHBOARD_POLICIES_SUCCESS = 'GET_DASHBOARD_POLICIES_SUCCESS';
export const GET_DASHBOARD_LEADS_SUCCESS = 'GET_DASHBOARD_LEADS_SUCCESS';
export const GET_DASHBOARD_CLAIMS_SUCCESS = 'GET_DASHBOARD_CLAIMS_SUCCESS';
export const GET_DASHBOARD_REVINUE_SUCCESS = 'GET_DASHBOARD_REVINUE_SUCCESS';
export const GET_DASHBOARD_DEALERS_SUCCESS = 'GET_DASHBOARD_DEALERS_SUCCESS';
export const GET_DASHBOARD_POLICIES_LINEGRAPH_SUCCESS = 'GET_DASHBOARD_POLICIES_LINEGRAPH_SUCCESS';
export const GET_DASHBOARD_CLAIMS_BARCHART_SUCCESS = 'GET_DASHBOARD_CLAIMS_BARCHART_SUCCESS';
export const GET_DASHBOARD_PRODUCT_LIST_SUCCESS = 'GET_DASHBOARD_PRODUCT_LIST_SUCCESS';
export const GET_DASHBOARD_DEALER_LIST_SUCCESS = 'GET_DASHBOARD_DEALER_LIST_SUCCESS';
export const GET_DASHBOARD_CLAIMS_PIECHART_SUCCESS = 'GET_DASHBOARD_CLAIMS_PIECHART_SUCCESS';
export const GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_SUCCESS = 'GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_SUCCESS';
export const GET_DASHBOARD_PRODUCT_LIST_REVENUE_SUCCESS = 'GET_DASHBOARD_PRODUCT_LIST_REVENUE_SUCCESS';
export const GET_DASHBOARD_CLAIM_PARTNER_VIEW_SUCCESS = 'GET_DASHBOARD_CLAIM_PARTNER_VIEW_SUCCESS';


export const GET_DASHBOARD_POLICIES_FAIL = 'GET_DASHBOARD_POLICIES_FAIL';
export const GET_DASHBOARD_LEADS_FAIL = 'GET_DASHBOARD_LEADS_FAIL';
export const GET_DASHBOARD_CLAIMS_FAIL = 'GET_DASHBOARD_CLAIMS_FAIL';
export const GET_DASHBOARD_REVINUE_FAIL = 'GET_DASHBOARD_REVINUE_FAIL';
export const GET_DASHBOARD_DEALERS_FAIL = 'GET_DASHBOARD_DEALERS_FAIL';
export const GET_DASHBOARD_POLICIES_LINEGRAPH_FAIL = 'GET_DASHBOARD_POLICIES_LINEGRAPH_FAIL';
export const GET_DASHBOARD_CLAIMS_BARCHART_FAIL = 'GET_DASHBOARD_CLAIMS_BARCHART_FAIL';
export const GET_DASHBOARD_PRODUCT_LIST_FAIL = 'GET_DASHBOARD_PRODUCT_LIST_FAIL';
export const GET_DASHBOARD_DEALER_LIST_FAIL = 'GET_DASHBOARD_DEALER_LIST_FAIL';
export const GET_DASHBOARD_CLAIMS_PIECHART_FAIL = 'GET_DASHBOARD_CLAIMS_PIECHART_FAIL';
export const GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_FAIL = 'GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_FAIL';
export const GET_DASHBOARD_PRODUCT_LIST_REVENUE_FAIL = 'GET_DASHBOARD_PRODUCT_LIST_REVENUE_FAIL';
export const GET_DASHBOARD_CLAIM_PARTNER_VIEW_FAIL = 'GET_DASHBOARD_CLAIM_PARTNER_VIEW_FAIL';
