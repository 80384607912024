import { Utils } from '../constants/utils';

export const Validators = {
  age: {
    required: 'Please enter age',
    valid: `Please enter age between ${Utils.limit.age.min} to ${Utils.limit.age.max}`
  },
  annual_income: {
    required: 'Please select annual income'
  },
  contact: {
    required: 'Please enter contact',
    valid: 'Please enter valid contact'
  },
  dob: {
    required: 'Please enter date of birth',
    valid: 'Please enter valid date of birth'
  },
  email: {
    required: 'Please enter email',
    valid: 'Please enter valid email'
  },
  gender: {
    required: 'Please select gender'
  },
  is_smoker: {
    required: 'Please select either you consume tobacco or not'
  },
  mobile: {
    required: 'Please enter mobile number',
    valid: 'Please enter valid mobile number'
  },
  name: {
    required: 'Please enter name',
    valid: 'Please enter the full name. If the name has only one word, then repeat it. Eg: Ravi Ravi'
  },
  nominee_email: {
    required: 'Please enter nominee email',
    valid: 'Please enter valid nominee email'
  },
  nominee_mobile: {
    required: 'Please enter nominee mobile number',
    valid: 'Please enter valid nominee mobile number'
  },
  occupation: {
    required: 'Please select occupation'
  },
  pincode: {
    required: 'Please enter pincode',
    valid: 'Please enter valid pincode',
    noData: 'No city and state found for this pincode'
  },
  addressLine1: {
    required: 'Please enter address line1'
  },
  dog_name: {
    required: 'Please enter dog name'
  },
  dog_gender: {
    required: 'Please select dog gender'
  },
  dog_breed: {
    required: 'Please select dog breed'
  },
  other_breed: {
    required: 'Please enter other breed name'
  },
  dog_age_year: {
    required: 'Please select dog age in year'
  },
  dog_age_month: {
    required: 'Please select dog age in month'
  },
  dog_mark: {
    required: 'Please enter dog unique identification mark'
  },
  spayed_castrated: {
    required: 'Please select any one'
  },
  defects_ailments: {
    required: 'Please select any one'
  },
  policy_number: {
    required: 'Please enter policy number'
  },
  payment_status: {
    required: 'Please select payment status'
  },
  premium: {
    required: 'Please enter policy premium'
  },
  vehicle_code: {
    required: 'Please enter vehicle code'
  },
  make: {
    required: 'Please enter make name'
  },
  model: {
    required: 'Please enter model name'
  },
  variant: {
    required: 'Please enter variant name'
  },
  carrying_capacity: {
    required: 'Please enter carrying capacity'
  },
  no_of_wheels: {
    required: 'Please enter number of wheels'
  },
  cubic_capacity: {
    required: 'Please enter cubic capacity'
  },
  fuel: {
    required: 'Please select fuel'
  },
  ex_showroom_price: {
    required: 'Please enter ex-showroom price'
  },
  search_vehicle_code: {
    required: 'Please enter vehicle code'
  },
  make_code: {
    required: 'Please enter make code'
  },
  model_code: {
    required: 'Please enter model code'
  },
  variant_code: {
    required: 'Please enter variant code'
  },
};