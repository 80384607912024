import React from 'react';
var Spinner = require('react-spinkit');

const spinner = () => (
    <div style={{textAlign: "center"}}>
        <Spinner name='ball-clip-rotate'  color="#da5e59"/>
    </div>
);

export default spinner;


