import {
  AbilityBuilder
} from '@casl/ability'


// let permissionArray = []
// userGroups.forEach(group => {
//   permissionArray.push({
//     groupName: group.groupName,
//     permissions: group.permissions.map(a => a.name.split('_')[a.name.split('_').length - 1])
//   })
// });

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item
  }
  return item.__type
}

export default AbilityBuilder.define({
  subjectName
}, can => {
  // permissionArray.forEach(element => {
  //   can(element.permissions, element.groupName)
  // });
  // can(['read', 'store'], 'Roles')
  // can(['update', 'delete'], 'Todo', { assignee: 'me' })
})