import React, { Component, Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import Loader from '../../../../components/CustomUi/Loader/Loader';
import AxiosReactClient from '../../../../utilities/AxiosRestClient'
import * as actions from '../../../../Store/actions';
import {
    Badge,
    Container,
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Col,
    CardHeader,
    CardTitle
} from "reactstrap";
class CreateConfig extends Component {
    state = {
        entityName: "",
        entityId: "",
        data: "",
        id:"",
        showLoader: false,
        notification: null,
        groupNameArray: [],
        showPermissions: false
    };

    ENTITY = this.props.match.params.entity;
    ENTITY_ID = this.props.match.params.entityId;
    CONFIG_ID = this.props.match.params.id;

    componentDidMount() {
        this.getConfig({entity:this.ENTITY,entity_id:this.ENTITY_ID})
    }

    getConfig =(query)=>{
         AxiosReactClient.getRequest('get_app_config', query).then(response => {
             if(response){
                 const {data} = response.data;
                 this.setState({
                     showLoader:false,
                     entityName:this.ENTITY,
                     entityId:this.ENTITY_ID,
                     id:this.CONFIG_ID,
                     data:JSON.stringify(data)!=="null"?JSON.stringify(data):""
                 })
             }
            }).catch(err => {
                if (err.response) {
                        this.showMsg(err?.response?.data?.message, "danger")
                    } else if (err.message) {
                        this.showMsg(err.message, "danger")
                    }
            });
    }

    submitConfigData = () => {
        if (this.state.entityId.trim() === '') {
            this.showMsg("Please enter entity Id", "danger");
            return
        }
        if (isNaN(this.state.entityId)) {
            this.showMsg("Please enter valid entity Id", "danger");
            return
        }
        if (this.state.entityName.trim() === '') {
            this.showMsg("Please enter entity name", "danger");
            return
        }
        if (this.state.data === '') {
            this.showMsg("Please enter data", "danger");
            return
        }
        if (!this.IsJsonString(this.state.data)) {
            this.showMsg("Please enter valid data", "danger");
            return
        }
        this.setState({
            showLoader: true
        })
 
        AxiosReactClient.postRequest('update_app_config/'+this.CONFIG_ID,
            {
                entity: this.state.entityName,
                entity_id: this.state.entityId,
                data: JSON.parse(this.state.data),
            }).then(response => {
                this.setState({
                    showLoader: false
                })
                this.showMsg("Config updated successfully", "success")
                this.props.history.push('/admin/config')
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                })
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                }
                else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }

    IsJsonString(str) {
        try {
            var json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (e) {
            return false;
        }
    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    }

    render() {
        this.rolesList = this.props.rolesData ? this.props.rolesData.roles : []
        if (!this.props.permissionsDataLoading && this.props.permissionsData) {
            this.permissionObjectArray = [].concat(this.props.permissionsData.permissions);
        }

        return (
            <Fragment>
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="4" xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-2"
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-user" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Edit Config
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="8" xs="6">
                            </Col>
                        </Row>
                    </div>
                    <Row className="m-0 p-0">
                        <Col className="p-2" xl="12">
                            <Card >
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Edit Config</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="position-relative">
                                    {this.state.showLoader && <Loader msg={"Loading"} />}
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="entityName"
                                                        >
                                                            Entity
                                                        </label>
                                                        <Input
                                                            id="entityName"
                                                            value={this.state.entityName}
                                                            type="text"
                                                            onChange={e => {
                                                                this.setState({
                                                                    entityName: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="entityId"
                                                        >
                                                            Entity Id
                                                        </label>
                                                        <Input
                                                            id="entityId"
                                                            value={this.state.entityId}
                                                            onChange={e => {
                                                                this.setState({
                                                                    entityId: e.target.value
                                                                })
                                                            }}
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="data"
                                                        >
                                                            Data
                                                        </label>
                                                        <Input
                                                            id="data"
                                                            value={this.state.data}
                                                            type="textarea"
                                                            rows={8}
                                                            onChange={e => {
                                                                this.setState({
                                                                        data: e.target.value
                                                                    })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                    <div className="text-right">
                                        <Button
                                            color="primary"
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.submitConfigData()
                                            }}
                                            size="mds"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}



const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetConfigIdData: (query) => dispatch(actions.getConfigIdData(query)),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateConfig);