import passwordReset from "./PasswordReset";


const forgotPasswordRoute = {
    path: "/password/reset/:token",
    name: "password-reset",
    component: passwordReset,
    layout: "/auth",
    invisible: true,
  };
export default forgotPasswordRoute;