import * as actionTypes from '../actions/actionTypes/invoiceTypes';
import { updateObject } from '../utility';


const initialState = {
    error: null,
    invoiceListLoading: false,
    invoiceListData: null,
    totalRecords: null,
}


//START
const invoiceListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        invoiceListLoading: true
    });
};

//SUCCESS
const invoiceListSuccess = (state, action) => {
    return updateObject(state, {
        invoiceListData: action.data,
        totalRecords: action.total,
        error: null,
        invoiceListLoading: false
    });
};

//FAIL
const invoiceListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        invoiceListLoading: false
    });
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INVOICE_LIST_START:
            return invoiceListStart(state, action);

        case actionTypes.GET_INVOICE_LIST_SUCCESS:
            return invoiceListSuccess(state, action);

        case actionTypes.GET_INVOICE_LIST_FAIL:
            return invoiceListFail(state, action);

        default:
            return state;
    }
};

export default reducer;

