import * as actionTypes from '../actions/actionTypes/navbarTypes';
import { updateObject } from '../utility';


const initialState = {
    error: null,
    userDataLoading: false,
    userData: null,
}


//  START 
const navUserDataStart = (state, action) => {
    return updateObject(state, { error: null, userDataLoading: true });
};


//  SUCCESS 

const navUserDataSuccess = (state, action) => {
    return updateObject(state, {
        userData: action.data,
        error: null,
        userDataLoading: false
    });
};

//  FAIL 

const navUserDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        userDataLoading: false
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_DATA_START: return navUserDataStart(state, action);
        case actionTypes.GET_USER_DATA_SUCCESS: return navUserDataSuccess(state, action);
        case actionTypes.GET_USER_DATA_FAIL: return navUserDataFail(state, action);
        default:
            return state;
    }
};

export default reducer;