
export const GET_PERMISSIONS_START = 'GET_PERMISSIONS_START';
export const GET_ROLES_START = 'GET_ROLES_START';
export const GET_ROLE_ID_START = 'GET_ROLE_ID_START';
export const POST_ROLES_START = 'POST_ROLES_START';
export const PUT_ROLES_START = 'PUT_ROLES_START';
export const DELETE_ROLE_START = 'DELETE_ROLE_START';
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_INFO_START = 'GET_USERS_INFO_START';
export const GET_USERS_PERMISSIONS_START = 'GET_USERS_PERMISSIONS_START';
export const GET_CONFIG_START = 'GET_CONFIG_START';
export const GET_CONFIG_ID_START = 'GET_CONFIG_ID_START';
export const CREATE_CONFIG_START = 'CREATE_CONFIG_START';
export const UPDATE_CONFIG_START = 'UPDATE_CONFIG_START';

export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLE_ID_SUCCESS = 'GET_ROLE_ID_SUCCESS';
export const POST_ROLES_SUCCESS = 'POST_ROLES_SUCCESS';
export const PUT_ROLES_SUCCESS = 'PUT_ROLES_SUCCESS';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_INFO_SUCCESS = 'GET_USERS_INFO_SUCCESS';
export const GET_USERS_PERMISSIONS_SUCCESS = 'GET_USERS_PERMISSIONS_SUCCESS';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS'
export const GET_CONFIG_ID_SUCCESS = 'GET_CONFIG_ID_SUCCESS'
export const CREATE_CONFIG_SUCCESS = 'GET_CONFIG_ID_SUCCESS'
export const UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS'


export const GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';
export const GET_ROLE_ID_FAIL = 'GET_ROLE_ID_FAIL';
export const POST_ROLES_FAIL = 'POST_ROLES_FAIL';
export const PUT_ROLES_FAIL = 'PUT_ROLES_FAIL';
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_USERS_INFO_FAIL = 'GET_USERS_INFO_FAIL';
export const GET_USERS_PERMISSIONS_FAIL = 'GET_USERS_PERMISSIONS_FAIL';
export const GET_CONFIG_FAIL = 'GET_CONFIG_FAIL';
export const GET_CONFIG_ID_FAIL = 'GET_CONFIG_ID_FAIL';
export const CREATE_CONFIG_FAIL = 'CREATE_CONFIG_FAIL';
export const UPDATE_CONFIG_FAIL = 'UPDATE_CONFIG_FAIL';