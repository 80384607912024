import * as actionTypes from '../actions/actionTypes/common';
import {
    updateObject
} from '../utility';


const initialState = {
    notification: null,


    productFilterLoading: false,
    partnerFilterLoading: false,

    productFilterData: null,
    partnerFilterData: null,
}


const showNotification = (state, action) => {
    return updateObject(state, {
        notification: action.notification,
    });
};


//  START 

const productFilterListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        productFilterLoading: true
    });
};
const partnerFilterListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        partnerFilterLoading: true
    });
};

//  SUCCESS

const productFilterListSuccess = (state, action) => {
    return updateObject(state, {
        productFilterData: action.data,
        error: null,
        productFilterLoading: false
    });
};
const partnerFilterListSuccess = (state, action) => {
    return updateObject(state, {
        partnerFilterData: action.data,
        error: null,
        partnerFilterLoading: false
    });
};

//  FAIL

const productFilterListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        productFilterLoading: false
    });
};
const partnerFilterListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        partnerFilterLoading: false
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_TOAST:
            return showNotification(state, action);

        case actionTypes.GET_PRODUCT_FILTER_LIST_START:
            return productFilterListStart(state, action);
        case actionTypes.GET_PARTNER_FILTER_LIST_START:
            return partnerFilterListStart(state, action);

        case actionTypes.GET_PRODUCT_FILTER_LIST_SUCCESS:
            return productFilterListSuccess(state, action);
        case actionTypes.GET_PARTNER_FILTER_LIST_SUCCESS:
            return partnerFilterListSuccess(state, action);

        case actionTypes.GET_PRODUCT_FILTER_LIST_FAIL:
            return productFilterListFail(state, action);
        case actionTypes.GET_PARTNER_FILTER_LIST_FAIL:
            return partnerFilterListFail(state, action);


        default:
            return state;
    }
};

export default reducer;