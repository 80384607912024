import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import * as actions from '../../../Store/actions';
import Select2 from "react-select2-wrapper";
import IssuePolicyInputs from "../../../components/IssuePolicyInputs/IssuePolicyInputs";
import AxiosReactClient from '../../../utilities/AxiosRestClient';
import Loader from '../../../components/CustomUi/Loader/Loader';
import Resizer from 'react-image-file-resizer';
import {
    Breadcrumb,
    BreadcrumbItem,
    CardHeader,
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Card,
    CardBody,
    Button,
    CardFooter,
} from "reactstrap";

const contact_regx = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const email_regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

class IssuePolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dealerValue: '',
            selectedDealer: '',
            selectedProduct: 22,
            selectedState: '',
            isCalculating: true,
            premium: null,
            isIssuing: true,
            loaderMsg: 'Updating',
            whatsappCheck: true,
            address_enable: true,
            selectedBrandInput: '',
            selectedInvoiceDate: '',
            customBrandInput: '',
            selectedBrandName: '',
            showBuyFlow: false,
            showButton: true,
            is_existing_cycle: '',
        }
    }

    selectedBrand = '';
    isCalculating = true;
    isIssuing = false;
    inputs = {};
    selectedInputs = {};
    formData = new FormData();

    componentDidMount() {
        this.props.onGetPolicyDealerListData();
        this.props.onGetPolicyProductListData();
    }

    onProductChange = (e) => {
        this.setState({
            selectedProduct: e.target.value
        })
    };
    onBrandChange = (e) => {
        if (this.state.selectedBrandInput !== '') {
            this.setState({
                selectedBrandInput: e.target.value,
                selectedBrandName: e.target.option
            }, () => {
                this.onPostProductRemap({
                    product_id: this.state.selectedProduct,
                    response_brand_id: this.state.selectedBrandInput,
                    response_custom_brand: this.state.customBrandInput,
                    response_invoice_date: this.state.selectedInvoiceDate
                })
            });
            return;
        }
        this.setState({
            selectedBrandInput: e.target.value,
            selectedBrandName: e.target.option
        })
    };
    onDealerChange = (e) => {
        this.setState({
            selectedDealer: e.target.value,
            showButton: true
        });
        this.props.onGetPolicyDealerCreditData(this.state.selectedDealer);
        this.props.onGetPolicyBrandsData(this.state.selectedDealer)
    };
    getInputData = (data) => {
        this.props.onSetCustomBrandVisibility(data);
        this.inputs[data.name] = data.value;
        this.inputs['state'] && this.props.onGetPolicyCityListData(this.inputs['state']);
        if (data.value.type) {
            Resizer.imageFileResizer(
                data.value,
                1024,
                1024,
                'PNG',
                90,
                0,
                uri => {
                    this.formData.delete(data.name);
                    this.formData.append(data.name, uri);
                },
                'blob'
            );
        } else {
            this.formData.delete(data.name);
            this.formData.append(data.name, data.value);
        }
    };
    calculateButton = () => {
        if (!this.inputs['title']) {
            this.showMsg("Please select user title", "danger");
            return
        }
        if (!this.inputs['name']) {
            this.showMsg("Please enter user name", "danger");
            return
        }
        if (!this.inputs['email']) {
            this.showMsg("Please enter user email", "danger");
            return
        }
        if (!email_regx.test(this.inputs['email'])) {
            this.showMsg("Please enter a valid email", 'danger');
            return
        }
        if (!this.inputs['mobile']) {
            this.showMsg("Please enter contact number", 'danger');
            return
        }
        if (!contact_regx.test(this.inputs['mobile'])) {
            this.showMsg("Please enter 10 digit number only", 'danger');
            return
        }
        if (!this.inputs['dob']) {
            this.showMsg("Please enter user date of birth", "danger");
            return
        }

        if (!this.inputs['response_model']) {
            this.showMsg("Please enter cycle model", "danger");
            return
        }
        if (!this.inputs['response_color']) {
            this.showMsg("Please select cycle color", "danger");
            return
        }
        if (!this.inputs['response_invoice_amount']) {
            this.showMsg("Please enter invoice amount", "danger");
            return
        }
        if (!this.inputs['response_invoice_number']) {
            this.showMsg("Please enter invoice number", "danger");
            return
        }

        if (!this.inputs['invoice']) {
            this.showMsg("Please upload invoice photo", "danger");
            return
        }
        
        const data = new FormData();
        Object.keys(this.inputs).forEach((key) => {
            data.append(key, this.inputs[key]);
        });
        data.append('response_brand_id', this.state.selectedBrandInput);
        data.append('response_custom_brand', this.state.customBrandInput);
        data.append('response_invoice_date', this.state.selectedInvoiceDate);
        this.getPremiumData(this.state.selectedProduct, this.state.selectedDealer, data)
    };
    getPremiumData = (id, dealer_id, payload) => {
        this.setState({
            loaderMsg: "Calculating Premium",
            showLoader: true
        });
        AxiosReactClient.postRequest('products/' + id + '/' + dealer_id + '/getPremium', payload).then(response => {
            this.setState({
                showLoader: false,
                premium: response.data.data.premium,
                isCalculating: false
            });
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    Object.values(err.response.data.data["error_msg"]).forEach(element => {
                    this.showMsg(element[0], "danger")                        
                    });
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    issueButton = () => {
        this.formData.append('response_brand_id', this.state.selectedBrandInput);
        this.formData.append('response_custom_brand', this.state.customBrandInput);
        this.formData.append('response_invoice_date', this.state.selectedInvoiceDate);
        this.formData.append('whatsapp_consent', this.state.whatsappCheck);
        this.formData.append('is_existing_cycle', this.state.is_existing_cycle);
        this.sendPolicyData(this.state.selectedProduct, this.state.selectedDealer, this.formData)
    };
    sendPolicyData = (id, dealer_id, payload) => {
        this.setState({
            loaderMsg: "Issuing Policy",
            showLoader: true
        });
        AxiosReactClient.postRequest('products/' + id + '/' + dealer_id + '/issue', payload).then(response => {
            this.setState({
                showLoader: false,
            });
            this.showMsg("Policy Issued successfully", "success");
            this.setState({
                isCalculating: true,
                selectedProduct: 22,
                selectedDealer: '',
                selectedBrandInput: '',
                customBrandInput: '',
                showBuyFlow: false
            });
            this.inputs = {};
            this.props.onGetPolicyDealerListData();
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    onWhatsappCheck = (e) => {
        this.setState({
            whatsappCheck: e.target.checked
        })
    };
    onChangeDate = (e) => {
        let tmp = e.target.value.split('-').reverse().join('/');
        this.setState({
            selectedInvoiceDate: tmp
        })
    };
    onCustomBrandChange = (e) => {
        this.setState({
            customBrandInput: e.target.value
        })
    };
    onClickGetBuyFlow = (custom_brand, brand) => {
        this.setState({
            showBuyFlow: true,
            customBrandInput: custom_brand,
            selectedBrandInput: brand,
            showButton: false
        }, () => {
            this.onPostProductRemap({
                product_id: this.state.selectedProduct,
                response_brand_id: this.state.selectedBrandInput,
                response_custom_brand: this.state.customBrandInput,
                response_invoice_date: this.state.selectedInvoiceDate
            })
        })
    };
    onPostProductRemap = (payload) => {
        this.setState({
            loaderMsg: "Please Wait",
            showLoader: true
        });
        AxiosReactClient.postRequest('products/productRemap', payload).then(response => {
            this.setState({
                showLoader: false,
                selectedProduct: response.data.product_id,
                selectedBrandInput: response.data.response_brand_id,
                customBrandInput: response.data.response_custom_brand,
                selectedInvoiceDate: response.data.response_invoice_date,
                is_existing_cycle: response.data.is_existing_cycle,
            }, () => {
                this.props.onGetPolicyInputsData(this.state.selectedProduct, this.state.selectedDealer)
            })
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };

    render() {
        const {policyInputsData, policyInputsLoading, policyProductListData} = this.props;
        return (
            <Fragment>
                <Container fluid>
                    {this.state.showLoader && <div className="loader-container">
                        <Loader msg={this.state.loaderMsg}/>
                    </div>}
                    <CardHeader className="border-0 bg-transparent px-0">
                        <Row>
                            <Col lg="8" xs="6" className="mt-0 m-auto">
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-0"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" className="text-primary" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-file"/>
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" className="text-primary font-weight-400"
                                           onClick={e => e.preventDefault()}>
                                            Policies
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" className="text-primary font-weight-400"
                                           onClick={this.clicked}>
                                            Issue Policy
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="4" xs="6">

                            </Col>
                        </Row>
                    </CardHeader>
                    <Card>
                        <CardHeader>
                            <label className=" form-control-label">
                                Dealer
                            </label>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <label className=" form-control-label"
                                       htmlFor="dealer">
                                    Dealer Name / Mobile
                                </label>
                                <Select2
                                    className="form-control"
                                    options={{
                                        placeholder: "Search"
                                    }}
                                    value={this.state.selectedDealer}
                                    data={this.props.policyDealerListData ? this.props.policyDealerListData.map(({id, dealership_name, mobile, type}) => {
                                        return {id: id, text: dealership_name + ' - ' + mobile + ' (' + type + ')'}
                                    }) : []}
                                    onChange={this.onDealerChange}
                                />
                            </FormGroup>
                            {this.state.selectedDealer &&
                            <label className="form-control-label "
                                   htmlFor="dealer">
                                Available Credit
                                : {(this.props.policyDealerCreditData && this.props.policyDealerCreditData.is_avaialble) ? this.props.policyDealerCreditData.is_avaialble : '0'}
                            </label>
                            }
                        </CardBody>
                    </Card>
                    {this.state.selectedDealer && !(this.props.policyDealerCreditData && this.props.policyDealerCreditData.is_avaialble === false) && <>
                        <Card>
                            <CardHeader>
                                <label className=" form-control-label">
                                    Product
                                </label>
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <label className=" form-control-label"
                                           htmlFor="dealer">
                                        Select Product
                                    </label>
                                    <Input
                                        id=''
                                        type='select'
                                        placeholder="Select Product"
                                        required
                                        defaultValue={this.state.selectedProduct}
                                        onChange={this.onProductChange}
                                    >
                                        <option value='' disabled>Choose here</option>
                                        {policyProductListData && policyProductListData.map(element => {
                                            return <option key={element.id} value={element.id}
                                                           disabled={element.id !== 22 && 'disabled'}>
                                                {element.name}
                                            </option>
                                        })}
                                    </Input>
                                </FormGroup>
                            </CardBody>
                        </Card>
                        {/* Brand selection and invoice date */}
                        <Card>
                            <CardHeader>
                                <label className=" form-control-label">
                                    Brand
                                </label>
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <label className=" form-control-label"
                                           htmlFor="dealer">
                                        Select Brand
                                    </label>
                                    <Input
                                        id=''
                                        type='select'
                                        placeholder="Select Brand"
                                        required
                                        value={this.state.selectedBrandInput}
                                        onChange={this.onBrandChange}
                                    >
                                        <option value='' disabled>Choose here</option>
                                        {!this.props.brandListLoading && this.props.brandListData && this.props.brandListData.map(element => {
                                            return <option key={element.id} value={element.id}>
                                                {element.name}
                                            </option>
                                        })}
                                    </Input>
                                </FormGroup>
                                {this.state.selectedBrandInput == 23 &&
                                <FormGroup>
                                    <label className=" form-control-label"
                                           htmlFor="dealer">
                                        Custom Brand
                                    </label>
                                    <Input
                                        id="custom"
                                        type='text'
                                        placeholder=""
                                        value={this.state.customBrandInput}
                                        onChange={e => this.onCustomBrandChange(e)}
                                    />
                                </FormGroup>
                                }
                                <FormGroup>
                                    <label className=" form-control-label"
                                           htmlFor="dealer">
                                        Select Invoice Date
                                    </label>
                                    <Input
                                        id="1"
                                        type='date'
                                        required={this.props.required && this.props.required}
                                        onChange={e => this.onChangeDate(e)}
                                    />
                                </FormGroup>
                            </CardBody>
                            {this.state.showButton &&
                            <CardFooter>
                                <Button block color="primary" size="lg" type="button" onClick={e => {
                                    e.preventDefault();
                                    this.onClickGetBuyFlow(this.state.customBrandInput, this.state.selectedBrandInput)
                                }}>
                                    Next
                                </Button>
                            </CardFooter>
                            }
                        </Card> </>}
                    {this.state.showBuyFlow &&
                    (!policyInputsLoading && policyInputsData && policyInputsData.map((buyer, index) => {
                        return <div key={Math.random(100) + index}>
                            {((this.state.selectedProduct === 19) || (this.state.selectedProduct === 22) || (this.state.selectedProduct === 31) || (this.state.selectedProduct === 40) ? (buyer.inputs[0] && buyer.inputs[0].type !== "pincode" && buyer.inputs[0] && buyer.inputs[0].name !== "address_line_1") : this.state.address_enable) &&
                            <Card>
                                <CardHeader>
                                    <label className=" form-control-label">
                                        {buyer.body}
                                    </label>
                                </CardHeader>
                                <CardBody>
                                    {buyer.inputs.map((buyer, index) => {
                                        return <div key={Math.random(100) + index}>
                                            <IssuePolicyInputs inputs={this.inputs}
                                                               getInputData={data => this.getInputData(data)}
                                                               d_name={buyer.display_name} data={buyer} id={buyer.id}
                                                               type={buyer.type} placeholder={buyer.placeholder}
                                                               required={buyer.validations}
                                                               product={this.state.selectedProduct}
                                                               brand={this.state.selectedBrandInput}
                                                               invoice={this.state.selectedInvoiceDate}
                                                               customBrand={this.state.customBrandInput}/>
                                        </div>
                                    })}
                                </CardBody>
                            </Card>}
                        </div>
                    }))
                    }
                    {this.state.showBuyFlow &&
                    <Card>
                        <CardBody>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="whatsapp"
                                    type="checkbox"
                                    checked={this.state.whatsappCheck}
                                    onChange={e => {
                                        this.onWhatsappCheck(e)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor="whatsapp">
                                    Notify Me over whatsapp
                                </label>
                            </div>
                        </CardBody>
                    </Card>
                    }
                    {!this.state.isCalculating &&
                    <Card>
                        <CardBody className="text-center">
                            <label className=" form-control-label text-success">
                                Your Premium for the Policy is {this.state.premium}
                            </label>
                        </CardBody>
                    </Card>
                    }
                    {this.state.showBuyFlow &&
                    <FormGroup>
                        {this.state.isCalculating &&
                        <Button block color="primary" size="lg" type="button" onClick={e => {
                            e.preventDefault();
                            this.calculateButton()
                        }}>
                            Calculate Premium
                        </Button>}

                        {!this.state.isCalculating &&
                        <Button block color="primary" size="lg" type="button" onClick={e => {
                            e.preventDefault();
                            this.issueButton()
                        }}>
                            Issue Policy
                        </Button>
                        }
                    </FormGroup>
                    }
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        policyInputsLoading: state.policies.policyInputsLoading,
        policyProductListLoading: state.policies.policyProductListLoading,
        policyDealerListLoading: state.policies.policyDealerListLoading,
        policyDealerCreditLoading: state.policies.policyDealerCreditLoading,
        brandListLoading: state.policies.policyBrandsListLoading,

        policyInputsData: state.policies.policyInputsData,
        policyProductListData: state.policies.policyProductListData,
        policyDealerListData: state.policies.policyDealerListData,
        policyDealerCreditData: state.policies.policyDealerCreditData,
        brandListData: state.policies.policyBrandsListData,

        showCustomBrands: state.policies.showCustomBrands
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onGetPolicyInputsData: (id, dealer_id) => dispatch(actions.getPolicyInputsData(id, dealer_id)),
        onGetPolicyProductListData: (data) => dispatch(actions.getPolicyProductListData(data)),
        onGetPolicyDealerListData: (data) => dispatch(actions.getPolicyDealerListData(data)),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetPolicyCityListData: (id) => dispatch(actions.getPolicyCityListData(id)),
        onGetPolicyDealerCreditData: (id) => dispatch(actions.getPolicyDealerCreditData(id)),
        onSetCustomBrandVisibility: (data) => dispatch(actions.onSetCustomBrandVisibility(data)),
        onGetPolicyBrandsData: (id) => dispatch(actions.getPolicyBrandsData(id)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(IssuePolicy);