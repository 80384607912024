import React, { Component, Fragment } from 'react';
import ReactDatetime from "react-datetime";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Container,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Progress,
    Modal,
} from "reactstrap";


export default class Reports extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }
    handleItemClick = (key) => {
        const singleRow = this.state.itemData.filter(arr => arr.key === key)[0];
        this.setState({ singleRow });
        //   console.log(this.state, key, typeof key);
    }
    handleChange = (event) => {
        this.setState({ period: event.target.value });
    }
    handleReactDatetimeChange = (who, date) => {
        if (
            this.state.startDate &&
            who === "endDate" &&
            new Date(this.state.startDate._d + "") > new Date(date._d + "")
        ) {
            this.setState({
                startDate: date,
                endDate: date
            });
        } else if (
            this.state.endDate &&
            who === "startDate" &&
            new Date(this.state.endDate._d + "") < new Date(date._d + "")
        ) {
            this.setState({
                startDate: date,
                endDate: date
            });
        } else {
            this.setState({
                [who]: date
            });
        }
    };
    // this function adds on the day tag of the date picker
    // middle-date className which means that this day will have no border radius
    // start-date className which means that this day will only have left border radius
    // end-date className which means that this day will only have right border radius
    // this way, the selected dates will look nice and will only be rounded at the ends
    getClassNameReactDatetimeDays = date => {
        if (this.state.startDate && this.state.endDate) {
        }
        if (
            this.state.startDate &&
            this.state.endDate &&
            this.state.startDate._d + "" !== this.state.endDate._d + ""
        ) {
            if (
                new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
                new Date(this.state.startDate._d + "") < new Date(date._d + "")
            ) {
                return " middle-date";
            }
            if (this.state.endDate._d + "" === date._d + "") {
                return " end-date";
            }
            if (this.state.startDate._d + "" === date._d + "") {
                return " start-date";
            }
        }
        return "";
    };


    render() {
        // const { reportItems } = this.state;

        // console.log(period, typeof period);

        return (
            <Fragment>
                <Container fluid>
                    <CardHeader className="border-0 bg-transparent pl-0">

                        <Breadcrumb
                            className="d-none d-md-inline-block ml-md-0"
                            listClassName="breadcrumb-links breadcrumb-dark"
                        >
                            <BreadcrumbItem>
                                <a href="#pablo" className="text-primary" onClick={e => e.preventDefault()}>
                                    <i className="fas fa-home" />
                                </a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="#pablo" className="text-primary h5" onClick={this.clicked}>
                                    Policies
                      </a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="#pablo" className="text-primary h5" onClick={this.clicked}>
                                    Reports
                      </a>
                            </BreadcrumbItem>

                        </Breadcrumb>
                    </CardHeader>
                    <Card>
                        <Row className="no-gutters">
                            <Col lg="3" className="border-0 text-center ">
                                <div className="d-flex align-items-center p-3 justify-content-between cursor-pointer report-item">
                                    EDI <span><i className="fa fa-caret-right" aria-hidden="true"></i></span>
                                </div>
                            </Col>
                            <Col lg="9" className="border-left border-right">
                                <CardBody className="pb-0">
                                    <CardTitle className="mb-3" tag="h3">
                                        EDI
                                            </CardTitle>

                                    <hr className="mt-3 mb-3" />
                                    <Form>
                                        <Row className=" align-items-center ">

                                            <Col xs={12} sm={6}>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="exampleFormControlSelect3">
                                                        Provider
                                                            </label>
                                                    <Input id="exampleFormControlSelect3" type="select" name="period" >
                                                        <option value="a">Provider A</option>
                                                        <option value="b">B</option>
                                                        <option value="c">C</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="exampleFormControlSelect3">
                                                        Product
                                                            </label>
                                                    <Input id="exampleFormControlSelect3" type="select" name="period" >
                                                        <option value="a">Product A</option>
                                                        <option value="b">B</option>
                                                        <option value="c">C</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <label className=" form-control-label">
                                                    Start date
                                                                            </label>
                                                <FormGroup>
                                                    <ReactDatetime
                                                        inputProps={{
                                                            placeholder: "Pick A Start Date"
                                                        }}
                                                        value={this.state.startDate}
                                                        timeFormat={false}
                                                        onChange={e =>
                                                            this.handleReactDatetimeChange("startDate", e)
                                                        }
                                                        renderDay={(props, currentDate, selectedDate) => {
                                                            let classes = props.className;
                                                            classes += this.getClassNameReactDatetimeDays(
                                                                currentDate
                                                            );
                                                            return (
                                                                <td {...props} className={classes}>
                                                                    {currentDate.date()}
                                                                </td>
                                                            );
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <FormGroup>
                                                    <label className=" form-control-label">
                                                        End date
                                                                                    </label>
                                                    <ReactDatetime
                                                        inputProps={{
                                                            placeholder: "Pick A End Date"
                                                        }}
                                                        value={this.state.endDate}
                                                        timeFormat={false}
                                                        onChange={e =>
                                                            this.handleReactDatetimeChange("endDate", e)
                                                        }
                                                        renderDay={(props, currentDate, selectedDate) => {
                                                            let classes = props.className;
                                                            classes += this.getClassNameReactDatetimeDays(
                                                                currentDate
                                                            );
                                                            return (
                                                                <td {...props} className={classes}>
                                                                    {currentDate.date()}
                                                                </td>
                                                            );
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="exampleFormControlSelect3"
                                                    >
                                                        Select File Format
                                                            </label>
                                                    <Input id="exampleFormControlSelect3" type="select" name="format" >

                                                        <option value="CSV">CSV</option>
                                                        <option value="XLS">XLS</option>
                                                        <option value="XLSX">XLSX</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6}>

                                                <Button color="danger" size="sm" type="button">
                                                    Clear
                                                                    </Button>
                                            </Col>

                                        </Row>
                                    </Form>

                                    <Row>
                                        <Col lg="12" className="text-right">
                                            <Button color="primary" size="sm" type="button">
                                                Download Report
                                                                    </Button>

                                            <Button color="secondary" size="sm" type="button"

                                                onClick={e => {
                                                    this.setState({
                                                        showEmailModel: true
                                                    })
                                                }}
                                            >
                                                Email Report
                                                                    </Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col lg="8">
                                            <div className="progress-wrapper">
                                                <div className="progress-info">

                                                    <h5>Generating EDI Report For Oct'19</h5>

                                                    <div className="progress-percentage ">

                                                        <span><i className="ni ni-fat-remove"></i></span>
                                                    </div>
                                                </div>
                                                <Progress max="100" value="90" color="success" />
                                            </div>
                                        </Col>
                                        <hr />
                                    </Row>


                                    <Modal
                                        className="modal-dialog-centered"
                                        size="sm"
                                        isOpen={this.state.showEmailModel}
                                        toggle={() => {
                                            this.setState({
                                                showEmailModel: !this.state.showEmailModel
                                            })
                                        }}
                                    >
                                        <div className="modal-body">

                                            <Form role="form">

                                                <Row className="input-daterange datepicker align-items-center">
                                                    <Col >
                                                        <FormGroup>
                                                            <label className=" form-control-label"
                                                                htmlFor="amountInput">
                                                                Email Addresses
                                                            </label>

                                                            <Input
                                                                type="text"
                                                                placeholder="example@example.com"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>

                                        <div className="modal-footer date-modal-footer ">
                                            <Button
                                                color="link"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={() => {
                                                    this.setState({
                                                        showEmailModel: false
                                                    })
                                                }}
                                            >
                                                Close</Button>
                                            <Button color="primary"
                                                className="ml-auto" type="button"
                                                size="sm"
                                                onClick={e => {
                                                    this.setState({
                                                        showEmailModel: false
                                                    })
                                                }}
                                            >
                                                Send Email</Button>
                                        </div>
                                    </Modal>

                                </CardBody>
                            </Col>
                        </Row>
                    </Card>

                </Container>
            </Fragment>
        );
    }
}