export class IndiaFirstLocation {
    get(pincode) {
        return this.code[pincode];
    }

    code = {
        110099: ['North West Delhi','Delhi'],
        123001: ['Mahendragarh','Haryana'],
        123021: ['Mahendragarh','Haryana'],
        123023: ['Mahendragarh','Haryana'],
        123024: ['Mahendragarh','Haryana'],
        123027: ['Mahendragarh','Haryana'],
        123028: ['Mahendragarh','Haryana'],
        123029: ['Mahendragarh','Haryana'],
        123034: ['Mahendragarh','Haryana'],
        123035: ['Rewari','Haryana'],
        123101: ['Rewari','Haryana'],
        123102: ['Rewari','Haryana'],
        123103: ['Rewari','Haryana'],
        123110: ['Rewari','Haryana'],
        123301: ['Rewari','Haryana'],
        123302: ['Rewari','Haryana'],
        123303: ['Rewari','Haryana'],
        123401: ['Rewari','Haryana'],
        123411: ['Rewari','Haryana'],
        123412: ['Rewari','Haryana'],
        123501: ['Rewari','Haryana'],
        124001: ['Rohtak','Haryana'],
        124010: ['Rohtak','Haryana'],
        124021: ['Rohtak','Haryana'],
        124022: ['Rohtak','Haryana'],
        124102: ['Jhajjar','Haryana'],
        124103: ['Jhajjar','Haryana'],
        124104: ['Jhajjar','Haryana'],
        124105: ['Jhajjar','Haryana'],
        124106: ['Jhajjar','Haryana'],
        124107: ['Jhajjar','Haryana'],
        124108: ['Rohtak','Haryana'],
        124109: ['Jhajjar','Haryana'],
        124111: ['Rohtak','Haryana'],
        124112: ['Rohtak','Haryana'],
        124113: ['Rohtak','Haryana'],
        124141: ['Jhajjar','Haryana'],
        124142: ['Jhajjar','Haryana'],
        124146: ['Jhajjar','Haryana'],
        124201: ['Jhajjar','Haryana'],
        124202: ['Jhajjar','Haryana'],
        124303: ['Rohtak','Haryana'],
        124401: ['Rohtak','Haryana'],
        124404: ['Jhajjar','Haryana'],
        124406: ['Rohtak','Haryana'],
        124411: ['Rohtak','Haryana'],
        124412: ['Rohtak','Haryana'],
        124501: ['Rohtak','Haryana'],
        124504: ['Jhajjar','Haryana'],
        124505: ['Jhajjar','Haryana'],
        124506: ['Jhajjar','Haryana'],
        124507: ['Jhajjar','Haryana'],
        124508: ['Jhajjar','Haryana'],
        124513: ['Rohtak','Haryana'],
        124514: ['Rohtak','Haryana'],
        125001: ['Hisar','Haryana'],
        125004: ['Hisar','Haryana'],
        125005: ['Hisar','Haryana'],
        125006: ['Hisar','Haryana'],
        125007: ['Hisar','Haryana'],
        125011: ['Hisar','Haryana'],
        125033: ['Hisar','Haryana'],
        125037: ['Hisar','Haryana'],
        125038: ['Hisar','Haryana'],
        125039: ['Hisar','Haryana'],
        125042: ['Hisar','Haryana'],
        125044: ['Hisar','Haryana'],
        125047: ['Hisar','Haryana'],
        125048: ['Fatehabad','Haryana'],
        125049: ['Hisar','Haryana'],
        125050: ['Fatehabad','Haryana'],
        125051: ['Fatehabad','Haryana'],
        125052: ['Hisar','Haryana'],
        125053: ['Hisar','Haryana'],
        125054: ['Sirsa','Haryana'],
        125055: ['Sirsa','Haryana'],
        125056: ['Sirsa','Haryana'],
        125058: ['Sirsa','Haryana'],
        125060: ['Sirsa','Haryana'],
        125075: ['Sirsa','Haryana'],
        125076: ['Sirsa','Haryana'],
        125077: ['Sirsa','Haryana'],
        125078: ['Sirsa','Haryana'],
        125101: ['Sirsa','Haryana'],
        125102: ['Sirsa','Haryana'],
        125103: ['Sirsa','Haryana'],
        125104: ['Sirsa','Haryana'],
        125106: ['Fatehabad','Haryana'],
        125110: ['Sirsa','Haryana'],
        125111: ['Fatehabad','Haryana'],
        125112: ['Hisar','Haryana'],
        125113: ['Hisar','Haryana'],
        125120: ['Fatehabad','Haryana'],
        125121: ['Hisar','Haryana'],
        125133: ['Fatehabad','Haryana'],
        125201: ['Sirsa','Haryana'],
        126101: ['Jind','Haryana'],
        126102: ['Jind','Haryana'],
        126110: ['Jind','Haryana'],
        126111: ['Jind','Haryana'],
        126112: ['Jind','Haryana'],
        126113: ['Jind','Haryana'],
        126114: ['Karnal','Haryana'],
        126115: ['Jind','Haryana'],
        126116: ['Jind','Haryana'],
        126125: ['Jind','Haryana'],
        126152: ['Jind','Haryana'],
        127021: ['Bhiwani','Haryana'],
        127022: ['Bhiwani','Haryana'],
        127025: ['Bhiwani','Haryana'],
        127026: ['Bhiwani','Haryana'],
        127027: ['Bhiwani','Haryana'],
        127028: ['Bhiwani','Haryana'],
        127029: ['Bhiwani','Haryana'],
        127030: ['Bhiwani','Haryana'],
        127031: ['Bhiwani','Haryana'],
        127032: ['Bhiwani','Haryana'],
        127035: ['Bhiwani','Haryana'],
        127040: ['Bhiwani','Haryana'],
        127041: ['Bhiwani','Haryana'],
        127042: ['Bhiwani','Haryana'],
        127043: ['Bhiwani','Haryana'],
        127045: ['Bhiwani','Haryana'],
        127046: ['Bhiwani','Haryana'],
        127111: ['Bhiwani','Haryana'],
        127114: ['Bhiwani','Haryana'],
        127201: ['Bhiwani','Haryana'],
        127306: ['Bhiwani','Haryana'],
        127307: ['Bhiwani','Haryana'],
        127308: ['Bhiwani','Haryana'],
        127309: ['Bhiwani','Haryana'],
        127310: ['Bhiwani','Haryana'],
        131030: ['Sonipat','Haryana'],
        132102: ['Panipat','Haryana'],
        132106: ['Panipat','Haryana'],
        132107: ['Panipat','Haryana'],
        132122: ['Panipat','Haryana'],
        133103: ['Yamunanagar','Haryana'],
        135004: ['Yamunanagar','Haryana'],
        135106: ['Yamunanagar','Haryana'],
        135133: ['Yamunanagar','Haryana'],
        136020: ['Kurukshetra','Haryana'],
        136021: ['Kaithal','Haryana'],
        136026: ['Kurukshetra','Haryana'],
        136027: ['Kurukshetra','Haryana'],
        136033: ['Kaithal','Haryana'],
        136034: ['Kurukshetra','Haryana'],
        136035: ['Kurukshetra','Haryana'],
        136042: ['Kaithal','Haryana'],
        136043: ['Kaithal','Haryana'],
        136044: ['Kaithal','Haryana'],
        136117: ['Kaithal','Haryana'],
        136134: ['Kurukshetra','Haryana'],
        140001: ['Ropar','Punjab'],
        140101: ['Mohali','Punjab'],
        140102: ['Mohali','Punjab'],
        140108: ['Ropar','Punjab'],
        140111: ['Ropar','Punjab'],
        140113: ['Ropar','Punjab'],
        140114: ['Ropar','Punjab'],
        140115: ['Mohali','Punjab'],
        140116: ['Ropar','Punjab'],
        140117: ['Rupnagar','Punjab'],
        140118: ['Ropar','Punjab'],
        140123: ['Ropar','Punjab'],
        140124: ['Ropar','Punjab'],
        140126: ['Ropar','Punjab'],
        140127: ['Ropar','Punjab'],
        140128: ['Ropar','Punjab'],
        140133: ['Ropar','Punjab'],
        140301: ['Ropar','Punjab'],
        140306: ['Mohali','Punjab'],
        140308: ['Mohali','Punjab'],
        141801: ['Fatehgarh Sahib','Punjab'],
        142001: ['Moga','Punjab'],
        142002: ['Moga','Punjab'],
        142003: ['Moga','Punjab'],
        142011: ['Moga','Punjab'],
        142037: ['Moga','Punjab'],
        142038: ['Moga','Punjab'],
        142039: ['Moga','Punjab'],
        142040: ['Moga','Punjab'],
        142041: ['Firozpur','Punjab'],
        142042: ['Moga','Punjab'],
        142043: ['Moga','Punjab'],
        142044: ['Firozpur','Punjab'],
        142045: ['Moga','Punjab'],
        142046: ['Moga','Punjab'],
        142047: ['Firozpur','Punjab'],
        142048: ['Moga','Punjab'],
        142049: ['Moga','Punjab'],
        142050: ['Firozpur','Punjab'],
        142052: ['Firozpur','Punjab'],
        142053: ['Moga','Punjab'],
        142054: ['Moga','Punjab'],
        142055: ['Moga','Punjab'],
        142056: ['Moga','Punjab'],
        142057: ['Moga','Punjab'],
        142058: ['Moga','Punjab'],
        142060: ['Firozpur','Punjab'],
        143505: ['Gurdaspur','Punjab'],
        143506: ['Gurdaspur','Punjab'],
        143507: ['Gurdaspur','Punjab'],
        143511: ['Gurdaspur','Punjab'],
        143512: ['Gurdaspur','Punjab'],
        143513: ['Gurdaspur','Punjab'],
        143514: ['Gurdaspur','Punjab'],
        143515: ['Gurdaspur','Punjab'],
        143516: ['Gurdaspur','Punjab'],
        143517: ['Gurdaspur','Punjab'],
        143518: ['Gurdaspur','Punjab'],
        143519: ['Gurdaspur','Punjab'],
        143520: ['Gurdaspur','Punjab'],
        143521: ['Gurdaspur','Punjab'],
        143525: ['Pathankot','Punjab'],
        143526: ['Gurdaspur','Punjab'],
        143527: ['Gurdaspur','Punjab'],
        143528: ['Gurdaspur','Punjab'],
        143529: ['Gurdaspur','Punjab'],
        143530: ['Gurdaspur','Punjab'],
        143531: ['Gurdaspur','Punjab'],
        143532: ['Gurdaspur','Punjab'],
        143533: ['Pathankot','Punjab'],
        143534: ['Pathankot','Punjab'],
        143602: ['Gurdaspur','Punjab'],
        143604: ['Gurdaspur','Punjab'],
        143605: ['Gurdaspur','Punjab'],
        144105: ['Hoshiarpur','Punjab'],
        144202: ['Hoshiarpur','Punjab'],
        144204: ['Hoshiarpur','Punjab'],
        144205: ['Hoshiarpur','Punjab'],
        144206: ['Hoshiarpur','Punjab'],
        144207: ['Hoshiarpur','Punjab'],
        144208: ['Hoshiarpur','Punjab'],
        144209: ['Hoshiarpur','Punjab'],
        144210: ['Hoshiarpur','Punjab'],
        144211: ['Hoshiarpur','Punjab'],
        144212: ['Hoshiarpur','Punjab'],
        144213: ['Hoshiarpur','Punjab'],
        144214: ['Hoshiarpur','Punjab'],
        144216: ['Hoshiarpur','Punjab'],
        144221: ['Hoshiarpur','Punjab'],
        144222: ['Hoshiarpur','Punjab'],
        144223: ['Hoshiarpur','Punjab'],
        144224: ['Hoshiarpur','Punjab'],
        144305: ['Hoshiarpur','Punjab'],
        144306: ['Hoshiarpur','Punjab'],
        144401: ['Kapurthala','Punjab'],
        144402: ['Kapurthala','Punjab'],
        144404: ['Hoshiarpur','Punjab'],
        144406: ['Hoshiarpur','Punjab'],
        144408: ['Jalandhar','Punjab'],
        144411: ['Kapurthala','Punjab'],
        144421: ['Nawanshahr','Punjab'],
        144501: ['Nawanshahr','Punjab'],
        144507: ['Nawanshahr','Punjab'],
        144518: ['Nawanshahr','Punjab'],
        144519: ['Hoshiarpur','Punjab'],
        144520: ['Hoshiarpur','Punjab'],
        144521: ['Hoshiarpur','Punjab'],
        144522: ['Hoshiarpur','Punjab'],
        144523: ['Hoshiarpur','Punjab'],
        144524: ['Hoshiarpur','Punjab'],
        144525: ['Hoshiarpur','Punjab'],
        144526: ['Hoshiarpur','Punjab'],
        144527: ['Hoshiarpur','Punjab'],
        144528: ['Hoshiarpur','Punjab'],
        144529: ['Hoshiarpur','Punjab'],
        144530: ['Hoshiarpur','Punjab'],
        144531: ['Hoshiarpur','Punjab'],
        144532: ['Hoshiarpur','Punjab'],
        144601: ['Kapurthala','Punjab'],
        144602: ['Kapurthala','Punjab'],
        144606: ['Kapurthala','Punjab'],
        144620: ['Kapurthala','Punjab'],
        144621: ['Kapurthala','Punjab'],
        144622: ['Kapurthala','Punjab'],
        144624: ['Kapurthala','Punjab'],
        144625: ['Kapurthala','Punjab'],
        144626: ['Kapurthala','Punjab'],
        144628: ['Kapurthala','Punjab'],
        144631: ['Kapurthala','Punjab'],
        144632: ['Kapurthala','Punjab'],
        144802: ['Kapurthala','Punjab'],
        144803: ['Jalandhar','Punjab'],
        144804: ['Kapurthala','Punjab'],
        144819: ['Kapurthala','Punjab'],
        145001: ['Pathankot','Punjab'],
        145022: ['Pathankot','Punjab'],
        145023: ['Pathankot','Punjab'],
        145024: ['Pathankot','Punjab'],
        145025: ['Pathankot','Punjab'],
        145026: ['Pathankot','Punjab'],
        145027: ['Pathankot','Punjab'],
        145029: ['Pathankot','Punjab'],
        145101: ['Pathankot','Punjab'],
        146001: ['Hoshiarpur','Punjab'],
        146021: ['Hoshiarpur','Punjab'],
        146022: ['Hoshiarpur','Punjab'],
        146023: ['Hoshiarpur','Punjab'],
        146024: ['Hoshiarpur','Punjab'],
        146101: ['Hoshiarpur','Punjab'],
        146102: ['Hoshiarpur','Punjab'],
        146103: ['Hoshiarpur','Punjab'],
        146104: ['Hoshiarpur','Punjab'],
        146105: ['Hoshiarpur','Punjab'],
        146106: ['Hoshiarpur','Punjab'],
        146107: ['Hoshiarpur','Punjab'],
        146108: ['Hoshiarpur','Punjab'],
        146109: ['Hoshiarpur','Punjab'],
        146110: ['Hoshiarpur','Punjab'],
        146111: ['Hoshiarpur','Punjab'],
        146112: ['Hoshiarpur','Punjab'],
        146113: ['Hoshiarpur','Punjab'],
        146114: ['Hoshiarpur','Punjab'],
        146115: ['Hoshiarpur','Punjab'],
        146116: ['Hoshiarpur','Punjab'],
        148001: ['Sangrur','Punjab'],
        148002: ['Sangrur','Punjab'],
        148017: ['Sangrur','Punjab'],
        148018: ['Sangrur','Punjab'],
        148019: ['Sangrur','Punjab'],
        148020: ['Sangrur','Punjab'],
        148021: ['Sangrur','Punjab'],
        148022: ['Sangrur','Punjab'],
        148023: ['Sangrur','Punjab'],
        148024: ['Sangrur','Punjab'],
        148025: ['Sangrur','Punjab'],
        148026: ['Sangrur','Punjab'],
        148027: ['Sangrur','Punjab'],
        148028: ['Sangrur','Punjab'],
        148029: ['Sangrur','Punjab'],
        148030: ['Sangrur','Punjab'],
        148031: ['Sangrur','Punjab'],
        148033: ['Sangrur','Punjab'],
        148034: ['Sangrur','Punjab'],
        148035: ['Sangrur','Punjab'],
        148100: ['Sangrur','Punjab'],
        148101: ['Sangrur','Punjab'],
        148102: ['Sangrur','Punjab'],
        148103: ['Sangrur','Punjab'],
        148104: ['Sangrur','Punjab'],
        148105: ['Sangrur','Punjab'],
        148106: ['Sangrur','Punjab'],
        148107: ['Sangrur','Punjab'],
        148108: ['Sangrur','Punjab'],
        148109: ['Sangrur','Punjab'],
        151001: ['Bathinda','Punjab'],
        151002: ['Bathinda','Punjab'],
        151003: ['Bathinda','Punjab'],
        151004: ['Bathinda','Punjab'],
        151005: ['Bathinda','Punjab'],
        151101: ['Bathinda','Punjab'],
        151102: ['Bathinda','Punjab'],
        151103: ['Bathinda','Punjab'],
        151104: ['Bathinda','Punjab'],
        151105: ['Bathinda','Punjab'],
        151106: ['Bathinda','Punjab'],
        151108: ['Bathinda','Punjab'],
        151111: ['Bathinda','Punjab'],
        151201: ['Bathinda','Punjab'],
        151202: ['Faridkot','Punjab'],
        151203: ['Faridkot','Punjab'],
        151204: ['Faridkot','Punjab'],
        151205: ['Faridkot','Punjab'],
        151206: ['Bathinda','Punjab'],
        151207: ['Faridkot','Punjab'],
        151208: ['Faridkot','Punjab'],
        151209: ['Faridkot','Punjab'],
        151210: ['Muktsar','Punjab'],
        151211: ['Muktsar','Punjab'],
        151212: ['Faridkot','Punjab'],
        151213: ['Faridkot','Punjab'],
        151301: ['Bathinda','Punjab'],
        151302: ['Bathinda','Punjab'],
        151401: ['Bathinda','Punjab'],
        151501: ['Mansa','Punjab'],
        151502: ['Mansa','Punjab'],
        151503: ['Mansa','Punjab'],
        151504: ['Mansa','Punjab'],
        151505: ['Mansa','Punjab'],
        151506: ['Mansa','Punjab'],
        151507: ['Mansa','Punjab'],
        151508: ['Mansa','Punjab'],
        151509: ['Bathinda','Punjab'],
        151510: ['Mansa','Punjab'],
        152001: ['Firozpur','Punjab'],
        152002: ['Firozpur','Punjab'],
        152003: ['Firozpur','Punjab'],
        152004: ['Firozpur','Punjab'],
        152005: ['Firozpur','Punjab'],
        152021: ['Firozpur','Punjab'],
        152022: ['Firozpur','Punjab'],
        152023: ['Firozpur','Punjab'],
        152024: ['Firozpur','Punjab'],
        152025: ['Muktsar','Punjab'],
        152026: ['Muktsar','Punjab'],
        152028: ['Firozpur','Punjab'],
        152031: ['Muktsar','Punjab'],
        152032: ['Muktsar','Punjab'],
        152033: ['Fazilka','Punjab'],
        152101: ['Muktsar','Punjab'],
        152107: ['Muktsar','Punjab'],
        152112: ['Faridkot','Punjab'],
        152113: ['Muktsar','Punjab'],
        152114: ['Muktsar','Punjab'],
        152115: ['Muktsar','Punjab'],
        152116: ['Fazilka','Punjab'],
        152117: ['Fazilka','Punjab'],
        152118: ['Fazilka','Punjab'],
        152121: ['Fazilka','Punjab'],
        152122: ['Fazilka','Punjab'],
        152123: ['Fazilka','Punjab'],
        152124: ['Fazilka','Punjab'],
        152128: ['Fazilka','Punjab'],
        152132: ['Fazilka','Punjab'],
        160062: ['Mohali','Punjab'],
        160071: ['Mohali','Punjab'],
        171102: ['Solan','Himachal Pradesh'],
        172002: ['Kullu','Himachal Pradesh'],
        172023: ['Kullu','Himachal Pradesh'],
        172025: ['Kullu','Himachal Pradesh'],
        172026: ['Kullu','Himachal Pradesh'],
        172032: ['Shimla','Himachal Pradesh'],
        172033: ['Kullu','Himachal Pradesh'],
        172101: ['Kinnaur','Himachal Pradesh'],
        172103: ['Kinnaur','Himachal Pradesh'],
        172104: ['Kinnaur','Himachal Pradesh'],
        172105: ['Kinnaur','Himachal Pradesh'],
        172106: ['Kinnaur','Himachal Pradesh'],
        172107: ['Kinnaur','Himachal Pradesh'],
        172108: ['Kinnaur','Himachal Pradesh'],
        172109: ['Kinnaur','Himachal Pradesh'],
        172110: ['Kinnaur','Himachal Pradesh'],
        172111: ['Kinnaur','Himachal Pradesh'],
        172112: ['Kinnaur','Himachal Pradesh'],
        172113: ['Lahul  Spiti','Himachal Pradesh'],
        172114: ['Lahul  Spiti','Himachal Pradesh'],
        172115: ['Kinnaur','Himachal Pradesh'],
        172116: ['Kinnaur','Himachal Pradesh'],
        172117: ['Lahul  Spiti','Himachal Pradesh'],
        172118: ['Kinnaur','Himachal Pradesh'],
        173001: ['Sirmaur','Himachal Pradesh'],
        173021: ['Sirmaur','Himachal Pradesh'],
        173022: ['Sirmaur','Himachal Pradesh'],
        173023: ['Sirmaur','Himachal Pradesh'],
        173024: ['Sirmaur','Himachal Pradesh'],
        173025: ['Sirmaur','Himachal Pradesh'],
        173026: ['Sirmaur','Himachal Pradesh'],
        173027: ['Sirmaur','Himachal Pradesh'],
        173029: ['Sirmaur','Himachal Pradesh'],
        173030: ['Sirmaur','Himachal Pradesh'],
        173031: ['Sirmaur','Himachal Pradesh'],
        173032: ['Sirmaur','Himachal Pradesh'],
        173101: ['Sirmaur','Himachal Pradesh'],
        173104: ['Sirmaur','Himachal Pradesh'],
        173201: ['Solan','Himachal Pradesh'],
        173202: ['Solan','Himachal Pradesh'],
        173204: ['Solan','Himachal Pradesh'],
        173205: ['Solan','Himachal Pradesh'],
        173206: ['Solan','Himachal Pradesh'],
        173207: ['Solan','Himachal Pradesh'],
        173208: ['Solan','Himachal Pradesh'],
        173209: ['Solan','Himachal Pradesh'],
        173210: ['Solan','Himachal Pradesh'],
        173211: ['Solan','Himachal Pradesh'],
        173212: ['Solan','Himachal Pradesh'],
        173213: ['Solan','Himachal Pradesh'],
        173214: ['Solan','Himachal Pradesh'],
        173215: ['Solan','Himachal Pradesh'],
        173217: ['Solan','Himachal Pradesh'],
        173218: ['Solan','Himachal Pradesh'],
        173220: ['Solan','Himachal Pradesh'],
        173221: ['Solan','Himachal Pradesh'],
        173222: ['Solan','Himachal Pradesh'],
        173223: ['Solan','Himachal Pradesh'],
        173225: ['Solan','Himachal Pradesh'],
        173229: ['Solan','Himachal Pradesh'],
        173230: ['Solan','Himachal Pradesh'],
        173233: ['Solan','Himachal Pradesh'],
        173234: ['Solan','Himachal Pradesh'],
        173235: ['Solan','Himachal Pradesh'],
        174001: ['Bilaspur Hp','Himachal Pradesh'],
        174002: ['Bilaspur Hp','Himachal Pradesh'],
        174003: ['Bilaspur Hp','Himachal Pradesh'],
        174004: ['Bilaspur Hp','Himachal Pradesh'],
        174005: ['Bilaspur Hp','Himachal Pradesh'],
        174011: ['Bilaspur Hp','Himachal Pradesh'],
        174012: ['Bilaspur Hp','Himachal Pradesh'],
        174013: ['Bilaspur Hp','Himachal Pradesh'],
        174015: ['Bilaspur Hp','Himachal Pradesh'],
        174017: ['Bilaspur Hp','Himachal Pradesh'],
        174021: ['Bilaspur Hp','Himachal Pradesh'],
        174023: ['Bilaspur Hp','Himachal Pradesh'],
        174024: ['Bilaspur Hp','Himachal Pradesh'],
        174026: ['Bilaspur Hp','Himachal Pradesh'],
        174027: ['Bilaspur Hp','Himachal Pradesh'],
        174028: ['Bilaspur Hp','Himachal Pradesh'],
        174029: ['Bilaspur Hp','Himachal Pradesh'],
        174030: ['Bilaspur Hp','Himachal Pradesh'],
        174031: ['Bilaspur Hp','Himachal Pradesh'],
        174032: ['Bilaspur Hp','Himachal Pradesh'],
        174033: ['Bilaspur Hp','Himachal Pradesh'],
        174034: ['Bilaspur Hp','Himachal Pradesh'],
        174035: ['Bilaspur Hp','Himachal Pradesh'],
        174036: ['Bilaspur Hp','Himachal Pradesh'],
        174101: ['Solan','Himachal Pradesh'],
        174102: ['Solan','Himachal Pradesh'],
        174103: ['Solan','Himachal Pradesh'],
        174201: ['Bilaspur Hp','Himachal Pradesh'],
        174301: ['Una','Himachal Pradesh'],
        174302: ['Una','Himachal Pradesh'],
        174303: ['Una','Himachal Pradesh'],
        174304: ['Hamirpurhp','Himachal Pradesh'],
        174305: ['Hamirpurhp','Himachal Pradesh'],
        174306: ['Una','Himachal Pradesh'],
        174307: ['Una','Himachal Pradesh'],
        174308: ['Una','Himachal Pradesh'],
        174309: ['Hamirpurhp','Himachal Pradesh'],
        174310: ['Bilaspur Hp','Himachal Pradesh'],
        174311: ['Hamirpurhp','Himachal Pradesh'],
        174312: ['Hamirpurhp','Himachal Pradesh'],
        174314: ['Una','Himachal Pradesh'],
        174315: ['Una','Himachal Pradesh'],
        174316: ['Una','Himachal Pradesh'],
        174317: ['Una','Himachal Pradesh'],
        174319: ['Una','Himachal Pradesh'],
        174320: ['Una','Himachal Pradesh'],
        174321: ['Una','Himachal Pradesh'],
        174405: ['Hamirpurhp','Himachal Pradesh'],
        174503: ['Una','Himachal Pradesh'],
        174505: ['Hamirpurhp','Himachal Pradesh'],
        174507: ['Una','Himachal Pradesh'],
        175001: ['Mandi','Himachal Pradesh'],
        175002: ['Mandi','Himachal Pradesh'],
        175003: ['Mandi','Himachal Pradesh'],
        175004: ['Mandi','Himachal Pradesh'],
        175005: ['Mandi','Himachal Pradesh'],
        175006: ['Mandi','Himachal Pradesh'],
        175007: ['Mandi','Himachal Pradesh'],
        175008: ['Mandi','Himachal Pradesh'],
        175009: ['Mandi','Himachal Pradesh'],
        175010: ['Mandi','Himachal Pradesh'],
        175011: ['Mandi','Himachal Pradesh'],
        175012: ['Mandi','Himachal Pradesh'],
        175013: ['Mandi','Himachal Pradesh'],
        175014: ['Mandi','Himachal Pradesh'],
        175015: ['Mandi','Himachal Pradesh'],
        175016: ['Mandi','Himachal Pradesh'],
        175017: ['Mandi','Himachal Pradesh'],
        175018: ['Mandi','Himachal Pradesh'],
        175019: ['Mandi','Himachal Pradesh'],
        175021: ['Mandi','Himachal Pradesh'],
        175023: ['Mandi','Himachal Pradesh'],
        175024: ['Mandi','Himachal Pradesh'],
        175025: ['Mandi','Himachal Pradesh'],
        175026: ['Mandi','Himachal Pradesh'],
        175027: ['Mandi','Himachal Pradesh'],
        175028: ['Mandi','Himachal Pradesh'],
        175029: ['Mandi','Himachal Pradesh'],
        175030: ['Mandi','Himachal Pradesh'],
        175031: ['Mandi','Himachal Pradesh'],
        175032: ['Mandi','Himachal Pradesh'],
        175033: ['Mandi','Himachal Pradesh'],
        175034: ['Mandi','Himachal Pradesh'],
        175035: ['Mandi','Himachal Pradesh'],
        175036: ['Mandi','Himachal Pradesh'],
        175037: ['Mandi','Himachal Pradesh'],
        175038: ['Mandi','Himachal Pradesh'],
        175039: ['Mandi','Himachal Pradesh'],
        175040: ['Mandi','Himachal Pradesh'],
        175042: ['Mandi','Himachal Pradesh'],
        175045: ['Mandi','Himachal Pradesh'],
        175046: ['Mandi','Himachal Pradesh'],
        175047: ['Mandi','Himachal Pradesh'],
        175048: ['Mandi','Himachal Pradesh'],
        175049: ['Mandi','Himachal Pradesh'],
        175050: ['Mandi','Himachal Pradesh'],
        175051: ['Mandi','Himachal Pradesh'],
        175052: ['Mandi','Himachal Pradesh'],
        175075: ['Mandi','Himachal Pradesh'],
        175101: ['Kullu','Himachal Pradesh'],
        175102: ['Kullu','Himachal Pradesh'],
        175103: ['Kullu','Himachal Pradesh'],
        175104: ['Kullu','Himachal Pradesh'],
        175105: ['Kullu','Himachal Pradesh'],
        175106: ['Kullu','Himachal Pradesh'],
        175121: ['Mandi','Himachal Pradesh'],
        175123: ['Kullu','Himachal Pradesh'],
        175124: ['Mandi','Himachal Pradesh'],
        175125: ['Kullu','Himachal Pradesh'],
        175126: ['Kullu','Himachal Pradesh'],
        175128: ['Mandi','Himachal Pradesh'],
        175129: ['Kullu','Himachal Pradesh'],
        175130: ['Kullu','Himachal Pradesh'],
        175131: ['Kullu','Himachal Pradesh'],
        175132: ['Mandi','Himachal Pradesh'],
        175133: ['Lahul  Spiti','Himachal Pradesh'],
        175134: ['Kullu','Himachal Pradesh'],
        175136: ['Kullu','Himachal Pradesh'],
        175138: ['Kullu','Himachal Pradesh'],
        175139: ['Lahul  Spiti','Himachal Pradesh'],
        175140: ['Lahul  Spiti','Himachal Pradesh'],
        175141: ['Kullu','Himachal Pradesh'],
        175142: ['Lahul  Spiti','Himachal Pradesh'],
        175143: ['Kullu','Himachal Pradesh'],
        176001: ['Kangra','Himachal Pradesh'],
        176002: ['Kangra','Himachal Pradesh'],
        176021: ['Kangra','Himachal Pradesh'],
        176022: ['Kangra','Himachal Pradesh'],
        176023: ['Kangra','Himachal Pradesh'],
        176025: ['Kangra','Himachal Pradesh'],
        176026: ['Kangra','Himachal Pradesh'],
        176027: ['Kangra','Himachal Pradesh'],
        176028: ['Kangra','Himachal Pradesh'],
        176029: ['Kangra','Himachal Pradesh'],
        176030: ['Kangra','Himachal Pradesh'],
        176031: ['Kangra','Himachal Pradesh'],
        176032: ['Kangra','Himachal Pradesh'],
        176033: ['Kangra','Himachal Pradesh'],
        176036: ['Kangra','Himachal Pradesh'],
        176037: ['Kangra','Himachal Pradesh'],
        176038: ['Kangra','Himachal Pradesh'],
        176039: ['Hamirpurhp','Himachal Pradesh'],
        176040: ['Hamirpurhp','Himachal Pradesh'],
        176041: ['Hamirpurhp','Himachal Pradesh'],
        176042: ['Hamirpurhp','Himachal Pradesh'],
        176043: ['Hamirpurhp','Himachal Pradesh'],
        176044: ['Hamirpurhp','Himachal Pradesh'],
        176045: ['Hamirpurhp','Himachal Pradesh'],
        176047: ['Kangra','Himachal Pradesh'],
        176048: ['Hamirpurhp','Himachal Pradesh'],
        176049: ['Hamirpurhp','Himachal Pradesh'],
        176051: ['Kangra','Himachal Pradesh'],
        176052: ['Kangra','Himachal Pradesh'],
        176053: ['Kangra','Himachal Pradesh'],
        176054: ['Kangra','Himachal Pradesh'],
        176055: ['Kangra','Himachal Pradesh'],
        176056: ['Kangra','Himachal Pradesh'],
        176057: ['Kangra','Himachal Pradesh'],
        176058: ['Kangra','Himachal Pradesh'],
        176059: ['Kangra','Himachal Pradesh'],
        176060: ['Kangra','Himachal Pradesh'],
        176061: ['Kangra','Himachal Pradesh'],
        176062: ['Kangra','Himachal Pradesh'],
        176063: ['Kangra','Himachal Pradesh'],
        176064: ['Kangra','Himachal Pradesh'],
        176065: ['Kangra','Himachal Pradesh'],
        176066: ['Kangra','Himachal Pradesh'],
        176067: ['Kangra','Himachal Pradesh'],
        176071: ['Kangra','Himachal Pradesh'],
        176073: ['Kangra','Himachal Pradesh'],
        176075: ['Kangra','Himachal Pradesh'],
        176076: ['Kangra','Himachal Pradesh'],
        176077: ['Kangra','Himachal Pradesh'],
        176081: ['Kangra','Himachal Pradesh'],
        176082: ['Kangra','Himachal Pradesh'],
        176083: ['Kangra','Himachal Pradesh'],
        176084: ['Kangra','Himachal Pradesh'],
        176085: ['Kangra','Himachal Pradesh'],
        176086: ['Kangra','Himachal Pradesh'],
        176087: ['Kangra','Himachal Pradesh'],
        176088: ['Kangra','Himachal Pradesh'],
        176089: ['Kangra','Himachal Pradesh'],
        176090: ['Mandi','Himachal Pradesh'],
        176091: ['Kangra','Himachal Pradesh'],
        176092: ['Kangra','Himachal Pradesh'],
        176093: ['Kangra','Himachal Pradesh'],
        176094: ['Kangra','Himachal Pradesh'],
        176095: ['Kangra','Himachal Pradesh'],
        176096: ['Kangra','Himachal Pradesh'],
        176097: ['Kangra','Himachal Pradesh'],
        176098: ['Kangra','Himachal Pradesh'],
        176101: ['Kangra','Himachal Pradesh'],
        176102: ['Kangra','Himachal Pradesh'],
        176103: ['Kangra','Himachal Pradesh'],
        176107: ['Kangra','Himachal Pradesh'],
        176108: ['Hamirpurhp','Himachal Pradesh'],
        176109: ['Hamirpurhp','Himachal Pradesh'],
        176110: ['Hamirpurhp','Himachal Pradesh'],
        176111: ['Hamirpurhp','Himachal Pradesh'],
        176115: ['Kangra','Himachal Pradesh'],
        176125: ['Kangra','Himachal Pradesh'],
        176128: ['Kangra','Himachal Pradesh'],
        176200: ['Kangra','Himachal Pradesh'],
        176201: ['Kangra','Himachal Pradesh'],
        176202: ['Kangra','Himachal Pradesh'],
        176203: ['Kangra','Himachal Pradesh'],
        176204: ['Kangra','Himachal Pradesh'],
        176205: ['Kangra','Himachal Pradesh'],
        176206: ['Kangra','Himachal Pradesh'],
        176207: ['Chamba','Himachal Pradesh'],
        176208: ['Kangra','Himachal Pradesh'],
        176209: ['Kangra','Himachal Pradesh'],
        176210: ['Kangra','Himachal Pradesh'],
        176211: ['Kangra','Himachal Pradesh'],
        176213: ['Kangra','Himachal Pradesh'],
        176214: ['Kangra','Himachal Pradesh'],
        176215: ['Kangra','Himachal Pradesh'],
        176216: ['Kangra','Himachal Pradesh'],
        176217: ['Kangra','Himachal Pradesh'],
        176218: ['Kangra','Himachal Pradesh'],
        176219: ['Kangra','Himachal Pradesh'],
        176225: ['Kangra','Himachal Pradesh'],
        176301: ['Chamba','Himachal Pradesh'],
        176302: ['Chamba','Himachal Pradesh'],
        176303: ['Chamba','Himachal Pradesh'],
        176304: ['Chamba','Himachal Pradesh'],
        176305: ['Chamba','Himachal Pradesh'],
        176306: ['Chamba','Himachal Pradesh'],
        176308: ['Chamba','Himachal Pradesh'],
        176309: ['Chamba','Himachal Pradesh'],
        176310: ['Chamba','Himachal Pradesh'],
        176311: ['Chamba','Himachal Pradesh'],
        176312: ['Chamba','Himachal Pradesh'],
        176313: ['Chamba','Himachal Pradesh'],
        176314: ['Chamba','Himachal Pradesh'],
        176315: ['Chamba','Himachal Pradesh'],
        176316: ['Chamba','Himachal Pradesh'],
        176317: ['Chamba','Himachal Pradesh'],
        176318: ['Chamba','Himachal Pradesh'],
        176319: ['Chamba','Himachal Pradesh'],
        176320: ['Chamba','Himachal Pradesh'],
        176321: ['Chamba','Himachal Pradesh'],
        176323: ['Chamba','Himachal Pradesh'],
        176324: ['Chamba','Himachal Pradesh'],
        176325: ['Chamba','Himachal Pradesh'],
        176401: ['Kangra','Himachal Pradesh'],
        176402: ['Kangra','Himachal Pradesh'],
        176403: ['Kangra','Himachal Pradesh'],
        176501: ['Kangra','Himachal Pradesh'],
        176502: ['Kangra','Himachal Pradesh'],
        176601: ['Una','Himachal Pradesh'],
        177001: ['Hamirpurhp','Himachal Pradesh'],
        177005: ['Hamirpurhp','Himachal Pradesh'],
        177006: ['Hamirpurhp','Himachal Pradesh'],
        177007: ['Hamirpurhp','Himachal Pradesh'],
        177020: ['Hamirpurhp','Himachal Pradesh'],
        177021: ['Hamirpurhp','Himachal Pradesh'],
        177022: ['Hamirpurhp','Himachal Pradesh'],
        177023: ['Hamirpurhp','Himachal Pradesh'],
        177024: ['Hamirpurhp','Himachal Pradesh'],
        177025: ['Hamirpurhp','Himachal Pradesh'],
        177026: ['Hamirpurhp','Himachal Pradesh'],
        177027: ['Hamirpurhp','Himachal Pradesh'],
        177028: ['Hamirpurhp','Himachal Pradesh'],
        177029: ['Hamirpurhp','Himachal Pradesh'],
        177031: ['Una','Himachal Pradesh'],
        177033: ['Hamirpurhp','Himachal Pradesh'],
        177034: ['Kangra','Himachal Pradesh'],
        177038: ['Hamirpurhp','Himachal Pradesh'],
        177039: ['Una','Himachal Pradesh'],
        177040: ['Hamirpurhp','Himachal Pradesh'],
        177041: ['Hamirpurhp','Himachal Pradesh'],
        177042: ['Hamirpurhp','Himachal Pradesh'],
        177043: ['Kangra','Himachal Pradesh'],
        177044: ['Hamirpurhp','Himachal Pradesh'],
        177045: ['Hamirpurhp','Himachal Pradesh'],
        177048: ['Hamirpurhp','Himachal Pradesh'],
        177101: ['Kangra','Himachal Pradesh'],
        177103: ['Kangra','Himachal Pradesh'],
        177104: ['Kangra','Himachal Pradesh'],
        177105: ['Kangra','Himachal Pradesh'],
        177106: ['Kangra','Himachal Pradesh'],
        177107: ['Kangra','Himachal Pradesh'],
        177108: ['Kangra','Himachal Pradesh'],
        177109: ['Una','Himachal Pradesh'],
        177110: ['Una','Himachal Pradesh'],
        177111: ['Kangra','Himachal Pradesh'],
        177112: ['Kangra','Himachal Pradesh'],
        177113: ['Kangra','Himachal Pradesh'],
        177114: ['Kangra','Himachal Pradesh'],
        177117: ['Kangra','Himachal Pradesh'],
        177118: ['Hamirpurhp','Himachal Pradesh'],
        177119: ['Hamirpurhp','Himachal Pradesh'],
        177201: ['Una','Himachal Pradesh'],
        177202: ['Una','Himachal Pradesh'],
        177203: ['Una','Himachal Pradesh'],
        177204: ['Una','Himachal Pradesh'],
        177205: ['Una','Himachal Pradesh'],
        177206: ['Una','Himachal Pradesh'],
        177207: ['Una','Himachal Pradesh'],
        177208: ['Una','Himachal Pradesh'],
        177209: ['Una','Himachal Pradesh'],
        177210: ['Una','Himachal Pradesh'],
        177211: ['Una','Himachal Pradesh'],
        177212: ['Una','Himachal Pradesh'],
        177213: ['Una','Himachal Pradesh'],
        177219: ['Una','Himachal Pradesh'],
        177220: ['Una','Himachal Pradesh'],
        177301: ['Hamirpurhp','Himachal Pradesh'],
        177401: ['Hamirpurhp','Himachal Pradesh'],
        177501: ['Hamirpurhp','Himachal Pradesh'],
        177601: ['Hamirpurhp','Himachal Pradesh'],
        182101: ['Udhampur','Jammu And Kashmir'],
        182104: ['Udhampur','Jammu And Kashmir'],
        182121: ['Udhampur','Jammu And Kashmir'],
        182122: ['Udhampur','Jammu And Kashmir'],
        182124: ['Udhampur','Jammu And Kashmir'],
        182125: ['Reasi','Jammu And Kashmir'],
        182126: ['Udhampur','Jammu And Kashmir'],
        182127: ['Udhampur','Jammu And Kashmir'],
        182128: ['Udhampur','Jammu And Kashmir'],
        182141: ['Udhampur','Jammu And Kashmir'],
        182142: ['Udhampur','Jammu And Kashmir'],
        182143: ['Ramban','Jammu And Kashmir'],
        182144: ['Ramban','Jammu And Kashmir'],
        182145: ['Ramban','Jammu And Kashmir'],
        182146: ['Ramban','Jammu And Kashmir'],
        182147: ['Doda','Jammu And Kashmir'],
        182148: ['Ramban','Jammu And Kashmir'],
        182161: ['Udhampur','Jammu And Kashmir'],
        182201: ['Doda','Jammu And Kashmir'],
        182202: ['Doda','Jammu And Kashmir'],
        182203: ['Doda','Jammu And Kashmir'],
        182204: ['Doda','Jammu And Kashmir'],
        182205: ['Doda','Jammu And Kashmir'],
        182206: ['Doda','Jammu And Kashmir'],
        182221: ['Doda','Jammu And Kashmir'],
        182222: ['Doda','Jammu And Kashmir'],
        182301: ['Reasi','Jammu And Kashmir'],
        182311: ['Reasi','Jammu And Kashmir'],
        182312: ['Reasi','Jammu And Kashmir'],
        182313: ['Reasi','Jammu And Kashmir'],
        182315: ['Reasi','Jammu And Kashmir'],
        182320: ['Reasi','Jammu And Kashmir'],
        184101: ['Kathua','Jammu And Kashmir'],
        184102: ['Kathua','Jammu And Kashmir'],
        184104: ['Kathua','Jammu And Kashmir'],
        184141: ['Kathua','Jammu And Kashmir'],
        184142: ['Kathua','Jammu And Kashmir'],
        184143: ['Kathua','Jammu And Kashmir'],
        184144: ['Kathua','Jammu And Kashmir'],
        184145: ['Kathua','Jammu And Kashmir'],
        184148: ['Kathua','Jammu And Kashmir'],
        184151: ['Jammu','Jammu And Kashmir'],
        184152: ['Kathua','Jammu And Kashmir'],
        184201: ['Kathua','Jammu And Kashmir'],
        184202: ['Kathua','Jammu And Kashmir'],
        184203: ['Kathua','Jammu And Kashmir'],
        184204: ['Kathua','Jammu And Kashmir'],
        184205: ['Kathua','Jammu And Kashmir'],
        184206: ['Kathua','Jammu And Kashmir'],
        184210: ['Kathua','Jammu And Kashmir'],
        185101: ['Rajauri','Jammu And Kashmir'],
        185102: ['Poonch','Jammu And Kashmir'],
        185121: ['Poonch','Jammu And Kashmir'],
        185131: ['Rajauri','Jammu And Kashmir'],
        185132: ['Rajauri','Jammu And Kashmir'],
        185133: ['Rajauri','Jammu And Kashmir'],
        185135: ['Rajauri','Jammu And Kashmir'],
        185151: ['Rajauri','Jammu And Kashmir'],
        185152: ['Rajauri','Jammu And Kashmir'],
        185153: ['Rajauri','Jammu And Kashmir'],
        185155: ['Rajauri','Jammu And Kashmir'],
        185156: ['Rajauri','Jammu And Kashmir'],
        185201: ['Rajauri','Jammu And Kashmir'],
        185202: ['Rajauri','Jammu And Kashmir'],
        185203: ['Reasi','Jammu And Kashmir'],
        185211: ['Poonch','Jammu And Kashmir'],
        185212: ['Rajauri','Jammu And Kashmir'],
        185233: ['Rajauri','Jammu And Kashmir'],
        185234: ['Rajauri','Jammu And Kashmir'],
        190001: ['Srinagar','Jammu And Kashmir'],
        190002: ['Srinagar','Jammu And Kashmir'],
        190003: ['Srinagar','Jammu And Kashmir'],
        190004: ['Srinagar','Jammu And Kashmir'],
        190005: ['Srinagar','Jammu And Kashmir'],
        190006: ['Srinagar','Jammu And Kashmir'],
        190007: ['Budgam','Jammu And Kashmir'],
        190008: ['Srinagar','Jammu And Kashmir'],
        190009: ['Srinagar','Jammu And Kashmir'],
        190010: ['Srinagar','Jammu And Kashmir'],
        190011: ['Srinagar','Jammu And Kashmir'],
        190012: ['Srinagar','Jammu And Kashmir'],
        190014: ['Srinagar','Jammu And Kashmir'],
        190015: ['Srinagar','Jammu And Kashmir'],
        190017: ['Srinagar','Jammu And Kashmir'],
        190018: ['Srinagar','Jammu And Kashmir'],
        190019: ['Srinagar','Jammu And Kashmir'],
        190020: ['Srinagar','Jammu And Kashmir'],
        190021: ['Budgam','Jammu And Kashmir'],
        190023: ['Srinagar','Jammu And Kashmir'],
        190024: ['Srinagar','Jammu And Kashmir'],
        190025: ['Srinagar','Jammu And Kashmir'],
        191101: ['Srinagar','Jammu And Kashmir'],
        191102: ['Pulwama','Jammu And Kashmir'],
        191103: ['Pulwama','Jammu And Kashmir'],
        191111: ['Budgam','Jammu And Kashmir'],
        191112: ['Budgam','Jammu And Kashmir'],
        191113: ['Budgam','Jammu And Kashmir'],
        191121: ['Srinagar','Jammu And Kashmir'],
        191131: ['Ganderbal','Jammu And Kashmir'],
        191132: ['Srinagar','Jammu And Kashmir'],
        191201: ['Ganderbal','Jammu And Kashmir'],
        191202: ['Ganderbal','Jammu And Kashmir'],
        192101: ['Ananthnag','Jammu And Kashmir'],
        192121: ['Srinagar','Jammu And Kashmir'],
        192122: ['Ananthnag','Jammu And Kashmir'],
        192123: ['Pulwama','Jammu And Kashmir'],
        192124: ['Ananthnag','Jammu And Kashmir'],
        192125: ['Ananthnag','Jammu And Kashmir'],
        192126: ['Ananthnag','Jammu And Kashmir'],
        192129: ['Ananthnag','Jammu And Kashmir'],
        192201: ['Ananthnag','Jammu And Kashmir'],
        192202: ['Ananthnag','Jammu And Kashmir'],
        192210: ['Ananthnag','Jammu And Kashmir'],
        192211: ['Ananthnag','Jammu And Kashmir'],
        192212: ['Ananthnag','Jammu And Kashmir'],
        192221: ['Kulgam','Jammu And Kashmir'],
        192231: ['Kulgam','Jammu And Kashmir'],
        192232: ['Ananthnag','Jammu And Kashmir'],
        192233: ['Ananthnag','Jammu And Kashmir'],
        192301: ['Pulwama','Jammu And Kashmir'],
        192302: ['Srinagar','Jammu And Kashmir'],
        192303: ['Shopian','Jammu And Kashmir'],
        192304: ['Pulwama','Jammu And Kashmir'],
        192305: ['Pulwama','Jammu And Kashmir'],
        192306: ['Pulwama','Jammu And Kashmir'],
        192401: ['Ananthnag','Jammu And Kashmir'],
        193101: ['Baramulla','Jammu And Kashmir'],
        193103: ['Baramulla','Jammu And Kashmir'],
        193108: ['Baramulla','Jammu And Kashmir'],
        193109: ['Baramulla','Jammu And Kashmir'],
        193121: ['Baramulla','Jammu And Kashmir'],
        193122: ['Baramulla','Jammu And Kashmir'],
        193123: ['Baramulla','Jammu And Kashmir'],
        193201: ['Baramulla','Jammu And Kashmir'],
        193221: ['Kupwara','Jammu And Kashmir'],
        193222: ['Kupwara','Jammu And Kashmir'],
        193223: ['Baramulla','Jammu And Kashmir'],
        193224: ['Kupwara','Jammu And Kashmir'],
        193225: ['Kupwara','Jammu And Kashmir'],
        193301: ['Baramulla','Jammu And Kashmir'],
        193302: ['Kupwara','Jammu And Kashmir'],
        193303: ['Baramulla','Jammu And Kashmir'],
        193306: ['Ananthnag','Jammu And Kashmir'],
        193401: ['Budgam','Jammu And Kashmir'],
        193402: ['Baramulla','Jammu And Kashmir'],
        193403: ['Baramulla','Jammu And Kashmir'],
        193404: ['Baramulla','Jammu And Kashmir'],
        193411: ['Srinagar','Jammu And Kashmir'],
        193501: ['Bandipur','Jammu And Kashmir'],
        193502: ['Bandipur','Jammu And Kashmir'],
        193503: ['Bandipur','Jammu And Kashmir'],
        193504: ['Bandipur','Jammu And Kashmir'],
        193505: ['Baramulla','Jammu And Kashmir'],
        194101: ['Leh','Jammu And Kashmir'],
        194102: ['Kargil','Jammu And Kashmir'],
        194103: ['Kargil','Jammu And Kashmir'],
        194104: ['Leh','Jammu And Kashmir'],
        194105: ['Kargil','Jammu And Kashmir'],
        194106: ['Leh','Jammu And Kashmir'],
        194107: ['Leh','Jammu And Kashmir'],
        194109: ['Kargil','Jammu And Kashmir'],
        194201: ['Leh','Jammu And Kashmir'],
        194202: ['Leh','Jammu And Kashmir'],
        194301: ['Kargil','Jammu And Kashmir'],
        194302: ['Kargil','Jammu And Kashmir'],
        194303: ['Kargil','Jammu And Kashmir'],
        194401: ['Leh','Jammu And Kashmir'],
        194402: ['Leh','Jammu And Kashmir'],
        194404: ['Leh','Jammu And Kashmir'],
        201021: ['Ghaziabad','Uttar Pradesh'],
        201311: ['Gautam Buddha Nagar','Uttar Pradesh'],
        201312: ['Gautam Buddha Nagar','Uttar Pradesh'],
        201313: ['Gautam Buddha Nagar','Uttar Pradesh'],
        201314: ['Gautam Buddha Nagar','Uttar Pradesh'],
        202001: ['Aligarh','Uttar Pradesh'],
        202002: ['Aligarh','Uttar Pradesh'],
        202121: ['Aligarh','Uttar Pradesh'],
        202122: ['Aligarh','Uttar Pradesh'],
        202123: ['Aligarh','Uttar Pradesh'],
        202124: ['Aligarh','Uttar Pradesh'],
        202125: ['Aligarh','Uttar Pradesh'],
        202126: ['Aligarh','Uttar Pradesh'],
        202127: ['Aligarh','Uttar Pradesh'],
        202128: ['Aligarh','Uttar Pradesh'],
        202129: ['Aligarh','Uttar Pradesh'],
        202130: ['Aligarh','Uttar Pradesh'],
        202131: ['Aligarh','Uttar Pradesh'],
        202132: ['Aligarh','Uttar Pradesh'],
        202133: ['Aligarh','Uttar Pradesh'],
        202134: ['Aligarh','Uttar Pradesh'],
        202135: ['Aligarh','Uttar Pradesh'],
        202136: ['Aligarh','Uttar Pradesh'],
        202137: ['Aligarh','Uttar Pradesh'],
        202138: ['Aligarh','Uttar Pradesh'],
        202139: ['Aligarh','Uttar Pradesh'],
        202140: ['Aligarh','Uttar Pradesh'],
        202141: ['Aligarh','Uttar Pradesh'],
        202142: ['Aligarh','Uttar Pradesh'],
        202143: ['Aligarh','Uttar Pradesh'],
        202145: ['Aligarh','Uttar Pradesh'],
        202146: ['Aligarh','Uttar Pradesh'],
        202150: ['Aligarh','Uttar Pradesh'],
        202155: ['Aligarh','Uttar Pradesh'],
        202165: ['Aligarh','Uttar Pradesh'],
        202170: ['Aligarh','Uttar Pradesh'],
        202280: ['Aligarh','Uttar Pradesh'],
        202281: ['Aligarh','Uttar Pradesh'],
        202282: ['Aligarh','Uttar Pradesh'],
        203001: ['Hathras','Uttar Pradesh'],
        203002: ['Bulandshahr','Uttar Pradesh'],
        203129: ['Bulandshahr','Uttar Pradesh'],
        203131: ['Bulandshahr','Uttar Pradesh'],
        203132: ['Bulandshahr','Uttar Pradesh'],
        203135: ['Bulandshahr','Uttar Pradesh'],
        203141: ['Bulandshahr','Uttar Pradesh'],
        203150: ['Bulandshahr','Uttar Pradesh'],
        203155: ['Bulandshahr','Uttar Pradesh'],
        203201: ['Bulandshahr','Uttar Pradesh'],
        203202: ['Bulandshahr','Uttar Pradesh'],
        203203: ['Bulandshahr','Uttar Pradesh'],
        203205: ['Bulandshahr','Uttar Pradesh'],
        203206: ['Bulandshahr','Uttar Pradesh'],
        203209: ['Bulandshahr','Uttar Pradesh'],
        203389: ['Bulandshahr','Uttar Pradesh'],
        203390: ['Bulandshahr','Uttar Pradesh'],
        203391: ['Bulandshahr','Uttar Pradesh'],
        203392: ['Bulandshahr','Uttar Pradesh'],
        203393: ['Bulandshahr','Uttar Pradesh'],
        203394: ['Bulandshahr','Uttar Pradesh'],
        203395: ['Bulandshahr','Uttar Pradesh'],
        203396: ['Bulandshahr','Uttar Pradesh'],
        203397: ['Bulandshahr','Uttar Pradesh'],
        203398: ['Bulandshahr','Uttar Pradesh'],
        203399: ['Bulandshahr','Uttar Pradesh'],
        203401: ['Bulandshahr','Uttar Pradesh'],
        203402: ['Bulandshahr','Uttar Pradesh'],
        203403: ['Bulandshahr','Uttar Pradesh'],
        203405: ['Bulandshahr','Uttar Pradesh'],
        203407: ['Bulandshahr','Uttar Pradesh'],
        203408: ['Bulandshahr','Uttar Pradesh'],
        203409: ['Bulandshahr','Uttar Pradesh'],
        203411: ['Bulandshahr','Uttar Pradesh'],
        203412: ['Bulandshahr','Uttar Pradesh'],
        204101: ['Hathras','Uttar Pradesh'],
        204102: ['Hathras','Uttar Pradesh'],
        204211: ['Hathras','Uttar Pradesh'],
        204212: ['Aligarh','Uttar Pradesh'],
        204213: ['Hathras','Uttar Pradesh'],
        204214: ['Hathras','Uttar Pradesh'],
        204215: ['Aligarh','Uttar Pradesh'],
        204216: ['Aligarh','Uttar Pradesh'],
        205001: ['Mainpuri','Uttar Pradesh'],
        205119: ['Mainpuri','Uttar Pradesh'],
        205121: ['Firozabad','Uttar Pradesh'],
        205247: ['Mainpuri','Uttar Pradesh'],
        205261: ['Mainpuri','Uttar Pradesh'],
        205262: ['Mainpuri','Uttar Pradesh'],
        205263: ['Mainpuri','Uttar Pradesh'],
        205264: ['Mainpuri','Uttar Pradesh'],
        205265: ['Mainpuri','Uttar Pradesh'],
        205267: ['Mainpuri','Uttar Pradesh'],
        205268: ['Mainpuri','Uttar Pradesh'],
        205301: ['Mainpuri','Uttar Pradesh'],
        205303: ['Mainpuri','Uttar Pradesh'],
        205304: ['Mainpuri','Uttar Pradesh'],
        206001: ['Etawah','Uttar Pradesh'],
        206002: ['Etawah','Uttar Pradesh'],
        206003: ['Etawah','Uttar Pradesh'],
        206120: ['Etawah','Uttar Pradesh'],
        206121: ['Auraiya','Uttar Pradesh'],
        206122: ['Auraiya','Uttar Pradesh'],
        206123: ['Etawah','Uttar Pradesh'],
        206124: ['Etawah','Uttar Pradesh'],
        206125: ['Etawah','Uttar Pradesh'],
        206126: ['Etawah','Uttar Pradesh'],
        206127: ['Etawah','Uttar Pradesh'],
        206128: ['Auraiya','Uttar Pradesh'],
        206129: ['Auraiya','Uttar Pradesh'],
        206130: ['Etawah','Uttar Pradesh'],
        206131: ['Etawah','Uttar Pradesh'],
        206241: ['Auraiya','Uttar Pradesh'],
        206242: ['Etawah','Uttar Pradesh'],
        206243: ['Auraiya','Uttar Pradesh'],
        206244: ['Auraiya','Uttar Pradesh'],
        206245: ['Etawah','Uttar Pradesh'],
        206246: ['Auraiya','Uttar Pradesh'],
        206247: ['Auraiya','Uttar Pradesh'],
        206248: ['Auraiya','Uttar Pradesh'],
        206249: ['Auraiya','Uttar Pradesh'],
        206250: ['Auraiya','Uttar Pradesh'],
        206251: ['Auraiya','Uttar Pradesh'],
        206252: ['Auraiya','Uttar Pradesh'],
        206253: ['Etawah','Uttar Pradesh'],
        206255: ['Auraiya','Uttar Pradesh'],
        207001: ['Etah','Uttar Pradesh'],
        207002: ['Etah','Uttar Pradesh'],
        207003: ['Etah','Uttar Pradesh'],
        207120: ['Etah','Uttar Pradesh'],
        207121: ['Etah','Uttar Pradesh'],
        207122: ['Etah','Uttar Pradesh'],
        207123: ['Kanshiram Nagar','Uttar Pradesh'],
        207124: ['Kanshiram Nagar','Uttar Pradesh'],
        207125: ['Kanshiram Nagar','Uttar Pradesh'],
        207241: ['Etah','Uttar Pradesh'],
        207242: ['Kanshiram Nagar','Uttar Pradesh'],
        207243: ['Kanshiram Nagar','Uttar Pradesh'],
        207244: ['Etah','Uttar Pradesh'],
        207245: ['Kanshiram Nagar','Uttar Pradesh'],
        207246: ['Etah','Uttar Pradesh'],
        207247: ['Etah','Uttar Pradesh'],
        207248: ['Etah','Uttar Pradesh'],
        207249: ['Etah','Uttar Pradesh'],
        207250: ['Etah','Uttar Pradesh'],
        207301: ['Etah','Uttar Pradesh'],
        207302: ['Etah','Uttar Pradesh'],
        207401: ['Kanshiram Nagar','Uttar Pradesh'],
        207402: ['Kanshiram Nagar','Uttar Pradesh'],
        207403: ['Kanshiram Nagar','Uttar Pradesh'],
        209501: ['Farrukhabad','Uttar Pradesh'],
        209502: ['Farrukhabad','Uttar Pradesh'],
        209503: ['Farrukhabad','Uttar Pradesh'],
        209504: ['Farrukhabad','Uttar Pradesh'],
        209505: ['Farrukhabad','Uttar Pradesh'],
        209601: ['Farrukhabad','Uttar Pradesh'],
        209602: ['Farrukhabad','Uttar Pradesh'],
        209621: ['Farrukhabad','Uttar Pradesh'],
        209622: ['Farrukhabad','Uttar Pradesh'],
        209625: ['Farrukhabad','Uttar Pradesh'],
        209651: ['Farrukhabad','Uttar Pradesh'],
        209652: ['Farrukhabad','Uttar Pradesh'],
        209720: ['Farrukhabad','Uttar Pradesh'],
        209721: ['Kannauj','Uttar Pradesh'],
        209722: ['Kannauj','Uttar Pradesh'],
        209723: ['Kannauj','Uttar Pradesh'],
        209724: ['Farrukhabad','Uttar Pradesh'],
        209725: ['Kannauj','Uttar Pradesh'],
        209726: ['Kannauj','Uttar Pradesh'],
        209727: ['Kannauj','Uttar Pradesh'],
        209728: ['Kannauj','Uttar Pradesh'],
        209729: ['Kannauj','Uttar Pradesh'],
        209731: ['Kannauj','Uttar Pradesh'],
        209732: ['Kannauj','Uttar Pradesh'],
        209733: ['Kannauj','Uttar Pradesh'],
        209734: ['Kannauj','Uttar Pradesh'],
        209735: ['Kannauj','Uttar Pradesh'],
        209736: ['Kannauj','Uttar Pradesh'],
        209738: ['Kannauj','Uttar Pradesh'],
        209739: ['Farrukhabad','Uttar Pradesh'],
        209743: ['Farrukhabad','Uttar Pradesh'],
        209745: ['Farrukhabad','Uttar Pradesh'],
        209747: ['Kannauj','Uttar Pradesh'],
        209749: ['Farrukhabad','Uttar Pradesh'],
        209801: ['Unnao','Uttar Pradesh'],
        209821: ['Unnao','Uttar Pradesh'],
        209825: ['Unnao','Uttar Pradesh'],
        209827: ['Unnao','Uttar Pradesh'],
        209831: ['Unnao','Uttar Pradesh'],
        209841: ['Unnao','Uttar Pradesh'],
        209859: ['Unnao','Uttar Pradesh'],
        209861: ['Unnao','Uttar Pradesh'],
        209862: ['Unnao','Uttar Pradesh'],
        209863: ['Mathura','Uttar Pradesh'],
        209864: ['Unnao','Uttar Pradesh'],
        209865: ['Unnao','Uttar Pradesh'],
        209866: ['Unnao','Uttar Pradesh'],
        209867: ['Unnao','Uttar Pradesh'],
        209868: ['Unnao','Uttar Pradesh'],
        209869: ['Unnao','Uttar Pradesh'],
        209870: ['Unnao','Uttar Pradesh'],
        209871: ['Unnao','Uttar Pradesh'],
        209881: ['Unnao','Uttar Pradesh'],
        210001: ['Banda','Uttar Pradesh'],
        210120: ['Banda','Uttar Pradesh'],
        210121: ['Banda','Uttar Pradesh'],
        210122: ['Banda','Uttar Pradesh'],
        210123: ['Banda','Uttar Pradesh'],
        210125: ['Banda','Uttar Pradesh'],
        210126: ['Banda','Uttar Pradesh'],
        210128: ['Banda','Uttar Pradesh'],
        210129: ['Banda','Uttar Pradesh'],
        210201: ['Banda','Uttar Pradesh'],
        210202: ['Chitrakoot','Uttar Pradesh'],
        210203: ['Banda','Uttar Pradesh'],
        210204: ['Chitrakoot','Uttar Pradesh'],
        210205: ['Chitrakoot','Uttar Pradesh'],
        210206: ['Chitrakoot','Uttar Pradesh'],
        210207: ['Chitrakoot','Uttar Pradesh'],
        210208: ['Chitrakoot','Uttar Pradesh'],
        210209: ['Chitrakoot','Uttar Pradesh'],
        210301: ['Hamirpur','Uttar Pradesh'],
        210341: ['Hamirpur','Uttar Pradesh'],
        210421: ['Mahoba','Uttar Pradesh'],
        210422: ['Hamirpur','Uttar Pradesh'],
        210423: ['Mahoba','Uttar Pradesh'],
        210424: ['Mahoba','Uttar Pradesh'],
        210425: ['Mahoba','Uttar Pradesh'],
        210426: ['Mahoba','Uttar Pradesh'],
        210427: ['Mahoba','Uttar Pradesh'],
        210428: ['Hamirpur','Uttar Pradesh'],
        210429: ['Mahoba','Uttar Pradesh'],
        210430: ['Hamirpur','Uttar Pradesh'],
        210431: ['Hamirpur','Uttar Pradesh'],
        210432: ['Hamirpur','Uttar Pradesh'],
        210433: ['Mahoba','Uttar Pradesh'],
        210501: ['Hamirpur','Uttar Pradesh'],
        210502: ['Hamirpur','Uttar Pradesh'],
        210504: ['Mahoba','Uttar Pradesh'],
        210505: ['Hamirpur','Uttar Pradesh'],
        210506: ['Hamirpur','Uttar Pradesh'],
        210507: ['Hamirpur','Uttar Pradesh'],
        211020: ['Prayagraj Allahabad','Uttar Pradesh'],
        212201: ['Kaushambi','Uttar Pradesh'],
        212218: ['Kaushambi','Uttar Pradesh'],
        212601: ['Fatehpur','Uttar Pradesh'],
        212620: ['Fatehpur','Uttar Pradesh'],
        212621: ['Fatehpur','Uttar Pradesh'],
        212622: ['Fatehpur','Uttar Pradesh'],
        212631: ['Fatehpur','Uttar Pradesh'],
        212635: ['Fatehpur','Uttar Pradesh'],
        212641: ['Fatehpur','Uttar Pradesh'],
        212645: ['Fatehpur','Uttar Pradesh'],
        212650: ['Fatehpur','Uttar Pradesh'],
        212651: ['Fatehpur','Uttar Pradesh'],
        212652: ['Fatehpur','Uttar Pradesh'],
        212653: ['Fatehpur','Uttar Pradesh'],
        212654: ['Fatehpur','Uttar Pradesh'],
        212655: ['Fatehpur','Uttar Pradesh'],
        212656: ['Fatehpur','Uttar Pradesh'],
        212657: ['Fatehpur','Uttar Pradesh'],
        212658: ['Fatehpur','Uttar Pradesh'],
        212659: ['Fatehpur','Uttar Pradesh'],
        212661: ['Fatehpur','Uttar Pradesh'],
        212663: ['Fatehpur','Uttar Pradesh'],
        212664: ['Fatehpur','Uttar Pradesh'],
        212665: ['Fatehpur','Uttar Pradesh'],
        221601: ['Mau','Uttar Pradesh'],
        221602: ['Mau','Uttar Pradesh'],
        221603: ['Mau','Uttar Pradesh'],
        221701: ['Ballia','Uttar Pradesh'],
        221705: ['Mau','Uttar Pradesh'],
        221706: ['Mau','Uttar Pradesh'],
        221709: ['Ballia','Uttar Pradesh'],
        221711: ['Ballia','Uttar Pradesh'],
        221712: ['Ballia','Uttar Pradesh'],
        221713: ['Ballia','Uttar Pradesh'],
        221715: ['Ballia','Uttar Pradesh'],
        221716: ['Ballia','Uttar Pradesh'],
        221717: ['Ballia','Uttar Pradesh'],
        221718: ['Ballia','Uttar Pradesh'],
        222001: ['Jaunpur','Uttar Pradesh'],
        222002: ['Jaunpur','Uttar Pradesh'],
        222003: ['Jaunpur','Uttar Pradesh'],
        222101: ['Jaunpur','Uttar Pradesh'],
        222105: ['Jaunpur','Uttar Pradesh'],
        222109: ['Jaunpur','Uttar Pradesh'],
        222125: ['Jaunpur','Uttar Pradesh'],
        222126: ['Jaunpur','Uttar Pradesh'],
        222127: ['Jaunpur','Uttar Pradesh'],
        222128: ['Jaunpur','Uttar Pradesh'],
        222129: ['Jaunpur','Uttar Pradesh'],
        222131: ['Jaunpur','Uttar Pradesh'],
        222132: ['Jaunpur','Uttar Pradesh'],
        222133: ['Jaunpur','Uttar Pradesh'],
        222135: ['Jaunpur','Uttar Pradesh'],
        222136: ['Jaunpur','Uttar Pradesh'],
        222137: ['Jaunpur','Uttar Pradesh'],
        222138: ['Jaunpur','Uttar Pradesh'],
        222139: ['Jaunpur','Uttar Pradesh'],
        222141: ['Jaunpur','Uttar Pradesh'],
        222142: ['Jaunpur','Uttar Pradesh'],
        222143: ['Jaunpur','Uttar Pradesh'],
        222144: ['Jaunpur','Uttar Pradesh'],
        222145: ['Jaunpur','Uttar Pradesh'],
        222146: ['Jaunpur','Uttar Pradesh'],
        222148: ['Jaunpur','Uttar Pradesh'],
        222149: ['Jaunpur','Uttar Pradesh'],
        222161: ['Jaunpur','Uttar Pradesh'],
        222162: ['Jaunpur','Uttar Pradesh'],
        222165: ['Jaunpur','Uttar Pradesh'],
        222170: ['Jaunpur','Uttar Pradesh'],
        222175: ['Jaunpur','Uttar Pradesh'],
        222180: ['Jaunpur','Uttar Pradesh'],
        222181: ['Jaunpur','Uttar Pradesh'],
        222201: ['Jaunpur','Uttar Pradesh'],
        222202: ['Jaunpur','Uttar Pradesh'],
        222203: ['Jaunpur','Uttar Pradesh'],
        222204: ['Jaunpur','Uttar Pradesh'],
        222301: ['Sultanpur','Uttar Pradesh'],
        222302: ['Sultanpur','Uttar Pradesh'],
        222303: ['Sultanpur','Uttar Pradesh'],
        223101: ['Jaunpur','Uttar Pradesh'],
        223102: ['Jaunpur','Uttar Pradesh'],
        223103: ['Jaunpur','Uttar Pradesh'],
        223104: ['Jaunpur','Uttar Pradesh'],
        223105: ['Jaunpur','Uttar Pradesh'],
        223221: ['Azamgarh','Uttar Pradesh'],
        223222: ['Azamgarh','Uttar Pradesh'],
        223223: ['Azamgarh','Uttar Pradesh'],
        223224: ['Azamgarh','Uttar Pradesh'],
        223225: ['Azamgarh','Uttar Pradesh'],
        223226: ['Azamgarh','Uttar Pradesh'],
        223227: ['Azamgarh','Uttar Pradesh'],
        224001: ['Faizabad','Uttar Pradesh'],
        224116: ['Faizabad','Uttar Pradesh'],
        224117: ['Faizabad','Uttar Pradesh'],
        224118: ['Faizabad','Uttar Pradesh'],
        224119: ['Faizabad','Uttar Pradesh'],
        224120: ['Faizabad','Uttar Pradesh'],
        224121: ['Faizabad','Uttar Pradesh'],
        224122: ['Kheri','Uttar Pradesh'],
        224123: ['Faizabad','Uttar Pradesh'],
        224125: ['Ambedkar Nagar','Uttar Pradesh'],
        224126: ['Faizabad','Uttar Pradesh'],
        224127: ['Faizabad','Uttar Pradesh'],
        224129: ['Faizabad','Uttar Pradesh'],
        224132: ['Ambedkar Nagar','Uttar Pradesh'],
        224133: ['Faizabad','Uttar Pradesh'],
        224135: ['Faizabad','Uttar Pradesh'],
        224137: ['Ambedkar Nagar','Uttar Pradesh'],
        224139: ['Ambedkar Nagar','Uttar Pradesh'],
        224141: ['Faizabad','Uttar Pradesh'],
        224143: ['Ambedkar Nagar','Uttar Pradesh'],
        224145: ['Ambedkar Nagar','Uttar Pradesh'],
        224146: ['Ambedkar Nagar','Uttar Pradesh'],
        224147: ['Faizabad','Uttar Pradesh'],
        224149: ['Ambedkar Nagar','Uttar Pradesh'],
        224151: ['Ambedkar Nagar','Uttar Pradesh'],
        224152: ['Ambedkar Nagar','Uttar Pradesh'],
        224153: ['Faizabad','Uttar Pradesh'],
        224155: ['Ambedkar Nagar','Uttar Pradesh'],
        224157: ['Ambedkar Nagar','Uttar Pradesh'],
        224158: ['Faizabad','Uttar Pradesh'],
        224159: ['Ambedkar Nagar','Uttar Pradesh'],
        224161: ['Faizabad','Uttar Pradesh'],
        224164: ['Faizabad','Uttar Pradesh'],
        224168: ['Ambedkar Nagar','Uttar Pradesh'],
        224171: ['Faizabad','Uttar Pradesh'],
        224172: ['Faizabad','Uttar Pradesh'],
        224176: ['Ambedkar Nagar','Uttar Pradesh'],
        224181: ['Ambedkar Nagar','Uttar Pradesh'],
        224182: ['Faizabad','Uttar Pradesh'],
        224183: ['Ambedkar Nagar','Uttar Pradesh'],
        224186: ['Ambedkar Nagar','Uttar Pradesh'],
        224188: ['Faizabad','Uttar Pradesh'],
        224189: ['Faizabad','Uttar Pradesh'],
        224190: ['Ambedkar Nagar','Uttar Pradesh'],
        224195: ['Faizabad','Uttar Pradesh'],
        224201: ['Faizabad','Uttar Pradesh'],
        224202: ['Faizabad','Uttar Pradesh'],
        224203: ['Faizabad','Uttar Pradesh'],
        224204: ['Faizabad','Uttar Pradesh'],
        224205: ['Faizabad','Uttar Pradesh'],
        224206: ['Faizabad','Uttar Pradesh'],
        224207: ['Faizabad','Uttar Pradesh'],
        224208: ['Faizabad','Uttar Pradesh'],
        224209: ['Faizabad','Uttar Pradesh'],
        224210: ['Ambedkar Nagar','Uttar Pradesh'],
        224225: ['Faizabad','Uttar Pradesh'],
        224227: ['Ambedkar Nagar','Uttar Pradesh'],
        224228: ['Faizabad','Uttar Pradesh'],
        224229: ['Faizabad','Uttar Pradesh'],
        224230: ['Ambedkar Nagar','Uttar Pradesh'],
        224231: ['Ambedkar Nagar','Uttar Pradesh'],
        224232: ['Ambedkar Nagar','Uttar Pradesh'],
        224234: ['Faizabad','Uttar Pradesh'],
        224235: ['Ambedkar Nagar','Uttar Pradesh'],
        224238: ['Ambedkar Nagar','Uttar Pradesh'],
        224284: ['Faizabad','Uttar Pradesh'],
        225001: ['Barabanki','Uttar Pradesh'],
        225002: ['Barabanki','Uttar Pradesh'],
        225003: ['Barabanki','Uttar Pradesh'],
        225119: ['Barabanki','Uttar Pradesh'],
        225120: ['Barabanki','Uttar Pradesh'],
        225121: ['Barabanki','Uttar Pradesh'],
        225122: ['Barabanki','Uttar Pradesh'],
        225123: ['Barabanki','Uttar Pradesh'],
        225124: ['Barabanki','Uttar Pradesh'],
        225125: ['Barabanki','Uttar Pradesh'],
        225126: ['Barabanki','Uttar Pradesh'],
        225201: ['Barabanki','Uttar Pradesh'],
        225202: ['Barabanki','Uttar Pradesh'],
        225203: ['Barabanki','Uttar Pradesh'],
        225204: ['Barabanki','Uttar Pradesh'],
        225205: ['Barabanki','Uttar Pradesh'],
        225206: ['Barabanki','Uttar Pradesh'],
        225207: ['Barabanki','Uttar Pradesh'],
        225208: ['Barabanki','Uttar Pradesh'],
        225301: ['Barabanki','Uttar Pradesh'],
        225302: ['Barabanki','Uttar Pradesh'],
        225303: ['Barabanki','Uttar Pradesh'],
        225304: ['Barabanki','Uttar Pradesh'],
        225305: ['Barabanki','Uttar Pradesh'],
        225306: ['Barabanki','Uttar Pradesh'],
        225401: ['Barabanki','Uttar Pradesh'],
        225403: ['Barabanki','Uttar Pradesh'],
        225404: ['Barabanki','Uttar Pradesh'],
        225405: ['Barabanki','Uttar Pradesh'],
        225409: ['Barabanki','Uttar Pradesh'],
        225412: ['Barabanki','Uttar Pradesh'],
        225413: ['Barabanki','Uttar Pradesh'],
        225414: ['Barabanki','Uttar Pradesh'],
        225415: ['Barabanki','Uttar Pradesh'],
        225416: ['Barabanki','Uttar Pradesh'],
        227304: ['Sultanpur','Uttar Pradesh'],
        227405: ['Amethi','Uttar Pradesh'],
        227406: ['Amethi','Uttar Pradesh'],
        227407: ['Sultanpur','Uttar Pradesh'],
        227408: ['Sultanpur','Uttar Pradesh'],
        227409: ['Amethi','Uttar Pradesh'],
        227411: ['Amethi','Uttar Pradesh'],
        227412: ['Amethi','Uttar Pradesh'],
        227413: ['Amethi','Uttar Pradesh'],
        227801: ['Amethi','Uttar Pradesh'],
        227805: ['Sultanpur','Uttar Pradesh'],
        227806: ['Amethi','Uttar Pradesh'],
        227807: ['Amethi','Uttar Pradesh'],
        227808: ['Amethi','Uttar Pradesh'],
        227809: ['Amethi','Uttar Pradesh'],
        227811: ['Amethi','Uttar Pradesh'],
        227812: ['Amethi','Uttar Pradesh'],
        227813: ['Amethi','Uttar Pradesh'],
        227814: ['Sultanpur','Uttar Pradesh'],
        227815: ['Amethi','Uttar Pradesh'],
        227816: ['Amethi','Uttar Pradesh'],
        227817: ['Amethi','Uttar Pradesh'],
        228001: ['Sultanpur','Uttar Pradesh'],
        228118: ['Sultanpur','Uttar Pradesh'],
        228119: ['Sultanpur','Uttar Pradesh'],
        228120: ['Sultanpur','Uttar Pradesh'],
        228121: ['Sultanpur','Uttar Pradesh'],
        228125: ['Sultanpur','Uttar Pradesh'],
        228131: ['Sultanpur','Uttar Pradesh'],
        228132: ['Sultanpur','Uttar Pradesh'],
        228133: ['Sultanpur','Uttar Pradesh'],
        228141: ['Sultanpur','Uttar Pradesh'],
        228142: ['Sultanpur','Uttar Pradesh'],
        228145: ['Sultanpur','Uttar Pradesh'],
        228151: ['Sultanpur','Uttar Pradesh'],
        228155: ['Amethi','Uttar Pradesh'],
        228159: ['Amethi','Uttar Pradesh'],
        228161: ['Sultanpur','Uttar Pradesh'],
        228171: ['Sultanpur','Uttar Pradesh'],
        228172: ['Sultanpur','Uttar Pradesh'],
        229001: ['Raebareli','Uttar Pradesh'],
        229010: ['Raebareli','Uttar Pradesh'],
        229103: ['Raebareli','Uttar Pradesh'],
        229120: ['Raebareli','Uttar Pradesh'],
        229121: ['Raebareli','Uttar Pradesh'],
        229122: ['Raebareli','Uttar Pradesh'],
        229123: ['Raebareli','Uttar Pradesh'],
        229124: ['Raebareli','Uttar Pradesh'],
        229125: ['Raebareli','Uttar Pradesh'],
        229126: ['Raebareli','Uttar Pradesh'],
        229127: ['Raebareli','Uttar Pradesh'],
        229128: ['Raebareli','Uttar Pradesh'],
        229129: ['Raebareli','Uttar Pradesh'],
        229130: ['Raebareli','Uttar Pradesh'],
        229135: ['Raebareli','Uttar Pradesh'],
        229201: ['Raebareli','Uttar Pradesh'],
        229202: ['Raebareli','Uttar Pradesh'],
        229203: ['Raebareli','Uttar Pradesh'],
        229204: ['Raebareli','Uttar Pradesh'],
        229205: ['Raebareli','Uttar Pradesh'],
        229206: ['Raebareli','Uttar Pradesh'],
        229207: ['Raebareli','Uttar Pradesh'],
        229208: ['Raebareli','Uttar Pradesh'],
        229209: ['Raebareli','Uttar Pradesh'],
        229210: ['Raebareli','Uttar Pradesh'],
        229211: ['Raebareli','Uttar Pradesh'],
        229212: ['Raebareli','Uttar Pradesh'],
        229215: ['Raebareli','Uttar Pradesh'],
        229216: ['Raebareli','Uttar Pradesh'],
        229301: ['Raebareli','Uttar Pradesh'],
        229302: ['Raebareli','Uttar Pradesh'],
        229303: ['Raebareli','Uttar Pradesh'],
        229304: ['Raebareli','Uttar Pradesh'],
        229305: ['Raebareli','Uttar Pradesh'],
        229306: ['Unnao','Uttar Pradesh'],
        229307: ['Raebareli','Uttar Pradesh'],
        229308: ['Raebareli','Uttar Pradesh'],
        229309: ['Raebareli','Uttar Pradesh'],
        229310: ['Raebareli','Uttar Pradesh'],
        229311: ['Raebareli','Uttar Pradesh'],
        229316: ['Raebareli','Uttar Pradesh'],
        229401: ['Raebareli','Uttar Pradesh'],
        229402: ['Raebareli','Uttar Pradesh'],
        229404: ['Raebareli','Uttar Pradesh'],
        229405: ['Raebareli','Uttar Pradesh'],
        229406: ['Raebareli','Uttar Pradesh'],
        229408: ['Pratapgarh','Uttar Pradesh'],
        229410: ['Pratapgarh','Uttar Pradesh'],
        229801: ['Raebareli','Uttar Pradesh'],
        229802: ['Raebareli','Uttar Pradesh'],
        230001: ['Pratapgarh','Uttar Pradesh'],
        230002: ['Pratapgarh','Uttar Pradesh'],
        230121: ['Pratapgarh','Uttar Pradesh'],
        230124: ['Pratapgarh','Uttar Pradesh'],
        230125: ['Pratapgarh','Uttar Pradesh'],
        230126: ['Pratapgarh','Uttar Pradesh'],
        230127: ['Pratapgarh','Uttar Pradesh'],
        230128: ['Pratapgarh','Uttar Pradesh'],
        230129: ['Pratapgarh','Uttar Pradesh'],
        230130: ['Pratapgarh','Uttar Pradesh'],
        230131: ['Pratapgarh','Uttar Pradesh'],
        230132: ['Pratapgarh','Uttar Pradesh'],
        230133: ['Pratapgarh','Uttar Pradesh'],
        230134: ['Pratapgarh','Uttar Pradesh'],
        230135: ['Pratapgarh','Uttar Pradesh'],
        230136: ['Pratapgarh','Uttar Pradesh'],
        230137: ['Pratapgarh','Uttar Pradesh'],
        230138: ['Pratapgarh','Uttar Pradesh'],
        230139: ['Pratapgarh','Uttar Pradesh'],
        230141: ['Pratapgarh','Uttar Pradesh'],
        230142: ['Pratapgarh','Uttar Pradesh'],
        230143: ['Pratapgarh','Uttar Pradesh'],
        230144: ['Pratapgarh','Uttar Pradesh'],
        230201: ['Pratapgarh','Uttar Pradesh'],
        230202: ['Pratapgarh','Uttar Pradesh'],
        230204: ['Pratapgarh','Uttar Pradesh'],
        230301: ['Pratapgarh','Uttar Pradesh'],
        230302: ['Pratapgarh','Uttar Pradesh'],
        230304: ['Pratapgarh','Uttar Pradesh'],
        230306: ['Pratapgarh','Uttar Pradesh'],
        230401: ['Pratapgarh','Uttar Pradesh'],
        230402: ['Pratapgarh','Uttar Pradesh'],
        230403: ['Pratapgarh','Uttar Pradesh'],
        230404: ['Pratapgarh','Uttar Pradesh'],
        230405: ['Pratapgarh','Uttar Pradesh'],
        230501: ['Pratapgarh','Uttar Pradesh'],
        230502: ['Pratapgarh','Uttar Pradesh'],
        230503: ['Pratapgarh','Uttar Pradesh'],
        231001: ['Mirzapur','Uttar Pradesh'],
        231205: ['Sonbhadra','Uttar Pradesh'],
        231206: ['Sonbhadra','Uttar Pradesh'],
        231207: ['Sonbhadra','Uttar Pradesh'],
        231208: ['Sonbhadra','Uttar Pradesh'],
        231209: ['Sonbhadra','Uttar Pradesh'],
        231210: ['Mirzapur','Uttar Pradesh'],
        231211: ['Mirzapur','Uttar Pradesh'],
        231212: ['Sonbhadra','Uttar Pradesh'],
        231213: ['Sonbhadra','Uttar Pradesh'],
        231215: ['Sonbhadra','Uttar Pradesh'],
        231216: ['Sonbhadra','Uttar Pradesh'],
        231217: ['Sonbhadra','Uttar Pradesh'],
        231218: ['Sonbhadra','Uttar Pradesh'],
        231219: ['Sonbhadra','Uttar Pradesh'],
        231220: ['Sonbhadra','Uttar Pradesh'],
        231221: ['Sonbhadra','Uttar Pradesh'],
        231222: ['Sonbhadra','Uttar Pradesh'],
        231223: ['Sonbhadra','Uttar Pradesh'],
        231224: ['Sonbhadra','Uttar Pradesh'],
        231225: ['Sonbhadra','Uttar Pradesh'],
        231226: ['Sonbhadra','Uttar Pradesh'],
        231301: ['Mirzapur','Uttar Pradesh'],
        231302: ['Mirzapur','Uttar Pradesh'],
        231303: ['Mirzapur','Uttar Pradesh'],
        231304: ['Mirzapur','Uttar Pradesh'],
        231305: ['Mirzapur','Uttar Pradesh'],
        231306: ['Mirzapur','Uttar Pradesh'],
        231307: ['Mirzapur','Uttar Pradesh'],
        231309: ['Mirzapur','Uttar Pradesh'],
        231311: ['Mirzapur','Uttar Pradesh'],
        231312: ['Mirzapur','Uttar Pradesh'],
        231313: ['Mirzapur','Uttar Pradesh'],
        231314: ['Mirzapur','Uttar Pradesh'],
        231501: ['Mirzapur','Uttar Pradesh'],
        232325: ['Ghazipur','Uttar Pradesh'],
        232326: ['Ghazipur','Uttar Pradesh'],
        232327: ['Varanasi','Uttar Pradesh'],
        232328: ['Ghazipur','Uttar Pradesh'],
        232329: ['Ghazipur','Uttar Pradesh'],
        232330: ['Ghazipur','Uttar Pradesh'],
        232331: ['Ghazipur','Uttar Pradesh'],
        232332: ['Ghazipur','Uttar Pradesh'],
        232333: ['Ghazipur','Uttar Pradesh'],
        232336: ['Ghazipur','Uttar Pradesh'],
        232339: ['Ghazipur','Uttar Pradesh'],
        232340: ['Ghazipur','Uttar Pradesh'],
        232341: ['Ghazipur','Uttar Pradesh'],
        233001: ['Ghazipur','Uttar Pradesh'],
        233002: ['Ghazipur','Uttar Pradesh'],
        233221: ['Ghazipur','Uttar Pradesh'],
        233222: ['Ghazipur','Uttar Pradesh'],
        233223: ['Ghazipur','Uttar Pradesh'],
        233224: ['Ghazipur','Uttar Pradesh'],
        233225: ['Ghazipur','Uttar Pradesh'],
        233226: ['Ghazipur','Uttar Pradesh'],
        233227: ['Ghazipur','Uttar Pradesh'],
        233228: ['Ghazipur','Uttar Pradesh'],
        233229: ['Ghazipur','Uttar Pradesh'],
        233230: ['Ghazipur','Uttar Pradesh'],
        233231: ['Ghazipur','Uttar Pradesh'],
        233232: ['Ghazipur','Uttar Pradesh'],
        233233: ['Ghazipur','Uttar Pradesh'],
        233234: ['Ghazipur','Uttar Pradesh'],
        233300: ['Ghazipur','Uttar Pradesh'],
        233301: ['Ghazipur','Uttar Pradesh'],
        233302: ['Ghazipur','Uttar Pradesh'],
        233303: ['Ghazipur','Uttar Pradesh'],
        233304: ['Ghazipur','Uttar Pradesh'],
        233305: ['Ghazipur','Uttar Pradesh'],
        233306: ['Ghazipur','Uttar Pradesh'],
        233307: ['Ghazipur','Uttar Pradesh'],
        233310: ['Ghazipur','Uttar Pradesh'],
        233311: ['Ghazipur','Uttar Pradesh'],
        241001: ['Mathura','Uttar Pradesh'],
        241121: ['Hardoi','Uttar Pradesh'],
        241122: ['Hardoi','Uttar Pradesh'],
        241123: ['Hardoi','Uttar Pradesh'],
        241124: ['Hardoi','Uttar Pradesh'],
        241125: ['Hardoi','Uttar Pradesh'],
        241126: ['Hardoi','Uttar Pradesh'],
        241127: ['Hardoi','Uttar Pradesh'],
        241201: ['Hardoi','Uttar Pradesh'],
        241202: ['Hardoi','Uttar Pradesh'],
        241203: ['Hardoi','Uttar Pradesh'],
        241204: ['Hardoi','Uttar Pradesh'],
        241301: ['Hardoi','Uttar Pradesh'],
        241302: ['Hardoi','Uttar Pradesh'],
        241303: ['Hardoi','Uttar Pradesh'],
        241304: ['Hardoi','Uttar Pradesh'],
        241305: ['Hardoi','Uttar Pradesh'],
        241401: ['Hardoi','Uttar Pradesh'],
        241402: ['Hardoi','Uttar Pradesh'],
        241403: ['Hardoi','Uttar Pradesh'],
        241404: ['Hardoi','Uttar Pradesh'],
        241405: ['Hardoi','Uttar Pradesh'],
        241406: ['Hardoi','Uttar Pradesh'],
        241407: ['Hardoi','Uttar Pradesh'],
        242001: ['Shahjahanpur','Uttar Pradesh'],
        242021: ['Budaun','Uttar Pradesh'],
        242042: ['Shahjahanpur','Uttar Pradesh'],
        242123: ['Pilibhit','Uttar Pradesh'],
        242127: ['Shahjahanpur','Uttar Pradesh'],
        242220: ['Shahjahanpur','Uttar Pradesh'],
        242221: ['Shahjahanpur','Uttar Pradesh'],
        242223: ['Shahjahanpur','Uttar Pradesh'],
        242226: ['Shahjahanpur','Uttar Pradesh'],
        242301: ['Shahjahanpur','Uttar Pradesh'],
        242303: ['Shahjahanpur','Uttar Pradesh'],
        242305: ['Shahjahanpur','Uttar Pradesh'],
        242306: ['Shahjahanpur','Uttar Pradesh'],
        242307: ['Shahjahanpur','Uttar Pradesh'],
        242401: ['Shahjahanpur','Uttar Pradesh'],
        242405: ['Shahjahanpur','Uttar Pradesh'],
        242406: ['Shahjahanpur','Uttar Pradesh'],
        242407: ['Shahjahanpur','Uttar Pradesh'],
        243001: ['Bareilly','Uttar Pradesh'],
        243002: ['Bareilly','Uttar Pradesh'],
        243003: ['Bareilly','Uttar Pradesh'],
        243004: ['Bareilly','Uttar Pradesh'],
        243005: ['Bareilly','Uttar Pradesh'],
        243006: ['Bareilly','Uttar Pradesh'],
        243122: ['Bareilly','Uttar Pradesh'],
        243123: ['Bareilly','Uttar Pradesh'],
        243126: ['Bareilly','Uttar Pradesh'],
        243201: ['Bareilly','Uttar Pradesh'],
        243202: ['Bareilly','Uttar Pradesh'],
        243203: ['Bareilly','Uttar Pradesh'],
        243301: ['Bareilly','Uttar Pradesh'],
        243302: ['Bareilly','Uttar Pradesh'],
        243303: ['Bareilly','Uttar Pradesh'],
        243401: ['Bareilly','Uttar Pradesh'],
        243402: ['Bareilly','Uttar Pradesh'],
        243403: ['Bareilly','Uttar Pradesh'],
        243407: ['Bareilly','Uttar Pradesh'],
        243501: ['Bareilly','Uttar Pradesh'],
        243502: ['Bareilly','Uttar Pradesh'],
        243503: ['Bareilly','Uttar Pradesh'],
        243504: ['Bareilly','Uttar Pradesh'],
        243505: ['Bareilly','Uttar Pradesh'],
        243506: ['Bareilly','Uttar Pradesh'],
        243601: ['Budaun','Uttar Pradesh'],
        243630: ['Budaun','Uttar Pradesh'],
        243631: ['Budaun','Uttar Pradesh'],
        243632: ['Budaun','Uttar Pradesh'],
        243633: ['Budaun','Uttar Pradesh'],
        243634: ['Budaun','Uttar Pradesh'],
        243635: ['Budaun','Uttar Pradesh'],
        243636: ['Budaun','Uttar Pradesh'],
        243637: ['Budaun','Uttar Pradesh'],
        243638: ['Budaun','Uttar Pradesh'],
        243639: ['Budaun','Uttar Pradesh'],
        243641: ['Budaun','Uttar Pradesh'],
        243720: ['Budaun','Uttar Pradesh'],
        243722: ['Budaun','Uttar Pradesh'],
        243723: ['Budaun','Uttar Pradesh'],
        243724: ['Budaun','Uttar Pradesh'],
        243725: ['Budaun','Uttar Pradesh'],
        243726: ['Budaun','Uttar Pradesh'],
        243727: ['Budaun','Uttar Pradesh'],
        243751: ['Budaun','Uttar Pradesh'],
        244001: ['Moradabad','Uttar Pradesh'],
        244102: ['Amroha','Uttar Pradesh'],
        244103: ['Moradabad','Uttar Pradesh'],
        244104: ['Moradabad','Uttar Pradesh'],
        244105: ['Moradabad','Uttar Pradesh'],
        244221: ['Amroha','Uttar Pradesh'],
        244222: ['Amroha','Uttar Pradesh'],
        244223: ['Amroha','Uttar Pradesh'],
        244231: ['Amroha','Uttar Pradesh'],
        244235: ['Amroha','Uttar Pradesh'],
        244236: ['Amroha','Uttar Pradesh'],
        244241: ['Amroha','Uttar Pradesh'],
        244242: ['Amroha','Uttar Pradesh'],
        244245: ['Amroha','Uttar Pradesh'],
        244251: ['Amroha','Uttar Pradesh'],
        244255: ['Amroha','Uttar Pradesh'],
        244301: ['Sambhal','Uttar Pradesh'],
        244302: ['Sambhal','Uttar Pradesh'],
        244303: ['Sambhal','Uttar Pradesh'],
        244304: ['Sambhal','Uttar Pradesh'],
        244401: ['Moradabad','Uttar Pradesh'],
        244402: ['Moradabad','Uttar Pradesh'],
        244410: ['Sambhal','Uttar Pradesh'],
        244411: ['Moradabad','Uttar Pradesh'],
        244412: ['Sambhal','Uttar Pradesh'],
        244413: ['Moradabad','Uttar Pradesh'],
        244414: ['Sambhal','Uttar Pradesh'],
        244415: ['Moradabad','Uttar Pradesh'],
        244501: ['Moradabad','Uttar Pradesh'],
        244504: ['Moradabad','Uttar Pradesh'],
        244601: ['Moradabad','Uttar Pradesh'],
        244602: ['Moradabad','Uttar Pradesh'],
        244701: ['Rampur','Uttar Pradesh'],
        244901: ['Rampur','Uttar Pradesh'],
        244921: ['Rampur','Uttar Pradesh'],
        244922: ['Rampur','Uttar Pradesh'],
        244923: ['Rampur','Uttar Pradesh'],
        244924: ['Rampur','Uttar Pradesh'],
        244925: ['Rampur','Uttar Pradesh'],
        244926: ['Rampur','Uttar Pradesh'],
        244927: ['Rampur','Uttar Pradesh'],
        244928: ['Rampur','Uttar Pradesh'],
        245206: ['Meerut','Uttar Pradesh'],
        246150: ['Pauri Garhwal','Uttarakhand'],
        246178: ['Pauri Garhwal','Uttarakhand'],
        246701: ['Bijnor','Uttar Pradesh'],
        246721: ['Bijnor','Uttar Pradesh'],
        246722: ['Bijnor','Uttar Pradesh'],
        246723: ['Bijnor','Uttar Pradesh'],
        246724: ['Bijnor','Uttar Pradesh'],
        246725: ['Bijnor','Uttar Pradesh'],
        246726: ['Bijnor','Uttar Pradesh'],
        246727: ['Bijnor','Uttar Pradesh'],
        246728: ['Bijnor','Uttar Pradesh'],
        246729: ['Bijnor','Uttar Pradesh'],
        246731: ['Bijnor','Uttar Pradesh'],
        246732: ['Bijnor','Uttar Pradesh'],
        246733: ['Bijnor','Uttar Pradesh'],
        246734: ['Bijnor','Uttar Pradesh'],
        246735: ['Bijnor','Uttar Pradesh'],
        246736: ['Bijnor','Uttar Pradesh'],
        246737: ['Bijnor','Uttar Pradesh'],
        246745: ['Bijnor','Uttar Pradesh'],
        246746: ['Bijnor','Uttar Pradesh'],
        246747: ['Bijnor','Uttar Pradesh'],
        246749: ['Bijnor','Uttar Pradesh'],
        246761: ['Aligarh','Uttar Pradesh'],
        246762: ['Bijnor','Uttar Pradesh'],
        246763: ['Bijnor','Uttar Pradesh'],
        246764: ['Bijnor','Uttar Pradesh'],
        247001: ['Saharanpur','Uttar Pradesh'],
        247002: ['Saharanpur','Uttar Pradesh'],
        247120: ['Saharanpur','Uttar Pradesh'],
        247121: ['Saharanpur','Uttar Pradesh'],
        247122: ['Saharanpur','Uttar Pradesh'],
        247129: ['Saharanpur','Uttar Pradesh'],
        247231: ['Saharanpur','Uttar Pradesh'],
        247232: ['Saharanpur','Uttar Pradesh'],
        247340: ['Saharanpur','Uttar Pradesh'],
        247341: ['Saharanpur','Uttar Pradesh'],
        247342: ['Saharanpur','Uttar Pradesh'],
        247343: ['Saharanpur','Uttar Pradesh'],
        247451: ['Saharanpur','Uttar Pradesh'],
        247452: ['Saharanpur','Uttar Pradesh'],
        247453: ['Saharanpur','Uttar Pradesh'],
        247551: ['Saharanpur','Uttar Pradesh'],
        247554: ['Saharanpur','Uttar Pradesh'],
        247662: ['Haridwar','Uttar Pradesh'],
        247669: ['Saharanpur','Uttar Pradesh'],
        247771: ['Muzaffarnagar','Uttar Pradesh'],
        247772: ['Muzaffarnagar','Uttar Pradesh'],
        247773: ['Muzaffarnagar','Uttar Pradesh'],
        247774: ['Muzaffarnagar','Uttar Pradesh'],
        247775: ['Muzaffarnagar','Uttar Pradesh'],
        247776: ['Muzaffarnagar','Uttar Pradesh'],
        247777: ['Muzaffarnagar','Uttar Pradesh'],
        247778: ['Muzaffarnagar','Uttar Pradesh'],
        248016: ['Dehradun','Uttarakhand'],
        248018: ['Dehradun','Uttarakhand'],
        249127: ['Tehri Garhwal','Uttarakhand'],
        249136: ['Uttarkashi','Uttarakhand'],
        250001: ['Meerut','Uttar Pradesh'],
        250002: ['Meerut','Uttar Pradesh'],
        250003: ['Meerut','Uttar Pradesh'],
        250004: ['Meerut','Uttar Pradesh'],
        250005: ['Meerut','Uttar Pradesh'],
        250101: ['Bagpat','Uttar Pradesh'],
        250103: ['Meerut','Uttar Pradesh'],
        250104: ['Meerut','Uttar Pradesh'],
        250106: ['Meerut','Uttar Pradesh'],
        250110: ['Meerut','Uttar Pradesh'],
        250205: ['Meerut','Uttar Pradesh'],
        250221: ['Meerut','Uttar Pradesh'],
        250222: ['Meerut','Uttar Pradesh'],
        250223: ['Meerut','Uttar Pradesh'],
        250341: ['Meerut','Uttar Pradesh'],
        250342: ['Meerut','Uttar Pradesh'],
        250344: ['Meerut','Uttar Pradesh'],
        250345: ['Bagpat','Uttar Pradesh'],
        250401: ['Meerut','Uttar Pradesh'],
        250402: ['Meerut','Uttar Pradesh'],
        250404: ['Meerut','Uttar Pradesh'],
        250406: ['Meerut','Uttar Pradesh'],
        250501: ['Meerut','Uttar Pradesh'],
        250502: ['Meerut','Uttar Pradesh'],
        250601: ['Bagpat','Uttar Pradesh'],
        250606: ['Bagpat','Uttar Pradesh'],
        250609: ['Bagpat','Uttar Pradesh'],
        250611: ['Hathras','Uttar Pradesh'],
        250615: ['Bagpat','Uttar Pradesh'],
        250617: ['Bagpat','Uttar Pradesh'],
        250619: ['Bagpat','Uttar Pradesh'],
        250620: ['Bagpat','Uttar Pradesh'],
        250621: ['Bagpat','Uttar Pradesh'],
        250622: ['Bagpat','Uttar Pradesh'],
        250623: ['Bagpat','Uttar Pradesh'],
        250625: ['Bagpat','Uttar Pradesh'],
        250626: ['Bagpat','Uttar Pradesh'],
        251001: ['Muzaffarnagar','Uttar Pradesh'],
        251002: ['Muzaffarnagar','Uttar Pradesh'],
        251003: ['Muzaffarnagar','Uttar Pradesh'],
        251201: ['Muzaffarnagar','Uttar Pradesh'],
        251202: ['Muzaffarnagar','Uttar Pradesh'],
        251203: ['Muzaffarnagar','Uttar Pradesh'],
        251301: ['Muzaffarnagar','Uttar Pradesh'],
        251305: ['Muzaffarnagar','Uttar Pradesh'],
        251306: ['Muzaffarnagar','Uttar Pradesh'],
        251307: ['Muzaffarnagar','Uttar Pradesh'],
        251308: ['Muzaffarnagar','Uttar Pradesh'],
        251309: ['Muzaffarnagar','Uttar Pradesh'],
        251310: ['Muzaffarnagar','Uttar Pradesh'],
        251311: ['Muzaffarnagar','Uttar Pradesh'],
        251314: ['Muzaffarnagar','Uttar Pradesh'],
        251315: ['Muzaffarnagar','Uttar Pradesh'],
        251316: ['Muzaffarnagar','Uttar Pradesh'],
        251318: ['Muzaffarnagar','Uttar Pradesh'],
        251319: ['Muzaffarnagar','Uttar Pradesh'],
        251320: ['Muzaffarnagar','Uttar Pradesh'],
        251327: ['Muzaffarnagar','Uttar Pradesh'],
        261001: ['Sitapur','Uttar Pradesh'],
        261121: ['Sitapur','Uttar Pradesh'],
        261125: ['Sitapur','Uttar Pradesh'],
        261131: ['Sitapur','Uttar Pradesh'],
        261135: ['Sitapur','Uttar Pradesh'],
        261136: ['Sitapur','Uttar Pradesh'],
        261141: ['Sitapur','Uttar Pradesh'],
        261145: ['Sitapur','Uttar Pradesh'],
        261151: ['Sitapur','Uttar Pradesh'],
        261201: ['Sitapur','Uttar Pradesh'],
        261202: ['Sitapur','Uttar Pradesh'],
        261203: ['Sitapur','Uttar Pradesh'],
        261204: ['Sitapur','Uttar Pradesh'],
        261205: ['Sitapur','Uttar Pradesh'],
        261206: ['Sitapur','Uttar Pradesh'],
        261207: ['Sitapur','Uttar Pradesh'],
        261208: ['Sitapur','Uttar Pradesh'],
        261301: ['Sitapur','Uttar Pradesh'],
        261302: ['Sitapur','Uttar Pradesh'],
        261303: ['Sitapur','Uttar Pradesh'],
        261401: ['Sitapur','Uttar Pradesh'],
        261402: ['Sitapur','Uttar Pradesh'],
        261403: ['Sitapur','Uttar Pradesh'],
        261404: ['Sitapur','Uttar Pradesh'],
        261405: ['Sitapur','Uttar Pradesh'],
        261501: ['Kheri','Uttar Pradesh'],
        261502: ['Kheri','Uttar Pradesh'],
        261505: ['Kheri','Uttar Pradesh'],
        261506: ['Kheri','Uttar Pradesh'],
        262001: ['Pilibhit','Uttar Pradesh'],
        262121: ['Pilibhit','Uttar Pradesh'],
        262122: ['Pilibhit','Uttar Pradesh'],
        262124: ['Pilibhit','Uttar Pradesh'],
        262201: ['Pilibhit','Uttar Pradesh'],
        262202: ['Pilibhit','Uttar Pradesh'],
        262203: ['Pilibhit','Uttar Pradesh'],
        262302: ['Pilibhit','Uttar Pradesh'],
        262305: ['Pilibhit','Uttar Pradesh'],
        262406: ['Bareilly','Uttar Pradesh'],
        262572: ['Pithoragarh','Uttarakhand'],
        262701: ['Kheri','Uttar Pradesh'],
        262702: ['Kheri','Uttar Pradesh'],
        262721: ['Kheri','Uttar Pradesh'],
        262722: ['Kheri','Uttar Pradesh'],
        262723: ['Kheri','Uttar Pradesh'],
        262724: ['Kheri','Uttar Pradesh'],
        262725: ['Kheri','Uttar Pradesh'],
        262726: ['Kheri','Uttar Pradesh'],
        262727: ['Kheri','Uttar Pradesh'],
        262728: ['Kheri','Uttar Pradesh'],
        262801: ['Kheri','Uttar Pradesh'],
        262802: ['Kheri','Uttar Pradesh'],
        262803: ['Kheri','Uttar Pradesh'],
        262804: ['Kheri','Uttar Pradesh'],
        262805: ['Kheri','Uttar Pradesh'],
        262901: ['Kheri','Uttar Pradesh'],
        262902: ['Kheri','Uttar Pradesh'],
        262903: ['Kheri','Uttar Pradesh'],
        262904: ['Kheri','Uttar Pradesh'],
        262905: ['Kheri','Uttar Pradesh'],
        262906: ['Kheri','Uttar Pradesh'],
        262907: ['Aligarh','Uttar Pradesh'],
        262908: ['Kheri','Uttar Pradesh'],
        263142: ['Nainital','Uttarakhand'],
        271001: ['Gonda','Uttar Pradesh'],
        271002: ['Gonda','Uttar Pradesh'],
        271003: ['Gonda','Uttar Pradesh'],
        271122: ['Gonda','Uttar Pradesh'],
        271123: ['Gonda','Uttar Pradesh'],
        271124: ['Gonda','Uttar Pradesh'],
        271125: ['Gonda','Uttar Pradesh'],
        271126: ['Gonda','Uttar Pradesh'],
        271129: ['Gonda','Uttar Pradesh'],
        271201: ['Balrampur','Uttar Pradesh'],
        271202: ['Gonda','Uttar Pradesh'],
        271203: ['Balrampur','Uttar Pradesh'],
        271204: ['Balrampur','Uttar Pradesh'],
        271205: ['Balrampur','Uttar Pradesh'],
        271206: ['Balrampur','Uttar Pradesh'],
        271207: ['Balrampur','Uttar Pradesh'],
        271208: ['Balrampur','Uttar Pradesh'],
        271209: ['Balrampur','Uttar Pradesh'],
        271210: ['Balrampur','Uttar Pradesh'],
        271215: ['Balrampur','Uttar Pradesh'],
        271301: ['Gonda','Uttar Pradesh'],
        271302: ['Gonda','Uttar Pradesh'],
        271303: ['Gonda','Uttar Pradesh'],
        271304: ['Gonda','Uttar Pradesh'],
        271305: ['Gonda','Uttar Pradesh'],
        271306: ['Gonda','Uttar Pradesh'],
        271307: ['Gonda','Uttar Pradesh'],
        271308: ['Gonda','Uttar Pradesh'],
        271309: ['Gonda','Uttar Pradesh'],
        271310: ['Gonda','Uttar Pradesh'],
        271311: ['Gonda','Uttar Pradesh'],
        271312: ['Gonda','Uttar Pradesh'],
        271313: ['Gonda','Uttar Pradesh'],
        271319: ['Gonda','Uttar Pradesh'],
        271401: ['Gonda','Uttar Pradesh'],
        271402: ['Gonda','Uttar Pradesh'],
        271403: ['Gonda','Uttar Pradesh'],
        271502: ['Gonda','Uttar Pradesh'],
        271503: ['Gonda','Uttar Pradesh'],
        271504: ['Gonda','Uttar Pradesh'],
        271601: ['Gonda','Uttar Pradesh'],
        271602: ['Gonda','Uttar Pradesh'],
        271603: ['Gonda','Uttar Pradesh'],
        271604: ['Balrampur','Uttar Pradesh'],
        271607: ['Balrampur','Uttar Pradesh'],
        271609: ['Balrampur','Uttar Pradesh'],
        271801: ['Bahraich','Uttar Pradesh'],
        271802: ['Bahraich','Uttar Pradesh'],
        271803: ['Shrawasti','Uttar Pradesh'],
        271804: ['Shrawasti','Uttar Pradesh'],
        271805: ['Shrawasti','Uttar Pradesh'],
        271806: ['Bahraich','Uttar Pradesh'],
        271821: ['Bahraich','Uttar Pradesh'],
        271824: ['Bahraich','Uttar Pradesh'],
        271825: ['Bahraich','Uttar Pradesh'],
        271830: ['Bahraich','Uttar Pradesh'],
        271831: ['Shrawasti','Uttar Pradesh'],
        271835: ['Shrawasti','Uttar Pradesh'],
        271840: ['Shrawasti','Uttar Pradesh'],
        271841: ['Bahraich','Uttar Pradesh'],
        271845: ['Shrawasti','Uttar Pradesh'],
        271851: ['Bahraich','Uttar Pradesh'],
        271855: ['Bahraich','Uttar Pradesh'],
        271861: ['Balrampur','Uttar Pradesh'],
        271865: ['Bahraich','Uttar Pradesh'],
        271870: ['Bahraich','Uttar Pradesh'],
        271871: ['Bahraich','Uttar Pradesh'],
        271872: ['Bahraich','Uttar Pradesh'],
        271875: ['Bahraich','Uttar Pradesh'],
        271881: ['Bahraich','Uttar Pradesh'],
        271882: ['Bahraich','Uttar Pradesh'],
        271901: ['Bahraich','Uttar Pradesh'],
        271902: ['Bahraich','Uttar Pradesh'],
        271903: ['Bahraich','Uttar Pradesh'],
        271904: ['Bahraich','Uttar Pradesh'],
        272001: ['Basti','Uttar Pradesh'],
        272002: ['Basti','Uttar Pradesh'],
        272123: ['Basti','Uttar Pradesh'],
        272124: ['Basti','Uttar Pradesh'],
        272125: ['Sant Kabir Nagar','Uttar Pradesh'],
        272126: ['Sant Kabir Nagar','Uttar Pradesh'],
        272127: ['Basti','Uttar Pradesh'],
        272128: ['Basti','Uttar Pradesh'],
        272129: ['Basti','Daman And Diu'],
        272130: ['Basti','Uttar Pradesh'],
        272131: ['Basti','Uttar Pradesh'],
        272148: ['Basti','Uttar Pradesh'],
        272150: ['Basti','Uttar Pradesh'],
        272151: ['Basti','Uttar Pradesh'],
        272152: ['Siddharthnagar','Uttar Pradesh'],
        272153: ['Mathura','Uttar Pradesh'],
        272154: ['Sant Kabir Nagar','Uttar Pradesh'],
        272155: ['Basti','Uttar Pradesh'],
        272161: ['Basti','Uttar Pradesh'],
        272162: ['Sant Kabir Nagar','Uttar Pradesh'],
        272163: ['Basti','Uttar Pradesh'],
        272164: ['Sant Kabir Nagar','Uttar Pradesh'],
        272165: ['Sant Kabir Nagar','Uttar Pradesh'],
        272171: ['Basti','Uttar Pradesh'],
        272172: ['Sant Kabir Nagar','Uttar Pradesh'],
        272173: ['Sant Kabir Nagar','Uttar Pradesh'],
        272175: ['Sant Kabir Nagar','Uttar Pradesh'],
        272176: ['Sant Kabir Nagar','Uttar Pradesh'],
        272177: ['Basti','Uttar Pradesh'],
        272178: ['Basti','Uttar Pradesh'],
        272181: ['Basti','Uttar Pradesh'],
        272182: ['Basti','Uttar Pradesh'],
        272189: ['Siddharthnagar','Uttar Pradesh'],
        272190: ['Basti','Uttar Pradesh'],
        272191: ['Siddharthnagar','Uttar Pradesh'],
        272192: ['Siddharthnagar','Uttar Pradesh'],
        272193: ['Siddharthnagar','Uttar Pradesh'],
        272194: ['Basti','Uttar Pradesh'],
        272195: ['Siddharthnagar','Uttar Pradesh'],
        272199: ['Sant Kabir Nagar','Uttar Pradesh'],
        272201: ['Siddharthnagar','Uttar Pradesh'],
        272202: ['Siddharthnagar','Uttar Pradesh'],
        272203: ['Siddharthnagar','Uttar Pradesh'],
        272204: ['Siddharthnagar','Uttar Pradesh'],
        272205: ['Siddharthnagar','Uttar Pradesh'],
        272206: ['Siddharthnagar','Uttar Pradesh'],
        272207: ['Siddharthnagar','Uttar Pradesh'],
        272208: ['Siddharthnagar','Uttar Pradesh'],
        272270: ['Sant Kabir Nagar','Uttar Pradesh'],
        272271: ['Sant Kabir Nagar','Uttar Pradesh'],
        272301: ['Basti','Uttar Pradesh'],
        272302: ['Basti','Uttar Pradesh'],
        273001: ['Gorakhpur','Uttar Pradesh'],
        273002: ['Gorakhpur','Uttar Pradesh'],
        273003: ['Gorakhpur','Uttar Pradesh'],
        273004: ['Gorakhpur','Uttar Pradesh'],
        273005: ['Gorakhpur','Uttar Pradesh'],
        273006: ['Gorakhpur','Uttar Pradesh'],
        273007: ['Gorakhpur','Uttar Pradesh'],
        273008: ['Gorakhpur','Uttar Pradesh'],
        273009: ['Gorakhpur','Uttar Pradesh'],
        273010: ['Gorakhpur','Uttar Pradesh'],
        273012: ['Gorakhpur','Uttar Pradesh'],
        273013: ['Gorakhpur','Uttar Pradesh'],
        273014: ['Gorakhpur','Uttar Pradesh'],
        273015: ['Gorakhpur','Uttar Pradesh'],
        273016: ['Gorakhpur','Uttar Pradesh'],
        273017: ['Gorakhpur','Uttar Pradesh'],
        273151: ['Maharajganj','Uttar Pradesh'],
        273152: ['Gorakhpur','Uttar Pradesh'],
        273155: ['Mahrajganj','Uttar Pradesh'],
        273157: ['Maharajganj','Uttar Pradesh'],
        273158: ['Gorakhpur','Uttar Pradesh'],
        273161: ['Maharajganj','Uttar Pradesh'],
        273162: ['Maharajganj','Uttar Pradesh'],
        273163: ['Maharajganj','Uttar Pradesh'],
        273164: ['Maharajganj','Uttar Pradesh'],
        273165: ['Gorakhpur','Uttar Pradesh'],
        273201: ['Gorakhpur','Uttar Pradesh'],
        273202: ['Gorakhpur','Uttar Pradesh'],
        273203: ['Gorakhpur','Uttar Pradesh'],
        273207: ['Maharajganj','Uttar Pradesh'],
        273209: ['Gorakhpur','Uttar Pradesh'],
        273211: ['Gorakhpur','Uttar Pradesh'],
        273212: ['Gorakhpur','Uttar Pradesh'],
        273213: ['Gorakhpur','Uttar Pradesh'],
        273301: ['Gorakhpur','Uttar Pradesh'],
        273302: ['Gorakhpur','Uttar Pradesh'],
        273303: ['Maharajganj','Uttar Pradesh'],
        273304: ['Maharajganj','Uttar Pradesh'],
        273305: ['Maharajganj','Uttar Pradesh'],
        273306: ['Gorakhpur','Uttar Pradesh'],
        273308: ['Maharajganj','Uttar Pradesh'],
        273309: ['Maharajganj','Uttar Pradesh'],
        273310: ['Maharajganj','Uttar Pradesh'],
        273311: ['Maharajganj','Uttar Pradesh'],
        273401: ['Gorakhpur','Uttar Pradesh'],
        273402: ['Gorakhpur','Uttar Pradesh'],
        273403: ['Gorakhpur','Uttar Pradesh'],
        273404: ['Gorakhpur','Uttar Pradesh'],
        273405: ['Gorakhpur','Uttar Pradesh'],
        273406: ['Gorakhpur','Uttar Pradesh'],
        273407: ['Gorakhpur','Uttar Pradesh'],
        273408: ['Gorakhpur','Uttar Pradesh'],
        273409: ['Gorakhpur','Uttar Pradesh'],
        273411: ['Gorakhpur','Uttar Pradesh'],
        273412: ['Gorakhpur','Uttar Pradesh'],
        273413: ['Gorakhpur','Uttar Pradesh'],
        274001: ['Deoria','Uttar Pradesh'],
        274149: ['Kushinagar','Uttar Pradesh'],
        274182: ['Deoria','Uttar Pradesh'],
        274201: ['Deoria','Uttar Pradesh'],
        274202: ['Deoria','Uttar Pradesh'],
        274203: ['Deoria','Uttar Pradesh'],
        274205: ['Deoria','Uttar Pradesh'],
        274206: ['Deoria','Uttar Pradesh'],
        274207: ['Kushinagar','Uttar Pradesh'],
        274208: ['Deoria','Uttar Pradesh'],
        274301: ['Kushinagar','Uttar Pradesh'],
        274302: ['Kushinagar','Uttar Pradesh'],
        274303: ['Kushinagar','Uttar Pradesh'],
        274304: ['Kushinagar','Uttar Pradesh'],
        274305: ['Kushinagar','Uttar Pradesh'],
        274306: ['Kushinagar','Uttar Pradesh'],
        274307: ['Kushinagar','Uttar Pradesh'],
        274308: ['Kushinagar','Uttar Pradesh'],
        274309: ['Kushinagar','Uttar Pradesh'],
        274401: ['Kushinagar','Uttar Pradesh'],
        274402: ['Kushinagar','Uttar Pradesh'],
        274403: ['Kushinagar','Uttar Pradesh'],
        274404: ['Deoria','Uttar Pradesh'],
        274405: ['Deoria','Uttar Pradesh'],
        274406: ['Kushinagar','Uttar Pradesh'],
        274407: ['Kushinagar','Uttar Pradesh'],
        274408: ['Deoria','Uttar Pradesh'],
        274409: ['Kushinagar','Uttar Pradesh'],
        274501: ['Deoria','Uttar Pradesh'],
        274502: ['Deoria','Uttar Pradesh'],
        274505: ['Deoria','Uttar Pradesh'],
        274506: ['Deoria','Uttar Pradesh'],
        274508: ['Deoria','Uttar Pradesh'],
        274509: ['Deoria','Uttar Pradesh'],
        274601: ['Deoria','Uttar Pradesh'],
        274602: ['Deoria','Uttar Pradesh'],
        274603: ['Deoria','Uttar Pradesh'],
        274604: ['Deoria','Uttar Pradesh'],
        274701: ['Deoria','Uttar Pradesh'],
        274702: ['Deoria','Uttar Pradesh'],
        274703: ['Deoria','Uttar Pradesh'],
        274704: ['Deoria','Uttar Pradesh'],
        274705: ['Deoria','Uttar Pradesh'],
        274801: ['Kushinagar','Uttar Pradesh'],
        274802: ['Kushinagar','Uttar Pradesh'],
        274805: ['Deoria','Uttar Pradesh'],
        274806: ['Deoria','Uttar Pradesh'],
        274807: ['Deoria','Uttar Pradesh'],
        274808: ['Deoria','Uttar Pradesh'],
        275101: ['Mau','Uttar Pradesh'],
        275102: ['Mau','Uttar Pradesh'],
        275103: ['Mau','Uttar Pradesh'],
        275105: ['Mau','Uttar Pradesh'],
        275201: ['Ghazipur','Uttar Pradesh'],
        275202: ['Ghazipur','Uttar Pradesh'],
        275203: ['Ghazipur','Uttar Pradesh'],
        275204: ['Ghazipur','Uttar Pradesh'],
        275205: ['Ghazipur','Uttar Pradesh'],
        275301: ['Mau','Uttar Pradesh'],
        275302: ['Mau','Uttar Pradesh'],
        275303: ['Mau','Uttar Pradesh'],
        275304: ['Mau','Uttar Pradesh'],
        275305: ['Mau','Uttar Pradesh'],
        275306: ['Mau','Uttar Pradesh'],
        275307: ['Mau','Uttar Pradesh'],
        276001: ['Azamgarh','Uttar Pradesh'],
        276121: ['Azamgarh','Uttar Pradesh'],
        276122: ['Azamgarh','Uttar Pradesh'],
        276123: ['Azamgarh','Uttar Pradesh'],
        276124: ['Azamgarh','Uttar Pradesh'],
        276125: ['Azamgarh','Uttar Pradesh'],
        276126: ['Azamgarh','Uttar Pradesh'],
        276127: ['Azamgarh','Uttar Pradesh'],
        276128: ['Azamgarh','Uttar Pradesh'],
        276129: ['Mau','Uttar Pradesh'],
        276131: ['Azamgarh','Uttar Pradesh'],
        276135: ['Azamgarh','Uttar Pradesh'],
        276136: ['Azamgarh','Uttar Pradesh'],
        276137: ['Azamgarh','Uttar Pradesh'],
        276138: ['Azamgarh','Uttar Pradesh'],
        276139: ['Azamgarh','Uttar Pradesh'],
        276140: ['Azamgarh','Uttar Pradesh'],
        276141: ['Azamgarh','Uttar Pradesh'],
        276142: ['Azamgarh','Uttar Pradesh'],
        276143: ['Azamgarh','Uttar Pradesh'],
        276201: ['Azamgarh','Uttar Pradesh'],
        276202: ['Azamgarh','Uttar Pradesh'],
        276203: ['Azamgarh','Uttar Pradesh'],
        276204: ['Azamgarh','Uttar Pradesh'],
        276205: ['Azamgarh','Uttar Pradesh'],
        276206: ['Azamgarh','Uttar Pradesh'],
        276207: ['Azamgarh','Uttar Pradesh'],
        276208: ['Azamgarh','Uttar Pradesh'],
        276288: ['Azamgarh','Uttar Pradesh'],
        276301: ['Azamgarh','Uttar Pradesh'],
        276302: ['Azamgarh','Uttar Pradesh'],
        276303: ['Azamgarh','Uttar Pradesh'],
        276304: ['Azamgarh','Uttar Pradesh'],
        276305: ['Azamgarh','Uttar Pradesh'],
        276306: ['Mau','Uttar Pradesh'],
        276402: ['Mau','Uttar Pradesh'],
        276403: ['Mau','Uttar Pradesh'],
        276404: ['Azamgarh','Uttar Pradesh'],
        276405: ['Mau','Uttar Pradesh'],
        276406: ['Azamgarh','Uttar Pradesh'],
        277001: ['Ballia','Uttar Pradesh'],
        277121: ['Ballia','Uttar Pradesh'],
        277123: ['Ballia','Uttar Pradesh'],
        277124: ['Ballia','Uttar Pradesh'],
        277201: ['Ballia','Uttar Pradesh'],
        277202: ['Ballia','Uttar Pradesh'],
        277203: ['Ballia','Uttar Pradesh'],
        277204: ['Ballia','Uttar Pradesh'],
        277205: ['Ballia','Uttar Pradesh'],
        277207: ['Ballia','Uttar Pradesh'],
        277208: ['Ballia','Uttar Pradesh'],
        277209: ['Ballia','Uttar Pradesh'],
        277210: ['Ballia','Uttar Pradesh'],
        277211: ['Ballia','Uttar Pradesh'],
        277213: ['Ballia','Uttar Pradesh'],
        277214: ['Ballia','Uttar Pradesh'],
        277216: ['Ballia','Uttar Pradesh'],
        277219: ['Ballia','Uttar Pradesh'],
        277301: ['Ballia','Uttar Pradesh'],
        277302: ['Ballia','Uttar Pradesh'],
        277303: ['Ballia','Uttar Pradesh'],
        277304: ['Ballia','Uttar Pradesh'],
        277401: ['Ballia','Uttar Pradesh'],
        277402: ['Ballia','Uttar Pradesh'],
        277403: ['Ballia','Uttar Pradesh'],
        277501: ['Ballia','Uttar Pradesh'],
        277502: ['Ballia','Uttar Pradesh'],
        277503: ['Ballia','Uttar Pradesh'],
        277504: ['Ballia','Uttar Pradesh'],
        277506: ['Ballia','Uttar Pradesh'],
        281104: ['Etah','Uttar Pradesh'],
        283130: ['Firozabad','Uttar Pradesh'],
        283135: ['Agra','Uttar Pradesh'],
        283136: ['Firozabad','Uttar Pradesh'],
        283141: ['Firozabad','Uttar Pradesh'],
        283142: ['Firozabad','Uttar Pradesh'],
        283145: ['Firozabad','Uttar Pradesh'],
        283151: ['Firozabad','Uttar Pradesh'],
        283152: ['Firozabad','Uttar Pradesh'],
        283205: ['Firozabad','Uttar Pradesh'],
        283206: ['Firozabad','Uttar Pradesh'],
        283207: ['Firozabad','Uttar Pradesh'],
        284001: ['Jhansi','Uttar Pradesh'],
        284002: ['Jhansi','Uttar Pradesh'],
        284003: ['Jhansi','Uttar Pradesh'],
        284120: ['Jhansi','Uttar Pradesh'],
        284121: ['Jhansi','Uttar Pradesh'],
        284122: ['Lalitpur','Uttar Pradesh'],
        284123: ['Lalitpur','Uttar Pradesh'],
        284124: ['Lalitpur','Uttar Pradesh'],
        284125: ['Lalitpur','Uttar Pradesh'],
        284126: ['Lalitpur','Uttar Pradesh'],
        284127: ['Jhansi','Uttar Pradesh'],
        284128: ['Jhansi','Uttar Pradesh'],
        284135: ['Jhansi','Uttar Pradesh'],
        284136: ['Lalitpur','Uttar Pradesh'],
        284201: ['Jhansi','Uttar Pradesh'],
        284202: ['Jhansi','Uttar Pradesh'],
        284203: ['Jhansi','Uttar Pradesh'],
        284204: ['Jhansi','Uttar Pradesh'],
        284205: ['Jhansi','Uttar Pradesh'],
        284206: ['Jhansi','Uttar Pradesh'],
        284301: ['Jhansi','Uttar Pradesh'],
        284302: ['Jhansi','Uttar Pradesh'],
        284303: ['Jhansi','Uttar Pradesh'],
        284304: ['Jhansi','Uttar Pradesh'],
        284305: ['Jhansi','Uttar Pradesh'],
        284306: ['Jhansi','Uttar Pradesh'],
        284401: ['Jhansi','Uttar Pradesh'],
        284402: ['Lalitpur','Uttar Pradesh'],
        284403: ['Lalitpur','Uttar Pradesh'],
        284404: ['Lalitpur','Uttar Pradesh'],
        284405: ['Lalitpur','Uttar Pradesh'],
        284406: ['Lalitpur','Uttar Pradesh'],
        284419: ['Jhansi','Uttar Pradesh'],
        284501: ['Lalitpur','Uttar Pradesh'],
        285001: ['Aligarh','Uttar Pradesh'],
        285121: ['Jalaun','Uttar Pradesh'],
        285122: ['Jalaun','Uttar Pradesh'],
        285123: ['Jalaun','Uttar Pradesh'],
        285124: ['Jalaun','Uttar Pradesh'],
        285125: ['Jalaun','Uttar Pradesh'],
        285126: ['Jalaun','Uttar Pradesh'],
        285127: ['Jalaun','Uttar Pradesh'],
        285128: ['Jalaun','Uttar Pradesh'],
        285129: ['Jalaun','Uttar Pradesh'],
        285130: ['Jalaun','Uttar Pradesh'],
        285201: ['Jalaun','Uttar Pradesh'],
        285202: ['Jalaun','Uttar Pradesh'],
        285203: ['Jhansi','Uttar Pradesh'],
        285204: ['Jalaun','Uttar Pradesh'],
        285205: ['Jalaun','Uttar Pradesh'],
        285206: ['Jalaun','Uttar Pradesh'],
        285223: ['Jalaun','Uttar Pradesh'],
        301001: ['Alwar','Rajasthan'],
        301002: ['Alwar','Rajasthan'],
        301018: ['Alwar','Rajasthan'],
        301019: ['Alwar','Rajasthan'],
        301020: ['Alwar','Rajasthan'],
        301021: ['Alwar','Rajasthan'],
        301022: ['Alwar','Rajasthan'],
        301023: ['Alwar','Rajasthan'],
        301024: ['Alwar','Rajasthan'],
        301025: ['Alwar','Rajasthan'],
        301026: ['Alwar','Rajasthan'],
        301027: ['Alwar','Rajasthan'],
        301028: ['Alwar','Rajasthan'],
        301030: ['Alwar','Rajasthan'],
        301035: ['Alwar','Rajasthan'],
        301401: ['Alwar','Rajasthan'],
        301402: ['Alwar','Rajasthan'],
        301403: ['Alwar','Rajasthan'],
        301404: ['Alwar','Rajasthan'],
        301405: ['Alwar','Rajasthan'],
        301406: ['Alwar','Rajasthan'],
        301407: ['Alwar','Rajasthan'],
        301408: ['Alwar','Rajasthan'],
        301409: ['Alwar','Rajasthan'],
        301410: ['Alwar','Rajasthan'],
        301411: ['Alwar','Rajasthan'],
        301412: ['Alwar','Rajasthan'],
        301413: ['Alwar','Rajasthan'],
        301414: ['Alwar','Rajasthan'],
        301415: ['Alwar','Rajasthan'],
        301416: ['Alwar','Rajasthan'],
        301427: ['Alwar','Rajasthan'],
        301604: ['Alwar','Rajasthan'],
        301701: ['Alwar','Rajasthan'],
        301702: ['Alwar','Rajasthan'],
        301703: ['Alwar','Rajasthan'],
        301704: ['Alwar','Rajasthan'],
        301705: ['Alwar','Rajasthan'],
        301706: ['Alwar','Rajasthan'],
        301707: ['Alwar','Rajasthan'],
        301708: ['Alwar','Rajasthan'],
        301709: ['Alwar','Rajasthan'],
        301713: ['Alwar','Rajasthan'],
        301714: ['Alwar','Rajasthan'],
        303124: ['Jaipur','Rajasthan'],
        303502: ['Dausa','Rajasthan'],
        303503: ['Dausa','Rajasthan'],
        303511: ['Dausa','Rajasthan'],
        304001: ['Tonk','Rajasthan'],
        304021: ['Tonk','Rajasthan'],
        304022: ['Tonk','Rajasthan'],
        304023: ['Tonk','Rajasthan'],
        304024: ['Tonk','Rajasthan'],
        304025: ['Tonk','Rajasthan'],
        304026: ['Tonk','Rajasthan'],
        304501: ['Tonk','Rajasthan'],
        304502: ['Tonk','Rajasthan'],
        304503: ['Tonk','Rajasthan'],
        304504: ['Tonk','Rajasthan'],
        304505: ['Tonk','Rajasthan'],
        304507: ['Tonk','Rajasthan'],
        304801: ['Tonk','Rajasthan'],
        304802: ['Tonk','Rajasthan'],
        304803: ['Tonk','Rajasthan'],
        304804: ['Tonk','Rajasthan'],
        305001: ['Ajmer','Rajasthan'],
        305002: ['Ajmer','Rajasthan'],
        305003: ['Ajmer','Rajasthan'],
        305004: ['Ajmer','Rajasthan'],
        305005: ['Ajmer','Rajasthan'],
        305007: ['Ajmer','Rajasthan'],
        305009: ['Ajmer','Rajasthan'],
        305012: ['Ajmer','Rajasthan'],
        305021: ['Ajmer','Rajasthan'],
        305022: ['Ajmer','Rajasthan'],
        305023: ['Ajmer','Rajasthan'],
        305024: ['Ajmer','Rajasthan'],
        305025: ['Ajmer','Rajasthan'],
        305026: ['Nagaur','Rajasthan'],
        305201: ['Ajmer','Rajasthan'],
        305202: ['Ajmer','Rajasthan'],
        305203: ['Ajmer','Rajasthan'],
        305204: ['Ajmer','Rajasthan'],
        305205: ['Ajmer','Rajasthan'],
        305206: ['Ajmer','Rajasthan'],
        305207: ['Ajmer','Rajasthan'],
        305401: ['Ajmer','Rajasthan'],
        305402: ['Ajmer','Rajasthan'],
        305403: ['Ajmer','Rajasthan'],
        305404: ['Ajmer','Rajasthan'],
        305405: ['Ajmer','Rajasthan'],
        305406: ['Ajmer','Rajasthan'],
        305407: ['Ajmer','Rajasthan'],
        305408: ['Ajmer','Rajasthan'],
        305412: ['Ajmer','Rajasthan'],
        305415: ['Ajmer','Rajasthan'],
        305601: ['Ajmer','Rajasthan'],
        305621: ['Ajmer','Rajasthan'],
        305622: ['Ajmer','Rajasthan'],
        305623: ['Ajmer','Rajasthan'],
        305624: ['Ajmer','Rajasthan'],
        305625: ['Ajmer','Rajasthan'],
        305627: ['Ajmer','Rajasthan'],
        305628: ['Ajmer','Rajasthan'],
        305629: ['Ajmer','Rajasthan'],
        305630: ['Ajmer','Rajasthan'],
        305631: ['Ajmer','Rajasthan'],
        305801: ['Ajmer','Rajasthan'],
        305802: ['Ajmer','Rajasthan'],
        305811: ['Ajmer','Rajasthan'],
        305812: ['Ajmer','Rajasthan'],
        305813: ['Ajmer','Rajasthan'],
        305814: ['Ajmer','Rajasthan'],
        305815: ['Ajmer','Rajasthan'],
        305816: ['Ajmer','Rajasthan'],
        305817: ['Ajmer','Rajasthan'],
        305819: ['Ajmer','Rajasthan'],
        305901: ['Ajmer','Rajasthan'],
        305922: ['Ajmer','Rajasthan'],
        305923: ['Ajmer','Rajasthan'],
        305924: ['Ajmer','Rajasthan'],
        305925: ['Ajmer','Rajasthan'],
        305926: ['Ajmer','Rajasthan'],
        305927: ['Ajmer','Rajasthan'],
        306001: ['Pali','Rajasthan'],
        306021: ['Pali','Rajasthan'],
        306022: ['Pali','Rajasthan'],
        306023: ['Pali','Rajasthan'],
        306101: ['Pali','Rajasthan'],
        306102: ['Pali','Rajasthan'],
        306103: ['Pali','Rajasthan'],
        306104: ['Pali','Rajasthan'],
        306105: ['Pali','Rajasthan'],
        306114: ['Pali','Rajasthan'],
        306115: ['Pali','Rajasthan'],
        306116: ['Pali','Rajasthan'],
        306119: ['Pali','Rajasthan'],
        306126: ['Pali','Rajasthan'],
        306301: ['Pali','Rajasthan'],
        306302: ['Pali','Rajasthan'],
        306303: ['Pali','Rajasthan'],
        306304: ['Pali','Rajasthan'],
        306305: ['Pali','Rajasthan'],
        306306: ['Pali','Rajasthan'],
        306307: ['Pali','Rajasthan'],
        306308: ['Pali','Rajasthan'],
        306401: ['Pali','Rajasthan'],
        306421: ['Pali','Rajasthan'],
        306422: ['Pali','Rajasthan'],
        306501: ['Pali','Rajasthan'],
        306502: ['Pali','Rajasthan'],
        306503: ['Pali','Rajasthan'],
        306504: ['Pali','Rajasthan'],
        306601: ['Pali','Rajasthan'],
        306602: ['Pali','Rajasthan'],
        306603: ['Pali','Rajasthan'],
        306701: ['Pali','Rajasthan'],
        306702: ['Pali','Rajasthan'],
        306703: ['Pali','Rajasthan'],
        306704: ['Pali','Rajasthan'],
        306705: ['Pali','Rajasthan'],
        306706: ['Pali','Rajasthan'],
        306707: ['Pali','Rajasthan'],
        306708: ['Pali','Rajasthan'],
        306709: ['Pali','Rajasthan'],
        306901: ['Pali','Rajasthan'],
        306902: ['Pali','Rajasthan'],
        306912: ['Pali','Rajasthan'],
        307001: ['Sirohi','Rajasthan'],
        307019: ['Sirohi','Rajasthan'],
        307022: ['Sirohi','Rajasthan'],
        307023: ['Sirohi','Rajasthan'],
        307024: ['Sirohi','Rajasthan'],
        307026: ['Sirohi','Rajasthan'],
        307027: ['Sirohi','Rajasthan'],
        307028: ['Sirohi','Rajasthan'],
        307029: ['Jalor','Rajasthan'],
        307030: ['Jalor','Rajasthan'],
        307031: ['Sirohi','Rajasthan'],
        307032: ['Sirohi','Rajasthan'],
        307043: ['Sirohi','Rajasthan'],
        307501: ['Sirohi','Rajasthan'],
        307510: ['Sirohi','Rajasthan'],
        307511: ['Sirohi','Rajasthan'],
        307512: ['Sirohi','Rajasthan'],
        307513: ['Sirohi','Rajasthan'],
        307514: ['Sirohi','Rajasthan'],
        307515: ['Jalor','Rajasthan'],
        307801: ['Sirohi','Rajasthan'],
        307802: ['Sirohi','Rajasthan'],
        307803: ['Jalor','Rajasthan'],
        311001: ['Bhilwara','Rajasthan'],
        311011: ['Bhilwara','Rajasthan'],
        311021: ['Bhilwara','Rajasthan'],
        311022: ['Bhilwara','Rajasthan'],
        311023: ['Bhilwara','Rajasthan'],
        311024: ['Bhilwara','Rajasthan'],
        311025: ['Bhilwara','Rajasthan'],
        311026: ['Bhilwara','Rajasthan'],
        311030: ['Bhilwara','Rajasthan'],
        311201: ['Bhilwara','Rajasthan'],
        311202: ['Bhilwara','Rajasthan'],
        311203: ['Bhilwara','Rajasthan'],
        311204: ['Bhilwara','Rajasthan'],
        311301: ['Bhilwara','Rajasthan'],
        311302: ['Bhilwara','Rajasthan'],
        311401: ['Bhilwara','Rajasthan'],
        311402: ['Bhilwara','Rajasthan'],
        311403: ['Bhilwara','Rajasthan'],
        311404: ['Bhilwara','Rajasthan'],
        311407: ['Bhilwara','Rajasthan'],
        311408: ['Bhilwara','Rajasthan'],
        311601: ['Bhilwara','Rajasthan'],
        311602: ['Bhilwara','Rajasthan'],
        311603: ['Bhilwara','Rajasthan'],
        311604: ['Bhilwara','Rajasthan'],
        311605: ['Bhilwara','Rajasthan'],
        311606: ['Bhilwara','Rajasthan'],
        311801: ['Bhilwara','Rajasthan'],
        311802: ['Bhilwara','Rajasthan'],
        311803: ['Bhilwara','Rajasthan'],
        311804: ['Bhilwara','Rajasthan'],
        311805: ['Bhilwara','Rajasthan'],
        311806: ['Bhilwara','Rajasthan'],
        312001: ['Chittorgarh','Rajasthan'],
        312021: ['Chittorgarh','Rajasthan'],
        312022: ['Chittorgarh','Rajasthan'],
        312023: ['Chittorgarh','Rajasthan'],
        312024: ['Chittorgarh','Rajasthan'],
        312025: ['Chittorgarh','Rajasthan'],
        312027: ['Chittorgarh','Rajasthan'],
        312201: ['Chittorgarh','Rajasthan'],
        312202: ['Jaipur','Rajasthan'],
        312203: ['Chittorgarh','Rajasthan'],
        312204: ['Chittorgarh','Rajasthan'],
        312205: ['Chittorgarh','Rajasthan'],
        312206: ['Chittorgarh','Rajasthan'],
        312207: ['Chittorgarh','Rajasthan'],
        312401: ['Chittorgarh','Rajasthan'],
        312402: ['Chittorgarh','Rajasthan'],
        312403: ['Chittorgarh','Rajasthan'],
        312404: ['Chittorgarh','Rajasthan'],
        312601: ['Chittorgarh','Rajasthan'],
        312602: ['Chittorgarh','Rajasthan'],
        312603: ['Chittorgarh','Rajasthan'],
        312604: ['Pratapghar','Rajasthan'],
        312605: ['Pratapghar','Rajasthan'],
        312606: ['Chittorgarh','Rajasthan'],
        312612: ['Chittorgarh','Rajasthan'],
        312613: ['Chittorgarh','Rajasthan'],
        312614: ['Chittorgarh','Rajasthan'],
        312615: ['Pratapghar','Rajasthan'],
        312616: ['Chittorgarh','Rajasthan'],
        312617: ['Chittorgarh','Rajasthan'],
        312619: ['Pratapghar','Rajasthan'],
        312620: ['Chittorgarh','Rajasthan'],
        312622: ['Chittorgarh','Rajasthan'],
        312623: ['Pratapghar','Rajasthan'],
        312624: ['Pratapghar','Rajasthan'],
        312625: ['Pratapghar','Rajasthan'],
        312626: ['Pratapghar','Rajasthan'],
        312627: ['Chittorgarh','Rajasthan'],
        312901: ['Chittorgarh','Rajasthan'],
        313334: ['Udaipur','Rajasthan'],
        314001: ['Dungarpur','Rajasthan'],
        314011: ['Dungarpur','Rajasthan'],
        314021: ['Dungarpur','Rajasthan'],
        314022: ['Dungarpur','Rajasthan'],
        314023: ['Dungarpur','Rajasthan'],
        314024: ['Dungarpur','Rajasthan'],
        314025: ['Dungarpur','Rajasthan'],
        314026: ['Dungarpur','Rajasthan'],
        314027: ['Dungarpur','Rajasthan'],
        314028: ['Dungarpur','Rajasthan'],
        314029: ['Dungarpur','Rajasthan'],
        314030: ['Dungarpur','Rajasthan'],
        314031: ['Dungarpur','Rajasthan'],
        314032: ['Dungarpur','Rajasthan'],
        314034: ['Dungarpur','Rajasthan'],
        314035: ['Dungarpur','Rajasthan'],
        314036: ['Dungarpur','Rajasthan'],
        314037: ['Dungarpur','Rajasthan'],
        314038: ['Dungarpur','Rajasthan'],
        314401: ['Dungarpur','Rajasthan'],
        314402: ['Dungarpur','Rajasthan'],
        314403: ['Dungarpur','Rajasthan'],
        314404: ['Dungarpur','Rajasthan'],
        314406: ['Dungarpur','Rajasthan'],
        314801: ['Dungarpur','Rajasthan'],
        314804: ['Dungarpur','Rajasthan'],
        321001: ['Bharatpur','Rajasthan'],
        321021: ['Bharatpur','Rajasthan'],
        321022: ['Bharatpur','Rajasthan'],
        321023: ['Bharatpur','Rajasthan'],
        321024: ['Bharatpur','Rajasthan'],
        321025: ['Bharatpur','Rajasthan'],
        321026: ['Bharatpur','Rajasthan'],
        321028: ['Bharatpur','Rajasthan'],
        321201: ['Bharatpur','Rajasthan'],
        321202: ['Bharatpur','Rajasthan'],
        321203: ['Bharatpur','Rajasthan'],
        321204: ['Bharatpur','Rajasthan'],
        321205: ['Bharatpur','Rajasthan'],
        321206: ['Bharatpur','Rajasthan'],
        321301: ['Bharatpur','Rajasthan'],
        321302: ['Bharatpur','Rajasthan'],
        321303: ['Bharatpur','Rajasthan'],
        321401: ['Bharatpur','Rajasthan'],
        321402: ['Bharatpur','Rajasthan'],
        321403: ['Bharatpur','Rajasthan'],
        321404: ['Bharatpur','Rajasthan'],
        321405: ['Bharatpur','Rajasthan'],
        321406: ['Bharatpur','Rajasthan'],
        321407: ['Bharatpur','Rajasthan'],
        321408: ['Bharatpur','Rajasthan'],
        321409: ['Bharatpur','Rajasthan'],
        321410: ['Bharatpur','Rajasthan'],
        321411: ['Bharatpur','Rajasthan'],
        321601: ['Bharatpur','Rajasthan'],
        321602: ['Bharatpur','Rajasthan'],
        321605: ['Alwar','Rajasthan'],
        321606: ['Alwar','Rajasthan'],
        321607: ['Alwar','Rajasthan'],
        321608: ['Dausa','Rajasthan'],
        321609: ['Dausa','Rajasthan'],
        321610: ['Karauli','Rajasthan'],
        321611: ['Karauli','Rajasthan'],
        321612: ['Dausa','Rajasthan'],
        321613: ['Dausa','Rajasthan'],
        321614: ['Bharatpur','Rajasthan'],
        321615: ['Bharatpur','Rajasthan'],
        321633: ['Alwar','Rajasthan'],
        321642: ['Bharatpur','Rajasthan'],
        322001: ['Sawai Madhopur','Rajasthan'],
        322021: ['Sawai Madhopur','Rajasthan'],
        322023: ['Sawai Madhopur','Rajasthan'],
        322024: ['Sawai Madhopur','Rajasthan'],
        322025: ['Sawai Madhopur','Rajasthan'],
        322026: ['Sawai Madhopur','Rajasthan'],
        322027: ['Sawai Madhopur','Rajasthan'],
        322028: ['Sawai Madhopur','Rajasthan'],
        322029: ['Sawai Madhopur','Rajasthan'],
        322030: ['Sawai Madhopur','Rajasthan'],
        322033: ['Sawai Madhopur','Rajasthan'],
        322034: ['Sawai Madhopur','Rajasthan'],
        322201: ['Sawai Madhopur','Rajasthan'],
        322202: ['Karauli','Rajasthan'],
        322203: ['Karauli','Rajasthan'],
        322204: ['Karauli','Rajasthan'],
        322205: ['Sawai Madhopur','Rajasthan'],
        322211: ['Sawai Madhopur','Rajasthan'],
        322212: ['Sawai Madhopur','Rajasthan'],
        322213: ['Karauli','Rajasthan'],
        322214: ['Sawai Madhopur','Rajasthan'],
        322215: ['Karauli','Rajasthan'],
        322216: ['Karauli','Rajasthan'],
        322218: ['Karauli','Rajasthan'],
        322219: ['Sawai Madhopur','Rajasthan'],
        322220: ['Karauli','Rajasthan'],
        322230: ['Karauli','Rajasthan'],
        322234: ['Karauli','Rajasthan'],
        322236: ['Karauli','Rajasthan'],
        322238: ['Karauli','Rajasthan'],
        322240: ['Dausa','Rajasthan'],
        322241: ['Karauli','Rajasthan'],
        322242: ['Karauli','Rajasthan'],
        322243: ['Karauli','Rajasthan'],
        322249: ['Karauli','Rajasthan'],
        322251: ['Karauli','Rajasthan'],
        322252: ['Karauli','Rajasthan'],
        322254: ['Karauli','Rajasthan'],
        322255: ['Karauli','Rajasthan'],
        322701: ['Sawai Madhopur','Rajasthan'],
        322702: ['Sawai Madhopur','Rajasthan'],
        322703: ['Sawai Madhopur','Rajasthan'],
        322704: ['Sawai Madhopur','Rajasthan'],
        323001: ['Bundi','Rajasthan'],
        323021: ['Bundi','Rajasthan'],
        323022: ['Bundi','Rajasthan'],
        323023: ['Bundi','Rajasthan'],
        323024: ['Bundi','Rajasthan'],
        323025: ['Bundi','Rajasthan'],
        323026: ['Bundi','Rajasthan'],
        323301: ['Bundi','Rajasthan'],
        323303: ['Chittorgarh','Rajasthan'],
        323304: ['Chittorgarh','Rajasthan'],
        323305: ['Chittorgarh','Rajasthan'],
        323306: ['Chittorgarh','Rajasthan'],
        323307: ['Chittorgarh','Rajasthan'],
        323601: ['Bundi','Rajasthan'],
        323602: ['Bundi','Rajasthan'],
        323603: ['Bundi','Rajasthan'],
        323613: ['Bundi','Rajasthan'],
        323614: ['Bundi','Rajasthan'],
        323615: ['Bundi','Rajasthan'],
        323616: ['Bundi','Rajasthan'],
        323801: ['Bundi','Rajasthan'],
        323802: ['Bundi','Rajasthan'],
        323803: ['Bundi','Rajasthan'],
        325206: ['Baran','Rajasthan'],
        325209: ['Baran','Rajasthan'],
        325219: ['Baran','Rajasthan'],
        325224: ['Baran','Rajasthan'],
        326001: ['Jhalawar','Rajasthan'],
        326021: ['Jhalawar','Rajasthan'],
        326022: ['Jhalawar','Rajasthan'],
        326023: ['Jhalawar','Rajasthan'],
        326033: ['Jhalawar','Rajasthan'],
        326034: ['Jhalawar','Rajasthan'],
        326035: ['Jhalawar','Rajasthan'],
        326036: ['Jhalawar','Rajasthan'],
        326037: ['Jhalawar','Rajasthan'],
        326039: ['Jhalawar','Rajasthan'],
        326501: ['Jhalawar','Rajasthan'],
        326502: ['Jhalawar','Rajasthan'],
        326512: ['Jhalawar','Rajasthan'],
        326513: ['Jhalawar','Rajasthan'],
        326514: ['Jhalawar','Rajasthan'],
        326515: ['Jhalawar','Rajasthan'],
        326516: ['Jhalawar','Rajasthan'],
        327001: ['Banswara','Rajasthan'],
        327021: ['Banswara','Rajasthan'],
        327022: ['Banswara','Rajasthan'],
        327023: ['Banswara','Rajasthan'],
        327024: ['Banswara','Rajasthan'],
        327025: ['Banswara','Rajasthan'],
        327026: ['Banswara','Rajasthan'],
        327027: ['Banswara','Rajasthan'],
        327031: ['Banswara','Rajasthan'],
        327032: ['Banswara','Rajasthan'],
        327034: ['Banswara','Rajasthan'],
        327601: ['Banswara','Rajasthan'],
        327602: ['Banswara','Rajasthan'],
        327603: ['Banswara','Rajasthan'],
        327604: ['Banswara','Rajasthan'],
        327605: ['Banswara','Rajasthan'],
        327606: ['Banswara','Rajasthan'],
        327801: ['Banswara','Rajasthan'],
        328001: ['Dholpur','Rajasthan'],
        328021: ['Dholpur','Rajasthan'],
        328022: ['Dholpur','Rajasthan'],
        328023: ['Dholpur','Rajasthan'],
        328024: ['Dholpur','Rajasthan'],
        328025: ['Dholpur','Rajasthan'],
        328026: ['Dholpur','Rajasthan'],
        328027: ['Dholpur','Rajasthan'],
        328028: ['Dholpur','Rajasthan'],
        328029: ['Dholpur','Rajasthan'],
        328030: ['Dholpur','Rajasthan'],
        328031: ['Dholpur','Rajasthan'],
        328041: ['Dholpur','Rajasthan'],
        328216: ['Baran','Rajasthan'],
        331001: ['Churu','Rajasthan'],
        331021: ['Churu','Rajasthan'],
        331022: ['Churu','Rajasthan'],
        331023: ['Churu','Rajasthan'],
        331024: ['Sikar','Rajasthan'],
        331025: ['Jhujhunu','Rajasthan'],
        331026: ['Jhujhunu','Rajasthan'],
        331027: ['Jhujhunu','Rajasthan'],
        331028: ['Jhujhunu','Rajasthan'],
        331029: ['Churu','Rajasthan'],
        331030: ['Jhujhunu','Rajasthan'],
        331031: ['Churu','Rajasthan'],
        331301: ['Churu','Rajasthan'],
        331302: ['Churu','Rajasthan'],
        331303: ['Churu','Rajasthan'],
        331304: ['Churu','Rajasthan'],
        331305: ['Churu','Rajasthan'],
        331402: ['Churu','Rajasthan'],
        331403: ['Churu','Rajasthan'],
        331411: ['Churu','Rajasthan'],
        331501: ['Churu','Rajasthan'],
        331502: ['Churu','Rajasthan'],
        331503: ['Churu','Rajasthan'],
        331504: ['Churu','Rajasthan'],
        331505: ['Churu','Rajasthan'],
        331506: ['Churu','Rajasthan'],
        331507: ['Churu','Rajasthan'],
        331517: ['Churu','Rajasthan'],
        331518: ['Churu','Rajasthan'],
        331701: ['Churu','Rajasthan'],
        331801: ['Bikaner','Rajasthan'],
        331802: ['Churu','Rajasthan'],
        331803: ['Bikaner','Rajasthan'],
        331811: ['Bikaner','Rajasthan'],
        332001: ['Sikar','Rajasthan'],
        332002: ['Sikar','Rajasthan'],
        332021: ['Sikar','Rajasthan'],
        332023: ['Sikar','Rajasthan'],
        332024: ['Sikar','Rajasthan'],
        332025: ['Sikar','Rajasthan'],
        332026: ['Sikar','Rajasthan'],
        332027: ['Sikar','Rajasthan'],
        332028: ['Sikar','Rajasthan'],
        332029: ['Sikar','Rajasthan'],
        332030: ['Sikar','Rajasthan'],
        332031: ['Sikar','Rajasthan'],
        332041: ['Sikar','Rajasthan'],
        332042: ['Sikar','Rajasthan'],
        332301: ['Sikar','Rajasthan'],
        332302: ['Sikar','Rajasthan'],
        332303: ['Sikar','Rajasthan'],
        332304: ['Sikar','Rajasthan'],
        332305: ['Sikar','Rajasthan'],
        332307: ['Sikar','Rajasthan'],
        332311: ['Sikar','Rajasthan'],
        332312: ['Sikar','Rajasthan'],
        332315: ['Sikar','Rajasthan'],
        332316: ['Sikar','Rajasthan'],
        332317: ['Sikar','Rajasthan'],
        332318: ['Sikar','Rajasthan'],
        332401: ['Sikar','Rajasthan'],
        332402: ['Sikar','Rajasthan'],
        332403: ['Sikar','Rajasthan'],
        332404: ['Sikar','Rajasthan'],
        332405: ['Sikar','Rajasthan'],
        332406: ['Sikar','Rajasthan'],
        332411: ['Sikar','Rajasthan'],
        332601: ['Sikar','Rajasthan'],
        332602: ['Sikar','Rajasthan'],
        332603: ['Sikar','Rajasthan'],
        332701: ['Sikar','Rajasthan'],
        332702: ['Sikar','Rajasthan'],
        332703: ['Sikar','Rajasthan'],
        332705: ['Sikar','Rajasthan'],
        332706: ['Sikar','Rajasthan'],
        332707: ['Sikar','Rajasthan'],
        332708: ['Sikar','Rajasthan'],
        332709: ['Sikar','Rajasthan'],
        332710: ['Sikar','Rajasthan'],
        332711: ['Sikar','Rajasthan'],
        332712: ['Sikar','Rajasthan'],
        332713: ['Sikar','Rajasthan'],
        332714: ['Sikar','Rajasthan'],
        332715: ['Sikar','Rajasthan'],
        332716: ['Jhujhunu','Rajasthan'],
        332718: ['Sikar','Rajasthan'],
        332719: ['Sikar','Rajasthan'],
        332721: ['Sikar','Rajasthan'],
        332722: ['Sikar','Rajasthan'],
        332742: ['Sikar','Rajasthan'],
        332746: ['Jhujhunu','Rajasthan'],
        333001: ['Jhujhunu','Rajasthan'],
        333011: ['Jhujhunu','Rajasthan'],
        333012: ['Jhujhunu','Rajasthan'],
        333021: ['Jhujhunu','Rajasthan'],
        333022: ['Jhujhunu','Rajasthan'],
        333023: ['Jhujhunu','Rajasthan'],
        333024: ['Jhujhunu','Rajasthan'],
        333025: ['Jhujhunu','Rajasthan'],
        333026: ['Jhujhunu','Rajasthan'],
        333027: ['Jhujhunu','Rajasthan'],
        333028: ['Jhujhunu','Rajasthan'],
        333029: ['Jhujhunu','Rajasthan'],
        333030: ['Jhujhunu','Rajasthan'],
        333031: ['Jhujhunu','Rajasthan'],
        333032: ['Jhujhunu','Rajasthan'],
        333033: ['Jhujhunu','Rajasthan'],
        333034: ['Jhujhunu','Rajasthan'],
        333035: ['Jhujhunu','Rajasthan'],
        333036: ['Jhujhunu','Rajasthan'],
        333041: ['Jhujhunu','Rajasthan'],
        333042: ['Jhujhunu','Rajasthan'],
        333053: ['Jhujhunu','Rajasthan'],
        333302: ['Jhujhunu','Rajasthan'],
        333303: ['Jhujhunu','Rajasthan'],
        333304: ['Jhujhunu','Rajasthan'],
        333305: ['Jhujhunu','Rajasthan'],
        333307: ['Jhujhunu','Rajasthan'],
        333308: ['Jhujhunu','Rajasthan'],
        333501: ['Jhujhunu','Rajasthan'],
        333503: ['Jhujhunu','Rajasthan'],
        333504: ['Jhujhunu','Rajasthan'],
        333514: ['Jhujhunu','Rajasthan'],
        333515: ['Jhujhunu','Rajasthan'],
        333516: ['Jhujhunu','Rajasthan'],
        333701: ['Jhujhunu','Rajasthan'],
        333702: ['Jhujhunu','Rajasthan'],
        333704: ['Jhujhunu','Rajasthan'],
        333705: ['Jhujhunu','Rajasthan'],
        333707: ['Jhujhunu','Rajasthan'],
        333801: ['Jhujhunu','Rajasthan'],
        334001: ['Bikaner','Rajasthan'],
        334003: ['Bikaner','Rajasthan'],
        334004: ['Bikaner','Rajasthan'],
        334006: ['Bikaner','Rajasthan'],
        334021: ['Bikaner','Rajasthan'],
        334022: ['Bikaner','Rajasthan'],
        334023: ['Bikaner','Rajasthan'],
        334201: ['Bikaner','Rajasthan'],
        334202: ['Bikaner','Rajasthan'],
        334302: ['Bikaner','Rajasthan'],
        334303: ['Bikaner','Rajasthan'],
        334305: ['Bikaner','Rajasthan'],
        334401: ['Bikaner','Rajasthan'],
        334402: ['Bikaner','Rajasthan'],
        334403: ['Bikaner','Rajasthan'],
        334601: ['Bikaner','Rajasthan'],
        334602: ['Bikaner','Rajasthan'],
        334603: ['Bikaner','Rajasthan'],
        334604: ['Bikaner','Rajasthan'],
        334801: ['Bikaner','Rajasthan'],
        334802: ['Bikaner','Rajasthan'],
        334803: ['Bikaner','Rajasthan'],
        334804: ['Bikaner','Rajasthan'],
        334808: ['Bikaner','Rajasthan'],
        335001: ['Sri Ganganagar','Rajasthan'],
        335002: ['Sri Ganganagar','Rajasthan'],
        335021: ['Sri Ganganagar','Rajasthan'],
        335022: ['Sri Ganganagar','Rajasthan'],
        335023: ['Sri Ganganagar','Rajasthan'],
        335024: ['Sri Ganganagar','Rajasthan'],
        335025: ['Sri Ganganagar','Rajasthan'],
        335027: ['Sri Ganganagar','Rajasthan'],
        335037: ['Sri Ganganagar','Rajasthan'],
        335038: ['Sri Ganganagar','Rajasthan'],
        335039: ['Sri Ganganagar','Rajasthan'],
        335040: ['Sri Ganganagar','Rajasthan'],
        335041: ['Sri Ganganagar','Rajasthan'],
        335051: ['Sri Ganganagar','Rajasthan'],
        335061: ['Sri Ganganagar','Rajasthan'],
        335062: ['Sri Ganganagar','Rajasthan'],
        335063: ['Hanumangarh','Rajasthan'],
        335064: ['Sri Ganganagar','Rajasthan'],
        335065: ['Hanumangarh','Rajasthan'],
        335073: ['Sri Ganganagar','Rajasthan'],
        335501: ['Hanumangarh','Rajasthan'],
        335502: ['Hanumangarh','Rajasthan'],
        335503: ['Hanumangarh','Rajasthan'],
        335504: ['Hanumangarh','Rajasthan'],
        335511: ['Hanumangarh','Rajasthan'],
        335512: ['Hanumangarh','Rajasthan'],
        335513: ['Hanumangarh','Rajasthan'],
        335523: ['Hanumangarh','Rajasthan'],
        335524: ['Hanumangarh','Rajasthan'],
        335525: ['Hanumangarh','Rajasthan'],
        335526: ['Sri Ganganagar','Rajasthan'],
        335701: ['Sri Ganganagar','Rajasthan'],
        335702: ['Sri Ganganagar','Rajasthan'],
        335703: ['Sri Ganganagar','Rajasthan'],
        335704: ['Sri Ganganagar','Rajasthan'],
        335705: ['Sri Ganganagar','Rajasthan'],
        335707: ['Sri Ganganagar','Rajasthan'],
        335708: ['Sriganganagar','Rajasthan'],
        335711: ['Sri Ganganagar','Rajasthan'],
        335801: ['Hanumangarh','Rajasthan'],
        335802: ['Hanumangarh','Rajasthan'],
        335803: ['Hanumangarh','Rajasthan'],
        335804: ['Sri Ganganagar','Rajasthan'],
        335805: ['Sri Ganganagar','Rajasthan'],
        335901: ['Sri Ganganagar','Rajasthan'],
        341001: ['Nagaur','Rajasthan'],
        341021: ['Nagaur','Rajasthan'],
        341022: ['Nagaur','Rajasthan'],
        341023: ['Nagaur','Rajasthan'],
        341024: ['Nagaur','Rajasthan'],
        341025: ['Nagaur','Rajasthan'],
        341026: ['Nagaur','Rajasthan'],
        341027: ['Nagaur','Rajasthan'],
        341028: ['Nagaur','Rajasthan'],
        341029: ['Nagaur','Rajasthan'],
        341030: ['Nagaur','Rajasthan'],
        341031: ['Nagaur','Rajasthan'],
        341301: ['Nagaur','Rajasthan'],
        341302: ['Nagaur','Rajasthan'],
        341303: ['Nagaur','Rajasthan'],
        341304: ['Nagaur','Rajasthan'],
        341305: ['Nagaur','Rajasthan'],
        341306: ['Nagaur','Rajasthan'],
        341316: ['Nagaur','Rajasthan'],
        341317: ['Nagaur','Rajasthan'],
        341318: ['Nagaur','Rajasthan'],
        341319: ['Nagaur','Rajasthan'],
        341501: ['Nagaur','Rajasthan'],
        341502: ['Nagaur','Rajasthan'],
        341503: ['Nagaur','Rajasthan'],
        341504: ['Nagaur','Rajasthan'],
        341505: ['Nagaur','Rajasthan'],
        341506: ['Nagaur','Rajasthan'],
        341507: ['Nagaur','Rajasthan'],
        341508: ['Nagaur','Rajasthan'],
        341509: ['Nagaur','Rajasthan'],
        341510: ['Nagaur','Rajasthan'],
        341511: ['Nagaur','Rajasthan'],
        341512: ['Nagaur','Rajasthan'],
        341513: ['Nagaur','Rajasthan'],
        341514: ['Nagaur','Rajasthan'],
        341515: ['Nagaur','Rajasthan'],
        341516: ['Nagaur','Rajasthan'],
        341517: ['Nagaur','Rajasthan'],
        341518: ['Nagaur','Rajasthan'],
        341519: ['Nagaur','Rajasthan'],
        341520: ['Nagaur','Rajasthan'],
        341533: ['Nagaur','Rajasthan'],
        341542: ['Nagaur','Rajasthan'],
        341551: ['Nagaur','Rajasthan'],
        342001: ['Jodhpur','Rajasthan'],
        342003: ['Jodhpur','Rajasthan'],
        342005: ['Jodhpur','Rajasthan'],
        342006: ['Jodhpur','Rajasthan'],
        342007: ['Jodhpur','Rajasthan'],
        342008: ['Jodhpur','Rajasthan'],
        342011: ['Jodhpur','Rajasthan'],
        342012: ['Jodhpur','Rajasthan'],
        342013: ['Jodhpur','Rajasthan'],
        342014: ['Jodhpur','Rajasthan'],
        342015: ['Jodhpur','Rajasthan'],
        342021: ['Jodhpur','Rajasthan'],
        342022: ['Jodhpur','Rajasthan'],
        342023: ['Jodhpur','Rajasthan'],
        342024: ['Jodhpur','Rajasthan'],
        342025: ['Jodhpur','Rajasthan'],
        342026: ['Jodhpur','Rajasthan'],
        342027: ['Jodhpur','Rajasthan'],
        342028: ['Jodhpur','Rajasthan'],
        342029: ['Jodhpur','Rajasthan'],
        342037: ['Jodhpur','Rajasthan'],
        342301: ['Jodhpur','Rajasthan'],
        342302: ['Jodhpur','Rajasthan'],
        342303: ['Jodhpur','Rajasthan'],
        342304: ['Jodhpur','Rajasthan'],
        342305: ['Jodhpur','Rajasthan'],
        342306: ['Jodhpur','Rajasthan'],
        342307: ['Jodhpur','Rajasthan'],
        342308: ['Jodhpur','Rajasthan'],
        342309: ['Jodhpur','Rajasthan'],
        342310: ['Jaisalmer','Rajasthan'],
        342311: ['Jodhpur','Rajasthan'],
        342312: ['Jodhpur','Rajasthan'],
        342314: ['Jodhpur','Rajasthan'],
        342601: ['Jodhpur','Rajasthan'],
        342602: ['Jodhpur','Rajasthan'],
        342603: ['Jodhpur','Rajasthan'],
        342604: ['Jodhpur','Rajasthan'],
        342605: ['Jodhpur','Rajasthan'],
        342606: ['Jodhpur','Rajasthan'],
        342801: ['Jodhpur','Rajasthan'],
        342802: ['Jodhpur','Rajasthan'],
        342901: ['Jodhpur','Rajasthan'],
        342902: ['Nagaur','Rajasthan'],
        343001: ['Jalor','Rajasthan'],
        343002: ['Jalor','Rajasthan'],
        343021: ['Jalor','Rajasthan'],
        343022: ['Jalor','Rajasthan'],
        343023: ['Jalor','Rajasthan'],
        343024: ['Jalor','Rajasthan'],
        343025: ['Jalor','Rajasthan'],
        343027: ['Jalor','Rajasthan'],
        343028: ['Jalor','Rajasthan'],
        343029: ['Jalor','Rajasthan'],
        343030: ['Jalor','Rajasthan'],
        343032: ['Jalor','Rajasthan'],
        343039: ['Jalor','Rajasthan'],
        343040: ['Jalor','Rajasthan'],
        343041: ['Jalor','Rajasthan'],
        343042: ['Jalor','Rajasthan'],
        343048: ['Jalor','Rajasthan'],
        343049: ['Jalor','Rajasthan'],
        344001: ['Barmer','Rajasthan'],
        344011: ['Barmer','Rajasthan'],
        344012: ['Barmer','Rajasthan'],
        344021: ['Barmer','Rajasthan'],
        344022: ['Barmer','Rajasthan'],
        344024: ['Barmer','Rajasthan'],
        344025: ['Barmer','Rajasthan'],
        344026: ['Barmer','Rajasthan'],
        344027: ['Barmer','Rajasthan'],
        344031: ['Barmer','Rajasthan'],
        344032: ['Barmer','Rajasthan'],
        344033: ['Barmer','Rajasthan'],
        344034: ['Barmer','Rajasthan'],
        344035: ['Barmer','Rajasthan'],
        344037: ['Barmer','Rajasthan'],
        344043: ['Barmer','Rajasthan'],
        344044: ['Barmer','Rajasthan'],
        344501: ['Barmer','Rajasthan'],
        344502: ['Barmer','Rajasthan'],
        344701: ['Barmer','Rajasthan'],
        344702: ['Barmer','Rajasthan'],
        344703: ['Barmer','Rajasthan'],
        344704: ['Barmer','Rajasthan'],
        344705: ['Barmer','Rajasthan'],
        344706: ['Barmer','Rajasthan'],
        344708: ['Barmer','Rajasthan'],
        344801: ['Barmer','Rajasthan'],
        345001: ['Jaisalmer','Rajasthan'],
        345021: ['Jaisalmer','Rajasthan'],
        345022: ['Jaisalmer','Rajasthan'],
        345023: ['Jaisalmer','Rajasthan'],
        345024: ['Jaisalmer','Rajasthan'],
        345025: ['Jaisalmer','Rajasthan'],
        345026: ['Jaisalmer','Rajasthan'],
        345027: ['Jaisalmer','Rajasthan'],
        345028: ['Jaisalmer','Rajasthan'],
        345031: ['Jaisalmer','Rajasthan'],
        345033: ['Jaisalmer','Rajasthan'],
        345034: ['Jaisalmer','Rajasthan'],
        360045: ['Rajkot','Gujarat'],
        360325: ['Rajkot','Gujarat'],
        360465: ['Rajkot','Gujarat'],
        360485: ['Rajkot','Gujarat'],
        360545: ['Porbandar','Gujarat'],
        360550: ['Porbandar','Gujarat'],
        360560: ['Porbandar','Gujarat'],
        360570: ['Porbandar','Gujarat'],
        360575: ['Porbandar','Gujarat'],
        360576: ['Porbandar','Gujarat'],
        360577: ['Porbandar','Gujarat'],
        360578: ['Porbandar','Gujarat'],
        360579: ['Porbandar','Gujarat'],
        360590: ['Porbandar','Gujarat'],
        362001: ['Junagadh','Gujarat'],
        362002: ['Junagadh','Gujarat'],
        362011: ['Junagadh','Gujarat'],
        362015: ['Gir Somnath','Gujarat'],
        362020: ['Junagadh','Gujarat'],
        362030: ['Junagadh','Gujarat'],
        362037: ['Junagadh','Gujarat'],
        362110: ['Junagadh','Gujarat'],
        362120: ['Junagadh','Gujarat'],
        362130: ['Junagadh','Gujarat'],
        362135: ['Gir Somnath','Gujarat'],
        362140: ['Gir Somnath','Gujarat'],
        362150: ['Gir Somnath','Gujarat'],
        362205: ['Junagadh','Gujarat'],
        362215: ['Junagadh','Gujarat'],
        362220: ['Junagadh','Gujarat'],
        362222: ['Junagadh','Gujarat'],
        362225: ['Junagadh','Gujarat'],
        362226: ['Junagadh','Gujarat'],
        362227: ['Junagadh','Gujarat'],
        362230: ['Porbandar','Gujarat'],
        362240: ['Junagadh','Gujarat'],
        362245: ['Junagadh','Gujarat'],
        362250: ['Junagadh','Gujarat'],
        362255: ['Junagadh','Gujarat'],
        362260: ['Junagadh','Gujarat'],
        362263: ['Junagadh','Gujarat'],
        362265: ['Gir Somnath','Gujarat'],
        362268: ['Gir Somnath','Gujarat'],
        362269: ['Gir Somnath','Gujarat'],
        362275: ['Gir Somnath','Gujarat'],
        362310: ['Junagadh','Gujarat'],
        362315: ['Junagadh','Gujarat'],
        362510: ['Gir Somnath','Gujarat'],
        362520: ['Diu','Daman And Diu'],
        362540: ['Diu','Daman And Diu'],
        362550: ['Gir Somnath','Gujarat'],
        362560: ['Gir Somnath','Gujarat'],
        362565: ['Gir Somnath','Gujarat'],
        362570: ['Diu','Daman And Diu'],
        362610: ['Junagadh','Gujarat'],
        362620: ['Porbandar','Gujarat'],
        362625: ['Junagadh','Gujarat'],
        362630: ['Junagadh','Gujarat'],
        362640: ['Junagadh','Gujarat'],
        362650: ['Porbandar','Gujarat'],
        362710: ['Gir Somnath','Gujarat'],
        362715: ['Gir Somnath','Gujarat'],
        363001: ['Surendra Nagar','Gujarat'],
        363002: ['Surendra Nagar','Gujarat'],
        363020: ['Surendra Nagar','Gujarat'],
        363030: ['Surendra Nagar','Gujarat'],
        363035: ['Surendra Nagar','Gujarat'],
        363040: ['Surendra Nagar','Gujarat'],
        363110: ['Surendra Nagar','Gujarat'],
        363115: ['Surendra Nagar','Gujarat'],
        363310: ['Surendra Nagar','Gujarat'],
        363320: ['Surendra Nagar','Gujarat'],
        363330: ['Surendra Nagar','Gujarat'],
        363351: ['Surendra Nagar','Gujarat'],
        363410: ['Surendra Nagar','Gujarat'],
        363415: ['Surendra Nagar','Gujarat'],
        363421: ['Surendra Nagar','Gujarat'],
        363423: ['Surendra Nagar','Gujarat'],
        363425: ['Surendra Nagar','Gujarat'],
        363427: ['Surendra Nagar','Gujarat'],
        363430: ['Surendra Nagar','Gujarat'],
        363435: ['Surendra Nagar','Gujarat'],
        363440: ['Surendra Nagar','Gujarat'],
        363510: ['Surendra Nagar','Gujarat'],
        363520: ['Surendra Nagar','Gujarat'],
        363530: ['Surendra Nagar','Gujarat'],
        363623: ['Morbi','Gujarat'],
        363745: ['Surendra Nagar','Gujarat'],
        363750: ['Surendra Nagar','Gujarat'],
        363755: ['Surendra Nagar','Gujarat'],
        363760: ['Surendra Nagar','Gujarat'],
        363765: ['Surendra Nagar','Gujarat'],
        363775: ['Surendra Nagar','Gujarat'],
        363780: ['Surendra Nagar','Gujarat'],
        364001: ['Bhavnagar','Gujarat'],
        364002: ['Bhavnagar','Gujarat'],
        364003: ['Bhavnagar','Gujarat'],
        364004: ['Bhavnagar','Gujarat'],
        364005: ['Bhavnagar','Gujarat'],
        364006: ['Bhavnagar','Gujarat'],
        364050: ['Bhavnagar','Gujarat'],
        364060: ['Bhavnagar','Gujarat'],
        364070: ['Bhavnagar','Gujarat'],
        364081: ['Bhavnagar','Gujarat'],
        364110: ['Bhavnagar','Gujarat'],
        364120: ['Bhavnagar','Gujarat'],
        364130: ['Bhavnagar','Gujarat'],
        364135: ['Bhavnagar','Gujarat'],
        364140: ['Bhavnagar','Gujarat'],
        364145: ['Bhavnagar','Gujarat'],
        364150: ['Bhavnagar','Gujarat'],
        364210: ['Bhavnagar','Gujarat'],
        364230: ['Bhavnagar','Gujarat'],
        364240: ['Bhavnagar','Gujarat'],
        364250: ['Bhavnagar','Gujarat'],
        364260: ['Bhavnagar','Gujarat'],
        364265: ['Bhavnagar','Gujarat'],
        364270: ['Bhavnagar','Gujarat'],
        364275: ['Bhavnagar','Gujarat'],
        364280: ['Bhavnagar','Gujarat'],
        364290: ['Bhavnagar','Gujarat'],
        364295: ['Bhavnagar','Gujarat'],
        364310: ['Bhavnagar','Gujarat'],
        364313: ['Bhavnagar','Gujarat'],
        364320: ['Bhavnagar','Gujarat'],
        364330: ['Bhavnagar','Gujarat'],
        364505: ['Bhavnagar','Gujarat'],
        364510: ['Bhavnagar','Gujarat'],
        364515: ['Amreli','Gujarat'],
        364521: ['Amreli','Gujarat'],
        364522: ['Amreli','Gujarat'],
        364525: ['Amreli','Gujarat'],
        364530: ['Amreli','Gujarat'],
        364710: ['Bhavnagar','Gujarat'],
        364720: ['Bhavnagar','Gujarat'],
        364730: ['Bhavnagar','Gujarat'],
        364740: ['Bhavnagar','Gujarat'],
        364750: ['Bhavnagar','Gujarat'],
        364760: ['Bhavnagar','Gujarat'],
        364765: ['Bhavnagar','Gujarat'],
        365220: ['Amreli','Gujarat'],
        365410: ['Amreli','Gujarat'],
        365421: ['Amreli','Gujarat'],
        365430: ['Amreli','Gujarat'],
        365435: ['Amreli','Gujarat'],
        365440: ['Amreli','Gujarat'],
        365450: ['Amreli','Gujarat'],
        365455: ['Amreli','Gujarat'],
        365456: ['Amreli','Gujarat'],
        365460: ['Amreli','Gujarat'],
        365480: ['Amreli','Gujarat'],
        365535: ['Amreli','Gujarat'],
        365540: ['Amreli','Gujarat'],
        365541: ['Amreli','Gujarat'],
        365550: ['Amreli','Gujarat'],
        365555: ['Amreli','Gujarat'],
        365560: ['Amreli','Gujarat'],
        365601: ['Amreli','Gujarat'],
        365610: ['Amreli','Gujarat'],
        365620: ['Amreli','Gujarat'],
        365630: ['Amreli','Gujarat'],
        365635: ['Amreli','Gujarat'],
        365640: ['Amreli','Gujarat'],
        365645: ['Amreli','Gujarat'],
        365650: ['Amreli','Gujarat'],
        365730: ['Amreli','Gujarat'],
        370001: ['Kachchh','Gujarat'],
        370015: ['Kachchh','Gujarat'],
        370020: ['Kachchh','Gujarat'],
        370030: ['Kachchh','Gujarat'],
        370040: ['Kachchh','Gujarat'],
        370105: ['Kachchh','Gujarat'],
        370110: ['Kachchh','Gujarat'],
        370115: ['Kachchh','Gujarat'],
        370130: ['Kachchh','Gujarat'],
        370135: ['Kachchh','Gujarat'],
        370140: ['Kachchh','Gujarat'],
        370145: ['Kachchh','Gujarat'],
        370150: ['Kachchh','Gujarat'],
        370155: ['Kachchh','Gujarat'],
        370160: ['Kachchh','Gujarat'],
        370165: ['Kachchh','Gujarat'],
        370201: ['Kachchh','Gujarat'],
        370203: ['Kachchh','Gujarat'],
        370205: ['Kachchh','Gujarat'],
        370210: ['Kachchh','Gujarat'],
        370230: ['Rajkot','Gujarat'],
        370240: ['Kachchh','Gujarat'],
        370405: ['Kachchh','Gujarat'],
        370410: ['Kachchh','Gujarat'],
        370415: ['Kachchh','Gujarat'],
        370421: ['Kachchh','Gujarat'],
        370425: ['Kachchh','Gujarat'],
        370427: ['Kachchh','Gujarat'],
        370430: ['Kachchh','Gujarat'],
        370435: ['Kachchh','Gujarat'],
        370445: ['Kachchh','Gujarat'],
        370450: ['Kachchh','Gujarat'],
        370455: ['Kachchh','Gujarat'],
        370460: ['Kachchh','Gujarat'],
        370465: ['Kachchh','Gujarat'],
        370475: ['Kachchh','Gujarat'],
        370485: ['Kachchh','Gujarat'],
        370490: ['Kachchh','Gujarat'],
        370510: ['Kachchh','Gujarat'],
        370601: ['Kachchh','Gujarat'],
        370605: ['Kachchh','Gujarat'],
        370610: ['Kachchh','Gujarat'],
        370615: ['Kachchh','Gujarat'],
        370620: ['Kachchh','Gujarat'],
        370625: ['Kachchh','Gujarat'],
        370627: ['Kachchh','Gujarat'],
        370630: ['Kachchh','Gujarat'],
        370640: ['Kachchh','Gujarat'],
        370641: ['Kachchh','Gujarat'],
        370645: ['Kachchh','Gujarat'],
        370650: ['Kachchh','Gujarat'],
        370655: ['Kachchh','Gujarat'],
        370660: ['Kachchh','Gujarat'],
        370665: ['Kachchh','Gujarat'],
        370670: ['Kachchh','Gujarat'],
        370675: ['Kachchh','Gujarat'],
        382722: ['Gandhinagar','Gujarat'],
        382728: ['Mahesana','Gujarat'],
        382815: ['Mahesana','Gujarat'],
        383001: ['Sabarkantha','Gujarat'],
        383006: ['Sabarkantha','Gujarat'],
        383010: ['Sabarkantha','Gujarat'],
        383030: ['Sabarkantha','Gujarat'],
        383110: ['Sabarkantha','Gujarat'],
        383120: ['Sabarkantha','Gujarat'],
        383210: ['Sabarkantha','Gujarat'],
        383215: ['Sabarkantha','Gujarat'],
        383220: ['Sabarkantha','Gujarat'],
        383225: ['Sabarkantha','Gujarat'],
        383230: ['Sabarkantha','Gujarat'],
        383235: ['Sabarkantha','Gujarat'],
        383240: ['Aravalli','Gujarat'],
        383245: ['Aravalli','Gujarat'],
        383246: ['Aravalli','Gujarat'],
        383250: ['Aravalli','Gujarat'],
        383251: ['Aravalli','Gujarat'],
        383255: ['Sabarkantha','Gujarat'],
        383260: ['Sabarkantha','Gujarat'],
        383270: ['Sabarkantha','Gujarat'],
        383275: ['Sabarkantha','Gujarat'],
        383276: ['Sabarkantha','Gujarat'],
        383305: ['Sabarkantha','Gujarat'],
        383307: ['Aravalli','Gujarat'],
        383310: ['Aravalli','Gujarat'],
        383315: ['Aravalli','Gujarat'],
        383316: ['Aravalli','Gujarat'],
        383317: ['Aravalli','Gujarat'],
        383320: ['Aravalli','Gujarat'],
        383325: ['Aravalli','Gujarat'],
        383330: ['Aravalli','Gujarat'],
        383335: ['Aravalli','Gujarat'],
        383340: ['Aravalli','Gujarat'],
        383350: ['Aravalli','Gujarat'],
        383355: ['Aravalli','Gujarat'],
        383410: ['Sabarkantha','Gujarat'],
        383421: ['Sabarkantha','Gujarat'],
        383422: ['Sabarkantha','Gujarat'],
        383430: ['Sabarkantha','Gujarat'],
        383434: ['Sabarkantha','Gujarat'],
        383440: ['Sabarkantha','Gujarat'],
        383450: ['Aravalli','Gujarat'],
        383460: ['Sabarkantha','Gujarat'],
        383462: ['Sabarkantha','Gujarat'],
        384001: ['Mahesana','Gujarat'],
        384002: ['Mahesana','Gujarat'],
        384003: ['Mahesana','Gujarat'],
        384012: ['Mahesana','Gujarat'],
        384110: ['Patan','Gujarat'],
        384120: ['Mahesana','Gujarat'],
        384130: ['Mahesana','Gujarat'],
        384135: ['Mahesana','Gujarat'],
        384140: ['Mahesana','Gujarat'],
        384151: ['Patan','Gujarat'],
        384160: ['Mahesana','Gujarat'],
        384170: ['Mahesana','Gujarat'],
        384205: ['Mahesana','Gujarat'],
        384220: ['Patan','Gujarat'],
        384221: ['Patan','Gujarat'],
        384225: ['Patan','Gujarat'],
        384229: ['Patan','Gujarat'],
        384230: ['Patan','Gujarat'],
        384240: ['Patan','Gujarat'],
        384241: ['Patan','Gujarat'],
        384245: ['Patan','Gujarat'],
        384246: ['Patan','Gujarat'],
        384255: ['Patan','Gujarat'],
        384260: ['Patan','Gujarat'],
        384265: ['Patan','Gujarat'],
        384266: ['Patan','Gujarat'],
        384272: ['Patan','Gujarat'],
        384275: ['Patan','Gujarat'],
        384285: ['Patan','Gujarat'],
        384290: ['Patan','Gujarat'],
        384305: ['Mahesana','Gujarat'],
        384310: ['Mahesana','Gujarat'],
        384315: ['Mahesana','Gujarat'],
        384320: ['Mahesana','Gujarat'],
        384325: ['Mahesana','Gujarat'],
        384330: ['Mahesana','Gujarat'],
        384335: ['Mahesana','Gujarat'],
        384340: ['Mahesana','Gujarat'],
        384345: ['Mahesana','Gujarat'],
        384355: ['Mahesana','Gujarat'],
        384360: ['Mahesana','Gujarat'],
        384410: ['Mahesana','Gujarat'],
        384412: ['Mahesana','Gujarat'],
        384415: ['Mahesana','Gujarat'],
        384421: ['Mahesana','Gujarat'],
        384430: ['Mahesana','Gujarat'],
        384435: ['Mahesana','Gujarat'],
        384440: ['Mahesana','Gujarat'],
        384441: ['Mahesana','Gujarat'],
        384445: ['Mahesana','Gujarat'],
        384450: ['Mahesana','Gujarat'],
        384455: ['Mahesana','Gujarat'],
        384460: ['Mahesana','Gujarat'],
        384465: ['Mahesana','Gujarat'],
        384470: ['Mahesana','Gujarat'],
        384515: ['Mahesana','Gujarat'],
        384520: ['Mahesana','Gujarat'],
        384530: ['Mahesana','Gujarat'],
        384540: ['Mahesana','Gujarat'],
        384550: ['Mahesana','Gujarat'],
        384560: ['Mahesana','Gujarat'],
        384565: ['Mahesana','Gujarat'],
        384570: ['Mahesana','Gujarat'],
        385001: ['Banaskantha','Gujarat'],
        385010: ['Banaskantha','Gujarat'],
        385110: ['Banaskantha','Gujarat'],
        385120: ['Banaskantha','Gujarat'],
        385130: ['Banaskantha','Gujarat'],
        385135: ['Banaskantha','Gujarat'],
        385210: ['Banaskantha','Gujarat'],
        385310: ['Banaskantha','Gujarat'],
        385320: ['Banaskantha','Gujarat'],
        385330: ['Banaskantha','Gujarat'],
        385340: ['Patan','Gujarat'],
        385350: ['Patan','Gujarat'],
        385360: ['Patan','Gujarat'],
        385410: ['Banaskantha','Gujarat'],
        385421: ['Banaskantha','Gujarat'],
        385505: ['Banaskantha','Gujarat'],
        385506: ['Banaskantha','Gujarat'],
        385510: ['Banaskantha','Gujarat'],
        385515: ['Banaskantha','Gujarat'],
        385520: ['Banaskantha','Gujarat'],
        385530: ['Banaskantha','Gujarat'],
        385535: ['Banaskantha','Gujarat'],
        385540: ['Bharuch','Gujarat'],
        385545: ['Banaskantha','Gujarat'],
        385550: ['Banaskantha','Gujarat'],
        385555: ['Banaskantha','Gujarat'],
        385560: ['Banaskantha','Gujarat'],
        385565: ['Banaskantha','Gujarat'],
        385566: ['Banaskantha','Gujarat'],
        385570: ['Banaskantha','Gujarat'],
        385575: ['Banaskantha','Gujarat'],
        387001: ['Kheda','Gujarat'],
        387002: ['Kheda','Gujarat'],
        387110: ['Kheda','Gujarat'],
        387115: ['Kheda','Gujarat'],
        387120: ['Kheda','Gujarat'],
        387130: ['Kheda','Gujarat'],
        387230: ['Kheda','Gujarat'],
        387305: ['Kheda','Gujarat'],
        387315: ['Kheda','Gujarat'],
        387320: ['Kheda','Gujarat'],
        387325: ['Kheda','Gujarat'],
        387330: ['Kheda','Gujarat'],
        387335: ['Kheda','Gujarat'],
        387340: ['Kheda','Gujarat'],
        387350: ['Kheda','Gujarat'],
        387355: ['Kheda','Gujarat'],
        387360: ['Kheda','Gujarat'],
        387365: ['Kheda','Gujarat'],
        387370: ['Kheda','Gujarat'],
        387375: ['Kheda','Gujarat'],
        387380: ['Kheda','Gujarat'],
        387411: ['Kheda','Gujarat'],
        387430: ['Kheda','Gujarat'],
        387510: ['Kheda','Gujarat'],
        387520: ['Kheda','Gujarat'],
        387530: ['Kheda','Gujarat'],
        387540: ['Kheda','Gujarat'],
        387550: ['Kheda','Gujarat'],
        387560: ['Kheda','Gujarat'],
        387570: ['Kheda','Gujarat'],
        387610: ['Kheda','Gujarat'],
        387620: ['Kheda','Gujarat'],
        387630: ['Kheda','Gujarat'],
        387635: ['Kheda','Gujarat'],
        387640: ['Kheda','Gujarat'],
        387650: ['Kheda','Gujarat'],
        388225: ['Kheda','Gujarat'],
        388230: ['Kheda','Gujarat'],
        388235: ['Mahisagar','Gujarat'],
        388239: ['Mahisagar','Gujarat'],
        388245: ['Mahisagar','Gujarat'],
        388250: ['Mahisagar','Gujarat'],
        388255: ['Mahisagar','Gujarat'],
        388260: ['Mahisagar','Gujarat'],
        388265: ['Mahisagar','Gujarat'],
        388270: ['Mahisagar','Gujarat'],
        388410: ['Kheda','Gujarat'],
        388421: ['Kheda','Gujarat'],
        388710: ['Panch Mahals','Gujarat'],
        388713: ['Panch Mahals','Gujarat'],
        389001: ['Panch Mahals','Gujarat'],
        389002: ['Panch Mahals','Gujarat'],
        389110: ['Panch Mahals','Gujarat'],
        389115: ['Panch Mahals','Gujarat'],
        389120: ['Panch Mahals','Gujarat'],
        389130: ['Dahod','Gujarat'],
        389140: ['Dahod','Gujarat'],
        389146: ['Dahod','Gujarat'],
        389151: ['Dahod','Gujarat'],
        389152: ['Dahod','Gujarat'],
        389154: ['Dahod','Gujarat'],
        389155: ['Dahod','Gujarat'],
        389160: ['Dahod','Gujarat'],
        389170: ['Dahod','Gujarat'],
        389172: ['Dahod','Gujarat'],
        389175: ['Dahod','Gujarat'],
        389180: ['Dahod','Gujarat'],
        389190: ['Dahod','Gujarat'],
        389210: ['Panch Mahals','Gujarat'],
        389220: ['Mahisagar','Gujarat'],
        389230: ['Mahisagar','Gujarat'],
        389232: ['Mahisagar','Gujarat'],
        389235: ['Mahisagar','Gujarat'],
        389240: ['Dahod','Gujarat'],
        389250: ['Mahisagar','Gujarat'],
        389260: ['Mahisagar','Gujarat'],
        389265: ['Mahisagar','Gujarat'],
        389310: ['Panch Mahals','Gujarat'],
        389320: ['Panch Mahals','Gujarat'],
        389330: ['Panch Mahals','Gujarat'],
        389340: ['Panch Mahals','Gujarat'],
        389341: ['Panch Mahals','Gujarat'],
        389350: ['Panch Mahals','Gujarat'],
        389360: ['Panch Mahals','Gujarat'],
        389365: ['Panch Mahals','Gujarat'],
        389370: ['Panch Mahals','Gujarat'],
        389380: ['Dahod','Gujarat'],
        389382: ['Dahod','Gujarat'],
        389390: ['Panch Mahals','Gujarat'],
        391810: ['Bharuch','Gujarat'],
        392001: ['Bharuch','Gujarat'],
        392011: ['Bharuch','Gujarat'],
        392012: ['Bharuch','Gujarat'],
        392015: ['Bharuch','Gujarat'],
        392020: ['Bharuch','Gujarat'],
        392025: ['Bharuch','Gujarat'],
        392035: ['Bharuch','Gujarat'],
        392040: ['Bharuch','Gujarat'],
        392135: ['Bharuch','Gujarat'],
        392140: ['Bharuch','Gujarat'],
        392155: ['Bharuch','Gujarat'],
        392165: ['Bharuch','Gujarat'],
        392180: ['Bharuch','Gujarat'],
        392210: ['Bharuch','Gujarat'],
        392215: ['Bharuch','Gujarat'],
        392230: ['Bharuch','Gujarat'],
        392240: ['Bharuch','Gujarat'],
        393001: ['Bharuch','Gujarat'],
        393017: ['Bharuch','Gujarat'],
        393020: ['Bharuch','Gujarat'],
        393025: ['Narmada','Gujarat'],
        393030: ['Bharuch','Gujarat'],
        393040: ['Narmada','Gujarat'],
        393041: ['Narmada','Gujarat'],
        393110: ['Bharuch','Gujarat'],
        393115: ['Bharuch','Gujarat'],
        393120: ['Bharuch','Gujarat'],
        393125: ['Bharuch','Gujarat'],
        393135: ['Bharuch','Gujarat'],
        393140: ['Narmada','Gujarat'],
        393145: ['Narmada','Gujarat'],
        393150: ['Narmada','Gujarat'],
        393151: ['Narmada','Gujarat'],
        393155: ['Narmada','Gujarat'],
        394112: ['Surat','Gujarat'],
        394115: ['Bharuch','Gujarat'],
        394116: ['Bharuch','Gujarat'],
        394710: ['Dangs','Gujarat'],
        394716: ['Dangs','Gujarat'],
        394720: ['Dangs','Gujarat'],
        394730: ['Dangs','Gujarat'],
        394810: ['Bharuch','Gujarat'],
        396210: ['Daman','Daman And Diu'],
        396240: ['Dadra  Nagar Haveli','Dadra And Nagar Hav.'],
        396380: ['Navsari','Gujarat'],
        396433: ['Navsari','Gujarat'],
        396580: ['Navsari','Gujarat'],
        402101: ['Raigarh','Maharashtra'],
        402102: ['Raigarh','Maharashtra'],
        402103: ['Raigarh','Maharashtra'],
        402104: ['Raigarh','Maharashtra'],
        402105: ['Raigarh','Maharashtra'],
        402106: ['Raigarh','Maharashtra'],
        402107: ['Raigarh','Maharashtra'],
        402108: ['Raigarh','Maharashtra'],
        402109: ['Raigarh','Maharashtra'],
        402110: ['Raigarh','Maharashtra'],
        402111: ['Raigarh','Maharashtra'],
        402112: ['Raigarh','Maharashtra'],
        402113: ['Raigarh','Maharashtra'],
        402114: ['Raigarh','Maharashtra'],
        402115: ['Raigarh','Maharashtra'],
        402116: ['Raigarh','Maharashtra'],
        402117: ['Raigarh','Maharashtra'],
        402120: ['Raigarh','Maharashtra'],
        402122: ['Raigarh','Maharashtra'],
        402125: ['Raigarh','Maharashtra'],
        402126: ['Raigarh','Maharashtra'],
        402201: ['Raigarh','Maharashtra'],
        402202: ['Raigarh','Maharashtra'],
        402203: ['Raigarh','Maharashtra'],
        402204: ['Raigarh','Maharashtra'],
        402207: ['Raigarh','Maharashtra'],
        402208: ['Raigarh','Maharashtra'],
        402209: ['Raigarh','Maharashtra'],
        402301: ['Raigarh','Maharashtra'],
        402302: ['Raigarh','Maharashtra'],
        402303: ['Raigarh','Maharashtra'],
        402304: ['Raigarh','Maharashtra'],
        402305: ['Raigarh','Maharashtra'],
        402306: ['Raigarh','Maharashtra'],
        402307: ['Raigarh','Maharashtra'],
        402308: ['Raigarh','Maharashtra'],
        402309: ['Raigarh','Maharashtra'],
        402401: ['Raigarh','Maharashtra'],
        402402: ['Raigarh','Maharashtra'],
        402403: ['Raigarh','Maharashtra'],
        402404: ['Raigarh','Maharashtra'],
        410101: ['Raigarh(Mh)','Maharashtra'],
        410102: ['Raigarh(Mh)','Maharashtra'],
        410201: ['Raigarh(Mh)','Maharashtra'],
        410202: ['Raigarh(Mh)','Maharashtra'],
        410203: ['Raigarh(Mh)','Maharashtra'],
        410204: ['Raigarh(Mh)','Maharashtra'],
        410205: ['Raigarh','Maharashtra'],
        410207: ['Raigarh(Mh)','Maharashtra'],
        410216: ['Raigarh(Mh)','Maharashtra'],
        410220: ['Raigarh(Mh)','Maharashtra'],
        410222: ['Raigarh(Mh)','Maharashtra'],
        412801: ['Satara','Maharashtra'],
        412802: ['Satara','Maharashtra'],
        412803: ['Satara','Maharashtra'],
        412804: ['Satara','Maharashtra'],
        412805: ['Satara','Maharashtra'],
        412806: ['Satara','Maharashtra'],
        413204: ['Ahmed Nagar','Maharashtra'],
        413207: ['Beed','Maharashtra'],
        413229: ['Beed','Maharashtra'],
        413249: ['Beed','Maharashtra'],
        413405: ['Osmanabad','Maharashtra'],
        413501: ['Osmanabad','Maharashtra'],
        413502: ['Osmanabad','Maharashtra'],
        413503: ['Osmanabad','Maharashtra'],
        413504: ['Osmanabad','Maharashtra'],
        413505: ['Osmanabad','Maharashtra'],
        413506: ['Osmanabad','Maharashtra'],
        413507: ['Latur','Maharashtra'],
        413508: ['Osmanabad','Maharashtra'],
        413509: ['Osmanabad','Maharashtra'],
        413510: ['Latur','Maharashtra'],
        413511: ['Latur','Maharashtra'],
        413512: ['Latur','Maharashtra'],
        413513: ['Latur','Maharashtra'],
        413514: ['Latur','Maharashtra'],
        413515: ['Latur','Maharashtra'],
        413516: ['Latur','Maharashtra'],
        413517: ['Latur','Maharashtra'],
        413518: ['Latur','Maharashtra'],
        413519: ['Latur','Maharashtra'],
        413520: ['Latur','Maharashtra'],
        413521: ['Latur','Maharashtra'],
        413522: ['Latur','Maharashtra'],
        413523: ['Latur','Maharashtra'],
        413524: ['Latur','Maharashtra'],
        413525: ['Osmanabad','Maharashtra'],
        413526: ['Osmanabad','Maharashtra'],
        413527: ['Latur','Maharashtra'],
        413528: ['Osmanabad','Maharashtra'],
        413529: ['Latur','Maharashtra'],
        413530: ['Latur','Maharashtra'],
        413531: ['Latur','Maharashtra'],
        413532: ['Latur','Maharashtra'],
        413534: ['Latur','Maharashtra'],
        413544: ['Latur','Maharashtra'],
        413581: ['Latur','Maharashtra'],
        413582: ['Osmanabad','Maharashtra'],
        413601: ['Osmanabad','Maharashtra'],
        413602: ['Osmanabad','Maharashtra'],
        413603: ['Osmanabad','Maharashtra'],
        413604: ['Osmanabad','Maharashtra'],
        413605: ['Osmanabad','Maharashtra'],
        413606: ['Osmanabad','Maharashtra'],
        413607: ['Latur','Maharashtra'],
        413608: ['Osmanabad','Maharashtra'],
        413623: ['Osmanabad','Maharashtra'],
        413624: ['Osmanabad','Maharashtra'],
        413705: ['Ahmed Nagar','Maharashtra'],
        413707: ['Ahmed Nagar','Maharashtra'],
        413717: ['Ahmed Nagar','Maharashtra'],
        413726: ['Ahmed Nagar','Maharashtra'],
        413738: ['Ahmed Nagar','Maharashtra'],
        414003: ['Ahmed Nagar','Maharashtra'],
        414006: ['Ahmed Nagar','Maharashtra'],
        414105: ['Ahmed Nagar','Maharashtra'],
        414110: ['Ahmed Nagar','Maharashtra'],
        414111: ['Ahmed Nagar','Maharashtra'],
        414113: ['Ahmed Nagar','Maharashtra'],
        414202: ['Beed','Maharashtra'],
        414203: ['Beed','Maharashtra'],
        414204: ['Beed','Maharashtra'],
        414205: ['Beed','Maharashtra'],
        414401: ['Ahmed Nagar','Maharashtra'],
        414503: ['Ahmed Nagar','Maharashtra'],
        414609: ['Ahmed Nagar','Maharashtra'],
        415001: ['Satara','Maharashtra'],
        415002: ['Satara','Maharashtra'],
        415003: ['Satara','Maharashtra'],
        415004: ['Satara','Maharashtra'],
        415010: ['Satara','Maharashtra'],
        415011: ['Satara','Maharashtra'],
        415012: ['Satara','Maharashtra'],
        415013: ['Satara','Maharashtra'],
        415014: ['Satara','Maharashtra'],
        415015: ['Satara','Maharashtra'],
        415019: ['Satara','Maharashtra'],
        415020: ['Satara','Maharashtra'],
        415021: ['Satara','Maharashtra'],
        415022: ['Satara','Maharashtra'],
        415023: ['Satara','Maharashtra'],
        415102: ['Satara','Maharashtra'],
        415103: ['Satara','Maharashtra'],
        415104: ['Satara','Maharashtra'],
        415105: ['Satara','Maharashtra'],
        415106: ['Satara','Maharashtra'],
        415107: ['Satara','Maharashtra'],
        415108: ['Satara','Maharashtra'],
        415109: ['Satara','Maharashtra'],
        415110: ['Satara','Maharashtra'],
        415111: ['Satara','Maharashtra'],
        415112: ['Satara','Maharashtra'],
        415114: ['Satara','Maharashtra'],
        415115: ['Satara','Maharashtra'],
        415116: ['Satara','Maharashtra'],
        415122: ['Satara','Maharashtra'],
        415124: ['Satara','Maharashtra'],
        415202: ['Ratnagiri','Maharashtra'],
        415203: ['Ratnagiri','Maharashtra'],
        415205: ['Satara','Maharashtra'],
        415206: ['Satara','Maharashtra'],
        415207: ['Satara','Maharashtra'],
        415208: ['Ratnagiri','Maharashtra'],
        415209: ['Satara','Maharashtra'],
        415211: ['Satara','Maharashtra'],
        415212: ['Satara','Maharashtra'],
        415213: ['Raigarh','Maharashtra'],
        415214: ['Ratnagiri','Maharashtra'],
        415312: ['Satara','Maharashtra'],
        415501: ['Satara','Maharashtra'],
        415502: ['Satara','Maharashtra'],
        415503: ['Satara','Maharashtra'],
        415504: ['Satara','Maharashtra'],
        415505: ['Satara','Maharashtra'],
        415506: ['Satara','Maharashtra'],
        415507: ['Satara','Maharashtra'],
        415508: ['Satara','Maharashtra'],
        415509: ['Satara','Maharashtra'],
        415510: ['Satara','Maharashtra'],
        415511: ['Satara','Maharashtra'],
        415512: ['Satara','Maharashtra'],
        415513: ['Satara','Maharashtra'],
        415514: ['Satara','Maharashtra'],
        415515: ['Satara','Maharashtra'],
        415516: ['Satara','Maharashtra'],
        415517: ['Satara','Maharashtra'],
        415518: ['Satara','Maharashtra'],
        415519: ['Satara','Maharashtra'],
        415520: ['Satara','Maharashtra'],
        415521: ['Satara','Maharashtra'],
        415522: ['Satara','Maharashtra'],
        415523: ['Satara','Maharashtra'],
        415524: ['Satara','Maharashtra'],
        415525: ['Satara','Maharashtra'],
        415526: ['Satara','Maharashtra'],
        415527: ['Satara','Maharashtra'],
        415528: ['Satara','Maharashtra'],
        415530: ['Satara','Maharashtra'],
        415536: ['Satara','Maharashtra'],
        415537: ['Satara','Maharashtra'],
        415538: ['Satara','Maharashtra'],
        415539: ['Satara','Maharashtra'],
        415540: ['Satara','Maharashtra'],
        415601: ['Ratnagiri','Maharashtra'],
        415602: ['Ratnagiri','Maharashtra'],
        415603: ['Ratnagiri','Maharashtra'],
        415604: ['Ratnagiri','Maharashtra'],
        415605: ['Ratnagiri','Maharashtra'],
        415606: ['Ratnagiri','Maharashtra'],
        415607: ['Ratnagiri','Maharashtra'],
        415608: ['Ratnagiri','Maharashtra'],
        415609: ['Ratnagiri','Maharashtra'],
        415610: ['Ratnagiri','Maharashtra'],
        415611: ['Ratnagiri','Maharashtra'],
        415612: ['Ratnagiri','Maharashtra'],
        415613: ['Ratnagiri','Maharashtra'],
        415614: ['Ratnagiri','Maharashtra'],
        415615: ['Ratnagiri','Maharashtra'],
        415616: ['Ratnagiri','Maharashtra'],
        415617: ['Ratnagiri','Maharashtra'],
        415619: ['Ratnagiri','Maharashtra'],
        415620: ['Ratnagiri','Maharashtra'],
        415621: ['Ratnagiri','Maharashtra'],
        415626: ['Ratnagiri','Maharashtra'],
        415628: ['Ratnagiri','Maharashtra'],
        415629: ['Ratnagiri','Maharashtra'],
        415634: ['Ratnagiri','Maharashtra'],
        415637: ['Ratnagiri','Maharashtra'],
        415639: ['Ratnagiri','Maharashtra'],
        415640: ['Ratnagiri','Maharashtra'],
        415641: ['Ratnagiri','Maharashtra'],
        415643: ['Ratnagiri','Maharashtra'],
        415701: ['Ratnagiri','Maharashtra'],
        415702: ['Ratnagiri','Maharashtra'],
        415703: ['Ratnagiri','Maharashtra'],
        415706: ['Ratnagiri','Maharashtra'],
        415708: ['Ratnagiri','Maharashtra'],
        415709: ['Ratnagiri','Maharashtra'],
        415710: ['Ratnagiri','Maharashtra'],
        415711: ['Ratnagiri','Maharashtra'],
        415712: ['Ratnagiri','Maharashtra'],
        415713: ['Ratnagiri','Maharashtra'],
        415714: ['Ratnagiri','Maharashtra'],
        415715: ['Ratnagiri','Maharashtra'],
        415716: ['Ratnagiri','Maharashtra'],
        415717: ['Ratnagiri','Maharashtra'],
        415718: ['Ratnagiri','Maharashtra'],
        415719: ['Ratnagiri','Maharashtra'],
        415720: ['Ratnagiri','Maharashtra'],
        415722: ['Ratnagiri','Maharashtra'],
        415724: ['Ratnagiri','Maharashtra'],
        415726: ['Ratnagiri','Maharashtra'],
        415727: ['Ratnagiri','Maharashtra'],
        415728: ['Ratnagiri','Maharashtra'],
        415729: ['Ratnagiri','Maharashtra'],
        415730: ['Ratnagiri','Maharashtra'],
        415801: ['Ratnagiri','Maharashtra'],
        415802: ['Ratnagiri','Maharashtra'],
        415803: ['Ratnagiri','Maharashtra'],
        415804: ['Ratnagiri','Maharashtra'],
        415805: ['Ratnagiri','Maharashtra'],
        415806: ['Ratnagiri','Maharashtra'],
        415807: ['Ratnagiri','Maharashtra'],
        416510: ['Sindhudurg','Maharashtra'],
        416511: ['Sindhudurg','Maharashtra'],
        416512: ['Sindhudurg','Maharashtra'],
        416513: ['Sindhudurg','Maharashtra'],
        416514: ['Sindhudurg','Maharashtra'],
        416515: ['Sindhudurg','Maharashtra'],
        416516: ['Sindhudurg','Maharashtra'],
        416517: ['Sindhudurg','Maharashtra'],
        416518: ['Sindhudurg','Maharashtra'],
        416519: ['Sindhudurg','Maharashtra'],
        416520: ['Sindhudurg','Maharashtra'],
        416521: ['Sindhudurg','Maharashtra'],
        416522: ['Sindhudurg','Maharashtra'],
        416523: ['Sindhudurg','Maharashtra'],
        416524: ['Sindhudurg','Maharashtra'],
        416525: ['Sindhudurg','Maharashtra'],
        416528: ['Sindhudurg','Maharashtra'],
        416529: ['Sindhudurg','Maharashtra'],
        416531: ['Sindhudurg','Maharashtra'],
        416534: ['Sindhudurg','Maharashtra'],
        416549: ['Sindhudurg','Maharashtra'],
        416550: ['Sindhudurg','Maharashtra'],
        416601: ['Sindhudurg','Maharashtra'],
        416602: ['Sindhudurg','Maharashtra'],
        416603: ['Sindhudurg','Maharashtra'],
        416604: ['Sindhudurg','Maharashtra'],
        416605: ['Sindhudurg','Maharashtra'],
        416606: ['Sindhudurg','Maharashtra'],
        416608: ['Sindhudurg','Maharashtra'],
        416609: ['Sindhudurg','Maharashtra'],
        416610: ['Sindhudurg','Maharashtra'],
        416611: ['Sindhudurg','Maharashtra'],
        416612: ['Sindhudurg','Maharashtra'],
        416613: ['Sindhudurg','Maharashtra'],
        416614: ['Sindhudurg','Maharashtra'],
        416615: ['Sindhudurg','Maharashtra'],
        416616: ['Sindhudurg','Maharashtra'],
        416620: ['Sindhudurg','Maharashtra'],
        416623: ['Sindhudurg','Maharashtra'],
        416626: ['Sindhudurg','Maharashtra'],
        416628: ['Sindhudurg','Maharashtra'],
        416630: ['Sindhudurg','Maharashtra'],
        416632: ['Sindhudurg','Maharashtra'],
        416701: ['Ratnagiri','Maharashtra'],
        416702: ['Ratnagiri','Maharashtra'],
        416703: ['Sindhudurg','Maharashtra'],
        416704: ['Ratnagiri','Maharashtra'],
        416705: ['Ratnagiri','Maharashtra'],
        416707: ['Ratnagiri','Maharashtra'],
        416709: ['Ratnagiri','Maharashtra'],
        416712: ['Ratnagiri','Maharashtra'],
        416713: ['Ratnagiri','Maharashtra'],
        416801: ['Sindhudurg','Maharashtra'],
        416803: ['Sindhudurg','Maharashtra'],
        416804: ['Sindhudurg','Maharashtra'],
        416805: ['Sindhudurg','Maharashtra'],
        416806: ['Sindhudurg','Maharashtra'],
        416807: ['Sindhudurg','Maharashtra'],
        416810: ['Sindhudurg','Maharashtra'],
        416811: ['Sindhudurg','Maharashtra'],
        416812: ['Sindhudurg','Maharashtra'],
        416813: ['Sindhudurg','Maharashtra'],
        422602: ['Ahmed Nagar','Maharashtra'],
        422604: ['Ahmed Nagar','Maharashtra'],
        422608: ['Ahmed Nagar','Maharashtra'],
        422622: ['Ahmed Nagar','Maharashtra'],
        423604: ['Ahmed Nagar','Maharashtra'],
        424001: ['Dhule','Maharashtra'],
        424002: ['Dhule','Maharashtra'],
        424004: ['Dhule','Maharashtra'],
        424005: ['Dhule','Maharashtra'],
        424006: ['Dhule','Maharashtra'],
        424101: ['Jalgaon','Maharashtra'],
        424102: ['Jalgaon','Maharashtra'],
        424103: ['Jalgaon','Maharashtra'],
        424104: ['Jalgaon','Maharashtra'],
        424105: ['Jalgaon','Maharashtra'],
        424106: ['Jalgaon','Maharashtra'],
        424107: ['Jalgaon','Maharashtra'],
        424108: ['Jalgaon','Maharashtra'],
        424119: ['Jalgaon','Maharashtra'],
        424201: ['Jalgaon','Maharashtra'],
        424202: ['Jalgaon','Maharashtra'],
        424203: ['Jalgaon','Maharashtra'],
        424204: ['Jalgaon','Maharashtra'],
        424205: ['Jalgaon','Maharashtra'],
        424206: ['Jalgaon','Maharashtra'],
        424207: ['Jalgaon','Maharashtra'],
        424208: ['Jalgaon','Maharashtra'],
        424301: ['Dhule','Maharashtra'],
        424302: ['Dhule','Maharashtra'],
        424303: ['Dhule','Maharashtra'],
        424304: ['Dhule','Maharashtra'],
        424305: ['Dhule','Maharashtra'],
        424306: ['Dhule','Maharashtra'],
        424307: ['Dhule','Maharashtra'],
        424308: ['Dhule','Maharashtra'],
        424309: ['Dhule','Maharashtra'],
        424310: ['Dhule','Maharashtra'],
        424311: ['Dhule','Maharashtra'],
        424318: ['Dhule','Maharashtra'],
        425001: ['Jalgaon','Maharashtra'],
        425002: ['Jalgaon','Maharashtra'],
        425003: ['Jalgaon','Maharashtra'],
        425004: ['Jalgaon','Maharashtra'],
        425101: ['Jalgaon','Maharashtra'],
        425102: ['Jalgaon','Maharashtra'],
        425103: ['Jalgaon','Maharashtra'],
        425104: ['Jalgaon','Maharashtra'],
        425105: ['Jalgaon','Maharashtra'],
        425107: ['Jalgaon','Maharashtra'],
        425108: ['Jalgaon','Maharashtra'],
        425109: ['Jalgaon','Maharashtra'],
        425110: ['Jalgaon','Maharashtra'],
        425111: ['Jalgaon','Maharashtra'],
        425112: ['Jalgaon','Maharashtra'],
        425113: ['Jalgaon','Maharashtra'],
        425114: ['Jalgaon','Maharashtra'],
        425115: ['Jalgaon','Maharashtra'],
        425116: ['Jalgaon','Maharashtra'],
        425201: ['Jalgaon','Maharashtra'],
        425203: ['Jalgaon','Maharashtra'],
        425301: ['Jalgaon','Maharashtra'],
        425302: ['Jalgaon','Maharashtra'],
        425303: ['Jalgaon','Maharashtra'],
        425304: ['Jalgaon','Maharashtra'],
        425305: ['Jalgaon','Maharashtra'],
        425306: ['Jalgaon','Maharashtra'],
        425307: ['Jalgaon','Maharashtra'],
        425308: ['Jalgaon','Maharashtra'],
        425309: ['Jalgaon','Maharashtra'],
        425310: ['Jalgaon','Maharashtra'],
        425311: ['Jalgaon','Maharashtra'],
        425327: ['Jalgaon','Maharashtra'],
        425401: ['Jalgaon','Maharashtra'],
        425402: ['Jalgaon','Maharashtra'],
        425403: ['Dhule','Maharashtra'],
        425404: ['Dhule','Maharashtra'],
        425405: ['Dhule','Maharashtra'],
        425406: ['Dhule','Maharashtra'],
        425407: ['Dhule','Maharashtra'],
        425408: ['Dhule','Maharashtra'],
        425409: ['Nandurbar','Maharashtra'],
        425410: ['Nandurbar','Maharashtra'],
        425411: ['Nandurbar','Maharashtra'],
        425412: ['Nandurbar','Maharashtra'],
        425413: ['Nandurbar','Maharashtra'],
        425414: ['Nandurbar','Maharashtra'],
        425415: ['Nandurbar','Maharashtra'],
        425416: ['Nandurbar','Maharashtra'],
        425417: ['Nandurbar','Maharashtra'],
        425418: ['Nandurbar','Maharashtra'],
        425419: ['Nandurbar','Maharashtra'],
        425420: ['Jalgaon','Maharashtra'],
        425421: ['Dhule','Maharashtra'],
        425422: ['Nandurbar','Maharashtra'],
        425423: ['Nandurbar','Maharashtra'],
        425424: ['Nandurbar','Maharashtra'],
        425426: ['Nandurbar','Maharashtra'],
        425427: ['Dhule','Maharashtra'],
        425428: ['Dhule','Maharashtra'],
        425432: ['Nandurbar','Maharashtra'],
        425442: ['Nandurbar','Maharashtra'],
        425444: ['Nandurbar','Maharashtra'],
        425452: ['Nandurbar','Maharashtra'],
        425501: ['Jalgaon','Maharashtra'],
        425502: ['Jalgaon','Maharashtra'],
        425503: ['Jalgaon','Maharashtra'],
        425504: ['Jalgaon','Maharashtra'],
        425505: ['Jalgaon','Maharashtra'],
        425506: ['Jalgaon','Maharashtra'],
        425507: ['Jalgaon','Maharashtra'],
        425508: ['Jalgaon','Maharashtra'],
        425524: ['Jalgaon','Maharashtra'],
        431114: ['Jalna','Maharashtra'],
        431122: ['Beed','Maharashtra'],
        431123: ['Beed','Maharashtra'],
        431124: ['Beed','Maharashtra'],
        431125: ['Beed','Maharashtra'],
        431126: ['Beed','Maharashtra'],
        431127: ['Beed','Maharashtra'],
        431128: ['Beed','Maharashtra'],
        431129: ['Beed','Maharashtra'],
        431130: ['Beed','Maharashtra'],
        431131: ['Beed','Maharashtra'],
        431142: ['Beed','Maharashtra'],
        431143: ['Beed','Maharashtra'],
        431144: ['Beed','Maharashtra'],
        431153: ['Beed','Maharashtra'],
        431202: ['Jalna','Maharashtra'],
        431203: ['Jalna','Maharashtra'],
        431204: ['Jalna','Maharashtra'],
        431205: ['Jalna','Maharashtra'],
        431206: ['Jalna','Maharashtra'],
        431207: ['Jalna','Maharashtra'],
        431208: ['Jalna','Maharashtra'],
        431209: ['Jalna','Maharashtra'],
        431211: ['Jalna','Maharashtra'],
        431214: ['Jalna','Maharashtra'],
        431215: ['Jalna','Maharashtra'],
        431401: ['Parbhani','Maharashtra'],
        431402: ['Parbhani','Maharashtra'],
        431502: ['Jalna','Maharashtra'],
        431503: ['Parbhani','Maharashtra'],
        431504: ['Jalna','Maharashtra'],
        431505: ['Parbhani','Maharashtra'],
        431506: ['Parbhani','Maharashtra'],
        431507: ['Jalna','Maharashtra'],
        431508: ['Parbhani','Maharashtra'],
        431509: ['Hingoli','Maharashtra'],
        431510: ['Hingoli','Maharashtra'],
        431511: ['Parbhani','Maharashtra'],
        431512: ['Hingoli','Maharashtra'],
        431513: ['Hingoli','Maharashtra'],
        431514: ['Parbhani','Maharashtra'],
        431515: ['Beed','Maharashtra'],
        431516: ['Parbhani','Maharashtra'],
        431517: ['Beed','Maharashtra'],
        431518: ['Beed','Maharashtra'],
        431519: ['Beed','Maharashtra'],
        431520: ['Beed','Maharashtra'],
        431521: ['Parbhani','Maharashtra'],
        431522: ['Latur','Maharashtra'],
        431523: ['Beed','Maharashtra'],
        431530: ['Beed','Maharashtra'],
        431536: ['Parbhani','Maharashtra'],
        431537: ['Parbhani','Maharashtra'],
        431540: ['Parbhani','Maharashtra'],
        431541: ['Parbhani','Maharashtra'],
        431542: ['Hingoli','Maharashtra'],
        431601: ['Nanded','Maharashtra'],
        431602: ['Nanded','Maharashtra'],
        431603: ['Nanded','Maharashtra'],
        431604: ['Nanded','Maharashtra'],
        431605: ['Nanded','Maharashtra'],
        431606: ['Nanded','Maharashtra'],
        431701: ['Hingoli','Maharashtra'],
        431702: ['Hingoli','Maharashtra'],
        431703: ['Hingoli','Maharashtra'],
        431704: ['Nanded','Maharashtra'],
        431705: ['Hingoli','Maharashtra'],
        431707: ['Nanded','Maharashtra'],
        431708: ['Nanded','Maharashtra'],
        431709: ['Nanded','Maharashtra'],
        431710: ['Nanded','Maharashtra'],
        431711: ['Nanded','Maharashtra'],
        431712: ['Nanded','Maharashtra'],
        431713: ['Nanded','Maharashtra'],
        431714: ['Nanded','Maharashtra'],
        431715: ['Nanded','Maharashtra'],
        431716: ['Nanded','Maharashtra'],
        431717: ['Nanded','Maharashtra'],
        431718: ['Nanded','Maharashtra'],
        431719: ['Nanded','Maharashtra'],
        431720: ['Parbhani','Maharashtra'],
        431721: ['Nanded','Maharashtra'],
        431722: ['Nanded','Maharashtra'],
        431723: ['Nanded','Maharashtra'],
        431731: ['Nanded','Maharashtra'],
        431736: ['Nanded','Maharashtra'],
        431741: ['Nanded','Maharashtra'],
        431742: ['Nanded','Maharashtra'],
        431743: ['Nanded','Maharashtra'],
        431745: ['Nanded','Maharashtra'],
        431746: ['Nanded','Maharashtra'],
        431750: ['Nanded','Maharashtra'],
        431801: ['Nanded','Maharashtra'],
        431802: ['Nanded','Maharashtra'],
        431803: ['Nanded','Maharashtra'],
        431804: ['Nanded','Maharashtra'],
        431805: ['Nanded','Maharashtra'],
        431806: ['Nanded','Maharashtra'],
        431807: ['Aurangabad','Maharashtra'],
        431808: ['Nanded','Maharashtra'],
        431809: ['Nanded','Maharashtra'],
        431810: ['Nanded','Maharashtra'],
        431811: ['Nanded','Maharashtra'],
        441208: ['Gadchiroli','Maharashtra'],
        441209: ['Gadchiroli','Maharashtra'],
        441217: ['Gadchiroli','Maharashtra'],
        441601: ['Gondia','Maharashtra'],
        441614: ['Gondia','Maharashtra'],
        441701: ['Gondia','Maharashtra'],
        441702: ['Gondia','Maharashtra'],
        441801: ['Gondia','Maharashtra'],
        441802: ['Bhandara','Maharashtra'],
        441803: ['Bhandara','Maharashtra'],
        441804: ['Bhandara','Maharashtra'],
        441805: ['Bhandara','Maharashtra'],
        441809: ['Bhandara','Maharashtra'],
        441901: ['Gondia','Maharashtra'],
        441902: ['Gondia','Maharashtra'],
        441903: ['Bhandara','Maharashtra'],
        441904: ['Bhandara','Maharashtra'],
        441905: ['Bhandara','Maharashtra'],
        441906: ['Bhandara','Maharashtra'],
        441907: ['Bhandara','Maharashtra'],
        441908: ['Bhandara','Maharashtra'],
        441909: ['Bhandara','Maharashtra'],
        441910: ['Bhandara','Maharashtra'],
        441914: ['Bhandara','Maharashtra'],
        441916: ['Gondia','Maharashtra'],
        441924: ['Bhandara','Maharashtra'],
        442001: ['Wardha','Maharashtra'],
        442003: ['Wardha','Maharashtra'],
        442101: ['Wardha','Maharashtra'],
        442102: ['Wardha','Maharashtra'],
        442104: ['Wardha','Maharashtra'],
        442105: ['Wardha','Maharashtra'],
        442106: ['Wardha','Maharashtra'],
        442111: ['Wardha','Maharashtra'],
        442201: ['Wardha','Maharashtra'],
        442203: ['Wardha','Maharashtra'],
        442301: ['Wardha','Maharashtra'],
        442303: ['Wardha','Maharashtra'],
        442304: ['Wardha','Maharashtra'],
        442305: ['Wardha','Maharashtra'],
        442306: ['Wardha','Maharashtra'],
        442307: ['Wardha','Maharashtra'],
        442603: ['Gadchiroli','Maharashtra'],
        442604: ['Gadchiroli','Maharashtra'],
        442605: ['Gadchiroli','Maharashtra'],
        442606: ['Gadchiroli','Maharashtra'],
        442703: ['Gadchiroli','Maharashtra'],
        442704: ['Gadchiroli','Maharashtra'],
        442705: ['Gadchiroli','Maharashtra'],
        442707: ['Gadchiroli','Maharashtra'],
        442709: ['Gadchiroli','Maharashtra'],
        442710: ['Gadchiroli','Maharashtra'],
        443001: ['Buldhana','Maharashtra'],
        443002: ['Buldhana','Maharashtra'],
        443101: ['Buldhana','Maharashtra'],
        443102: ['Buldhana','Maharashtra'],
        443103: ['Buldhana','Maharashtra'],
        443104: ['Buldhana','Maharashtra'],
        443106: ['Buldhana','Maharashtra'],
        443112: ['Buldhana','Maharashtra'],
        443201: ['Buldhana','Maharashtra'],
        443202: ['Buldhana','Maharashtra'],
        443203: ['Buldhana','Maharashtra'],
        443204: ['Buldhana','Maharashtra'],
        443206: ['Buldhana','Maharashtra'],
        443301: ['Buldhana','Maharashtra'],
        443302: ['Buldhana','Maharashtra'],
        443303: ['Buldhana','Maharashtra'],
        443304: ['Buldhana','Maharashtra'],
        443308: ['Buldhana','Maharashtra'],
        443401: ['Buldhana','Maharashtra'],
        443402: ['Buldhana','Maharashtra'],
        443403: ['Buldhana','Maharashtra'],
        443404: ['Buldhana','Maharashtra'],
        444001: ['Akola','Maharashtra'],
        444002: ['Akola','Maharashtra'],
        444003: ['Akola','Maharashtra'],
        444004: ['Akola','Maharashtra'],
        444005: ['Akola','Maharashtra'],
        444006: ['Akola','Maharashtra'],
        444101: ['Akola','Maharashtra'],
        444102: ['Akola','Maharashtra'],
        444103: ['Akola','Maharashtra'],
        444104: ['Akola','Maharashtra'],
        444105: ['Washim','Maharashtra'],
        444106: ['Akola','Maharashtra'],
        444107: ['Akola','Maharashtra'],
        444108: ['Akola','Maharashtra'],
        444109: ['Akola','Maharashtra'],
        444111: ['Akola','Maharashtra'],
        444117: ['Akola','Maharashtra'],
        444126: ['Akola','Maharashtra'],
        444201: ['Buldhana','Maharashtra'],
        444202: ['Buldhana','Maharashtra'],
        444203: ['Buldhana','Maharashtra'],
        444204: ['Buldhana','Maharashtra'],
        444301: ['Buldhana','Maharashtra'],
        444302: ['Akola','Maharashtra'],
        444303: ['Buldhana','Maharashtra'],
        444304: ['Buldhana','Maharashtra'],
        444306: ['Buldhana','Maharashtra'],
        444311: ['Akola','Maharashtra'],
        444312: ['Buldhana','Maharashtra'],
        444401: ['Akola','Maharashtra'],
        444403: ['Washim','Maharashtra'],
        444404: ['Washim','Maharashtra'],
        444405: ['Akola','Maharashtra'],
        444407: ['Akola','Maharashtra'],
        444409: ['Washim','Maharashtra'],
        444501: ['Akola','Maharashtra'],
        444502: ['Akola','Maharashtra'],
        444503: ['Washim','Maharashtra'],
        444504: ['Washim','Maharashtra'],
        444505: ['Washim','Maharashtra'],
        444506: ['Washim','Maharashtra'],
        444507: ['Washim','Maharashtra'],
        444510: ['Washim','Maharashtra'],
        444511: ['Akola','Maharashtra'],
        445001: ['Yavatmal','Maharashtra'],
        445002: ['Yavatmal','Maharashtra'],
        445101: ['Yavatmal','Maharashtra'],
        445102: ['Yavatmal','Maharashtra'],
        445103: ['Yavatmal','Maharashtra'],
        445105: ['Yavatmal','Maharashtra'],
        445106: ['Yavatmal','Maharashtra'],
        445109: ['Yavatmal','Maharashtra'],
        445110: ['Yavatmal','Maharashtra'],
        445201: ['Yavatmal','Maharashtra'],
        445202: ['Yavatmal','Maharashtra'],
        445203: ['Yavatmal','Maharashtra'],
        445204: ['Yavatmal','Maharashtra'],
        445205: ['Yavatmal','Maharashtra'],
        445206: ['Yavatmal','Maharashtra'],
        445207: ['Yavatmal','Maharashtra'],
        445209: ['Yavatmal','Maharashtra'],
        445210: ['Yavatmal','Maharashtra'],
        445211: ['Yavatmal','Maharashtra'],
        445215: ['Yavatmal','Maharashtra'],
        445216: ['Yavatmal','Maharashtra'],
        445230: ['Yavatmal','Maharashtra'],
        445301: ['Yavatmal','Maharashtra'],
        445302: ['Yavatmal','Maharashtra'],
        445303: ['Yavatmal','Maharashtra'],
        445304: ['Yavatmal','Maharashtra'],
        445305: ['Yavatmal','Maharashtra'],
        445306: ['Yavatmal','Maharashtra'],
        445307: ['Yavatmal','Maharashtra'],
        445308: ['Yavatmal','Maharashtra'],
        445323: ['Yavatmal','Maharashtra'],
        445401: ['Yavatmal','Maharashtra'],
        445402: ['Yavatmal','Maharashtra'],
        450001: ['Khandwa','Madhya Pradesh'],
        450051: ['Khandwa','Madhya Pradesh'],
        450110: ['Khandwa','Madhya Pradesh'],
        450112: ['Khandwa','Madhya Pradesh'],
        450114: ['Khandwa','Madhya Pradesh'],
        450116: ['Khandwa','Madhya Pradesh'],
        450117: ['Khandwa','Madhya Pradesh'],
        450119: ['Khandwa','Madhya Pradesh'],
        450221: ['Burhanpur','Madhya Pradesh'],
        450331: ['Burhanpur','Madhya Pradesh'],
        450332: ['Burhanpur','Madhya Pradesh'],
        450337: ['Khandwa','Madhya Pradesh'],
        450445: ['Burhanpur','Madhya Pradesh'],
        450551: ['Khandwa','Madhya Pradesh'],
        450554: ['Khandwa','Madhya Pradesh'],
        450661: ['Khandwa','Madhya Pradesh'],
        450771: ['Khandwa','Madhya Pradesh'],
        450881: ['Khandwa','Madhya Pradesh'],
        450991: ['Khandwa','Madhya Pradesh'],
        451001: ['Khargone','Madhya Pradesh'],
        451111: ['Khargone','Madhya Pradesh'],
        451113: ['Khargone','Madhya Pradesh'],
        451115: ['Khargone','Madhya Pradesh'],
        451220: ['Khargone','Madhya Pradesh'],
        451221: ['Khargone','Madhya Pradesh'],
        451224: ['Khargone','Madhya Pradesh'],
        451225: ['Khargone','Madhya Pradesh'],
        451228: ['Khargone','Madhya Pradesh'],
        451331: ['Khargone','Madhya Pradesh'],
        451332: ['Khargone','Madhya Pradesh'],
        451335: ['Khargone','Madhya Pradesh'],
        451440: ['Barwani','Madhya Pradesh'],
        451441: ['Khargone','Madhya Pradesh'],
        451442: ['Khargone','Madhya Pradesh'],
        451447: ['Khargone','Madhya Pradesh'],
        451449: ['Barwani','Madhya Pradesh'],
        451551: ['Barwani','Madhya Pradesh'],
        451556: ['Barwani','Madhya Pradesh'],
        451660: ['Barwani','Madhya Pradesh'],
        451666: ['Barwani','Madhya Pradesh'],
        451770: ['Khargone','Madhya Pradesh'],
        451881: ['Barwani','Madhya Pradesh'],
        454001: ['Dhar','Madhya Pradesh'],
        454010: ['Dhar','Madhya Pradesh'],
        454111: ['Dhar','Madhya Pradesh'],
        454116: ['Dhar','Madhya Pradesh'],
        454221: ['Dhar','Madhya Pradesh'],
        454331: ['Dhar','Madhya Pradesh'],
        454335: ['Dhar','Madhya Pradesh'],
        454441: ['Dhar','Madhya Pradesh'],
        454446: ['Dhar','Madhya Pradesh'],
        454449: ['Dhar','Madhya Pradesh'],
        454552: ['Dhar','Madhya Pradesh'],
        454660: ['Dhar','Madhya Pradesh'],
        454665: ['Dhar','Madhya Pradesh'],
        454773: ['Dhar','Madhya Pradesh'],
        454774: ['Dhar','Madhya Pradesh'],
        454775: ['Dhar','Madhya Pradesh'],
        455001: ['Dewas','Madhya Pradesh'],
        455111: ['Dewas','Madhya Pradesh'],
        455116: ['Dewas','Madhya Pradesh'],
        455118: ['Dewas','Madhya Pradesh'],
        455221: ['Dewas','Madhya Pradesh'],
        455223: ['Dewas','Madhya Pradesh'],
        455227: ['Dewas','Madhya Pradesh'],
        455332: ['Dewas','Madhya Pradesh'],
        455336: ['Dewas','Madhya Pradesh'],
        455339: ['Dewas','Madhya Pradesh'],
        455440: ['Dewas','Madhya Pradesh'],
        455459: ['Dewas','Madhya Pradesh'],
        457001: ['Ratlam','Madhya Pradesh'],
        457114: ['Ratlam','Madhya Pradesh'],
        457118: ['Ratlam','Madhya Pradesh'],
        457119: ['Ratlam','Madhya Pradesh'],
        457222: ['Ratlam','Madhya Pradesh'],
        457226: ['Ratlam','Madhya Pradesh'],
        457331: ['Ratlam','Madhya Pradesh'],
        457333: ['Ratlam','Madhya Pradesh'],
        457336: ['Ratlam','Madhya Pradesh'],
        457339: ['Ratlam','Madhya Pradesh'],
        457340: ['Ratlam','Madhya Pradesh'],
        457441: ['Ratlam','Madhya Pradesh'],
        457550: ['Ratlam','Madhya Pradesh'],
        457555: ['Ratlam','Madhya Pradesh'],
        457661: ['Jhabua','Madhya Pradesh'],
        457770: ['Jhabua','Madhya Pradesh'],
        457772: ['Jhabua','Madhya Pradesh'],
        457773: ['Jhabua','Madhya Pradesh'],
        457775: ['Jhabua','Madhya Pradesh'],
        457777: ['Jhabua','Madhya Pradesh'],
        457779: ['Jhabua','Madhya Pradesh'],
        457882: ['Alirajpur','Madhya Pradesh'],
        457885: ['Alirajpur','Madhya Pradesh'],
        457887: ['Alirajpur','Madhya Pradesh'],
        457888: ['Alirajpur','Madhya Pradesh'],
        457990: ['Alirajpur','Madhya Pradesh'],
        457993: ['Jhabua','Madhya Pradesh'],
        458001: ['Mandsaur','Madhya Pradesh'],
        458002: ['Mandsaur','Madhya Pradesh'],
        458110: ['Neemuch','Madhya Pradesh'],
        458113: ['Neemuch','Madhya Pradesh'],
        458116: ['Neemuch','Madhya Pradesh'],
        458118: ['Neemuch','Madhya Pradesh'],
        458220: ['Neemuch','Madhya Pradesh'],
        458226: ['Neemuch','Madhya Pradesh'],
        458228: ['Neemuch','Madhya Pradesh'],
        458330: ['Neemuch','Madhya Pradesh'],
        458336: ['Neemuch','Madhya Pradesh'],
        458339: ['Mandsaur','Madhya Pradesh'],
        458389: ['Mandsaur','Madhya Pradesh'],
        458441: ['Neemuch','Madhya Pradesh'],
        458468: ['Neemuch','Madhya Pradesh'],
        458470: ['Neemuch','Madhya Pradesh'],
        458553: ['Mandsaur','Madhya Pradesh'],
        458556: ['Mandsaur','Madhya Pradesh'],
        458558: ['Mandsaur','Madhya Pradesh'],
        458664: ['Mandsaur','Madhya Pradesh'],
        458667: ['Mandsaur','Madhya Pradesh'],
        458669: ['Mandsaur','Madhya Pradesh'],
        458771: ['Mandsaur','Madhya Pradesh'],
        458775: ['Mandsaur','Madhya Pradesh'],
        458778: ['Mandsaur','Madhya Pradesh'],
        458880: ['Mandsaur','Madhya Pradesh'],
        458883: ['Mandsaur','Madhya Pradesh'],
        458888: ['Mandsaur','Madhya Pradesh'],
        458895: ['Mandsaur','Madhya Pradesh'],
        458990: ['Mandsaur','Madhya Pradesh'],
        460001: ['Betul','Madhya Pradesh'],
        460004: ['Betul','Madhya Pradesh'],
        460110: ['Betul','Madhya Pradesh'],
        460220: ['Betul','Madhya Pradesh'],
        460225: ['Betul','Madhya Pradesh'],
        460330: ['Betul','Madhya Pradesh'],
        460440: ['Betul','Madhya Pradesh'],
        460443: ['Betul','Madhya Pradesh'],
        460447: ['Betul','Madhya Pradesh'],
        460449: ['Betul','Madhya Pradesh'],
        460551: ['Betul','Madhya Pradesh'],
        460553: ['Betul','Madhya Pradesh'],
        460554: ['Betul','Madhya Pradesh'],
        460557: ['Betul','Madhya Pradesh'],
        460661: ['Betul','Madhya Pradesh'],
        460663: ['Betul','Madhya Pradesh'],
        460665: ['Betul','Madhya Pradesh'],
        460666: ['Betul','Madhya Pradesh'],
        460668: ['Betul','Madhya Pradesh'],
        461001: ['Hoshangabad','Madhya Pradesh'],
        461005: ['Hoshangabad','Madhya Pradesh'],
        461110: ['Hoshangabad','Madhya Pradesh'],
        461111: ['Hoshangabad','Madhya Pradesh'],
        461114: ['Narsinghpur','Madhya Pradesh'],
        461115: ['Hoshangabad','Madhya Pradesh'],
        461116: ['Hoshangabad','Madhya Pradesh'],
        461122: ['Hoshangabad','Madhya Pradesh'],
        461221: ['Hoshangabad','Madhya Pradesh'],
        461223: ['Hoshangabad','Madhya Pradesh'],
        461228: ['Harda','Madhya Pradesh'],
        461331: ['Harda','Madhya Pradesh'],
        461441: ['Harda','Madhya Pradesh'],
        461446: ['Hoshangabad','Madhya Pradesh'],
        461551: ['Hoshangabad','Madhya Pradesh'],
        461661: ['Hoshangabad','Madhya Pradesh'],
        461668: ['Hoshangabad','Madhya Pradesh'],
        461771: ['Harda','Madhya Pradesh'],
        461775: ['Hoshangabad','Madhya Pradesh'],
        461881: ['Hoshangabad','Madhya Pradesh'],
        461990: ['Hoshangabad','Madhya Pradesh'],
        464001: ['Vidisha','Madhya Pradesh'],
        464111: ['Vidisha','Madhya Pradesh'],
        464113: ['Vidisha','Madhya Pradesh'],
        464114: ['Vidisha','Madhya Pradesh'],
        464220: ['Vidisha','Madhya Pradesh'],
        464221: ['Vidisha','Madhya Pradesh'],
        464224: ['Vidisha','Madhya Pradesh'],
        464226: ['Vidisha','Madhya Pradesh'],
        464228: ['Vidisha','Madhya Pradesh'],
        464240: ['Raisen','Madhya Pradesh'],
        464258: ['Vidisha','Madhya Pradesh'],
        464331: ['Vidisha','Madhya Pradesh'],
        464337: ['Vidisha','Madhya Pradesh'],
        464551: ['Raisen','Madhya Pradesh'],
        464573: ['Raisen','Madhya Pradesh'],
        464651: ['Raisen','Madhya Pradesh'],
        464661: ['Raisen','Madhya Pradesh'],
        464665: ['Raisen','Madhya Pradesh'],
        464668: ['Raisen','Madhya Pradesh'],
        464671: ['Raisen','Madhya Pradesh'],
        464672: ['Raisen','Madhya Pradesh'],
        464770: ['Raisen','Madhya Pradesh'],
        464774: ['Raisen','Madhya Pradesh'],
        464776: ['Raisen','Madhya Pradesh'],
        464881: ['Raisen','Madhya Pradesh'],
        464884: ['Raisen','Madhya Pradesh'],
        464886: ['Raisen','Madhya Pradesh'],
        464986: ['Raisen','Madhya Pradesh'],
        464990: ['Raisen','Madhya Pradesh'],
        464993: ['Bhopal','Madhya Pradesh'],
        465001: ['Shajapur','Madhya Pradesh'],
        465106: ['Shajapur','Madhya Pradesh'],
        465110: ['Shajapur','Madhya Pradesh'],
        465113: ['Shajapur','Madhya Pradesh'],
        465116: ['Shajapur','Madhya Pradesh'],
        465118: ['Shajapur','Madhya Pradesh'],
        465220: ['Shajapur','Madhya Pradesh'],
        465223: ['Shajapur','Madhya Pradesh'],
        465226: ['Shajapur','Madhya Pradesh'],
        465227: ['Shajapur','Madhya Pradesh'],
        465230: ['Shajapur','Madhya Pradesh'],
        465333: ['Shajapur','Madhya Pradesh'],
        465335: ['Shajapur','Madhya Pradesh'],
        465337: ['Shajapur','Madhya Pradesh'],
        465339: ['Shajapur','Madhya Pradesh'],
        465441: ['Agar Malwa','Madhya Pradesh'],
        465445: ['Agar Malwa','Madhya Pradesh'],
        465447: ['Agar Malwa','Madhya Pradesh'],
        465449: ['Agar Malwa','Madhya Pradesh'],
        465550: ['Agar Malwa','Madhya Pradesh'],
        465661: ['Rajgarh','Madhya Pradesh'],
        465667: ['Rajgarh','Madhya Pradesh'],
        465669: ['Rajgarh','Madhya Pradesh'],
        465674: ['Rajgarh','Madhya Pradesh'],
        465677: ['Rajgarh','Madhya Pradesh'],
        465679: ['Rajgarh','Madhya Pradesh'],
        465680: ['Rajgarh','Madhya Pradesh'],
        465683: ['Rajgarh','Madhya Pradesh'],
        465685: ['Rajgarh','Madhya Pradesh'],
        465687: ['Rajgarh','Madhya Pradesh'],
        465689: ['Rajgarh','Madhya Pradesh'],
        465691: ['Rajgarh','Madhya Pradesh'],
        465693: ['Rajgarh','Madhya Pradesh'],
        465697: ['Rajgarh','Madhya Pradesh'],
        466001: ['Sehore','Madhya Pradesh'],
        466111: ['Sehore','Madhya Pradesh'],
        466113: ['Sehore','Madhya Pradesh'],
        466114: ['Sehore','Madhya Pradesh'],
        466115: ['Sehore','Madhya Pradesh'],
        466116: ['Sehore','Madhya Pradesh'],
        466118: ['Sehore','Madhya Pradesh'],
        466120: ['Sehore','Madhya Pradesh'],
        466125: ['Sehore','Madhya Pradesh'],
        466221: ['Sehore','Madhya Pradesh'],
        466331: ['Sehore','Madhya Pradesh'],
        466445: ['Sehore','Madhya Pradesh'],
        466446: ['Sehore','Madhya Pradesh'],
        466448: ['Sehore','Madhya Pradesh'],
        466554: ['Sehore','Madhya Pradesh'],
        466651: ['Sehore','Madhya Pradesh'],
        466661: ['Sehore','Madhya Pradesh'],
        466665: ['Sehore','Madhya Pradesh'],
        470001: ['Sagar','Madhya Pradesh'],
        470002: ['Sagar','Madhya Pradesh'],
        470003: ['Sagar','Madhya Pradesh'],
        470004: ['Sagar','Madhya Pradesh'],
        470021: ['Sagar','Madhya Pradesh'],
        470051: ['Sagar','Madhya Pradesh'],
        470113: ['Sagar','Madhya Pradesh'],
        470115: ['Sagar','Madhya Pradesh'],
        470117: ['Sagar','Madhya Pradesh'],
        470118: ['Sagar','Madhya Pradesh'],
        470119: ['Sagar','Madhya Pradesh'],
        470120: ['Sagar','Madhya Pradesh'],
        470124: ['Sagar','Madhya Pradesh'],
        470125: ['Sagar','Madhya Pradesh'],
        470221: ['Sagar','Madhya Pradesh'],
        470223: ['Sagar','Madhya Pradesh'],
        470226: ['Sagar','Madhya Pradesh'],
        470227: ['Sagar','Madhya Pradesh'],
        470228: ['Sagar','Madhya Pradesh'],
        470229: ['Sagar','Madhya Pradesh'],
        470232: ['Sagar','Madhya Pradesh'],
        470235: ['Sagar','Madhya Pradesh'],
        470335: ['Sagar','Madhya Pradesh'],
        470337: ['Sagar','Madhya Pradesh'],
        470339: ['Sagar','Madhya Pradesh'],
        470441: ['Sagar','Madhya Pradesh'],
        470442: ['Sagar','Madhya Pradesh'],
        470661: ['Damoh','Madhya Pradesh'],
        470663: ['Damoh','Madhya Pradesh'],
        470664: ['Damoh','Madhya Pradesh'],
        470666: ['Damoh','Madhya Pradesh'],
        470669: ['Sagar','Madhya Pradesh'],
        470672: ['Damoh','Madhya Pradesh'],
        470673: ['Damoh','Madhya Pradesh'],
        470675: ['Damoh','Madhya Pradesh'],
        470771: ['Damoh','Madhya Pradesh'],
        470772: ['Damoh','Madhya Pradesh'],
        470775: ['Damoh','Madhya Pradesh'],
        470880: ['Damoh','Madhya Pradesh'],
        470881: ['Damoh','Madhya Pradesh'],
        471001: ['Chhatarpur','Madhya Pradesh'],
        471101: ['Chhatarpur','Madhya Pradesh'],
        471105: ['Chhatarpur','Madhya Pradesh'],
        471111: ['Chhatarpur','Madhya Pradesh'],
        471201: ['Chhatarpur','Madhya Pradesh'],
        471301: ['Chhatarpur','Madhya Pradesh'],
        471311: ['Chhatarpur','Madhya Pradesh'],
        471313: ['Chhatarpur','Madhya Pradesh'],
        471315: ['Chhatarpur','Madhya Pradesh'],
        471318: ['Chhatarpur','Madhya Pradesh'],
        471405: ['Chhatarpur','Madhya Pradesh'],
        471408: ['Chhatarpur','Madhya Pradesh'],
        471411: ['Panna','Madhya Pradesh'],
        471501: ['Chhatarpur','Madhya Pradesh'],
        471510: ['Chhatarpur','Madhya Pradesh'],
        471515: ['Chhatarpur','Madhya Pradesh'],
        471516: ['Chhatarpur','Madhya Pradesh'],
        471525: ['Chhatarpur','Madhya Pradesh'],
        471606: ['Chhatarpur','Madhya Pradesh'],
        471625: ['Chhatarpur','Madhya Pradesh'],
        472001: ['Tikamgarh','Madhya Pradesh'],
        472005: ['Tikamgarh','Madhya Pradesh'],
        472010: ['Tikamgarh','Madhya Pradesh'],
        472101: ['Tikamgarh','Madhya Pradesh'],
        472111: ['Tikamgarh','Madhya Pradesh'],
        472115: ['Tikamgarh','Madhya Pradesh'],
        472118: ['Tikamgarh','Madhya Pradesh'],
        472221: ['Tikamgarh','Madhya Pradesh'],
        472246: ['Tikamgarh','Madhya Pradesh'],
        472331: ['Tikamgarh','Madhya Pradesh'],
        472336: ['Tikamgarh','Madhya Pradesh'],
        472337: ['Tikamgarh','Madhya Pradesh'],
        472339: ['Tikamgarh','Madhya Pradesh'],
        472442: ['Tikamgarh','Madhya Pradesh'],
        472445: ['Tikamgarh','Madhya Pradesh'],
        472446: ['Tikamgarh','Madhya Pradesh'],
        472447: ['Tikamgarh','Madhya Pradesh'],
        473001: ['Guna','Madhya Pradesh'],
        473101: ['Ashok Nagar','Madhya Pradesh'],
        473105: ['Guna','Madhya Pradesh'],
        473110: ['Guna','Madhya Pradesh'],
        473111: ['Guna','Madhya Pradesh'],
        473112: ['Guna','Madhya Pradesh'],
        473113: ['Guna','Madhya Pradesh'],
        473115: ['Guna','Madhya Pradesh'],
        473118: ['Guna','Madhya Pradesh'],
        473222: ['Guna','Madhya Pradesh'],
        473226: ['Guna','Madhya Pradesh'],
        473249: ['Guna','Madhya Pradesh'],
        473287: ['Guna','Madhya Pradesh'],
        473330: ['Ashok Nagar','Madhya Pradesh'],
        473331: ['Ashok Nagar','Madhya Pradesh'],
        473332: ['Ashok Nagar','Madhya Pradesh'],
        473335: ['Ashok Nagar','Madhya Pradesh'],
        473440: ['Ashok Nagar','Madhya Pradesh'],
        473443: ['Ashok Nagar','Madhya Pradesh'],
        473444: ['Ashok Nagar','Madhya Pradesh'],
        473446: ['Ashok Nagar','Madhya Pradesh'],
        473551: ['Shivpuri','Madhya Pradesh'],
        473585: ['Shivpuri','Madhya Pradesh'],
        473638: ['Shivpuri','Madhya Pradesh'],
        473660: ['Shivpuri','Madhya Pradesh'],
        473662: ['Shivpuri','Madhya Pradesh'],
        473665: ['Shivpuri','Madhya Pradesh'],
        473670: ['Shivpuri','Madhya Pradesh'],
        473770: ['Shivpuri','Madhya Pradesh'],
        473774: ['Shivpuri','Madhya Pradesh'],
        473775: ['Shivpuri','Madhya Pradesh'],
        473781: ['Shivpuri','Madhya Pradesh'],
        473793: ['Shivpuri','Madhya Pradesh'],
        473865: ['Shivpuri','Madhya Pradesh'],
        473880: ['Shivpuri','Madhya Pradesh'],
        473885: ['Shivpuri','Madhya Pradesh'],
        473990: ['Shivpuri','Madhya Pradesh'],
        473995: ['Shivpuri','Madhya Pradesh'],
        474001: ['Gwalior','Madhya Pradesh'],
        474002: ['Gwalior','Madhya Pradesh'],
        474003: ['Gwalior','Madhya Pradesh'],
        474004: ['Gwalior','Madhya Pradesh'],
        474005: ['Gwalior','Madhya Pradesh'],
        474006: ['Gwalior','Madhya Pradesh'],
        474007: ['Gwalior','Madhya Pradesh'],
        474008: ['Gwalior','Madhya Pradesh'],
        474009: ['Gwalior','Madhya Pradesh'],
        474010: ['Gwalior','Madhya Pradesh'],
        474011: ['Gwalior','Madhya Pradesh'],
        474012: ['Gwalior','Madhya Pradesh'],
        474015: ['Gwalior','Madhya Pradesh'],
        474020: ['Gwalior','Madhya Pradesh'],
        475001: ['Gwalior','Madhya Pradesh'],
        475002: ['Gwalior','Madhya Pradesh'],
        475005: ['Gwalior','Madhya Pradesh'],
        475110: ['Gwalior','Madhya Pradesh'],
        475115: ['Gwalior','Madhya Pradesh'],
        475220: ['Gwalior','Madhya Pradesh'],
        475330: ['Gwalior','Madhya Pradesh'],
        475335: ['Datia','Madhya Pradesh'],
        475336: ['Gwalior','Madhya Pradesh'],
        475661: ['Datia','Madhya Pradesh'],
        475671: ['Gwalior','Madhya Pradesh'],
        475673: ['Gwalior','Madhya Pradesh'],
        475675: ['Datia','Madhya Pradesh'],
        475682: ['Datia','Madhya Pradesh'],
        475685: ['Gwalior','Madhya Pradesh'],
        475686: ['Datia','Madhya Pradesh'],
        476001: ['Morena','Madhya Pradesh'],
        476111: ['Morena','Madhya Pradesh'],
        476115: ['Morena','Madhya Pradesh'],
        476134: ['Morena','Madhya Pradesh'],
        476219: ['Morena','Madhya Pradesh'],
        476221: ['Morena','Madhya Pradesh'],
        476224: ['Morena','Madhya Pradesh'],
        476228: ['Morena','Madhya Pradesh'],
        476229: ['Morena','Madhya Pradesh'],
        476332: ['Sheopur','Madhya Pradesh'],
        476335: ['Sheopur','Madhya Pradesh'],
        476337: ['Morena','Madhya Pradesh'],
        476339: ['Morena','Madhya Pradesh'],
        476355: ['Sheopur','Madhya Pradesh'],
        476444: ['Morena','Madhya Pradesh'],
        476554: ['Morena','Madhya Pradesh'],
        477001: ['Bhind','Madhya Pradesh'],
        477105: ['Bhind','Madhya Pradesh'],
        477111: ['Bhind','Madhya Pradesh'],
        477116: ['Bhind','Madhya Pradesh'],
        477117: ['Bhind','Madhya Pradesh'],
        477222: ['Bhind','Madhya Pradesh'],
        477227: ['Bhind','Madhya Pradesh'],
        477331: ['Bhind','Madhya Pradesh'],
        477332: ['Bhind','Madhya Pradesh'],
        477333: ['Bhind','Madhya Pradesh'],
        477335: ['Bhind','Madhya Pradesh'],
        477441: ['Bhind','Madhya Pradesh'],
        477445: ['Bhind','Madhya Pradesh'],
        477446: ['Bhind','Madhya Pradesh'],
        477447: ['Bhind','Madhya Pradesh'],
        477449: ['Bhind','Madhya Pradesh'],
        477555: ['Bhind','Madhya Pradesh'],
        477557: ['Bhind','Madhya Pradesh'],
        477566: ['Bhind','Madhya Pradesh'],
        477660: ['Bhind','Madhya Pradesh'],
        480001: ['Chhindwara','Madhya Pradesh'],
        480003: ['Chhindwara','Madhya Pradesh'],
        480105: ['Chhindwara','Madhya Pradesh'],
        480106: ['Chhindwara','Madhya Pradesh'],
        480107: ['Chhindwara','Madhya Pradesh'],
        480108: ['Chhindwara','Madhya Pradesh'],
        480109: ['Chhindwara','Madhya Pradesh'],
        480110: ['Chhindwara','Madhya Pradesh'],
        480111: ['Chhindwara','Madhya Pradesh'],
        480112: ['Chhindwara','Madhya Pradesh'],
        480115: ['Chhindwara','Madhya Pradesh'],
        480221: ['Chhindwara','Madhya Pradesh'],
        480223: ['Chhindwara','Madhya Pradesh'],
        480224: ['Chhindwara','Madhya Pradesh'],
        480331: ['Chhindwara','Madhya Pradesh'],
        480334: ['Chhindwara','Madhya Pradesh'],
        480337: ['Chhindwara','Madhya Pradesh'],
        480338: ['Chhindwara','Madhya Pradesh'],
        480441: ['Chhindwara','Madhya Pradesh'],
        480447: ['Chhindwara','Madhya Pradesh'],
        480449: ['Chhindwara','Madhya Pradesh'],
        480551: ['Chhindwara','Madhya Pradesh'],
        480555: ['Chhindwara','Madhya Pradesh'],
        480557: ['Chhindwara','Madhya Pradesh'],
        480559: ['Chhindwara','Madhya Pradesh'],
        480661: ['Seoni','Madhya Pradesh'],
        480667: ['Seoni','Madhya Pradesh'],
        480771: ['Seoni','Madhya Pradesh'],
        480880: ['Seoni','Madhya Pradesh'],
        480881: ['Seoni','Madhya Pradesh'],
        480882: ['Seoni','Madhya Pradesh'],
        480884: ['Seoni','Madhya Pradesh'],
        480886: ['Seoni','Madhya Pradesh'],
        480887: ['Seoni','Madhya Pradesh'],
        480888: ['Seoni','Madhya Pradesh'],
        480990: ['Seoni','Madhya Pradesh'],
        480991: ['Seoni','Madhya Pradesh'],
        480994: ['Seoni','Madhya Pradesh'],
        480996: ['Seoni','Madhya Pradesh'],
        480997: ['Seoni','Madhya Pradesh'],
        480999: ['Seoni','Madhya Pradesh'],
        481001: ['Balaghat','Madhya Pradesh'],
        481051: ['Balaghat','Madhya Pradesh'],
        481102: ['Balaghat','Madhya Pradesh'],
        481105: ['Balaghat','Madhya Pradesh'],
        481111: ['Balaghat','Madhya Pradesh'],
        481115: ['Balaghat','Madhya Pradesh'],
        481116: ['Balaghat','Madhya Pradesh'],
        481117: ['Balaghat','Madhya Pradesh'],
        481222: ['Balaghat','Madhya Pradesh'],
        481224: ['Balaghat','Madhya Pradesh'],
        481226: ['Balaghat','Madhya Pradesh'],
        481331: ['Balaghat','Madhya Pradesh'],
        481332: ['Balaghat','Madhya Pradesh'],
        481335: ['Balaghat','Madhya Pradesh'],
        481337: ['Balaghat','Madhya Pradesh'],
        481441: ['Balaghat','Madhya Pradesh'],
        481445: ['Balaghat','Madhya Pradesh'],
        481449: ['Seoni','Madhya Pradesh'],
        481551: ['Balaghat','Madhya Pradesh'],
        481556: ['Balaghat','Madhya Pradesh'],
        481661: ['Mandla','Madhya Pradesh'],
        481662: ['Mandla','Madhya Pradesh'],
        481663: ['Mandla','Madhya Pradesh'],
        481664: ['Mandla','Madhya Pradesh'],
        481665: ['Mandla','Madhya Pradesh'],
        481666: ['Mandla','Madhya Pradesh'],
        481668: ['Mandla','Madhya Pradesh'],
        481672: ['Mandla','Madhya Pradesh'],
        481768: ['Mandla','Madhya Pradesh'],
        481771: ['Mandla','Madhya Pradesh'],
        481776: ['Mandla','Madhya Pradesh'],
        481778: ['Dindori','Madhya Pradesh'],
        481879: ['Dindori','Madhya Pradesh'],
        481880: ['Dindori','Madhya Pradesh'],
        481882: ['Dindori','Madhya Pradesh'],
        481884: ['Mandla','Madhya Pradesh'],
        481885: ['Mandla','Madhya Pradesh'],
        481990: ['Dindori','Madhya Pradesh'],
        481995: ['Mandla','Madhya Pradesh'],
        481996: ['Mandla','Madhya Pradesh'],
        481998: ['Mandla','Madhya Pradesh'],
        482002: ['Jabalpur','Madhya Pradesh'],
        482003: ['Jabalpur','Madhya Pradesh'],
        482004: ['Jabalpur','Madhya Pradesh'],
        482005: ['Jabalpur','Madhya Pradesh'],
        482008: ['Jabalpur','Madhya Pradesh'],
        482009: ['Jabalpur','Madhya Pradesh'],
        482010: ['Jabalpur','Madhya Pradesh'],
        482011: ['Jabalpur','Madhya Pradesh'],
        482020: ['Jabalpur','Madhya Pradesh'],
        482021: ['Jabalpur','Madhya Pradesh'],
        482051: ['Jabalpur','Madhya Pradesh'],
        482056: ['Jabalpur','Madhya Pradesh'],
        483001: ['Jabalpur','Madhya Pradesh'],
        483053: ['Jabalpur','Madhya Pradesh'],
        483105: ['Jabalpur','Madhya Pradesh'],
        483110: ['Jabalpur','Madhya Pradesh'],
        483113: ['Jabalpur','Madhya Pradesh'],
        483119: ['Jabalpur','Madhya Pradesh'],
        483220: ['Jabalpur','Madhya Pradesh'],
        483222: ['Jabalpur','Madhya Pradesh'],
        483330: ['Katni','Madhya Pradesh'],
        483331: ['Katni','Madhya Pradesh'],
        483332: ['Katni','Madhya Pradesh'],
        483334: ['Katni','Madhya Pradesh'],
        483336: ['Katni','Madhya Pradesh'],
        483440: ['Katni','Madhya Pradesh'],
        483442: ['Katni','Madhya Pradesh'],
        483501: ['Katni','Madhya Pradesh'],
        483504: ['Katni','Madhya Pradesh'],
        483770: ['Katni','Madhya Pradesh'],
        483773: ['Katni','Madhya Pradesh'],
        483775: ['Katni','Madhya Pradesh'],
        483880: ['Katni','Madhya Pradesh'],
        483990: ['Katni','Madhya Pradesh'],
        484001: ['Shahdol','Madhya Pradesh'],
        484110: ['Shahdol','Madhya Pradesh'],
        484113: ['Anuppur','Madhya Pradesh'],
        484114: ['Shahdol','Madhya Pradesh'],
        484116: ['Anuppur','Madhya Pradesh'],
        484117: ['Shahdol','Madhya Pradesh'],
        484120: ['Anuppur','Madhya Pradesh'],
        484220: ['Anuppur','Madhya Pradesh'],
        484224: ['Anuppur','Madhya Pradesh'],
        484330: ['Anuppur','Madhya Pradesh'],
        484334: ['Shahdol','Madhya Pradesh'],
        484336: ['Anuppur','Madhya Pradesh'],
        484440: ['Anuppur','Madhya Pradesh'],
        484444: ['Shahdol','Madhya Pradesh'],
        484446: ['Anuppur','Madhya Pradesh'],
        484551: ['Umaria','Madhya Pradesh'],
        484555: ['Umaria','Madhya Pradesh'],
        484660: ['Umaria','Madhya Pradesh'],
        484661: ['Umaria','Madhya Pradesh'],
        484664: ['Umaria','Madhya Pradesh'],
        484665: ['Umaria','Madhya Pradesh'],
        484669: ['Shahdol','Madhya Pradesh'],
        484770: ['Shahdol','Madhya Pradesh'],
        484771: ['Shahdol','Madhya Pradesh'],
        484774: ['Shahdol','Madhya Pradesh'],
        484776: ['Shahdol','Madhya Pradesh'],
        484881: ['Anuppur','Madhya Pradesh'],
        484886: ['Anuppur','Madhya Pradesh'],
        484887: ['Anuppur','Madhya Pradesh'],
        485001: ['Satna','Madhya Pradesh'],
        485005: ['Satna','Madhya Pradesh'],
        485111: ['Satna','Madhya Pradesh'],
        485112: ['Satna','Madhya Pradesh'],
        485113: ['Satna','Madhya Pradesh'],
        485114: ['Satna','Madhya Pradesh'],
        485115: ['Satna','Madhya Pradesh'],
        485221: ['Satna','Madhya Pradesh'],
        485226: ['Satna','Madhya Pradesh'],
        485331: ['Satna','Madhya Pradesh'],
        485334: ['Satna','Madhya Pradesh'],
        485441: ['Satna','Madhya Pradesh'],
        485446: ['Satna','Madhya Pradesh'],
        485447: ['Satna','Madhya Pradesh'],
        485551: ['Satna','Madhya Pradesh'],
        485661: ['Satna','Madhya Pradesh'],
        485666: ['Satna','Madhya Pradesh'],
        485771: ['Satna','Madhya Pradesh'],
        485772: ['Satna','Madhya Pradesh'],
        485773: ['Satna','Madhya Pradesh'],
        485774: ['Satna','Madhya Pradesh'],
        485775: ['Satna','Madhya Pradesh'],
        485778: ['Satna','Madhya Pradesh'],
        485881: ['Satna','Madhya Pradesh'],
        486001: ['Rewa','Madhya Pradesh'],
        486002: ['Rewa','Madhya Pradesh'],
        486003: ['Rewa','Madhya Pradesh'],
        486005: ['Rewa','Madhya Pradesh'],
        486006: ['Rewa','Madhya Pradesh'],
        486111: ['Rewa','Madhya Pradesh'],
        486114: ['Rewa','Madhya Pradesh'],
        486115: ['Rewa','Madhya Pradesh'],
        486117: ['Rewa','Madhya Pradesh'],
        486123: ['Rewa','Madhya Pradesh'],
        486220: ['Rewa','Madhya Pradesh'],
        486223: ['Rewa','Madhya Pradesh'],
        486226: ['Rewa','Madhya Pradesh'],
        486331: ['Rewa','Madhya Pradesh'],
        486333: ['Rewa','Madhya Pradesh'],
        486335: ['Rewa','Madhya Pradesh'],
        486338: ['Rewa','Madhya Pradesh'],
        486340: ['Rewa','Madhya Pradesh'],
        486341: ['Rewa','Madhya Pradesh'],
        486440: ['Rewa','Madhya Pradesh'],
        486441: ['Rewa','Madhya Pradesh'],
        486445: ['Rewa','Madhya Pradesh'],
        486446: ['Rewa','Madhya Pradesh'],
        486447: ['Rewa','Madhya Pradesh'],
        486448: ['Rewa','Madhya Pradesh'],
        486450: ['Rewa','Madhya Pradesh'],
        486550: ['Rewa','Madhya Pradesh'],
        486553: ['Rewa','Madhya Pradesh'],
        486556: ['Rewa','Madhya Pradesh'],
        486661: ['Sidhi','Madhya Pradesh'],
        486666: ['Sidhi','Madhya Pradesh'],
        486669: ['Sidhi','Madhya Pradesh'],
        486670: ['Sidhi','Madhya Pradesh'],
        486675: ['Sidhi','Madhya Pradesh'],
        486771: ['Sidhi','Madhya Pradesh'],
        486775: ['Sidhi','Madhya Pradesh'],
        486776: ['Sidhi','Madhya Pradesh'],
        486881: ['Sidhi','Madhya Pradesh'],
        486882: ['Sidhi','Madhya Pradesh'],
        486884: ['Singrauli','Madhya Pradesh'],
        486885: ['Singrauli','Madhya Pradesh'],
        486886: ['Singrauli','Madhya Pradesh'],
        486887: ['Singrauli','Madhya Pradesh'],
        486888: ['Singrauli','Madhya Pradesh'],
        486889: ['Singrauli','Madhya Pradesh'],
        486890: ['Singrauli','Madhya Pradesh'],
        486892: ['Sidhi','Madhya Pradesh'],
        487001: ['Narsinghpur','Madhya Pradesh'],
        487110: ['Narsinghpur','Madhya Pradesh'],
        487114: ['Narsinghpur','Madhya Pradesh'],
        487118: ['Narsinghpur','Madhya Pradesh'],
        487221: ['Narsinghpur','Madhya Pradesh'],
        487225: ['Narsinghpur','Madhya Pradesh'],
        487330: ['Narsinghpur','Madhya Pradesh'],
        487334: ['Narsinghpur','Madhya Pradesh'],
        487337: ['Narsinghpur','Madhya Pradesh'],
        487441: ['Narsinghpur','Madhya Pradesh'],
        487551: ['Narsinghpur','Madhya Pradesh'],
        487555: ['Narsinghpur','Madhya Pradesh'],
        487661: ['Narsinghpur','Madhya Pradesh'],
        487770: ['Narsinghpur','Madhya Pradesh'],
        487881: ['Narsinghpur','Madhya Pradesh'],
        488001: ['Chhatarpur','Madhya Pradesh'],
        488050: ['Panna','Madhya Pradesh'],
        488051: ['Panna','Madhya Pradesh'],
        488059: ['Panna','Madhya Pradesh'],
        488220: ['Panna','Madhya Pradesh'],
        488222: ['Panna','Madhya Pradesh'],
        488333: ['Panna','Madhya Pradesh'],
        488441: ['Panna','Madhya Pradesh'],
        488442: ['Panna','Madhya Pradesh'],
        488443: ['Panna','Madhya Pradesh'],
        488446: ['Panna','Madhya Pradesh'],
        488448: ['Panna','Madhya Pradesh'],
        490001: ['Durg','Chattisgarh'],
        490006: ['Durg','Chattisgarh'],
        490009: ['Durg','Chattisgarh'],
        490020: ['Durg','Chattisgarh'],
        490021: ['Durg','Chattisgarh'],
        490024: ['Durg','Chattisgarh'],
        490025: ['Durg','Chattisgarh'],
        490026: ['Durg','Chattisgarh'],
        491001: ['Durg','Chattisgarh'],
        491107: ['Durg','Chattisgarh'],
        491111: ['Durg','Chattisgarh'],
        491221: ['Durg','Chattisgarh'],
        491222: ['Balod','Chattisgarh'],
        491223: ['Balod','Chattisgarh'],
        491225: ['Balod','Chattisgarh'],
        491226: ['Balod','Chattisgarh'],
        491227: ['Balod','Chattisgarh'],
        491228: ['Balod','Chattisgarh'],
        491229: ['Rajnandgaon','Chattisgarh'],
        491230: ['Balod','Chattisgarh'],
        491331: ['Durg','Chattisgarh'],
        491332: ['Bemetara','Chattisgarh'],
        491335: ['Bemetara','Chattisgarh'],
        491336: ['Kawardha','Chattisgarh'],
        491337: ['Bemetara','Chattisgarh'],
        491338: ['Bemetara','Chattisgarh'],
        491340: ['Bemetara','Chattisgarh'],
        491441: ['Rajnandgaon','Chattisgarh'],
        491444: ['Rajnandgaon','Chattisgarh'],
        491445: ['Rajnandgaon','Chattisgarh'],
        491557: ['Rajnandgaon','Chattisgarh'],
        491558: ['Rajnandgaon','Chattisgarh'],
        491559: ['Kawardha','Chattisgarh'],
        491661: ['Rajnandgaon','Chattisgarh'],
        491665: ['Rajnandgaon','Chattisgarh'],
        491666: ['Rajnandgaon','Chattisgarh'],
        491668: ['Rajnandgaon','Chattisgarh'],
        491771: ['Balod','Chattisgarh'],
        491881: ['Rajnandgaon','Chattisgarh'],
        491885: ['Rajnandgaon','Chattisgarh'],
        491888: ['Rajnandgaon','Chattisgarh'],
        491993: ['Bemetara','Chattisgarh'],
        491995: ['Kawardha','Chattisgarh'],
        491996: ['Kawardha','Chattisgarh'],
        492016: ['Raipur','Chattisgarh'],
        492017: ['Raipur','Chattisgarh'],
        492018: ['Raipur','Chattisgarh'],
        493448: ['Mahasamund','Chattisgarh'],
        493554: ['Mahasamund','Chattisgarh'],
        493662: ['Dhamtari','Chattisgarh'],
        493770: ['Dhamtari','Chattisgarh'],
        493776: ['Dhamtari','Chattisgarh'],
        493887: ['Gariaband','Chattisgarh'],
        493891: ['Gariaband','Chattisgarh'],
        494001: ['Jagdalpur','Chattisgarh'],
        494010: ['Bastar','Chattisgarh'],
        494111: ['Dantewada','Chattisgarh'],
        494114: ['Dantewada','Chattisgarh'],
        494115: ['Dantewada','Chattisgarh'],
        494122: ['Dantewada','Chattisgarh'],
        494221: ['Bastar','Chattisgarh'],
        494222: ['Bastar','Chattisgarh'],
        494223: ['Bastar','Chattisgarh'],
        494224: ['Bastar','Chattisgarh'],
        494226: ['Bastar','Chattisgarh'],
        494228: ['Bastar','Chattisgarh'],
        494229: ['Bastar','Chattisgarh'],
        494230: ['Bastar','Chattisgarh'],
        494237: ['Kanker','Chattisgarh'],
        494331: ['Bastar','Chattisgarh'],
        494333: ['Kanker','Chattisgarh'],
        494334: ['Kanker','Chattisgarh'],
        494335: ['Kanker','Chattisgarh'],
        494336: ['Kanker','Chattisgarh'],
        494337: ['Kanker','Chattisgarh'],
        494347: ['Bastar','Chattisgarh'],
        494441: ['Dantewada','Chattisgarh'],
        494442: ['Bastar','Chattisgarh'],
        494444: ['Bijapur(Cgh)','Chattisgarh'],
        494446: ['Bijapur(Cgh)','Chattisgarh'],
        494447: ['Bijapur(Cgh)','Chattisgarh'],
        494448: ['Bijapur(Cgh)','Chattisgarh'],
        494449: ['Dantewada','Chattisgarh'],
        494450: ['Bilaspur','Chattisgarh'],
        494551: ['Dantewada','Chattisgarh'],
        494552: ['Dantewada','Chattisgarh'],
        494553: ['Dantewada','Chattisgarh'],
        494556: ['Dantewada','Chattisgarh'],
        494635: ['Kanker','Chattisgarh'],
        494661: ['Narayanpur','Chattisgarh'],
        494665: ['Kanker','Chattisgarh'],
        494669: ['Kanker','Chattisgarh'],
        494670: ['Kanker','Chattisgarh'],
        494771: ['Kanker','Chattisgarh'],
        494776: ['Kanker','Chattisgarh'],
        494777: ['Kanker','Chattisgarh'],
        495001: ['Bilaspurcgh','Chattisgarh'],
        495003: ['Bilaspurcgh','Chattisgarh'],
        495004: ['Bilaspurcgh','Chattisgarh'],
        495006: ['Bilaspurcgh','Chattisgarh'],
        495009: ['Bilaspurcgh','Chattisgarh'],
        495112: ['Bilaspurcgh','Chattisgarh'],
        495113: ['Bilaspurcgh','Chattisgarh'],
        495115: ['Mungeli','Chattisgarh'],
        495116: ['Bilaspurcgh','Chattisgarh'],
        495117: ['Bilaspurcgh','Chattisgarh'],
        495118: ['Bilaspurcgh','Chattisgarh'],
        495119: ['Bilaspurcgh','Chattisgarh'],
        495220: ['Bilaspurcgh','Chattisgarh'],
        495222: ['Bilaspurcgh','Chattisgarh'],
        495224: ['Bilaspurcgh','Chattisgarh'],
        495330: ['Bilaspurcgh','Chattisgarh'],
        495334: ['Mungeli','Chattisgarh'],
        495335: ['Mungeli','Chattisgarh'],
        495442: ['Bilaspurcgh','Chattisgarh'],
        495444: ['Korba','Chattisgarh'],
        495445: ['Korba','Chattisgarh'],
        495446: ['Korba','Chattisgarh'],
        495447: ['Korba','Chattisgarh'],
        495448: ['Korba','Chattisgarh'],
        495449: ['Korba','Chattisgarh'],
        495450: ['Korba','Chattisgarh'],
        495452: ['Korba','Chattisgarh'],
        495454: ['Korba','Chattisgarh'],
        495455: ['Korba','Chattisgarh'],
        495550: ['Bilaspurcgh','Chattisgarh'],
        495551: ['Bilaspurcgh','Chattisgarh'],
        495552: ['Janjgirchampa','Chattisgarh'],
        495553: ['Janjgirchampa','Chattisgarh'],
        495554: ['Janjgirchampa','Chattisgarh'],
        495555: ['Bilaspurcgh','Chattisgarh'],
        495556: ['Janjgirchampa','Chattisgarh'],
        495557: ['Janjgirchampa','Chattisgarh'],
        495559: ['Janjgirchampa','Chattisgarh'],
        495660: ['Janjgirchampa','Chattisgarh'],
        495661: ['Janjgirchampa','Chattisgarh'],
        495663: ['Janjgirchampa','Chattisgarh'],
        495668: ['Janjgirchampa','Chattisgarh'],
        495669: ['Janjgir-Champa','Chattisgarh'],
        495671: ['Janjgirchampa','Chattisgarh'],
        495674: ['Korba','Chattisgarh'],
        495677: ['Korba','Chattisgarh'],
        495682: ['Korba','Chattisgarh'],
        495683: ['Korba','Chattisgarh'],
        495684: ['Korba','Chattisgarh'],
        495686: ['Janjgirchampa','Chattisgarh'],
        495687: ['Janjgirchampa','Chattisgarh'],
        495688: ['Janjgirchampa','Chattisgarh'],
        495689: ['Janjgirchampa','Chattisgarh'],
        495690: ['Janjgirchampa','Chattisgarh'],
        495691: ['Janjgirchampa','Chattisgarh'],
        495692: ['Janjgirchampa','Chattisgarh'],
        495695: ['Janjgirchampa','Chattisgarh'],
        496001: ['Raigarh','Chattisgarh'],
        496005: ['Raigarh','Chattisgarh'],
        496100: ['Raigarh','Chattisgarh'],
        496107: ['Raigarh','Chattisgarh'],
        496108: ['Raigarh','Chattisgarh'],
        496109: ['Raigarh','Chattisgarh'],
        496111: ['Raigarh','Chattisgarh'],
        496113: ['Raigarh','Chattisgarh'],
        496115: ['Raigarh','Chattisgarh'],
        496116: ['Raigarh','Chattisgarh'],
        496118: ['Jaspur','Chattisgarh'],
        496220: ['Jaspur','Chattisgarh'],
        496223: ['Jaspur','Chattisgarh'],
        496224: ['Jaspur','Chattisgarh'],
        496225: ['Jaspur','Chattisgarh'],
        496227: ['Jaspur','Chattisgarh'],
        496242: ['Jaspur','Chattisgarh'],
        496245: ['Jaspur','Chattisgarh'],
        496330: ['Jaspur','Chattisgarh'],
        496331: ['Jaspur','Chattisgarh'],
        496334: ['Jaspur','Chattisgarh'],
        496336: ['Jaspur','Chattisgarh'],
        496338: ['Jaspur','Chattisgarh'],
        496440: ['Raigarh','Chattisgarh'],
        496445: ['Raigarh','Chattisgarh'],
        496450: ['Raigarh','Chattisgarh'],
        496551: ['Raigarh','Chattisgarh'],
        496554: ['Raigarh','Chattisgarh'],
        496661: ['Raigarh','Chattisgarh'],
        496665: ['Raigarh','Chattisgarh'],
        497001: ['Surguja','Chattisgarh'],
        497101: ['Surguja','Chattisgarh'],
        497111: ['Surguja','Chattisgarh'],
        497114: ['Surguja','Chattisgarh'],
        497116: ['Surguja','Chattisgarh'],
        497117: ['Surguja','Chattisgarh'],
        497118: ['Balrampur','Chattisgarh'],
        497119: ['Balrampur','Chattisgarh'],
        497220: ['Balrampur','Chattisgarh'],
        497223: ['Balrampur','Chattisgarh'],
        497224: ['Balrampur','Chattisgarh'],
        497225: ['Balrampur','Chattisgarh'],
        497226: ['Surajpur','Chattisgarh'],
        497229: ['Surajpur','Chattisgarh'],
        497231: ['Surajpur','Chattisgarh'],
        497235: ['Surajpur','Chattisgarh'],
        497331: ['Koriya','Chattisgarh'],
        497333: ['Koriya','Chattisgarh'],
        497335: ['Koriya','Chattisgarh'],
        497339: ['Koriya','Chattisgarh'],
        497442: ['Koriya','Chattisgarh'],
        497446: ['Koriya','Chattisgarh'],
        497447: ['Koriya','Chattisgarh'],
        497448: ['Koriya','Chattisgarh'],
        497449: ['Koriya','Chattisgarh'],
        497450: ['Koriya','Chattisgarh'],
        497451: ['Koriya','Chattisgarh'],
        497553: ['Koriya','Chattisgarh'],
        497555: ['Koriya','Chattisgarh'],
        497557: ['Koriya','Chattisgarh'],
        497559: ['Koriya','Chattisgarh'],
        497773: ['Koriya','Chattisgarh'],
        497778: ['Koriya','Chattisgarh'],
        500019: ['Rangareddy','Telangana'],
        500030: ['Rangareddy','Telangana'],
        500032: ['Rangareddy','Telangana'],
        500035: ['Rangareddy','Telangana'],
        500052: ['Rangareddy','Telangana'],
        500060: ['Rangareddy','Telangana'],
        500070: ['Rangareddy','Telangana'],
        500075: ['Rangareddy','Telangana'],
        500079: ['Rangareddy','Telangana'],
        500084: ['Rangareddy','Telangana'],
        500090: ['Rangareddy','Telangana'],
        500102: ['Rangareddy','Telangana'],
        500109: ['Hyderabad','Telangana'],
        500112: ['Ranga Reddy','Telangana'],
        500114: ['Rangareddy','Telangana'],
        500115: ['Ranga Reddy','Telangana'],
        501106: ['Rangareddy','Telangana'],
        501111: ['Rangareddy','Telangana'],
        501121: ['Rangareddy','Telangana'],
        501141: ['Rangareddy','Telangana'],
        501142: ['Rangareddy','Telangana'],
        501143: ['Rangareddy','Telangana'],
        501144: ['Rangareddy','Telangana'],
        501158: ['Rangareddy','Telangana'],
        501202: ['Rangareddy','Telangana'],
        501203: ['Rangareddy','Telangana'],
        501218: ['Rangareddy','Telangana'],
        501359: ['Rangareddy','Telangana'],
        501501: ['Rangareddy','Telangana'],
        501502: ['Rangareddy','Telangana'],
        501503: ['Rangareddy','Telangana'],
        501504: ['Rangareddy','Telangana'],
        501506: ['Rangareddy','Telangana'],
        501508: ['Rangareddy','Telangana'],
        501509: ['Rangareddy','Telangana'],
        501510: ['Rangareddy','Telangana'],
        501511: ['Rangareddy','Telangana'],
        501512: ['Rangareddy','Telangana'],
        502001: ['Sangareddy','Telangana'],
        502032: ['Sangareddy','Telangana'],
        502101: ['Medak','Telangana'],
        502102: ['Siddipet','Telangana'],
        502103: ['Siddipet','Telangana'],
        502107: ['Siddipet','Telangana'],
        502108: ['Siddipet','Telangana'],
        502109: ['Medak','Telangana'],
        502110: ['Medak','Telangana'],
        502113: ['Medak','Telangana'],
        502114: ['Siddipet','Telangana'],
        502115: ['Medak','Telangana'],
        502117: ['Medak','Telangana'],
        502125: ['Medak','Telangana'],
        502130: ['Medak','Telangana'],
        502205: ['Sangareddy','Telangana'],
        502210: ['Sangareddy','Telangana'],
        502220: ['Sangareddy','Telangana'],
        502221: ['Sangareddy','Telangana'],
        502228: ['Sangareddy','Telangana'],
        502246: ['Sangareddy','Telangana'],
        502247: ['Medak','Telangana'],
        502248: ['Medak','Telangana'],
        502249: ['Sangareddy','Telangana'],
        502251: ['Sangareddy','Telangana'],
        502255: ['Medak','Telangana'],
        502256: ['Sangareddy','Telangana'],
        502257: ['Sangareddy','Telangana'],
        502267: ['Siddipet','Telangana'],
        502269: ['Sangareddy','Telangana'],
        502270: ['Sangareddy','Telangana'],
        502271: ['Sangareddy','Telangana'],
        502273: ['Sangareddy','Telangana'],
        502276: ['Siddipet','Telangana'],
        502277: ['Medak','Telangana'],
        502278: ['Siddipet','Telangana'],
        502279: ['Siddipet','Telangana'],
        502280: ['Siddipet','Telangana'],
        502281: ['Siddipet','Telangana'],
        502285: ['Sangareddy','Telangana'],
        502286: ['Sangareddy','Telangana'],
        502287: ['Sangareddy','Telangana'],
        502290: ['Sangareddy','Telangana'],
        502291: ['Sangareddy','Telangana'],
        502293: ['Sangareddy','Telangana'],
        502294: ['Sangareddy','Telangana'],
        502295: ['Sangareddy','Telangana'],
        502296: ['Sangareddy','Telangana'],
        502300: ['Sangareddy','Telangana'],
        502301: ['Siddipet','Telangana'],
        502302: ['Medak','Telangana'],
        502303: ['Medak','Telangana'],
        502305: ['Sangareddy','Telangana'],
        502306: ['Sangareddy','Telangana'],
        502307: ['Sangareddy','Telangana'],
        502310: ['Medak','Telangana'],
        502311: ['Siddipet','Telangana'],
        502312: ['Siddipet','Telangana'],
        502313: ['Sangareddy','Telangana'],
        502314: ['Sangareddy','Telangana'],
        502316: ['Sangareddy','Telangana'],
        502318: ['Sangareddy','Telangana'],
        502319: ['Sangareddy','Telangana'],
        502321: ['Sangareddy','Telangana'],
        502324: ['Sangareddy','Telangana'],
        502325: ['Sangareddy','Telangana'],
        502329: ['Sangareddy','Telangana'],
        502331: ['Medak','Telangana'],
        502334: ['Medak','Telangana'],
        502335: ['Medak','Telangana'],
        502336: ['Medak','Telangana'],
        502345: ['Sangareddy','Telangana'],
        502371: ['Sangareddy','Telangana'],
        502372: ['Siddipet','Telangana'],
        502375: ['Siddipet','Telangana'],
        502381: ['Medak','Telangana'],
        503001: ['Nizamabad','Telangana'],
        503002: ['Nizamabad','Telangana'],
        503003: ['Nizamabad','Telangana'],
        503101: ['Kamareddy','Telangana'],
        503102: ['Kamareddy','Telangana'],
        503108: ['Kamareddy','Telangana'],
        503110: ['Kamareddy','Telangana'],
        503111: ['Kamareddy','Telangana'],
        503112: ['Kamareddy','Telangana'],
        503114: ['Kamareddy','Telangana'],
        503120: ['Kamareddy','Telangana'],
        503122: ['Kamareddy','Telangana'],
        503123: ['Kamareddy','Telangana'],
        503124: ['Nizamabad','Telangana'],
        503125: ['Kamareddy','Telangana'],
        503144: ['Kamareddy','Telangana'],
        503145: ['Vizianagaram','Andhra Pradesh'],
        503164: ['Nizamabad','Telangana'],
        503165: ['Nizamabad','Telangana'],
        503174: ['Nizamabad','Telangana'],
        503175: ['Nizamabad','Telangana'],
        503180: ['Nizamabad','Telangana'],
        503185: ['Nizamabad','Telangana'],
        503186: ['Nizamabad','Telangana'],
        503187: ['Kamareddy','Telangana'],
        503188: ['Vizianagaram','Andhra Pradesh'],
        503201: ['Nizamabad','Telangana'],
        503202: ['Nizamabad','Telangana'],
        503206: ['Nizamabad','Telangana'],
        503207: ['Nizamabad','Telangana'],
        503212: ['Nizamabad','Telangana'],
        503213: ['Nizamabad','Telangana'],
        503217: ['Nizamabad','Telangana'],
        503218: ['Nizamabad','Telangana'],
        503219: ['Nizamabad','Telangana'],
        503223: ['Nizamabad','Telangana'],
        503224: ['Nizamabad','Telangana'],
        503225: ['Vizianagaram','Andhra Pradesh'],
        503230: ['Vizianagaram','Andhra Pradesh'],
        503235: ['Vizianagaram','Andhra Pradesh'],
        503245: ['Nizamabad','Telangana'],
        503246: ['Nizamabad','Telangana'],
        503301: ['Kamareddy','Telangana'],
        503302: ['Kamareddy','Telangana'],
        503305: ['Kamareddy','Telangana'],
        503306: ['Kamareddy','Telangana'],
        503307: ['Nizamabad','Telangana'],
        503308: ['Nizamabad','Telangana'],
        503309: ['Kamareddy','Telangana'],
        503310: ['Kamareddy','Telangana'],
        503311: ['Nizamabad','Telangana'],
        503321: ['Vizianagaram','Andhra Pradesh'],
        503322: ['Nizamabad','Telangana'],
        504001: ['Adilabad','Telangana'],
        504002: ['Adilabad','Telangana'],
        504101: ['Nirmal','Telangana'],
        504102: ['Nirmal','Telangana'],
        504103: ['Nirmal','Telangana'],
        504104: ['Nirmal','Telangana'],
        504105: ['Nirmal','Telangana'],
        504106: ['Nirmal','Telangana'],
        504107: ['Nirmal','Telangana'],
        504109: ['Nirmal','Telangana'],
        504110: ['Nirmal','Telangana'],
        504201: ['Mancherial','Telangana'],
        504202: ['Nirmal','Telangana'],
        504203: ['Nirmal','Telangana'],
        504204: ['Mancherial','Telangana'],
        504205: ['Mancherial','Telangana'],
        504206: ['Mancherial','Telangana'],
        504207: ['Mancherial','Telangana'],
        504208: ['Mancherial','Telangana'],
        504209: ['Mancherial','Telangana'],
        504214: ['Prakasam','Andhra Pradesh'],
        504215: ['Prakasam','Andhra Pradesh'],
        504216: ['Mancherial','Telangana'],
        504218: ['Mancherial','Telangana'],
        504219: ['Mancherial','Telangana'],
        504220: ['Mancherial','Telangana'],
        504231: ['Mancherial','Telangana'],
        504251: ['Mancherial','Telangana'],
        504272: ['Mancherial','Telangana'],
        504273: ['Mancherial','Telangana'],
        504292: ['Mancherial','Telangana'],
        504293: ['Mancherial','Telangana'],
        504294: ['Mancherial','Telangana'],
        504295: ['Mancherial','Telangana'],
        504296: ['Mancherial','Telangana'],
        504297: ['Mancherial','Telangana'],
        504299: ['Mancherial','Telangana'],
        504301: ['Mancherial','Telangana'],
        504302: ['Mancherial','Telangana'],
        504303: ['Mancherial','Telangana'],
        504304: ['Adilabad','Telangana'],
        504306: ['Nirmal','Telangana'],
        504307: ['Adilabad','Telangana'],
        504308: ['Adilabad','Telangana'],
        504309: ['Adilabad','Telangana'],
        504310: ['Nirmal','Telangana'],
        504311: ['Adilabad','Telangana'],
        504312: ['Adilabad','Telangana'],
        504313: ['Adilabad','Telangana'],
        504323: ['Adilabad','Telangana'],
        504346: ['Prakasam','Andhra Pradesh'],
        505001: ['Karim Nagar','Telangana'],
        505002: ['Karim Nagar','Telangana'],
        505101: ['Warangal','Telangana'],
        505102: ['Warangal','Telangana'],
        505122: ['Karim Nagar','Telangana'],
        505129: ['Karim Nagar','Telangana'],
        505152: ['Peddapalli','Telangana'],
        505153: ['Peddapalli','Telangana'],
        505162: ['Peddapalli','Telangana'],
        505172: ['Peddapalli','Telangana'],
        505174: ['Peddapalli','Telangana'],
        505184: ['Peddapalli','Telangana'],
        505185: ['Peddapalli','Telangana'],
        505186: ['Peddapalli','Telangana'],
        505187: ['Vizianagaram','Andhra Pradesh'],
        505188: ['Peddapalli','Telangana'],
        505208: ['Peddapalli','Telangana'],
        505209: ['Peddapalli','Telangana'],
        505210: ['Peddapalli','Telangana'],
        505211: ['Peddapalli','Telangana'],
        505212: ['Peddapalli','Telangana'],
        505214: ['Peddapalli','Telangana'],
        505215: ['Peddapalli','Telangana'],
        505301: ['Sircilla','Telangana'],
        505302: ['Sircilla','Telangana'],
        505303: ['Sircilla','Telangana'],
        505304: ['Sircilla','Telangana'],
        505305: ['Sircilla','Telangana'],
        505306: ['Jagtial','Telangana'],
        505307: ['Sircilla','Telangana'],
        505325: ['Guntur','Andhra Pradesh'],
        505326: ['Jagtial','Telangana'],
        505327: ['Jagtial','Telangana'],
        505330: ['Jagtial','Telangana'],
        505331: ['Jagtial','Telangana'],
        505401: ['Karim Nagar','Telangana'],
        505402: ['Sircilla','Telangana'],
        505403: ['Sircilla','Telangana'],
        505404: ['Sircilla','Telangana'],
        505405: ['Sircilla','Telangana'],
        505415: ['Karim Nagar','Telangana'],
        505416: ['Peddapalli','Telangana'],
        505425: ['Jagtial','Telangana'],
        505445: ['Karim Nagar','Telangana'],
        505450: ['Jagtial','Telangana'],
        505451: ['Karim Nagar','Telangana'],
        505452: ['Jagtial','Telangana'],
        505453: ['Jagtial','Telangana'],
        505454: ['Jagtial','Telangana'],
        505455: ['Jagtial','Telangana'],
        505460: ['Jagtial','Telangana'],
        505462: ['Jagtial','Telangana'],
        505466: ['Siddipet','Telangana'],
        505467: ['Siddipet','Telangana'],
        505468: ['Karim Nagar','Telangana'],
        505469: ['Karim Nagar','Telangana'],
        505470: ['Karim Nagar','Telangana'],
        505471: ['Warangal','Telangana'],
        505472: ['Karim Nagar','Telangana'],
        505473: ['Siddipet','Telangana'],
        505474: ['Karim Nagar','Telangana'],
        505475: ['Karim Nagar','Telangana'],
        505476: ['Warangal','Telangana'],
        505480: ['Karim Nagar','Telangana'],
        505481: ['Karim Nagar','Telangana'],
        505490: ['Karim Nagar','Telangana'],
        505497: ['Warangal','Telangana'],
        505498: ['Karim Nagar','Telangana'],
        505501: ['Jagtial','Telangana'],
        505502: ['Sircilla','Telangana'],
        505503: ['Vizianagaram','Andhra Pradesh'],
        505504: ['Karim Nagar','Telangana'],
        505505: ['Karim Nagar','Telangana'],
        505514: ['Peddapalli','Telangana'],
        505524: ['Karim Nagar','Telangana'],
        505525: ['Vizianagaram','Andhra Pradesh'],
        505526: ['Jagtial','Telangana'],
        505527: ['Karim Nagar','Telangana'],
        505528: ['Siddipet','Telangana'],
        505529: ['Jagtial','Telangana'],
        505530: ['Karim Nagar','Telangana'],
        505531: ['Karim Nagar','Telangana'],
        505532: ['Jagtial','Telangana'],
        506001: ['Warangal','Telangana'],
        506002: ['Warangal','Telangana'],
        506003: ['Prakasam','Andhra Pradesh'],
        506004: ['Warangal','Telangana'],
        506005: ['Warangal','Telangana'],
        506006: ['Warangal','Telangana'],
        506007: ['Warangal','Telangana'],
        506008: ['Warangal','Telangana'],
        506009: ['Warangal','Telangana'],
        506011: ['Warangal','Telangana'],
        506013: ['Warangal','Telangana'],
        506015: ['Hanamkonda','Telangana'],
        506101: ['Warangal','Telangana'],
        506102: ['Mahabub Nagar','Telangana'],
        506104: ['Warangal','Telangana'],
        506105: ['Warangal','Telangana'],
        506112: ['Warangal','Telangana'],
        506122: ['Warangal','Telangana'],
        506132: ['Warangal','Telangana'],
        506134: ['Warangal','Telangana'],
        506135: ['Warangal','Telangana'],
        506142: ['Hanamkonda','Telangana'],
        506143: ['Warangal','Telangana'],
        506144: ['Warangal','Telangana'],
        506145: ['Hanamkonda','Telangana'],
        506151: ['Warangal','Telangana'],
        506163: ['Warangal','Telangana'],
        506164: ['Warangal','Telangana'],
        506165: ['Jayashankar','Telangana'],
        506166: ['Warangal','Telangana'],
        506167: ['Warangal','Telangana'],
        506168: ['Warangal','Telangana'],
        506169: ['Parkal','Telangana'],
        506170: ['Parkal','Telangana'],
        506172: ['Warangal','Telangana'],
        506175: ['Jangaon','Telangana'],
        506201: ['Jangaon','Telangana'],
        506221: ['Jangaon','Telangana'],
        506222: ['Warangal','Telangana'],
        506223: ['Jangaon','Telangana'],
        506224: ['Jangaon','Telangana'],
        506244: ['Jangaon','Telangana'],
        506252: ['Warangal','Telangana'],
        506301: ['Warangal','Telangana'],
        506302: ['Warangal','Telangana'],
        506303: ['Jangaon','Telangana'],
        506310: ['Warangal','Telangana'],
        506313: ['Warangal','Telangana'],
        506314: ['Warangal','Telangana'],
        506315: ['Warangal','Telangana'],
        506316: ['Warangal','Telangana'],
        506317: ['Warangal','Telangana'],
        506318: ['Warangal','Telangana'],
        506319: ['Parkal','Telangana'],
        506324: ['Warangal','Telangana'],
        506329: ['Warangal','Telangana'],
        506330: ['Warangal','Telangana'],
        506331: ['Warangal','Telangana'],
        506332: ['Warangal','Telangana'],
        506333: ['Warangal Rural','Telangana'],
        506342: ['Warangal','Telangana'],
        506343: ['Parkal','Telangana'],
        506344: ['Warangal','Telangana'],
        506345: ['Parkal','Telangana'],
        506347: ['Parkal','Telangana'],
        506348: ['Parkal','Telangana'],
        506349: ['Parkal','Telangana'],
        506352: ['Warangal','Telangana'],
        506355: ['Jangaon','Telangana'],
        506356: ['Warangal','Telangana'],
        506365: ['Warangal','Telangana'],
        506366: ['Warangal','Telangana'],
        506367: ['Jangaon','Telangana'],
        506368: ['Mahabuababad','Telangana'],
        506369: ['Warangal','Telangana'],
        506370: ['Hanamkonda','Telangana'],
        506371: ['Warangal','Telangana'],
        506381: ['Warangal','Telangana'],
        506391: ['Warangal','Telangana'],
        507001: ['Khammam','Telangana'],
        507002: ['Khammam','Telangana'],
        507003: ['Khammam','Telangana'],
        507101: ['Kothagudem Colls','Telangana'],
        507103: ['Kothagudem','Telangana'],
        507111: ['Kothagudem Colls','Telangana'],
        507114: ['Kothagudem Colls','Telangana'],
        507115: ['Kothagudem','Telangana'],
        507116: ['Kothagudem','Telangana'],
        507117: ['Kothagudem','Telangana'],
        507118: ['Kothagudem','Telangana'],
        507119: ['Kothagudem Colls','Telangana'],
        507120: ['Khammam','Telangana'],
        507122: ['Khammam','Telangana'],
        507123: ['Khammam','Telangana'],
        507124: ['Kothagudem','Telangana'],
        507125: ['Kothagudem Colls','Telangana'],
        507128: ['Kothagudem','Telangana'],
        507133: ['Kothagudem','Telangana'],
        507136: ['Kothagudem','Telangana'],
        507137: ['Khammam','Telangana'],
        507138: ['Kothagudem Colls','Telangana'],
        507140: ['Kothagudem','Telangana'],
        507154: ['Kothagudem Colls','Telangana'],
        507157: ['Khammam','Telangana'],
        507158: ['Khammam','Telangana'],
        507159: ['Khammam','Telangana'],
        507160: ['Khammam','Telangana'],
        507161: ['Khammam','Telangana'],
        507163: ['Khammam','Telangana'],
        507164: ['Kothagudem','Telangana'],
        507165: ['Khammam','Telangana'],
        507166: ['Khammam','Telangana'],
        507167: ['Khammam','Telangana'],
        507168: ['Khammam','Telangana'],
        507169: ['Khammam','Telangana'],
        507170: ['Khammam','Telangana'],
        507182: ['Khammam','Telangana'],
        507183: ['Khammam','Telangana'],
        507201: ['Khammam','Telangana'],
        507202: ['Khammam','Telangana'],
        507203: ['Khammam','Telangana'],
        507204: ['Khammam','Telangana'],
        507208: ['Khammam','Telangana'],
        507209: ['Khammam','Telangana'],
        507210: ['Khammam','Telangana'],
        507211: ['Khammam','Telangana'],
        507301: ['Khammam','Telangana'],
        507302: ['Kothagudem','Telangana'],
        507303: ['Kothagudem','Telangana'],
        507304: ['Khammam','Telangana'],
        507305: ['Khammam','Telangana'],
        507306: ['Kothagudem','Telangana'],
        507316: ['Kothagudem','Telangana'],
        507318: ['Khammam','Telangana'],
        508001: ['Nalgonda','Telangana'],
        508002: ['Nalgonda','Telangana'],
        508004: ['Nalgonda','Telangana'],
        508101: ['Bhongir','Telangana'],
        508105: ['Bhongir','Telangana'],
        508111: ['Bhongir','Telangana'],
        508112: ['Bhongir','Telangana'],
        508113: ['Bhongir','Telangana'],
        508114: ['Nalgonda','Telangana'],
        508115: ['Bhongir','Telangana'],
        508116: ['Bhongir','Telangana'],
        508117: ['Bhongir','Telangana'],
        508126: ['Bhongir','Telangana'],
        508201: ['Suryapet','Telangana'],
        508202: ['Nalgonda','Telangana'],
        508204: ['Suryapet','Telangana'],
        508205: ['Nalgonda','Telangana'],
        508206: ['Suryapet','Telangana'],
        508207: ['Nalgonda','Telangana'],
        508208: ['Nalgonda','Telangana'],
        508210: ['Nalgonda','Telangana'],
        508211: ['Nalgonda','Telangana'],
        508212: ['Suryapet','Telangana'],
        508213: ['Suryapet','Telangana'],
        508214: ['Suryapet','Telangana'],
        508217: ['Nalgonda','Telangana'],
        508218: ['Suryapet','Telangana'],
        508221: ['Suryapet','Telangana'],
        508223: ['Suryapet','Telangana'],
        508224: ['Suryapet','Telangana'],
        508233: ['Suryapet','Telangana'],
        508234: ['Suryapet','Telangana'],
        508238: ['Suryapet','Telangana'],
        508243: ['Nalgonda','Telangana'],
        508244: ['Nalgonda','Telangana'],
        508245: ['Nalgonda','Telangana'],
        508246: ['Suryapet','Telangana'],
        508247: ['Nalgonda','Telangana'],
        508248: ['Nalgonda','Telangana'],
        508250: ['Nalgonda','Telangana'],
        508252: ['Bhongir','Telangana'],
        508253: ['Bhongir','Telangana'],
        508254: ['Nalgonda','Telangana'],
        508255: ['Nalgonda','Telangana'],
        508256: ['Nalgonda','Telangana'],
        508257: ['Nalgonda','Telangana'],
        508258: ['Nalgonda','Telangana'],
        508266: ['Nalgonda','Telangana'],
        508277: ['Bhongir','Telangana'],
        508278: ['Nalgonda','Telangana'],
        508279: ['Suryapet','Telangana'],
        508280: ['Suryapet','Telangana'],
        508284: ['Bhongir','Telangana'],
        508285: ['Bhongir','Telangana'],
        508286: ['Bhongir','Telangana'],
        508355: ['Nalgonda','Telangana'],
        508373: ['Nalgonda','Telangana'],
        508374: ['Nalgonda','Telangana'],
        508376: ['Nalgonda','Telangana'],
        508377: ['Nalgonda','Telangana'],
        509001: ['Mahabub Nagar','Telangana'],
        509002: ['Mahabub Nagar','Telangana'],
        509102: ['Nagar Kurnool','Telangana'],
        509103: ['Wanaparthy','Telangana'],
        509104: ['Wanaparthy','Telangana'],
        509105: ['Nagar Kurnool','Telangana'],
        509106: ['Wanaparthy','Telangana'],
        509110: ['Wanaparthy','Telangana'],
        509120: ['Wanaparthy','Telangana'],
        509125: ['Gadwal','Telangana'],
        509126: ['Gadwal','Telangana'],
        509127: ['Gadwal','Telangana'],
        509128: ['Gadwal','Telangana'],
        509129: ['Gadwal','Telangana'],
        509130: ['Wanaparthy','Telangana'],
        509131: ['Wanaparthy','Telangana'],
        509132: ['Krishna','Andhra Pradesh'],
        509135: ['Gadwal','Telangana'],
        509152: ['Gadwal','Telangana'],
        509153: ['Gadwal','Telangana'],
        509201: ['Nagar Kurnool','Telangana'],
        509202: ['Rangareddy','Telangana'],
        509203: ['Nagar Kurnool','Telangana'],
        509204: ['Mahabub Nagar','Telangana'],
        509205: ['Mahabub Nagar','Telangana'],
        509206: ['Wanaparthy','Telangana'],
        509207: ['Rangareddy','Telangana'],
        509208: ['Mahabub Nagar','Telangana'],
        509209: ['Nagar Kurnool','Telangana'],
        509210: ['Mahabub Nagar','Telangana'],
        509215: ['Nagar Kurnool','Telangana'],
        509216: ['Rangareddy','Telangana'],
        509217: ['Rangareddy','Telangana'],
        509219: ['Mahabub Nagar','Andhra Pradesh'],
        509228: ['Rangareddy','Telangana'],
        509235: ['Nagar Kurnool','Telangana'],
        509301: ['Mahabub Nagar','Telangana'],
        509302: ['Nagar Kurnool','Telangana'],
        509311: ['Mahabub Nagar','Telangana'],
        509320: ['Sangareddy','Telangana'],
        509321: ['Rangareddy','Telangana'],
        509324: ['Vizianagaram','Andhra Pradesh'],
        509325: ['Rangareddy','Telangana'],
        509326: ['Nagar Kurnool','Telangana'],
        509327: ['Rangareddy','Telangana'],
        509334: ['Mahabub Nagar','Telangana'],
        509335: ['Vikarabad','Telangana'],
        509336: ['Vikarabad','Telangana'],
        509337: ['Mahabub Nagar','Telangana'],
        509338: ['Vikarabad','Telangana'],
        509339: ['Mahabub Nagar','Telangana'],
        509340: ['Mahabub Nagar','Telangana'],
        509349: ['Nagar Kurnool','Telangana'],
        509350: ['Vikarabad','Telangana'],
        509351: ['Mahabub Nagar','Telangana'],
        509352: ['Mahabub Nagar','Telangana'],
        509353: ['Mahabub Nagar','Telangana'],
        509357: ['Rangareddy','Telangana'],
        509358: ['Rangareddy','Telangana'],
        509360: ['Nagar Kurnool','Telangana'],
        509371: ['Mahabub Nagar','Telangana'],
        509375: ['Nagar Kurnool','Telangana'],
        509376: ['Nagar Kurnool','Telangana'],
        509380: ['Wanaparthy','Telangana'],
        509381: ['Nagar Kurnool','Telangana'],
        509382: ['Mahabub Nagar','Telangana'],
        509385: ['Stn. Jadcherla','Telangana'],
        509401: ['Nagar Kurnool','Telangana'],
        509406: ['Nagar Kurnool','Telangana'],
        509407: ['Mahabub Nagar','Telangana'],
        509408: ['Rangareddy','Telangana'],
        509409: ['Mahabub Nagar','Telangana'],
        509410: ['Rangareddy','Telangana'],
        509411: ['Mahabub Nagar','Telangana'],
        509412: ['Nagar Kurnool','Telangana'],
        515001: ['Ananthapur','Andhra Pradesh'],
        515002: ['Ananthapur','Andhra Pradesh'],
        515003: ['Ananthapur','Andhra Pradesh'],
        515004: ['Ananthapur','Andhra Pradesh'],
        515005: ['Ananthapur','Andhra Pradesh'],
        515101: ['Ananthapur','Andhra Pradesh'],
        515110: ['Ananthapur','Andhra Pradesh'],
        515122: ['Ananthapur','Andhra Pradesh'],
        515123: ['Ananthapur','Andhra Pradesh'],
        515124: ['Ananthapur','Andhra Pradesh'],
        515133: ['Ananthapur','Andhra Pradesh'],
        515134: ['Ananthapur','Andhra Pradesh'],
        515144: ['Ananthapur','Andhra Pradesh'],
        515154: ['Ananthapur','Andhra Pradesh'],
        515159: ['Ananthapur','Andhra Pradesh'],
        515164: ['Ananthapur','Andhra Pradesh'],
        515201: ['Ananthapur','Andhra Pradesh'],
        515202: ['Hindupur','Andhra Pradesh'],
        515211: ['Ananthapur','Andhra Pradesh'],
        515212: ['Ananthapur','Andhra Pradesh'],
        515231: ['Ananthapur','Andhra Pradesh'],
        515241: ['Ananthapur','Andhra Pradesh'],
        515261: ['Ananthapur','Andhra Pradesh'],
        515271: ['Ananthapur','Andhra Pradesh'],
        515281: ['Ananthapur','Andhra Pradesh'],
        515286: ['Ananthapur','Andhra Pradesh'],
        515291: ['Ananthapur','Andhra Pradesh'],
        515301: ['Ananthapur','Andhra Pradesh'],
        515303: ['Ananthapur','Andhra Pradesh'],
        515305: ['Ananthapur','Andhra Pradesh'],
        515311: ['Ananthapur','Andhra Pradesh'],
        515321: ['Ananthapur','Andhra Pradesh'],
        515331: ['Ananthapur','Andhra Pradesh'],
        515341: ['Ananthapur','Andhra Pradesh'],
        515401: ['Ananthapur','Andhra Pradesh'],
        515402: ['Ananthapur','Andhra Pradesh'],
        515405: ['Ananthapur','Andhra Pradesh'],
        515408: ['Ananthapur','Andhra Pradesh'],
        515411: ['Ananthapur','Andhra Pradesh'],
        515413: ['Ananthapur','Andhra Pradesh'],
        515414: ['Ananthapur','Andhra Pradesh'],
        515415: ['Ananthapur','Andhra Pradesh'],
        515425: ['Ananthapur','Andhra Pradesh'],
        515435: ['Ananthapur','Andhra Pradesh'],
        515445: ['Ananthapur','Andhra Pradesh'],
        515455: ['Ananthapur','Andhra Pradesh'],
        515465: ['Ananthapur','Andhra Pradesh'],
        515501: ['Ananthapur','Andhra Pradesh'],
        515511: ['Ananthapur','Andhra Pradesh'],
        515521: ['Ananthapur','Andhra Pradesh'],
        515531: ['Ananthapur','Andhra Pradesh'],
        515541: ['Ananthapur','Andhra Pradesh'],
        515551: ['Ananthapur','Andhra Pradesh'],
        515556: ['Ananthapur','Andhra Pradesh'],
        515561: ['Ananthapur','Andhra Pradesh'],
        515571: ['Ananthapur','Andhra Pradesh'],
        515581: ['Ananthapur','Andhra Pradesh'],
        515591: ['Ananthapur','Andhra Pradesh'],
        515601: ['Ananthapur','Andhra Pradesh'],
        515611: ['Ananthapur','Andhra Pradesh'],
        515621: ['Ananthapur','Andhra Pradesh'],
        515631: ['Ananthapur','Andhra Pradesh'],
        515641: ['Ananthapur','Andhra Pradesh'],
        515651: ['Ananthapur','Andhra Pradesh'],
        515661: ['Ananthapur','Andhra Pradesh'],
        515671: ['Ananthapur','Andhra Pradesh'],
        515672: ['Ananthapur','Andhra Pradesh'],
        515701: ['Ananthapur','Andhra Pradesh'],
        515711: ['Ananthapur','Andhra Pradesh'],
        515721: ['Ananthapur','Andhra Pradesh'],
        515722: ['Ananthapur','Andhra Pradesh'],
        515731: ['Ananthapur','Andhra Pradesh'],
        515741: ['Ananthapur','Andhra Pradesh'],
        515751: ['Ananthapur','Andhra Pradesh'],
        515761: ['Ananthapur','Andhra Pradesh'],
        515763: ['Ananthapur','Andhra Pradesh'],
        515765: ['Ananthapur','Andhra Pradesh'],
        515766: ['Ananthapur','Andhra Pradesh'],
        515767: ['Ananthapur','Andhra Pradesh'],
        515774: ['Ananthapur','Andhra Pradesh'],
        515775: ['Ananthapur','Andhra Pradesh'],
        515787: ['Ananthapur','Andhra Pradesh'],
        515801: ['Ananthapur','Andhra Pradesh'],
        515803: ['Ananthapur','Andhra Pradesh'],
        515812: ['Ananthapur','Andhra Pradesh'],
        515822: ['Ananthapur','Andhra Pradesh'],
        515832: ['Ananthapur','Andhra Pradesh'],
        515842: ['Ananthapur','Andhra Pradesh'],
        515863: ['Ananthapur','Andhra Pradesh'],
        515865: ['Ananthapur','Andhra Pradesh'],
        515867: ['Ananthapur','Andhra Pradesh'],
        515870: ['Ananthapur','Andhra Pradesh'],
        515871: ['Ananthapur','Andhra Pradesh'],
        515872: ['Ananthapur','Andhra Pradesh'],
        516001: ['Kadapa','Andhra Pradesh'],
        516002: ['Kadapa','Andhra Pradesh'],
        516003: ['Kadapa','Andhra Pradesh'],
        516004: ['Kadapa','Andhra Pradesh'],
        516005: ['Kadapa','Andhra Pradesh'],
        516101: ['Kadapa','Andhra Pradesh'],
        516104: ['Kadapa','Andhra Pradesh'],
        516105: ['Kadapa','Andhra Pradesh'],
        516107: ['Kadapa','Andhra Pradesh'],
        516108: ['Kadapa','Andhra Pradesh'],
        516110: ['Kadapa','Andhra Pradesh'],
        516115: ['Kadapa','Andhra Pradesh'],
        516126: ['Kadapa','Andhra Pradesh'],
        516127: ['Kadapa','Andhra Pradesh'],
        516128: ['Kadapa','Andhra Pradesh'],
        516129: ['Kadapa','Andhra Pradesh'],
        516130: ['Kadapa','Andhra Pradesh'],
        516150: ['Kadapa','Andhra Pradesh'],
        516151: ['Kadapa','Andhra Pradesh'],
        516152: ['Kadapa','Andhra Pradesh'],
        516162: ['Kadapa','Andhra Pradesh'],
        516163: ['Kadapa','Andhra Pradesh'],
        516172: ['Kadapa','Andhra Pradesh'],
        516173: ['Kadapa','Andhra Pradesh'],
        516175: ['Kadapa','Andhra Pradesh'],
        516193: ['Kadapa','Andhra Pradesh'],
        516203: ['Kadapa','Andhra Pradesh'],
        516213: ['Kadapa','Andhra Pradesh'],
        516214: ['Kadapa','Andhra Pradesh'],
        516215: ['Kadapa','Andhra Pradesh'],
        516216: ['Kadapa','Andhra Pradesh'],
        516217: ['Kadapa','Andhra Pradesh'],
        516218: ['Kadapa','Andhra Pradesh'],
        516227: ['Kadapa','Andhra Pradesh'],
        516233: ['Kadapa','Andhra Pradesh'],
        516237: ['Kadapa','Andhra Pradesh'],
        516247: ['Kadapa','Andhra Pradesh'],
        516257: ['Kadapa','Andhra Pradesh'],
        516259: ['Kadapa','Andhra Pradesh'],
        516267: ['Kadapa','Andhra Pradesh'],
        516268: ['Kadapa','Andhra Pradesh'],
        516269: ['Kadapa','Andhra Pradesh'],
        516270: ['Kadapa','Andhra Pradesh'],
        516289: ['Kadapa','Andhra Pradesh'],
        516293: ['Kadapa','Andhra Pradesh'],
        516309: ['Kadapa','Andhra Pradesh'],
        516310: ['Kadapa','Andhra Pradesh'],
        516311: ['Kadapa','Andhra Pradesh'],
        516312: ['Kadapa','Andhra Pradesh'],
        516321: ['Kadapa','Andhra Pradesh'],
        516329: ['Kadapa','Andhra Pradesh'],
        516330: ['Kadapa','Andhra Pradesh'],
        516339: ['Kadapa','Andhra Pradesh'],
        516349: ['Kadapa','Andhra Pradesh'],
        516350: ['Kadapa','Andhra Pradesh'],
        516355: ['Kadapa','Andhra Pradesh'],
        516356: ['Kadapa','Andhra Pradesh'],
        516359: ['Kadapa','Andhra Pradesh'],
        516360: ['Kadapa','Andhra Pradesh'],
        516361: ['Kadapa','Andhra Pradesh'],
        516362: ['Kadapa','Andhra Pradesh'],
        516380: ['Kadapa','Andhra Pradesh'],
        516390: ['Kadapa','Andhra Pradesh'],
        516391: ['Kadapa','Andhra Pradesh'],
        516396: ['Kadapa','Andhra Pradesh'],
        516401: ['Kadapa','Andhra Pradesh'],
        516411: ['Kadapa','Andhra Pradesh'],
        516421: ['Kadapa','Andhra Pradesh'],
        516431: ['Kadapa','Andhra Pradesh'],
        516432: ['Kadapa','Andhra Pradesh'],
        516433: ['Kadapa','Andhra Pradesh'],
        516434: ['Kadapa','Andhra Pradesh'],
        516439: ['Kadapa','Andhra Pradesh'],
        516444: ['Kadapa','Andhra Pradesh'],
        516454: ['Kadapa','Andhra Pradesh'],
        516464: ['Kadapa','Andhra Pradesh'],
        516474: ['Kadapa','Andhra Pradesh'],
        516484: ['Kadapa','Andhra Pradesh'],
        516501: ['Kadapa','Andhra Pradesh'],
        516502: ['Kadapa','Andhra Pradesh'],
        516503: ['Kadapa','Andhra Pradesh'],
        516504: ['Kadapa','Andhra Pradesh'],
        516505: ['Kadapa','Andhra Pradesh'],
        517001: ['Chittoor','Andhra Pradesh'],
        517002: ['Chittoor','Andhra Pradesh'],
        517004: ['Chittoor','Andhra Pradesh'],
        517102: ['Chittoor','Andhra Pradesh'],
        517112: ['Chittoor','Andhra Pradesh'],
        517113: ['Chittoor','Andhra Pradesh'],
        517123: ['Chittoor','Andhra Pradesh'],
        517124: ['Chittoor','Andhra Pradesh'],
        517125: ['Chittoor','Andhra Pradesh'],
        517126: ['Chittoor','Andhra Pradesh'],
        517127: ['Chittoor','Andhra Pradesh'],
        517128: ['Chittoor','Andhra Pradesh'],
        517129: ['Chittoor','Andhra Pradesh'],
        517130: ['Chittoor','Andhra Pradesh'],
        517131: ['Chittoor','Andhra Pradesh'],
        517132: ['Chittoor','Andhra Pradesh'],
        517152: ['Chittoor','Andhra Pradesh'],
        517167: ['Chittoor','Andhra Pradesh'],
        517172: ['Chittoor','Andhra Pradesh'],
        517192: ['Chittoor','Andhra Pradesh'],
        517193: ['Chittoor','Andhra Pradesh'],
        517194: ['Chittoor','Andhra Pradesh'],
        517213: ['Chittoor','Andhra Pradesh'],
        517214: ['Chittoor','Andhra Pradesh'],
        517234: ['Chittoor','Andhra Pradesh'],
        517235: ['Chittoor','Andhra Pradesh'],
        517236: ['Chittoor','Andhra Pradesh'],
        517237: ['Chittoor','Andhra Pradesh'],
        517247: ['Chittoor','Andhra Pradesh'],
        517257: ['Chittoor','Andhra Pradesh'],
        517277: ['Chittoor','Andhra Pradesh'],
        517280: ['Chittoor','Andhra Pradesh'],
        517291: ['Chittoor','Andhra Pradesh'],
        517297: ['Chittoor','Andhra Pradesh'],
        517299: ['Chittoor','Andhra Pradesh'],
        517305: ['Chittoor','Andhra Pradesh'],
        517319: ['Chittoor','Andhra Pradesh'],
        517325: ['Chittoor','Andhra Pradesh'],
        517326: ['Madanapalle','Andhra Pradesh'],
        517350: ['Chittoor','Andhra Pradesh'],
        517351: ['Chittoor','Andhra Pradesh'],
        517352: ['Chittoor','Andhra Pradesh'],
        517370: ['Chittoor','Andhra Pradesh'],
        517390: ['Chittoor','Andhra Pradesh'],
        517391: ['Chittoor','Andhra Pradesh'],
        517401: ['Chittoor','Andhra Pradesh'],
        517403: ['Chittoor','Andhra Pradesh'],
        517408: ['Chittoor','Andhra Pradesh'],
        517414: ['Chittoor','Andhra Pradesh'],
        517415: ['Chittoor','Andhra Pradesh'],
        517416: ['Chittoor','Andhra Pradesh'],
        517417: ['Chittoor','Andhra Pradesh'],
        517418: ['Chittoor','Andhra Pradesh'],
        517419: ['Chittoor','Andhra Pradesh'],
        517421: ['Chittoor','Andhra Pradesh'],
        517422: ['Chittoor','Andhra Pradesh'],
        517423: ['Chittoor','Andhra Pradesh'],
        517424: ['Chittoor','Andhra Pradesh'],
        517425: ['Chittoor','Andhra Pradesh'],
        517426: ['Chittoor','Andhra Pradesh'],
        517429: ['Chittoor','Andhra Pradesh'],
        517432: ['Chittoor','Andhra Pradesh'],
        517501: ['Chittoor','Andhra Pradesh'],
        517502: ['Chittoor','Andhra Pradesh'],
        517503: ['Chittoor','Andhra Pradesh'],
        517504: ['Chittoor','Andhra Pradesh'],
        517505: ['Chittoor','Andhra Pradesh'],
        517506: ['Chittoor','Andhra Pradesh'],
        517507: ['Chittoor','Andhra Pradesh'],
        517520: ['Chittoor','Andhra Pradesh'],
        517526: ['Chittoor','Andhra Pradesh'],
        517536: ['Chittoor','Andhra Pradesh'],
        517541: ['Chittoor','Andhra Pradesh'],
        517551: ['Chittoor','Andhra Pradesh'],
        517561: ['Chittoor','Andhra Pradesh'],
        517569: ['Chittoor','Andhra Pradesh'],
        517571: ['Chittoor','Andhra Pradesh'],
        517581: ['Chittoor','Andhra Pradesh'],
        517582: ['Chittoor','Andhra Pradesh'],
        517583: ['Chittoor','Andhra Pradesh'],
        517584: ['Chittoor','Andhra Pradesh'],
        517586: ['Chittoor','Andhra Pradesh'],
        517587: ['Chittoor','Andhra Pradesh'],
        517588: ['Chittoor','Andhra Pradesh'],
        517589: ['Chittoor','Andhra Pradesh'],
        517590: ['Chittoor','Andhra Pradesh'],
        517591: ['Chittoor','Andhra Pradesh'],
        517592: ['Chittoor','Andhra Pradesh'],
        517599: ['Chittoor','Andhra Pradesh'],
        517619: ['Chittoor','Andhra Pradesh'],
        517620: ['Chittoor','Andhra Pradesh'],
        517640: ['Chittoor','Andhra Pradesh'],
        517641: ['Chittoor','Andhra Pradesh'],
        517642: ['Chittoor','Andhra Pradesh'],
        517643: ['Chittoor','Andhra Pradesh'],
        517644: ['Chittoor','Andhra Pradesh'],
        517645: ['Chittoor','Andhra Pradesh'],
        517646: ['Chittoor','Andhra Pradesh'],
        518001: ['Kurnool','Andhra Pradesh'],
        518002: ['Kurnool','Andhra Pradesh'],
        518003: ['Kurnool','Andhra Pradesh'],
        518004: ['Kurnool','Andhra Pradesh'],
        518005: ['Kurnool','Andhra Pradesh'],
        518006: ['Kurnool','Andhra Pradesh'],
        518007: ['Kurnool','Andhra Pradesh'],
        518010: ['Kurnool','Andhra Pradesh'],
        518101: ['Kurnool','Andhra Pradesh'],
        518102: ['Kurnool','Andhra Pradesh'],
        518112: ['Kurnool','Andhra Pradesh'],
        518122: ['Kurnool','Andhra Pradesh'],
        518123: ['Kurnool','Andhra Pradesh'],
        518124: ['Kurnool','Andhra Pradesh'],
        518134: ['Kurnool','Andhra Pradesh'],
        518135: ['Kurnool','Andhra Pradesh'],
        518145: ['Kurnool','Andhra Pradesh'],
        518155: ['Kurnool','Andhra Pradesh'],
        518165: ['Kurnool','Andhra Pradesh'],
        518166: ['Kurnool','Andhra Pradesh'],
        518176: ['Kurnool','Andhra Pradesh'],
        518186: ['Kurnool','Andhra Pradesh'],
        518196: ['Kurnool','Andhra Pradesh'],
        518206: ['Kurnool','Andhra Pradesh'],
        518216: ['Kurnool','Andhra Pradesh'],
        518217: ['Kurnool','Andhra Pradesh'],
        518218: ['Kurnool','Andhra Pradesh'],
        518220: ['Kurnool','Andhra Pradesh'],
        518221: ['Kurnool','Andhra Pradesh'],
        518222: ['Kurnool','Andhra Pradesh'],
        518225: ['Kurnool','Andhra Pradesh'],
        518301: ['Kurnool','Andhra Pradesh'],
        518302: ['Kurnool','Andhra Pradesh'],
        518308: ['Kurnool','Andhra Pradesh'],
        518313: ['Kurnool','Andhra Pradesh'],
        518323: ['Kurnool','Andhra Pradesh'],
        518333: ['Kurnool','Andhra Pradesh'],
        518343: ['Kurnool','Andhra Pradesh'],
        518344: ['Kurnool','Andhra Pradesh'],
        518345: ['Kurnool','Andhra Pradesh'],
        518346: ['Kurnool','Andhra Pradesh'],
        518347: ['Kurnool','Andhra Pradesh'],
        518348: ['Kurnool','Andhra Pradesh'],
        518349: ['Kurnool','Andhra Pradesh'],
        518350: ['Kurnool','Andhra Pradesh'],
        518360: ['Kurnool','Andhra Pradesh'],
        518380: ['Kurnool','Andhra Pradesh'],
        518385: ['Kurnool','Andhra Pradesh'],
        518390: ['Kurnool','Andhra Pradesh'],
        518395: ['Kurnool','Andhra Pradesh'],
        518396: ['Kurnool','Andhra Pradesh'],
        518401: ['Kurnool','Andhra Pradesh'],
        518405: ['Kurnool','Andhra Pradesh'],
        518411: ['Kurnool','Andhra Pradesh'],
        518412: ['Kurnool','Andhra Pradesh'],
        518422: ['Kurnool','Andhra Pradesh'],
        518432: ['Kurnool','Andhra Pradesh'],
        518442: ['Kurnool','Andhra Pradesh'],
        518452: ['Kurnool','Andhra Pradesh'],
        518462: ['Kurnool','Andhra Pradesh'],
        518463: ['Kurnool','Andhra Pradesh'],
        518464: ['Kurnool','Andhra Pradesh'],
        518465: ['Kurnool','Andhra Pradesh'],
        518466: ['Kurnool','Andhra Pradesh'],
        518467: ['Kurnool','Andhra Pradesh'],
        518468: ['Kurnool','Andhra Pradesh'],
        518501: ['Kurnool','Andhra Pradesh'],
        518502: ['Kurnool','Andhra Pradesh'],
        518508: ['Kurnool','Andhra Pradesh'],
        518510: ['Kurnool','Andhra Pradesh'],
        518511: ['Kurnool','Andhra Pradesh'],
        518512: ['Kurnool','Andhra Pradesh'],
        518513: ['Kurnool','Andhra Pradesh'],
        518523: ['Kurnool','Andhra Pradesh'],
        518533: ['Kurnool','Andhra Pradesh'],
        518543: ['Kurnool','Andhra Pradesh'],
        518553: ['Kurnool','Andhra Pradesh'],
        518563: ['Kurnool','Andhra Pradesh'],
        518573: ['Kurnool','Andhra Pradesh'],
        518583: ['Kurnool','Andhra Pradesh'],
        518593: ['Kurnool','Andhra Pradesh'],
        518594: ['Kurnool','Andhra Pradesh'],
        518598: ['Kurnool','Andhra Pradesh'],
        518599: ['Kurnool','Andhra Pradesh'],
        518674: ['Kurnool','Andhra Pradesh'],
        520001: ['Krishna','Andhra Pradesh'],
        520002: ['Krishna','Andhra Pradesh'],
        520003: ['Krishna','Andhra Pradesh'],
        520004: ['Krishna','Andhra Pradesh'],
        520007: ['Krishna','Andhra Pradesh'],
        520008: ['Krishna','Andhra Pradesh'],
        520010: ['Krishna','Andhra Pradesh'],
        520011: ['Krishna','Andhra Pradesh'],
        520012: ['Krishna','Andhra Pradesh'],
        520013: ['Krishna','Andhra Pradesh'],
        520015: ['Krishna','Andhra Pradesh'],
        521001: ['Krishna','Andhra Pradesh'],
        521002: ['Krishna','Andhra Pradesh'],
        521003: ['Machilipatnam','Andhra Pradesh'],
        521101: ['Krishna','Andhra Pradesh'],
        521102: ['Krishna','Andhra Pradesh'],
        521104: ['Krishna','Andhra Pradesh'],
        521105: ['Krishna','Andhra Pradesh'],
        521106: ['Krishna','Andhra Pradesh'],
        521107: ['Krishna','Andhra Pradesh'],
        521108: ['Krishna','Andhra Pradesh'],
        521109: ['Krishna','Andhra Pradesh'],
        521110: ['Krishna','Andhra Pradesh'],
        521111: ['Krishna','Andhra Pradesh'],
        521120: ['Krishna','Andhra Pradesh'],
        521121: ['Krishna','Andhra Pradesh'],
        521122: ['Krishna','Andhra Pradesh'],
        521125: ['Krishna','Andhra Pradesh'],
        521126: ['Krishna','Andhra Pradesh'],
        521130: ['Krishna','Andhra Pradesh'],
        521131: ['Krishna','Andhra Pradesh'],
        521132: ['Krishna','Andhra Pradesh'],
        521133: ['Krishna','Andhra Pradesh'],
        521134: ['Krishna','Andhra Pradesh'],
        521135: ['Krishna','Andhra Pradesh'],
        521136: ['Krishna','Andhra Pradesh'],
        521137: ['Krishna','Andhra Pradesh'],
        521138: ['Krishna','Andhra Pradesh'],
        521139: ['Krishna','Andhra Pradesh'],
        521148: ['Krishna','Andhra Pradesh'],
        521149: ['Krishna','Andhra Pradesh'],
        521150: ['Krishna','Andhra Pradesh'],
        521151: ['Krishna','Andhra Pradesh'],
        521153: ['Krishna','Andhra Pradesh'],
        521156: ['Krishna','Andhra Pradesh'],
        521157: ['Krishna','Andhra Pradesh'],
        521158: ['Krishna','Andhra Pradesh'],
        521162: ['Krishna','Andhra Pradesh'],
        521163: ['Krishna','Andhra Pradesh'],
        521164: ['Krishna','Andhra Pradesh'],
        521165: ['Krishna','Andhra Pradesh'],
        521170: ['Krishna','Andhra Pradesh'],
        521175: ['Krishna','Andhra Pradesh'],
        521178: ['Krishna','Andhra Pradesh'],
        521180: ['Krishna','Andhra Pradesh'],
        521181: ['Krishna','Andhra Pradesh'],
        521182: ['Krishna','Andhra Pradesh'],
        521183: ['Krishna','Andhra Pradesh'],
        521184: ['Krishna','Andhra Pradesh'],
        521185: ['Krishna','Andhra Pradesh'],
        521190: ['Krishna','Andhra Pradesh'],
        521201: ['Krishna','Andhra Pradesh'],
        521202: ['Krishna','Andhra Pradesh'],
        521207: ['Krishna','Andhra Pradesh'],
        521211: ['Krishna','Andhra Pradesh'],
        521212: ['Krishna','Andhra Pradesh'],
        521213: ['Krishna','Andhra Pradesh'],
        521214: ['Krishna','Andhra Pradesh'],
        521215: ['Krishna','Andhra Pradesh'],
        521225: ['Krishna','Andhra Pradesh'],
        521226: ['Krishna','Andhra Pradesh'],
        521227: ['Krishna','Andhra Pradesh'],
        521228: ['Krishna','Andhra Pradesh'],
        521229: ['Krishna','Andhra Pradesh'],
        521230: ['Krishna','Andhra Pradesh'],
        521231: ['Krishna','Andhra Pradesh'],
        521235: ['Krishna','Andhra Pradesh'],
        521241: ['Krishna','Andhra Pradesh'],
        521245: ['Krishna','Andhra Pradesh'],
        521246: ['Krishna','Andhra Pradesh'],
        521247: ['Krishna','Andhra Pradesh'],
        521250: ['Krishna','Andhra Pradesh'],
        521256: ['Krishna','Andhra Pradesh'],
        521260: ['Krishna','Andhra Pradesh'],
        521261: ['Krishna','Andhra Pradesh'],
        521263: ['Krishna','Andhra Pradesh'],
        521286: ['Krishna','Andhra Pradesh'],
        521301: ['Krishna','Andhra Pradesh'],
        521311: ['Krishna','Andhra Pradesh'],
        521312: ['Krishna','Andhra Pradesh'],
        521321: ['Krishna','Andhra Pradesh'],
        521322: ['Krishna','Andhra Pradesh'],
        521323: ['Krishna','Andhra Pradesh'],
        521324: ['Krishna','Andhra Pradesh'],
        521325: ['Krishna','Andhra Pradesh'],
        521326: ['Krishna','Andhra Pradesh'],
        521327: ['Krishna','Andhra Pradesh'],
        521328: ['Krishna','Andhra Pradesh'],
        521329: ['Krishna','Andhra Pradesh'],
        521330: ['Krishna','Andhra Pradesh'],
        521331: ['Krishna','Andhra Pradesh'],
        521332: ['Krishna','Andhra Pradesh'],
        521333: ['Krishna','Andhra Pradesh'],
        521340: ['Krishna','Andhra Pradesh'],
        521343: ['Krishna','Andhra Pradesh'],
        521344: ['Krishna','Andhra Pradesh'],
        521345: ['Krishna','Andhra Pradesh'],
        521356: ['Krishna','Andhra Pradesh'],
        521366: ['Krishna','Andhra Pradesh'],
        521369: ['Krishna','Andhra Pradesh'],
        521390: ['Krishna','Andhra Pradesh'],
        521401: ['Krishna','Andhra Pradesh'],
        521402: ['Krishna','Andhra Pradesh'],
        521403: ['Krishna','Andhra Pradesh'],
        521456: ['Krishna','Andhra Pradesh'],
        521457: ['Krishna','Andhra Pradesh'],
        522001: ['Guntur','Andhra Pradesh'],
        522002: ['Guntur','Andhra Pradesh'],
        522003: ['Guntur','Andhra Pradesh'],
        522004: ['Guntur','Andhra Pradesh'],
        522005: ['Guntur','Andhra Pradesh'],
        522006: ['Guntur','Andhra Pradesh'],
        522007: ['Guntur','Andhra Pradesh'],
        522009: ['Guntur','Andhra Pradesh'],
        522015: ['Guntur','Andhra Pradesh'],
        522016: ['Guntur','Andhra Pradesh'],
        522017: ['Guntur','Andhra Pradesh'],
        522018: ['Guntur','Andhra Pradesh'],
        522019: ['Guntur','Andhra Pradesh'],
        522020: ['Guntur','Andhra Pradesh'],
        522034: ['Guntur','Andhra Pradesh'],
        522101: ['Guntur','Andhra Pradesh'],
        522102: ['Bapatla','Andhra Pradesh'],
        522111: ['Guntur','Andhra Pradesh'],
        522112: ['Guntur','Andhra Pradesh'],
        522113: ['Guntur','Andhra Pradesh'],
        522124: ['Guntur','Andhra Pradesh'],
        522201: ['Guntur','Andhra Pradesh'],
        522202: ['Guntur','Andhra Pradesh'],
        522211: ['Guntur','Andhra Pradesh'],
        522212: ['Guntur','Andhra Pradesh'],
        522213: ['Guntur','Andhra Pradesh'],
        522233: ['Guntur','Andhra Pradesh'],
        522234: ['Guntur','Andhra Pradesh'],
        522235: ['Guntur','Andhra Pradesh'],
        522236: ['Guntur','Andhra Pradesh'],
        522237: ['Guntur','Andhra Pradesh'],
        522238: ['Guntur','Andhra Pradesh'],
        522256: ['Guntur','Andhra Pradesh'],
        522257: ['Guntur','Andhra Pradesh'],
        522258: ['Guntur','Andhra Pradesh'],
        522259: ['Guntur','Andhra Pradesh'],
        522261: ['Guntur','Andhra Pradesh'],
        522262: ['Guntur','Andhra Pradesh'],
        522264: ['Guntur','Andhra Pradesh'],
        522265: ['Guntur','Andhra Pradesh'],
        522268: ['Guntur','Andhra Pradesh'],
        522301: ['Guntur','Andhra Pradesh'],
        522302: ['Guntur','Andhra Pradesh'],
        522303: ['Guntur','Andhra Pradesh'],
        522304: ['Guntur','Andhra Pradesh'],
        522305: ['Guntur','Andhra Pradesh'],
        522306: ['Guntur','Andhra Pradesh'],
        522307: ['Guntur','Andhra Pradesh'],
        522308: ['Guntur','Andhra Pradesh'],
        522309: ['Guntur','Andhra Pradesh'],
        522310: ['Guntur','Andhra Pradesh'],
        522311: ['Guntur','Andhra Pradesh'],
        522312: ['Guntur','Andhra Pradesh'],
        522313: ['Guntur','Andhra Pradesh'],
        522314: ['Guntur','Andhra Pradesh'],
        522315: ['Guntur','Andhra Pradesh'],
        522316: ['Guntur','Andhra Pradesh'],
        522317: ['Guntur','Andhra Pradesh'],
        522318: ['Guntur','Andhra Pradesh'],
        522324: ['Guntur','Andhra Pradesh'],
        522325: ['Guntur','Andhra Pradesh'],
        522329: ['Guntur','Andhra Pradesh'],
        522330: ['Guntur','Andhra Pradesh'],
        522341: ['Guntur','Andhra Pradesh'],
        522401: ['Guntur','Andhra Pradesh'],
        522402: ['Guntur','Andhra Pradesh'],
        522403: ['Guntur','Andhra Pradesh'],
        522408: ['Guntur','Andhra Pradesh'],
        522409: ['Guntur','Andhra Pradesh'],
        522410: ['Guntur','Andhra Pradesh'],
        522411: ['Guntur','Andhra Pradesh'],
        522412: ['Guntur','Andhra Pradesh'],
        522413: ['Guntur','Andhra Pradesh'],
        522414: ['Guntur','Andhra Pradesh'],
        522415: ['Guntur','Andhra Pradesh'],
        522421: ['Guntur','Andhra Pradesh'],
        522426: ['Guntur','Andhra Pradesh'],
        522435: ['Guntur','Andhra Pradesh'],
        522436: ['Guntur','Andhra Pradesh'],
        522437: ['Guntur','Andhra Pradesh'],
        522438: ['Guntur','Andhra Pradesh'],
        522439: ['Guntur','Andhra Pradesh'],
        522501: ['Guntur','Andhra Pradesh'],
        522502: ['Guntur','Andhra Pradesh'],
        522503: ['Guntur','Andhra Pradesh'],
        522508: ['Guntur','Andhra Pradesh'],
        522509: ['Guntur','Andhra Pradesh'],
        522510: ['Guntur','Andhra Pradesh'],
        522529: ['Guntur','Andhra Pradesh'],
        522549: ['Guntur','Andhra Pradesh'],
        522601: ['Guntur','Andhra Pradesh'],
        522603: ['Guntur','Andhra Pradesh'],
        522611: ['Guntur','Andhra Pradesh'],
        522612: ['Guntur','Andhra Pradesh'],
        522613: ['Guntur','Andhra Pradesh'],
        522614: ['Guntur','Andhra Pradesh'],
        522615: ['Guntur','Andhra Pradesh'],
        522616: ['Guntur','Andhra Pradesh'],
        522617: ['Guntur','Andhra Pradesh'],
        522619: ['Guntur','Andhra Pradesh'],
        522626: ['Guntur','Andhra Pradesh'],
        522646: ['Guntur','Andhra Pradesh'],
        522647: ['Guntur','Andhra Pradesh'],
        522649: ['Guntur','Andhra Pradesh'],
        522657: ['Guntur','Andhra Pradesh'],
        522658: ['Guntur','Andhra Pradesh'],
        522659: ['Guntur','Andhra Pradesh'],
        522660: ['Guntur','Andhra Pradesh'],
        522661: ['Guntur','Andhra Pradesh'],
        522663: ['Guntur','Andhra Pradesh'],
        523001: ['Prakasam','Andhra Pradesh'],
        523002: ['Prakasam','Andhra Pradesh'],
        523101: ['Prakasam','Andhra Pradesh'],
        523104: ['Prakasam','Andhra Pradesh'],
        523105: ['Prakasam','Andhra Pradesh'],
        523108: ['Prakasam','Andhra Pradesh'],
        523109: ['Prakasam','Andhra Pradesh'],
        523110: ['Prakasam','Andhra Pradesh'],
        523111: ['Prakasam','Andhra Pradesh'],
        523112: ['Prakasam','Andhra Pradesh'],
        523113: ['Prakasam','Andhra Pradesh'],
        523114: ['Prakasam','Andhra Pradesh'],
        523115: ['Prakasam','Andhra Pradesh'],
        523116: ['Prakasam','Andhra Pradesh'],
        523117: ['Prakasam','Andhra Pradesh'],
        523135: ['Prakasam','Andhra Pradesh'],
        523155: ['Prakasam','Andhra Pradesh'],
        523156: ['Prakasam','Andhra Pradesh'],
        523157: ['Prakasam','Andhra Pradesh'],
        523165: ['Prakasam','Andhra Pradesh'],
        523166: ['Prakasam','Andhra Pradesh'],
        523167: ['Prakasam','Andhra Pradesh'],
        523168: ['Prakasam','Andhra Pradesh'],
        523169: ['Prakasam','Andhra Pradesh'],
        523170: ['Prakasam','Andhra Pradesh'],
        523171: ['Prakasam','Andhra Pradesh'],
        523180: ['Prakasam','Andhra Pradesh'],
        523181: ['Prakasam','Andhra Pradesh'],
        523182: ['Prakasam','Andhra Pradesh'],
        523183: ['Prakasam','Andhra Pradesh'],
        523184: ['Prakasam','Andhra Pradesh'],
        523185: ['Prakasam','Andhra Pradesh'],
        523186: ['Prakasam','Andhra Pradesh'],
        523187: ['Prakasam','Andhra Pradesh'],
        523190: ['Prakasam','Andhra Pradesh'],
        523201: ['Prakasam','Andhra Pradesh'],
        523211: ['Prakasam','Andhra Pradesh'],
        523212: ['Prakasam','Andhra Pradesh'],
        523213: ['Prakasam','Andhra Pradesh'],
        523214: ['Prakasam','Andhra Pradesh'],
        523223: ['Prakasam','Andhra Pradesh'],
        523224: ['Prakasam','Andhra Pradesh'],
        523225: ['Prakasam','Andhra Pradesh'],
        523226: ['Prakasam','Andhra Pradesh'],
        523227: ['Prakasam','Andhra Pradesh'],
        523228: ['Prakasam','Andhra Pradesh'],
        523230: ['Prakasam','Andhra Pradesh'],
        523240: ['Prakasam','Andhra Pradesh'],
        523241: ['Prakasam','Andhra Pradesh'],
        523245: ['Prakasam','Andhra Pradesh'],
        523246: ['Prakasam','Andhra Pradesh'],
        523247: ['Prakasam','Andhra Pradesh'],
        523252: ['Prakasam','Andhra Pradesh'],
        523253: ['Prakasam','Andhra Pradesh'],
        523254: ['Prakasam','Andhra Pradesh'],
        523260: ['Prakasam','Andhra Pradesh'],
        523261: ['Prakasam','Andhra Pradesh'],
        523262: ['Prakasam','Andhra Pradesh'],
        523263: ['Prakasam','Andhra Pradesh'],
        523264: ['Prakasam','Andhra Pradesh'],
        523265: ['Prakasam','Andhra Pradesh'],
        523270: ['Prakasam','Andhra Pradesh'],
        523271: ['Prakasam','Andhra Pradesh'],
        523272: ['Prakasam','Andhra Pradesh'],
        523273: ['Prakasam','Andhra Pradesh'],
        523274: ['Prakasam','Andhra Pradesh'],
        523279: ['Prakasam','Andhra Pradesh'],
        523280: ['Prakasam','Andhra Pradesh'],
        523281: ['Prakasam','Andhra Pradesh'],
        523286: ['Prakasam','Andhra Pradesh'],
        523291: ['Prakasam','Andhra Pradesh'],
        523292: ['Prakasam','Andhra Pradesh'],
        523301: ['Prakasam','Andhra Pradesh'],
        523302: ['Prakasam','Andhra Pradesh'],
        523303: ['Prakasam','Andhra Pradesh'],
        523304: ['Prakasam','Andhra Pradesh'],
        523305: ['Prakasam','Andhra Pradesh'],
        523315: ['Prakasam','Andhra Pradesh'],
        523316: ['Prakasam','Andhra Pradesh'],
        523320: ['Prakasam','Andhra Pradesh'],
        523326: ['Prakasam','Andhra Pradesh'],
        523327: ['Prakasam','Andhra Pradesh'],
        523328: ['Prakasam','Andhra Pradesh'],
        523329: ['Prakasam','Andhra Pradesh'],
        523330: ['Prakasam','Andhra Pradesh'],
        523331: ['Prakasam','Andhra Pradesh'],
        523332: ['Prakasam','Andhra Pradesh'],
        523333: ['Prakasam','Andhra Pradesh'],
        523334: ['Prakasam','Andhra Pradesh'],
        523335: ['Prakasam','Andhra Pradesh'],
        523336: ['Prakasam','Andhra Pradesh'],
        523346: ['Prakasam','Andhra Pradesh'],
        523356: ['Prakasam','Andhra Pradesh'],
        523357: ['Prakasam','Andhra Pradesh'],
        523367: ['Prakasam','Andhra Pradesh'],
        523368: ['Prakasam','Andhra Pradesh'],
        523369: ['Prakasam','Andhra Pradesh'],
        523370: ['Prakasam','Andhra Pradesh'],
        523371: ['Prakasam','Andhra Pradesh'],
        523372: ['Prakasam','Andhra Pradesh'],
        523373: ['Prakasam','Andhra Pradesh'],
        524001: ['Nellore','Andhra Pradesh'],
        524002: ['Nellore','Andhra Pradesh'],
        524003: ['Nellore','Andhra Pradesh'],
        524004: ['Nellore','Andhra Pradesh'],
        524005: ['Nellore','Andhra Pradesh'],
        524101: ['Nellore','Andhra Pradesh'],
        524121: ['Nellore','Andhra Pradesh'],
        524123: ['Nellore','Andhra Pradesh'],
        524124: ['Nellore','Andhra Pradesh'],
        524126: ['Nellore','Andhra Pradesh'],
        524127: ['Nellore','Andhra Pradesh'],
        524129: ['Nellore','Andhra Pradesh'],
        524131: ['Nellore','Andhra Pradesh'],
        524132: ['Nellore','Andhra Pradesh'],
        524134: ['Nellore','Andhra Pradesh'],
        524137: ['Nellore','Andhra Pradesh'],
        524142: ['Nellore','Andhra Pradesh'],
        524152: ['Nellore','Andhra Pradesh'],
        524201: ['Nellore','Andhra Pradesh'],
        524203: ['Nellore','Andhra Pradesh'],
        524221: ['Nellore','Andhra Pradesh'],
        524222: ['Nellore','Andhra Pradesh'],
        524223: ['Nellore','Andhra Pradesh'],
        524224: ['Nellore','Andhra Pradesh'],
        524225: ['Nellore','Andhra Pradesh'],
        524226: ['Nellore','Andhra Pradesh'],
        524227: ['Nellore','Andhra Pradesh'],
        524228: ['Nellore','Andhra Pradesh'],
        524230: ['Nellore','Andhra Pradesh'],
        524234: ['Nellore','Andhra Pradesh'],
        524236: ['Nellore','Andhra Pradesh'],
        524239: ['Nellore','Andhra Pradesh'],
        524240: ['Nellore','Andhra Pradesh'],
        524301: ['Nellore','Andhra Pradesh'],
        524302: ['Nellore','Andhra Pradesh'],
        524303: ['Nellore','Andhra Pradesh'],
        524304: ['Nellore','Andhra Pradesh'],
        524305: ['Nellore','Andhra Pradesh'],
        524306: ['Nellore','Andhra Pradesh'],
        524307: ['Nellore','Andhra Pradesh'],
        524308: ['Nellore','Andhra Pradesh'],
        524309: ['Nellore','Andhra Pradesh'],
        524310: ['Nellore','Andhra Pradesh'],
        524311: ['Nellore','Andhra Pradesh'],
        524312: ['Nellore','Andhra Pradesh'],
        524313: ['Nellore','Andhra Pradesh'],
        524314: ['Nellore','Andhra Pradesh'],
        524315: ['Nellore','Andhra Pradesh'],
        524316: ['Nellore','Andhra Pradesh'],
        524317: ['Nellore','Andhra Pradesh'],
        524318: ['Nellore','Andhra Pradesh'],
        524319: ['Nellore','Andhra Pradesh'],
        524320: ['Nellore','Andhra Pradesh'],
        524321: ['Nellore','Andhra Pradesh'],
        524322: ['Nellore','Andhra Pradesh'],
        524323: ['Nellore','Andhra Pradesh'],
        524341: ['Nellore','Andhra Pradesh'],
        524342: ['Nellore','Andhra Pradesh'],
        524343: ['Nellore','Andhra Pradesh'],
        524344: ['Nellore','Andhra Pradesh'],
        524345: ['Nellore','Andhra Pradesh'],
        524346: ['Nellore','Andhra Pradesh'],
        524366: ['Nellore','Andhra Pradesh'],
        524401: ['Nellore','Andhra Pradesh'],
        524402: ['Nellore','Andhra Pradesh'],
        524403: ['Nellore','Andhra Pradesh'],
        524404: ['Nellore','Andhra Pradesh'],
        524405: ['Nellore','Andhra Pradesh'],
        524406: ['Nellore','Andhra Pradesh'],
        524407: ['Nellore','Andhra Pradesh'],
        524408: ['Nellore','Andhra Pradesh'],
        524409: ['Nellore','Andhra Pradesh'],
        524410: ['Nellore','Andhra Pradesh'],
        524411: ['Nellore','Andhra Pradesh'],
        524412: ['Nellore','Andhra Pradesh'],
        524413: ['Nellore','Andhra Pradesh'],
        524414: ['Nellore','Andhra Pradesh'],
        524415: ['Nellore','Andhra Pradesh'],
        524421: ['Nellore','Andhra Pradesh'],
        532001: ['Srikakulam','Andhra Pradesh'],
        532005: ['Srikakulam','Andhra Pradesh'],
        532122: ['Srikakulam','Andhra Pradesh'],
        532123: ['Srikakulam','Andhra Pradesh'],
        532127: ['Srikakulam','Andhra Pradesh'],
        532148: ['Srikakulam','Andhra Pradesh'],
        532168: ['Srikakulam','Andhra Pradesh'],
        532185: ['Srikakulam','Andhra Pradesh'],
        532186: ['Srikakulam','Andhra Pradesh'],
        532190: ['Srikakulam','Andhra Pradesh'],
        532195: ['Srikakulam','Andhra Pradesh'],
        532201: ['Srikakulam','Andhra Pradesh'],
        532203: ['Srikakulam','Andhra Pradesh'],
        532211: ['Srikakulam','Andhra Pradesh'],
        532212: ['Srikakulam','Andhra Pradesh'],
        532213: ['Srikakulam','Andhra Pradesh'],
        532214: ['Srikakulam','Andhra Pradesh'],
        532215: ['Srikakulam','Andhra Pradesh'],
        532216: ['Srikakulam','Andhra Pradesh'],
        532218: ['Srikakulam','Andhra Pradesh'],
        532219: ['Srikakulam','Andhra Pradesh'],
        532220: ['Srikakulam','Andhra Pradesh'],
        532221: ['Srikakulam','Andhra Pradesh'],
        532222: ['Srikakulam','Andhra Pradesh'],
        532242: ['Srikakulam','Andhra Pradesh'],
        532243: ['Srikakulam','Andhra Pradesh'],
        532263: ['Srikakulam','Andhra Pradesh'],
        532264: ['Srikakulam','Andhra Pradesh'],
        532284: ['Srikakulam','Andhra Pradesh'],
        532290: ['Srikakulam','Andhra Pradesh'],
        532291: ['Srikakulam','Andhra Pradesh'],
        532292: ['Srikakulam','Andhra Pradesh'],
        532312: ['Srikakulam','Andhra Pradesh'],
        532322: ['Srikakulam','Andhra Pradesh'],
        532401: ['Srikakulam','Andhra Pradesh'],
        532402: ['Srikakulam','Andhra Pradesh'],
        532403: ['Srikakulam','Andhra Pradesh'],
        532404: ['Srikakulam','Andhra Pradesh'],
        532405: ['Srikakulam','Andhra Pradesh'],
        532406: ['Srikakulam','Andhra Pradesh'],
        532407: ['Srikakulam','Andhra Pradesh'],
        532408: ['Srikakulam','Andhra Pradesh'],
        532409: ['Srikakulam','Andhra Pradesh'],
        532410: ['Srikakulam','Andhra Pradesh'],
        532421: ['Srikakulam','Andhra Pradesh'],
        532425: ['Srikakulam','Andhra Pradesh'],
        532426: ['Srikakulam','Andhra Pradesh'],
        532427: ['Srikakulam','Andhra Pradesh'],
        532428: ['Srikakulam','Andhra Pradesh'],
        532429: ['Srikakulam','Andhra Pradesh'],
        532430: ['Srikakulam','Andhra Pradesh'],
        532432: ['Srikakulam','Andhra Pradesh'],
        532440: ['Srikakulam','Andhra Pradesh'],
        532443: ['Srikakulam','Andhra Pradesh'],
        532445: ['Srikakulam','Andhra Pradesh'],
        532455: ['Srikakulam','Andhra Pradesh'],
        532456: ['Srikakulam','Andhra Pradesh'],
        532457: ['Srikakulam','Andhra Pradesh'],
        532458: ['Srikakulam','Andhra Pradesh'],
        532459: ['Srikakulam','Andhra Pradesh'],
        532460: ['Srikakulam','Andhra Pradesh'],
        532461: ['Srikakulam','Andhra Pradesh'],
        532462: ['Srikakulam','Andhra Pradesh'],
        532474: ['Srikakulam','Andhra Pradesh'],
        532484: ['Srikakulam','Andhra Pradesh'],
        533001: ['East Godavari','Andhra Pradesh'],
        533002: ['East Godavari','Andhra Pradesh'],
        533003: ['East Godavari','Andhra Pradesh'],
        533004: ['East Godavari','Andhra Pradesh'],
        533005: ['East Godavari','Andhra Pradesh'],
        533006: ['East Godavari','Andhra Pradesh'],
        533016: ['East Godavari','Andhra Pradesh'],
        533101: ['East Godavari','Andhra Pradesh'],
        533102: ['East Godavari','Andhra Pradesh'],
        533103: ['East Godavari','Andhra Pradesh'],
        533104: ['East Godavari','Andhra Pradesh'],
        533105: ['East Godavari','Andhra Pradesh'],
        533106: ['East Godavari','Andhra Pradesh'],
        533107: ['East Godavari','Andhra Pradesh'],
        533124: ['East Godavari','Andhra Pradesh'],
        533125: ['East Godavari','Andhra Pradesh'],
        533126: ['East Godavari','Andhra Pradesh'],
        533201: ['East Godavari','Andhra Pradesh'],
        533210: ['East Godavari','Andhra Pradesh'],
        533211: ['East Godavari','Andhra Pradesh'],
        533212: ['East Godavari','Andhra Pradesh'],
        533213: ['East Godavari','Andhra Pradesh'],
        533214: ['East Godavari','Andhra Pradesh'],
        533215: ['East Godavari','Andhra Pradesh'],
        533216: ['East Godavari','Andhra Pradesh'],
        533217: ['East Godavari','Andhra Pradesh'],
        533218: ['East Godavari','Andhra Pradesh'],
        533220: ['East Godavari','Andhra Pradesh'],
        533221: ['East Godavari','Andhra Pradesh'],
        533222: ['East Godavari','Andhra Pradesh'],
        533223: ['East Godavari','Andhra Pradesh'],
        533228: ['East Godavari','Andhra Pradesh'],
        533229: ['East Godavari','Andhra Pradesh'],
        533232: ['East Godavari','Andhra Pradesh'],
        533233: ['East Godavari','Andhra Pradesh'],
        533234: ['East Godavari','Andhra Pradesh'],
        533235: ['East Godavari','Andhra Pradesh'],
        533236: ['East Godavari','Andhra Pradesh'],
        533237: ['East Godavari','Andhra Pradesh'],
        533238: ['East Godavari','Andhra Pradesh'],
        533239: ['East Godavari','Andhra Pradesh'],
        533240: ['East Godavari','Andhra Pradesh'],
        533241: ['East Godavari','Andhra Pradesh'],
        533242: ['East Godavari','Andhra Pradesh'],
        533244: ['East Godavari','Andhra Pradesh'],
        533247: ['East Godavari','Andhra Pradesh'],
        533248: ['East Godavari','Andhra Pradesh'],
        533249: ['East Godavari','Andhra Pradesh'],
        533250: ['East Godavari','Andhra Pradesh'],
        533251: ['East Godavari','Andhra Pradesh'],
        533252: ['East Godavari','Andhra Pradesh'],
        533253: ['East Godavari','Andhra Pradesh'],
        533254: ['East Godavari','Andhra Pradesh'],
        533255: ['East Godavari','Andhra Pradesh'],
        533256: ['East Godavari','Andhra Pradesh'],
        533260: ['East Godavari','Andhra Pradesh'],
        533261: ['East Godavari','Andhra Pradesh'],
        533262: ['East Godavari','Andhra Pradesh'],
        533263: ['East Godavari','Andhra Pradesh'],
        533264: ['East Godavari','Andhra Pradesh'],
        533274: ['East Godavari','Andhra Pradesh'],
        533284: ['East Godavari','Andhra Pradesh'],
        533285: ['East Godavari','Andhra Pradesh'],
        533286: ['East Godavari','Andhra Pradesh'],
        533287: ['East Godavari','Andhra Pradesh'],
        533288: ['East Godavari','Andhra Pradesh'],
        533289: ['East Godavari','Andhra Pradesh'],
        533290: ['East Godavari','Andhra Pradesh'],
        533291: ['East Godavari','Andhra Pradesh'],
        533292: ['East Godavari','Andhra Pradesh'],
        533293: ['East Godavari','Andhra Pradesh'],
        533294: ['East Godavari','Andhra Pradesh'],
        533295: ['East Godavari','Andhra Pradesh'],
        533296: ['East Godavari','Andhra Pradesh'],
        533297: ['East Godavari','Andhra Pradesh'],
        533305: ['East Godavari','Andhra Pradesh'],
        533306: ['East Godavari','Andhra Pradesh'],
        533307: ['East Godavari','Andhra Pradesh'],
        533308: ['East Godavari','Andhra Pradesh'],
        533309: ['East Godavari','Andhra Pradesh'],
        533339: ['East Godavari','Andhra Pradesh'],
        533340: ['East Godavari','Andhra Pradesh'],
        533341: ['East Godavari','Andhra Pradesh'],
        533342: ['East Godavari','Andhra Pradesh'],
        533343: ['East Godavari','Andhra Pradesh'],
        533344: ['East Godavari','Andhra Pradesh'],
        533345: ['East Godavari','Andhra Pradesh'],
        533346: ['East Godavari','Andhra Pradesh'],
        533401: ['East Godavari','Andhra Pradesh'],
        533406: ['East Godavari','Andhra Pradesh'],
        533407: ['East Godavari','Andhra Pradesh'],
        533408: ['East Godavari','Andhra Pradesh'],
        533428: ['East Godavari','Andhra Pradesh'],
        533429: ['East Godavari','Andhra Pradesh'],
        533430: ['East Godavari','Andhra Pradesh'],
        533431: ['East Godavari','Andhra Pradesh'],
        533432: ['East Godavari','Andhra Pradesh'],
        533433: ['East Godavari','Andhra Pradesh'],
        533434: ['East Godavari','Andhra Pradesh'],
        533435: ['East Godavari','Andhra Pradesh'],
        533436: ['East Godavari','Andhra Pradesh'],
        533437: ['East Godavari','Andhra Pradesh'],
        533440: ['East Godavari','Andhra Pradesh'],
        533444: ['East Godavari','Andhra Pradesh'],
        533445: ['East Godavari','Andhra Pradesh'],
        533446: ['East Godavari','Andhra Pradesh'],
        533447: ['East Godavari','Andhra Pradesh'],
        533448: ['East Godavari','Andhra Pradesh'],
        533449: ['East Godavari','Andhra Pradesh'],
        533450: ['East Godavari','Andhra Pradesh'],
        533461: ['East Godavari','Andhra Pradesh'],
        533462: ['East Godavari','Andhra Pradesh'],
        533463: ['East Godavari','Andhra Pradesh'],
        533464: ['East Godavari','Andhra Pradesh'],
        533468: ['East Godavari','Andhra Pradesh'],
        533483: ['East Godavari','Andhra Pradesh'],
        533577: ['East Godavari','Andhra Pradesh'],
        534001: ['West Godavari','Andhra Pradesh'],
        534002: ['West Godavari','Andhra Pradesh'],
        534003: ['West Godavari','Andhra Pradesh'],
        534004: ['West Godavari','Andhra Pradesh'],
        534005: ['West Godavari','Andhra Pradesh'],
        534006: ['West Godavari','Andhra Pradesh'],
        534007: ['West Godavari','Andhra Pradesh'],
        534101: ['West Godavari','Andhra Pradesh'],
        534102: ['West Godavari','Andhra Pradesh'],
        534111: ['West Godavari','Andhra Pradesh'],
        534112: ['West Godavari','Andhra Pradesh'],
        534122: ['West Godavari','Andhra Pradesh'],
        534123: ['West Godavari','Andhra Pradesh'],
        534124: ['West Godavari','Andhra Pradesh'],
        534126: ['West Godavari','Andhra Pradesh'],
        534134: ['West Godavari','Andhra Pradesh'],
        534145: ['West Godavari','Andhra Pradesh'],
        534146: ['West Godavari','Andhra Pradesh'],
        534156: ['West Godavari','Andhra Pradesh'],
        534165: ['West Godavari','Andhra Pradesh'],
        534166: ['West Godavari','Andhra Pradesh'],
        534176: ['West Godavari','Andhra Pradesh'],
        534186: ['West Godavari','Andhra Pradesh'],
        534195: ['West Godavari','Andhra Pradesh'],
        534196: ['West Godavari','Andhra Pradesh'],
        534197: ['West Godavari','Andhra Pradesh'],
        534198: ['Warangal','Andhra Pradesh'],
        534199: ['West Godavari','Andhra Pradesh'],
        534201: ['West Godavari','Andhra Pradesh'],
        534202: ['West Godavari','Andhra Pradesh'],
        534204: ['West Godavari','Andhra Pradesh'],
        534206: ['West Godavari','Andhra Pradesh'],
        534207: ['West Godavari','Andhra Pradesh'],
        534208: ['West Godavari','Andhra Pradesh'],
        534209: ['West Godavari','Andhra Pradesh'],
        534210: ['West Godavari','Andhra Pradesh'],
        534211: ['West Godavari','Andhra Pradesh'],
        534215: ['West Godavari','Andhra Pradesh'],
        534216: ['West Godavari','Andhra Pradesh'],
        534217: ['West Godavari','Andhra Pradesh'],
        534218: ['West Godavari','Andhra Pradesh'],
        534222: ['West Godavari','Andhra Pradesh'],
        534225: ['West Godavari','Andhra Pradesh'],
        534227: ['West Godavari','Andhra Pradesh'],
        534230: ['West Godavari','Andhra Pradesh'],
        534235: ['West Godavari','Andhra Pradesh'],
        534236: ['West Godavari','Andhra Pradesh'],
        534237: ['West Godavari','Andhra Pradesh'],
        534238: ['West Godavari','Andhra Pradesh'],
        534239: ['West Godavari','Andhra Pradesh'],
        534240: ['West Godavari','Andhra Pradesh'],
        534243: ['West Godavari','Andhra Pradesh'],
        534244: ['West Godavari','Andhra Pradesh'],
        534245: ['West Godavari','Andhra Pradesh'],
        534247: ['West Godavari','Andhra Pradesh'],
        534250: ['West Godavari','Andhra Pradesh'],
        534260: ['West Godavari','Andhra Pradesh'],
        534265: ['West Godavari','Andhra Pradesh'],
        534266: ['West Godavari','Andhra Pradesh'],
        534267: ['West Godavari','Andhra Pradesh'],
        534268: ['West Godavari','Andhra Pradesh'],
        534269: ['West Godavari','Andhra Pradesh'],
        534275: ['West Godavari','Andhra Pradesh'],
        534280: ['West Godavari','Andhra Pradesh'],
        534281: ['West Godavari','Andhra Pradesh'],
        534301: ['West Godavari','Andhra Pradesh'],
        534302: ['West Godavari','Andhra Pradesh'],
        534305: ['West Godavari','Andhra Pradesh'],
        534311: ['West Godavari','Andhra Pradesh'],
        534312: ['West Godavari','Andhra Pradesh'],
        534313: ['West Godavari','Andhra Pradesh'],
        534315: ['West Godavari','Andhra Pradesh'],
        534316: ['West Godavari','Andhra Pradesh'],
        534318: ['West Godavari','Andhra Pradesh'],
        534320: ['West Godavari','Andhra Pradesh'],
        534324: ['West Godavari','Andhra Pradesh'],
        534326: ['West Godavari','Andhra Pradesh'],
        534327: ['West Godavari','Andhra Pradesh'],
        534328: ['West Godavari','Andhra Pradesh'],
        534329: ['West Godavari','Andhra Pradesh'],
        534330: ['West Godavari','Andhra Pradesh'],
        534331: ['West Godavari','Andhra Pradesh'],
        534338: ['West Godavari','Andhra Pradesh'],
        534340: ['Kovvur','Andhra Pradesh'],
        534341: ['West Godavari','Andhra Pradesh'],
        534342: ['West Godavari','Andhra Pradesh'],
        534350: ['West Godavari','Andhra Pradesh'],
        534401: ['West Godavari','Andhra Pradesh'],
        534406: ['West Godavari','Andhra Pradesh'],
        534411: ['West Godavari','Andhra Pradesh'],
        534416: ['West Godavari','Andhra Pradesh'],
        534425: ['West Godavari','Andhra Pradesh'],
        534426: ['West Godavari','Andhra Pradesh'],
        534427: ['West Godavari','Andhra Pradesh'],
        534432: ['West Godavari','Andhra Pradesh'],
        534437: ['West Godavari','Andhra Pradesh'],
        534442: ['West Godavari','Andhra Pradesh'],
        534447: ['West Godavari','Andhra Pradesh'],
        534448: ['West Godavari','Andhra Pradesh'],
        534449: ['West Godavari','Andhra Pradesh'],
        534450: ['West Godavari','Andhra Pradesh'],
        534451: ['West Godavari','Andhra Pradesh'],
        534452: ['West Godavari','Andhra Pradesh'],
        534455: ['West Godavari','Andhra Pradesh'],
        534456: ['West Godavari','Andhra Pradesh'],
        534460: ['West Godavari','Andhra Pradesh'],
        534461: ['West Godavari','Andhra Pradesh'],
        534462: ['West Godavari','Andhra Pradesh'],
        534467: ['West Godavari','Andhra Pradesh'],
        534475: ['West Godavari','Andhra Pradesh'],
        535001: ['Vizianagaram','Andhra Pradesh'],
        535002: ['Vizianagaram','Andhra Pradesh'],
        535003: ['Vizianagaram','Andhra Pradesh'],
        535004: ['Vizianagaram','Andhra Pradesh'],
        535006: ['Vizianagaram','Andhra Pradesh'],
        535101: ['Vizianagaram','Andhra Pradesh'],
        535102: ['Vizianagaram','Andhra Pradesh'],
        535124: ['Vizianagaram','Andhra Pradesh'],
        535125: ['Vizianagaram','Andhra Pradesh'],
        535126: ['Vizianagaram','Andhra Pradesh'],
        535128: ['Vizianagaram','Andhra Pradesh'],
        535148: ['Vizianagaram','Andhra Pradesh'],
        535160: ['Vizianagaram','Andhra Pradesh'],
        535161: ['Vizianagaram','Andhra Pradesh'],
        535183: ['Vizianagaram','Andhra Pradesh'],
        535204: ['Vizianagaram','Andhra Pradesh'],
        535213: ['Vizianagaram','Andhra Pradesh'],
        535214: ['Vizianagaram','Andhra Pradesh'],
        535215: ['Vizianagaram','Andhra Pradesh'],
        535216: ['Vizianagaram','Andhra Pradesh'],
        535217: ['Vizianagaram','Andhra Pradesh'],
        535218: ['Vizianagaram','Andhra Pradesh'],
        535220: ['Vizianagaram','Andhra Pradesh'],
        535221: ['Vizianagaram','Andhra Pradesh'],
        535240: ['Vizianagaram','Andhra Pradesh'],
        535250: ['Vizianagaram','Andhra Pradesh'],
        535260: ['Vizianagaram','Andhra Pradesh'],
        535270: ['Vizianagaram','Andhra Pradesh'],
        535273: ['Vizianagaram','Andhra Pradesh'],
        535280: ['Vizianagaram','Andhra Pradesh'],
        535281: ['Vizianagaram','Andhra Pradesh'],
        535463: ['Vizianagaram','Andhra Pradesh'],
        535501: ['Vizianagaram','Andhra Pradesh'],
        535502: ['Vizianagaram','Andhra Pradesh'],
        535521: ['Vizianagaram','Andhra Pradesh'],
        535522: ['Vizianagaram','Andhra Pradesh'],
        535523: ['Vizianagaram','Andhra Pradesh'],
        535524: ['Vizianagaram','Andhra Pradesh'],
        535525: ['Vizianagaram','Andhra Pradesh'],
        535526: ['Vizianagaram','Andhra Pradesh'],
        535527: ['Vizianagaram','Andhra Pradesh'],
        535534: ['Vizianagaram','Andhra Pradesh'],
        535546: ['Vizianagaram','Andhra Pradesh'],
        535547: ['Vizianagaram','Andhra Pradesh'],
        535557: ['Vizianagaram','Andhra Pradesh'],
        535558: ['Vizianagaram','Andhra Pradesh'],
        535559: ['Vizianagaram','Andhra Pradesh'],
        535568: ['Vizianagaram','Andhra Pradesh'],
        535573: ['Vizianagaram','Andhra Pradesh'],
        535578: ['Vizianagaram','Andhra Pradesh'],
        535579: ['Vizianagaram','Andhra Pradesh'],
        535580: ['Vizianagaram','Andhra Pradesh'],
        535581: ['Vizianagaram','Andhra Pradesh'],
        535591: ['Vizianagaram','Andhra Pradesh'],
        535592: ['Vizianagaram','Andhra Pradesh'],
        535593: ['Vizianagaram','Andhra Pradesh'],
        535594: ['Vizianagaram','Andhra Pradesh'],
        561202: ['Tumakuru','Karnataka'],
        561206: ['Chikkaballapur','Karnataka'],
        561207: ['Chikkaballapur','Karnataka'],
        561208: ['Chikkaballapur','Karnataka'],
        561209: ['Chikkaballapur','Karnataka'],
        561210: ['Chikkaballapur','Karnataka'],
        561211: ['Chikkaballapur','Karnataka'],
        561212: ['Chikkaballapur','Karnataka'],
        561213: ['Chikkaballapur','Karnataka'],
        561228: ['Chikkaballapur','Karnataka'],
        562102: ['Chikkaballapur','Karnataka'],
        562103: ['Chikkaballapur','Karnataka'],
        562104: ['Chikkaballapur','Karnataka'],
        562105: ['Chikkaballapur','Karnataka'],
        562159: ['Ramanagar','Karnataka'],
        562160: ['Ramanagar','Karnataka'],
        562161: ['Ramanagar','Karnataka'],
        563101: ['Kodagu','Karnataka'],
        563102: ['Kolar','Karnataka'],
        563103: ['Kolar','Karnataka'],
        563113: ['Chikkaballapur','Karnataka'],
        563114: ['Kolar','Karnataka'],
        563115: ['Kolar','Karnataka'],
        563116: ['Kolar','Karnataka'],
        563117: ['Kolar','Karnataka'],
        563118: ['Kolar','Karnataka'],
        563119: ['Chikkaballapur','Karnataka'],
        563120: ['Kolar','Karnataka'],
        563121: ['Kolar','Karnataka'],
        563122: ['Kolar','Karnataka'],
        563123: ['Chikkaballapur','Karnataka'],
        563124: ['Chikkaballapur','Karnataka'],
        563125: ['Chikkaballapur','Karnataka'],
        563126: ['Kolar','Karnataka'],
        563127: ['Kolar','Karnataka'],
        563128: ['Chikkaballapur','Karnataka'],
        563129: ['Kolar','Karnataka'],
        563130: ['Kolar','Karnataka'],
        563131: ['Kolar','Karnataka'],
        563132: ['Kolar','Karnataka'],
        563133: ['Kolar','Karnataka'],
        563134: ['Kolar','Karnataka'],
        563135: ['Kolar','Karnataka'],
        563136: ['Kolar','Karnataka'],
        563137: ['Kolar','Karnataka'],
        563138: ['Kolar','Karnataka'],
        563139: ['Kolar','Karnataka'],
        563146: ['Chikkaballapur','Karnataka'],
        563147: ['Kolar','Karnataka'],
        563157: ['Kolar','Karnataka'],
        563159: ['Chikkaballapur','Karnataka'],
        563160: ['Kolar','Karnataka'],
        563161: ['Kolar','Karnataka'],
        563162: ['Kolar','Karnataka'],
        563163: ['Kolar','Karnataka'],
        570032: ['Mysuru','Karnataka'],
        570033: ['Mysuru','Karnataka'],
        570034: ['Mysuru','Karnataka'],
        571201: ['Athani','Karnataka'],
        571211: ['Kodagu','Karnataka'],
        571212: ['Kodagu','Karnataka'],
        571213: ['Kodagu','Karnataka'],
        571214: ['Kodagu','Karnataka'],
        571215: ['Kodagu','Karnataka'],
        571216: ['Kodagu','Karnataka'],
        571217: ['Kodagu','Karnataka'],
        571218: ['Kodagu','Karnataka'],
        571219: ['Kodagu','Karnataka'],
        571231: ['Kodagu','Karnataka'],
        571232: ['Kodagu','Karnataka'],
        571234: ['Kodagu','Karnataka'],
        571235: ['Kodagu','Karnataka'],
        571236: ['Kodagu','Karnataka'],
        571237: ['Kodagu','Karnataka'],
        571247: ['Kodagu','Karnataka'],
        571248: ['Kodagu','Karnataka'],
        571249: ['Kodagu','Karnataka'],
        571250: ['Kodagu','Karnataka'],
        571251: ['Kodagu','Karnataka'],
        571252: ['Kodagu','Karnataka'],
        571253: ['Kodagu','Karnataka'],
        571254: ['Kodagu','Karnataka'],
        571320: ['Nanjangud','Karnataka'],
        571342: ['Chamrajnagar','Karnataka'],
        571401: ['Mandya','Karnataka'],
        571402: ['Mandya','Karnataka'],
        571403: ['Mandya','Karnataka'],
        571404: ['Mandya','Karnataka'],
        571405: ['Mandya','Karnataka'],
        571416: ['Mandya','Karnataka'],
        571418: ['Mandya','Karnataka'],
        571419: ['Mandya','Karnataka'],
        571421: ['Mandya','Karnataka'],
        571422: ['Mandya','Karnataka'],
        571423: ['Mandya','Karnataka'],
        571424: ['Mandya','Karnataka'],
        571425: ['Mandya','Karnataka'],
        571427: ['Mandya','Karnataka'],
        571428: ['Mandya','Karnataka'],
        571429: ['Mandya','Karnataka'],
        571430: ['Mandya','Karnataka'],
        571431: ['Mandya','Karnataka'],
        571432: ['Mandya','Karnataka'],
        571433: ['Mandya','Karnataka'],
        571434: ['Mandya','Karnataka'],
        571435: ['Mandya','Karnataka'],
        571438: ['Mandya','Karnataka'],
        571443: ['Chamrajnagar','Karnataka'],
        571445: ['Mandya','Karnataka'],
        571446: ['Mandya','Karnataka'],
        571448: ['Mandya','Karnataka'],
        571450: ['Mandya','Karnataka'],
        571455: ['Mandya','Karnataka'],
        571463: ['Mandya','Karnataka'],
        571475: ['Mandya','Karnataka'],
        571606: ['Mandya','Karnataka'],
        571802: ['Mandya','Karnataka'],
        571807: ['Mandya','Karnataka'],
        572101: ['Tumakuru','Karnataka'],
        572102: ['Tumakuru','Karnataka'],
        572103: ['Tumakuru','Karnataka'],
        572104: ['Tumakuru','Karnataka'],
        572105: ['Tumakuru','Karnataka'],
        572106: ['Tumakuru','Karnataka'],
        572107: ['Tumakuru','Karnataka'],
        572111: ['Tumakuru','Karnataka'],
        572112: ['Tumakuru','Karnataka'],
        572113: ['Tumakuru','Karnataka'],
        572114: ['Tumakuru','Karnataka'],
        572115: ['Tumakuru','Karnataka'],
        572116: ['Tumakuru','Karnataka'],
        572117: ['Tumakuru','Karnataka'],
        572118: ['Tumakuru','Karnataka'],
        572119: ['Tumakuru','Karnataka'],
        572120: ['Tumakuru','Karnataka'],
        572121: ['Tumakuru','Karnataka'],
        572122: ['Tumakuru','Karnataka'],
        572123: ['Tumakuru','Karnataka'],
        572124: ['Tumakuru','Karnataka'],
        572125: ['Tumakuru','Karnataka'],
        572126: ['Tumakuru','Karnataka'],
        572127: ['Tumakuru','Karnataka'],
        572128: ['Tumakuru','Karnataka'],
        572129: ['Tumakuru','Karnataka'],
        572130: ['Tumakuru','Karnataka'],
        572132: ['Tumakuru','Karnataka'],
        572133: ['Tumakuru','Karnataka'],
        572134: ['Tumakuru','Karnataka'],
        572135: ['Tumakuru','Karnataka'],
        572136: ['Tumakuru','Karnataka'],
        572137: ['Tumakuru','Karnataka'],
        572138: ['Tumakuru','Karnataka'],
        572139: ['Tumakuru','Karnataka'],
        572140: ['Tumakuru','Karnataka'],
        572141: ['Tumakuru','Karnataka'],
        572142: ['Tumakuru','Karnataka'],
        572168: ['Tumakuru','Karnataka'],
        572175: ['Tumakuru','Karnataka'],
        572201: ['Tumakuru','Karnataka'],
        572211: ['Tumakuru','Karnataka'],
        572212: ['Tumakuru','Karnataka'],
        572213: ['Tumakuru','Karnataka'],
        572214: ['Tumakuru','Karnataka'],
        572215: ['Tumakuru','Karnataka'],
        572216: ['Tumakuru','Karnataka'],
        572217: ['Tumakuru','Karnataka'],
        572218: ['Tumakuru','Karnataka'],
        572219: ['Tumakuru','Karnataka'],
        572220: ['Tumakuru','Karnataka'],
        572221: ['Tumakuru','Karnataka'],
        572222: ['Tumakuru','Karnataka'],
        572223: ['Tumakuru','Karnataka'],
        572224: ['Tumakuru','Karnataka'],
        572225: ['Tumakuru','Karnataka'],
        572226: ['Tumakuru','Karnataka'],
        572227: ['Tumakuru','Karnataka'],
        572228: ['Tumakuru','Karnataka'],
        573101: ['Hassan','Karnataka'],
        573102: ['Hassan','Karnataka'],
        573103: ['Hassan','Karnataka'],
        573111: ['Hassan','Karnataka'],
        573112: ['Hassan','Karnataka'],
        573113: ['Hassan','Karnataka'],
        573115: ['Hassan','Karnataka'],
        573116: ['Hassan','Karnataka'],
        573117: ['Hassan','Karnataka'],
        573118: ['Hassan','Karnataka'],
        573119: ['Hassan','Karnataka'],
        573120: ['Hassan','Karnataka'],
        573121: ['Hassan','Karnataka'],
        573122: ['Hassan','Karnataka'],
        573123: ['Hassan','Karnataka'],
        573124: ['Hassan','Karnataka'],
        573125: ['Hassan','Karnataka'],
        573126: ['Hassan','Karnataka'],
        573127: ['Hassan','Karnataka'],
        573128: ['Hassan','Karnataka'],
        573129: ['Hassan','Karnataka'],
        573130: ['Hassan','Karnataka'],
        573131: ['Hassan','Karnataka'],
        573133: ['Hassan','Karnataka'],
        573134: ['Hassan','Karnataka'],
        573135: ['Hassan','Karnataka'],
        573136: ['Hassan','Karnataka'],
        573137: ['Hassan','Karnataka'],
        573141: ['Hassan','Karnataka'],
        573142: ['Hassan','Karnataka'],
        573144: ['Hassan','Karnataka'],
        573162: ['Hassan','Karnataka'],
        573164: ['Hassan','Karnataka'],
        573165: ['Hassan','Karnataka'],
        573201: ['Hassan','Karnataka'],
        573202: ['Hassan','Karnataka'],
        573210: ['Hassan','Karnataka'],
        573211: ['Hassan','Karnataka'],
        573212: ['Hassan','Karnataka'],
        573213: ['Hassan','Karnataka'],
        573214: ['Hassan','Karnataka'],
        573215: ['Hassan','Karnataka'],
        573216: ['Hassan','Karnataka'],
        573217: ['Hassan','Karnataka'],
        573218: ['Hassan','Karnataka'],
        573219: ['Hassan','Karnataka'],
        573220: ['Hassan','Karnataka'],
        573225: ['Hassan','Karnataka'],
        573226: ['Hassan','Karnataka'],
        574141: ['Dakshina Kannada','Karnataka'],
        574143: ['Dakshina Kannada','Karnataka'],
        574145: ['Dakshina Kannada','Karnataka'],
        574146: ['Dakshina Kannada','Karnataka'],
        574148: ['Dakshina Kannada','Karnataka'],
        574150: ['Dakshina Kannada','Karnataka'],
        574151: ['Dakshina Kannada','Karnataka'],
        574153: ['Dakshina Kannada','Karnataka'],
        574154: ['Dakshina Kannada','Karnataka'],
        574197: ['Dakshina Kannada','Karnataka'],
        574198: ['Dakshina Kannada','Karnataka'],
        574199: ['Dakshina Kannada','Karnataka'],
        574201: ['Dakshina Kannada','Karnataka'],
        574202: ['Dakshina Kannada','Karnataka'],
        574203: ['Dakshina Kannada','Karnataka'],
        574210: ['Dakshina Kannada','Karnataka'],
        574211: ['Dakshina Kannada','Karnataka'],
        574212: ['Dakshina Kannada','Karnataka'],
        574213: ['Dakshina Kannada','Karnataka'],
        574214: ['Dakshina Kannada','Karnataka'],
        574216: ['Dakshina Kannada','Karnataka'],
        574217: ['Dakshina Kannada','Karnataka'],
        574218: ['Dakshina Kannada','Karnataka'],
        574219: ['Dakshina Kannada','Karnataka'],
        574220: ['Dakshina Kannada','Karnataka'],
        574221: ['Dakshina Kannada','Karnataka'],
        574222: ['Dakshina Kannada','Karnataka'],
        574223: ['Dakshina Kannada','Karnataka'],
        574224: ['Dakshina Kannada','Karnataka'],
        574225: ['Dakshina Kannada','Karnataka'],
        574226: ['Dakshina Kannada','Karnataka'],
        574227: ['Dakshina Kannada','Karnataka'],
        574228: ['Dakshina Kannada','Karnataka'],
        574229: ['Dakshina Kannada','Karnataka'],
        574230: ['Dakshina Kannada','Karnataka'],
        574231: ['Dakshina Kannada','Karnataka'],
        574232: ['Dakshina Kannada','Karnataka'],
        574233: ['Dakshina Kannada','Karnataka'],
        574234: ['Kodagu','Karnataka'],
        574235: ['Dakshina Kannada','Karnataka'],
        574236: ['Dakshina Kannada','Karnataka'],
        574237: ['Dakshina Kannada','Karnataka'],
        574238: ['Dakshina Kannada','Karnataka'],
        574239: ['Dakshina Kannada','Karnataka'],
        574240: ['Dakshina Kannada','Karnataka'],
        574241: ['Dakshina Kannada','Karnataka'],
        574242: ['Dakshina Kannada','Karnataka'],
        574243: ['Dakshina Kannada','Karnataka'],
        574248: ['Dakshina Kannada','Karnataka'],
        574253: ['Dakshina Kannada','Karnataka'],
        574259: ['Dakshina Kannada','Karnataka'],
        574260: ['Dakshina Kannada','Karnataka'],
        574265: ['Dakshina Kannada','Karnataka'],
        574267: ['Dakshina Kannada','Karnataka'],
        574274: ['Dakshina Kannada','Karnataka'],
        574279: ['Dakshina Kannada','Karnataka'],
        574285: ['Dakshina Kannada','Karnataka'],
        574313: ['Dakshina Kannada','Karnataka'],
        574314: ['Dakshina Kannada','Karnataka'],
        574323: ['Dakshina Kannada','Karnataka'],
        574324: ['Dakshina Kannada','Karnataka'],
        574325: ['Dakshina Kannada','Karnataka'],
        574326: ['Dakshina Kannada','Karnataka'],
        574327: ['Dakshina Kannada','Karnataka'],
        574328: ['Dakshina Kannada','Karnataka'],
        574509: ['Dakshina Kannada','Karnataka'],
        575007: ['Dakshina Kannada','Karnataka'],
        575017: ['Dakshina Kannada','Karnataka'],
        575018: ['Dakshina Kannada','Karnataka'],
        575020: ['Dakshina Kannada','Karnataka'],
        575022: ['Dakshina Kannada','Karnataka'],
        575023: ['Dakshina Kannada','Karnataka'],
        575025: ['Dakshina Kannada','Karnataka'],
        577004: ['Davangere','Karnataka'],
        577005: ['Davangere','Karnataka'],
        577006: ['Davangere','Karnataka'],
        577007: ['Davangere','Karnataka'],
        577101: ['Chickmagalur','Karnataka'],
        577102: ['Chickmagalur','Karnataka'],
        577111: ['Chickmagalur','Karnataka'],
        577112: ['Chickmagalur','Karnataka'],
        577113: ['Chickmagalur','Karnataka'],
        577114: ['Chickmagalur','Karnataka'],
        577115: ['Shivamogga','Karnataka'],
        577116: ['Chickmagalur','Karnataka'],
        577117: ['Chickmagalur','Karnataka'],
        577120: ['Chickmagalur','Karnataka'],
        577121: ['Chickmagalur','Karnataka'],
        577122: ['Chickmagalur','Karnataka'],
        577123: ['Chickmagalur','Karnataka'],
        577124: ['Chickmagalur','Karnataka'],
        577125: ['Chickmagalur','Karnataka'],
        577126: ['Chickmagalur','Karnataka'],
        577127: ['Chickmagalur','Karnataka'],
        577128: ['Chickmagalur','Karnataka'],
        577129: ['Chickmagalur','Karnataka'],
        577130: ['Chickmagalur','Karnataka'],
        577131: ['Chickmagalur','Karnataka'],
        577132: ['Chickmagalur','Karnataka'],
        577133: ['Chickmagalur','Karnataka'],
        577134: ['Chickmagalur','Karnataka'],
        577135: ['Chickmagalur','Karnataka'],
        577136: ['Chickmagalur','Karnataka'],
        577137: ['Chickmagalur','Karnataka'],
        577138: ['Chickmagalur','Karnataka'],
        577139: ['Chickmagalur','Karnataka'],
        577140: ['Chickmagalur','Karnataka'],
        577144: ['Chickmagalur','Karnataka'],
        577145: ['Chickmagalur','Karnataka'],
        577146: ['Chickmagalur','Karnataka'],
        577160: ['Chickmagalur','Karnataka'],
        577168: ['Chickmagalur','Karnataka'],
        577175: ['Chickmagalur','Karnataka'],
        577179: ['Chickmagalur','Karnataka'],
        577180: ['Chickmagalur','Karnataka'],
        577181: ['Chickmagalur','Karnataka'],
        577182: ['Chickmagalur','Karnataka'],
        577201: ['Shivamogga','Karnataka'],
        577202: ['Shivamogga','Karnataka'],
        577203: ['Shivamogga','Karnataka'],
        577204: ['Shivamogga','Karnataka'],
        577205: ['Shivamogga','Karnataka'],
        577211: ['Shivamogga','Karnataka'],
        577213: ['Shivamogga','Karnataka'],
        577214: ['Shivamogga','Karnataka'],
        577215: ['Davangere','Karnataka'],
        577216: ['Shivamogga','Karnataka'],
        577217: ['Davangere','Karnataka'],
        577218: ['Shivamogga','Karnataka'],
        577219: ['Davangere','Karnataka'],
        577220: ['Shivamogga','Karnataka'],
        577221: ['Davangere','Karnataka'],
        577222: ['Shivamogga','Karnataka'],
        577223: ['Davangere','Karnataka'],
        577224: ['Davangere','Karnataka'],
        577225: ['Davangere','Karnataka'],
        577226: ['Shivamogga','Karnataka'],
        577227: ['Shivamogga','Karnataka'],
        577228: ['Chickmagalur','Karnataka'],
        577229: ['Shivamogga','Karnataka'],
        577230: ['Shivamogga','Karnataka'],
        577231: ['Davangere','Karnataka'],
        577232: ['Shivamogga','Karnataka'],
        577233: ['Shivamogga','Karnataka'],
        577243: ['Shivamogga','Karnataka'],
        577245: ['Shivamogga','Karnataka'],
        577301: ['Shivamogga','Karnataka'],
        577302: ['Shivamogga','Karnataka'],
        577401: ['Shivamogga','Karnataka'],
        577411: ['Shivamogga','Karnataka'],
        577412: ['Shivamogga','Karnataka'],
        577413: ['Shivamogga','Karnataka'],
        577414: ['Shivamogga','Karnataka'],
        577415: ['Shivamogga','Karnataka'],
        577416: ['Shivamogga','Karnataka'],
        577417: ['Shivamogga','Karnataka'],
        577418: ['Shivamogga','Karnataka'],
        577419: ['Shivamogga','Karnataka'],
        577421: ['Shivamogga','Karnataka'],
        577422: ['Shivamogga','Karnataka'],
        577423: ['Shivamogga','Karnataka'],
        577424: ['Shivamogga','Karnataka'],
        577425: ['Shivamogga','Karnataka'],
        577426: ['Shivamogga','Karnataka'],
        577427: ['Shivamogga','Karnataka'],
        577428: ['Shivamogga','Karnataka'],
        577429: ['Shivamogga','Karnataka'],
        577430: ['Shivamogga','Karnataka'],
        577431: ['Shivamogga','Karnataka'],
        577432: ['Shivamogga','Karnataka'],
        577433: ['Shivamogga','Karnataka'],
        577434: ['Shivamogga','Karnataka'],
        577435: ['Shivamogga','Karnataka'],
        577436: ['Shivamogga','Karnataka'],
        577451: ['Shivamogga','Karnataka'],
        577452: ['Shivamogga','Karnataka'],
        577453: ['Shivamogga','Karnataka'],
        577501: ['Chitradurga','Karnataka'],
        577502: ['Chitradurga','Karnataka'],
        577511: ['Chitradurga','Karnataka'],
        577512: ['Davangere','Karnataka'],
        577513: ['Davangere','Karnataka'],
        577514: ['Davangere','Karnataka'],
        577515: ['Chitradurga','Karnataka'],
        577516: ['Davangere','Karnataka'],
        577517: ['Chitradurga','Karnataka'],
        577518: ['Chitradurga','Karnataka'],
        577519: ['Chitradurga','Karnataka'],
        577520: ['Chitradurga','Karnataka'],
        577521: ['Chitradurga','Karnataka'],
        577522: ['Chitradurga','Karnataka'],
        577523: ['Chitradurga','Karnataka'],
        577524: ['Chitradurga','Karnataka'],
        577525: ['Davangere','Karnataka'],
        577526: ['Chitradurga','Karnataka'],
        577527: ['Chitradurga','Karnataka'],
        577528: ['Davangere','Karnataka'],
        577529: ['Chitradurga','Karnataka'],
        577530: ['Davangere','Karnataka'],
        577531: ['Chitradurga','Karnataka'],
        577532: ['Chitradurga','Karnataka'],
        577533: ['Chitradurga','Karnataka'],
        577534: ['Davangere','Karnataka'],
        577535: ['Chitradurga','Karnataka'],
        577536: ['Chitradurga','Karnataka'],
        577537: ['Chitradurga','Karnataka'],
        577538: ['Chitradurga','Karnataka'],
        577539: ['Chitradurga','Karnataka'],
        577540: ['Chitradurga','Karnataka'],
        577541: ['Chitradurga','Karnataka'],
        577542: ['Chitradurga','Karnataka'],
        577543: ['Chitradurga','Karnataka'],
        577544: ['Davangere','Karnataka'],
        577545: ['Chitradurga','Karnataka'],
        577546: ['Chitradurga','Karnataka'],
        577547: ['Chickmagalur','Karnataka'],
        577548: ['Chickmagalur','Karnataka'],
        577549: ['Chickmagalur','Karnataka'],
        577550: ['Chickmagalur','Karnataka'],
        577551: ['Davangere','Karnataka'],
        577552: ['Shivamogga','Karnataka'],
        577553: ['Chitradurga','Karnataka'],
        577554: ['Chitradurga','Karnataka'],
        577555: ['Chitradurga','Karnataka'],
        577556: ['Davangere','Karnataka'],
        577557: ['Chitradurga','Karnataka'],
        577558: ['Chitradurga','Karnataka'],
        577566: ['Davangere','Karnataka'],
        577589: ['Davangere','Karnataka'],
        577596: ['Chitradurga','Karnataka'],
        577597: ['Chitradurga','Karnataka'],
        577598: ['Chitradurga','Karnataka'],
        577599: ['Chitradurga','Karnataka'],
        577601: ['Davangere','Karnataka'],
        580001: ['Dharwad','Karnataka'],
        580002: ['Dharwad','Karnataka'],
        580004: ['Dharwad','Karnataka'],
        580005: ['Dharwad','Karnataka'],
        580006: ['Dharwad','Karnataka'],
        580007: ['Dharwad','Karnataka'],
        580008: ['Dharwad','Karnataka'],
        580009: ['Dharwad','Karnataka'],
        580011: ['Dharwad','Karnataka'],
        580020: ['Dharwad','Karnataka'],
        580021: ['Dharwad','Karnataka'],
        580023: ['Dharwad','Karnataka'],
        580024: ['Dharwad','Karnataka'],
        580025: ['Dharwad','Karnataka'],
        580026: ['Dharwad','Karnataka'],
        580027: ['Dharwad','Karnataka'],
        580028: ['Hubballi','Karnataka'],
        580029: ['Dharwad','Karnataka'],
        580030: ['Dharwad','Karnataka'],
        580031: ['Dharwad','Karnataka'],
        580032: ['Dharwad','Karnataka'],
        580112: ['Dharwad','Karnataka'],
        580114: ['Dharwad','Karnataka'],
        580118: ['Dharwad','Karnataka'],
        581101: ['Haveri','Karnataka'],
        581102: ['Haveri','Karnataka'],
        581103: ['Dharwad','Karnataka'],
        581104: ['Haveri','Karnataka'],
        581105: ['Dharwad','Karnataka'],
        581106: ['Haveri','Karnataka'],
        581107: ['Dharwad','Karnataka'],
        581108: ['Haveri','Karnataka'],
        581109: ['Haveri','Karnataka'],
        581110: ['Haveri','Karnataka'],
        581111: ['Haveri','Karnataka'],
        581112: ['Haveri','Karnataka'],
        581113: ['Dharwad','Karnataka'],
        581115: ['Haveri','Karnataka'],
        581116: ['Haveri','Karnataka'],
        581117: ['Dharwad','Karnataka'],
        581118: ['Haveri','Karnataka'],
        581119: ['Haveri','Karnataka'],
        581120: ['Haveri','Karnataka'],
        581121: ['Joida','Karnataka'],
        581123: ['Haveri','Karnataka'],
        581126: ['Haveri','Karnataka'],
        581128: ['Haveri','Karnataka'],
        581129: ['Uttara Kannada','Karnataka'],
        581145: ['Haveri','Karnataka'],
        581148: ['Haveri','Karnataka'],
        581186: ['Uttara Kannada','Karnataka'],
        581187: ['Uttara Kannada','Karnataka'],
        581193: ['Haveri','Karnataka'],
        581195: ['Madikeri','Karnataka'],
        581196: ['Dharwad','Karnataka'],
        581198: ['Haveri','Karnataka'],
        581199: ['Haveri','Karnataka'],
        581201: ['Dharwad','Karnataka'],
        581202: ['Haveri','Karnataka'],
        581203: ['Haveri','Karnataka'],
        581204: ['Dharwad','Karnataka'],
        581205: ['Haveri','Karnataka'],
        581206: ['Dharwad','Karnataka'],
        581207: ['Dharwad','Karnataka'],
        581208: ['Haveri','Karnataka'],
        581209: ['Dharwad','Karnataka'],
        581210: ['Haveri','Karnataka'],
        581211: ['Haveri','Karnataka'],
        581212: ['Haveri','Karnataka'],
        581213: ['Haveri','Karnataka'],
        581237: ['Haveri','Karnataka'],
        581301: ['Uttara Kannada','Karnataka'],
        581302: ['Uttara Kannada','Karnataka'],
        581303: ['Uttara Kannada','Karnataka'],
        581304: ['Uttara Kannada','Karnataka'],
        581306: ['Uttara Kannada','Karnataka'],
        581307: ['Uttara Kannada','Karnataka'],
        581308: ['Uttara Kannada','Karnataka'],
        581314: ['Uttara Kannada','Karnataka'],
        581315: ['Uttara Kannada','Karnataka'],
        581316: ['Uttara Kannada','Karnataka'],
        581317: ['Uttara Kannada','Karnataka'],
        581318: ['Uttara Kannada','Karnataka'],
        581319: ['Uttara Kannada','Karnataka'],
        581320: ['Uttara Kannada','Karnataka'],
        581321: ['Uttara Kannada','Karnataka'],
        581322: ['Uttara Kannada','Karnataka'],
        581323: ['Uttara Kannada','Karnataka'],
        581324: ['Uttara Kannada','Karnataka'],
        581325: ['Uttara Kannada','Karnataka'],
        581326: ['Uttara Kannada','Karnataka'],
        581327: ['Uttara Kannada','Karnataka'],
        581328: ['Uttara Kannada','Karnataka'],
        581329: ['Uttara Kannada','Karnataka'],
        581330: ['Uttara Kannada','Karnataka'],
        581331: ['Uttara Kannada','Karnataka'],
        581332: ['Uttara Kannada','Karnataka'],
        581333: ['Uttara Kannada','Karnataka'],
        581334: ['Uttara Kannada','Karnataka'],
        581335: ['Uttara Kannada','Karnataka'],
        581336: ['Uttara Kannada','Karnataka'],
        581337: ['Uttara Kannada','Karnataka'],
        581338: ['Uttara Kannada','Karnataka'],
        581339: ['Uttara Kannada','Karnataka'],
        581340: ['Uttara Kannada','Karnataka'],
        581341: ['Uttara Kannada','Karnataka'],
        581342: ['Uttara Kannada','Karnataka'],
        581343: ['Uttara Kannada','Karnataka'],
        581344: ['Uttara Kannada','Karnataka'],
        581345: ['Uttara Kannada','Karnataka'],
        581346: ['Uttara Kannada','Karnataka'],
        581347: ['Uttara Kannada','Karnataka'],
        581348: ['Uttara Kannada','Karnataka'],
        581349: ['Uttara Kannada','Karnataka'],
        581350: ['Uttara Kannada','Karnataka'],
        581351: ['Uttara Kannada','Karnataka'],
        581352: ['Uttara Kannada','Karnataka'],
        581353: ['Uttara Kannada','Karnataka'],
        581354: ['Uttara Kannada','Karnataka'],
        581355: ['Uttara Kannada','Karnataka'],
        581356: ['Uttara Kannada','Karnataka'],
        581357: ['Uttara Kannada','Karnataka'],
        581358: ['Uttara Kannada','Karnataka'],
        581359: ['Uttara Kannada','Karnataka'],
        581360: ['Uttara Kannada','Karnataka'],
        581361: ['Uttara Kannada','Karnataka'],
        581362: ['Uttara Kannada','Karnataka'],
        581363: ['Uttara Kannada','Karnataka'],
        581365: ['Uttara Kannada','Karnataka'],
        581384: ['Uttara Kannada','Karnataka'],
        581396: ['Uttara Kannada','Karnataka'],
        581400: ['Uttara Kannada','Karnataka'],
        581401: ['Uttara Kannada','Karnataka'],
        581402: ['Uttara Kannada','Karnataka'],
        581403: ['Uttara Kannada','Karnataka'],
        581411: ['Uttara Kannada','Karnataka'],
        581412: ['Uttara Kannada','Karnataka'],
        581421: ['Uttara Kannada','Karnataka'],
        581423: ['Uttara Kannada','Karnataka'],
        581440: ['Uttara Kannada','Karnataka'],
        581450: ['Uttara Kannada','Karnataka'],
        581453: ['Joida','Karnataka'],
        582101: ['Gadag','Karnataka'],
        582102: ['Gadag','Karnataka'],
        582103: ['Gadag','Karnataka'],
        582111: ['Gadag','Karnataka'],
        582112: ['Gadag','Karnataka'],
        582113: ['Gadag','Karnataka'],
        582114: ['Gadag','Karnataka'],
        582115: ['Gadag','Karnataka'],
        582116: ['Gadag','Karnataka'],
        582117: ['Gadag','Karnataka'],
        582118: ['Gadag','Karnataka'],
        582119: ['Gadag','Karnataka'],
        582120: ['Gadag','Karnataka'],
        582201: ['Dharwad','Karnataka'],
        582202: ['Gadag','Karnataka'],
        582203: ['Gadag','Karnataka'],
        582204: ['Gadag','Karnataka'],
        582205: ['Gadag','Karnataka'],
        582206: ['Gadag','Karnataka'],
        582207: ['Gadag','Karnataka'],
        582208: ['Dharwad','Karnataka'],
        582209: ['Gadag','Karnataka'],
        582210: ['Gadag','Karnataka'],
        582211: ['Gadag','Karnataka'],
        583101: ['Bellary','Karnataka'],
        583102: ['Bellary','Karnataka'],
        583103: ['Bellary','Karnataka'],
        583104: ['Bellary','Karnataka'],
        583105: ['Bellary','Karnataka'],
        583111: ['Bellary','Karnataka'],
        583112: ['Bellary','Karnataka'],
        583113: ['Bellary','Karnataka'],
        583114: ['Bellary','Karnataka'],
        583115: ['Bellary','Karnataka'],
        583116: ['Bellary','Karnataka'],
        583117: ['Bellary','Karnataka'],
        583118: ['Bellary','Karnataka'],
        583119: ['Bellary','Karnataka'],
        583120: ['Bellary','Karnataka'],
        583121: ['Bellary','Karnataka'],
        583122: ['Bellary','Karnataka'],
        583123: ['Bellary','Karnataka'],
        583124: ['Bellary','Karnataka'],
        583125: ['Davangere','Karnataka'],
        583126: ['Bellary','Karnataka'],
        583127: ['Davangere','Karnataka'],
        583128: ['Bellary','Karnataka'],
        583129: ['Bellary','Karnataka'],
        583130: ['Bellary','Karnataka'],
        583131: ['Bellary','Karnataka'],
        583132: ['Bellary','Karnataka'],
        583134: ['Bellary','Karnataka'],
        583135: ['Bellary','Karnataka'],
        583136: ['Bellary','Karnataka'],
        583137: ['Davangere','Karnataka'],
        583152: ['Bellary','Karnataka'],
        583153: ['Bellary','Karnataka'],
        583154: ['Bellary','Karnataka'],
        583155: ['Bellary','Karnataka'],
        583201: ['Bellary','Karnataka'],
        583203: ['Bellary','Karnataka'],
        583211: ['Bellary','Karnataka'],
        583212: ['Bellary','Karnataka'],
        583213: ['Davangere','Karnataka'],
        583214: ['Bellary','Karnataka'],
        583215: ['Bellary','Karnataka'],
        583216: ['Bellary','Karnataka'],
        583217: ['Bellary','Karnataka'],
        583218: ['Bellary','Karnataka'],
        583219: ['Bellary','Karnataka'],
        583220: ['Bellary','Karnataka'],
        583221: ['Bellary','Karnataka'],
        583222: ['Bellary','Karnataka'],
        583223: ['Bellary','Karnataka'],
        583224: ['Bellary','Karnataka'],
        583225: ['Bellary','Karnataka'],
        583226: ['Koppal','Karnataka'],
        583227: ['Koppal','Karnataka'],
        583228: ['Koppal','Karnataka'],
        583229: ['Koppal','Karnataka'],
        583230: ['Koppal','Karnataka'],
        583231: ['Koppal','Karnataka'],
        583232: ['Gadag','Karnataka'],
        583233: ['Koppal','Karnataka'],
        583234: ['Koppal','Karnataka'],
        583235: ['Koppal','Karnataka'],
        583236: ['Koppal','Karnataka'],
        583237: ['Koppal','Karnataka'],
        583238: ['Koppal','Karnataka'],
        583239: ['Bellary','Karnataka'],
        583268: ['Koppal','Karnataka'],
        583275: ['Bellary','Karnataka'],
        583276: ['Bellary','Karnataka'],
        583277: ['Koppal','Karnataka'],
        583278: ['Koppal','Karnataka'],
        583279: ['Koppal','Karnataka'],
        583280: ['Koppal','Karnataka'],
        583281: ['Koppal','Karnataka'],
        583282: ['Koppal','Karnataka'],
        583283: ['Koppal','Karnataka'],
        583284: ['Koppal','Karnataka'],
        583285: ['Koppal','Karnataka'],
        583287: ['Koppal','Karnataka'],
        584101: ['Raichur','Karnataka'],
        584102: ['Raichur','Karnataka'],
        584103: ['Raichur','Karnataka'],
        584104: ['Raichur','Karnataka'],
        584111: ['Raichur','Karnataka'],
        584113: ['Raichur','Karnataka'],
        584115: ['Raichur','Karnataka'],
        584116: ['Raichur','Karnataka'],
        584118: ['Raichur','Karnataka'],
        584120: ['Raichur','Karnataka'],
        584122: ['Raichur','Karnataka'],
        584123: ['Raichur','Karnataka'],
        584124: ['Raichur','Karnataka'],
        584125: ['Raichur','Karnataka'],
        584126: ['Raichur','Karnataka'],
        584127: ['Raichur','Karnataka'],
        584128: ['Raichur','Karnataka'],
        584129: ['Raichur','Karnataka'],
        584132: ['Raichur','Karnataka'],
        584133: ['Raichur','Karnataka'],
        584134: ['Raichur','Karnataka'],
        584135: ['Raichur','Karnataka'],
        584136: ['Raichur','Karnataka'],
        584138: ['Raichur','Karnataka'],
        584139: ['Raichur','Karnataka'],
        584140: ['Raichur','Karnataka'],
        584143: ['Raichur','Karnataka'],
        584167: ['Raichur','Karnataka'],
        584170: ['Raichur','Karnataka'],
        584202: ['Raichur','Karnataka'],
        584203: ['Raichur','Karnataka'],
        585101: ['Kalaburagi','Karnataka'],
        585102: ['Kalaburagi','Karnataka'],
        585103: ['Kalaburagi','Karnataka'],
        585104: ['Kalaburagi','Karnataka'],
        585105: ['Kalaburagi','Karnataka'],
        585106: ['Kalaburagi','Karnataka'],
        585107: ['Kalaburagi','Karnataka'],
        585201: ['Yadgir','Karnataka'],
        585202: ['Kalaburagi','Karnataka'],
        585210: ['Kalaburagi','Karnataka'],
        585211: ['Kalaburagi','Karnataka'],
        585212: ['Kalaburagi','Karnataka'],
        585213: ['Kalaburagi','Karnataka'],
        585214: ['Kalaburagi','Karnataka'],
        585215: ['Kalaburagi','Karnataka'],
        585216: ['Kalaburagi','Karnataka'],
        585217: ['Kalaburagi','Karnataka'],
        585218: ['Kalaburagi','Karnataka'],
        585219: ['Yadgir','Karnataka'],
        585220: ['Kalaburagi','Karnataka'],
        585221: ['Kalaburagi','Karnataka'],
        585222: ['Kalaburagi','Karnataka'],
        585223: ['Kalaburagi','Karnataka'],
        585224: ['Kalaburagi','Karnataka'],
        585225: ['Kalaburagi','Karnataka'],
        585226: ['Bidar','Karnataka'],
        585227: ['Bidar','Karnataka'],
        585228: ['Kalaburagi','Karnataka'],
        585229: ['Kalaburagi','Karnataka'],
        585236: ['Kalaburagi','Karnataka'],
        585237: ['Kalaburagi','Karnataka'],
        585265: ['Kalaburagi','Karnataka'],
        585287: ['Kalaburagi','Karnataka'],
        585290: ['Kalaburagi','Karnataka'],
        585291: ['Kalaburagi','Karnataka'],
        585292: ['Kalaburagi','Karnataka'],
        585301: ['Kalaburagi','Karnataka'],
        585302: ['Kalaburagi','Karnataka'],
        585303: ['Kalaburagi','Karnataka'],
        585304: ['Kalaburagi','Karnataka'],
        585305: ['Kalaburagi','Karnataka'],
        585306: ['Kalaburagi','Karnataka'],
        585307: ['Kalaburagi','Karnataka'],
        585308: ['Kalaburagi','Karnataka'],
        585309: ['Kalaburagi','Karnataka'],
        585310: ['Kalaburagi','Karnataka'],
        585311: ['Kalaburagi','Karnataka'],
        585312: ['Kalaburagi','Karnataka'],
        585313: ['Kalaburagi','Karnataka'],
        585314: ['Kalaburagi','Karnataka'],
        585315: ['Kalaburagi','Karnataka'],
        585316: ['Kalaburagi','Karnataka'],
        585317: ['Kalaburagi','Karnataka'],
        585318: ['Kalaburagi','Karnataka'],
        585319: ['Kalaburagi','Karnataka'],
        585320: ['Kalaburagi','Karnataka'],
        585321: ['Kalaburagi','Karnataka'],
        585322: ['Kalaburagi','Karnataka'],
        585323: ['Kalaburagi','Karnataka'],
        585324: ['Kalaburagi','Karnataka'],
        585325: ['Kalaburagi','Karnataka'],
        585326: ['Bidar','Karnataka'],
        585327: ['Bidar','Karnataka'],
        585328: ['Bidar','Karnataka'],
        585329: ['Bidar','Karnataka'],
        585330: ['Bidar','Karnataka'],
        585331: ['Bidar','Karnataka'],
        585353: ['Bidar','Karnataka'],
        585355: ['Kalaburagi','Karnataka'],
        585367: ['Kalaburagi','Karnataka'],
        585401: ['Bidar','Karnataka'],
        585402: ['Bidar','Karnataka'],
        585403: ['Bidar','Karnataka'],
        585411: ['Bidar','Karnataka'],
        585412: ['Bidar','Karnataka'],
        585413: ['Bidar','Karnataka'],
        585414: ['Bidar','Karnataka'],
        585415: ['Bidar','Karnataka'],
        585416: ['Bidar','Karnataka'],
        585417: ['Bidar','Karnataka'],
        585418: ['Bidar','Karnataka'],
        585419: ['Bidar','Karnataka'],
        585421: ['Bidar','Karnataka'],
        585436: ['Bidar','Karnataka'],
        585437: ['Bidar','Karnataka'],
        585443: ['Bidar','Karnataka'],
        585444: ['Bidar','Karnataka'],
        585445: ['Bidar','Karnataka'],
        586101: ['Bijapur','Karnataka'],
        586103: ['Bijapur(Kar)','Karnataka'],
        586104: ['Bijapur(Kar)','Karnataka'],
        586108: ['Bijapur(Kar)','Karnataka'],
        586109: ['Bijapur(Kar)','Karnataka'],
        586111: ['Bijapur(Kar)','Karnataka'],
        586112: ['Bijapur(Kar)','Karnataka'],
        586113: ['Bijapur(Kar)','Karnataka'],
        586114: ['Bijapur(Kar)','Karnataka'],
        586115: ['Bijapur(Kar)','Karnataka'],
        586116: ['Bijapur(Kar)','Karnataka'],
        586117: ['Bijapur(Kar)','Karnataka'],
        586118: ['Bijapur(Kar)','Karnataka'],
        586119: ['Bijapur(Kar)','Karnataka'],
        586120: ['Bijapur(Kar)','Karnataka'],
        586121: ['Bijapur(Kar)','Karnataka'],
        586122: ['Bijapur(Kar)','Karnataka'],
        586123: ['Bijapur(Kar)','Karnataka'],
        586124: ['Bijapur(Kar)','Karnataka'],
        586125: ['Bijapur(Kar)','Karnataka'],
        586127: ['Bijapur(Kar)','Karnataka'],
        586128: ['Bijapur(Kar)','Karnataka'],
        586129: ['Bijapur(Kar)','Karnataka'],
        586130: ['Bijapur(Kar)','Karnataka'],
        586201: ['Bijapur(Kar)','Karnataka'],
        586202: ['Bijapur(Kar)','Karnataka'],
        586203: ['Bijapur(Kar)','Karnataka'],
        586204: ['Bijapur(Kar)','Karnataka'],
        586205: ['Bijapur(Kar)','Karnataka'],
        586206: ['Bijapur(Kar)','Karnataka'],
        586207: ['Bijapur(Kar)','Karnataka'],
        586208: ['Bijapur(Kar)','Karnataka'],
        586209: ['Bijapur(Kar)','Karnataka'],
        586210: ['Bijapur(Kar)','Karnataka'],
        586211: ['Bijapur(Kar)','Karnataka'],
        586212: ['Bijapur(Kar)','Karnataka'],
        586213: ['Bijapur(Kar)','Karnataka'],
        586214: ['Bijapur(Kar)','Karnataka'],
        586215: ['Bijapur(Kar)','Karnataka'],
        586216: ['Bijapur(Kar)','Karnataka'],
        586217: ['Bijapur(Kar)','Karnataka'],
        587101: ['Bagalkot','Karnataka'],
        587102: ['Bagalkot','Karnataka'],
        587103: ['Bagalkot','Karnataka'],
        587104: ['Bagalkot','Karnataka'],
        587111: ['Bagalkot','Karnataka'],
        587112: ['Bagalkot','Karnataka'],
        587113: ['Bagalkot','Karnataka'],
        587114: ['Bagalkot','Karnataka'],
        587115: ['Bagalkot','Karnataka'],
        587116: ['Bagalkot','Karnataka'],
        587117: ['Bagalkot','Karnataka'],
        587118: ['Bagalkot','Karnataka'],
        587119: ['Bagalkot','Karnataka'],
        587120: ['Bagalkot','Karnataka'],
        587121: ['Bagalkot','Karnataka'],
        587122: ['Bagalkot','Karnataka'],
        587125: ['Bagalkot','Karnataka'],
        587154: ['Bagalkot','Karnataka'],
        587155: ['Bagalkot','Karnataka'],
        587156: ['Bagalkot','Karnataka'],
        587201: ['Bagalkot','Karnataka'],
        587202: ['Bagalkot','Karnataka'],
        587203: ['Bagalkot','Karnataka'],
        587204: ['Bagalkot','Karnataka'],
        587205: ['Bagalkot','Karnataka'],
        587206: ['Bagalkot','Karnataka'],
        587207: ['Bagalkot','Karnataka'],
        587301: ['Bagalkot','Karnataka'],
        587311: ['Bagalkot','Karnataka'],
        587312: ['Bagalkot','Karnataka'],
        587313: ['Bagalkot','Karnataka'],
        587314: ['Bagalkot','Karnataka'],
        587315: ['Bagalkot','Karnataka'],
        587316: ['Bagalkot','Karnataka'],
        587330: ['Bagalkot','Karnataka'],
        587331: ['Bagalkot','Karnataka'],
        600016: ['Kanchipuram','Tamil Nadu'],
        600019: ['Tiruvallur','Tamil Nadu'],
        600043: ['Kanchipuram','Tamil Nadu'],
        600044: ['Kanchipuram','Tamil Nadu'],
        600045: ['Kanchipuram','Tamil Nadu'],
        600046: ['Kanchipuram','Tamil Nadu'],
        600047: ['Kanchipuram','Tamil Nadu'],
        600048: ['Kanchipuram','Tamil Nadu'],
        600049: ['Tiruvallur','Tamil Nadu'],
        600050: ['Tiruvallur','Tamil Nadu'],
        600051: ['Tiruvallur','Tamil Nadu'],
        600052: ['Tiruvallur','Tamil Nadu'],
        600053: ['Kanchipuram','Tamil Nadu'],
        600054: ['Tiruvallur','Tamil Nadu'],
        600055: ['Tiruvallur','Tamil Nadu'],
        600056: ['Tiruvallur','Tamil Nadu'],
        600058: ['Tiruvallur','Tamil Nadu'],
        600059: ['Kanchipuram','Tamil Nadu'],
        600060: ['Tiruvallur','Tamil Nadu'],
        600061: ['Kanchipuram','Tamil Nadu'],
        600062: ['Tiruvallur','Tamil Nadu'],
        600063: ['Kanchipuram','Tamil Nadu'],
        600064: ['Kanchipuram','Tamil Nadu'],
        600066: ['Tiruvallur','Tamil Nadu'],
        600067: ['Tiruvallur','Tamil Nadu'],
        600068: ['Tiruvallur','Tamil Nadu'],
        600069: ['Kanchipuram','Tamil Nadu'],
        600070: ['Kanchipuram','Tamil Nadu'],
        600071: ['Tiruvallur','Tamil Nadu'],
        600072: ['Tiruvallur','Tamil Nadu'],
        600073: ['Kanchipuram','Tamil Nadu'],
        600074: ['Kanchipuram','Tamil Nadu'],
        600075: ['Kanchipuram','Tamil Nadu'],
        600077: ['Tiruvallur','Tamil Nadu'],
        600087: ['Tiruvallur','Tamil Nadu'],
        600088: ['Kanchipuram','Tamil Nadu'],
        600089: ['Kanchipuram','Tamil Nadu'],
        600091: ['Kanchipuram','Tamil Nadu'],
        600099: ['Chennai','Tamil Nadu'],
        600100: ['Kanchipuram','Tamil Nadu'],
        600103: ['Tiruvallur','Tamil Nadu'],
        600115: ['Kanchipuram','Tamil Nadu'],
        600116: ['Tiruvallur','Tamil Nadu'],
        600117: ['Kanchipuram','Tamil Nadu'],
        600119: ['Kanchipuram','Tamil Nadu'],
        600120: ['Tiruvallur','Tamil Nadu'],
        600122: ['Kanchipuram','Tamil Nadu'],
        600123: ['Tiruvallur','Tamil Nadu'],
        600124: ['Tiruvallur','Tamil Nadu'],
        600125: ['Kanchipuram','Tamil Nadu'],
        600126: ['Kanchipuram','Tamil Nadu'],
        600127: ['Kanchipuram','Tamil Nadu'],
        600128: ['Kanchipuram','Tamil Nadu'],
        600129: ['Kanchipuram','Tamil Nadu'],
        600130: ['Kanchipuram','Tamil Nadu'],
        601101: ['Tiruvallur','Tamil Nadu'],
        601102: ['Tiruvallur','Tamil Nadu'],
        601103: ['Tiruvallur','Tamil Nadu'],
        601201: ['Tiruvallur','Tamil Nadu'],
        601202: ['Tiruvallur','Tamil Nadu'],
        601203: ['Tiruvallur','Tamil Nadu'],
        601204: ['Tiruvallur','Tamil Nadu'],
        601205: ['Tiruvallur','Tamil Nadu'],
        601206: ['Tiruvallur','Tamil Nadu'],
        601301: ['Kanchipuram','Tamil Nadu'],
        602001: ['Tiruvallur','Tamil Nadu'],
        602002: ['Tiruvallur','Tamil Nadu'],
        602003: ['Tiruvallur','Tamil Nadu'],
        602021: ['Tiruvallur','Tamil Nadu'],
        602023: ['Tiruvallur','Tamil Nadu'],
        602024: ['Tiruvallur','Tamil Nadu'],
        602025: ['Tiruvallur','Tamil Nadu'],
        602026: ['Tiruvallur','Tamil Nadu'],
        602105: ['Kanchipuram','Tamil Nadu'],
        602106: ['Kanchipuram','Tamil Nadu'],
        602108: ['Kanchipuram','Tamil Nadu'],
        602117: ['Kanchipuram','Tamil Nadu'],
        603001: ['Kanchipuram','Tamil Nadu'],
        603002: ['Kanchipuram','Tamil Nadu'],
        603003: ['Kanchipuram','Tamil Nadu'],
        603101: ['Kanchipuram','Tamil Nadu'],
        603102: ['Kanchipuram','Tamil Nadu'],
        603103: ['Kanchipuram','Tamil Nadu'],
        603104: ['Kanchipuram','Tamil Nadu'],
        603105: ['Kanchipuram','Tamil Nadu'],
        603106: ['Kanchipuram','Tamil Nadu'],
        603107: ['Kanchipuram','Tamil Nadu'],
        603108: ['Kanchipuram','Tamil Nadu'],
        603109: ['Kanchipuram','Tamil Nadu'],
        603110: ['Kanchipuram','Tamil Nadu'],
        603111: ['Kanchipuram','Tamil Nadu'],
        603112: ['Kanchipuram','Tamil Nadu'],
        603127: ['Kanchipuram','Tamil Nadu'],
        603201: ['Kanchipuram','Tamil Nadu'],
        603202: ['Kanchipuram','Tamil Nadu'],
        603203: ['Kanchipuram','Tamil Nadu'],
        603204: ['Kanchipuram','Tamil Nadu'],
        603209: ['Kanchipuram','Tamil Nadu'],
        603210: ['Kanchipuram','Tamil Nadu'],
        603211: ['Kanchipuram','Tamil Nadu'],
        603301: ['Kanchipuram','Tamil Nadu'],
        603302: ['Kanchipuram','Tamil Nadu'],
        603303: ['Kanchipuram','Tamil Nadu'],
        603304: ['Kanchipuram','Tamil Nadu'],
        603305: ['Kanchipuram','Tamil Nadu'],
        603306: ['Kanchipuram','Tamil Nadu'],
        603307: ['Kanchipuram','Tamil Nadu'],
        603308: ['Kanchipuram','Tamil Nadu'],
        603309: ['Kanchipuram','Tamil Nadu'],
        603310: ['Kanchipuram','Tamil Nadu'],
        603311: ['Kanchipuram','Tamil Nadu'],
        603312: ['Kanchipuram','Tamil Nadu'],
        603313: ['Kanchipuram','Tamil Nadu'],
        603314: ['Kanchipuram','Tamil Nadu'],
        603319: ['Kanchipuram','Tamil Nadu'],
        603401: ['Kanchipuram','Tamil Nadu'],
        603402: ['Kanchipuram','Tamil Nadu'],
        603403: ['Kanchipuram','Tamil Nadu'],
        603405: ['Kanchipuram','Tamil Nadu'],
        603406: ['Kanchipuram','Tamil Nadu'],
        604001: ['Villupuram','Tamil Nadu'],
        604101: ['Villupuram','Tamil Nadu'],
        604102: ['Villupuram','Tamil Nadu'],
        604151: ['Villupuram','Tamil Nadu'],
        604152: ['Villupuram','Tamil Nadu'],
        604153: ['Villupuram','Tamil Nadu'],
        604154: ['Villupuram','Tamil Nadu'],
        604201: ['Villupuram','Tamil Nadu'],
        604202: ['Villupuram','Tamil Nadu'],
        604203: ['Villupuram','Tamil Nadu'],
        604204: ['Villupuram','Tamil Nadu'],
        604205: ['Villupuram','Tamil Nadu'],
        604206: ['Villupuram','Tamil Nadu'],
        604207: ['Villupuram','Tamil Nadu'],
        604208: ['Villupuram','Tamil Nadu'],
        604210: ['Villupuram','Tamil Nadu'],
        604301: ['Villupuram','Tamil Nadu'],
        604302: ['Villupuram','Tamil Nadu'],
        604303: ['Villupuram','Tamil Nadu'],
        604304: ['Villupuram','Tamil Nadu'],
        604305: ['Villupuram','Tamil Nadu'],
        604306: ['Villupuram','Tamil Nadu'],
        604307: ['Villupuram','Tamil Nadu'],
        604401: ['Tiruvannamalai','Tamil Nadu'],
        604402: ['Tiruvannamalai','Tamil Nadu'],
        604403: ['Tiruvannamalai','Tamil Nadu'],
        604404: ['Tiruvannamalai','Tamil Nadu'],
        604405: ['Tiruvannamalai','Tamil Nadu'],
        604406: ['Tiruvannamalai','Tamil Nadu'],
        604407: ['Tiruvannamalai','Tamil Nadu'],
        604408: ['Tiruvannamalai','Tamil Nadu'],
        604409: ['Tiruvannamalai','Tamil Nadu'],
        604410: ['Tiruvannamalai','Tamil Nadu'],
        604501: ['Tiruvannamalai','Tamil Nadu'],
        604502: ['Tiruvannamalai','Tamil Nadu'],
        604503: ['Tiruvannamalai','Tamil Nadu'],
        604504: ['Tiruvannamalai','Tamil Nadu'],
        604505: ['Tiruvannamalai','Tamil Nadu'],
        604601: ['Tiruvannamalai','Tamil Nadu'],
        605103: ['Villupuram','Tamil Nadu'],
        605107: ['Villupuram','Tamil Nadu'],
        605108: ['Villupuram','Tamil Nadu'],
        605109: ['Villupuram','Tamil Nadu'],
        605111: ['Villupuram','Tamil Nadu'],
        605201: ['Villupuram','Tamil Nadu'],
        605202: ['Villupuram','Tamil Nadu'],
        605203: ['Villupuram','Tamil Nadu'],
        605301: ['Villupuram','Tamil Nadu'],
        605302: ['Villupuram','Tamil Nadu'],
        605401: ['Villupuram','Tamil Nadu'],
        605402: ['Villupuram','Tamil Nadu'],
        605601: ['Villupuram','Tamil Nadu'],
        605602: ['Villupuram','Tamil Nadu'],
        605651: ['Villupuram','Tamil Nadu'],
        605652: ['Villupuram','Tamil Nadu'],
        605701: ['Villupuram','Tamil Nadu'],
        605702: ['Villupuram','Tamil Nadu'],
        605751: ['Villupuram','Tamil Nadu'],
        605752: ['Villupuram','Tamil Nadu'],
        605754: ['Villupuram','Tamil Nadu'],
        605755: ['Villupuram','Tamil Nadu'],
        605756: ['Villupuram','Tamil Nadu'],
        605757: ['Villupuram','Tamil Nadu'],
        605758: ['Villupuram','Tamil Nadu'],
        605759: ['Villupuram','Tamil Nadu'],
        605766: ['Villupuram','Tamil Nadu'],
        605801: ['Villupuram','Tamil Nadu'],
        605802: ['Villupuram','Tamil Nadu'],
        605803: ['Villupuram','Tamil Nadu'],
        606001: ['Cuddalore','Tamil Nadu'],
        606003: ['Cuddalore','Tamil Nadu'],
        606102: ['Villupuram','Tamil Nadu'],
        606103: ['Cuddalore','Tamil Nadu'],
        606104: ['Cuddalore','Tamil Nadu'],
        606105: ['Cuddalore','Tamil Nadu'],
        606106: ['Cuddalore','Tamil Nadu'],
        606107: ['Villupuram','Tamil Nadu'],
        606108: ['Cuddalore','Tamil Nadu'],
        606109: ['Cuddalore','Tamil Nadu'],
        606110: ['Cuddalore','Tamil Nadu'],
        606111: ['Cuddalore','Tamil Nadu'],
        606115: ['Villupuram','Tamil Nadu'],
        606201: ['Villupuram','Tamil Nadu'],
        606202: ['Villupuram','Tamil Nadu'],
        606203: ['Villupuram','Tamil Nadu'],
        606204: ['Villupuram','Tamil Nadu'],
        606205: ['Villupuram','Tamil Nadu'],
        606206: ['Villupuram','Tamil Nadu'],
        606207: ['Villupuram','Tamil Nadu'],
        606208: ['Villupuram','Tamil Nadu'],
        606209: ['Villupuram','Tamil Nadu'],
        606213: ['Villupuram','Tamil Nadu'],
        606301: ['Villupuram','Tamil Nadu'],
        606302: ['Cuddalore','Tamil Nadu'],
        606303: ['Cuddalore','Tamil Nadu'],
        606304: ['Cuddalore','Tamil Nadu'],
        606305: ['Villupuram','Tamil Nadu'],
        606401: ['Villupuram','Tamil Nadu'],
        606402: ['Villupuram','Tamil Nadu'],
        606601: ['Tiruvannamalai','Tamil Nadu'],
        606603: ['Tiruvannamalai','Tamil Nadu'],
        606604: ['Tiruvannamalai','Tamil Nadu'],
        606611: ['Tiruvannamalai','Tamil Nadu'],
        606701: ['Tiruvannamalai','Tamil Nadu'],
        606702: ['Tiruvannamalai','Tamil Nadu'],
        606703: ['Tiruvannamalai','Tamil Nadu'],
        606704: ['Tiruvannamalai','Tamil Nadu'],
        606705: ['Tiruvannamalai','Tamil Nadu'],
        606706: ['Tiruvannamalai','Tamil Nadu'],
        606707: ['Tiruvannamalai','Tamil Nadu'],
        606708: ['Tiruvannamalai','Tamil Nadu'],
        606709: ['Tiruvannamalai','Tamil Nadu'],
        606710: ['Tiruvannamalai','Tamil Nadu'],
        606751: ['Tiruvannamalai','Tamil Nadu'],
        606752: ['Tiruvannamalai','Tamil Nadu'],
        606753: ['Tiruvannamalai','Tamil Nadu'],
        606754: ['Tiruvannamalai','Tamil Nadu'],
        606755: ['Tiruvannamalai','Tamil Nadu'],
        606801: ['Tiruvannamalai','Tamil Nadu'],
        606802: ['Tiruvannamalai','Tamil Nadu'],
        606803: ['Tiruvannamalai','Tamil Nadu'],
        606804: ['Tiruvannamalai','Tamil Nadu'],
        606805: ['Tiruvannamalai','Tamil Nadu'],
        606806: ['Tiruvannamalai','Tamil Nadu'],
        606807: ['Tiruvannamalai','Tamil Nadu'],
        606808: ['Tiruvannamalai','Tamil Nadu'],
        606811: ['Tiruvannamalai','Tamil Nadu'],
        606901: ['Tiruvannamalai','Tamil Nadu'],
        606902: ['Tiruvannamalai','Tamil Nadu'],
        606903: ['Tiruvannamalai','Tamil Nadu'],
        606904: ['Tiruvannamalai','Tamil Nadu'],
        606905: ['Tiruvannamalai','Tamil Nadu'],
        606906: ['Tiruvannamalai','Tamil Nadu'],
        606907: ['Tiruvannamalai','Tamil Nadu'],
        606908: ['Tiruvannamalai','Tamil Nadu'],
        607001: ['Cuddalore','Tamil Nadu'],
        607002: ['Cuddalore','Tamil Nadu'],
        607003: ['Cuddalore','Tamil Nadu'],
        607004: ['Cuddalore','Tamil Nadu'],
        607005: ['Cuddalore','Tamil Nadu'],
        607006: ['Cuddalore','Tamil Nadu'],
        607101: ['Cuddalore','Tamil Nadu'],
        607102: ['Cuddalore','Tamil Nadu'],
        607103: ['Cuddalore','Tamil Nadu'],
        607104: ['Cuddalore','Tamil Nadu'],
        607105: ['Cuddalore','Tamil Nadu'],
        607106: ['Cuddalore','Tamil Nadu'],
        607107: ['Villupuram','Tamil Nadu'],
        607108: ['Cuddalore','Tamil Nadu'],
        607109: ['Cuddalore','Tamil Nadu'],
        607112: ['Cuddalore','Tamil Nadu'],
        607201: ['Villupuram','Tamil Nadu'],
        607202: ['Villupuram','Tamil Nadu'],
        607203: ['Villupuram','Tamil Nadu'],
        607204: ['Villupuram','Tamil Nadu'],
        607205: ['Cuddalore','Tamil Nadu'],
        607209: ['Villupuram','Tamil Nadu'],
        607301: ['Cuddalore','Tamil Nadu'],
        607302: ['Cuddalore','Tamil Nadu'],
        607303: ['Cuddalore','Tamil Nadu'],
        607308: ['Cuddalore','Tamil Nadu'],
        607401: ['Cuddalore','Tamil Nadu'],
        607801: ['Cuddalore','Tamil Nadu'],
        607802: ['Cuddalore','Tamil Nadu'],
        607803: ['Cuddalore','Tamil Nadu'],
        607804: ['Cuddalore','Tamil Nadu'],
        607805: ['Cuddalore','Tamil Nadu'],
        607807: ['Cuddalore','Tamil Nadu'],
        608001: ['Cuddalore','Tamil Nadu'],
        608002: ['Cuddalore','Tamil Nadu'],
        608102: ['Cuddalore','Tamil Nadu'],
        608201: ['Cuddalore','Tamil Nadu'],
        608301: ['Cuddalore','Tamil Nadu'],
        608302: ['Cuddalore','Tamil Nadu'],
        608303: ['Cuddalore','Tamil Nadu'],
        608304: ['Cuddalore','Tamil Nadu'],
        608305: ['Cuddalore','Tamil Nadu'],
        608306: ['Cuddalore','Tamil Nadu'],
        608401: ['Cuddalore','Tamil Nadu'],
        608501: ['Cuddalore','Tamil Nadu'],
        608502: ['Cuddalore','Tamil Nadu'],
        608601: ['Cuddalore','Tamil Nadu'],
        608602: ['Cuddalore','Tamil Nadu'],
        608701: ['Cuddalore','Tamil Nadu'],
        608702: ['Cuddalore','Tamil Nadu'],
        608703: ['Cuddalore','Tamil Nadu'],
        608704: ['Cuddalore','Tamil Nadu'],
        608801: ['Cuddalore','Tamil Nadu'],
        608901: ['Ariyalur','Tamil Nadu'],
        609001: ['Nagapattinam','Tamil Nadu'],
        609003: ['Nagapattinam','Tamil Nadu'],
        609101: ['Nagapattinam','Tamil Nadu'],
        609102: ['Nagapattinam','Tamil Nadu'],
        609103: ['Nagapattinam','Tamil Nadu'],
        609104: ['Nagapattinam','Tamil Nadu'],
        609105: ['Nagapattinam','Tamil Nadu'],
        609106: ['Nagapattinam','Tamil Nadu'],
        609107: ['Nagapattinam','Tamil Nadu'],
        609108: ['Nagapattinam','Tamil Nadu'],
        609109: ['Nagapattinam','Tamil Nadu'],
        609110: ['Nagapattinam','Tamil Nadu'],
        609111: ['Nagapattinam','Tamil Nadu'],
        609112: ['Nagapattinam','Tamil Nadu'],
        609113: ['Nagapattinam','Tamil Nadu'],
        609114: ['Nagapattinam','Tamil Nadu'],
        609115: ['Nagapattinam','Tamil Nadu'],
        609116: ['Nagapattinam','Tamil Nadu'],
        609117: ['Nagapattinam','Tamil Nadu'],
        609118: ['Nagapattinam','Tamil Nadu'],
        609201: ['Nagapattinam','Tamil Nadu'],
        609202: ['Nagapattinam','Tamil Nadu'],
        609203: ['Nagapattinam','Tamil Nadu'],
        609204: ['Thanjavur','Tamil Nadu'],
        609205: ['Nagapattinam','Tamil Nadu'],
        609301: ['Nagapattinam','Tamil Nadu'],
        609302: ['Nagapattinam','Tamil Nadu'],
        609303: ['Nagapattinam','Tamil Nadu'],
        609304: ['Nagapattinam','Tamil Nadu'],
        609305: ['Nagapattinam','Tamil Nadu'],
        609306: ['Nagapattinam','Tamil Nadu'],
        609307: ['Nagapattinam','Tamil Nadu'],
        609308: ['Nagapattinam','Tamil Nadu'],
        609309: ['Nagapattinam','Tamil Nadu'],
        609310: ['Nagapattinam','Tamil Nadu'],
        609311: ['Nagapattinam','Tamil Nadu'],
        609312: ['Nagapattinam','Tamil Nadu'],
        609313: ['Nagapattinam','Tamil Nadu'],
        609314: ['Nagapattinam','Tamil Nadu'],
        609401: ['Nagapattinam','Tamil Nadu'],
        609402: ['Nagapattinam','Tamil Nadu'],
        609403: ['Nagapattinam','Tamil Nadu'],
        609404: ['Nagapattinam','Tamil Nadu'],
        609405: ['Tiruvarur','Tamil Nadu'],
        609501: ['Nagapattinam','Tamil Nadu'],
        609502: ['Tiruvarur','Tamil Nadu'],
        609503: ['Tiruvarur','Tamil Nadu'],
        609504: ['Tiruvarur','Tamil Nadu'],
        609601: ['Karaikal','Tamil Nadu'],
        609602: ['Karaikal','Tamil Nadu'],
        609603: ['Karaikal','Tamil Nadu'],
        609604: ['Karaikal','Tamil Nadu'],
        609605: ['Karaikal','Tamil Nadu'],
        609606: ['Karaikal','Tamil Nadu'],
        609607: ['Karaikal','Tamil Nadu'],
        609608: ['Tiruvarur','Tamil Nadu'],
        609609: ['Karaikal','Tamil Nadu'],
        609701: ['Nagapattinam','Tamil Nadu'],
        609702: ['Nagapattinam','Tamil Nadu'],
        609703: ['Nagapattinam','Tamil Nadu'],
        609704: ['Nagapattinam','Tamil Nadu'],
        609801: ['Nagapattinam','Tamil Nadu'],
        609802: ['Nagapattinam','Tamil Nadu'],
        609803: ['Nagapattinam','Tamil Nadu'],
        609804: ['Nagapattinam','Tamil Nadu'],
        609805: ['Nagapattinam','Tamil Nadu'],
        609806: ['Nagapattinam','Tamil Nadu'],
        609807: ['Thanjavur','Tamil Nadu'],
        609808: ['Nagapattinam','Tamil Nadu'],
        609810: ['Nagapattinam','Tamil Nadu'],
        609811: ['Nagapattinam','Tamil Nadu'],
        610001: ['Tiruvarur','Tamil Nadu'],
        610003: ['Tiruvarur','Tamil Nadu'],
        610004: ['Tiruvarur','Tamil Nadu'],
        610005: ['Nagapattinam','Tamil Nadu'],
        610101: ['Tiruvarur','Tamil Nadu'],
        610102: ['Tiruvarur','Tamil Nadu'],
        610103: ['Tiruvarur','Tamil Nadu'],
        610104: ['Tiruvarur','Tamil Nadu'],
        610105: ['Tiruvarur','Tamil Nadu'],
        610106: ['Tiruvarur','Tamil Nadu'],
        610107: ['Tiruvarur','Tamil Nadu'],
        610109: ['Tiruvarur','Tamil Nadu'],
        610201: ['Tiruvarur','Tamil Nadu'],
        610202: ['Tiruvarur','Tamil Nadu'],
        610203: ['Tiruvarur','Tamil Nadu'],
        610204: ['Nagapattinam','Tamil Nadu'],
        610205: ['Tiruvarur','Tamil Nadu'],
        610206: ['Tiruvarur','Tamil Nadu'],
        610207: ['Tiruvarur','Tamil Nadu'],
        611001: ['Nagapattinam','Tamil Nadu'],
        611002: ['Nagapattinam','Tamil Nadu'],
        611003: ['Nagapattinam','Tamil Nadu'],
        611101: ['Tiruvarur','Tamil Nadu'],
        611102: ['Nagapattinam','Tamil Nadu'],
        611103: ['Nagapattinam','Tamil Nadu'],
        611104: ['Nagapattinam','Tamil Nadu'],
        611105: ['Nagapattinam','Tamil Nadu'],
        611106: ['Nagapattinam','Tamil Nadu'],
        611108: ['Nagapattinam','Tamil Nadu'],
        611109: ['Nagapattinam','Tamil Nadu'],
        611110: ['Nagapattinam','Tamil Nadu'],
        611111: ['Nagapattinam','Tamil Nadu'],
        611112: ['Nagapattinam','Tamil Nadu'],
        612001: ['Thanjavur','Tamil Nadu'],
        612002: ['Thanjavur','Tamil Nadu'],
        612101: ['Thanjavur','Tamil Nadu'],
        612102: ['Thanjavur','Tamil Nadu'],
        612103: ['Thanjavur','Tamil Nadu'],
        612104: ['Thanjavur','Tamil Nadu'],
        612105: ['Thanjavur','Tamil Nadu'],
        612106: ['Thanjavur','Tamil Nadu'],
        612201: ['Nagapattinam','Tamil Nadu'],
        612202: ['Thanjavur','Tamil Nadu'],
        612203: ['Nagapattinam','Tamil Nadu'],
        612204: ['Thanjavur','Tamil Nadu'],
        612301: ['Thanjavur','Tamil Nadu'],
        612302: ['Thanjavur','Tamil Nadu'],
        612303: ['Thanjavur','Tamil Nadu'],
        612401: ['Thanjavur','Tamil Nadu'],
        612402: ['Thanjavur','Tamil Nadu'],
        612501: ['Thanjavur','Tamil Nadu'],
        612502: ['Thanjavur','Tamil Nadu'],
        612503: ['Thanjavur','Tamil Nadu'],
        612504: ['Thanjavur','Tamil Nadu'],
        612601: ['Tiruvarur','Tamil Nadu'],
        612602: ['Thanjavur','Tamil Nadu'],
        612603: ['Tiruvarur','Tamil Nadu'],
        612604: ['Tiruvarur','Tamil Nadu'],
        612605: ['Thanjavur','Tamil Nadu'],
        612610: ['Tiruvarur','Tamil Nadu'],
        612701: ['Thanjavur','Tamil Nadu'],
        612702: ['Thanjavur','Tamil Nadu'],
        612703: ['Thanjavur','Tamil Nadu'],
        612801: ['Tiruvarur','Tamil Nadu'],
        612802: ['Tiruvarur','Tamil Nadu'],
        612803: ['Tiruvarur','Tamil Nadu'],
        612804: ['Tiruvarur','Tamil Nadu'],
        612901: ['Ariyalur','Tamil Nadu'],
        612902: ['Ariyalur','Tamil Nadu'],
        612903: ['Ariyalur','Tamil Nadu'],
        612904: ['Ariyalur','Tamil Nadu'],
        612905: ['Ariyalur','Tamil Nadu'],
        613001: ['Thanjavur','Tamil Nadu'],
        613002: ['Thanjavur','Tamil Nadu'],
        613003: ['Thanjavur','Tamil Nadu'],
        613004: ['Thanjavur','Tamil Nadu'],
        613005: ['Thanjavur','Tamil Nadu'],
        613006: ['Thanjavur','Tamil Nadu'],
        613007: ['Thanjavur','Tamil Nadu'],
        613008: ['Thanjavur','Tamil Nadu'],
        613009: ['Thanjavur','Tamil Nadu'],
        613010: ['Thanjavur','Tamil Nadu'],
        613101: ['Thanjavur','Tamil Nadu'],
        613102: ['Thanjavur','Tamil Nadu'],
        613103: ['Thanjavur','Tamil Nadu'],
        613104: ['Thanjavur','Tamil Nadu'],
        613105: ['Thanjavur','Tamil Nadu'],
        613201: ['Thanjavur','Tamil Nadu'],
        613202: ['Thanjavur','Tamil Nadu'],
        613203: ['Thanjavur','Tamil Nadu'],
        613204: ['Thanjavur','Tamil Nadu'],
        613205: ['Thanjavur','Tamil Nadu'],
        613301: ['Pudukkottai','Tamil Nadu'],
        613303: ['Thanjavur','Tamil Nadu'],
        613401: ['Thanjavur','Tamil Nadu'],
        613402: ['Thanjavur','Tamil Nadu'],
        613403: ['Thanjavur','Tamil Nadu'],
        613501: ['Thanjavur','Tamil Nadu'],
        613502: ['Thanjavur','Tamil Nadu'],
        613503: ['Thanjavur','Tamil Nadu'],
        613504: ['Thanjavur','Tamil Nadu'],
        613601: ['Thanjavur','Tamil Nadu'],
        613602: ['Thanjavur','Tamil Nadu'],
        613701: ['Tiruvarur','Tamil Nadu'],
        613702: ['Tiruvarur','Tamil Nadu'],
        613703: ['Tiruvarur','Tamil Nadu'],
        613704: ['Tiruvarur','Tamil Nadu'],
        613705: ['Tiruvarur','Tamil Nadu'],
        614001: ['Tiruvarur','Tamil Nadu'],
        614013: ['Tiruvarur','Tamil Nadu'],
        614014: ['Tiruvarur','Tamil Nadu'],
        614015: ['Tiruvarur','Tamil Nadu'],
        614016: ['Tiruvarur','Tamil Nadu'],
        614017: ['Tiruvarur','Tamil Nadu'],
        614018: ['Tiruvarur','Tamil Nadu'],
        614019: ['Tiruvarur','Tamil Nadu'],
        614020: ['Tiruvarur','Tamil Nadu'],
        614101: ['Tiruvarur','Tamil Nadu'],
        614102: ['Thanjavur','Tamil Nadu'],
        614103: ['Tiruvarur','Tamil Nadu'],
        614202: ['Thanjavur','Tamil Nadu'],
        614203: ['Thanjavur','Tamil Nadu'],
        614204: ['Thanjavur','Tamil Nadu'],
        614205: ['Thanjavur','Tamil Nadu'],
        614206: ['Thanjavur','Tamil Nadu'],
        614207: ['Thanjavur','Tamil Nadu'],
        614208: ['Thanjavur','Tamil Nadu'],
        614210: ['Thanjavur','Tamil Nadu'],
        614211: ['Thanjavur','Tamil Nadu'],
        614301: ['Thanjavur','Tamil Nadu'],
        614302: ['Thanjavur','Tamil Nadu'],
        614303: ['Thanjavur','Tamil Nadu'],
        614401: ['Thanjavur','Tamil Nadu'],
        614402: ['Thanjavur','Tamil Nadu'],
        614403: ['Tiruvarur','Tamil Nadu'],
        614404: ['Tiruvarur','Tamil Nadu'],
        614601: ['Thanjavur','Tamil Nadu'],
        614602: ['Thanjavur','Tamil Nadu'],
        614612: ['Thanjavur','Tamil Nadu'],
        614613: ['Thanjavur','Tamil Nadu'],
        614614: ['Thanjavur','Tamil Nadu'],
        614615: ['Thanjavur','Tamil Nadu'],
        614616: ['Pudukkottai','Tamil Nadu'],
        614617: ['Pudukkottai','Tamil Nadu'],
        614618: ['Pudukkottai','Tamil Nadu'],
        614619: ['Pudukkottai','Tamil Nadu'],
        614620: ['Pudukkottai','Tamil Nadu'],
        614621: ['Pudukkottai','Tamil Nadu'],
        614622: ['Pudukkottai','Tamil Nadu'],
        614623: ['Thanjavur','Tamil Nadu'],
        614624: ['Pudukkottai','Tamil Nadu'],
        614625: ['Thanjavur','Tamil Nadu'],
        614626: ['Thanjavur','Tamil Nadu'],
        614628: ['Thanjavur','Tamil Nadu'],
        614629: ['Pudukkottai','Tamil Nadu'],
        614630: ['Pudukkottai','Tamil Nadu'],
        614701: ['Thanjavur','Tamil Nadu'],
        614702: ['Thanjavur','Tamil Nadu'],
        614703: ['Tiruvarur','Tamil Nadu'],
        614704: ['Tiruvarur','Tamil Nadu'],
        614705: ['Tiruvarur','Tamil Nadu'],
        614706: ['Tiruvarur','Tamil Nadu'],
        614707: ['Thanjavur','Tamil Nadu'],
        614708: ['Tiruvarur','Tamil Nadu'],
        614710: ['Tiruvarur','Tamil Nadu'],
        614711: ['Nagapattinam','Tamil Nadu'],
        614712: ['Nagapattinam','Tamil Nadu'],
        614713: ['Tiruvarur','Tamil Nadu'],
        614714: ['Nagapattinam','Tamil Nadu'],
        614715: ['Tiruvarur','Tamil Nadu'],
        614716: ['Tiruvarur','Tamil Nadu'],
        614717: ['Tiruvarur','Tamil Nadu'],
        614723: ['Thanjavur','Tamil Nadu'],
        614738: ['Tiruvarur','Tamil Nadu'],
        614801: ['Pudukkottai','Tamil Nadu'],
        614802: ['Thanjavur','Tamil Nadu'],
        614803: ['Thanjavur','Tamil Nadu'],
        614804: ['Thanjavur','Tamil Nadu'],
        614805: ['Pudukkottai','Tamil Nadu'],
        614806: ['Thanjavur','Tamil Nadu'],
        614807: ['Nagapattinam','Tamil Nadu'],
        614808: ['Nagapattinam','Tamil Nadu'],
        614809: ['Nagapattinam','Tamil Nadu'],
        614810: ['Nagapattinam','Tamil Nadu'],
        614901: ['Thanjavur','Tamil Nadu'],
        614902: ['Pudukkottai','Tamil Nadu'],
        614903: ['Thanjavur','Tamil Nadu'],
        614904: ['Thanjavur','Tamil Nadu'],
        614905: ['Thanjavur','Tamil Nadu'],
        614906: ['Thanjavur','Tamil Nadu'],
        620023: ['Tiruchirappalli','Tamil Nadu'],
        620024: ['Tiruchirappalli','Tamil Nadu'],
        620025: ['Tiruchirappalli','Tamil Nadu'],
        620026: ['Tiruchirappalli','Tamil Nadu'],
        620027: ['Tiruchirappalli','Tamil Nadu'],
        621011: ['Tiruchirappalli','Tamil Nadu'],
        621102: ['Tiruchirappalli','Tamil Nadu'],
        621111: ['Tiruchirappalli','Tamil Nadu'],
        621133: ['Perambalur','Tamil Nadu'],
        621204: ['Tiruchirappalli','Tamil Nadu'],
        621207: ['Tiruchirappalli','Tamil Nadu'],
        621301: ['Karur','Tamil Nadu'],
        621308: ['Tiruchirappalli','Tamil Nadu'],
        621316: ['Pudukkottai','Tamil Nadu'],
        621706: ['Tiruchirappalli','Tamil Nadu'],
        621730: ['Ariyalur','Tamil Nadu'],
        621731: ['Ariyalur','Tamil Nadu'],
        621804: ['Ariyalur','Tamil Nadu'],
        622001: ['Pudukkottai','Tamil Nadu'],
        622002: ['Pudukkottai','Tamil Nadu'],
        622003: ['Pudukkottai','Tamil Nadu'],
        622004: ['Pudukkottai','Tamil Nadu'],
        622005: ['Pudukkottai','Tamil Nadu'],
        622101: ['Pudukkottai','Tamil Nadu'],
        622102: ['Pudukkottai','Tamil Nadu'],
        622103: ['Pudukkottai','Tamil Nadu'],
        622104: ['Pudukkottai','Tamil Nadu'],
        622201: ['Pudukkottai','Tamil Nadu'],
        622202: ['Pudukkottai','Tamil Nadu'],
        622203: ['Pudukkottai','Tamil Nadu'],
        622204: ['Pudukkottai','Tamil Nadu'],
        622209: ['Pudukkottai','Tamil Nadu'],
        622301: ['Pudukkottai','Tamil Nadu'],
        622302: ['Pudukkottai','Tamil Nadu'],
        622303: ['Pudukkottai','Tamil Nadu'],
        622304: ['Pudukkottai','Tamil Nadu'],
        622401: ['Pudukkottai','Tamil Nadu'],
        622402: ['Pudukkottai','Tamil Nadu'],
        622403: ['Pudukkottai','Tamil Nadu'],
        622404: ['Pudukkottai','Tamil Nadu'],
        622407: ['Pudukkottai','Tamil Nadu'],
        622409: ['Pudukkottai','Tamil Nadu'],
        622411: ['Pudukkottai','Tamil Nadu'],
        622412: ['Pudukkottai','Tamil Nadu'],
        622422: ['Pudukkottai','Tamil Nadu'],
        622501: ['Pudukkottai','Tamil Nadu'],
        622502: ['Pudukkottai','Tamil Nadu'],
        622503: ['Pudukkottai','Tamil Nadu'],
        622504: ['Pudukkottai','Tamil Nadu'],
        622505: ['Pudukkottai','Tamil Nadu'],
        622506: ['Pudukkottai','Tamil Nadu'],
        622507: ['Pudukkottai','Tamil Nadu'],
        622515: ['Pudukkottai','Tamil Nadu'],
        623115: ['Ramanathapuram','Tamil Nadu'],
        623120: ['Ramanathapuram','Tamil Nadu'],
        623135: ['Ramanathapuram','Tamil Nadu'],
        623308: ['Ramanathapuram','Tamil Nadu'],
        623315: ['Ramanathapuram','Tamil Nadu'],
        623401: ['Ramanathapuram','Tamil Nadu'],
        623402: ['Ramanathapuram','Tamil Nadu'],
        623403: ['Ramanathapuram','Tamil Nadu'],
        623404: ['Ramanathapuram','Tamil Nadu'],
        623406: ['Ramanathapuram','Tamil Nadu'],
        623407: ['Ramanathapuram','Tamil Nadu'],
        623409: ['Ramanathapuram','Tamil Nadu'],
        623501: ['Ramanathapuram','Tamil Nadu'],
        623502: ['Ramanathapuram','Tamil Nadu'],
        623503: ['Ramanathapuram','Tamil Nadu'],
        623504: ['Ramanathapuram','Tamil Nadu'],
        623512: ['Ramanathapuram','Tamil Nadu'],
        623513: ['Ramanathapuram','Tamil Nadu'],
        623514: ['Ramanathapuram','Tamil Nadu'],
        623515: ['Ramanathapuram','Tamil Nadu'],
        623516: ['Ramanathapuram','Tamil Nadu'],
        623517: ['Ramanathapuram','Tamil Nadu'],
        623518: ['Ramanathapuram','Tamil Nadu'],
        623519: ['Ramanathapuram','Tamil Nadu'],
        623520: ['Ramanathapuram','Tamil Nadu'],
        623521: ['Ramanathapuram','Tamil Nadu'],
        623522: ['Ramanathapuram','Tamil Nadu'],
        623523: ['Ramanathapuram','Tamil Nadu'],
        623524: ['Ramanathapuram','Tamil Nadu'],
        623525: ['Ramanathapuram','Tamil Nadu'],
        623526: ['Ramanathapuram','Tamil Nadu'],
        623527: ['Ramanathapuram','Tamil Nadu'],
        623528: ['Ramanathapuram','Tamil Nadu'],
        623529: ['Ramanathapuram','Tamil Nadu'],
        623530: ['Ramanathapuram','Tamil Nadu'],
        623531: ['Ramanathapuram','Tamil Nadu'],
        623532: ['Ramanathapuram','Tamil Nadu'],
        623533: ['Ramanathapuram','Tamil Nadu'],
        623534: ['Ramanathapuram','Tamil Nadu'],
        623536: ['Ramanathapuram','Tamil Nadu'],
        623537: ['Ramanathapuram','Tamil Nadu'],
        623538: ['Ramanathapuram','Tamil Nadu'],
        623566: ['Ramanathapuram','Tamil Nadu'],
        623601: ['Ramanathapuram','Tamil Nadu'],
        623603: ['Ramanathapuram','Tamil Nadu'],
        623604: ['Ramanathapuram','Tamil Nadu'],
        623605: ['Ramanathapuram','Tamil Nadu'],
        623608: ['Ramanathapuram','Tamil Nadu'],
        623701: ['Ramanathapuram','Tamil Nadu'],
        623703: ['Ramanathapuram','Tamil Nadu'],
        623704: ['Ramanathapuram','Tamil Nadu'],
        623705: ['Ramanathapuram','Tamil Nadu'],
        623706: ['Ramanathapuram','Tamil Nadu'],
        623707: ['Ramanathapuram','Tamil Nadu'],
        623708: ['Ramanathapuram','Tamil Nadu'],
        623711: ['Ramanathapuram','Tamil Nadu'],
        623712: ['Ramanathapuram','Tamil Nadu'],
        623806: ['Ramanathapuram','Tamil Nadu'],
        624001: ['Dindigul','Tamil Nadu'],
        624002: ['Dindigul','Tamil Nadu'],
        624003: ['Dindigul','Tamil Nadu'],
        624004: ['Dindigul','Tamil Nadu'],
        624005: ['Dindigul','Tamil Nadu'],
        624101: ['Dindigul','Tamil Nadu'],
        624103: ['Dindigul','Tamil Nadu'],
        624201: ['Dindigul','Tamil Nadu'],
        624202: ['Dindigul','Tamil Nadu'],
        624204: ['Dindigul','Tamil Nadu'],
        624206: ['Dindigul','Tamil Nadu'],
        624208: ['Dindigul','Tamil Nadu'],
        624210: ['Dindigul','Tamil Nadu'],
        624211: ['Dindigul','Tamil Nadu'],
        624212: ['Dindigul','Tamil Nadu'],
        624216: ['Dindigul','Tamil Nadu'],
        624219: ['Dindigul','Tamil Nadu'],
        624220: ['Dindigul','Tamil Nadu'],
        624301: ['Dindigul','Tamil Nadu'],
        624302: ['Dindigul','Tamil Nadu'],
        624303: ['Dindigul','Tamil Nadu'],
        624304: ['Dindigul','Tamil Nadu'],
        624306: ['Dindigul','Tamil Nadu'],
        624307: ['Dindigul','Tamil Nadu'],
        624308: ['Dindigul','Tamil Nadu'],
        624601: ['Dindigul','Tamil Nadu'],
        624610: ['Dindigul','Tamil Nadu'],
        624612: ['Dindigul','Tamil Nadu'],
        624613: ['Dindigul','Tamil Nadu'],
        624614: ['Dindigul','Tamil Nadu'],
        624615: ['Dindigul','Tamil Nadu'],
        624616: ['Dindigul','Tamil Nadu'],
        624617: ['Dindigul','Tamil Nadu'],
        624618: ['Dindigul','Tamil Nadu'],
        624619: ['Dindigul','Tamil Nadu'],
        624620: ['Dindigul','Tamil Nadu'],
        624621: ['Dindigul','Tamil Nadu'],
        624622: ['Dindigul','Tamil Nadu'],
        624701: ['Dindigul','Tamil Nadu'],
        624702: ['Dindigul','Tamil Nadu'],
        624703: ['Dindigul','Tamil Nadu'],
        624704: ['Dindigul','Tamil Nadu'],
        624705: ['Dindigul','Tamil Nadu'],
        624706: ['Dindigul','Tamil Nadu'],
        624707: ['Dindigul','Tamil Nadu'],
        624708: ['Dindigul','Tamil Nadu'],
        624709: ['Dindigul','Tamil Nadu'],
        624710: ['Dindigul','Tamil Nadu'],
        624711: ['Dindigul','Tamil Nadu'],
        624712: ['Dindigul','Tamil Nadu'],
        624801: ['Dindigul','Tamil Nadu'],
        624802: ['Dindigul','Tamil Nadu'],
        625203: ['Theni','Tamil Nadu'],
        625512: ['Theni','Tamil Nadu'],
        625513: ['Theni','Tamil Nadu'],
        625515: ['Theni','Tamil Nadu'],
        625516: ['Theni','Tamil Nadu'],
        625517: ['Theni','Tamil Nadu'],
        625518: ['Theni','Tamil Nadu'],
        625519: ['Theni','Tamil Nadu'],
        625520: ['Theni','Tamil Nadu'],
        625521: ['Theni','Tamil Nadu'],
        625522: ['Theni','Tamil Nadu'],
        625523: ['Theni','Tamil Nadu'],
        625524: ['Theni','Tamil Nadu'],
        625525: ['Theni','Tamil Nadu'],
        625526: ['Theni','Tamil Nadu'],
        625528: ['Theni','Tamil Nadu'],
        625530: ['Theni','Tamil Nadu'],
        625531: ['Theni','Tamil Nadu'],
        625533: ['Theni','Tamil Nadu'],
        625534: ['Theni','Tamil Nadu'],
        625536: ['Theni','Tamil Nadu'],
        625540: ['Theni','Tamil Nadu'],
        625556: ['Theni','Tamil Nadu'],
        625562: ['Theni','Tamil Nadu'],
        625579: ['Theni','Tamil Nadu'],
        625582: ['Theni','Tamil Nadu'],
        625601: ['Theni','Tamil Nadu'],
        625602: ['Theni','Tamil Nadu'],
        625603: ['Theni','Tamil Nadu'],
        625604: ['Theni','Tamil Nadu'],
        625605: ['Theni','Tamil Nadu'],
        626001: ['Virudhunagar','Tamil Nadu'],
        626002: ['Virudhunagar','Tamil Nadu'],
        626003: ['Virudhunagar','Tamil Nadu'],
        626004: ['Virudhunagar','Tamil Nadu'],
        626005: ['Virudhunagar','Tamil Nadu'],
        626101: ['Virudhunagar','Tamil Nadu'],
        626102: ['Virudhunagar','Tamil Nadu'],
        626103: ['Virudhunagar','Tamil Nadu'],
        626104: ['Virudhunagar','Tamil Nadu'],
        626105: ['Virudhunagar','Tamil Nadu'],
        626106: ['Virudhunagar','Tamil Nadu'],
        626107: ['Virudhunagar','Tamil Nadu'],
        626108: ['Virudhunagar','Tamil Nadu'],
        626109: ['Virudhunagar','Tamil Nadu'],
        626110: ['Virudhunagar','Tamil Nadu'],
        626111: ['Virudhunagar','Tamil Nadu'],
        626112: ['Virudhunagar','Tamil Nadu'],
        626113: ['Virudhunagar','Tamil Nadu'],
        626114: ['Virudhunagar','Tamil Nadu'],
        626115: ['Virudhunagar','Tamil Nadu'],
        626116: ['Virudhunagar','Tamil Nadu'],
        626117: ['Virudhunagar','Tamil Nadu'],
        626118: ['Virudhunagar','Tamil Nadu'],
        626119: ['Virudhunagar','Tamil Nadu'],
        626121: ['Virudhunagar','Tamil Nadu'],
        626122: ['Virudhunagar','Tamil Nadu'],
        626123: ['Virudhunagar','Tamil Nadu'],
        626124: ['Virudhunagar','Tamil Nadu'],
        626125: ['Virudhunagar','Tamil Nadu'],
        626126: ['Virudhunagar','Tamil Nadu'],
        626127: ['Virudhunagar','Tamil Nadu'],
        626128: ['Virudhunagar','Tamil Nadu'],
        626129: ['Virudhunagar','Tamil Nadu'],
        626130: ['Virudhunagar','Tamil Nadu'],
        626131: ['Virudhunagar','Tamil Nadu'],
        626132: ['Virudhunagar','Tamil Nadu'],
        626133: ['Virudhunagar','Tamil Nadu'],
        626134: ['Virudhunagar','Tamil Nadu'],
        626135: ['Virudhunagar','Tamil Nadu'],
        626136: ['Virudhunagar','Tamil Nadu'],
        626137: ['Virudhunagar','Tamil Nadu'],
        626138: ['Virudhunagar','Tamil Nadu'],
        626139: ['Virudhunagar','Tamil Nadu'],
        626140: ['Virudhunagar','Tamil Nadu'],
        626141: ['Virudhunagar','Tamil Nadu'],
        626142: ['Virudhunagar','Tamil Nadu'],
        626149: ['Virudhunagar','Tamil Nadu'],
        626188: ['Virudhunagar','Tamil Nadu'],
        626189: ['Virudhunagar','Tamil Nadu'],
        626201: ['Virudhunagar','Tamil Nadu'],
        626202: ['Virudhunagar','Tamil Nadu'],
        626203: ['Virudhunagar','Tamil Nadu'],
        626204: ['Virudhunagar','Tamil Nadu'],
        626205: ['Virudhunagar','Tamil Nadu'],
        626607: ['Virudhunagar','Tamil Nadu'],
        626612: ['Virudhunagar','Tamil Nadu'],
        627001: ['Tirunelveli','Tamil Nadu'],
        627002: ['Tirunelveli','Tamil Nadu'],
        627003: ['Tirunelveli','Tamil Nadu'],
        627004: ['Tirunelveli','Tamil Nadu'],
        627005: ['Tirunelveli','Tamil Nadu'],
        627006: ['Tirunelveli','Tamil Nadu'],
        627007: ['Tirunelveli','Tamil Nadu'],
        627008: ['Tirunelveli','Tamil Nadu'],
        627009: ['Tirunelveli','Tamil Nadu'],
        627010: ['Tirunelveli','Tamil Nadu'],
        627011: ['Tirunelveli','Tamil Nadu'],
        627012: ['Tirunelveli','Tamil Nadu'],
        627101: ['Tirunelveli','Tamil Nadu'],
        627102: ['Tirunelveli','Tamil Nadu'],
        627103: ['Tirunelveli','Tamil Nadu'],
        627104: ['Tirunelveli','Tamil Nadu'],
        627105: ['Tirunelveli','Tamil Nadu'],
        627106: ['Tirunelveli','Tamil Nadu'],
        627107: ['Tirunelveli','Tamil Nadu'],
        627108: ['Tirunelveli','Tamil Nadu'],
        627109: ['Tirunelveli','Tamil Nadu'],
        627110: ['Tirunelveli','Tamil Nadu'],
        627111: ['Tirunelveli','Tamil Nadu'],
        627112: ['Tirunelveli','Tamil Nadu'],
        627113: ['Tirunelveli','Tamil Nadu'],
        627114: ['Tirunelveli','Tamil Nadu'],
        627115: ['Tirunelveli','Tamil Nadu'],
        627116: ['Tirunelveli','Tamil Nadu'],
        627117: ['Tirunelveli','Tamil Nadu'],
        627118: ['Tirunelveli','Tamil Nadu'],
        627119: ['Tirunelveli','Tamil Nadu'],
        627120: ['Tirunelveli','Tamil Nadu'],
        627127: ['Tirunelveli','Tamil Nadu'],
        627133: ['Tirunelveli','Tamil Nadu'],
        627151: ['Tirunelveli','Tamil Nadu'],
        627152: ['Tirunelveli','Tamil Nadu'],
        627201: ['Tirunelveli','Tamil Nadu'],
        627202: ['Tirunelveli','Tamil Nadu'],
        627351: ['Tirunelveli','Tamil Nadu'],
        627352: ['Tirunelveli','Tamil Nadu'],
        627353: ['Tirunelveli','Tamil Nadu'],
        627354: ['Tirunelveli','Tamil Nadu'],
        627355: ['Tirunelveli','Tamil Nadu'],
        627356: ['Tirunelveli','Tamil Nadu'],
        627357: ['Tirunelveli','Tamil Nadu'],
        627358: ['Tirunelveli','Tamil Nadu'],
        627359: ['Tirunelveli','Tamil Nadu'],
        627401: ['Tirunelveli','Tamil Nadu'],
        627412: ['Tirunelveli','Tamil Nadu'],
        627413: ['Tirunelveli','Tamil Nadu'],
        627414: ['Tirunelveli','Tamil Nadu'],
        627415: ['Tirunelveli','Tamil Nadu'],
        627416: ['Tirunelveli','Tamil Nadu'],
        627417: ['Tirunelveli','Tamil Nadu'],
        627418: ['Tirunelveli','Tamil Nadu'],
        627420: ['Tirunelveli','Tamil Nadu'],
        627421: ['Tirunelveli','Tamil Nadu'],
        627422: ['Tirunelveli','Tamil Nadu'],
        627423: ['Tirunelveli','Tamil Nadu'],
        627424: ['Tirunelveli','Tamil Nadu'],
        627425: ['Tirunelveli','Tamil Nadu'],
        627426: ['Tirunelveli','Tamil Nadu'],
        627427: ['Tirunelveli','Tamil Nadu'],
        627428: ['Tirunelveli','Tamil Nadu'],
        627451: ['Tirunelveli','Tamil Nadu'],
        627452: ['Tirunelveli','Tamil Nadu'],
        627453: ['Tirunelveli','Tamil Nadu'],
        627501: ['Tirunelveli','Tamil Nadu'],
        627502: ['Tirunelveli','Tamil Nadu'],
        627601: ['Tiruchirappalli','Tamil Nadu'],
        627602: ['Tirunelveli','Tamil Nadu'],
        627603: ['Tirunelveli','Tamil Nadu'],
        627604: ['Tirunelveli','Tamil Nadu'],
        627651: ['Tirunelveli','Tamil Nadu'],
        627652: ['Tirunelveli','Tamil Nadu'],
        627654: ['Tirunelveli','Tamil Nadu'],
        627657: ['Tirunelveli','Tamil Nadu'],
        627713: ['Tirunelveli','Tamil Nadu'],
        627719: ['Tirunelveli','Tamil Nadu'],
        627751: ['Tirunelveli','Tamil Nadu'],
        627753: ['Tirunelveli','Tamil Nadu'],
        627754: ['Tirunelveli','Tamil Nadu'],
        627755: ['Tirunelveli','Tamil Nadu'],
        627756: ['Tirunelveli','Tamil Nadu'],
        627757: ['Tirunelveli','Tamil Nadu'],
        627758: ['Tirunelveli','Tamil Nadu'],
        627759: ['Tirunelveli','Tamil Nadu'],
        627760: ['Tirunelveli','Tamil Nadu'],
        627761: ['Tirunelveli','Tamil Nadu'],
        627764: ['Tirunelveli','Tamil Nadu'],
        627802: ['Tirunelveli','Tamil Nadu'],
        627803: ['Tirunelveli','Tamil Nadu'],
        627804: ['Tirunelveli','Tamil Nadu'],
        627805: ['Tirunelveli','Tamil Nadu'],
        627806: ['Tirunelveli','Tamil Nadu'],
        627807: ['Tirunelveli','Tamil Nadu'],
        627808: ['Tirunelveli','Tamil Nadu'],
        627809: ['Tirunelveli','Tamil Nadu'],
        627811: ['Tirunelveli','Tamil Nadu'],
        627812: ['Tirunelveli','Tamil Nadu'],
        627813: ['Tirunelveli','Tamil Nadu'],
        627814: ['Tirunelveli','Tamil Nadu'],
        627818: ['Tirunelveli','Tamil Nadu'],
        627851: ['Tirunelveli','Tamil Nadu'],
        627852: ['Tirunelveli','Tamil Nadu'],
        627853: ['Tirunelveli','Tamil Nadu'],
        627854: ['Tirunelveli','Tamil Nadu'],
        627855: ['Tirunelveli','Tamil Nadu'],
        627856: ['Tirunelveli','Tamil Nadu'],
        627857: ['Tirunelveli','Tamil Nadu'],
        627858: ['Tirunelveli','Tamil Nadu'],
        627859: ['Tirunelveli','Tamil Nadu'],
        627860: ['Tirunelveli','Tamil Nadu'],
        627861: ['Tirunelveli','Tamil Nadu'],
        627862: ['Tirunelveli','Tamil Nadu'],
        627951: ['Tirunelveli','Tamil Nadu'],
        627953: ['Tirunelveli','Tamil Nadu'],
        628001: ['Tuticorin','Tamil Nadu'],
        628002: ['Tuticorin','Tamil Nadu'],
        628003: ['Tuticorin','Tamil Nadu'],
        628004: ['Tuticorin','Tamil Nadu'],
        628005: ['Tuticorin','Tamil Nadu'],
        628006: ['Tuticorin','Tamil Nadu'],
        628007: ['Tuticorin','Tamil Nadu'],
        628008: ['Tuticorin','Tamil Nadu'],
        628101: ['Tuticorin','Tamil Nadu'],
        628102: ['Tuticorin','Tamil Nadu'],
        628103: ['Tuticorin','Tamil Nadu'],
        628104: ['Tuticorin','Tamil Nadu'],
        628105: ['Tuticorin','Tamil Nadu'],
        628151: ['Tuticorin','Tamil Nadu'],
        628152: ['Tuticorin','Tamil Nadu'],
        628201: ['Tuticorin','Tamil Nadu'],
        628202: ['Tuticorin','Tamil Nadu'],
        628203: ['Tuticorin','Tamil Nadu'],
        628204: ['Tuticorin','Tamil Nadu'],
        628205: ['Tuticorin','Tamil Nadu'],
        628206: ['Tuticorin','Tamil Nadu'],
        628208: ['Tuticorin','Tamil Nadu'],
        628209: ['Tuticorin','Tamil Nadu'],
        628210: ['Tuticorin','Tamil Nadu'],
        628211: ['Tuticorin','Tamil Nadu'],
        628212: ['Tuticorin','Tamil Nadu'],
        628213: ['Tuticorin','Tamil Nadu'],
        628215: ['Tuticorin','Tamil Nadu'],
        628216: ['Tuticorin','Tamil Nadu'],
        628217: ['Tuticorin','Tamil Nadu'],
        628218: ['Tuticorin','Tamil Nadu'],
        628219: ['Tuticorin','Tamil Nadu'],
        628229: ['Tuticorin','Tamil Nadu'],
        628251: ['Tuticorin','Tamil Nadu'],
        628252: ['Tuticorin','Tamil Nadu'],
        628301: ['Tuticorin','Tamil Nadu'],
        628302: ['Tuticorin','Tamil Nadu'],
        628303: ['Tuticorin','Tamil Nadu'],
        628304: ['Tuticorin','Tamil Nadu'],
        628401: ['Tuticorin','Tamil Nadu'],
        628402: ['Tuticorin','Tamil Nadu'],
        628501: ['Tuticorin','Tamil Nadu'],
        628502: ['Tuticorin','Tamil Nadu'],
        628503: ['Tuticorin','Tamil Nadu'],
        628552: ['Tuticorin','Tamil Nadu'],
        628601: ['Tuticorin','Tamil Nadu'],
        628612: ['Tuticorin','Tamil Nadu'],
        628613: ['Tuticorin','Tamil Nadu'],
        628614: ['Tuticorin','Tamil Nadu'],
        628615: ['Tuticorin','Tamil Nadu'],
        628616: ['Tuticorin','Tamil Nadu'],
        628617: ['Tuticorin','Tamil Nadu'],
        628618: ['Tuticorin','Tamil Nadu'],
        628619: ['Tuticorin','Tamil Nadu'],
        628620: ['Tuticorin','Tamil Nadu'],
        628621: ['Tuticorin','Tamil Nadu'],
        628622: ['Tuticorin','Tamil Nadu'],
        628623: ['Tuticorin','Tamil Nadu'],
        628653: ['Tuticorin','Tamil Nadu'],
        628656: ['Tuticorin','Tamil Nadu'],
        628701: ['Tuticorin','Tamil Nadu'],
        628702: ['Tuticorin','Tamil Nadu'],
        628703: ['Tuticorin','Tamil Nadu'],
        628704: ['Tuticorin','Tamil Nadu'],
        628712: ['Tuticorin','Tamil Nadu'],
        628714: ['Tuticorin','Tamil Nadu'],
        628716: ['Tuticorin','Tamil Nadu'],
        628718: ['Tuticorin','Tamil Nadu'],
        628720: ['Tuticorin','Tamil Nadu'],
        628721: ['Tuticorin','Tamil Nadu'],
        628722: ['Tuticorin','Tamil Nadu'],
        628751: ['Tuticorin','Tamil Nadu'],
        628752: ['Tuticorin','Tamil Nadu'],
        628753: ['Tuticorin','Tamil Nadu'],
        628801: ['Tuticorin','Tamil Nadu'],
        628802: ['Tuticorin','Tamil Nadu'],
        628809: ['Tuticorin','Tamil Nadu'],
        628851: ['Tuticorin','Tamil Nadu'],
        628901: ['Tuticorin','Tamil Nadu'],
        628902: ['Tuticorin','Tamil Nadu'],
        628903: ['Tuticorin','Tamil Nadu'],
        628904: ['Tuticorin','Tamil Nadu'],
        628905: ['Tuticorin','Tamil Nadu'],
        628906: ['Tuticorin','Tamil Nadu'],
        628907: ['Tuticorin','Tamil Nadu'],
        628908: ['Tuticorin','Tamil Nadu'],
        628952: ['Tuticorin','Tamil Nadu'],
        629001: ['Kanyakumari','Tamil Nadu'],
        629002: ['Kanyakumari','Tamil Nadu'],
        629003: ['Kanyakumari','Tamil Nadu'],
        629004: ['Kanyakumari','Tamil Nadu'],
        629101: ['Kanyakumari','Tamil Nadu'],
        629102: ['Kanyakumari','Tamil Nadu'],
        629151: ['Kanyakumari','Tamil Nadu'],
        629152: ['Kanyakumari','Tamil Nadu'],
        629153: ['Kanyakumari','Tamil Nadu'],
        629154: ['Kanyakumari','Tamil Nadu'],
        629155: ['Kanyakumari','Tamil Nadu'],
        629156: ['Kanyakumari','Tamil Nadu'],
        629157: ['Kanyakumari','Tamil Nadu'],
        629158: ['Kanyakumari','Tamil Nadu'],
        629159: ['Kanyakumari','Tamil Nadu'],
        629160: ['Kanyakumari','Tamil Nadu'],
        629161: ['Kanyakumari','Tamil Nadu'],
        629162: ['Kanyakumari','Tamil Nadu'],
        629163: ['Kanyakumari','Tamil Nadu'],
        629164: ['Kanyakumari','Tamil Nadu'],
        629165: ['Kanyakumari','Tamil Nadu'],
        629166: ['Kanyakumari','Tamil Nadu'],
        629167: ['Kanyakumari','Tamil Nadu'],
        629168: ['Kanyakumari','Tamil Nadu'],
        629169: ['Kanyakumari','Tamil Nadu'],
        629170: ['Kanyakumari','Tamil Nadu'],
        629171: ['Kanyakumari','Tamil Nadu'],
        629172: ['Kanyakumari','Tamil Nadu'],
        629173: ['Kanyakumari','Tamil Nadu'],
        629174: ['Kanyakumari','Tamil Nadu'],
        629175: ['Kanyakumari','Tamil Nadu'],
        629176: ['Kanyakumari','Tamil Nadu'],
        629177: ['Kanyakumari','Tamil Nadu'],
        629178: ['Kanyakumari','Tamil Nadu'],
        629179: ['Kanyakumari','Tamil Nadu'],
        629180: ['Kanyakumari','Tamil Nadu'],
        629193: ['Kanyakumari','Tamil Nadu'],
        629201: ['Kanyakumari','Tamil Nadu'],
        629202: ['Kanyakumari','Tamil Nadu'],
        629203: ['Kanyakumari','Tamil Nadu'],
        629204: ['Kanyakumari','Tamil Nadu'],
        629251: ['Kanyakumari','Tamil Nadu'],
        629252: ['Kanyakumari','Tamil Nadu'],
        629301: ['Kanyakumari','Tamil Nadu'],
        629302: ['Kanyakumari','Tamil Nadu'],
        629401: ['Kanyakumari','Tamil Nadu'],
        629402: ['Kanyakumari','Tamil Nadu'],
        629403: ['Kanyakumari','Tamil Nadu'],
        629501: ['Kanyakumari','Tamil Nadu'],
        629502: ['Kanyakumari','Tamil Nadu'],
        629601: ['Kanyakumari','Tamil Nadu'],
        629602: ['Kanyakumari','Tamil Nadu'],
        629701: ['Kanyakumari','Tamil Nadu'],
        629702: ['Kanyakumari','Tamil Nadu'],
        629703: ['Kanyakumari','Tamil Nadu'],
        629704: ['Kanyakumari','Tamil Nadu'],
        629801: ['Kanyakumari','Tamil Nadu'],
        629802: ['Kanyakumari','Tamil Nadu'],
        629803: ['Kanyakumari','Tamil Nadu'],
        629804: ['Kanyakumari','Tamil Nadu'],
        629809: ['Kanyakumari','Tamil Nadu'],
        629810: ['Kanyakumari','Tamil Nadu'],
        629851: ['Kanyakumari','Tamil Nadu'],
        629852: ['Kanyakumari','Tamil Nadu'],
        629901: ['Kanyakumari','Tamil Nadu'],
        630001: ['Sivaganga','Tamil Nadu'],
        630002: ['Sivaganga','Tamil Nadu'],
        630003: ['Sivaganga','Tamil Nadu'],
        630005: ['Sivaganga','Tamil Nadu'],
        630101: ['Sivaganga','Tamil Nadu'],
        630102: ['Sivaganga','Tamil Nadu'],
        630103: ['Sivaganga','Tamil Nadu'],
        630104: ['Sivaganga','Tamil Nadu'],
        630105: ['Sivaganga','Tamil Nadu'],
        630106: ['Sivaganga','Tamil Nadu'],
        630107: ['Sivaganga','Tamil Nadu'],
        630108: ['Sivaganga','Tamil Nadu'],
        630201: ['Sivaganga','Tamil Nadu'],
        630202: ['Sivaganga','Tamil Nadu'],
        630203: ['Sivaganga','Tamil Nadu'],
        630204: ['Sivaganga','Tamil Nadu'],
        630205: ['Sivaganga','Tamil Nadu'],
        630206: ['Sivaganga','Tamil Nadu'],
        630207: ['Sivaganga','Tamil Nadu'],
        630208: ['Sivaganga','Tamil Nadu'],
        630210: ['Sivaganga','Tamil Nadu'],
        630211: ['Sivaganga','Tamil Nadu'],
        630212: ['Sivaganga','Tamil Nadu'],
        630301: ['Sivaganga','Tamil Nadu'],
        630302: ['Sivaganga','Tamil Nadu'],
        630303: ['Sivaganga','Tamil Nadu'],
        630305: ['Sivaganga','Tamil Nadu'],
        630306: ['Sivaganga','Tamil Nadu'],
        630307: ['Sivaganga','Tamil Nadu'],
        630309: ['Sivaganga','Tamil Nadu'],
        630311: ['Sivaganga','Tamil Nadu'],
        630312: ['Sivaganga','Tamil Nadu'],
        630313: ['Sivaganga','Tamil Nadu'],
        630314: ['Sivaganga','Tamil Nadu'],
        630321: ['Sivaganga','Tamil Nadu'],
        630405: ['Sivaganga','Tamil Nadu'],
        630408: ['Sivaganga','Tamil Nadu'],
        630410: ['Sivaganga','Tamil Nadu'],
        630411: ['Sivaganga','Tamil Nadu'],
        630501: ['Sivaganga','Tamil Nadu'],
        630502: ['Sivaganga','Tamil Nadu'],
        630551: ['Sivaganga','Tamil Nadu'],
        630552: ['Sivaganga','Tamil Nadu'],
        630553: ['Sivaganga','Tamil Nadu'],
        630554: ['Sivaganga','Tamil Nadu'],
        630555: ['Sivaganga','Tamil Nadu'],
        630556: ['Sivaganga','Tamil Nadu'],
        630557: ['Sivaganga','Tamil Nadu'],
        630558: ['Sivaganga','Tamil Nadu'],
        630559: ['Sivaganga','Tamil Nadu'],
        630561: ['Sivaganga','Tamil Nadu'],
        630562: ['Sivaganga','Tamil Nadu'],
        630566: ['Sivaganga','Tamil Nadu'],
        630602: ['Sivaganga','Tamil Nadu'],
        630606: ['Sivaganga','Tamil Nadu'],
        630609: ['Sivaganga','Tamil Nadu'],
        630610: ['Sivaganga','Tamil Nadu'],
        630611: ['Sivaganga','Tamil Nadu'],
        630612: ['Sivaganga','Tamil Nadu'],
        630702: ['Sivaganga','Tamil Nadu'],
        630709: ['Sivaganga','Tamil Nadu'],
        630710: ['Sivaganga','Tamil Nadu'],
        630713: ['Sivaganga','Tamil Nadu'],
        631201: ['Tiruvallur','Tamil Nadu'],
        631202: ['Tiruvallur','Tamil Nadu'],
        631203: ['Tiruvallur','Tamil Nadu'],
        631204: ['Tiruvallur','Tamil Nadu'],
        631205: ['Tiruvallur','Tamil Nadu'],
        631206: ['Tiruvallur','Tamil Nadu'],
        631207: ['Tiruvallur','Tamil Nadu'],
        631208: ['Tiruvallur','Tamil Nadu'],
        631209: ['Tiruvallur','Tamil Nadu'],
        631210: ['Tiruvallur','Tamil Nadu'],
        631211: ['Tiruvallur','Tamil Nadu'],
        631212: ['Tiruvallur','Tamil Nadu'],
        631213: ['Tiruvallur','Tamil Nadu'],
        631301: ['Tiruvallur','Tamil Nadu'],
        631302: ['Tiruvallur','Tamil Nadu'],
        631303: ['Tiruvallur','Tamil Nadu'],
        631304: ['Tiruvallur','Tamil Nadu'],
        631402: ['Tiruvallur','Tamil Nadu'],
        631501: ['Kanchipuram','Tamil Nadu'],
        631502: ['Kanchipuram','Tamil Nadu'],
        631551: ['Kanchipuram','Tamil Nadu'],
        631552: ['Kanchipuram','Tamil Nadu'],
        631553: ['Kanchipuram','Tamil Nadu'],
        631561: ['Kanchipuram','Tamil Nadu'],
        631601: ['Kanchipuram','Tamil Nadu'],
        631603: ['Kanchipuram','Tamil Nadu'],
        631604: ['Kanchipuram','Tamil Nadu'],
        631605: ['Kanchipuram','Tamil Nadu'],
        631606: ['Kanchipuram','Tamil Nadu'],
        631701: ['Tiruvannamalai','Tamil Nadu'],
        631702: ['Tiruvannamalai','Tamil Nadu'],
        632301: ['Tiruvannamalai','Tamil Nadu'],
        632311: ['Tiruvannamalai','Tamil Nadu'],
        632313: ['Tiruvannamalai','Tamil Nadu'],
        632314: ['Tiruvannamalai','Tamil Nadu'],
        632315: ['Tiruvannamalai','Tamil Nadu'],
        632316: ['Tiruvannamalai','Tamil Nadu'],
        632317: ['Tiruvannamalai','Tamil Nadu'],
        632326: ['Tiruvannamalai','Tamil Nadu'],
        635001: ['Krishnagiri','Tamil Nadu'],
        635002: ['Krishnagiri','Tamil Nadu'],
        635101: ['Krishnagiri','Tamil Nadu'],
        635102: ['Krishnagiri','Tamil Nadu'],
        635103: ['Krishnagiri','Tamil Nadu'],
        635104: ['Krishnagiri','Tamil Nadu'],
        635105: ['Krishnagiri','Tamil Nadu'],
        635106: ['Krishnagiri','Tamil Nadu'],
        635107: ['Krishnagiri','Tamil Nadu'],
        635108: ['Krishnagiri','Tamil Nadu'],
        635109: ['Krishnagiri','Tamil Nadu'],
        635110: ['Krishnagiri','Tamil Nadu'],
        635111: ['Krishnagiri','Tamil Nadu'],
        635112: ['Krishnagiri','Tamil Nadu'],
        635113: ['Krishnagiri','Tamil Nadu'],
        635114: ['Krishnagiri','Tamil Nadu'],
        635115: ['Krishnagiri','Tamil Nadu'],
        635116: ['Krishnagiri','Tamil Nadu'],
        635117: ['Krishnagiri','Tamil Nadu'],
        635118: ['Krishnagiri','Tamil Nadu'],
        635119: ['Krishnagiri','Tamil Nadu'],
        635120: ['Krishnagiri','Tamil Nadu'],
        635121: ['Krishnagiri','Tamil Nadu'],
        635122: ['Krishnagiri','Tamil Nadu'],
        635123: ['Krishnagiri','Tamil Nadu'],
        635124: ['Krishnagiri','Tamil Nadu'],
        635126: ['Krishnagiri','Tamil Nadu'],
        635201: ['Krishnagiri','Tamil Nadu'],
        635202: ['Dharmapuri','Tamil Nadu'],
        635203: ['Krishnagiri','Tamil Nadu'],
        635204: ['Krishnagiri','Tamil Nadu'],
        635205: ['Dharmapuri','Tamil Nadu'],
        635206: ['Krishnagiri','Tamil Nadu'],
        635207: ['Krishnagiri','Tamil Nadu'],
        635301: ['Dharmapuri','Tamil Nadu'],
        635302: ['Dharmapuri','Tamil Nadu'],
        635303: ['Dharmapuri','Tamil Nadu'],
        635304: ['Krishnagiri','Tamil Nadu'],
        635305: ['Dharmapuri','Tamil Nadu'],
        635306: ['Krishnagiri','Tamil Nadu'],
        635307: ['Krishnagiri','Tamil Nadu'],
        636001: ['Salem','Tamil Nadu'],
        636002: ['Salem','Tamil Nadu'],
        636003: ['Salem','Tamil Nadu'],
        636004: ['Salem','Tamil Nadu'],
        636005: ['Salem','Tamil Nadu'],
        636006: ['Salem','Tamil Nadu'],
        636007: ['Salem','Tamil Nadu'],
        636008: ['Salem','Tamil Nadu'],
        636009: ['Salem','Tamil Nadu'],
        636010: ['Salem','Tamil Nadu'],
        636011: ['Salem','Tamil Nadu'],
        636012: ['Salem','Tamil Nadu'],
        636013: ['Salem','Tamil Nadu'],
        636014: ['Salem','Tamil Nadu'],
        636015: ['Salem','Tamil Nadu'],
        636016: ['Salem','Tamil Nadu'],
        636030: ['Salem','Tamil Nadu'],
        636101: ['Salem','Tamil Nadu'],
        636102: ['Salem','Tamil Nadu'],
        636103: ['Salem','Tamil Nadu'],
        636104: ['Salem','Tamil Nadu'],
        636105: ['Salem','Tamil Nadu'],
        636106: ['Salem','Tamil Nadu'],
        636107: ['Salem','Tamil Nadu'],
        636108: ['Salem','Tamil Nadu'],
        636109: ['Salem','Tamil Nadu'],
        636110: ['Salem','Tamil Nadu'],
        636111: ['Salem','Tamil Nadu'],
        636112: ['Salem','Tamil Nadu'],
        636113: ['Salem','Tamil Nadu'],
        636114: ['Salem','Tamil Nadu'],
        636115: ['Salem','Tamil Nadu'],
        636116: ['Salem','Tamil Nadu'],
        636117: ['Salem','Tamil Nadu'],
        636118: ['Namakkal','Tamil Nadu'],
        636119: ['Salem','Tamil Nadu'],
        636121: ['Salem','Tamil Nadu'],
        636122: ['Salem','Tamil Nadu'],
        636138: ['Salem','Tamil Nadu'],
        636139: ['Salem','Tamil Nadu'],
        636140: ['Salem','Tamil Nadu'],
        636141: ['Salem','Tamil Nadu'],
        636142: ['Salem','Tamil Nadu'],
        636201: ['Salem','Tamil Nadu'],
        636202: ['Namakkal','Tamil Nadu'],
        636203: ['Salem','Tamil Nadu'],
        636204: ['Salem','Tamil Nadu'],
        636301: ['Namakkal','Tamil Nadu'],
        636302: ['Salem','Tamil Nadu'],
        636303: ['Salem','Tamil Nadu'],
        636304: ['Salem','Tamil Nadu'],
        636305: ['Salem','Tamil Nadu'],
        636306: ['Salem','Tamil Nadu'],
        636307: ['Salem','Tamil Nadu'],
        636308: ['Salem','Tamil Nadu'],
        636309: ['Salem','Tamil Nadu'],
        636351: ['Salem','Tamil Nadu'],
        636352: ['Dharmapuri','Tamil Nadu'],
        636354: ['Salem','Tamil Nadu'],
        636401: ['Salem','Tamil Nadu'],
        636402: ['Salem','Tamil Nadu'],
        636403: ['Salem','Tamil Nadu'],
        636404: ['Salem','Tamil Nadu'],
        636406: ['Salem','Tamil Nadu'],
        636451: ['Salem','Tamil Nadu'],
        636452: ['Salem','Tamil Nadu'],
        636453: ['Salem','Tamil Nadu'],
        636454: ['Salem','Tamil Nadu'],
        636455: ['Salem','Tamil Nadu'],
        636456: ['Salem','Tamil Nadu'],
        636457: ['Salem','Tamil Nadu'],
        636458: ['Salem','Tamil Nadu'],
        636501: ['Salem','Tamil Nadu'],
        636502: ['Salem','Tamil Nadu'],
        636503: ['Salem','Tamil Nadu'],
        636601: ['Salem','Tamil Nadu'],
        636602: ['Salem','Tamil Nadu'],
        636701: ['Dharmapuri','Tamil Nadu'],
        636704: ['Dharmapuri','Tamil Nadu'],
        636705: ['Dharmapuri','Tamil Nadu'],
        636803: ['Dharmapuri','Tamil Nadu'],
        636804: ['Dharmapuri','Tamil Nadu'],
        636805: ['Dharmapuri','Tamil Nadu'],
        636806: ['Dharmapuri','Tamil Nadu'],
        636807: ['Dharmapuri','Tamil Nadu'],
        636808: ['Dharmapuri','Tamil Nadu'],
        636809: ['Dharmapuri','Tamil Nadu'],
        636810: ['Dharmapuri','Tamil Nadu'],
        636811: ['Dharmapuri','Tamil Nadu'],
        636812: ['Dharmapuri','Tamil Nadu'],
        636813: ['Dharmapuri','Tamil Nadu'],
        636902: ['Dharmapuri','Tamil Nadu'],
        636903: ['Dharmapuri','Tamil Nadu'],
        636904: ['Dharmapuri','Tamil Nadu'],
        636905: ['Dharmapuri','Tamil Nadu'],
        636906: ['Dharmapuri','Tamil Nadu'],
        637001: ['Namakkal','Tamil Nadu'],
        637002: ['Namakkal','Tamil Nadu'],
        637003: ['Namakkal','Tamil Nadu'],
        637013: ['Namakkal','Tamil Nadu'],
        637014: ['Namakkal','Tamil Nadu'],
        637015: ['Namakkal','Tamil Nadu'],
        637017: ['Namakkal','Tamil Nadu'],
        637018: ['Namakkal','Tamil Nadu'],
        637019: ['Namakkal','Tamil Nadu'],
        637020: ['Namakkal','Tamil Nadu'],
        637021: ['Namakkal','Tamil Nadu'],
        637101: ['Salem','Tamil Nadu'],
        637102: ['Salem','Tamil Nadu'],
        637103: ['Salem','Tamil Nadu'],
        637104: ['Salem','Tamil Nadu'],
        637105: ['Salem','Tamil Nadu'],
        637107: ['Salem','Tamil Nadu'],
        637201: ['Namakkal','Tamil Nadu'],
        637202: ['Namakkal','Tamil Nadu'],
        637203: ['Namakkal','Tamil Nadu'],
        637204: ['Namakkal','Tamil Nadu'],
        637205: ['Namakkal','Tamil Nadu'],
        637206: ['Namakkal','Tamil Nadu'],
        637207: ['Namakkal','Tamil Nadu'],
        637208: ['Namakkal','Tamil Nadu'],
        637209: ['Namakkal','Tamil Nadu'],
        637210: ['Namakkal','Tamil Nadu'],
        637211: ['Namakkal','Tamil Nadu'],
        637212: ['Namakkal','Tamil Nadu'],
        637213: ['Namakkal','Tamil Nadu'],
        637214: ['Namakkal','Tamil Nadu'],
        637215: ['Namakkal','Tamil Nadu'],
        637301: ['Salem','Tamil Nadu'],
        637302: ['Namakkal','Tamil Nadu'],
        637303: ['Salem','Tamil Nadu'],
        637304: ['Namakkal','Tamil Nadu'],
        637401: ['Namakkal','Tamil Nadu'],
        637402: ['Namakkal','Tamil Nadu'],
        637403: ['Namakkal','Tamil Nadu'],
        637404: ['Namakkal','Tamil Nadu'],
        637405: ['Namakkal','Tamil Nadu'],
        637406: ['Namakkal','Tamil Nadu'],
        637407: ['Namakkal','Tamil Nadu'],
        637408: ['Namakkal','Tamil Nadu'],
        637409: ['Namakkal','Tamil Nadu'],
        637410: ['Namakkal','Tamil Nadu'],
        637411: ['Namakkal','Tamil Nadu'],
        637412: ['Namakkal','Tamil Nadu'],
        637415: ['Salem','Tamil Nadu'],
        637501: ['Salem','Tamil Nadu'],
        637502: ['Salem','Tamil Nadu'],
        637503: ['Namakkal','Tamil Nadu'],
        637504: ['Salem','Tamil Nadu'],
        637505: ['Namakkal','Tamil Nadu'],
        638006: ['Namakkal','Tamil Nadu'],
        638008: ['Namakkal','Tamil Nadu'],
        638181: ['Namakkal','Tamil Nadu'],
        638182: ['Namakkal','Tamil Nadu'],
        638183: ['Namakkal','Tamil Nadu'],
        639001: ['Karur','Tamil Nadu'],
        639002: ['Karur','Tamil Nadu'],
        639003: ['Karur','Tamil Nadu'],
        639004: ['Karur','Tamil Nadu'],
        639006: ['Karur','Tamil Nadu'],
        639007: ['Karur','Tamil Nadu'],
        639008: ['Karur','Tamil Nadu'],
        639101: ['Tiruchirappalli','Tamil Nadu'],
        639105: ['Karur','Tamil Nadu'],
        639107: ['Karur','Tamil Nadu'],
        639108: ['Karur','Tamil Nadu'],
        639109: ['Karur','Tamil Nadu'],
        639110: ['Karur','Tamil Nadu'],
        639113: ['Karur','Tamil Nadu'],
        639114: ['Karur','Tamil Nadu'],
        639118: ['Karur','Tamil Nadu'],
        639119: ['Karur','Tamil Nadu'],
        639120: ['Karur','Tamil Nadu'],
        639136: ['Karur','Tamil Nadu'],
        639201: ['Karur','Tamil Nadu'],
        639203: ['Karur','Tamil Nadu'],
        639205: ['Karur','Tamil Nadu'],
        639206: ['Karur','Tamil Nadu'],
        639207: ['Karur','Tamil Nadu'],
        643001: ['Nilgiris','Tamil Nadu'],
        643002: ['Nilgiris','Tamil Nadu'],
        643003: ['Nilgiris','Tamil Nadu'],
        643004: ['Nilgiris','Tamil Nadu'],
        643005: ['Nilgiris','Tamil Nadu'],
        643006: ['Nilgiris','Tamil Nadu'],
        643007: ['Nilgiris','Tamil Nadu'],
        643101: ['Nilgiris','Tamil Nadu'],
        643102: ['Nilgiris','Tamil Nadu'],
        643103: ['Nilgiris','Tamil Nadu'],
        643105: ['Nilgiris','Tamil Nadu'],
        643201: ['Nilgiris','Tamil Nadu'],
        643202: ['Nilgiris','Tamil Nadu'],
        643203: ['Nilgiris','Tamil Nadu'],
        643204: ['Nilgiris','Tamil Nadu'],
        643205: ['Nilgiris','Tamil Nadu'],
        643206: ['Nilgiris','Tamil Nadu'],
        643207: ['Nilgiris','Tamil Nadu'],
        643209: ['Nilgiris','Tamil Nadu'],
        643211: ['Nilgiris','Tamil Nadu'],
        643212: ['Nilgiris','Tamil Nadu'],
        643213: ['Nilgiris','Tamil Nadu'],
        643214: ['Nilgiris','Tamil Nadu'],
        643215: ['Nilgiris','Tamil Nadu'],
        643216: ['Nilgiris','Tamil Nadu'],
        643217: ['Nilgiris','Tamil Nadu'],
        643218: ['Nilgiris','Tamil Nadu'],
        643219: ['Nilgiris','Tamil Nadu'],
        643220: ['Nilgiris','Tamil Nadu'],
        643221: ['Nilgiris','Tamil Nadu'],
        643223: ['Nilgiris','Tamil Nadu'],
        643224: ['Nilgiris','Tamil Nadu'],
        643225: ['Nilgiris','Tamil Nadu'],
        643226: ['Nilgiris','Tamil Nadu'],
        643231: ['Nilgiris','Tamil Nadu'],
        643233: ['Nilgiris','Tamil Nadu'],
        643236: ['Nilgiris','Tamil Nadu'],
        643237: ['Nilgiris','Tamil Nadu'],
        643238: ['Nilgiris','Tamil Nadu'],
        643239: ['Nilgiris','Tamil Nadu'],
        643240: ['Nilgiris','Tamil Nadu'],
        643241: ['Nilgiris','Tamil Nadu'],
        643242: ['Nilgiris','Tamil Nadu'],
        643243: ['Nilgiris','Tamil Nadu'],
        643253: ['Nilgiris','Tamil Nadu'],
        643270: ['Nilgiris','Tamil Nadu'],
        670644: ['Wayanad','Kerala'],
        670645: ['Wayanad','Kerala'],
        670646: ['Wayanad','Kerala'],
        670721: ['Wayanad','Kerala'],
        670731: ['Wayanad','Kerala'],
        671121: ['Kasargod','Kerala'],
        671122: ['Kasargod','Kerala'],
        671123: ['Kasargod','Kerala'],
        671124: ['Kasargod','Kerala'],
        671310: ['Kasargod','Kerala'],
        671311: ['Kasargod','Kerala'],
        671312: ['Kasargod','Kerala'],
        671313: ['Kasargod','Kerala'],
        671314: ['Kasargod','Kerala'],
        671315: ['Kasargod','Kerala'],
        671316: ['Kasargod','Kerala'],
        671317: ['Kasargod','Kerala'],
        671318: ['Kasargod','Kerala'],
        671319: ['Kasargod','Kerala'],
        671320: ['Kasaragod','Kerala'],
        671321: ['Kasargod','Kerala'],
        671322: ['Kasargod','Kerala'],
        671323: ['Kasargod','Kerala'],
        671324: ['Kasargod','Kerala'],
        671326: ['Kasargod','Kerala'],
        671348: ['Kasargod','Kerala'],
        671351: ['Kasargod','Kerala'],
        671531: ['Kasargod','Kerala'],
        671532: ['Kasargod','Kerala'],
        671533: ['Kasargod','Kerala'],
        671541: ['Kasargod','Kerala'],
        671542: ['Kasargod','Kerala'],
        671543: ['Kasargod','Kerala'],
        671551: ['Kasargod','Kerala'],
        671552: ['Kasargod','Kerala'],
        673121: ['Wayanad','Kerala'],
        673122: ['Wayanad','Kerala'],
        673123: ['Wayanad','Kerala'],
        673124: ['Kozhikode','Kerala'],
        673314: ['Malappuram','Kerala'],
        673507: ['Kozhikode','Kerala'],
        673570: ['Kozhikode','Kerala'],
        673573: ['Kozhikode','Kerala'],
        673575: ['Wayanad','Kerala'],
        673576: ['Wayanad','Kerala'],
        673577: ['Wayanad','Kerala'],
        673579: ['Wayanad','Kerala'],
        673581: ['Wayanad','Kerala'],
        673582: ['Kozhikode','Kerala'],
        673585: ['Kozhikode','Kerala'],
        673592: ['Wayanad','Kerala'],
        673595: ['Wayanad','Kerala'],
        673596: ['Wayanad','Kerala'],
        673601: ['Kozhikode','Kerala'],
        673603: ['Kozhikode','Kerala'],
        673634: ['Malappuram','Kerala'],
        673636: ['Malappuram','Kerala'],
        673637: ['Malappuram','Kerala'],
        673638: ['Malappuram','Kerala'],
        673639: ['Malappuram','Kerala'],
        673640: ['Malappuram','Kerala'],
        673641: ['Malappuram','Kerala'],
        673642: ['Malappuram','Kerala'],
        673645: ['Malappuram','Kerala'],
        673647: ['Malappuram','Kerala'],
        676101: ['Malappuram','Kerala'],
        676102: ['Malappuram','Kerala'],
        676103: ['Malappuram','Kerala'],
        676104: ['Malappuram','Kerala'],
        676105: ['Malappuram','Kerala'],
        676106: ['Malappuram','Kerala'],
        676107: ['Malappuram','Kerala'],
        676108: ['Malappuram','Kerala'],
        676109: ['Malappuram','Kerala'],
        676121: ['Malappuram','Kerala'],
        676122: ['Malappuram','Kerala'],
        676123: ['Malappuram','Kerala'],
        676301: ['Malappuram','Kerala'],
        676302: ['Malappuram','Kerala'],
        676303: ['Malappuram','Kerala'],
        676304: ['Malappuram','Kerala'],
        676305: ['Malappuram','Kerala'],
        676306: ['Malappuram','Kerala'],
        676307: ['Malappuram','Kerala'],
        676309: ['Malappuram','Kerala'],
        676311: ['Malappuram','Kerala'],
        676312: ['Malappuram','Kerala'],
        676317: ['Malappuram','Kerala'],
        676319: ['Malappuram','Kerala'],
        676320: ['Malappuram','Kerala'],
        676501: ['Malappuram','Kerala'],
        676502: ['Malappuram','Kerala'],
        676503: ['Malappuram','Kerala'],
        676504: ['Malappuram','Kerala'],
        676505: ['Malappuram','Kerala'],
        676506: ['Malappuram','Kerala'],
        676507: ['Malappuram','Kerala'],
        676508: ['Malappuram','Kerala'],
        676509: ['Malappuram','Kerala'],
        676510: ['Malappuram','Kerala'],
        676517: ['Malappuram','Kerala'],
        676519: ['Malappuram','Kerala'],
        676521: ['Malappuram','Kerala'],
        676522: ['Malappuram','Kerala'],
        676523: ['Malappuram','Kerala'],
        676525: ['Malappuram','Kerala'],
        676528: ['Malappuram','Kerala'],
        676541: ['Malappuram','Kerala'],
        676542: ['Malappuram','Kerala'],
        676551: ['Malappuram','Kerala'],
        676552: ['Malappuram','Kerala'],
        676553: ['Malappuram','Kerala'],
        676561: ['Malappuram','Kerala'],
        676562: ['Malappuram','Kerala'],
        679321: ['Malappuram','Kerala'],
        679322: ['Malappuram','Kerala'],
        679323: ['Malappuram','Kerala'],
        679324: ['Malappuram','Kerala'],
        679325: ['Malappuram','Kerala'],
        679326: ['Malappuram','Kerala'],
        679327: ['Malappuram','Kerala'],
        679328: ['Malappuram','Kerala'],
        679329: ['Malappuram','Kerala'],
        679330: ['Malappuram','Kerala'],
        679331: ['Malappuram','Kerala'],
        679332: ['Malappuram','Kerala'],
        679333: ['Malappuram','Kerala'],
        679334: ['Malappuram','Kerala'],
        679338: ['Malappuram','Kerala'],
        679339: ['Malappuram','Kerala'],
        679340: ['Malappuram','Kerala'],
        679341: ['Malappuram','Kerala'],
        679357: ['Malappuram','Kerala'],
        679571: ['Malappuram','Kerala'],
        679572: ['Malappuram','Kerala'],
        679573: ['Malappuram','Kerala'],
        679574: ['Malappuram','Kerala'],
        679575: ['Malappuram','Kerala'],
        679576: ['Malappuram','Kerala'],
        679577: ['Malappuram','Kerala'],
        679578: ['Malappuram','Kerala'],
        679579: ['Malappuram','Kerala'],
        679580: ['Malappuram','Kerala'],
        679581: ['Malappuram','Kerala'],
        679582: ['Malappuram','Kerala'],
        679583: ['Malappuram','Kerala'],
        679584: ['Malappuram','Kerala'],
        679585: ['Malappuram','Kerala'],
        679586: ['Malappuram','Kerala'],
        679587: ['Malappuram','Kerala'],
        679591: ['Malappuram','Kerala'],
        682001: ['Ernakulam','Kerala'],
        682002: ['Ernakulam','Kerala'],
        682003: ['Ernakulam','Kerala'],
        682004: ['Ernakulam','Kerala'],
        682005: ['Ernakulam','Kerala'],
        682006: ['Ernakulam','Kerala'],
        682007: ['Ernakulam','Kerala'],
        682008: ['Ernakulam','Kerala'],
        682009: ['Ernakulam','Kerala'],
        682010: ['Ernakulam','Kerala'],
        682011: ['Ernakulam','Kerala'],
        682012: ['Ernakulam','Kerala'],
        682013: ['Ernakulam','Kerala'],
        682015: ['Ernakulam','Kerala'],
        682016: ['Ernakulam','Kerala'],
        682017: ['Ernakulam','Kerala'],
        682018: ['Ernakulam','Kerala'],
        682019: ['Ernakulam','Kerala'],
        682020: ['Ernakulam','Kerala'],
        682021: ['Ernakulam','Kerala'],
        682022: ['Ernakulam','Kerala'],
        682023: ['Ernakulam','Kerala'],
        682024: ['Ernakulam','Kerala'],
        682025: ['Ernakulam','Kerala'],
        682026: ['Ernakulam','Kerala'],
        682027: ['Ernakulam','Kerala'],
        682028: ['Ernakulam','Kerala'],
        682029: ['Ernakulam','Kerala'],
        682030: ['Ernakulam','Kerala'],
        682031: ['Ernakulam','Kerala'],
        682032: ['Ernakulam','Kerala'],
        682033: ['Ernakulam','Kerala'],
        682034: ['Ernakulam','Kerala'],
        682035: ['Ernakulam','Kerala'],
        682036: ['Ernakulam','Kerala'],
        682037: ['Ernakulam','Kerala'],
        682038: ['Ernakulam','Kerala'],
        682039: ['Ernakulam','Kerala'],
        682040: ['Ernakulam','Kerala'],
        682041: ['Ernakulam','Kerala'],
        682042: ['Ernakulam','Kerala'],
        682301: ['Ernakulam','Kerala'],
        682302: ['Ernakulam','Kerala'],
        682303: ['Ernakulam','Kerala'],
        682304: ['Ernakulam','Kerala'],
        682305: ['Ernakulam','Kerala'],
        682306: ['Ernakulam','Kerala'],
        682307: ['Ernakulam','Kerala'],
        682308: ['Ernakulam','Kerala'],
        682309: ['Ernakulam','Kerala'],
        682310: ['Ernakulam','Kerala'],
        682311: ['Ernakulam','Kerala'],
        682312: ['Ernakulam','Kerala'],
        682313: ['Ernakulam','Kerala'],
        682314: ['Ernakulam','Kerala'],
        682315: ['Ernakulam','Kerala'],
        682316: ['Ernakulam','Kerala'],
        682317: ['Ernakulam','Kerala'],
        682501: ['Ernakulam','Kerala'],
        682502: ['Ernakulam','Kerala'],
        682503: ['Ernakulam','Kerala'],
        682504: ['Ernakulam','Kerala'],
        682505: ['Ernakulam','Kerala'],
        682506: ['Ernakulam','Kerala'],
        682507: ['Ernakulam','Kerala'],
        682508: ['Ernakulam','Kerala'],
        682509: ['Ernakulam','Kerala'],
        682511: ['Ernakulam','Kerala'],
        682551: ['Lakshadweep','Lakshadweep'],
        682552: ['Lakshadweep','Lakshadweep'],
        682553: ['Lakshadweep','Lakshadweep'],
        682554: ['Kochi','Lakshadweep'],
        682555: ['Lakshadweep','Lakshadweep'],
        682556: ['Lakshadweep','Lakshadweep'],
        682557: ['Lakshadweep','Lakshadweep'],
        682558: ['Lakshadweep','Lakshadweep'],
        682559: ['Lakshadweep','Lakshadweep'],
        683101: ['Ernakulam','Kerala'],
        683102: ['Ernakulam','Kerala'],
        683104: ['Ernakulam','Kerala'],
        683105: ['Ernakulam','Kerala'],
        683106: ['Ernakulam','Kerala'],
        683108: ['Ernakulam','Kerala'],
        683110: ['Ernakulam','Kerala'],
        683111: ['Ernakulam','Kerala'],
        683112: ['Ernakulam','Kerala'],
        683501: ['Ernakulam','Kerala'],
        683502: ['Ernakulam','Kerala'],
        683503: ['Ernakulam','Kerala'],
        683511: ['Ernakulam','Kerala'],
        683512: ['Ernakulam','Kerala'],
        683513: ['Ernakulam','Kerala'],
        683514: ['Ernakulam','Kerala'],
        683515: ['Ernakulam','Kerala'],
        683516: ['Ernakulam','Kerala'],
        683517: ['Ernakulam','Kerala'],
        683518: ['Ernakulam','Kerala'],
        683519: ['Ernakulam','Kerala'],
        683520: ['Ernakulam','Kerala'],
        683521: ['Ernakulam','Kerala'],
        683522: ['Ernakulam','Kerala'],
        683541: ['Ernakulam','Kerala'],
        683542: ['Ernakulam','Kerala'],
        683543: ['Ernakulam','Kerala'],
        683544: ['Ernakulam','Kerala'],
        683545: ['Ernakulam','Kerala'],
        683546: ['Ernakulam','Kerala'],
        683547: ['Ernakulam','Kerala'],
        683548: ['Ernakulam','Kerala'],
        683549: ['Ernakulam','Kerala'],
        683550: ['Ernakulam','Kerala'],
        683556: ['Ernakulam','Kerala'],
        683561: ['Ernakulam','Kerala'],
        683562: ['Ernakulam','Kerala'],
        683563: ['Ernakulam','Kerala'],
        683565: ['Ernakulam','Kerala'],
        683571: ['Ernakulam','Kerala'],
        683572: ['Ernakulam','Kerala'],
        683573: ['Ernakulam','Kerala'],
        683574: ['Ernakulam','Kerala'],
        683575: ['Ernakulam','Kerala'],
        683576: ['Ernakulam','Kerala'],
        683577: ['Ernakulam','Kerala'],
        683578: ['Ernakulam','Kerala'],
        683579: ['Ernakulam','Kerala'],
        683580: ['Ernakulam','Kerala'],
        683581: ['Ernakulam','Kerala'],
        683585: ['Ernakulam','Kerala'],
        683587: ['Ernakulam','Kerala'],
        683589: ['Ernakulam','Kerala'],
        683594: ['Ernakulam','Kerala'],
        685501: ['Idukki','Kerala'],
        685503: ['Idukki','Kerala'],
        685505: ['Idukki','Kerala'],
        685507: ['Idukki','Kerala'],
        685508: ['Idukki','Kerala'],
        685511: ['Idukki','Kerala'],
        685512: ['Idukki','Kerala'],
        685514: ['Idukki','Kerala'],
        685515: ['Idukki','Kerala'],
        685531: ['Idukki','Kerala'],
        685532: ['Idukki','Kerala'],
        685533: ['Idukki','Kerala'],
        685535: ['Idukki','Kerala'],
        685551: ['Idukki','Kerala'],
        685552: ['Idukki','Kerala'],
        685553: ['Idukki','Kerala'],
        685554: ['Idukki','Kerala'],
        685561: ['Idukki','Kerala'],
        685562: ['Idukki','Kerala'],
        685563: ['Idukki','Kerala'],
        685565: ['Idukki','Kerala'],
        685566: ['Idukki','Kerala'],
        685571: ['Idukki','Kerala'],
        685581: ['Idukki','Kerala'],
        685582: ['Idukki','Kerala'],
        685583: ['Idukki','Kerala'],
        685584: ['Idukki','Kerala'],
        685585: ['Idukki','Kerala'],
        685586: ['Idukki','Kerala'],
        685587: ['Idukki','Kerala'],
        685588: ['Idukki','Kerala'],
        685589: ['Idukki','Kerala'],
        685590: ['Idukki','Kerala'],
        685595: ['Idukki','Kerala'],
        685601: ['Idukki','Kerala'],
        685602: ['Idukki','Kerala'],
        685603: ['Idukki','Kerala'],
        685604: ['Idukki','Kerala'],
        685605: ['Idukki','Kerala'],
        685606: ['Idukki','Kerala'],
        685607: ['Idukki','Kerala'],
        685608: ['Idukki','Kerala'],
        685609: ['Idukki','Kerala'],
        685612: ['Idukki','Kerala'],
        685613: ['Idukki','Kerala'],
        685614: ['Idukki','Kerala'],
        685615: ['Idukki','Kerala'],
        685616: ['Idukki','Kerala'],
        685618: ['Idukki','Kerala'],
        685619: ['Idukki','Kerala'],
        685620: ['Idukki','Kerala'],
        686547: ['Pathanamthitta','Kerala'],
        686661: ['Ernakulam','Kerala'],
        686662: ['Ernakulam','Kerala'],
        686663: ['Ernakulam','Kerala'],
        686664: ['Ernakulam','Kerala'],
        686665: ['Ernakulam','Kerala'],
        686666: ['Ernakulam','Kerala'],
        686667: ['Ernakulam','Kerala'],
        686668: ['Ernakulam','Kerala'],
        686669: ['Ernakulam','Kerala'],
        686670: ['Ernakulam','Kerala'],
        686671: ['Ernakulam','Kerala'],
        686672: ['Ernakulam','Kerala'],
        686673: ['Ernakulam','Kerala'],
        686681: ['Ernakulam','Kerala'],
        686691: ['Ernakulam','Kerala'],
        686692: ['Ernakulam','Kerala'],
        686693: ['Ernakulam','Kerala'],
        688001: ['Alappuzha','Kerala'],
        688002: ['Alappuzha','Kerala'],
        688003: ['Alappuzha','Kerala'],
        688004: ['Alappuzha','Kerala'],
        688005: ['Alappuzha','Kerala'],
        688006: ['Alappuzha','Kerala'],
        688007: ['Alappuzha','Kerala'],
        688008: ['Alappuzha','Kerala'],
        688009: ['Alappuzha','Kerala'],
        688011: ['Alappuzha','Kerala'],
        688012: ['Alappuzha','Kerala'],
        688013: ['Alappuzha','Kerala'],
        688014: ['Alappuzha','Kerala'],
        688501: ['Alappuzha','Kerala'],
        688502: ['Alappuzha','Kerala'],
        688503: ['Alappuzha','Kerala'],
        688504: ['Alappuzha','Kerala'],
        688505: ['Alappuzha','Kerala'],
        688506: ['Alappuzha','Kerala'],
        688521: ['Alappuzha','Kerala'],
        688522: ['Alappuzha','Kerala'],
        688523: ['Alappuzha','Kerala'],
        688524: ['Alappuzha','Kerala'],
        688525: ['Alappuzha','Kerala'],
        688526: ['Alappuzha','Kerala'],
        688527: ['Alappuzha','Kerala'],
        688528: ['Alappuzha','Kerala'],
        688529: ['Alappuzha','Kerala'],
        688530: ['Alappuzha','Kerala'],
        688531: ['Alappuzha','Kerala'],
        688532: ['Alappuzha','Kerala'],
        688533: ['Alappuzha','Kerala'],
        688534: ['Alappuzha','Kerala'],
        688535: ['Alappuzha','Kerala'],
        688536: ['Alappuzha','Kerala'],
        688537: ['Alappuzha','Kerala'],
        688538: ['Alappuzha','Kerala'],
        688539: ['Alappuzha','Kerala'],
        688540: ['Alappuzha','Kerala'],
        688541: ['Alappuzha','Kerala'],
        688555: ['Alappuzha','Kerala'],
        688561: ['Alappuzha','Kerala'],
        688562: ['Alappuzha','Kerala'],
        688570: ['Alappuzha','Kerala'],
        688582: ['Alappuzha','Kerala'],
        689101: ['Pathanamthitta','Kerala'],
        689102: ['Pathanamthitta','Kerala'],
        689103: ['Pathanamthitta','Kerala'],
        689104: ['Pathanamthitta','Kerala'],
        689105: ['Pathanamthitta','Kerala'],
        689106: ['Pathanamthitta','Kerala'],
        689107: ['Pathanamthitta','Kerala'],
        689108: ['Pathanamthitta','Kerala'],
        689109: ['Alappuzha','Kerala'],
        689110: ['Pathanamthitta','Kerala'],
        689111: ['Pathanamthitta','Kerala'],
        689112: ['Alappuzha','Kerala'],
        689113: ['Pathanamthitta','Kerala'],
        689115: ['Pathanamthitta','Kerala'],
        689121: ['Alappuzha','Kerala'],
        689122: ['Alappuzha','Kerala'],
        689123: ['Alappuzha','Kerala'],
        689124: ['Alappuzha','Kerala'],
        689126: ['Alappuzha','Kerala'],
        689501: ['Pathanamthitta','Kerala'],
        689502: ['Pathanamthitta','Kerala'],
        689503: ['Pathanamthitta','Kerala'],
        689504: ['Alappuzha','Kerala'],
        689505: ['Alappuzha','Kerala'],
        689506: ['Alappuzha','Kerala'],
        689507: ['Pathanamthitta','Kerala'],
        689508: ['Alappuzha','Kerala'],
        689509: ['Alappuzha','Kerala'],
        689510: ['Alappuzha','Kerala'],
        689511: ['Alappuzha','Kerala'],
        689512: ['Alappuzha','Kerala'],
        689513: ['Pathanamthitta','Kerala'],
        689514: ['Pathanamthitta','Kerala'],
        689515: ['Pathanamthitta','Kerala'],
        689520: ['Alappuzha','Kerala'],
        689521: ['Alappuzha','Kerala'],
        689531: ['Pathanamthitta','Kerala'],
        689532: ['Pathanamthitta','Kerala'],
        689533: ['Alappuzha','Kerala'],
        689541: ['Pathanamthitta','Kerala'],
        689542: ['Pathanamthitta','Kerala'],
        689543: ['Pathanamthitta','Kerala'],
        689544: ['Pathanamthitta','Kerala'],
        689545: ['Pathanamthitta','Kerala'],
        689546: ['Pathanamthitta','Kerala'],
        689547: ['Pathanamthitta','Kerala'],
        689548: ['Pathanamthitta','Kerala'],
        689549: ['Pathanamthitta','Kerala'],
        689550: ['Pathanamthitta','Kerala'],
        689551: ['Pathanamthitta','Kerala'],
        689571: ['Alappuzha','Kerala'],
        689572: ['Alappuzha','Kerala'],
        689573: ['Alappuzha','Kerala'],
        689574: ['Alappuzha','Kerala'],
        689581: ['Pathanamthitta','Kerala'],
        689582: ['Pathanamthitta','Kerala'],
        689583: ['Pathanamthitta','Kerala'],
        689584: ['Pathanamthitta','Kerala'],
        689585: ['Pathanamthitta','Kerala'],
        689586: ['Pathanamthitta','Kerala'],
        689587: ['Pathanamthitta','Kerala'],
        689588: ['Pathanamthitta','Kerala'],
        689589: ['Pathanamthitta','Kerala'],
        689590: ['Pathanamthitta','Kerala'],
        689591: ['Pathanamthitta','Kerala'],
        689592: ['Pathanamthitta','Kerala'],
        689594: ['Pathanamthitta','Kerala'],
        689595: ['Alappuzha','Kerala'],
        689597: ['Pathanamthitta','Kerala'],
        689602: ['Pathanamthitta','Kerala'],
        689611: ['Pathanamthitta','Kerala'],
        689612: ['Pathanamthitta','Kerala'],
        689613: ['Pathanamthitta','Kerala'],
        689614: ['Pathanamthitta','Kerala'],
        689615: ['Pathanamthitta','Kerala'],
        689621: ['Pathanamthitta','Kerala'],
        689622: ['Alappuzha','Kerala'],
        689623: ['Alappuzha','Kerala'],
        689624: ['Alappuzha','Kerala'],
        689625: ['Pathanamthitta','Kerala'],
        689626: ['Pathanamthitta','Kerala'],
        689627: ['Alappuzha','Kerala'],
        689641: ['Pathanamthitta','Kerala'],
        689642: ['Pathanamthitta','Kerala'],
        689643: ['Pathanamthitta','Kerala'],
        689644: ['Pathanamthitta','Kerala'],
        689645: ['Pathanamthitta','Kerala'],
        689646: ['Pathanamthitta','Kerala'],
        689647: ['Pathanamthitta','Kerala'],
        689648: ['Pathanamthitta','Kerala'],
        689649: ['Pathanamthitta','Kerala'],
        689650: ['Pathanamthitta','Kerala'],
        689652: ['Pathanamthitta','Kerala'],
        689653: ['Pathanamthitta','Kerala'],
        689654: ['Pathanamthitta','Kerala'],
        689656: ['Pathanamthitta','Kerala'],
        689661: ['Pathanamthitta','Kerala'],
        689662: ['Pathanamthitta','Kerala'],
        689663: ['Pathanamthitta','Kerala'],
        689664: ['Pathanamthitta','Kerala'],
        689666: ['Pathanamthitta','Kerala'],
        689667: ['Pathanamthitta','Kerala'],
        689668: ['Pathanamthitta','Kerala'],
        689671: ['Pathanamthitta','Kerala'],
        689672: ['Pathanamthitta','Kerala'],
        689673: ['Pathanamthitta','Kerala'],
        689674: ['Pathanamthitta','Kerala'],
        689675: ['Pathanamthitta','Kerala'],
        689676: ['Pathanamthitta','Kerala'],
        689677: ['Pathanamthitta','Kerala'],
        689678: ['Pathanamthitta','Kerala'],
        689691: ['Pathanamthitta','Kerala'],
        689692: ['Pathanamthitta','Kerala'],
        689693: ['Pathanamthitta','Kerala'],
        689694: ['Pathanamthitta','Kerala'],
        689698: ['Pathanamthitta','Kerala'],
        689699: ['Pathanamthitta','Kerala'],
        689711: ['Pathanamthitta','Kerala'],
        690101: ['Alappuzha','Kerala'],
        690102: ['Alappuzha','Kerala'],
        690103: ['Alappuzha','Kerala'],
        690104: ['Alappuzha','Kerala'],
        690105: ['Alappuzha','Kerala'],
        690106: ['Alappuzha','Kerala'],
        690107: ['Alappuzha','Kerala'],
        690108: ['Alappuzha','Kerala'],
        690110: ['Alappuzha','Kerala'],
        690501: ['Alappuzha','Kerala'],
        690502: ['Alappuzha','Kerala'],
        690503: ['Alappuzha','Kerala'],
        690504: ['Alappuzha','Kerala'],
        690505: ['Alappuzha','Kerala'],
        690506: ['Alappuzha','Kerala'],
        690507: ['Alappuzha','Kerala'],
        690508: ['Alappuzha','Kerala'],
        690509: ['Alappuzha','Kerala'],
        690510: ['Alappuzha','Kerala'],
        690511: ['Alappuzha','Kerala'],
        690512: ['Alappuzha','Kerala'],
        690513: ['Alappuzha','Kerala'],
        690514: ['Alappuzha','Kerala'],
        690515: ['Alappuzha','Kerala'],
        690516: ['Alappuzha','Kerala'],
        690517: ['Alappuzha','Kerala'],
        690527: ['Alappuzha','Kerala'],
        690529: ['Alappuzha','Kerala'],
        690530: ['Alappuzha','Kerala'],
        690531: ['Alappuzha','Kerala'],
        690532: ['Alappuzha','Kerala'],
        690533: ['Alappuzha','Kerala'],
        690534: ['Alappuzha','Kerala'],
        690535: ['Alappuzha','Kerala'],
        690537: ['Alappuzha','Kerala'],
        690546: ['Kollam','Kerala'],
        690548: ['Alappuzha','Kerala'],
        690558: ['Alappuzha','Kerala'],
        690559: ['Alappuzha','Kerala'],
        690571: ['Alappuzha','Kerala'],
        690572: ['Alappuzha','Kerala'],
        691523: ['Pathanamthitta','Kerala'],
        691524: ['Pathanamthitta','Kerala'],
        691525: ['Pathanamthitta','Kerala'],
        691527: ['Kollam','Kerala'],
        691530: ['Pathanamthitta','Kerala'],
        691551: ['Pathanamthitta','Kerala'],
        691554: ['Pathanamthitta','Kerala'],
        691555: ['Pathanamthitta','Kerala'],
        691556: ['Pathanamthitta','Kerala'],
        695571: ['Thiruvananthapuram','Kerala'],
        700039: ['Kolkata','West Bengal'],
        700048: ['North 24 Parganas','West Bengal'],
        700049: ['North 24 Parganas','West Bengal'],
        700051: ['North 24 Parganas','West Bengal'],
        700055: ['North 24 Parganas','West Bengal'],
        700056: ['North 24 Parganas','West Bengal'],
        700057: ['North 24 Parganas','West Bengal'],
        700058: ['North 24 Parganas','West Bengal'],
        700059: ['North 24 Parganas','West Bengal'],
        700064: ['North 24 Parganas','West Bengal'],
        700070: ['South 24 Parganas','West Bengal'],
        700076: ['North 24 Parganas','West Bengal'],
        700079: ['North 24 Parganas','West Bengal'],
        700081: ['North 24 Parganas','West Bengal'],
        700083: ['North 24 Parganas','West Bengal'],
        700084: ['South 24 Parganas','West Bengal'],
        700091: ['North 24 Parganas','West Bengal'],
        700093: ['South 24 Parganas','West Bengal'],
        700096: ['South 24 Parganas','West Bengal'],
        700097: ['North 24 Parganas','West Bengal'],
        700100: ['Kolkata','West Bengal'],
        700101: ['North 24 Parganas','West Bengal'],
        700102: ['North 24 Parganas','West Bengal'],
        700103: ['South 24 Parganas','West Bengal'],
        700104: ['South 24 Parganas','West Bengal'],
        700105: ['South 24 Parganas','West Bengal'],
        700106: ['North 24 Parganas','West Bengal'],
        700109: ['North 24 Parganas','West Bengal'],
        700110: ['North 24 Parganas','West Bengal'],
        700111: ['North 24 Parganas','West Bengal'],
        700112: ['North 24 Parganas','West Bengal'],
        700113: ['North 24 Parganas','West Bengal'],
        700114: ['North 24 Parganas','West Bengal'],
        700115: ['North 24 Parganas','West Bengal'],
        700116: ['North 24 Parganas','West Bengal'],
        700117: ['North 24 Parganas','West Bengal'],
        700118: ['North 24 Parganas','West Bengal'],
        700119: ['North 24 Parganas','West Bengal'],
        700120: ['North 24 Parganas','West Bengal'],
        700121: ['North 24 Parganas','West Bengal'],
        700122: ['North 24 Parganas','West Bengal'],
        700123: ['North 24 Parganas','West Bengal'],
        700124: ['North 24 Parganas','West Bengal'],
        700125: ['North 24 Parganas','West Bengal'],
        700126: ['North 24 Parganas','West Bengal'],
        700127: ['North 24 Parganas','West Bengal'],
        700128: ['North 24 Parganas','West Bengal'],
        700129: ['North 24 Parganas','West Bengal'],
        700130: ['North 24 Parganas','West Bengal'],
        700131: ['North 24 Parganas','West Bengal'],
        700132: ['North 24 Parganas','West Bengal'],
        700133: ['North 24 Parganas','West Bengal'],
        700134: ['North 24 Parganas','West Bengal'],
        700135: ['North 24 Parganas','West Bengal'],
        700136: ['North 24 Parganas','West Bengal'],
        700137: ['South 24 Parganas','West Bengal'],
        700138: ['South 24 Parganas','West Bengal'],
        700139: ['South 24 Parganas','West Bengal'],
        700140: ['South 24 Parganas','West Bengal'],
        700141: ['South 24 Parganas','West Bengal'],
        700142: ['South 24 Parganas','West Bengal'],
        700143: ['South 24 Parganas','West Bengal'],
        700144: ['South 24 Parganas','West Bengal'],
        700145: ['South 24 Parganas','West Bengal'],
        700146: ['South 24 Parganas','West Bengal'],
        700147: ['South 24 Parganas','West Bengal'],
        700148: ['South 24 Parganas','West Bengal'],
        700149: ['South 24 Parganas','West Bengal'],
        700150: ['South 24 Parganas','West Bengal'],
        700151: ['South 24 Parganas','West Bengal'],
        700152: ['South 24 Parganas','West Bengal'],
        700153: ['South 24 Parganas','West Bengal'],
        700154: ['South 24 Parganas','West Bengal'],
        700155: ['North 24 Parganas','West Bengal'],
        700156: ['North 24 Parganas','West Bengal'],
        700157: ['North 24 Parganas','West Bengal'],
        700158: ['North 24 Parganas','West Bengal'],
        700159: ['North 24 Parganas','West Bengal'],
        700160: ['North 24 Parganas','West Bengal'],
        700161: ['North 24 Parganas','West Bengal'],
        700162: ['Kolkata','West Bengal'],
        700163: ['North 24 Parganas','West Bengal'],
        707004: ['Kohima','Nagaland'],
        711115: ['Howrah','West Bengal'],
        711206: ['Howrah','West Bengal'],
        712103: ['Hooghly','West Bengal'],
        712104: ['Hooghly','West Bengal'],
        712105: ['Hooghly','West Bengal'],
        712121: ['Hooghly','West Bengal'],
        712122: ['Hooghly','West Bengal'],
        712123: ['Hooghly','West Bengal'],
        712124: ['Hooghly','West Bengal'],
        712125: ['Hooghly','West Bengal'],
        712134: ['Hooghly','West Bengal'],
        712135: ['Hooghly','West Bengal'],
        712137: ['Hooghly','West Bengal'],
        712138: ['Hooghly','West Bengal'],
        712139: ['Hooghly','West Bengal'],
        712146: ['Hooghly','West Bengal'],
        712147: ['Hooghly','West Bengal'],
        712148: ['Hooghly','West Bengal'],
        712149: ['Hooghly','West Bengal'],
        712152: ['Hooghly','West Bengal'],
        712201: ['Hooghly','West Bengal'],
        712202: ['Hooghly','West Bengal'],
        712203: ['Hooghly','West Bengal'],
        712204: ['Hooghly','West Bengal'],
        712222: ['Hooghly','West Bengal'],
        712223: ['Hooghly','West Bengal'],
        712232: ['Hooghly','West Bengal'],
        712233: ['Hooghly','West Bengal'],
        712234: ['Hooghly','West Bengal'],
        712235: ['Hooghly','West Bengal'],
        712245: ['Hooghly','West Bengal'],
        712246: ['Hooghly','West Bengal'],
        712248: ['Hooghly','West Bengal'],
        712249: ['Hooghly','West Bengal'],
        712250: ['Hooghly','West Bengal'],
        712258: ['Hooghly','West Bengal'],
        712301: ['Hooghly','West Bengal'],
        712302: ['Hooghly','West Bengal'],
        712303: ['Hooghly','West Bengal'],
        712304: ['Hooghly','West Bengal'],
        712305: ['Hooghly','West Bengal'],
        712306: ['Hooghly','West Bengal'],
        712308: ['Hooghly','West Bengal'],
        712310: ['Hooghly','West Bengal'],
        712311: ['Hooghly','West Bengal'],
        712401: ['Hooghly','West Bengal'],
        712402: ['Hooghly','West Bengal'],
        712403: ['Hooghly','West Bengal'],
        712404: ['Hooghly','West Bengal'],
        712405: ['Hooghly','West Bengal'],
        712406: ['Hooghly','West Bengal'],
        712407: ['Hooghly','West Bengal'],
        712409: ['Hooghly','West Bengal'],
        712410: ['Hooghly','West Bengal'],
        712412: ['Hooghly','West Bengal'],
        712413: ['Hooghly','West Bengal'],
        712414: ['Hooghly','West Bengal'],
        712415: ['Hooghly','West Bengal'],
        712416: ['Hooghly','West Bengal'],
        712417: ['Hooghly','West Bengal'],
        712501: ['Hooghly','West Bengal'],
        712502: ['Hooghly','West Bengal'],
        712503: ['Hooghly','West Bengal'],
        712504: ['Hooghly','West Bengal'],
        712512: ['Hooghly','West Bengal'],
        712513: ['Hooghly','West Bengal'],
        712514: ['Hooghly','West Bengal'],
        712515: ['Hooghly','West Bengal'],
        712601: ['Hooghly','West Bengal'],
        712602: ['Hooghly','West Bengal'],
        712611: ['Hooghly','West Bengal'],
        712612: ['Hooghly','West Bengal'],
        712613: ['Hooghly','West Bengal'],
        712614: ['Hooghly','West Bengal'],
        712615: ['Hooghly','West Bengal'],
        712616: ['Hooghly','West Bengal'],
        712617: ['Hooghly','West Bengal'],
        712701: ['Hooghly','West Bengal'],
        712702: ['Hooghly','West Bengal'],
        712706: ['Hooghly','West Bengal'],
        713101: ['Bardhaman','West Bengal'],
        713102: ['Bardhaman','West Bengal'],
        713103: ['Bardhaman','West Bengal'],
        713104: ['Bardhaman','West Bengal'],
        713121: ['Bardhaman','West Bengal'],
        713122: ['Bardhaman','West Bengal'],
        713123: ['Bardhaman','West Bengal'],
        713124: ['Bardhaman','West Bengal'],
        713125: ['Bardhaman','West Bengal'],
        713126: ['Bardhaman','West Bengal'],
        713127: ['Bardhaman','West Bengal'],
        713128: ['Bardhaman','West Bengal'],
        713129: ['Bardhaman','West Bengal'],
        713130: ['Bardhaman','West Bengal'],
        713131: ['Bardhaman','West Bengal'],
        713132: ['Bardhaman','West Bengal'],
        713140: ['Bardhaman','West Bengal'],
        713141: ['Bardhaman','West Bengal'],
        713142: ['Bardhaman','West Bengal'],
        713143: ['Bardhaman','West Bengal'],
        713144: ['Bardhaman','West Bengal'],
        713145: ['Bardhaman','West Bengal'],
        713146: ['Bardhaman','West Bengal'],
        713147: ['Bardhaman','West Bengal'],
        713148: ['Bardhaman','West Bengal'],
        713149: ['Bardhaman','West Bengal'],
        713150: ['Bardhaman','West Bengal'],
        713151: ['Bardhaman','West Bengal'],
        713152: ['Bardhaman','West Bengal'],
        713153: ['Bardhaman','West Bengal'],
        713154: ['Bardhaman','West Bengal'],
        713166: ['Bardhaman','West Bengal'],
        713208: ['Bardhaman','West Bengal'],
        713211: ['Bardhaman','West Bengal'],
        713212: ['Bardhaman','West Bengal'],
        713213: ['Bardhaman','West Bengal'],
        713216: ['Bardhaman','West Bengal'],
        713217: ['Bardhaman','West Bengal'],
        713301: ['Bardhaman','West Bengal'],
        713302: ['Bardhaman','West Bengal'],
        713303: ['Bardhaman','West Bengal'],
        713304: ['Bardhaman','West Bengal'],
        713305: ['Bardhaman','West Bengal'],
        713315: ['Bardhaman','West Bengal'],
        713321: ['Bardhaman','West Bengal'],
        713322: ['Bardhaman','West Bengal'],
        713323: ['Bardhaman','West Bengal'],
        713324: ['Bardhaman','West Bengal'],
        713325: ['Bardhaman','West Bengal'],
        713326: ['Bardhaman','West Bengal'],
        713330: ['Bardhaman','West Bengal'],
        713331: ['Bardhaman','West Bengal'],
        713332: ['Bardhaman','West Bengal'],
        713333: ['Bardhaman','West Bengal'],
        713334: ['Bardhaman','West Bengal'],
        713335: ['Bardhaman','West Bengal'],
        713336: ['Bardhaman','West Bengal'],
        713337: ['Bardhaman','West Bengal'],
        713338: ['Bardhaman','West Bengal'],
        713339: ['Bardhaman','West Bengal'],
        713340: ['Bardhaman','West Bengal'],
        713341: ['Bardhaman','West Bengal'],
        713342: ['Bardhaman','West Bengal'],
        713343: ['Bardhaman','West Bengal'],
        713344: ['Bardhaman','West Bengal'],
        713346: ['Bardhaman','West Bengal'],
        713347: ['Bardhaman','West Bengal'],
        713357: ['Bardhaman','West Bengal'],
        713358: ['Bardhaman','West Bengal'],
        713359: ['Bardhaman','West Bengal'],
        713360: ['Bardhaman','West Bengal'],
        713361: ['Bardhaman','West Bengal'],
        713362: ['Bardhaman','West Bengal'],
        713363: ['Bardhaman','West Bengal'],
        713365: ['Bardhaman','West Bengal'],
        713369: ['Bardhaman','West Bengal'],
        713370: ['Bardhaman','West Bengal'],
        713371: ['Bardhaman','West Bengal'],
        713372: ['Bardhaman','West Bengal'],
        713373: ['Bardhaman','West Bengal'],
        713376: ['Bardhaman','West Bengal'],
        713378: ['Bardhaman','West Bengal'],
        713381: ['Bardhaman','West Bengal'],
        713384: ['Bardhaman','West Bengal'],
        713385: ['Bardhaman','West Bengal'],
        713386: ['Bardhaman','West Bengal'],
        713401: ['Bardhaman','West Bengal'],
        713403: ['Bardhaman','West Bengal'],
        713404: ['Bardhaman','West Bengal'],
        713405: ['Bardhaman','West Bengal'],
        713406: ['Bardhaman','West Bengal'],
        713407: ['Bardhaman','West Bengal'],
        713408: ['Bardhaman','West Bengal'],
        713409: ['Bardhaman','West Bengal'],
        713420: ['Bardhaman','West Bengal'],
        713421: ['Bardhaman','West Bengal'],
        713422: ['Bardhaman','West Bengal'],
        713423: ['Bardhaman','West Bengal'],
        713424: ['Bardhaman','West Bengal'],
        713426: ['Bardhaman','West Bengal'],
        713427: ['Bardhaman','West Bengal'],
        713428: ['Bardhaman','West Bengal'],
        713502: ['Bardhaman','West Bengal'],
        713512: ['Bardhaman','West Bengal'],
        713513: ['Bardhaman','West Bengal'],
        713514: ['Bardhaman','West Bengal'],
        713515: ['Bardhaman','West Bengal'],
        713519: ['Bardhaman','West Bengal'],
        713520: ['Bardhaman','West Bengal'],
        721101: ['West Midnapore','West Bengal'],
        721102: ['West Midnapore','West Bengal'],
        721121: ['West Midnapore','West Bengal'],
        721122: ['West Midnapore','West Bengal'],
        721124: ['West Midnapore','West Bengal'],
        721125: ['West Midnapore','West Bengal'],
        721126: ['West Midnapore','West Bengal'],
        721127: ['West Midnapore','West Bengal'],
        721128: ['West Midnapore','West Bengal'],
        721129: ['West Midnapore','West Bengal'],
        721130: ['West Midnapore','West Bengal'],
        721131: ['West Midnapore','West Bengal'],
        721132: ['West Midnapore','West Bengal'],
        721133: ['West Midnapore','West Bengal'],
        721134: ['East Midnapore','West Bengal'],
        721135: ['West Midnapore','West Bengal'],
        721136: ['West Midnapore','West Bengal'],
        721137: ['East Midnapore','West Bengal'],
        721139: ['East Midnapore','West Bengal'],
        721140: ['West Midnapore','West Bengal'],
        721143: ['West Midnapore','West Bengal'],
        721144: ['West Midnapore','West Bengal'],
        721145: ['West Midnapore','West Bengal'],
        721146: ['West Midnapore','West Bengal'],
        721147: ['West Midnapore','West Bengal'],
        721148: ['West Midnapore','West Bengal'],
        721149: ['West Midnapore','West Bengal'],
        721150: ['West Midnapore','West Bengal'],
        721151: ['East Midnapore','West Bengal'],
        721152: ['East Midnapore','West Bengal'],
        721153: ['East Midnapore','West Bengal'],
        721154: ['East Midnapore','West Bengal'],
        721155: ['West Midnapore','West Bengal'],
        721156: ['West Midnapore','West Bengal'],
        721157: ['West Midnapore','West Bengal'],
        721158: ['East Midnapore','West Bengal'],
        721159: ['West Midnapore','West Bengal'],
        721160: ['West Midnapore','West Bengal'],
        721161: ['West Midnapore','West Bengal'],
        721166: ['West Midnapore','West Bengal'],
        721171: ['East Midnapore','West Bengal'],
        721172: ['East Midnapore','West Bengal'],
        721201: ['West Midnapore','West Bengal'],
        721211: ['West Midnapore','West Bengal'],
        721212: ['West Midnapore','West Bengal'],
        721222: ['West Midnapore','West Bengal'],
        721232: ['West Midnapore','West Bengal'],
        721242: ['West Midnapore','West Bengal'],
        721253: ['West Midnapore','West Bengal'],
        721260: ['West Midnapore','West Bengal'],
        721301: ['West Midnapore','West Bengal'],
        721302: ['West Midnapore','West Bengal'],
        721303: ['West Midnapore','West Bengal'],
        721304: ['West Midnapore','West Bengal'],
        721305: ['West Midnapore','West Bengal'],
        721306: ['West Midnapore','West Bengal'],
        721401: ['East Midnapore','West Bengal'],
        721402: ['East Midnapore','West Bengal'],
        721403: ['East Midnapore','West Bengal'],
        721404: ['West Midnapore','West Bengal'],
        721405: ['East Midnapore','West Bengal'],
        721406: ['East Midnapore','West Bengal'],
        721420: ['West Midnapore','West Bengal'],
        721422: ['East Midnapore','West Bengal'],
        721423: ['East Midnapore','West Bengal'],
        721424: ['West Midnapore','West Bengal'],
        721425: ['East Midnapore','West Bengal'],
        721426: ['West Midnapore','West Bengal'],
        721427: ['East Midnapore','West Bengal'],
        721428: ['East Midnapore','West Bengal'],
        721429: ['East Midnapore','West Bengal'],
        721430: ['East Midnapore','West Bengal'],
        721431: ['East Midnapore','West Bengal'],
        721432: ['East Midnapore','West Bengal'],
        721433: ['East Midnapore','West Bengal'],
        721434: ['East Midnapore','West Bengal'],
        721435: ['West Midnapore','West Bengal'],
        721436: ['West Midnapore','West Bengal'],
        721437: ['West Midnapore','West Bengal'],
        721438: ['East Midnapore','West Bengal'],
        721439: ['East Midnapore','West Bengal'],
        721440: ['East Midnapore','West Bengal'],
        721441: ['East Midnapore','West Bengal'],
        721442: ['East Midnapore','West Bengal'],
        721443: ['East Midnapore','West Bengal'],
        721444: ['East Midnapore','West Bengal'],
        721445: ['West Midnapore','West Bengal'],
        721446: ['East Midnapore','West Bengal'],
        721447: ['East Midnapore','West Bengal'],
        721448: ['East Midnapore','West Bengal'],
        721449: ['East Midnapore','West Bengal'],
        721450: ['East Midnapore','West Bengal'],
        721451: ['West Midnapore','West Bengal'],
        721452: ['East Midnapore','West Bengal'],
        721453: ['East Midnapore','West Bengal'],
        721454: ['East Midnapore','West Bengal'],
        721455: ['East Midnapore','West Bengal'],
        721456: ['East Midnapore','West Bengal'],
        721457: ['West Midnapore','West Bengal'],
        721458: ['East Midnapore','West Bengal'],
        721463: ['East Midnapore','West Bengal'],
        721467: ['West Midnapore','West Bengal'],
        721501: ['West Midnapore','West Bengal'],
        721503: ['West Midnapore','West Bengal'],
        721504: ['West Midnapore','West Bengal'],
        721505: ['West Midnapore','West Bengal'],
        721506: ['West Midnapore','West Bengal'],
        721507: ['West Midnapore','West Bengal'],
        721513: ['West Midnapore','West Bengal'],
        721514: ['West Midnapore','West Bengal'],
        721515: ['West Midnapore','West Bengal'],
        721516: ['West Midnapore','West Bengal'],
        721517: ['West Midnapore','West Bengal'],
        721601: ['East Midnapore','West Bengal'],
        721602: ['East Midnapore','West Bengal'],
        721603: ['East Midnapore','West Bengal'],
        721604: ['East Midnapore','West Bengal'],
        721606: ['East Midnapore','West Bengal'],
        721607: ['East Midnapore','West Bengal'],
        721624: ['East Midnapore','West Bengal'],
        721625: ['East Midnapore','West Bengal'],
        721626: ['East Midnapore','West Bengal'],
        721627: ['East Midnapore','West Bengal'],
        721628: ['East Midnapore','West Bengal'],
        721629: ['East Midnapore','West Bengal'],
        721631: ['East Midnapore','West Bengal'],
        721632: ['East Midnapore','West Bengal'],
        721633: ['East Midnapore','West Bengal'],
        721634: ['East Midnapore','West Bengal'],
        721635: ['East Midnapore','West Bengal'],
        721636: ['East Midnapore','West Bengal'],
        721637: ['East Midnapore','West Bengal'],
        721641: ['West Midnapore','West Bengal'],
        721642: ['East Midnapore','West Bengal'],
        721643: ['East Midnapore','West Bengal'],
        721644: ['East Midnapore','West Bengal'],
        721645: ['East Midnapore','West Bengal'],
        721646: ['East Midnapore','West Bengal'],
        721647: ['East Midnapore','West Bengal'],
        721648: ['East Midnapore','West Bengal'],
        721649: ['East Midnapore','West Bengal'],
        721650: ['East Midnapore','West Bengal'],
        721651: ['East Midnapore','West Bengal'],
        721652: ['East Midnapore','West Bengal'],
        721653: ['East Midnapore','West Bengal'],
        721654: ['East Midnapore','West Bengal'],
        721655: ['East Midnapore','West Bengal'],
        721656: ['East Midnapore','West Bengal'],
        721657: ['East Midnapore','West Bengal'],
        721658: ['East Midnapore','West Bengal'],
        721659: ['East Midnapore','West Bengal'],
        722101: ['Bankura','West Bengal'],
        722102: ['Bankura','West Bengal'],
        722121: ['Bankura','West Bengal'],
        722122: ['Bankura','West Bengal'],
        722132: ['Bankura','West Bengal'],
        722133: ['Bankura','West Bengal'],
        722134: ['Bankura','West Bengal'],
        722135: ['Bankura','West Bengal'],
        722136: ['Bankura','West Bengal'],
        722137: ['Bankura','West Bengal'],
        722138: ['Bankura','West Bengal'],
        722139: ['Bankura','West Bengal'],
        722140: ['Bankura','West Bengal'],
        722141: ['Bankura','West Bengal'],
        722142: ['Bankura','West Bengal'],
        722143: ['Bankura','West Bengal'],
        722144: ['Bankura','West Bengal'],
        722146: ['Bankura','West Bengal'],
        722147: ['Bankura','West Bengal'],
        722148: ['Bankura','West Bengal'],
        722149: ['Bankura','West Bengal'],
        722150: ['Bankura','West Bengal'],
        722151: ['Bankura','West Bengal'],
        722152: ['Bankura','West Bengal'],
        722153: ['Bankura','West Bengal'],
        722154: ['Bankura','West Bengal'],
        722155: ['Bankura','West Bengal'],
        722156: ['Bankura','West Bengal'],
        722157: ['Bankura','West Bengal'],
        722158: ['Bankura','West Bengal'],
        722160: ['Bankura','West Bengal'],
        722161: ['Bankura','West Bengal'],
        722162: ['Bankura','West Bengal'],
        722164: ['Bankura','West Bengal'],
        722173: ['Bankura','West Bengal'],
        722183: ['Bankura','West Bengal'],
        722201: ['Bankura','West Bengal'],
        722202: ['Bankura','West Bengal'],
        722203: ['Bankura','West Bengal'],
        722204: ['Bankura','West Bengal'],
        722205: ['Bankura','West Bengal'],
        722206: ['Bankura','West Bengal'],
        722207: ['Bankura','West Bengal'],
        722208: ['Bankura','West Bengal'],
        723101: ['Puruliya','West Bengal'],
        723102: ['Puruliya','West Bengal'],
        723103: ['Puruliya','West Bengal'],
        723104: ['Puruliya','West Bengal'],
        723121: ['Puruliya','West Bengal'],
        723126: ['Puruliya','West Bengal'],
        723127: ['Puruliya','West Bengal'],
        723128: ['Puruliya','West Bengal'],
        723129: ['Puruliya','West Bengal'],
        723130: ['Puruliya','West Bengal'],
        723131: ['Puruliya','West Bengal'],
        723132: ['Puruliya','West Bengal'],
        723133: ['Puruliya','West Bengal'],
        723142: ['Puruliya','West Bengal'],
        723143: ['Puruliya','West Bengal'],
        723145: ['Puruliya','West Bengal'],
        723146: ['Puruliya','West Bengal'],
        723147: ['Puruliya','West Bengal'],
        723148: ['Puruliya','West Bengal'],
        723149: ['Puruliya','West Bengal'],
        723151: ['Puruliya','West Bengal'],
        723152: ['Puruliya','West Bengal'],
        723153: ['Puruliya','West Bengal'],
        723154: ['Puruliya','West Bengal'],
        723155: ['Puruliya','West Bengal'],
        723156: ['Puruliya','West Bengal'],
        723201: ['Puruliya','West Bengal'],
        723202: ['Puruliya','West Bengal'],
        723212: ['Puruliya','West Bengal'],
        723213: ['Puruliya','West Bengal'],
        723215: ['Puruliya','West Bengal'],
        731101: ['Birbhum','West Bengal'],
        731102: ['Birbhum','West Bengal'],
        731103: ['Birbhum','West Bengal'],
        731104: ['Birbhum','West Bengal'],
        731121: ['Birbhum','West Bengal'],
        731123: ['Birbhum','West Bengal'],
        731124: ['Birbhum','West Bengal'],
        731125: ['Birbhum','West Bengal'],
        731126: ['Birbhum','West Bengal'],
        731127: ['Birbhum','West Bengal'],
        731129: ['Birbhum','West Bengal'],
        731130: ['Birbhum','West Bengal'],
        731132: ['Birbhum','West Bengal'],
        731133: ['Birbhum','West Bengal'],
        731201: ['Birbhum','West Bengal'],
        731202: ['Birbhum','West Bengal'],
        731204: ['Birbhum','West Bengal'],
        731213: ['Birbhum','West Bengal'],
        731214: ['Birbhum','West Bengal'],
        731215: ['Birbhum','West Bengal'],
        731216: ['Birbhum','West Bengal'],
        731218: ['Birbhum','West Bengal'],
        731219: ['Birbhum','West Bengal'],
        731220: ['Birbhum','West Bengal'],
        731221: ['Birbhum','West Bengal'],
        731222: ['Birbhum','West Bengal'],
        731223: ['Birbhum','West Bengal'],
        731224: ['Birbhum','West Bengal'],
        731233: ['Birbhum','West Bengal'],
        731234: ['Birbhum','West Bengal'],
        731235: ['Birbhum','West Bengal'],
        731236: ['Birbhum','West Bengal'],
        731237: ['Birbhum','West Bengal'],
        731238: ['Birbhum','West Bengal'],
        731240: ['Birbhum','West Bengal'],
        731241: ['Birbhum','West Bengal'],
        731242: ['Birbhum','West Bengal'],
        731243: ['Birbhum','West Bengal'],
        731244: ['Birbhum','West Bengal'],
        731245: ['Birbhum','West Bengal'],
        731301: ['Birbhum','West Bengal'],
        731302: ['Birbhum','West Bengal'],
        731303: ['Birbhum','West Bengal'],
        731304: ['Birbhum','West Bengal'],
        732101: ['Malda','West Bengal'],
        732102: ['Malda','West Bengal'],
        732103: ['Malda','West Bengal'],
        732121: ['Malda','West Bengal'],
        732122: ['Malda','West Bengal'],
        732123: ['Malda','West Bengal'],
        732124: ['Malda','West Bengal'],
        732125: ['Malda','West Bengal'],
        732126: ['Malda','West Bengal'],
        732127: ['Malda','West Bengal'],
        732128: ['Malda','West Bengal'],
        732138: ['Malda','West Bengal'],
        732139: ['Malda','West Bengal'],
        732140: ['Malda','West Bengal'],
        732141: ['Malda','West Bengal'],
        732142: ['Malda','West Bengal'],
        732144: ['Malda','West Bengal'],
        732201: ['Malda','West Bengal'],
        732202: ['Malda','West Bengal'],
        732203: ['Malda','West Bengal'],
        732204: ['Malda','West Bengal'],
        732205: ['Malda','West Bengal'],
        732206: ['Malda','West Bengal'],
        732207: ['Malda','West Bengal'],
        732208: ['Malda','West Bengal'],
        732209: ['Malda','West Bengal'],
        732210: ['Malda','West Bengal'],
        732215: ['Malda','West Bengal'],
        732216: ['Malda','West Bengal'],
        733101: ['South Dinajpur','West Bengal'],
        733102: ['South Dinajpur','West Bengal'],
        733103: ['South Dinajpur','West Bengal'],
        733121: ['South Dinajpur','West Bengal'],
        733123: ['North Dinajpur','West Bengal'],
        733124: ['South Dinajpur','West Bengal'],
        733125: ['South Dinajpur','West Bengal'],
        733126: ['South Dinajpur','West Bengal'],
        733127: ['South Dinajpur','West Bengal'],
        733128: ['North Dinajpur','West Bengal'],
        733129: ['North Dinajpur','West Bengal'],
        733130: ['North Dinajpur','West Bengal'],
        733132: ['South Dinajpur','West Bengal'],
        733133: ['South Dinajpur','West Bengal'],
        733134: ['North Dinajpur','West Bengal'],
        733140: ['South Dinajpur','West Bengal'],
        733141: ['South Dinajpur','West Bengal'],
        733142: ['South Dinajpur','West Bengal'],
        733143: ['North Dinajpur','West Bengal'],
        733145: ['South Dinajpur','West Bengal'],
        733156: ['North Dinajpur','West Bengal'],
        733158: ['South Dinajpur','West Bengal'],
        733201: ['North Dinajpur','West Bengal'],
        733202: ['North Dinajpur','West Bengal'],
        733207: ['North Dinajpur','West Bengal'],
        733208: ['North Dinajpur','West Bengal'],
        733209: ['North Dinajpur','West Bengal'],
        733210: ['North Dinajpur','West Bengal'],
        733215: ['North Dinajpur','West Bengal'],
        734004: ['Darjiling','West Bengal'],
        734006: ['Darjiling','West Bengal'],
        734007: ['Darjiling','West Bengal'],
        734015: ['Darjiling','West Bengal'],
        735101: ['Jalpaiguri','West Bengal'],
        735102: ['Jalpaiguri','West Bengal'],
        735121: ['Jalpaiguri','West Bengal'],
        735122: ['Cooch Behar','West Bengal'],
        735132: ['Jalpaiguri','West Bengal'],
        735133: ['Jalpaiguri','West Bengal'],
        735134: ['Jalpaiguri','West Bengal'],
        735135: ['Jalpaiguri','West Bengal'],
        735202: ['Jalpaiguri','West Bengal'],
        735203: ['Jalpaiguri','West Bengal'],
        735204: ['Jalpaiguri','West Bengal'],
        735205: ['Jalpaiguri','West Bengal'],
        735206: ['Jalpaiguri','West Bengal'],
        735207: ['Jalpaiguri','West Bengal'],
        735208: ['Jalpaiguri','West Bengal'],
        735209: ['Jalpaiguri','West Bengal'],
        735210: ['Jalpaiguri','West Bengal'],
        735211: ['Jalpaiguri','West Bengal'],
        735212: ['Jalpaiguri','West Bengal'],
        735213: ['Jalpaiguri','West Bengal'],
        735214: ['Jalpaiguri','West Bengal'],
        735215: ['Jalpaiguri','West Bengal'],
        735216: ['Jalpaiguri','West Bengal'],
        735217: ['Jalpaiguri','West Bengal'],
        735218: ['Jalpaiguri','West Bengal'],
        735219: ['Jalpaiguri','West Bengal'],
        735220: ['Jalpaiguri','West Bengal'],
        735221: ['Jalpaiguri','West Bengal'],
        735222: ['Jalpaiguri','West Bengal'],
        735223: ['Jalpaiguri','West Bengal'],
        735224: ['Jalpaiguri','West Bengal'],
        735225: ['Jalpaiguri','West Bengal'],
        735226: ['Jalpaiguri','West Bengal'],
        735227: ['Jalpaiguri','West Bengal'],
        735228: ['Jalpaiguri','West Bengal'],
        735229: ['Jalpaiguri','West Bengal'],
        735230: ['Jalpaiguri','West Bengal'],
        735232: ['Jalpaiguri','West Bengal'],
        735233: ['Jalpaiguri','West Bengal'],
        735234: ['Jalpaiguri','West Bengal'],
        735301: ['Jalpaiguri','West Bengal'],
        735302: ['Jalpaiguri','West Bengal'],
        735303: ['Cooch Behar','West Bengal'],
        735304: ['Cooch Behar','West Bengal'],
        735305: ['Jalpaiguri','West Bengal'],
        736101: ['Cooch Behar','West Bengal'],
        736121: ['Jalpaiguri','West Bengal'],
        736122: ['Jalpaiguri','West Bengal'],
        736123: ['Jalpaiguri','West Bengal'],
        736131: ['Cooch Behar','West Bengal'],
        736133: ['Cooch Behar','West Bengal'],
        736134: ['Cooch Behar','West Bengal'],
        736135: ['Cooch Behar','West Bengal'],
        736145: ['Cooch Behar','West Bengal'],
        736146: ['Cooch Behar','West Bengal'],
        736156: ['Cooch Behar','West Bengal'],
        736157: ['Cooch Behar','West Bengal'],
        736158: ['Cooch Behar','West Bengal'],
        736159: ['Cooch Behar','West Bengal'],
        736160: ['Cooch Behar','West Bengal'],
        736165: ['Cooch Behar','West Bengal'],
        736167: ['Cooch Behar','West Bengal'],
        736168: ['Cooch Behar','West Bengal'],
        736169: ['Cooch Behar','West Bengal'],
        736170: ['Cooch Behar','West Bengal'],
        736171: ['Cooch Behar','West Bengal'],
        736172: ['Cooch Behar','West Bengal'],
        736176: ['Cooch Behar','West Bengal'],
        736179: ['Cooch Behar','West Bengal'],
        736182: ['Jalpaiguri','West Bengal'],
        736201: ['Jalpaiguri','West Bengal'],
        736202: ['Jalpaiguri','West Bengal'],
        736203: ['Jalpaiguri','West Bengal'],
        736204: ['Jalpaiguri','West Bengal'],
        736205: ['Jalpaiguri','West Bengal'],
        736206: ['Jalpaiguri','West Bengal'],
        736207: ['Jalpaiguri','West Bengal'],
        736208: ['Jalpaiguri','West Bengal'],
        737101: ['East Sikkim','Sikkim'],
        737102: ['East Sikkim','Sikkim'],
        737103: ['East Sikkim','Sikkim'],
        737106: ['East Sikkim','Sikkim'],
        737107: ['East Sikkim','Sikkim'],
        737111: ['West Sikkim','Sikkim'],
        737113: ['West Sikkim','Sikkim'],
        737116: ['North Sikkim','West Bengal'],
        737120: ['East Sikkim','Sikkim'],
        737121: ['West Sikkim','Sikkim'],
        737126: ['South Sikkim','Sikkim'],
        737128: ['South Sikkim','Sikkim'],
        737131: ['East Sikkim','Sikkim'],
        737132: ['East Sikkim','Sikkim'],
        737133: ['East Sikkim','Sikkim'],
        737134: ['East Sikkim','Sikkim'],
        737135: ['East Sikkim','Sikkim'],
        737136: ['East Sikkim','Sikkim'],
        737139: ['South Sikkim','Sikkim'],
        741101: ['Nadia','West Bengal'],
        741102: ['Nadia','West Bengal'],
        741103: ['Nadia','West Bengal'],
        741121: ['Nadia','West Bengal'],
        741122: ['Nadia','West Bengal'],
        741123: ['Nadia','West Bengal'],
        741124: ['Nadia','West Bengal'],
        741125: ['Nadia','West Bengal'],
        741126: ['Nadia','West Bengal'],
        741127: ['Nadia','West Bengal'],
        741137: ['Nadia','West Bengal'],
        741138: ['Nadia','West Bengal'],
        741139: ['Nadia','West Bengal'],
        741140: ['Nadia','West Bengal'],
        741150: ['Nadia','West Bengal'],
        741151: ['Nadia','West Bengal'],
        741152: ['Nadia','West Bengal'],
        741153: ['Nadia','West Bengal'],
        741154: ['Nadia','West Bengal'],
        741155: ['Nadia','West Bengal'],
        741156: ['Nadia','West Bengal'],
        741157: ['Nadia','West Bengal'],
        741158: ['Nadia','West Bengal'],
        741159: ['Nadia','West Bengal'],
        741160: ['Nadia','West Bengal'],
        741161: ['Nadia','West Bengal'],
        741162: ['Nadia','West Bengal'],
        741163: ['Nadia','West Bengal'],
        741164: ['Nadia','West Bengal'],
        741165: ['Nadia','West Bengal'],
        741166: ['Nadia','West Bengal'],
        741167: ['Nadia','West Bengal'],
        741181: ['Nadia','West Bengal'],
        741201: ['Nadia','West Bengal'],
        741202: ['Nadia','West Bengal'],
        741221: ['Nadia','West Bengal'],
        741222: ['Nadia','West Bengal'],
        741223: ['Nadia','West Bengal'],
        741232: ['Nadia','West Bengal'],
        741234: ['Nadia','West Bengal'],
        741235: ['Nadia','West Bengal'],
        741238: ['Nadia','West Bengal'],
        741245: ['Nadia','West Bengal'],
        741246: ['Nadia','West Bengal'],
        741247: ['Nadia','West Bengal'],
        741248: ['Nadia','West Bengal'],
        741249: ['Nadia','West Bengal'],
        741250: ['Nadia','West Bengal'],
        741251: ['Nadia','West Bengal'],
        741252: ['Nadia','West Bengal'],
        741253: ['Nadia','West Bengal'],
        741254: ['Nadia','West Bengal'],
        741255: ['Nadia','West Bengal'],
        741256: ['Nadia','West Bengal'],
        741257: ['Nadia','West Bengal'],
        741301: ['Nadia','West Bengal'],
        741302: ['Nadia','West Bengal'],
        741313: ['Nadia','West Bengal'],
        741315: ['Nadia','West Bengal'],
        741316: ['Nadia','West Bengal'],
        741317: ['Nadia','West Bengal'],
        741319: ['Nadia','West Bengal'],
        741401: ['Nadia','West Bengal'],
        741402: ['Nadia','West Bengal'],
        741404: ['Nadia','West Bengal'],
        741501: ['Nadia','West Bengal'],
        741502: ['Nadia','West Bengal'],
        741503: ['Nadia','West Bengal'],
        741504: ['Nadia','West Bengal'],
        741505: ['Nadia','West Bengal'],
        741506: ['Nadia','West Bengal'],
        741507: ['Nadia','West Bengal'],
        741508: ['Nadia','West Bengal'],
        741509: ['Nadia','West Bengal'],
        742101: ['Murshidabad','West Bengal'],
        742103: ['Murshidabad','West Bengal'],
        742104: ['Murshidabad','West Bengal'],
        742113: ['Murshidabad','West Bengal'],
        742121: ['Murshidabad','West Bengal'],
        742122: ['Murshidabad','West Bengal'],
        742123: ['Murshidabad','West Bengal'],
        742132: ['Murshidabad','West Bengal'],
        742133: ['Murshidabad','West Bengal'],
        742134: ['Murshidabad','West Bengal'],
        742135: ['Murshidabad','West Bengal'],
        742136: ['Murshidabad','West Bengal'],
        742137: ['Murshidabad','West Bengal'],
        742138: ['Murshidabad','West Bengal'],
        742140: ['Murshidabad','West Bengal'],
        742147: ['Murshidabad','West Bengal'],
        742148: ['Murshidabad','West Bengal'],
        742149: ['Murshidabad','West Bengal'],
        742151: ['Murshidabad','West Bengal'],
        742159: ['Murshidabad','West Bengal'],
        742160: ['Murshidabad','West Bengal'],
        742161: ['Murshidabad','West Bengal'],
        742163: ['Murshidabad','West Bengal'],
        742164: ['Murshidabad','West Bengal'],
        742165: ['Murshidabad','West Bengal'],
        742166: ['Murshidabad','West Bengal'],
        742168: ['Murshidabad','West Bengal'],
        742174: ['Murshidabad','West Bengal'],
        742175: ['Murshidabad','West Bengal'],
        742184: ['Murshidabad','West Bengal'],
        742187: ['Murshidabad','West Bengal'],
        742189: ['Murshidabad','West Bengal'],
        742201: ['Murshidabad','West Bengal'],
        742202: ['Murshidabad','West Bengal'],
        742212: ['Murshidabad','West Bengal'],
        742213: ['Murshidabad','West Bengal'],
        742223: ['Murshidabad','West Bengal'],
        742224: ['Murshidabad','West Bengal'],
        742225: ['Murshidabad','West Bengal'],
        742226: ['Murshidabad','West Bengal'],
        742227: ['Murshidabad','West Bengal'],
        742235: ['Murshidabad','West Bengal'],
        742236: ['Murshidabad','West Bengal'],
        742237: ['Murshidabad','West Bengal'],
        742238: ['Murshidabad','West Bengal'],
        742301: ['Murshidabad','West Bengal'],
        742302: ['Murshidabad','West Bengal'],
        742303: ['Murshidabad','West Bengal'],
        742304: ['Murshidabad','West Bengal'],
        742305: ['Murshidabad','West Bengal'],
        742306: ['Murshidabad','West Bengal'],
        742308: ['Murshidabad','West Bengal'],
        742401: ['Murshidabad','West Bengal'],
        742402: ['Murshidabad','West Bengal'],
        742404: ['Murshidabad','West Bengal'],
        742405: ['Murshidabad','West Bengal'],
        742406: ['Murshidabad','West Bengal'],
        742407: ['Murshidabad','West Bengal'],
        742408: ['Murshidabad','West Bengal'],
        742409: ['Murshidabad','West Bengal'],
        742410: ['Murshidabad','West Bengal'],
        743122: ['North 24 Parganas','West Bengal'],
        743123: ['North 24 Parganas','West Bengal'],
        743124: ['North 24 Parganas','West Bengal'],
        743125: ['North 24 Parganas','West Bengal'],
        743126: ['North 24 Parganas','West Bengal'],
        743127: ['North 24 Parganas','West Bengal'],
        743128: ['North 24 Parganas','West Bengal'],
        743129: ['North 24 Parganas','West Bengal'],
        743130: ['North 24 Parganas','West Bengal'],
        743133: ['North 24 Parganas','West Bengal'],
        743134: ['North 24 Parganas','West Bengal'],
        743135: ['North 24 Parganas','West Bengal'],
        743136: ['North 24 Parganas','West Bengal'],
        743144: ['North 24 Parganas','West Bengal'],
        743145: ['North 24 Parganas','West Bengal'],
        743165: ['North 24 Parganas','West Bengal'],
        743166: ['North 24 Parganas','West Bengal'],
        743193: ['North 24 Parganas','West Bengal'],
        743194: ['North 24 Parganas','West Bengal'],
        743221: ['North 24 Parganas','West Bengal'],
        743222: ['North 24 Parganas','West Bengal'],
        743223: ['North 24 Parganas','West Bengal'],
        743232: ['North 24 Parganas','West Bengal'],
        743233: ['North 24 Parganas','West Bengal'],
        743234: ['North 24 Parganas','West Bengal'],
        743235: ['North 24 Parganas','West Bengal'],
        743245: ['North 24 Parganas','West Bengal'],
        743247: ['North 24 Parganas','West Bengal'],
        743248: ['North 24 Parganas','West Bengal'],
        743249: ['North 24 Parganas','West Bengal'],
        743251: ['North 24 Parganas','West Bengal'],
        743252: ['North 24 Parganas','West Bengal'],
        743263: ['North 24 Parganas','West Bengal'],
        743268: ['North 24 Parganas','West Bengal'],
        743270: ['North 24 Parganas','West Bengal'],
        743271: ['North 24 Parganas','West Bengal'],
        743272: ['North 24 Parganas','West Bengal'],
        743273: ['North 24 Parganas','West Bengal'],
        743286: ['North 24 Parganas','West Bengal'],
        743287: ['North 24 Parganas','West Bengal'],
        743289: ['North 24 Parganas','West Bengal'],
        743290: ['North 24 Parganas','West Bengal'],
        743291: ['North 24 Parganas','West Bengal'],
        743292: ['North 24 Parganas','West Bengal'],
        743293: ['North 24 Parganas','West Bengal'],
        743294: ['North 24 Parganas','West Bengal'],
        743297: ['North 24 Parganas','West Bengal'],
        743312: ['South 24 Parganas','West Bengal'],
        743318: ['South 24 Parganas','West Bengal'],
        743329: ['South 24 Parganas','West Bengal'],
        743330: ['South 24 Parganas','West Bengal'],
        743331: ['South 24 Parganas','West Bengal'],
        743332: ['South 24 Parganas','West Bengal'],
        743336: ['South 24 Parganas','West Bengal'],
        743337: ['South 24 Parganas','West Bengal'],
        743338: ['South 24 Parganas','West Bengal'],
        743345: ['South 24 Parganas','West Bengal'],
        743347: ['South 24 Parganas','West Bengal'],
        743348: ['South 24 Parganas','West Bengal'],
        743349: ['South 24 Parganas','West Bengal'],
        743351: ['South 24 Parganas','West Bengal'],
        743354: ['South 24 Parganas','West Bengal'],
        743355: ['South 24 Parganas','West Bengal'],
        743356: ['South 24 Parganas','West Bengal'],
        743357: ['South 24 Parganas','West Bengal'],
        743363: ['South 24 Parganas','West Bengal'],
        743368: ['South 24 Parganas','West Bengal'],
        743370: ['South 24 Parganas','West Bengal'],
        743371: ['South 24 Parganas','West Bengal'],
        743373: ['South 24 Parganas','West Bengal'],
        743374: ['South 24 Parganas','West Bengal'],
        743375: ['South 24 Parganas','West Bengal'],
        743376: ['South 24 Parganas','West Bengal'],
        743377: ['South 24 Parganas','West Bengal'],
        743378: ['South 24 Parganas','West Bengal'],
        743383: ['South 24 Parganas','West Bengal'],
        743387: ['South 24 Parganas','West Bengal'],
        743395: ['South 24 Parganas','West Bengal'],
        743399: ['South 24 Parganas','West Bengal'],
        743401: ['North 24 Parganas','West Bengal'],
        743405: ['North 24 Parganas','West Bengal'],
        743411: ['North 24 Parganas','West Bengal'],
        743412: ['North 24 Parganas','West Bengal'],
        743422: ['North 24 Parganas','West Bengal'],
        743423: ['North 24 Parganas','West Bengal'],
        743424: ['North 24 Parganas','West Bengal'],
        743425: ['North 24 Parganas','West Bengal'],
        743426: ['North 24 Parganas','West Bengal'],
        743427: ['North 24 Parganas','West Bengal'],
        743428: ['North 24 Parganas','West Bengal'],
        743429: ['North 24 Parganas','West Bengal'],
        743435: ['North 24 Parganas','West Bengal'],
        743437: ['North 24 Parganas','West Bengal'],
        743438: ['North 24 Parganas','West Bengal'],
        743439: ['North 24 Parganas','West Bengal'],
        743442: ['North 24 Parganas','West Bengal'],
        743445: ['North 24 Parganas','West Bengal'],
        743446: ['North 24 Parganas','West Bengal'],
        743456: ['North 24 Parganas','West Bengal'],
        743502: ['South 24 Parganas','West Bengal'],
        743503: ['South 24 Parganas','West Bengal'],
        743504: ['South 24 Parganas','West Bengal'],
        743513: ['South 24 Parganas','West Bengal'],
        743609: ['South 24 Parganas','West Bengal'],
        743610: ['South 24 Parganas','West Bengal'],
        743611: ['South 24 Parganas','West Bengal'],
        743613: ['South 24 Parganas','West Bengal'],
        743701: ['North 24 Parganas','West Bengal'],
        743702: ['North 24 Parganas','West Bengal'],
        743704: ['North 24 Parganas','West Bengal'],
        743710: ['North 24 Parganas','West Bengal'],
        743711: ['North 24 Parganas','West Bengal'],
        744101: ['South Andaman','Andaman And Nico.In.'],
        744102: ['South Andaman','Andaman And Nico.In.'],
        744103: ['South Andaman','Andaman And Nico.In.'],
        744104: ['South Andaman','Andaman And Nico.In.'],
        744105: ['South Andaman','Andaman And Nico.In.'],
        744106: ['South Andaman','Andaman And Nico.In.'],
        744107: ['South Andaman','Andaman And Nico.In.'],
        744112: ['South Andaman','Andaman And Nico.In.'],
        744201: ['North And Middle Andaman','Andaman And Nico.In.'],
        744202: ['North And Middle Andaman','Andaman And Nico.In.'],
        744203: ['North And Middle Andaman','Andaman And Nico.In.'],
        744204: ['North And Middle Andaman','Andaman And Nico.In.'],
        744205: ['North And Middle Andaman','Andaman And Nico.In.'],
        744206: ['South Andaman','Andaman And Nico.In.'],
        744207: ['South Andaman','Andaman And Nico.In.'],
        744209: ['North And Middle Andaman','Andaman And Nico.In.'],
        744210: ['North And Middle Andaman','Andaman And Nico.In.'],
        744211: ['South Andaman','Andaman And Nico.In.'],
        744301: ['Nicobar','Andaman And Nico.In.'],
        744302: ['Nicobar','Andaman And Nico.In.'],
        744303: ['Nicobar','Andaman And Nico.In.'],
        744304: ['Nicobar','Andaman And Nico.In.'],
        751003: ['Khorda','Odisha'],
        751004: ['Khorda','Odisha'],
        751005: ['Khorda','Odisha'],
        751006: ['Khorda','Odisha'],
        751007: ['Khorda','Odisha'],
        751008: ['Khorda','Odisha'],
        751010: ['Khorda','Odisha'],
        751011: ['Khorda','Odisha'],
        751012: ['Khorda','Odisha'],
        751013: ['Khorda','Odisha'],
        751014: ['Khorda','Odisha'],
        751015: ['Khorda','Odisha'],
        751016: ['Khorda','Odisha'],
        751017: ['Khorda','Odisha'],
        751018: ['Khorda','Odisha'],
        751019: ['Khorda','Odisha'],
        751020: ['Khorda','Odisha'],
        751021: ['Khorda','Odisha'],
        751022: ['Khorda','Odisha'],
        751023: ['Khorda','Odisha'],
        751024: ['Khorda','Odisha'],
        751025: ['Khorda','Odisha'],
        751026: ['Khorda','Odisha'],
        751027: ['Khorda','Odisha'],
        751028: ['Khorda','Odisha'],
        751029: ['Khorda','Odisha'],
        751030: ['Khorda','Odisha'],
        751031: ['Khorda','Odisha'],
        752001: ['Puri','Odisha'],
        752002: ['Puri','Odisha'],
        752003: ['Puri','Odisha'],
        752010: ['Puri','Odisha'],
        752011: ['Puri','Odisha'],
        752012: ['Puri','Odisha'],
        752013: ['Puri','Odisha'],
        752014: ['Puri','Odisha'],
        752015: ['Puri','Odisha'],
        752016: ['Puri','Odisha'],
        752017: ['Puri','Odisha'],
        752018: ['Khorda','Odisha'],
        752019: ['Khorda','Odisha'],
        752020: ['Khorda','Odisha'],
        752021: ['Khorda','Odisha'],
        752022: ['Khorda','Odisha'],
        752023: ['Khorda','Odisha'],
        752024: ['Nayagarh','Odisha'],
        752025: ['Nayagarh','Odisha'],
        752026: ['Nayagarh','Odisha'],
        752027: ['Khorda','Odisha'],
        752030: ['Khorda','Odisha'],
        752031: ['Khorda','Odisha'],
        752032: ['Puri','Odisha'],
        752034: ['Khorda','Odisha'],
        752035: ['Khorda','Odisha'],
        752037: ['Khorda','Odisha'],
        752038: ['Khorda','Odisha'],
        752045: ['Puri','Odisha'],
        752046: ['Puri','Odisha'],
        752050: ['Khorda','Odisha'],
        752054: ['Khorda','Odisha'],
        752055: ['Khorda','Odisha'],
        752056: ['Khorda','Odisha'],
        752057: ['Khorda','Odisha'],
        752060: ['Khorda','Odisha'],
        752061: ['Khorda','Odisha'],
        752062: ['Khorda','Odisha'],
        752063: ['Nayagarh','Odisha'],
        752064: ['Khorda','Odisha'],
        752065: ['Nayagarh','Odisha'],
        752066: ['Khorda','Odisha'],
        752068: ['Nayagarh','Odisha'],
        752069: ['Puri','Odisha'],
        752070: ['Nayagarh','Odisha'],
        752077: ['Nayagarh','Odisha'],
        752078: ['Nayagarh','Odisha'],
        752079: ['Nayagarh','Odisha'],
        752080: ['Nayagarh','Odisha'],
        752081: ['Nayagarh','Odisha'],
        752082: ['Nayagarh','Odisha'],
        752083: ['Nayagarh','Odisha'],
        752084: ['Nayagarh','Odisha'],
        752085: ['Nayagarh','Odisha'],
        752089: ['Nayagarh','Odisha'],
        752090: ['Nayagarh','Odisha'],
        752091: ['Nayagarh','Odisha'],
        752092: ['Nayagarh','Odisha'],
        752093: ['Nayagarh','Odisha'],
        752094: ['Nayagarh','Odisha'],
        752100: ['Khorda','Odisha'],
        752101: ['Khorda','Odisha'],
        752102: ['Khorda','Odisha'],
        752103: ['Khorda','Odisha'],
        752104: ['Puri','Odisha'],
        752105: ['Puri','Odisha'],
        752106: ['Puri','Odisha'],
        752107: ['Puri','Odisha'],
        752108: ['Puri','Odisha'],
        752109: ['Puri','Odisha'],
        752110: ['Puri','Odisha'],
        752111: ['Puri','Odisha'],
        752113: ['Puri','Odisha'],
        752114: ['Puri','Odisha'],
        752115: ['Khorda','Odisha'],
        752116: ['Puri','Odisha'],
        752118: ['Puri','Odisha'],
        752119: ['Puri','Odisha'],
        752120: ['Cuttack','Odisha'],
        752121: ['Puri','Odisha'],
        754120: ['Jagatsinghapur','Odisha'],
        754130: ['Cuttack','Odisha'],
        754138: ['Jagatsinghapur','Odisha'],
        754145: ['Jagatsinghapur','Odisha'],
        754245: ['Kendrapara','Odisha'],
        754294: ['Jagatsinghapur','Odisha'],
        754296: ['Jajapur','Odisha'],
        754297: ['Cuttack','Odisha'],
        755020: ['Jajapur','Odisha'],
        755050: ['Jajapur','Odisha'],
        755051: ['Jajapur','Odisha'],
        755062: ['Jajapur','Odisha'],
        756001: ['Baleswar','Odisha'],
        756002: ['Baleswar','Odisha'],
        756003: ['Baleswar','Odisha'],
        756019: ['Baleswar','Odisha'],
        756020: ['Baleswar','Odisha'],
        756021: ['Baleswar','Odisha'],
        756022: ['Baleswar','Odisha'],
        756023: ['Baleswar','Odisha'],
        756024: ['Baleswar','Odisha'],
        756025: ['Baleswar','Odisha'],
        756026: ['Baleswar','Odisha'],
        756027: ['Baleswar','Odisha'],
        756028: ['Baleswar','Odisha'],
        756029: ['Baleswar','Odisha'],
        756030: ['Baleswar','Odisha'],
        756032: ['Baleswar','Odisha'],
        756033: ['Baleswar','Odisha'],
        756034: ['Baleswar','Odisha'],
        756035: ['Baleswar','Odisha'],
        756036: ['Baleswar','Odisha'],
        756037: ['Baleswar','Odisha'],
        756038: ['Baleswar','Odisha'],
        756039: ['Baleswar','Odisha'],
        756040: ['Baleswar','Odisha'],
        756041: ['Baleswar','Odisha'],
        756042: ['Baleswar','Odisha'],
        756043: ['Baleswar','Odisha'],
        756044: ['Baleswar','Odisha'],
        756045: ['Baleswar','Odisha'],
        756046: ['Baleswar','Odisha'],
        756047: ['Baleswar','Odisha'],
        756048: ['Baleswar','Odisha'],
        756049: ['Baleswar','Odisha'],
        756051: ['Baleswar','Odisha'],
        756055: ['Baleswar','Odisha'],
        756056: ['Baleswar','Odisha'],
        756058: ['Baleswar','Odisha'],
        756059: ['Baleswar','Odisha'],
        756060: ['Baleswar','Odisha'],
        756079: ['Baleswar','Odisha'],
        756080: ['Baleswar','Odisha'],
        756081: ['Baleswar','Odisha'],
        756083: ['Baleswar','Odisha'],
        756084: ['Baleswar','Odisha'],
        756085: ['Baleswar','Odisha'],
        756086: ['Baleswar','Odisha'],
        756087: ['Baleswar','Odisha'],
        756100: ['Bhadrak','Odisha'],
        756101: ['Bhadrak','Odisha'],
        756111: ['Bhadrak','Odisha'],
        756112: ['Bhadrak','Odisha'],
        756113: ['Bhadrak','Odisha'],
        756114: ['Bhadrak','Odisha'],
        756115: ['Bhadrak','Odisha'],
        756116: ['Bhadrak','Odisha'],
        756117: ['Bhadrak','Odisha'],
        756118: ['Bhadrak','Odisha'],
        756119: ['Bhadrak','Odisha'],
        756120: ['Bhadrak','Odisha'],
        756121: ['Bhadrak','Odisha'],
        756122: ['Bhadrak','Odisha'],
        756123: ['Bhadrak','Odisha'],
        756124: ['Bhadrak','Odisha'],
        756125: ['Bhadrak','Odisha'],
        756126: ['Baleswar','Odisha'],
        756127: ['Bhadrak','Odisha'],
        756128: ['Bhadrak','Odisha'],
        756129: ['Bhadrak','Odisha'],
        756130: ['Bhadrak','Odisha'],
        756131: ['Bhadrak','Odisha'],
        756132: ['Bhadrak','Odisha'],
        756133: ['Bhadrak','Odisha'],
        756134: ['Baleswar','Odisha'],
        756135: ['Bhadrak','Odisha'],
        756137: ['Bhadrak','Odisha'],
        756138: ['Bhadrak','Odisha'],
        756139: ['Bhadrak','Odisha'],
        756144: ['Bhadrak','Odisha'],
        756162: ['Bhadrak','Odisha'],
        756163: ['Bhadrak','Odisha'],
        756164: ['Bhadrak','Odisha'],
        756165: ['Bhadrak','Odisha'],
        756166: ['Bhadrak','Odisha'],
        756167: ['Bhadrak','Odisha'],
        756168: ['Bhadrak','Odisha'],
        756171: ['Bhadrak','Odisha'],
        756181: ['Bhadrak','Odisha'],
        756182: ['Baleswar','Odisha'],
        757001: ['Mayurbhanj','Odisha'],
        757002: ['Mayurbhanj','Odisha'],
        757003: ['Mayurbhanj','Odisha'],
        757014: ['Mayurbhanj','Odisha'],
        757016: ['Mayurbhanj','Odisha'],
        757017: ['Mayurbhanj','Odisha'],
        757018: ['Mayurbhanj','Odisha'],
        757019: ['Mayurbhanj','Odisha'],
        757020: ['Mayurbhanj','Odisha'],
        757021: ['Mayurbhanj','Odisha'],
        757022: ['Mayurbhanj','Odisha'],
        757023: ['Mayurbhanj','Odisha'],
        757024: ['Mayurbhanj','Odisha'],
        757025: ['Mayurbhanj','Odisha'],
        757026: ['Mayurbhanj','Odisha'],
        757027: ['Mayurbhanj','Odisha'],
        757028: ['Mayurbhanj','Odisha'],
        757029: ['Mayurbhanj','Odisha'],
        757030: ['Mayurbhanj','Odisha'],
        757031: ['Mayurbhanj','Odisha'],
        757032: ['Mayurbhanj','Odisha'],
        757033: ['Mayurbhanj','Odisha'],
        757034: ['Mayurbhanj','Odisha'],
        757035: ['Mayurbhanj','Odisha'],
        757036: ['Mayurbhanj','Odisha'],
        757037: ['Mayurbhanj','Odisha'],
        757038: ['Mayurbhanj','Odisha'],
        757039: ['Mayurbhanj','Odisha'],
        757040: ['Mayurbhanj','Odisha'],
        757041: ['Mayurbhanj','Odisha'],
        757042: ['Mayurbhanj','Odisha'],
        757043: ['Mayurbhanj','Odisha'],
        757045: ['Mayurbhanj','Odisha'],
        757046: ['Mayurbhanj','Odisha'],
        757047: ['Mayurbhanj','Odisha'],
        757048: ['Mayurbhanj','Odisha'],
        757049: ['Mayurbhanj','Odisha'],
        757050: ['Mayurbhanj','Odisha'],
        757051: ['Mayurbhanj','Odisha'],
        757052: ['Mayurbhanj','Odisha'],
        757053: ['Mayurbhanj','Odisha'],
        757054: ['Mayurbhanj','Odisha'],
        757055: ['Mayurbhanj','Odisha'],
        757073: ['Mayurbhanj','Odisha'],
        757074: ['Mayurbhanj','Odisha'],
        757075: ['Mayurbhanj','Odisha'],
        757077: ['Mayurbhanj','Odisha'],
        757079: ['Mayurbhanj','Odisha'],
        757081: ['Mayurbhanj','Odisha'],
        757082: ['Mayurbhanj','Odisha'],
        757083: ['Mayurbhanj','Odisha'],
        757084: ['Mayurbhanj','Odisha'],
        757085: ['Mayurbhanj','Odisha'],
        757086: ['Mayurbhanj','Odisha'],
        757087: ['Mayurbhanj','Odisha'],
        757091: ['Mayurbhanj','Odisha'],
        757092: ['Mayurbhanj','Odisha'],
        757093: ['Mayurbhanj','Odisha'],
        757100: ['Mayurbhanj','Odisha'],
        757101: ['Mayurbhanj','Odisha'],
        757102: ['Mayurbhanj','Odisha'],
        757103: ['Mayurbhanj','Odisha'],
        757104: ['Mayurbhanj','Odisha'],
        757105: ['Mayurbhanj','Odisha'],
        757106: ['Mayurbhanj','Odisha'],
        757107: ['Mayurbhanj','Odisha'],
        758001: ['Kendujhar','Odisha'],
        758002: ['Kendujhar','Odisha'],
        758013: ['Kendujhar','Odisha'],
        758014: ['Kendujhar','Odisha'],
        758015: ['Kendujhar','Odisha'],
        758016: ['Kendujhar','Odisha'],
        758017: ['Kendujhar','Odisha'],
        758018: ['Kendujhar','Odisha'],
        758019: ['Kendujhar','Odisha'],
        758020: ['Kendujhar','Odisha'],
        758021: ['Kendujhar','Odisha'],
        758022: ['Kendujhar','Odisha'],
        758023: ['Kendujhar','Odisha'],
        758025: ['Kendujhar','Odisha'],
        758026: ['Kendujhar','Odisha'],
        758027: ['Kendujhar','Odisha'],
        758028: ['Kendujhar','Odisha'],
        758029: ['Kendujhar','Odisha'],
        758030: ['Kendujhar','Odisha'],
        758031: ['Kendujhar','Odisha'],
        758032: ['Kendujhar','Odisha'],
        758034: ['Kendujhar','Odisha'],
        758035: ['Kendujhar','Odisha'],
        758036: ['Kendujhar','Odisha'],
        758037: ['Kendujhar','Odisha'],
        758038: ['Kendujhar','Odisha'],
        758040: ['Kendujhar','Odisha'],
        758041: ['Kendujhar','Odisha'],
        758043: ['Kendujhar','Odisha'],
        758044: ['Kendujhar','Odisha'],
        758045: ['Kendujhar','Odisha'],
        758046: ['Kendujhar','Odisha'],
        758047: ['Kendujhar','Odisha'],
        758076: ['Kendujhar','Odisha'],
        758078: ['Kendujhar','Odisha'],
        758079: ['Kendujhar','Odisha'],
        758080: ['Kendujhar','Odisha'],
        758081: ['Kendujhar','Odisha'],
        758082: ['Kendujhar','Odisha'],
        758083: ['Kendujhar','Odisha'],
        758084: ['Kendujhar','Odisha'],
        758085: ['Kendujhar','Odisha'],
        758086: ['Kendujhar','Odisha'],
        758087: ['Kendujhar','Odisha'],
        759001: ['Dhenkanal','Odisha'],
        759013: ['Dhenkanal','Odisha'],
        759014: ['Dhenkanal','Odisha'],
        759015: ['Dhenkanal','Odisha'],
        759016: ['Dhenkanal','Odisha'],
        759017: ['Dhenkanal','Odisha'],
        759018: ['Dhenkanal','Odisha'],
        759019: ['Dhenkanal','Odisha'],
        759020: ['Dhenkanal','Odisha'],
        759021: ['Dhenkanal','Odisha'],
        759022: ['Dhenkanal','Odisha'],
        759023: ['Dhenkanal','Odisha'],
        759024: ['Dhenkanal','Odisha'],
        759025: ['Dhenkanal','Odisha'],
        759026: ['Dhenkanal','Odisha'],
        759027: ['Dhenkanal','Odisha'],
        759028: ['Dhenkanal','Odisha'],
        759029: ['Dhenkanal','Odisha'],
        759037: ['Angul','Odisha'],
        759039: ['Dhenkanal','Odisha'],
        759040: ['Dhenkanal','Odisha'],
        759100: ['Angul','Odisha'],
        759101: ['Angul','Odisha'],
        759102: ['Angul','Odisha'],
        759103: ['Angul','Odisha'],
        759104: ['Angul','Odisha'],
        759105: ['Angul','Odisha'],
        759106: ['Angul','Odisha'],
        759107: ['Angul','Odisha'],
        759111: ['Angul','Odisha'],
        759116: ['Angul','Odisha'],
        759117: ['Angul','Odisha'],
        759118: ['Angul','Odisha'],
        759119: ['Angul','Odisha'],
        759120: ['Dhenkanal','Odisha'],
        759121: ['Dhenkanal','Odisha'],
        759122: ['Angul','Odisha'],
        759123: ['Angul','Odisha'],
        759124: ['Angul','Odisha'],
        759125: ['Angul','Odisha'],
        759126: ['Angul','Odisha'],
        759127: ['Angul','Odisha'],
        759128: ['Angul','Odisha'],
        759129: ['Angul','Odisha'],
        759130: ['Angul','Odisha'],
        759132: ['Angul','Odisha'],
        759141: ['Angul','Odisha'],
        759143: ['Angul','Odisha'],
        759145: ['Angul','Odisha'],
        759146: ['Angul','Odisha'],
        759147: ['Angul','Odisha'],
        759148: ['Angul','Odisha'],
        760001: ['Ganjam','Odisha'],
        760002: ['Ganjam','Odisha'],
        760003: ['Ganjam','Odisha'],
        760004: ['Ganjam','Odisha'],
        760005: ['Ganjam','Odisha'],
        760006: ['Ganjam','Odisha'],
        760007: ['Ganjam','Odisha'],
        760008: ['Ganjam','Odisha'],
        760009: ['Ganjam','Odisha'],
        760010: ['Ganjam','Odisha'],
        760011: ['Ganjam','Odisha'],
        761001: ['Ganjam','Odisha'],
        761002: ['Ganjam','Odisha'],
        761003: ['Ganjam','Odisha'],
        761004: ['Ganjam','Odisha'],
        761005: ['Ganjam','Odisha'],
        761006: ['Ganjam','Odisha'],
        761007: ['Ganjam','Odisha'],
        761008: ['Ganjam','Odisha'],
        761009: ['Ganjam','Odisha'],
        761010: ['Ganjam','Odisha'],
        761011: ['Ganjam','Odisha'],
        761012: ['Ganjam','Odisha'],
        761013: ['Ganjam','Odisha'],
        761014: ['Ganjam','Odisha'],
        761015: ['Gajapati','Odisha'],
        761016: ['Gajapati','Odisha'],
        761017: ['Gajapati','Odisha'],
        761018: ['Ganjam','Odisha'],
        761019: ['Ganjam','Odisha'],
        761020: ['Ganjam','Odisha'],
        761025: ['Ganjam','Odisha'],
        761026: ['Ganjam','Odisha'],
        761027: ['Ganjam','Odisha'],
        761028: ['Ganjam','Odisha'],
        761029: ['Ganjam','Odisha'],
        761030: ['Ganjam','Odisha'],
        761031: ['Ganjam','Odisha'],
        761032: ['Ganjam','Odisha'],
        761035: ['Ganjam','Odisha'],
        761037: ['Ganjam','Odisha'],
        761041: ['Ganjam','Odisha'],
        761042: ['Ganjam','Odisha'],
        761043: ['Ganjam','Odisha'],
        761045: ['Ganjam','Odisha'],
        761052: ['Ganjam','Odisha'],
        761054: ['Ganjam','Odisha'],
        761055: ['Ganjam','Odisha'],
        761100: ['Ganjam','Odisha'],
        761101: ['Ganjam','Odisha'],
        761102: ['Ganjam','Odisha'],
        761103: ['Ganjam','Odisha'],
        761104: ['Ganjam','Odisha'],
        761105: ['Ganjam','Odisha'],
        761106: ['Ganjam','Odisha'],
        761107: ['Ganjam','Odisha'],
        761108: ['Ganjam','Odisha'],
        761109: ['Ganjam','Odisha'],
        761110: ['Ganjam','Odisha'],
        761111: ['Ganjam','Odisha'],
        761114: ['Ganjam','Odisha'],
        761115: ['Ganjam','Odisha'],
        761116: ['Ganjam','Odisha'],
        761117: ['Ganjam','Odisha'],
        761118: ['Ganjam','Odisha'],
        761119: ['Ganjam','Odisha'],
        761120: ['Ganjam','Odisha'],
        761121: ['Ganjam','Odisha'],
        761122: ['Ganjam','Odisha'],
        761123: ['Ganjam','Odisha'],
        761124: ['Ganjam','Odisha'],
        761125: ['Ganjam','Odisha'],
        761126: ['Ganjam','Odisha'],
        761131: ['Ganjam','Odisha'],
        761132: ['Ganjam','Odisha'],
        761133: ['Ganjam','Odisha'],
        761140: ['Ganjam','Odisha'],
        761141: ['Ganjam','Odisha'],
        761143: ['Ganjam','Odisha'],
        761144: ['Ganjam','Odisha'],
        761146: ['Ganjam','Odisha'],
        761151: ['Ganjam','Odisha'],
        761200: ['Gajapati','Odisha'],
        761201: ['Gajapati','Odisha'],
        761206: ['Gajapati','Odisha'],
        761207: ['Gajapati','Odisha'],
        761208: ['Gajapati','Odisha'],
        761209: ['Ganjam','Odisha'],
        761210: ['Gajapati','Odisha'],
        761211: ['Gajapati','Odisha'],
        761212: ['Gajapati','Odisha'],
        761213: ['Gajapati','Odisha'],
        761214: ['Gajapati','Odisha'],
        761215: ['Gajapati','Odisha'],
        761217: ['Gajapati','Odisha'],
        762001: ['Kandhamal','Odisha'],
        762002: ['Kandhamal','Odisha'],
        762010: ['Kandhamal','Odisha'],
        762011: ['Kandhamal','Odisha'],
        762012: ['Kandhamal','Odisha'],
        762013: ['Boudh','Odisha'],
        762014: ['Boudh','Odisha'],
        762015: ['Boudh','Odisha'],
        762016: ['Boudh','Odisha'],
        762017: ['Boudh','Odisha'],
        762018: ['Boudh','Odisha'],
        762019: ['Kandhamal','Odisha'],
        762020: ['Boudh','Odisha'],
        762021: ['Kandhamal','Odisha'],
        762022: ['Kandhamal','Odisha'],
        762023: ['Boudh','Odisha'],
        762024: ['Boudh','Odisha'],
        762026: ['Boudh','Odisha'],
        762027: ['Kandhamal','Odisha'],
        762028: ['Kandhamal','Odisha'],
        762029: ['Kandhamal','Odisha'],
        762030: ['Boudh','Odisha'],
        762100: ['Kandhamal','Odisha'],
        762101: ['Kandhamal','Odisha'],
        762102: ['Kandhamal','Odisha'],
        762103: ['Kandhamal','Odisha'],
        762104: ['Kandhamal','Odisha'],
        762105: ['Kandhamal','Odisha'],
        762106: ['Kandhamal','Odisha'],
        762107: ['Kandhamal','Odisha'],
        762109: ['Kandhamal','Odisha'],
        762110: ['Kandhamal','Odisha'],
        762112: ['Kandhamal','Odisha'],
        763001: ['Koraput','Odisha'],
        763002: ['Koraput','Odisha'],
        763003: ['Koraput','Odisha'],
        763004: ['Koraput','Odisha'],
        763008: ['Koraput','Odisha'],
        764001: ['Koraput','Odisha'],
        764002: ['Koraput','Odisha'],
        764003: ['Koraput','Odisha'],
        764004: ['Koraput','Odisha'],
        764005: ['Koraput','Odisha'],
        764006: ['Koraput','Odisha'],
        764011: ['Koraput','Odisha'],
        764014: ['Koraput','Odisha'],
        764020: ['Koraput','Odisha'],
        764021: ['Koraput','Odisha'],
        764028: ['Koraput','Odisha'],
        764036: ['Koraput','Odisha'],
        764037: ['Koraput','Odisha'],
        764038: ['Koraput','Odisha'],
        764039: ['Koraput','Odisha'],
        764040: ['Malkangiri','Odisha'],
        764041: ['Koraput','Odisha'],
        764042: ['Malkangiri','Odisha'],
        764043: ['Koraput','Odisha'],
        764044: ['Malkangiri','Odisha'],
        764045: ['Malkangiri','Odisha'],
        764046: ['Malkangiri','Odisha'],
        764047: ['Malkangiri','Odisha'],
        764048: ['Malkangiri','Odisha'],
        764049: ['Nabarangapur','Odisha'],
        764051: ['Malkangiri','Odisha'],
        764052: ['Malkangiri','Odisha'],
        764055: ['Koraput','Odisha'],
        764056: ['Koraput','Odisha'],
        764057: ['Koraput','Odisha'],
        764058: ['Koraput','Odisha'],
        764059: ['Nabarangapur','Odisha'],
        764061: ['Nabarangapur','Odisha'],
        764062: ['Rayagada','Odisha'],
        764063: ['Nabarangapur','Odisha'],
        764070: ['Nabarangapur','Odisha'],
        764071: ['Nabarangapur','Odisha'],
        764072: ['Nabarangapur','Odisha'],
        764073: ['Nabarangapur','Odisha'],
        764074: ['Nabarangapur','Odisha'],
        764075: ['Nabarangapur','Odisha'],
        764076: ['Nabarangapur','Odisha'],
        764077: ['Nabarangapur','Odisha'],
        764078: ['Nabarangapur','Odisha'],
        764081: ['Koraput','Odisha'],
        764085: ['Nabarangapur','Odisha'],
        764086: ['Malkangiri','Odisha'],
        764087: ['Malkangiri','Odisha'],
        764088: ['Nabarangapur','Odisha'],
        765001: ['Rayagada','Odisha'],
        765002: ['Rayagada','Odisha'],
        765013: ['Koraput','Odisha'],
        765015: ['Rayagada','Odisha'],
        765016: ['Rayagada','Odisha'],
        765017: ['Rayagada','Odisha'],
        765018: ['Rayagada','Odisha'],
        765019: ['Rayagada','Odisha'],
        765020: ['Rayagada','Odisha'],
        765021: ['Rayagada','Odisha'],
        765022: ['Rayagada','Odisha'],
        765023: ['Rayagada','Odisha'],
        765024: ['Rayagada','Odisha'],
        765025: ['Rayagada','Odisha'],
        765026: ['Rayagada','Odisha'],
        765029: ['Rayagada','Odisha'],
        765033: ['Rayagada','Odisha'],
        765034: ['Rayagada','Odisha'],
        766001: ['Kalahandi','Odisha'],
        766002: ['Kalahandi','Odisha'],
        766011: ['Kalahandi','Odisha'],
        766012: ['Kalahandi','Odisha'],
        766013: ['Kalahandi','Odisha'],
        766014: ['Kalahandi','Odisha'],
        766015: ['Kalahandi','Odisha'],
        766016: ['Kalahandi','Odisha'],
        766017: ['Kalahandi','Odisha'],
        766018: ['Kalahandi','Odisha'],
        766019: ['Kalahandi','Odisha'],
        766020: ['Kalahandi','Odisha'],
        766023: ['Kalahandi','Odisha'],
        766026: ['Kalahandi','Odisha'],
        766027: ['Kalahandi','Odisha'],
        766028: ['Kalahandi','Odisha'],
        766029: ['Kalahandi','Odisha'],
        766031: ['Kalahandi','Odisha'],
        766032: ['Kalahandi','Odisha'],
        766036: ['Kalahandi','Odisha'],
        766037: ['Kalahandi','Odisha'],
        766100: ['Kalahandi','Odisha'],
        766101: ['Kalahandi','Odisha'],
        766102: ['Kalahandi','Odisha'],
        766103: ['Kalahandi','Odisha'],
        766104: ['Nuapada','Odisha'],
        766105: ['Nuapada','Odisha'],
        766106: ['Nuapada','Odisha'],
        766107: ['Nuapada','Odisha'],
        766108: ['Nuapada','Odisha'],
        766110: ['Kalahandi','Odisha'],
        766111: ['Nuapada','Odisha'],
        766118: ['Nuapada','Odisha'],
        767001: ['Balangir','Odisha'],
        767002: ['Balangir','Odisha'],
        767016: ['Balangir','Odisha'],
        767017: ['Sonapur','Odisha'],
        767018: ['Sonapur','Odisha'],
        767019: ['Sonapur','Odisha'],
        767020: ['Balangir','Odisha'],
        767021: ['Balangir','Odisha'],
        767022: ['Balangir','Odisha'],
        767023: ['Sonapur','Odisha'],
        767024: ['Balangir','Odisha'],
        767025: ['Balangir','Odisha'],
        767026: ['Balangir','Odisha'],
        767027: ['Balangir','Odisha'],
        767028: ['Balangir','Odisha'],
        767029: ['Balangir','Odisha'],
        767030: ['Balangir','Odisha'],
        767032: ['Balangir','Odisha'],
        767033: ['Balangir','Odisha'],
        767035: ['Balangir','Odisha'],
        767037: ['Balangir','Odisha'],
        767038: ['Balangir','Odisha'],
        767039: ['Balangir','Odisha'],
        767040: ['Balangir','Odisha'],
        767041: ['Balangir','Odisha'],
        767042: ['Balangir','Odisha'],
        767045: ['Sonapur','Odisha'],
        767046: ['Balangir','Odisha'],
        767048: ['Balangir','Odisha'],
        767060: ['Balangir','Odisha'],
        767061: ['Balangir','Odisha'],
        767062: ['Sonapur','Odisha'],
        767065: ['Balangir','Odisha'],
        767066: ['Balangir','Odisha'],
        767067: ['Balangir','Odisha'],
        767068: ['Balangir','Odisha'],
        767070: ['Balangir','Odisha'],
        767071: ['Balangir','Odisha'],
        768001: ['Sambalpur','Odisha'],
        768002: ['Sambalpur','Odisha'],
        768003: ['Sambalpur','Odisha'],
        768004: ['Sambalpur','Odisha'],
        768005: ['Sambalpur','Odisha'],
        768006: ['Sambalpur','Odisha'],
        768016: ['Sambalpur','Odisha'],
        768017: ['Sambalpur','Odisha'],
        768018: ['Sambalpur','Odisha'],
        768019: ['Sambalpur','Odisha'],
        768020: ['Sambalpur','Odisha'],
        768025: ['Sambalpur','Odisha'],
        768026: ['Sambalpur','Odisha'],
        768027: ['Bargarh','Odisha'],
        768028: ['Bargarh','Odisha'],
        768029: ['Bargarh','Odisha'],
        768030: ['Bargarh','Odisha'],
        768031: ['Bargarh','Odisha'],
        768032: ['Bargarh','Odisha'],
        768033: ['Bargarh','Odisha'],
        768034: ['Bargarh','Odisha'],
        768035: ['Bargarh','Odisha'],
        768036: ['Bargarh','Odisha'],
        768037: ['Bargarh','Odisha'],
        768038: ['Bargarh','Odisha'],
        768039: ['Bargarh','Odisha'],
        768040: ['Bargarh','Odisha'],
        768042: ['Bargarh','Odisha'],
        768045: ['Bargarh','Odisha'],
        768048: ['Bargarh','Odisha'],
        768049: ['Bargarh','Odisha'],
        768050: ['Bargarh','Odisha'],
        768052: ['Bargarh','Odisha'],
        768102: ['Bargarh','Odisha'],
        768103: ['Bargarh','Odisha'],
        768104: ['Bargarh','Odisha'],
        768105: ['Sambalpur','Odisha'],
        768106: ['Sambalpur','Odisha'],
        768107: ['Sambalpur','Odisha'],
        768108: ['Debagarh','Odisha'],
        768109: ['Debagarh','Odisha'],
        768110: ['Debagarh','Odisha'],
        768111: ['Bargarh','Odisha'],
        768112: ['Sambalpur','Odisha'],
        768113: ['Sambalpur','Odisha'],
        768115: ['Bargarh','Odisha'],
        768118: ['Sambalpur','Odisha'],
        768119: ['Debagarh','Odisha'],
        768121: ['Debagarh','Odisha'],
        768200: ['Sambalpur','Odisha'],
        768201: ['Jharsuguda','Odisha'],
        768202: ['Jharsuguda','Odisha'],
        768203: ['Jharsuguda','Odisha'],
        768204: ['Jharsuguda','Odisha'],
        768210: ['Jharsuguda','Odisha'],
        768211: ['Jharsuguda','Odisha'],
        768212: ['Sambalpur','Odisha'],
        768213: ['Jharsuguda','Odisha'],
        768214: ['Sambalpur','Odisha'],
        768215: ['Jharsuguda','Odisha'],
        768216: ['Jharsuguda','Odisha'],
        768217: ['Jharsuguda','Odisha'],
        768218: ['Jharsuguda','Odisha'],
        768219: ['Jharsuguda','Odisha'],
        768220: ['Jharsuguda','Odisha'],
        768221: ['Sambalpur','Odisha'],
        768222: ['Sambalpur','Odisha'],
        768224: ['Sambalpur','Odisha'],
        768225: ['Jharsuguda','Odisha'],
        768226: ['Jharsuguda','Odisha'],
        768227: ['Sambalpur','Odisha'],
        768228: ['Sambalpur','Odisha'],
        768233: ['Jharsuguda','Odisha'],
        768234: ['Jharsuguda','Odisha'],
        769010: ['Sundergarh','Odisha'],
        769011: ['Sundergarh','Odisha'],
        769013: ['Sundergarh','Odisha'],
        769015: ['Sundergarh','Odisha'],
        769042: ['Sundergarh','Odisha'],
        769043: ['Sundergarh','Odisha'],
        770001: ['Sundergarh','Odisha'],
        770002: ['Sundergarh','Odisha'],
        770011: ['Sundergarh','Odisha'],
        770012: ['Sundergarh','Odisha'],
        770013: ['Sundergarh','Odisha'],
        770014: ['Sundergarh','Odisha'],
        770015: ['Sundergarh','Odisha'],
        770016: ['Sundergarh','Odisha'],
        770017: ['Sundergarh','Odisha'],
        770018: ['Sundergarh','Odisha'],
        770019: ['Sundergarh','Odisha'],
        770020: ['Sundergarh','Odisha'],
        770021: ['Sundergarh','Odisha'],
        770022: ['Sundergarh','Odisha'],
        770023: ['Sundergarh','Odisha'],
        770024: ['Sundergarh','Odisha'],
        770025: ['Sundergarh','Odisha'],
        770031: ['Sundergarh','Odisha'],
        770032: ['Sundergarh','Odisha'],
        770033: ['Sundergarh','Odisha'],
        770034: ['Sundergarh','Odisha'],
        770035: ['Sundergarh','Odisha'],
        770036: ['Sundergarh','Odisha'],
        770037: ['Sundergarh','Odisha'],
        770038: ['Sundergarh','Odisha'],
        770039: ['Sundergarh','Odisha'],
        770040: ['Sundergarh','Odisha'],
        770041: ['Sundergarh','Odisha'],
        770042: ['Sundergarh','Odisha'],
        770043: ['Sundergarh','Odisha'],
        770044: ['Sundergarh','Odisha'],
        770046: ['Sundergarh','Odisha'],
        770048: ['Sundergarh','Odisha'],
        770051: ['Sundergarh','Odisha'],
        770052: ['Sundergarh','Odisha'],
        770070: ['Sundergarh','Odisha'],
        770072: ['Sundergarh','Odisha'],
        770073: ['Sundergarh','Odisha'],
        770074: ['Sundergarh','Odisha'],
        770075: ['Sundergarh','Odisha'],
        770076: ['Sundergarh','Odisha'],
        781001: ['Kamrup Metropolitan','Assam'],
        781003: ['Kamrup Metropolitan','Assam'],
        781004: ['Kamrup Metropolitan','Assam'],
        781005: ['Kamrup Metropolitan','Assam'],
        781006: ['Kamrup Metropolitan','Assam'],
        781007: ['Kamrup Metropolitan','Assam'],
        781008: ['Kamrup Metropolitan','Assam'],
        781009: ['Kamrup Metropolitan','Assam'],
        781010: ['Kamrup Metropolitan','Assam'],
        781011: ['Kamrup','Assam'],
        781012: ['Kamrup Metropolitan','Assam'],
        781013: ['Kamrup Metropolitan','Assam'],
        781014: ['Kamrup Metropolitan','Assam'],
        781015: ['Kamrup Metropolitan','Assam'],
        781016: ['Kamrup Metropolitan','Assam'],
        781017: ['Kamrup','Assam'],
        781018: ['Kamrup Metropolitan','Assam'],
        781019: ['Kamrup Metropolitan','Assam'],
        781020: ['Kamrup Metropolitan','Assam'],
        781021: ['Kamrup Metropolitan','Assam'],
        781022: ['Kamrup Metropolitan','Assam'],
        781023: ['Kamrup Metropolitan','Assam'],
        781024: ['Kamrup Metropolitan','Assam'],
        781025: ['Kamrup Metropolitan','Assam'],
        781026: ['Kamrup Metropolitan','Assam'],
        781027: ['Kamrup Metropolitan','Assam'],
        781028: ['Kamrup Metropolitan','Assam'],
        781029: ['Kamrup Metropolitan','Assam'],
        781030: ['Kamrup Metropolitan','Assam'],
        781031: ['Kamrup','Assam'],
        781032: ['Kamrup Metropolitan','Assam'],
        781034: ['Kamrup','Assam'],
        781035: ['Kamrup Metropolitan','Assam'],
        781036: ['Kamrup Metropolitan','Assam'],
        781037: ['Kamrup Metropolitan','Assam'],
        781038: ['Kamrup Metropolitan','Assam'],
        781039: ['Kamrup','Assam'],
        781040: ['Kamrup Metropolitan','Assam'],
        781101: ['Kamrup','Assam'],
        781102: ['Kamrup','Assam'],
        781103: ['Kamrup','Assam'],
        781104: ['Kamrup','Assam'],
        781120: ['Kamrup','Assam'],
        781121: ['Kamrup','Assam'],
        781122: ['Kamrup','Assam'],
        781123: ['Kamrup','Assam'],
        781124: ['Kamrup','Assam'],
        781125: ['Kamrup Metropolitan','Assam'],
        781126: ['Nalbari','Assam'],
        781127: ['Kamrup','Assam'],
        781128: ['Kamrup','Assam'],
        781129: ['Kamrup','Assam'],
        781131: ['Kamrup','Assam'],
        781132: ['Kamrup Metropolitan','Assam'],
        781133: ['Kamrup','Assam'],
        781134: ['Kamrup','Assam'],
        781135: ['Kamrup','Assam'],
        781136: ['Kamrup','Assam'],
        781137: ['Kamrup','Assam'],
        781138: ['Nalbari','Assam'],
        781141: ['Kamrup','Assam'],
        781150: ['Kamrup Metropolitan','Assam'],
        781171: ['Kamrup Metropolitan','Assam'],
        781301: ['Barpeta','Assam'],
        781302: ['Barpeta','Assam'],
        781303: ['Nalbari','Assam'],
        781304: ['Nalbari','Assam'],
        781305: ['Barpeta','Assam'],
        781306: ['Nalbari','Assam'],
        781307: ['Barpeta','Assam'],
        781308: ['Barpeta','Assam'],
        781309: ['Barpeta','Assam'],
        781310: ['Nalbari','Assam'],
        781311: ['Barpeta','Assam'],
        781312: ['Nalbari','Assam'],
        781313: ['Baksa','Assam'],
        781314: ['Barpeta','Assam'],
        781315: ['Baksa','Assam'],
        781316: ['Barpeta','Assam'],
        781317: ['Barpeta','Assam'],
        781318: ['Barpeta','Assam'],
        781319: ['Barpeta','Assam'],
        781321: ['Barpeta','Assam'],
        781325: ['Barpeta','Assam'],
        781326: ['Barpeta','Assam'],
        781327: ['Baksa','Assam'],
        781328: ['Barpeta','Assam'],
        781329: ['Barpeta','Assam'],
        781330: ['Baksa','Assam'],
        781333: ['Baksa','Assam'],
        781334: ['Nalbari','Assam'],
        781335: ['Nalbari','Assam'],
        781337: ['Nalbari','Assam'],
        781338: ['Nalbari','Assam'],
        781339: ['Nalbari','Assam'],
        781340: ['Nalbari','Assam'],
        781341: ['Nalbari','Assam'],
        781343: ['Nalbari','Assam'],
        781344: ['Baksa','Assam'],
        781346: ['Baksa','Assam'],
        781347: ['Nalbari','Assam'],
        781348: ['Nalbari','Assam'],
        781349: ['Nalbari','Assam'],
        781350: ['Nalbari','Assam'],
        781351: ['Nalbari','Assam'],
        781352: ['Barpeta','Assam'],
        781353: ['Nalbari','Assam'],
        781354: ['Kamrup','Assam'],
        781355: ['Nalbari','Assam'],
        781360: ['Nalbari','Assam'],
        781364: ['Baksa','Assam'],
        781365: ['Kamrup','Assam'],
        781366: ['Kamrup','Assam'],
        781367: ['Nalbari','Assam'],
        781368: ['Baksa','Assam'],
        781369: ['Nalbari','Assam'],
        781370: ['Nalbari','Assam'],
        781371: ['Nalbari','Assam'],
        781372: ['Nalbari','Assam'],
        781373: ['Nalbari','Assam'],
        781374: ['Nalbari','Assam'],
        781375: ['Nalbari','Assam'],
        781376: ['Kamrup','Assam'],
        781377: ['Nalbari','Assam'],
        781378: ['Nalbari','Assam'],
        781380: ['Kamrup','Assam'],
        781381: ['Kamrup','Assam'],
        781382: ['Kamrup','Assam'],
        782001: ['Nagaon','Assam'],
        782002: ['Nagaon','Assam'],
        782003: ['Nagaon','Assam'],
        782101: ['Nagaon','Assam'],
        782102: ['Nagaon','Assam'],
        782103: ['Nagaon','Assam'],
        782104: ['Marigaon','Assam'],
        782105: ['Marigaon','Assam'],
        782106: ['Marigaon','Assam'],
        782120: ['Nagaon','Assam'],
        782121: ['Nagaon','Assam'],
        782122: ['Nagaon','Assam'],
        782123: ['Nagaon','Assam'],
        782124: ['Nagaon','Assam'],
        782125: ['Nagaon','Assam'],
        782126: ['Marigaon','Assam'],
        782127: ['Marigaon','Assam'],
        782128: ['Nagaon','Assam'],
        782135: ['Nagaon','Assam'],
        782136: ['Nagaon','Assam'],
        782137: ['Nagaon','Assam'],
        782138: ['Nagaon','Assam'],
        782139: ['Nagaon','Assam'],
        782140: ['Nagaon','Assam'],
        782141: ['Nagaon','Assam'],
        782142: ['Nagaon','Assam'],
        782143: ['Nagaon','Assam'],
        782144: ['Nagaon','Assam'],
        782401: ['Kamrup Metropolitan','Assam'],
        782402: ['Kamrup Metropolitan','Assam'],
        782403: ['Kamrup Metropolitan','Assam'],
        782410: ['Marigaon','Assam'],
        782411: ['Marigaon','Assam'],
        782412: ['Marigaon','Assam'],
        782413: ['Marigaon','Assam'],
        782425: ['Nagaon','Assam'],
        782426: ['Nagaon','Assam'],
        782427: ['Nagaon','Assam'],
        782428: ['Nagaon','Assam'],
        782429: ['Nagaon','Assam'],
        782435: ['Nagaon','Assam'],
        782439: ['Nagaon','Assam'],
        782440: ['Nagaon','Assam'],
        782441: ['Karbi Anglong','Assam'],
        782442: ['Nagaon','Assam'],
        782445: ['Nagaon','Assam'],
        782446: ['Nagaon','Assam'],
        782447: ['Nagaon','Assam'],
        782448: ['Karbi Anglong','Assam'],
        782450: ['Karbi Anglong','Assam'],
        782460: ['Karbi Anglong','Assam'],
        782461: ['Karbi Anglong','Assam'],
        782462: ['Karbi Anglong','Assam'],
        782470: ['Karbi Anglong','Assam'],
        782480: ['Karbi Anglong','Assam'],
        782481: ['Nagaon','Assam'],
        782482: ['Karbi Anglong','Assam'],
        782485: ['Karbi Anglong','Assam'],
        782486: ['Karbi Anglong','Assam'],
        782490: ['Karbi Anglong','Assam'],
        783101: ['Goalpara','Assam'],
        783120: ['Goalpara','Assam'],
        783122: ['Goalpara','Assam'],
        783123: ['Goalpara','Assam'],
        783124: ['Goalpara','Assam'],
        783125: ['Goalpara','Assam'],
        783126: ['Goalpara','Assam'],
        783127: ['Dhubri','Assam'],
        783128: ['Dhubri','Assam'],
        783129: ['Goalpara','Assam'],
        783130: ['Goalpara','Assam'],
        783131: ['Dhubri','Assam'],
        783132: ['Goalpara','Assam'],
        783133: ['Goalpara','Assam'],
        783134: ['Goalpara','Assam'],
        783135: ['Dhubri','Assam'],
        783301: ['Bongaigaon','Assam'],
        783323: ['Dhubri','Assam'],
        783324: ['Dhubri','Assam'],
        783325: ['Dhubri','Assam'],
        783330: ['Dhubri','Assam'],
        783331: ['Dhubri','Assam'],
        783332: ['Dhubri','Assam'],
        783333: ['Kokrajhar','Assam'],
        783334: ['Dhubri','Assam'],
        783335: ['Dhubri','Assam'],
        783336: ['Kokrajhar','Assam'],
        783337: ['Kokrajhar','Assam'],
        783339: ['Dhubri','Assam'],
        783345: ['Kokrajhar','Assam'],
        783346: ['Kokrajhar','Assam'],
        783347: ['Kokrajhar','Assam'],
        783348: ['Dhubri','Assam'],
        783349: ['Dhubri','Assam'],
        783350: ['Kokrajhar','Assam'],
        783354: ['Kokrajhar','Assam'],
        783360: ['Kokrajhar','Assam'],
        783361: ['Kokrajhar','Assam'],
        783369: ['Kokrajhar','Assam'],
        783370: ['Kokrajhar','Assam'],
        783371: ['Dhubri','Assam'],
        783372: ['Kokrajhar','Assam'],
        783373: ['Kokrajhar','Assam'],
        783375: ['Kokrajhar','Assam'],
        783376: ['Kokrajhar','Assam'],
        783380: ['Bongaigaon','Assam'],
        783381: ['Bongaigaon','Assam'],
        783382: ['Goalpara','Assam'],
        783383: ['Dhubri','Assam'],
        783384: ['Bongaigaon','Assam'],
        783385: ['Bongaigaon','Assam'],
        783388: ['Bongaigaon','Assam'],
        783389: ['Bongaigaon','Assam'],
        783390: ['Bongaigaon','Assam'],
        783391: ['Bongaigaon','Assam'],
        783392: ['Bongaigaon','Assam'],
        783393: ['Bongaigaon','Assam'],
        783394: ['Bongaigaon','Assam'],
        784001: ['Sonitpur','Assam'],
        784010: ['Sonitpur','Assam'],
        784025: ['Sonitpur','Assam'],
        784026: ['Sonitpur','Assam'],
        784027: ['Sonitpur','Assam'],
        784028: ['Sonitpur','Assam'],
        784101: ['Sonitpur','Assam'],
        784102: ['Sonitpur','Assam'],
        784103: ['Sonitpur','Assam'],
        784104: ['Sonitpur','Assam'],
        784105: ['Sonitpur','Assam'],
        784110: ['Sonitpur','Assam'],
        784111: ['Sonitpur','Assam'],
        784112: ['Sonitpur','Assam'],
        784113: ['Udalguri','Assam'],
        784114: ['Udalguri','Assam'],
        784115: ['Darrang','Assam'],
        784116: ['Darrang','Assam'],
        784117: ['Sonitpur','Assam'],
        784125: ['Darrang','Assam'],
        784144: ['Darrang','Assam'],
        784145: ['Darrang','Assam'],
        784146: ['Darrang','Assam'],
        784147: ['Darrang','Assam'],
        784148: ['Darrang','Assam'],
        784149: ['Sonitpur','Assam'],
        784150: ['Sonitpur','Assam'],
        784153: ['Sonitpur','Assam'],
        784154: ['Sonitpur','Assam'],
        784160: ['Lakhimpur','Assam'],
        784161: ['Lakhimpur','Assam'],
        784163: ['Lakhimpur','Assam'],
        784164: ['Lakhimpur','Assam'],
        784165: ['Lakhimpur','Assam'],
        784166: ['Sonitpur','Assam'],
        784167: ['Sonitpur','Assam'],
        784168: ['Sonitpur','Assam'],
        784169: ['Sonitpur','Assam'],
        784170: ['Sonitpur','Assam'],
        784171: ['Sonitpur','Assam'],
        784172: ['Sonitpur','Assam'],
        784173: ['Sonitpur','Assam'],
        784174: ['Sonitpur','Assam'],
        784175: ['Sonitpur','Assam'],
        784176: ['Sonitpur','Assam'],
        784177: ['Sonitpur','Assam'],
        784178: ['Sonitpur','Assam'],
        784179: ['Sonitpur','Assam'],
        784180: ['Sonitpur','Assam'],
        784182: ['Sonitpur','Assam'],
        784184: ['Sonitpur','Assam'],
        784189: ['Sonitpur','Assam'],
        784190: ['Darrang','Assam'],
        784501: ['Sonitpur','Assam'],
        784502: ['Sonitpur','Assam'],
        784504: ['Sonitpur','Assam'],
        784505: ['Sonitpur','Assam'],
        784506: ['Sonitpur','Assam'],
        784507: ['Udalguri','Assam'],
        784508: ['Udalguri','Assam'],
        784509: ['Udalguri','Assam'],
        784510: ['Udalguri','Assam'],
        784513: ['Udalguri','Assam'],
        784514: ['Darrang','Assam'],
        784521: ['Udalguri','Assam'],
        784522: ['Udalguri','Assam'],
        784523: ['Udalguri','Assam'],
        784524: ['Udalguri','Assam'],
        784525: ['Udalguri','Assam'],
        784526: ['Udalguri','Assam'],
        784527: ['Udalguri','Assam'],
        784528: ['Udalguri','Assam'],
        784529: ['Darrang','Assam'],
        785001: ['Jorhat','Assam'],
        785004: ['Jorhat','Assam'],
        785006: ['Jorhat','Assam'],
        785007: ['Jorhat','Assam'],
        785008: ['Jorhat','Assam'],
        785009: ['Jorhat','Assam'],
        785010: ['Jorhat','Assam'],
        785013: ['Jorhat','Assam'],
        785014: ['Jorhat','Assam'],
        785015: ['Jorhat','Assam'],
        785101: ['Jorhat','Assam'],
        785102: ['Jorhat','Assam'],
        785104: ['Jorhat','Assam'],
        785105: ['Jorhat','Assam'],
        785106: ['Jorhat','Assam'],
        785107: ['Jorhat','Assam'],
        785108: ['Jorhat','Assam'],
        785110: ['Jorhat','Assam'],
        785111: ['Jorhat','Assam'],
        785112: ['Jorhat','Assam'],
        785601: ['Golaghat','Assam'],
        785602: ['Golaghat','Assam'],
        785603: ['Golaghat','Assam'],
        785609: ['Golaghat','Assam'],
        785610: ['Golaghat','Assam'],
        785611: ['Golaghat','Assam'],
        785612: ['Golaghat','Assam'],
        785613: ['Golaghat','Assam'],
        785614: ['Golaghat','Assam'],
        785615: ['Golaghat','Assam'],
        785616: ['Jorhat','Assam'],
        785618: ['Golaghat','Assam'],
        785619: ['Golaghat','Assam'],
        785621: ['Golaghat','Assam'],
        785622: ['Golaghat','Assam'],
        785625: ['Golaghat','Assam'],
        785626: ['Golaghat','Assam'],
        785630: ['Jorhat','Assam'],
        785631: ['Jorhat','Assam'],
        785632: ['Jorhat','Assam'],
        785633: ['Jorhat','Assam'],
        785634: ['Jorhat','Assam'],
        785635: ['Jorhat','Assam'],
        785636: ['Jorhat','Assam'],
        785640: ['Sibsagar','Assam'],
        785661: ['Sibsagar','Assam'],
        785662: ['Sibsagar','Assam'],
        785663: ['Sibsagar','Assam'],
        785664: ['Sibsagar','Assam'],
        785665: ['Sibsagar','Assam'],
        785666: ['Sibsagar','Assam'],
        785667: ['Sibsagar','Assam'],
        785669: ['Sibsagar','Assam'],
        785670: ['Sibsagar','Assam'],
        785671: ['Sibsagar','Assam'],
        785672: ['Sibsagar','Assam'],
        785673: ['Sibsagar','Assam'],
        785674: ['Sibsagar','Assam'],
        785675: ['Dibrugarh','Assam'],
        785676: ['Dibrugarh','Assam'],
        785680: ['Sibsagar','Assam'],
        785681: ['Sibsagar','Assam'],
        785682: ['Sibsagar','Assam'],
        785683: ['Sibsagar','Assam'],
        785684: ['Sibsagar','Assam'],
        785685: ['Sibsagar','Assam'],
        785686: ['Sibsagar','Assam'],
        785687: ['Sibsagar','Assam'],
        785688: ['Sibsagar','Assam'],
        785689: ['Sibsagar','Assam'],
        785690: ['Sibsagar','Assam'],
        785691: ['Sibsagar','Assam'],
        785692: ['Sibsagar','Assam'],
        785693: ['Sibsagar','Assam'],
        785696: ['Sibsagar','Assam'],
        785697: ['Sibsagar','Assam'],
        785698: ['Sibsagar','Assam'],
        785699: ['Golaghat','Assam'],
        785700: ['Jorhat','Assam'],
        785701: ['Sibsagar','Assam'],
        785702: ['Golaghat','Assam'],
        785703: ['Golaghat','Assam'],
        785704: ['Jorhat','Assam'],
        785705: ['Golaghat','Assam'],
        786001: ['Dibrugarh','Assam'],
        786002: ['Dibrugarh','Assam'],
        786003: ['Dibrugarh','Assam'],
        786004: ['Dibrugarh','Assam'],
        786005: ['Dibrugarh','Assam'],
        786006: ['Dibrugarh','Assam'],
        786007: ['Dibrugarh','Assam'],
        786008: ['Dibrugarh','Assam'],
        786010: ['Dibrugarh','Assam'],
        786012: ['Dibrugarh','Assam'],
        786101: ['Dibrugarh','Assam'],
        786102: ['Dibrugarh','Assam'],
        786103: ['Dibrugarh','Assam'],
        786125: ['Tinsukia','Assam'],
        786126: ['Tinsukia','Assam'],
        786145: ['Tinsukia','Assam'],
        786146: ['Tinsukia','Assam'],
        786147: ['Tinsukia','Assam'],
        786148: ['Tinsukia','Assam'],
        786150: ['Tinsukia','Assam'],
        786151: ['Tinsukia','Assam'],
        786152: ['Tinsukia','Assam'],
        786153: ['Tinsukia','Assam'],
        786154: ['Tinsukia','Assam'],
        786155: ['Tinsukia','Assam'],
        786156: ['Tinsukia','Assam'],
        786157: ['Tinsukia','Assam'],
        786158: ['Tinsukia','Assam'],
        786159: ['Tinsukia','Assam'],
        786160: ['Tinsukia','Assam'],
        786170: ['Tinsukia','Assam'],
        786171: ['Tinsukia','Assam'],
        786173: ['Tinsukia','Assam'],
        786174: ['Tinsukia','Assam'],
        786179: ['Tinsukia','Assam'],
        786181: ['Tinsukia','Assam'],
        786182: ['Tinsukia','Assam'],
        786183: ['Tinsukia','Assam'],
        786184: ['Dibrugarh','Assam'],
        786187: ['Tinsukia','Assam'],
        786188: ['Tinsukia','Assam'],
        786189: ['Tinsukia','Assam'],
        786190: ['Tinsukia','Assam'],
        786191: ['Dibrugarh','Assam'],
        786192: ['Tinsukia','Assam'],
        786601: ['Tinsukia','Assam'],
        786602: ['Dibrugarh','Assam'],
        786610: ['Dibrugarh','Assam'],
        786611: ['Dibrugarh','Assam'],
        786612: ['Dibrugarh','Assam'],
        786613: ['Dibrugarh','Assam'],
        786614: ['Dibrugarh','Assam'],
        786621: ['Dibrugarh','Assam'],
        786622: ['Dibrugarh','Assam'],
        786623: ['Dibrugarh','Assam'],
        786692: ['Dibrugarh','Assam'],
        787001: ['Lakhimpur','Assam'],
        787023: ['Lakhimpur','Assam'],
        787026: ['Dhemaji','Assam'],
        787031: ['Dhemaji','Assam'],
        787032: ['Lakhimpur','Assam'],
        787033: ['Lakhimpur','Assam'],
        787034: ['Dhemaji','Assam'],
        787035: ['Dhemaji','Assam'],
        787051: ['Lakhimpur','Assam'],
        787052: ['Lakhimpur','Assam'],
        787053: ['Lakhimpur','Assam'],
        787054: ['Lakhimpur','Assam'],
        787055: ['Lakhimpur','Assam'],
        787056: ['Lakhimpur','Assam'],
        787057: ['Dhemaji','Assam'],
        787058: ['Dhemaji','Assam'],
        787059: ['Dhemaji','Assam'],
        787060: ['Dhemaji','Assam'],
        787061: ['Dhemaji','Assam'],
        787110: ['Dhemaji','Assam'],
        788001: ['Cachar','Assam'],
        788002: ['Cachar','Assam'],
        788003: ['Cachar','Assam'],
        788004: ['Cachar','Assam'],
        788005: ['Cachar','Assam'],
        788006: ['Cachar','Assam'],
        788007: ['Cachar','Assam'],
        788009: ['Cachar','Assam'],
        788010: ['Cachar','Assam'],
        788011: ['Cachar','Assam'],
        788013: ['Cachar','Assam'],
        788014: ['Cachar','Assam'],
        788015: ['Cachar','Assam'],
        788025: ['Cachar','Assam'],
        788026: ['Cachar','Assam'],
        788030: ['Cachar','Assam'],
        788031: ['Cachar','Assam'],
        788098: ['Cachar','Assam'],
        788099: ['Cachar','Assam'],
        788101: ['Cachar','Assam'],
        788102: ['Cachar','Assam'],
        788103: ['Cachar','Assam'],
        788104: ['Cachar','Assam'],
        788106: ['Cachar','Assam'],
        788107: ['Cachar','Assam'],
        788108: ['Cachar','Assam'],
        788109: ['Cachar','Assam'],
        788110: ['Cachar','Assam'],
        788111: ['Cachar','Assam'],
        788112: ['Cachar','Assam'],
        788113: ['Cachar','Assam'],
        788114: ['Cachar','Assam'],
        788115: ['Cachar','Assam'],
        788116: ['Cachar','Assam'],
        788117: ['Cachar','Assam'],
        788118: ['Cachar','Assam'],
        788119: ['Cachar','Assam'],
        788120: ['Cachar','Assam'],
        788121: ['Cachar','Assam'],
        788123: ['Cachar','Assam'],
        788126: ['Cachar','Assam'],
        788127: ['Cachar','Assam'],
        788150: ['Hailakandi','Assam'],
        788151: ['Hailakandi','Assam'],
        788152: ['Hailakandi','Assam'],
        788155: ['Karimganj','Assam'],
        788156: ['Karimganj','Assam'],
        788160: ['Hailakandi','Assam'],
        788161: ['Hailakandi','Assam'],
        788162: ['Hailakandi','Assam'],
        788163: ['Hailakandi','Assam'],
        788164: ['Hailakandi','Assam'],
        788165: ['Hailakandi','Assam'],
        788166: ['Karimganj','Assam'],
        788168: ['Hailakandi','Assam'],
        788701: ['Karimganj','Assam'],
        788709: ['Karimganj','Assam'],
        788710: ['Karimganj','Assam'],
        788711: ['Karimganj','Assam'],
        788712: ['Karimganj','Assam'],
        788713: ['Karimganj','Assam'],
        788719: ['Karimganj','Assam'],
        788720: ['Karimganj','Assam'],
        788722: ['Karimganj','Assam'],
        788723: ['Karimganj','Assam'],
        788724: ['Karimganj','Assam'],
        788725: ['Karimganj','Assam'],
        788726: ['Karimganj','Assam'],
        788727: ['Karimganj','Assam'],
        788728: ['Karimganj','Assam'],
        788733: ['Karimganj','Assam'],
        788734: ['Karimganj','Assam'],
        788735: ['Karimganj','Assam'],
        788736: ['Karimganj','Assam'],
        788737: ['Karimganj','Assam'],
        788781: ['Karimganj','Assam'],
        788801: ['Hailakandi','Assam'],
        788802: ['Cachar','Assam'],
        788803: ['Cachar','Assam'],
        788804: ['Karimganj','Assam'],
        788805: ['Karimganj','Assam'],
        788806: ['Karimganj','Assam'],
        788815: ['Cachar','Assam'],
        788816: ['Karimganj','Assam'],
        788817: ['Karimganj','Assam'],
        788818: ['Cachar','Assam'],
        788819: ['Cachar','Assam'],
        788820: ['Cachar','Assam'],
        788830: ['Dima Hasso - North Cachar Hill','Assam'],
        788831: ['Dima Hasso - North Cachar Hill','Assam'],
        788832: ['Cachar','Assam'],
        788931: ['Dima Hasso - North Cachar Hill','Assam'],
        790001: ['West Kameng','Arunachal Pradesh'],
        790002: ['West Kameng','Arunachal Pradesh'],
        790003: ['West Kameng','Arunachal Pradesh'],
        790101: ['West Kameng','Arunachal Pradesh'],
        790102: ['East Kameng','Arunachal Pradesh'],
        790103: ['East Kameng','Arunachal Pradesh'],
        790104: ['Tawang','Arunachal Pradesh'],
        790105: ['Tawang','Arunachal Pradesh'],
        790106: ['Tawang','Arunachal Pradesh'],
        790114: ['West Kameng','Arunachal Pradesh'],
        790116: ['West Kameng','Arunachal Pradesh'],
        791001: ['West Siang','Arunachal Pradesh'],
        791002: ['Upper Siang','Arunachal Pradesh'],
        791003: ['West Siang','Arunachal Pradesh'],
        791101: ['West Siang','Arunachal Pradesh'],
        791102: ['East Siang','Arunachal Pradesh'],
        791103: ['East Siang','Arunachal Pradesh'],
        791104: ['East Siang','Arunachal Pradesh'],
        791105: ['Upper Siang','Arunachal Pradesh'],
        791109: ['Papum Pare','Arunachal Pradesh'],
        791110: ['Papum Pare','Arunachal Pradesh'],
        791111: ['Papum Pare','Arunachal Pradesh'],
        791112: ['Papum Pare','Arunachal Pradesh'],
        791113: ['Lower Subansiri','Arunachal Pradesh'],
        791114: ['Papum Pare','Arunachal Pradesh'],
        791118: ['Kurung Kumey','Arunachal Pradesh'],
        791119: ['Papum Pare','Arunachal Pradesh'],
        791120: ['Lower Subansiri','Arunachal Pradesh'],
        791121: ['Papum Pare','Arunachal Pradesh'],
        791122: ['Upper Subansiri','Arunachal Pradesh'],
        791123: ['Papum Pare','Arunachal Pradesh'],
        791125: ['West Siang','Arunachal Pradesh'],
        792001: ['Lohit','Arunachal Pradesh'],
        792055: ['Changlang','Arunachal Pradesh'],
        792056: ['Changlang','Arunachal Pradesh'],
        792101: ['Dibang Valley','Arunachal Pradesh'],
        792102: ['Lohit','Arunachal Pradesh'],
        792103: ['Lohit','Arunachal Pradesh'],
        792104: ['Papum Pare','Arunachal Pradesh'],
        792105: ['Lohit','Arunachal Pradesh'],
        792110: ['Lower Dibang Valley','Arunachal Pradesh'],
        792111: ['Lohit','Arunachal Pradesh'],
        792120: ['Changlang','Arunachal Pradesh'],
        792121: ['Changlang','Arunachal Pradesh'],
        792122: ['Changlang','Arunachal Pradesh'],
        792123: ['Changlang','Arunachal Pradesh'],
        792129: ['Tirap','Arunachal Pradesh'],
        792130: ['Tirap','Arunachal Pradesh'],
        792131: ['Tirap','Arunachal Pradesh'],
        793001: ['East Khasi Hills','Meghalaya'],
        793002: ['East Khasi Hills','Meghalaya'],
        793003: ['East Khasi Hills','Meghalaya'],
        793004: ['East Khasi Hills','Meghalaya'],
        793005: ['East Khasi Hills','Meghalaya'],
        793006: ['East Khasi Hills','Meghalaya'],
        793007: ['East Khasi Hills','Meghalaya'],
        793008: ['East Khasi Hills','Meghalaya'],
        793009: ['East Khasi Hills','Meghalaya'],
        793010: ['East Khasi Hills','Meghalaya'],
        793011: ['East Khasi Hills','Meghalaya'],
        793012: ['East Khasi Hills','Meghalaya'],
        793014: ['East Khasi Hills','Meghalaya'],
        793015: ['East Khasi Hills','Meghalaya'],
        793016: ['East Khasi Hills','Meghalaya'],
        793017: ['East Khasi Hills','Meghalaya'],
        793018: ['Na','Meghalaya'],
        793019: ['East Khasi Hills','Meghalaya'],
        793021: ['East Khasi Hills','Meghalaya'],
        793022: ['East Khasi Hills','Meghalaya'],
        793101: ['Ri Bhoi','Meghalaya'],
        793102: ['Ri Bhoi','Meghalaya'],
        793103: ['Ri Bhoi','Meghalaya'],
        793104: ['Ri Bhoi','Meghalaya'],
        793105: ['Ri Bhoi','Meghalaya'],
        793106: ['East Khasi Hills','Meghalaya'],
        793107: ['East Khasi Hills','Meghalaya'],
        793108: ['East Khasi Hills','Meghalaya'],
        793109: ['Jaintia Hills','Meghalaya'],
        793110: ['East Khasi Hills','Meghalaya'],
        793111: ['East Khasi Hills','Meghalaya'],
        793112: ['East Khasi Hills','Meghalaya'],
        793113: ['East Khasi Hills','Meghalaya'],
        793114: ['West Khasi Hills','Meghalaya'],
        793115: ['Na','Meghalaya'],
        793116: ['Shillong','Meghalaya'],
        793119: ['West Khasi Hills','Meghalaya'],
        793120: ['West Khasi Hills','Meghalaya'],
        793121: ['East Khasi Hills','Meghalaya'],
        793122: ['Ri Bhoi','Meghalaya'],
        793123: ['Ri Bhoi','Meghalaya'],
        793126: ['Na','Meghalaya'],
        793150: ['Jaintia Hills','Meghalaya'],
        793151: ['Jaintia Hills','Meghalaya'],
        793160: ['Jaintia Hills','Meghalaya'],
        793161: ['Jaintia Hills','Meghalaya'],
        793200: ['Jaintia Hills','Meghalaya'],
        793210: ['Na','Meghalaya'],
        794001: ['West Garo Hills','Meghalaya'],
        794002: ['West Garo Hills','Meghalaya'],
        794003: ['Tura','Meghalaya'],
        794005: ['West Garo Hills','Meghalaya'],
        794101: ['West Garo Hills','Meghalaya'],
        794102: ['South Garo Hills','Meghalaya'],
        794103: ['West Garo Hills','Meghalaya'],
        794104: ['West Garo Hills','Meghalaya'],
        794105: ['West Garo Hills','Meghalaya'],
        794106: ['West Garo Hills','Meghalaya'],
        794107: ['East Garo Hills','Meghalaya'],
        794108: ['East Garo Hills','Meghalaya'],
        794109: ['West Garo Hills','Meghalaya'],
        794110: ['East Garo Hills','Meghalaya'],
        794111: ['East Garo Hills','Meghalaya'],
        794112: ['West Garo Hills','Meghalaya'],
        794114: ['South Garo Hills','Meghalaya'],
        794115: ['West Garo Hills','Meghalaya'],
        795001: ['Imphal West','Manipur'],
        795002: ['Imphal West','Manipur'],
        795003: ['Imphal West','Manipur'],
        795004: ['Imphal West','Manipur'],
        795005: ['Imphal East','Manipur'],
        795006: ['Churachandpur','Manipur'],
        795007: ['Senapati','Manipur'],
        795008: ['Imphal East','Manipur'],
        795009: ['Imphal West','Manipur'],
        795010: ['Imphal East','Manipur'],
        795011: ['Bishnupur','Manipur'],
        795015: ['Ukhrul','Manipur'],
        795101: ['Thoubal','Manipur'],
        795102: ['Chandel','Manipur'],
        795103: ['Thoubal','Manipur'],
        795104: ['Senapati','Manipur'],
        795105: ['Senapati','Manipur'],
        795106: ['Senapati','Manipur'],
        795107: ['Senapati','Manipur'],
        795112: ['Senapati','Manipur'],
        795113: ['Imphal West','Manipur'],
        795114: ['Imphal East','Manipur'],
        795115: ['Imphal West','Manipur'],
        795116: ['Imphal West','Manipur'],
        795117: ['Churachandpur','Manipur'],
        795118: ['Senapati','Manipur'],
        795122: ['Senapati','Manipur'],
        795124: ['Bishnupur','Manipur'],
        795125: ['Tamenglong','Manipur'],
        795126: ['Bishnupur','Manipur'],
        795127: ['Chandel','Manipur'],
        795128: ['Churachandpur','Manipur'],
        795129: ['Senapati','Manipur'],
        795130: ['Thoubal','Manipur'],
        795131: ['Chandel','Manipur'],
        795132: ['Imphal West','Manipur'],
        795133: ['Bishnupur','Manipur'],
        795134: ['Bishnupur','Manipur'],
        795135: ['Thoubal','Manipur'],
        795136: ['Imphal West','Manipur'],
        795138: ['Thoubal','Manipur'],
        795139: ['Churachandpur','Manipur'],
        795140: ['Imphal West','Manipur'],
        795141: ['Tamenglong','Manipur'],
        795142: ['Ukhrul','Manipur'],
        795144: ['Ukhrul','Manipur'],
        795145: ['Ukhrul','Manipur'],
        795146: ['Imphal West','Manipur'],
        795147: ['Tamenglong','Manipur'],
        795148: ['Thoubal','Manipur'],
        795149: ['Thoubal','Manipur'],
        795150: ['Senapati','Manipur'],
        795159: ['Tamenglong','Manipur'],
        796001: ['Aizawl','Mizoram'],
        796004: ['Aizawl','Mizoram'],
        796005: ['Aizawl','Mizoram'],
        796007: ['Aizawl','Mizoram'],
        796008: ['Aizawl','Mizoram'],
        796009: ['Aizawl','Mizoram'],
        796012: ['Aizawl','Mizoram'],
        796014: ['Aizawl','Mizoram'],
        796015: ['Aizawal','Mizoram'],
        796017: ['Aizawl','Mizoram'],
        796036: ['Aizawl','Mizoram'],
        796070: ['Kolasib','Mizoram'],
        796075: ['Kolasib','Mizoram'],
        796081: ['Kolasib','Mizoram'],
        796091: ['Kolasib','Mizoram'],
        796101: ['Kolasib','Mizoram'],
        796111: ['Aizawl','Mizoram'],
        796161: ['Aizawl','Mizoram'],
        796181: ['Serchhip','Mizoram'],
        796184: ['Serchhip','Mizoram'],
        796186: ['Na','Mizoram'],
        796190: ['Aizawl','Mizoram'],
        796230: ['Aizawl','Mizoram'],
        796261: ['Aizawl','Mizoram'],
        796290: ['Champhai','Mizoram'],
        796310: ['Champhai','Mizoram'],
        796320: ['Champhai','Mizoram'],
        796321: ['Champhai','Mizoram'],
        796370: ['Champhai','Mizoram'],
        796410: ['Mammit','Mizoram'],
        796421: ['Aizawl','Mizoram'],
        796431: ['Mammit','Mizoram'],
        796441: ['Mammit','Mizoram'],
        796470: ['Mammit','Mizoram'],
        796471: ['Aizawl','Mizoram'],
        796501: ['Mammit','Mizoram'],
        796571: ['Lunglei','Mizoram'],
        796581: ['Lunglei','Mizoram'],
        796691: ['Lunglei','Mizoram'],
        796701: ['Na','Mizoram'],
        796710: ['Lunglei','Mizoram'],
        796751: ['Lunglei','Mizoram'],
        796770: ['Lunglei','Mizoram'],
        796772: ['Lawngtlai','Mizoram'],
        796810: ['Lawngtlai','Mizoram'],
        796891: ['Lawngtlai','Mizoram'],
        796901: ['Saiha','Mizoram'],
        797001: ['Kohima','Nagaland'],
        797002: ['Kohima','Nagaland'],
        797003: ['Kohima','Nagaland'],
        797004: ['Kohima','Nagaland'],
        797006: ['Kohima','Nagaland'],
        797099: ['Wokha','Nagaland'],
        797101: ['Peren','Nagaland'],
        797103: ['Dimapur','Nagaland'],
        797104: ['Phek','Nagaland'],
        797105: ['Kohima','Nagaland'],
        797106: ['Dimapur','Nagaland'],
        797107: ['Na','Nagaland'],
        797108: ['Phek','Nagaland'],
        797109: ['Kohima','Nagaland'],
        797110: ['Peren','Nagaland'],
        797111: ['Wokha','Nagaland'],
        797112: ['Dimapur','Nagaland'],
        797113: ['Dimapur','Sikkim'],
        797114: ['Phek','Nagaland'],
        797115: ['Dimapur','Nagaland'],
        797116: ['Dimapur','Nagaland'],
        797117: ['Dimapur','Nagaland'],
        797118: ['Dimapur','Nagaland'],
        797120: ['Kohima','Nagaland'],
        797121: ['Kohima','Nagaland'],
        798601: ['Mokokchung','Nagaland'],
        798602: ['Mon','Nagaland'],
        798603: ['Mon','Nagaland'],
        798604: ['Mokokchung','Nagaland'],
        798607: ['Mokokchung','Nagaland'],
        798611: ['Kiphire','Nagaland'],
        798612: ['Tuensang','Nagaland'],
        798613: ['Mokokchung','Nagaland'],
        798614: ['Mokokchung','Nagaland'],
        798615: ['Mokokchung','Nagaland'],
        798616: ['Tuensang','Nagaland'],
        798618: ['Mokokchung','Nagaland'],
        798619: ['Zunhebotto','Nagaland'],
        798620: ['Zunhebotto','Nagaland'],
        798621: ['Mon','Nagaland'],
        798622: ['Mon','Nagaland'],
        798623: ['Mokokchung','Nagaland'],
        798625: ['Longleng','Nagaland'],
        798627: ['Zunhebotto','Nagaland'],
        799001: ['West Tripura','Tripura'],
        799002: ['West Tripura','Tripura'],
        799003: ['West Tripura','Tripura'],
        799004: ['West Tripura','Tripura'],
        799005: ['West Tripura','Tripura'],
        799006: ['West Tripura','Tripura'],
        799007: ['West Tripura','Tripura'],
        799008: ['West Tripura','Tripura'],
        799009: ['West Tripura','Tripura'],
        799010: ['West Tripura','Tripura'],
        799011: ['Agartala ','Tripura'],
        799012: ['West Tripura','Tripura'],
        799013: ['South Tripura','Tripura'],
        799014: ['West Tripura','Tripura'],
        799015: ['West Tripura','Tripura'],
        799022: ['West Tripura','Tripura'],
        799035: ['West Tripura','Tripura'],
        799045: ['West Tripura','Tripura'],
        799046: ['West Tripura','Manipur'],
        799101: ['South Tripura','Tripura'],
        799102: ['West Tripura','Tripura'],
        799103: ['West Tripura','Tripura'],
        799104: ['South Tripura','Tripura'],
        799105: ['South Tripura','Tripura'],
        799113: ['Gomati','Tripura'],
        799114: ['South Tripura','Tripura'],
        799115: ['South Tripura','Tripura'],
        799120: ['South Tripura','Tripura'],
        799125: ['South Tripura','Tripura'],
        799130: ['West Tripura','Tripura'],
        799131: ['West Tripura','Tripura'],
        799132: ['Sepahijala','Tripura'],
        799141: ['South Tripura','Tripura'],
        799142: ['South Tripura','Tripura'],
        799143: ['South Tripura','Tripura'],
        799144: ['South Tripura','Tripura'],
        799145: ['South Tripura','Tripura'],
        799150: ['South Tripura','Tripura'],
        799153: ['West Tripura','Tripura'],
        799155: ['South Tripura','Tripura'],
        799156: ['South Tripura','Tripura'],
        799157: ['South Tripura','Tripura'],
        799201: ['West Tripura','Tripura'],
        799202: ['West Tripura','Tripura'],
        799203: ['West Tripura','Tripura'],
        799204: ['Dhalai','Tripura'],
        799205: ['West Tripura','Tripura'],
        799207: ['West Tripura','Tripura'],
        799210: ['West Tripura','Tripura'],
        799211: ['West Tripura','Tripura'],
        799212: ['West Tripura','Tripura'],
        799250: ['North Tripura','Tripura'],
        799251: ['North Tripura','Tripura'],
        799253: ['North Tripura','Tripura'],
        799254: ['North Tripura','Tripura'],
        799256: ['North Tripura','Tripura'],
        799260: ['North Tripura','Tripura'],
        799261: ['North Tripura','Tripura'],
        799262: ['North Tripura','Tripura'],
        799263: ['North Tripura','Tripura'],
        799264: ['North Tripura','Tripura'],
        799266: ['Dharmanagar','Tripura'],
        799269: ['North Tripura','Tripura'],
        799270: ['North Tripura','Tripura'],
        799271: ['North Tripura','Tripura'],
        799273: ['North Tripura','Tripura'],
        799275: ['Dhalai','Tripura'],
        799277: ['North Tripura','Tripura'],
        799278: ['Dhalai','Tripura'],
        799279: ['North Tripura','Tripura'],
        799280: ['North Tripura','Tripura'],
        799281: ['North Tripura','Tripura'],
        799282: ['Dharmanagar','Tripura'],
        799284: ['Dhalai','Tripura'],
        799285: ['Dhalai','Tripura'],
        799286: ['Dhalai','Tripura'],
        799287: ['Dhalai','Tripura'],
        799288: ['North Tripura','Tripura'],
        799289: ['Dhalai','Tripura'],
        799290: ['North Tripura','Tripura'],
        801301: ['Nalanda','Bihar'],
        801302: ['Nalanda','Bihar'],
        801303: ['Nalanda','Bihar'],
        801305: ['Nalanda','Bihar'],
        801306: ['Nalanda','Bihar'],
        801307: ['Nalanda','Bihar'],
        802101: ['Buxar','Bihar'],
        802102: ['Buxar','Bihar'],
        802103: ['Bhojpur','Bihar'],
        802111: ['Bhojpur','Bihar'],
        802112: ['Buxar','Bihar'],
        802113: ['Buxar','Bihar'],
        802114: ['Buxar','Bihar'],
        802115: ['Bhojpur','Bihar'],
        802116: ['Buxar','Bihar'],
        802117: ['Buxar','Bihar'],
        802118: ['Bhojpur','Bihar'],
        802119: ['Buxar','Bihar'],
        802120: ['Buxar','Bihar'],
        802122: ['Buxar','Bihar'],
        802123: ['Buxar','Bihar'],
        802125: ['Buxar','Bihar'],
        802126: ['Buxar','Bihar'],
        802127: ['Buxar','Bihar'],
        802128: ['Buxar','Bihar'],
        802129: ['Buxar','Bihar'],
        802130: ['Buxar','Bihar'],
        802131: ['Buxar','Bihar'],
        802132: ['Rohtas','Bihar'],
        802133: ['Bhojpur','Bihar'],
        802134: ['Buxar','Bihar'],
        802135: ['Buxar','Bihar'],
        802136: ['Bhojpur','Bihar'],
        802152: ['Bhojpur','Bihar'],
        802154: ['Bhojpur','Bihar'],
        802155: ['Bhojpur','Bihar'],
        802156: ['Bhojpur','Bihar'],
        802157: ['Bhojpur','Bihar'],
        802158: ['Bhojpur','Bihar'],
        802159: ['Bhojpur','Bihar'],
        802160: ['Bhojpur','Bihar'],
        802161: ['Bhojpur','Bihar'],
        802162: ['Bhojpur','Bihar'],
        802163: ['Bhojpur','Bihar'],
        802164: ['Bhojpur','Bihar'],
        802165: ['Bhojpur','Bihar'],
        802166: ['Bhojpur','Bihar'],
        802183: ['Bhojpur','Bihar'],
        802201: ['Bhojpur','Bihar'],
        802202: ['Bhojpur','Bihar'],
        802203: ['Bhojpur','Bihar'],
        802204: ['Bhojpur','Bihar'],
        802205: ['Bhojpur','Bihar'],
        802206: ['Bhojpur','Bihar'],
        802207: ['Bhojpur','Bihar'],
        802208: ['Bhojpur','Bihar'],
        802209: ['Bhojpur','Bihar'],
        802210: ['Bhojpur','Bihar'],
        802211: ['Rohtas','Bihar'],
        802212: ['Rohtas','Bihar'],
        802213: ['Rohtas','Bihar'],
        802214: ['Rohtas','Bihar'],
        802215: ['Rohtas','Bihar'],
        802216: ['Rohtas','Bihar'],
        802217: ['Rohtas','Bihar'],
        802218: ['Rohtas','Bihar'],
        802219: ['Rohtas','Bihar'],
        802220: ['Rohtas','Bihar'],
        802221: ['Rohtas','Bihar'],
        802222: ['Bhojpur','Bihar'],
        802223: ['Bhojpur','Bihar'],
        802226: ['Rohtas','Bihar'],
        802301: ['Bhojpur','Bihar'],
        802302: ['Bhojpur','Bihar'],
        802311: ['Bhojpur','Bihar'],
        802312: ['Bhojpur','Bihar'],
        802313: ['Bhojpur','Bihar'],
        802314: ['Bhojpur','Bihar'],
        802316: ['Bhojpur','Bihar'],
        802351: ['Bhojpur','Bihar'],
        802352: ['Bhojpur','Bihar'],
        803101: ['Nalanda','Bihar'],
        803107: ['Nalanda','Bihar'],
        803108: ['Nalanda','Bihar'],
        803109: ['Nawada','Bihar'],
        803110: ['Nalanda','Bihar'],
        803111: ['Nalanda','Bihar'],
        803113: ['Nalanda','Bihar'],
        803114: ['Nalanda','Bihar'],
        803115: ['Nalanda','Bihar'],
        803116: ['Nalanda','Bihar'],
        803117: ['Nalanda','Bihar'],
        803118: ['Nalanda','Bihar'],
        803119: ['Nalanda','Bihar'],
        803120: ['Nalanda','Bihar'],
        803121: ['Nalanda','Bihar'],
        803215: ['Nalanda','Bihar'],
        803216: ['Nalanda','Bihar'],
        804401: ['Aurangabad','Bihar'],
        804402: ['Arwal','Bihar'],
        804403: ['Gaya','Bihar'],
        804404: ['Gaya','Bihar'],
        804405: ['Gaya','Bihar'],
        804406: ['Gaya','Bihar'],
        804407: ['Jehanabad','Bihar'],
        804408: ['Jehanabad','Bihar'],
        804417: ['Gaya','Bihar'],
        804418: ['Gaya','Bihar'],
        804419: ['Arwal','Bihar'],
        804420: ['Gaya','Bihar'],
        804421: ['Gaya','Bihar'],
        804422: ['Gaya','Bihar'],
        804423: ['Gaya','Bihar'],
        804424: ['Gaya','Bihar'],
        804425: ['Gaya','Bihar'],
        804426: ['Gaya','Bihar'],
        804427: ['Jehanabad','Bihar'],
        804428: ['Jehanabad','Bihar'],
        804429: ['Gaya','Bihar'],
        804432: ['Gaya','Bihar'],
        804435: ['Gaya','Bihar'],
        805101: ['Nawada','Bihar'],
        805102: ['Nawada','Bihar'],
        805103: ['Nawada','Bihar'],
        805104: ['Nawada','Bihar'],
        805105: ['Nawada','Bihar'],
        805106: ['Nawada','Bihar'],
        805107: ['Nawada','Bihar'],
        805108: ['Nawada','Bihar'],
        805109: ['Nawada','Bihar'],
        805110: ['Nawada','Bihar'],
        805111: ['Nawada','Bihar'],
        805112: ['Nawada','Bihar'],
        805113: ['Nawada','Bihar'],
        805121: ['Nawada','Bihar'],
        805122: ['Nawada','Bihar'],
        805123: ['Nawada','Bihar'],
        805124: ['Nawada','Bihar'],
        805125: ['Nawada','Bihar'],
        805126: ['Nawada','Bihar'],
        805127: ['Nawada','Bihar'],
        805128: ['Nawada','Bihar'],
        805129: ['Nawada','Bihar'],
        805130: ['Nawada','Bihar'],
        805131: ['Gaya','Bihar'],
        805132: ['Nawada','Bihar'],
        811101: ['Nalanda','Bihar'],
        811102: ['Sheikhpura','Bihar'],
        811103: ['Sheikhpura','Bihar'],
        811104: ['Nalanda','Bihar'],
        811105: ['Sheikhpura','Bihar'],
        811106: ['Begusarai','Bihar'],
        811107: ['Lakhisarai','Bihar'],
        811112: ['Lakhisarai','Bihar'],
        811201: ['Munger','Bihar'],
        811202: ['Munger','Bihar'],
        811211: ['Munger','Bihar'],
        811212: ['Munger','Bihar'],
        811213: ['Munger','Bihar'],
        811214: ['Munger','Bihar'],
        811301: ['Jamui','Bihar'],
        811302: ['Lakhisarai','Bihar'],
        811303: ['Jamui','Bihar'],
        811304: ['Sheikhpura','Bihar'],
        811305: ['Jamui','Bihar'],
        811307: ['Jamui','Bihar'],
        811308: ['Jamui','Bihar'],
        811309: ['Lakhisarai','Bihar'],
        811310: ['Lakhisarai','Bihar'],
        811311: ['Lakhisarai','Bihar'],
        811312: ['Jamui','Bihar'],
        811313: ['Jamui','Bihar'],
        811314: ['Jamui','Bihar'],
        811315: ['Jamui','Bihar'],
        811316: ['Jamui','Bihar'],
        811317: ['Jamui','Bihar'],
        812001: ['Bhagalpur','Bihar'],
        812002: ['Bhagalpur','Bihar'],
        812003: ['Bhagalpur','Bihar'],
        812004: ['Bhagalpur','Bihar'],
        812005: ['Bhagalpur','Bihar'],
        812006: ['Bhagalpur','Bihar'],
        812007: ['Bhagalpur','Bihar'],
        813101: ['Banka','Bihar'],
        813102: ['Banka','Bihar'],
        813103: ['Banka','Bihar'],
        813104: ['Bhagalpur','Bihar'],
        813105: ['Bhagalpur','Bihar'],
        813106: ['Banka','Bihar'],
        813107: ['Banka','Bihar'],
        813108: ['Bhagalpur','Bihar'],
        813109: ['Banka','Bihar'],
        813110: ['Banka','Bihar'],
        813201: ['Bhagalpur','Bihar'],
        813203: ['Bhagalpur','Bihar'],
        813204: ['Bhagalpur','Bihar'],
        813205: ['Bhagalpur','Bihar'],
        813206: ['Bhagalpur','Bihar'],
        813207: ['Banka','Bihar'],
        813208: ['Sahibganj','Jharkhand'],
        813209: ['Bhagalpur','Bihar'],
        813210: ['Bhagalpur','Bihar'],
        813211: ['Banka','Bihar'],
        813212: ['Bhagalpur','Bihar'],
        813213: ['Bhagalpur','Bihar'],
        813214: ['Bhagalpur','Bihar'],
        813221: ['Bhagalpur','Bihar'],
        813222: ['Bhagalpur','Bihar'],
        814101: ['Dumka','Jharkhand'],
        814102: ['Dumka','Jharkhand'],
        814103: ['Dumka','Jharkhand'],
        814110: ['Dumka','Jharkhand'],
        814111: ['Pakur','Jharkhand'],
        814112: ['Deoghar','Jharkhand'],
        814113: ['Deoghar','Jharkhand'],
        814114: ['Deoghar','Jharkhand'],
        814115: ['Deoghar','Jharkhand'],
        814116: ['Deoghar','Jharkhand'],
        814118: ['Dumka','Jharkhand'],
        814119: ['Dumka','Jharkhand'],
        814120: ['Deoghar','Jharkhand'],
        814131: ['Banka','Bihar'],
        814133: ['Godda','Jharkhand'],
        814141: ['Dumka','Jharkhand'],
        814142: ['Deoghar','Jharkhand'],
        814143: ['Deoghar','Jharkhand'],
        814144: ['Dumka','Jharkhand'],
        814145: ['Godda','Jharkhand'],
        814146: ['Deoghar','Jharkhand'],
        814147: ['Godda','Jharkhand'],
        814148: ['Dumka','Jharkhand'],
        814149: ['Deoghar','Jharkhand'],
        814150: ['Deoghar','Jharkhand'],
        814151: ['Dumka','Jharkhand'],
        814152: ['Deoghar','Jharkhand'],
        814153: ['Godda','Jharkhand'],
        814154: ['Godda','Jharkhand'],
        814155: ['Godda','Jharkhand'],
        814156: ['Godda','Jharkhand'],
        814157: ['Deoghar','Jharkhand'],
        814158: ['Dumka','Jharkhand'],
        814160: ['Godda','Jharkhand'],
        814165: ['Godda','Jharkhand'],
        814166: ['Jamtara','Jharkhand'],
        814167: ['Dumka','Jharkhand'],
        815301: ['Giridh','Jharkhand'],
        815302: ['Giridh','Jharkhand'],
        815311: ['Giridh','Jharkhand'],
        815312: ['Giridh','Jharkhand'],
        815313: ['Giridh','Jharkhand'],
        815314: ['Giridh','Jharkhand'],
        815315: ['Giridh','Jharkhand'],
        815316: ['Giridh','Jharkhand'],
        815317: ['Giridh','Jharkhand'],
        815318: ['Giridh','Jharkhand'],
        815351: ['Jamtara','Jharkhand'],
        815352: ['Jamtara','Jharkhand'],
        815353: ['Deoghar','Jharkhand'],
        815354: ['Jamtara','Jharkhand'],
        815355: ['Jamtara','Jharkhand'],
        815357: ['Deoghar','Jharkhand'],
        815359: ['Jamtara','Jharkhand'],
        816101: ['Sahibganj','Jharkhand'],
        816102: ['Sahibganj','Jharkhand'],
        816103: ['Dumka','Jharkhand'],
        816104: ['Pakur','Jharkhand'],
        816105: ['Sahibganj','Jharkhand'],
        816106: ['Pakur','Jharkhand'],
        816107: ['Pakur','Jharkhand'],
        816108: ['Sahibganj','Jharkhand'],
        816109: ['Sahibganj','Jharkhand'],
        816110: ['Sahibganj','Jharkhand'],
        816115: ['Sahibganj','Jharkhand'],
        816116: ['Sahibganj','Jharkhand'],
        816117: ['Pakur','Jharkhand'],
        816118: ['Dumka','Jharkhand'],
        816120: ['Sahibganj','Jharkhand'],
        816129: ['Dumka','Jharkhand'],
        818142: ['Dhanbad','Jharkhand'],
        821101: ['Rohtas','Bihar'],
        821102: ['Rohtas','Bihar'],
        821103: ['Rohtas','Bihar'],
        821104: ['Rohtas','Bihar'],
        821105: ['Rohtas','Bihar'],
        821106: ['Rohtas','Bihar'],
        821107: ['Rohtas','Bihar'],
        821108: ['Rohtas','Bihar'],
        821109: ['Kaimur Bhabua','Bihar'],
        821110: ['Kaimur Bhabua','Bihar'],
        821111: ['Rohtas','Bihar'],
        821112: ['Rohtas','Bihar'],
        821113: ['Rohtas','Bihar'],
        821115: ['Patna','Bihar'],
        821301: ['Rohtas','Bihar'],
        821302: ['Rohtas','Bihar'],
        821303: ['Rohtas','Bihar'],
        821304: ['Rohtas','Bihar'],
        821305: ['Rohtas','Bihar'],
        821306: ['Rohtas','Bihar'],
        821307: ['Rohtas','Bihar'],
        821308: ['Rohtas','Bihar'],
        821309: ['Rohtas','Bihar'],
        821310: ['Rohtas','Bihar'],
        821311: ['Rohtas','Bihar'],
        821312: ['Rohtas','Bihar'],
        822101: ['Palamau','Jharkhand'],
        822102: ['Palamau','Jharkhand'],
        822110: ['Palamau','Jharkhand'],
        822111: ['Latehar','Jharkhand'],
        822112: ['Garhwa','Jharkhand'],
        822113: ['Palamau','Jharkhand'],
        822114: ['Garhwa','Jharkhand'],
        822115: ['Palamau','Jharkhand'],
        822116: ['Pakur','Jharkhand'],
        822117: ['Palamau','Jharkhand'],
        822118: ['Palamau','Jharkhand'],
        822119: ['Latehar','Jharkhand'],
        822120: ['Palamau','Jharkhand'],
        822121: ['Garhwa','Jharkhand'],
        822122: ['Palamau','Jharkhand'],
        822123: ['Palamau','Jharkhand'],
        822124: ['Garhwa','Jharkhand'],
        822125: ['Garhwa','Jharkhand'],
        822126: ['Palamau','Jharkhand'],
        822128: ['Garhwa','Jharkhand'],
        822129: ['Garhwa','Jharkhand'],
        822131: ['Palamau','Jharkhand'],
        822132: ['Palamau','Jharkhand'],
        822133: ['Garhwa','Jharkhand'],
        822134: ['Garhwa','Jharkhand'],
        823001: ['Gaya','Bihar'],
        823002: ['Gaya','Bihar'],
        823003: ['Gaya','Bihar'],
        823004: ['Gaya','Bihar'],
        823005: ['Gaya','Bihar'],
        823311: ['Gaya','Bihar'],
        824101: ['Aurangabad','Bihar'],
        824102: ['Aurangabad','Bihar'],
        824103: ['Aurangabad','Bihar'],
        824111: ['Aurangabad','Bihar'],
        824112: ['Aurangabad','Bihar'],
        824113: ['Aurangabad','Bihar'],
        824114: ['Aurangabad','Bihar'],
        824115: ['Aurangabad','Bihar'],
        824116: ['Gaya','Bihar'],
        824118: ['Gaya','Bihar'],
        824120: ['Aurangabad','Bihar'],
        824121: ['Aurangabad','Bihar'],
        824122: ['Aurangabad','Bihar'],
        824123: ['Aurangabad','Bihar'],
        824124: ['Aurangabad','Bihar'],
        824125: ['Aurangabad','Bihar'],
        824127: ['Aurangabad','Bihar'],
        824129: ['Aurangabad','Bihar'],
        824143: ['Aurangabad','Bihar'],
        824201: ['Gaya','Bihar'],
        824202: ['Aurangabad','Bihar'],
        824203: ['Aurangabad','Bihar'],
        824205: ['Gaya','Bihar'],
        824206: ['Gaya','Bihar'],
        824207: ['Gaya','Bihar'],
        824208: ['Aurangabad','Bihar'],
        824209: ['Gaya','Bihar'],
        824210: ['Gaya','Bihar'],
        824211: ['Aurangabad','Bihar'],
        824217: ['Gaya','Bihar'],
        824219: ['Gaya','Bihar'],
        824220: ['Aurangabad','Bihar'],
        824221: ['Aurangabad','Bihar'],
        824231: ['Gaya','Bihar'],
        824232: ['Gaya','Bihar'],
        824233: ['Gaya','Bihar'],
        824234: ['Gaya','Bihar'],
        824235: ['Gaya','Bihar'],
        824236: ['Gaya','Bihar'],
        824237: ['Gaya','Bihar'],
        824301: ['Aurangabad','Bihar'],
        824302: ['Aurangabad','Bihar'],
        824303: ['Aurangabad','Bihar'],
        825101: ['Ramgarh','Jharkhand'],
        825102: ['Bokaro','Jharkhand'],
        825103: ['Chatra','Jharkhand'],
        825106: ['Giridh','Jharkhand'],
        825108: ['Giridh','Jharkhand'],
        825109: ['Koderma','Jharkhand'],
        825132: ['Koderma','Jharkhand'],
        825167: ['Giridh','Jharkhand'],
        825301: ['Hazaribag','Jharkhand'],
        825302: ['Hazaribag','Jharkhand'],
        825303: ['Hazaribag','Jharkhand'],
        825311: ['Hazaribag','Jharkhand'],
        825312: ['Hazaribag','Jharkhand'],
        825313: ['Hazaribag','Jharkhand'],
        825314: ['Hazaribag','Jharkhand'],
        825315: ['Bokaro','Jharkhand'],
        825316: ['Ramgarh','Jharkhand'],
        825317: ['Hazaribag','Jharkhand'],
        825318: ['Koderma','Jharkhand'],
        825319: ['Hazaribag','Jharkhand'],
        825320: ['Giridh','Jharkhand'],
        825321: ['Chatra','Jharkhand'],
        825322: ['Giridh','Jharkhand'],
        825323: ['Hazaribag','Jharkhand'],
        825324: ['Giridh','Jharkhand'],
        825325: ['Hazaribag','Jharkhand'],
        825326: ['Hazaribag','Jharkhand'],
        825329: ['Giridh','Jharkhand'],
        825330: ['Ramgarh','Jharkhand'],
        825336: ['Hazaribag','Jharkhand'],
        825401: ['Chatra','Jharkhand'],
        825402: ['Hazaribag','Jharkhand'],
        825403: ['Koderma','Jharkhand'],
        825404: ['Chatra','Jharkhand'],
        825405: ['Hazaribag','Jharkhand'],
        825406: ['Hazaribag','Jharkhand'],
        825407: ['Koderma','Jharkhand'],
        825408: ['Chatra','Jharkhand'],
        825409: ['Koderma','Jharkhand'],
        825410: ['Koderma','Jharkhand'],
        825411: ['Hazaribagh','Jharkhand'],
        825412: ['Giridh','Jharkhand'],
        825413: ['Koderma','Jharkhand'],
        825414: ['Chatra','Jharkhand'],
        825418: ['Koderma','Jharkhand'],
        825421: ['Koderma','Jharkhand'],
        826006: ['Dhanbad','Jharkhand'],
        826007: ['Dhanbad','Jharkhand'],
        826008: ['Dhanbad','Jharkhand'],
        826009: ['Dhanbad','Jharkhand'],
        826010: ['Dhanbad','Jharkhand'],
        827001: ['Bokaro','Jharkhand'],
        827003: ['Bokaro','Jharkhand'],
        827004: ['Bokaro','Jharkhand'],
        827006: ['Bokaro','Jharkhand'],
        827009: ['Bokaro','Jharkhand'],
        827010: ['Bokaro','Jharkhand'],
        827012: ['Bokaro','Jharkhand'],
        827013: ['Bokaro','Jharkhand'],
        827014: ['Bokaro','Jharkhand'],
        827015: ['Bokaro','Jharkhand'],
        828134: ['Bokaro','Jharkhand'],
        828302: ['Bokaro','Jharkhand'],
        828303: ['Bokaro','Jharkhand'],
        828403: ['Bokaro','Jharkhand'],
        828404: ['Bokaro','Jharkhand'],
        829101: ['Ramgarh','Jharkhand'],
        829102: ['Ramgarh','Jharkhand'],
        829103: ['Ramgarh','Jharkhand'],
        829104: ['Bokaro','Jharkhand'],
        829105: ['Ramgarh','Jharkhand'],
        829106: ['Ramgarh','Jharkhand'],
        829107: ['Bokaro','Jharkhand'],
        829108: ['Ramgarh','Jharkhand'],
        829109: ['Ramgarh','Jharkhand'],
        829110: ['Ramgarh','Jharkhand'],
        829111: ['Bokaro','Jharkhand'],
        829112: ['Bokaro','Jharkhand'],
        829113: ['Bokaro','Jharkhand'],
        829114: ['Bokaro','Jharkhand'],
        829115: ['Bokaro','Jharkhand'],
        829116: ['Bokaro','Jharkhand'],
        829117: ['Ramgarh','Jharkhand'],
        829118: ['Ramgarh','Jharkhand'],
        829119: ['Ramgarh','Jharkhand'],
        829121: ['Bokaro','Jharkhand'],
        829122: ['Ramgarh','Jharkhand'],
        829123: ['Bokaro','Jharkhand'],
        829125: ['Ramgarh','Jharkhand'],
        829126: ['Ramgarh','Jharkhand'],
        829127: ['Bokaro','Jharkhand'],
        829128: ['Bokaro','Jharkhand'],
        829129: ['Bokaro','Jharkhand'],
        829130: ['Ramgarh','Jharkhand'],
        829131: ['Ramgarh','Jharkhand'],
        829132: ['Bokaro','Jharkhand'],
        829133: ['Ramgarh','Jharkhand'],
        829134: ['Ramgarh','Jharkhand'],
        829135: ['Ramgarh','Jharkhand'],
        829143: ['Ramgarh','Jharkhand'],
        829144: ['Bokaro','Jharkhand'],
        829149: ['Bokaro','Jharkhand'],
        829150: ['Ramgarh','Jharkhand'],
        829201: ['Chatra','Jharkhand'],
        829202: ['Latehar','Jharkhand'],
        829203: ['Latehar','Jharkhand'],
        829204: ['Latehar','Jharkhand'],
        829205: ['Ranchi','Jharkhand'],
        829206: ['Latehar','Jharkhand'],
        829207: ['Latehar','Jharkhand'],
        829208: ['Latehar','Jharkhand'],
        829209: ['Ranchi','Jharkhand'],
        829210: ['Ranchi','Jharkhand'],
        829301: ['Bokaro','Jharkhand'],
        831002: ['East Singhbhum','Jharkhand'],
        831003: ['East Singhbhum','Jharkhand'],
        831004: ['East Singhbhum','Jharkhand'],
        831005: ['East Singhbhum','Jharkhand'],
        831006: ['East Singhbhum','Jharkhand'],
        831007: ['East Singhbhum','Jharkhand'],
        831009: ['East Singhbhum','Jharkhand'],
        831011: ['East Singhbhum','Jharkhand'],
        831012: ['East Singhbhum','Jharkhand'],
        831013: ['Seraikelakharsawan','Jharkhand'],
        831014: ['Seraikelakharsawan','Jharkhand'],
        831015: ['East Singhbhum','Jharkhand'],
        831016: ['East Singhbhum','Jharkhand'],
        831017: ['East Singhbhum','Jharkhand'],
        831019: ['East Singhbhum','Jharkhand'],
        831020: ['East Singhbhum','Jharkhand'],
        831021: ['East Singhbhum','Jharkhand'],
        832101: ['East Singhbhum','Jharkhand'],
        832102: ['East Singhbhum','Jharkhand'],
        832103: ['East Singhbhum','Jharkhand'],
        832104: ['East Singhbhum','Jharkhand'],
        832105: ['East Singhbhum','Jharkhand'],
        832106: ['East Singhbhum','Jharkhand'],
        832107: ['East Singhbhum','Jharkhand'],
        832108: ['Seraikelakharsawan','Jharkhand'],
        832109: ['Seraikelakharsawan','Jharkhand'],
        832111: ['East Singhbhum','Jharkhand'],
        832112: ['East Singhbhum','Jharkhand'],
        832113: ['East Singhbhum','Jharkhand'],
        832301: ['East Singhbhum','Jharkhand'],
        832302: ['East Singhbhum','Jharkhand'],
        832303: ['East Singhbhum','Jharkhand'],
        832304: ['East Singhbhum','Jharkhand'],
        832401: ['Seraikelakharsawan','Jharkhand'],
        832402: ['Seraikelakharsawan','Jharkhand'],
        832403: ['East Singhbhum','Jharkhand'],
        832404: ['Seraikelakharsawan','Jharkhand'],
        833101: ['Seraikelakharsawan','Jharkhand'],
        833102: ['West Singhbhum','Jharkhand'],
        833103: ['West Singhbhum','Jharkhand'],
        833104: ['West Singhbhum','Jharkhand'],
        833105: ['West Singhbhum','Jharkhand'],
        833106: ['West Singhbhum','Jharkhand'],
        833201: ['West Singhbhum','Jharkhand'],
        833202: ['West Singhbhum','Jharkhand'],
        833203: ['West Singhbhum','Jharkhand'],
        833204: ['West Singhbhum','Jharkhand'],
        833212: ['West Singhbhum','Jharkhand'],
        833213: ['West Singhbhum','Jharkhand'],
        833214: ['West Singhbhum','Jharkhand'],
        833215: ['West Singhbhum','Jharkhand'],
        833216: ['Seraikelakharsawan','Jharkhand'],
        833217: ['West Singhbhum','Jharkhand'],
        833218: ['West Singhbhum','Jharkhand'],
        833219: ['Seraikelakharsawan','Jharkhand'],
        833220: ['Seraikelakharsawan','Jharkhand'],
        833221: ['West Singhbhum','Jharkhand'],
        833222: ['West Singhbhum','Jharkhand'],
        833223: ['West Singhbhum','Jharkhand'],
        834001: ['Ranchi','Jharkhand'],
        834002: ['Ranchi','Jharkhand'],
        834003: ['Ranchi','Jharkhand'],
        834004: ['Ranchi','Jharkhand'],
        834005: ['Ranchi','Jharkhand'],
        834006: ['Ranchi','Jharkhand'],
        834008: ['Ranchi','Jharkhand'],
        834009: ['Ranchi','Jharkhand'],
        834010: ['Ranchi','Jharkhand'],
        834011: ['Ranchi','Jharkhand'],
        834012: ['Ranchi','Jharkhand'],
        834013: ['Ranchi','Jharkhand'],
        835101: ['Ranchi','Jharkhand'],
        835102: ['Ranchi','Jharkhand'],
        835103: ['Ranchi','Jharkhand'],
        835201: ['Simdega','Jharkhand'],
        835202: ['Ranchi','Jharkhand'],
        835203: ['Gumla','Jharkhand'],
        835204: ['Ranchi','Jharkhand'],
        835205: ['Ranchi','Jharkhand'],
        835206: ['Gumla','Jharkhand'],
        835207: ['Gumla','Jharkhand'],
        835208: ['Gumla','Jharkhand'],
        835209: ['Khunti','Jharkhand'],
        835210: ['Khunti','Jharkhand'],
        835211: ['Simdega','Jharkhand'],
        835212: ['Simdega','Jharkhand'],
        835213: ['Lohardaga','Jharkhand'],
        835214: ['Ranchi','Jharkhand'],
        835215: ['Ranchi','Jharkhand'],
        835216: ['Khunti','Jharkhand'],
        835217: ['Ranchi','Jharkhand'],
        835218: ['Latehar','Jharkhand'],
        835219: ['Ranchi','Jharkhand'],
        835220: ['Gumla','Jharkhand'],
        835221: ['Ranchi','Jharkhand'],
        835222: ['Ranchi','Jharkhand'],
        835223: ['Simdega','Jharkhand'],
        835224: ['Gumla','Jharkhand'],
        835225: ['Ranchi','Jharkhand'],
        835226: ['Simdega','Jharkhand'],
        835227: ['Ranchi','Jharkhand'],
        835228: ['Simdega','Jharkhand'],
        835229: ['Gumla','Jharkhand'],
        835230: ['Gumla','Jharkhand'],
        835231: ['Gumla','Jharkhand'],
        835232: ['Gumla','Jharkhand'],
        835233: ['Gumla','Jharkhand'],
        835234: ['Khunti','Jharkhand'],
        835235: ['Simdega','Jharkhand'],
        835301: ['Ranchi','Jharkhand'],
        835302: ['Lohardaga','Jharkhand'],
        835303: ['Ranchi','Jharkhand'],
        835325: ['Ranchi','Jharkhand'],
        841101: ['Saran','Bihar'],
        841201: ['Saran','Bihar'],
        841202: ['Saran','Bihar'],
        841203: ['Siwan','Bihar'],
        841204: ['Saran','Bihar'],
        841205: ['Saran','Bihar'],
        841206: ['Saran','Bihar'],
        841207: ['Saran','Bihar'],
        841208: ['Saran','Bihar'],
        841209: ['Saran','Bihar'],
        841210: ['Siwan','Bihar'],
        841211: ['Saran','Bihar'],
        841212: ['Saran','Bihar'],
        841213: ['Saran','Bihar'],
        841214: ['Saran','Bihar'],
        841215: ['Saran','Bihar'],
        841216: ['Saran','Bihar'],
        841217: ['Saran','Bihar'],
        841218: ['Saran','Bihar'],
        841219: ['Saran','Bihar'],
        841220: ['Saran','Bihar'],
        841221: ['Saran','Bihar'],
        841222: ['Saran','Bihar'],
        841223: ['Saran','Bihar'],
        841224: ['Saran','Bihar'],
        841225: ['Samastipur','Bihar'],
        841226: ['Siwan','Bihar'],
        841227: ['Siwan','Bihar'],
        841231: ['Siwan','Bihar'],
        841232: ['Siwan','Bihar'],
        841233: ['Siwan','Bihar'],
        841234: ['Siwan','Bihar'],
        841235: ['Siwan','Bihar'],
        841236: ['Siwan','Bihar'],
        841237: ['Siwan','Bihar'],
        841238: ['Siwan','Bihar'],
        841239: ['Siwan','Bihar'],
        841240: ['Siwan','Bihar'],
        841241: ['Siwan','Bihar'],
        841242: ['Siwan','Bihar'],
        841243: ['Siwan','Bihar'],
        841244: ['Siwan','Bihar'],
        841245: ['Khagaria','Bihar'],
        841286: ['Siwan','Bihar'],
        841287: ['Siwan','Bihar'],
        841301: ['Saran','Bihar'],
        841302: ['Saran','Bihar'],
        841305: ['Saran','Bihar'],
        841311: ['Saran','Bihar'],
        841312: ['Saran','Bihar'],
        841313: ['Saran','Bihar'],
        841316: ['Saran','Bihar'],
        841401: ['Saran','Bihar'],
        841402: ['Saran','Bihar'],
        841403: ['Saran','Bihar'],
        841404: ['Siwan','Bihar'],
        841405: ['Gopalganj','Bihar'],
        841406: ['Siwan','Bihar'],
        841407: ['Gopalganj','Bihar'],
        841408: ['Siwan','Bihar'],
        841409: ['Gopalganj','Bihar'],
        841410: ['Saran','Bihar'],
        841411: ['Saran','Bihar'],
        841412: ['Saran','Bihar'],
        841413: ['Gopalganj','Bihar'],
        841414: ['Saran','Bihar'],
        841415: ['Saran','Bihar'],
        841416: ['Siwan','Bihar'],
        841417: ['Saran','Bihar'],
        841418: ['Saran','Bihar'],
        841419: ['Saran','Bihar'],
        841420: ['Gopalganj','Bihar'],
        841421: ['Saran','Bihar'],
        841422: ['Saran','Bihar'],
        841423: ['Siwan','Bihar'],
        841424: ['Saran','Bihar'],
        841425: ['Gopalganj','Bihar'],
        841426: ['Gopalganj','Bihar'],
        841427: ['Gopalganj','Bihar'],
        841428: ['Gopalganj','Bihar'],
        841434: ['Siwan','Bihar'],
        841435: ['Siwan','Bihar'],
        841436: ['Gopalganj','Bihar'],
        841437: ['Gopalganj','Bihar'],
        841438: ['Gopalganj','Bihar'],
        841439: ['Siwan','Bihar'],
        841440: ['Gopalganj','Bihar'],
        841441: ['Gopalganj','Bihar'],
        841442: ['Saran','Bihar'],
        841443: ['Saran','Bihar'],
        841446: ['Siwan','Bihar'],
        841460: ['Saran','Bihar'],
        841501: ['Gopalganj','Bihar'],
        841502: ['Siwan','Bihar'],
        841503: ['Gopalganj','Bihar'],
        841504: ['Siwan','Bihar'],
        841505: ['Gopalganj','Bihar'],
        841506: ['Siwan','Bihar'],
        841507: ['Siwan','Bihar'],
        841508: ['Gopalganj','Bihar'],
        841509: ['Siwan','Bihar'],
        842001: ['Muzaffarpur','Bihar'],
        842002: ['Muzaffarpur','Bihar'],
        842003: ['Muzaffarpur','Bihar'],
        842004: ['Muzaffarpur','Bihar'],
        842005: ['Muzaffarpur','Bihar'],
        842006: ['Muzaffarpur','Bihar'],
        843101: ['Muzaffarpur','Bihar'],
        843102: ['Muzaffarpur','Bihar'],
        843103: ['Muzaffarpur','Bihar'],
        843104: ['Muzaffarpur','Bihar'],
        843105: ['Muzaffarpur','Bihar'],
        843106: ['Muzaffarpur','Bihar'],
        843107: ['Muzaffarpur','Bihar'],
        843108: ['Muzaffarpur','Bihar'],
        843109: ['Muzaffarpur','Bihar'],
        843110: ['Vaishali','Bihar'],
        843111: ['Muzaffarpur','Bihar'],
        843112: ['Muzaffarpur','Bihar'],
        843113: ['Muzaffarpur','Bihar'],
        843114: ['Vaishali','Bihar'],
        843115: ['Muzaffarpur','Bihar'],
        843116: ['Muzaffarpur','Bihar'],
        843117: ['Muzaffarpur','Bihar'],
        843118: ['Muzaffarpur','Bihar'],
        843119: ['Muzaffarpur','Bihar'],
        843120: ['Muzaffarpur','Bihar'],
        843121: ['Muzaffarpur','Bihar'],
        843122: ['Muzaffarpur','Bihar'],
        843123: ['Muzaffarpur','Bihar'],
        843124: ['Muzaffarpur','Bihar'],
        843125: ['Muzaffarpur','Bihar'],
        843126: ['Muzaffarpur','Bihar'],
        843127: ['Muzaffarpur','Bihar'],
        843128: ['Muzaffarpur','Bihar'],
        843129: ['Muzaffarpur','Bihar'],
        843130: ['Muzaffarpur','Bihar'],
        843131: ['Muzaffarpur','Bihar'],
        843301: ['Sitamarhi','Bihar'],
        843302: ['Sitamarhi','Bihar'],
        843311: ['Sitamarhi','Bihar'],
        843312: ['Muzaffarpur','Bihar'],
        843313: ['Sitamarhi','Bihar'],
        843314: ['Sitamarhi','Bihar'],
        843315: ['Sitamarhi','Bihar'],
        843316: ['Sitamarhi','Bihar'],
        843317: ['Sitamarhi','Bihar'],
        843318: ['Sitamarhi','Bihar'],
        843319: ['Sitamarhi','Bihar'],
        843320: ['Sitamarhi','Bihar'],
        843321: ['Muzaffarpur','Bihar'],
        843322: ['Sitamarhi','Bihar'],
        843323: ['Sitamarhi','Bihar'],
        843324: ['Sitamarhi','Bihar'],
        843325: ['Sitamarhi','Bihar'],
        843326: ['Sitamarhi','Bihar'],
        843327: ['Sitamarhi','Bihar'],
        843328: ['Sitamarhi','Bihar'],
        843329: ['Sheohar','Bihar'],
        843330: ['Sitamarhi','Bihar'],
        843331: ['Sitamarhi','Bihar'],
        843332: ['Sitamarhi','Bihar'],
        843333: ['Sitamarhi','Bihar'],
        843334: ['Sitamarhi','Bihar'],
        843335: ['Sheohar','Bihar'],
        843360: ['Muzaffarpur','Bihar'],
        844101: ['Vaishali','Bihar'],
        844102: ['Vaishali','Bihar'],
        844103: ['Vaishali','Bihar'],
        844111: ['Vaishali','Bihar'],
        844112: ['Muzaffarpur','Bihar'],
        844113: ['Vaishali','Bihar'],
        844114: ['Vaishali','Bihar'],
        844115: ['Vaishali','Bihar'],
        844116: ['Vaishali','Bihar'],
        844117: ['Vaishali','Bihar'],
        844118: ['Vaishali','Bihar'],
        844119: ['Vaishali','Bihar'],
        844120: ['Muzaffarpur','Bihar'],
        844121: ['Vaishali','Bihar'],
        844122: ['Vaishali','Bihar'],
        844123: ['Vaishali','Bihar'],
        844124: ['Vaishali','Bihar'],
        844125: ['Vaishali','Bihar'],
        844126: ['Vaishali','Bihar'],
        844127: ['Muzaffarpur','Bihar'],
        844128: ['Vaishali','Bihar'],
        844501: ['Vaishali','Bihar'],
        844502: ['Vaishali','Bihar'],
        844503: ['Vaishali','Bihar'],
        844504: ['Vaishali','Bihar'],
        844505: ['Vaishali','Bihar'],
        844506: ['Vaishali','Bihar'],
        844507: ['Vaishali','Bihar'],
        844508: ['Vaishali','Bihar'],
        844509: ['Vaishali','Bihar'],
        845101: ['West Champaran','Bihar'],
        845103: ['West Champaran','Bihar'],
        845104: ['West Champaran','Bihar'],
        845105: ['West Champaran','Bihar'],
        845106: ['West Champaran','Bihar'],
        845107: ['West Champaran','Bihar'],
        845301: ['East Champaran','Bihar'],
        845302: ['East Champaran','Bihar'],
        845303: ['East Champaran','Bihar'],
        845304: ['East Champaran','Bihar'],
        845305: ['East Champaran','Bihar'],
        845306: ['West Champaran','Bihar'],
        845307: ['West Champaran','Bihar'],
        845315: ['East Champaran','Bihar'],
        845401: ['East Champaran','Bihar'],
        845404: ['West Champaran','Bihar'],
        845406: ['East Champaran','Bihar'],
        845411: ['East Champaran','Bihar'],
        845412: ['East Champaran','Bihar'],
        845413: ['East Champaran','Bihar'],
        845414: ['East Champaran','Bihar'],
        845415: ['East Champaran','Bihar'],
        845416: ['East Champaran','Bihar'],
        845417: ['East Champaran','Bihar'],
        845418: ['East Champaran','Bihar'],
        845419: ['East Champaran','Bihar'],
        845420: ['East Champaran','Bihar'],
        845422: ['East Champaran','Bihar'],
        845423: ['East Champaran','Bihar'],
        845424: ['East Champaran','Bihar'],
        845425: ['East Champaran','Bihar'],
        845426: ['Darbhanga','Bihar'],
        845427: ['East Champaran','Bihar'],
        845428: ['East Champaran','Bihar'],
        845429: ['East Champaran','Bihar'],
        845430: ['East Champaran','Bihar'],
        845431: ['East Champaran','Bihar'],
        845432: ['East Champaran','Bihar'],
        845433: ['East Champaran','Bihar'],
        845434: ['East Champaran','Bihar'],
        845435: ['East Champaran','Bihar'],
        845436: ['East Champaran','Bihar'],
        845437: ['East Champaran','Bihar'],
        845438: ['West Champaran','Bihar'],
        845440: ['East Champaran','Bihar'],
        845449: ['West Champaran','Bihar'],
        845450: ['West Champaran','Bihar'],
        845451: ['West Champaran','Bihar'],
        845452: ['West Champaran','Bihar'],
        845453: ['West Champaran','Bihar'],
        845454: ['West Champaran','Bihar'],
        845455: ['West Champaran','Bihar'],
        845456: ['East Champaran','Bihar'],
        845457: ['East Champaran','Bihar'],
        845458: ['East Champaran','Bihar'],
        845459: ['West Champaran','Bihar'],
        846001: ['Darbhanga','Bihar'],
        846002: ['Darbhanga','Bihar'],
        846003: ['Darbhanga','Bihar'],
        846004: ['Darbhanga','Bihar'],
        846005: ['Darbhanga','Bihar'],
        846006: ['Darbhanga','Bihar'],
        846007: ['Darbhanga','Bihar'],
        846008: ['Darbhanga','Bihar'],
        846009: ['Darbhanga','Bihar'],
        847101: ['Darbhanga','Bihar'],
        847102: ['Madhubani','Bihar'],
        847103: ['Darbhanga','Bihar'],
        847104: ['Darbhanga','Bihar'],
        847105: ['Darbhanga','Bihar'],
        847106: ['Darbhanga','Bihar'],
        847107: ['Muzaffarpur','Bihar'],
        847108: ['Madhubani','Bihar'],
        847109: ['Madhubani','Bihar'],
        847115: ['Darbhanga','Bihar'],
        847121: ['Darbhanga','Bihar'],
        847122: ['Darbhanga','Bihar'],
        847123: ['Darbhanga','Bihar'],
        847201: ['Darbhanga','Bihar'],
        847202: ['Darbhanga','Goa'],
        847203: ['Darbhanga','Bihar'],
        847204: ['Darbhanga','Bihar'],
        847211: ['Madhubani','Bihar'],
        847212: ['Madhubani','Bihar'],
        847213: ['Madhubani','Bihar'],
        847214: ['Madhubani','Bihar'],
        847215: ['Madhubani','Bihar'],
        847222: ['Madhubani','Bihar'],
        847223: ['Madhubani','Bihar'],
        847224: ['Madhubani','Bihar'],
        847225: ['Madhubani','Bihar'],
        847226: ['Madhubani','Bihar'],
        847227: ['Madhubani','Bihar'],
        847228: ['Madhubani','Bihar'],
        847229: ['Madhubani','Bihar'],
        847230: ['Madhubani','Bihar'],
        847231: ['Madhubani','Bihar'],
        847232: ['Madhubani','Bihar'],
        847233: ['Darbhanga','Bihar'],
        847234: ['Madhubani','Bihar'],
        847235: ['Madhubani','Bihar'],
        847236: ['Madhubani','Bihar'],
        847237: ['Darbhanga','Bihar'],
        847238: ['Madhubani','Bihar'],
        847239: ['Madhubani','Bihar'],
        847240: ['Madhubani','Bihar'],
        847301: ['Samastipur','Bihar'],
        847302: ['Darbhanga','Bihar'],
        847303: ['Darbhanga','Bihar'],
        847304: ['Darbhanga','Bihar'],
        847305: ['Madhubani','Bihar'],
        847306: ['Darbhanga','Bihar'],
        847307: ['Darbhanga','Bihar'],
        847308: ['Madhubani','Bihar'],
        847337: ['Darbhanga','Bihar'],
        847401: ['Madhubani','Bihar'],
        847402: ['Madhubani','Bihar'],
        847403: ['Madhubani','Bihar'],
        847404: ['Madhubani','Bihar'],
        847405: ['Darbhanga','Bihar'],
        847407: ['Darbhanga','Bihar'],
        847408: ['Madhubani','Bihar'],
        847409: ['Madhubani','Bihar'],
        847410: ['Madhubani','Bihar'],
        847411: ['Madhubani','Bihar'],
        847421: ['Madhubani','Bihar'],
        847422: ['Darbhanga','Bihar'],
        847423: ['Darbhanga','Bihar'],
        847424: ['Madhubani','Bihar'],
        847427: ['Darbhanga','Bihar'],
        847428: ['Darbhanga','Bihar'],
        847429: ['Darbhanga','Bihar'],
        847451: ['Supaul','Bihar'],
        847452: ['Supaul','Bihar'],
        848101: ['Samastipur','Bihar'],
        848102: ['Samastipur','Bihar'],
        848113: ['Samastipur','Bihar'],
        848114: ['Samastipur','Bihar'],
        848115: ['Samastipur','Bihar'],
        848117: ['Samastipur','Bihar'],
        848121: ['Samastipur','Bihar'],
        848122: ['Samastipur','Bihar'],
        848125: ['Samastipur','Bihar'],
        848127: ['Samastipur','Bihar'],
        848129: ['Samastipur','Bihar'],
        848130: ['Samastipur','Bihar'],
        848131: ['Samastipur','Bihar'],
        848132: ['Samastipur','Bihar'],
        848133: ['Samastipur','Bihar'],
        848134: ['Samastipur','Bihar'],
        848160: ['Samastipur','Bihar'],
        848201: ['Begusarai','Bihar'],
        848202: ['Begusarai','Bihar'],
        848203: ['Khagaria','Bihar'],
        848204: ['Begusarai','Bihar'],
        848205: ['Samastipur','Bihar'],
        848206: ['Samastipur','Bihar'],
        848207: ['Samastipur','Bihar'],
        848208: ['Samastipur','Bihar'],
        848209: ['Samastipur','Bihar'],
        848210: ['Samastipur','Bihar'],
        848211: ['Samastipur','Bihar'],
        848213: ['Samastipur','Bihar'],
        848216: ['Samastipur','Bihar'],
        848236: ['Samastipur','Bihar'],
        848301: ['Samastipur','Bihar'],
        848302: ['Samastipur','Bihar'],
        848501: ['Samastipur','Bihar'],
        848502: ['Samastipur','Bihar'],
        848503: ['Samastipur','Bihar'],
        848504: ['Samastipur','Bihar'],
        848505: ['Samastipur','Bihar'],
        848506: ['Samastipur','Bihar'],
        851101: ['Begusarai','Bihar'],
        851111: ['Begusarai','Bihar'],
        851112: ['Begusarai','Bihar'],
        851113: ['Begusarai','Bihar'],
        851114: ['Begusarai','Bihar'],
        851115: ['Begusarai','Bihar'],
        851116: ['Begusarai','Bihar'],
        851117: ['Begusarai','Bihar'],
        851118: ['Begusarai','Bihar'],
        851120: ['Begusarai','Bihar'],
        851126: ['Begusarai','Bihar'],
        851127: ['Begusarai','Bihar'],
        851128: ['Begusarai','Bihar'],
        851129: ['Begusarai','Bihar'],
        851130: ['Begusarai','Bihar'],
        851131: ['Begusarai','Bihar'],
        851132: ['Begusarai','Bihar'],
        851133: ['Begusarai','Bihar'],
        851134: ['Begusarai','Bihar'],
        851135: ['Begusarai','Bihar'],
        851201: ['Khagaria','Bihar'],
        851202: ['Khagaria','Bihar'],
        851203: ['Khagaria','Bihar'],
        851204: ['Khagaria','Bihar'],
        851205: ['Khagaria','Bihar'],
        851206: ['Khagaria','Bihar'],
        851210: ['Begusarai','Bihar'],
        851211: ['Begusarai','Bihar'],
        851212: ['Khagaria','Bihar'],
        851213: ['Khagaria','Bihar'],
        851214: ['Khagaria','Bihar'],
        851215: ['Khagaria','Bihar'],
        851216: ['Khagaria','Bihar'],
        851217: ['Begusarai','Bihar'],
        851218: ['Begusarai','Bihar'],
        852101: ['Madhepura','Bihar'],
        852105: ['Supaul','Bihar'],
        852106: ['Saharsa','Bihar'],
        852107: ['Saharsa','Bihar'],
        852108: ['Supaul','Bihar'],
        852109: ['Supaul','Bihar'],
        852110: ['Supaul','Bihar'],
        852111: ['Supaul','Bihar'],
        852112: ['Madhepura','Bihar'],
        852113: ['Madhepura','Bihar'],
        852114: ['Madhepura','Bihar'],
        852115: ['Madhepura','Bihar'],
        852116: ['Madhepura','Bihar'],
        852121: ['Madhepura','Bihar'],
        852122: ['Madhepura','Bihar'],
        852123: ['Saharsa','Bihar'],
        852124: ['Saharsa','Bihar'],
        852125: ['Supaul','Bihar'],
        852126: ['Saharsa','Bihar'],
        852127: ['Saharsa','Bihar'],
        852128: ['Madhepura','Bihar'],
        852129: ['Saharsa','Bihar'],
        852130: ['Supaul','Bihar'],
        852131: ['Supaul','Bihar'],
        852132: ['Madhepura','Bihar'],
        852133: ['Supaul','Bihar'],
        852137: ['Supaul','Bihar'],
        852138: ['Supaul','Bihar'],
        852139: ['Supaul','Bihar'],
        852161: ['Khagaria','Bihar'],
        852201: ['Saharsa','Bihar'],
        852202: ['Saharsa','Bihar'],
        852212: ['Saharsa','Bihar'],
        852213: ['Madhepura','Bihar'],
        852214: ['Supaul','Bihar'],
        852215: ['Supaul','Bihar'],
        852216: ['Saharsa','Bihar'],
        852217: ['Saharsa','Bihar'],
        852218: ['Supaul','Bihar'],
        852219: ['Madhepura','Bihar'],
        852220: ['Madhepura','Bihar'],
        852221: ['Saharsa','Bihar'],
        853201: ['Bhagalpur','Bihar'],
        853202: ['Bhagalpur','Bihar'],
        853203: ['Bhagalpur','Bihar'],
        853204: ['Bhagalpur','Bihar'],
        853205: ['Bhagalpur','Bihar'],
        854101: ['Katihar','Bihar'],
        854102: ['Purnia','Bihar'],
        854103: ['Katihar','Bihar'],
        854104: ['Katihar','Bihar'],
        854106: ['Katihar','Bihar'],
        854107: ['Katihar','Bihar'],
        854108: ['Katihar','Bihar'],
        854109: ['Katihar','Bihar'],
        854112: ['Katihar','Bihar'],
        854113: ['Katihar','Bihar'],
        854114: ['Katihar','Bihar'],
        854115: ['Katihar','Bihar'],
        854116: ['Katihar','Bihar'],
        854117: ['Katihar','Bihar'],
        854201: ['Purnia','Bihar'],
        854202: ['Purnia','Bihar'],
        854203: ['Purnia','Bihar'],
        854204: ['Purnia','Bihar'],
        854205: ['Purnia','Bihar'],
        854301: ['Purnia','Bihar'],
        854302: ['Purnia','Bihar'],
        854303: ['Purnia','Bihar'],
        854304: ['Purnia','Bihar'],
        854305: ['Purnia','Bihar'],
        854306: ['Purnia','Bihar'],
        854311: ['Araria','Bihar'],
        854312: ['Araria','Bihar'],
        854315: ['Purnia','Bihar'],
        854316: ['Araria','Bihar'],
        854317: ['Katihar','Bihar'],
        854318: ['Araria','Bihar'],
        854325: ['Purnia','Bihar'],
        854326: ['Purnia','Bihar'],
        854327: ['Purnia','Bihar'],
        854328: ['Araria','Bihar'],
        854329: ['Araria','Bihar'],
        854330: ['Purnia','Bihar'],
        854331: ['Araria','Bihar'],
        854332: ['Araria','Bihar'],
        854333: ['Araria','Bihar'],
        854334: ['Araria','Bihar'],
        854335: ['Araria','Bihar'],
        854336: ['Araria','Bihar'],
        854337: ['Purnia','Bihar'],
        854338: ['Supaul','Bihar'],
        854339: ['Supaul','Bihar'],
        854340: ['Supaul','Bihar'],
        855101: ['Kishanganj','Bihar'],
        855102: ['Katihar','Bihar'],
        855105: ['Katihar','Bihar'],
        855106: ['Kishanganj','Bihar'],
        855107: ['Kishanganj','Bihar'],
        855108: ['Kishanganj','Bihar'],
        855113: ['Katihar','Bihar'],
        855114: ['Katihar','Bihar'],
        855115: ['Kishanganj','Bihar'],
        855116: ['Kishanganj','Bihar'],
        855117: ['Kishanganj','Bihar'],
        110001: ['Delhi', 'Delhi'],
        110002: ['Delhi', 'Delhi'],
        110003: ['Delhi', 'Delhi'],
        110004: ['Delhi', 'Delhi'],
        110005: ['Delhi', 'Delhi'],
        110006: ['Delhi', 'Delhi'],
        110007: ['Delhi', 'Delhi'],
        110008: ['Delhi', 'Delhi'],
        110009: ['Delhi', 'Delhi'],
        110010: ['Delhi', 'Delhi'],
        110011: ['Delhi', 'Delhi'],
        110012: ['Delhi', 'Delhi'],
        110013: ['Delhi', 'Delhi'],
        110014: ['Delhi', 'Delhi'],
        110015: ['Delhi', 'Delhi'],
        110016: ['Delhi', 'Delhi'],
        110017: ['Delhi', 'Delhi'],
        110018: ['Delhi', 'Delhi'],
        110019: ['Delhi', 'Delhi'],
        110020: ['Delhi', 'Delhi'],
        110021: ['Delhi', 'Delhi'],
        110022: ['Delhi', 'Delhi'],
        110023: ['Delhi', 'Delhi'],
        110024: ['Delhi', 'Delhi'],
        110025: ['Delhi', 'Delhi'],
        110026: ['Delhi', 'Delhi'],
        110027: ['Delhi', 'Delhi'],
        110028: ['Delhi', 'Delhi'],
        110029: ['Delhi', 'Delhi'],
        110030: ['Delhi', 'Delhi'],
        110031: ['Delhi', 'Delhi'],
        110032: ['Delhi', 'Delhi'],
        110033: ['Delhi', 'Delhi'],
        110034: ['Delhi', 'Delhi'],
        110035: ['Delhi', 'Delhi'],
        110036: ['Delhi', 'Delhi'],
        110037: ['Delhi', 'Delhi'],
        110038: ['Delhi', 'Delhi'],
        110039: ['Delhi', 'Delhi'],
        110040: ['Delhi', 'Delhi'],
        110041: ['Delhi', 'Delhi'],
        110042: ['Delhi', 'Delhi'],
        110043: ['Delhi', 'Delhi'],
        110044: ['Delhi', 'Delhi'],
        110045: ['Delhi', 'Delhi'],
        110046: ['Delhi', 'Delhi'],
        110047: ['Delhi', 'Delhi'],
        110048: ['Delhi', 'Delhi'],
        110049: ['Delhi', 'Delhi'],
        110050: ['Delhi', 'Delhi'],
        110051: ['Delhi', 'Delhi'],
        110052: ['Delhi', 'Delhi'],
        110053: ['Delhi', 'Delhi'],
        110054: ['Delhi', 'Delhi'],
        110055: ['Delhi', 'Delhi'],
        110056: ['Delhi', 'Delhi'],
        110057: ['Delhi', 'Delhi'],
        110058: ['Delhi', 'Delhi'],
        110059: ['Delhi', 'Delhi'],
        110060: ['Delhi', 'Delhi'],
        110061: ['Delhi', 'Delhi'],
        110062: ['Delhi', 'Delhi'],
        110063: ['Delhi', 'Delhi'],
        110064: ['Delhi', 'Delhi'],
        110065: ['Delhi', 'Delhi'],
        110066: ['Delhi', 'Delhi'],
        110067: ['Delhi', 'Delhi'],
        110068: ['Delhi', 'Delhi'],
        110069: ['Delhi', 'Delhi'],
        110070: ['Delhi', 'Delhi'],
        110071: ['Delhi', 'Delhi'],
        110072: ['Delhi', 'Delhi'],
        110073: ['Delhi', 'Delhi'],
        110074: ['Delhi', 'Delhi'],
        110075: ['Delhi', 'Delhi'],
        110076: ['Delhi', 'Delhi'],
        110077: ['Delhi', 'Delhi'],
        110078: ['Delhi', 'Delhi'],
        110080: ['Delhi', 'Delhi'],
        110081: ['Delhi', 'Delhi'],
        110082: ['Delhi', 'Delhi'],
        110083: ['Delhi', 'Delhi'],
        110084: ['Delhi', 'Delhi'],
        110085: ['Delhi', 'Delhi'],
        110086: ['Delhi', 'Delhi'],
        110087: ['Delhi', 'Delhi'],
        110088: ['Delhi', 'Delhi'],
        110089: ['Delhi', 'Delhi'],
        110090: ['Delhi', 'Delhi'],
        110091: ['Delhi', 'Delhi'],
        110092: ['Delhi', 'Delhi'],
        110093: ['Delhi', 'Delhi'],
        110094: ['Delhi', 'Delhi'],
        110095: ['Delhi', 'Delhi'],
        110096: ['Delhi', 'Delhi'],
        110097: ['Delhi', 'Delhi'],
        110098: ['Delhi', 'Delhi'],
        110100: ['Delhi', 'Delhi'],
        121001: ['Faridabad', 'Haryana'],
        121002: ['Faridabad', 'Haryana'],
        121003: ['Faridabad', 'Haryana'],
        121004: ['Faridabad', 'Haryana'],
        121005: ['Faridabad', 'Haryana'],
        121006: ['Faridabad', 'Haryana'],
        121007: ['Faridabad', 'Haryana'],
        121008: ['Faridabad', 'Haryana'],
        121009: ['Faridabad', 'Haryana'],
        121010: ['Faridabad', 'Haryana'],
        121012: ['Faridabad', 'Haryana'],
        121013: ['Faridabad', 'Haryana'],
        121014: ['Ambala', 'Haryana'],
        121015: ['Ambala', 'Haryana'],
        121101: ['Faridabad', 'Haryana'],
        121102: ['Faridabad', 'Haryana'],
        121103: ['Faridabad', 'Haryana'],
        121104: ['Faridabad', 'Haryana'],
        121105: ['Faridabad', 'Haryana'],
        121106: ['Faridabad', 'Haryana'],
        121107: ['Faridabad', 'Haryana'],
        122001: ['Gurgaon', 'Haryana'],
        122002: ['Gurgaon', 'Haryana'],
        122003: ['Gurgaon', 'Haryana'],
        122004: ['Gurgaon', 'Haryana'],
        122005: ['Gurgaon', 'Haryana'],
        122006: ['Gurgaon', 'Haryana'],
        122007: ['Gurgaon', 'Haryana'],
        122008: ['Gurgaon', 'Haryana'],
        122009: ['Gurgaon', 'Haryana'],
        122010: ['Gurgaon', 'Haryana'],
        122011: ['Gurgaon', 'Haryana'],
        122012: ['Ambala', 'Haryana'],
        122015: ['Gurgaon', 'Haryana'],
        122016: ['Gurgaon', 'Haryana'],
        122017: ['Gurgaon', 'Haryana'],
        122018: ['Gurgaon', 'Haryana'],
        122051: ['Gurgaon', 'Haryana'],
        122052: ['Gurgaon', 'Haryana'],
        122098: ['Ambala', 'Haryana'],
        122101: ['Gurgaon', 'Haryana'],
        122102: ['Gurgaon', 'Haryana'],
        122103: ['Gurgaon', 'Haryana'],
        122104: ['Gurgaon', 'Haryana'],
        122105: ['Gurgaon', 'Haryana'],
        122107: ['Gurgaon', 'Haryana'],
        122108: ['Gurgaon', 'Haryana'],
        122413: ['Gurgaon', 'Haryana'],
        122414: ['Gurgaon', 'Haryana'],
        122502: ['Gurgaon', 'Haryana'],
        122503: ['Gurgaon', 'Haryana'],
        122504: ['Gurgaon', 'Haryana'],
        122505: ['Gurgaon', 'Haryana'],
        122506: ['Gurgaon', 'Haryana'],
        122508: ['Gurgaon', 'Haryana'],
        123031: ['Ambala', 'Haryana'],
        123106: ['Gurgaon', 'Haryana'],
        123413: ['Gurgaon', 'Haryana'],
        123414: ['Gurgaon', 'Haryana'],
        123502: ['Gurgaon', 'Haryana'],
        123503: ['Gurgaon', 'Haryana'],
        123504: ['Gurgaon', 'Haryana'],
        123505: ['Gurgaon', 'Haryana'],
        123506: ['Gurgaon', 'Haryana'],
        127311: ['Ambala', 'Haryana'],
        127312: ['Ambala', 'Haryana'],
        131001: ['Sonipat', 'Haryana'],
        131021: ['Sonipat', 'Haryana'],
        131022: ['Sonipat', 'Haryana'],
        131023: ['Sonipat', 'Haryana'],
        131024: ['Sonipat', 'Haryana'],
        131027: ['Sonipat', 'Haryana'],
        131028: ['Sonipat', 'Haryana'],
        131029: ['Sonipat', 'Haryana'],
        131039: ['Sonipat', 'Haryana'],
        131101: ['Sonipat', 'Haryana'],
        131102: ['Sonipat', 'Haryana'],
        131103: ['Sonipat', 'Haryana'],
        131301: ['Sonipat', 'Haryana'],
        131302: ['Sonipat', 'Haryana'],
        131304: ['Sonipat', 'Haryana'],
        131305: ['Sonipat', 'Haryana'],
        131306: ['Sonipat', 'Haryana'],
        131402: ['Sonipat', 'Haryana'],
        131403: ['Sonipat', 'Haryana'],
        131408: ['Sonipat', 'Haryana'],
        131409: ['Sonipat', 'Haryana'],
        132001: ['Karnal', 'Haryana'],
        132020: ['Kurukshetra', 'Haryana'],
        132021: ['Kurukshetra', 'Haryana'],
        132022: ['Karnal', 'Haryana'],
        132023: ['Karnal', 'Haryana'],
        132024: ['Karnal', 'Haryana'],
        132025: ['Karnal', 'Haryana'],
        132026: ['Kurukshetra', 'Haryana'],
        132027: ['Kurukshetra', 'Haryana'],
        132034: ['Kurukshetra', 'Haryana'],
        132035: ['Kurukshetra', 'Haryana'],
        132036: ['Karnal', 'Haryana'],
        132037: ['Karnal', 'Haryana'],
        132038: ['Karnal', 'Haryana'],
        132039: ['Karnal', 'Haryana'],
        132040: ['Karnal', 'Haryana'],
        132041: ['Karnal', 'Haryana'],
        132042: ['Kurukshetra', 'Haryana'],
        132043: ['Kurukshetra', 'Haryana'],
        132046: ['Karnal', 'Haryana'],
        132054: ['Karnal', 'Haryana'],
        132101: ['Karnal', 'Haryana'],
        132103: ['Karnal', 'Haryana'],
        132104: ['Karnal', 'Haryana'],
        132105: ['Karnal', 'Haryana'],
        132108: ['Karnal', 'Haryana'],
        132113: ['Karnal', 'Haryana'],
        132114: ['Karnal', 'Haryana'],
        132115: ['Karnal', 'Haryana'],
        132116: ['Karnal', 'Haryana'],
        132117: ['Karnal', 'Haryana'],
        132118: ['Kurukshetra', 'Haryana'],
        132119: ['Kurukshetra', 'Haryana'],
        132124: ['Karnal', 'Haryana'],
        132128: ['Karnal', 'Haryana'],
        132129: ['Kurukshetra', 'Haryana'],
        132130: ['Kurukshetra', 'Haryana'],
        132131: ['Kurukshetra', 'Haryana'],
        132132: ['Kurukshetra', 'Haryana'],
        132133: ['Kurukshetra', 'Haryana'],
        132135: ['Kurukshetra', 'Haryana'],
        132136: ['Kurukshetra', 'Haryana'],
        132137: ['Kurukshetra', 'Haryana'],
        132140: ['Karnal', 'Haryana'],
        132145: ['Karnal', 'Haryana'],
        132153: ['Karnal', 'Haryana'],
        132154: ['Karnal', 'Haryana'],
        132157: ['Karnal', 'Haryana'],
        133001: ['Ambala', 'Haryana'],
        133004: ['Ambala', 'Haryana'],
        133005: ['Ambala', 'Haryana'],
        133006: ['Ambala', 'Haryana'],
        133008: ['Ambala', 'Haryana'],
        133010: ['Ambala', 'Haryana'],
        133021: ['Ambala', 'Haryana'],
        133101: ['Ambala', 'Haryana'],
        133102: ['Ambala', 'Haryana'],
        133104: ['Ambala', 'Haryana'],
        133105: ['Ambala', 'Haryana'],
        133201: ['Ambala', 'Haryana'],
        133202: ['Ambala', 'Haryana'],
        133203: ['Ambala', 'Haryana'],
        133204: ['Ambala', 'Haryana'],
        133205: ['Ambala', 'Haryana'],
        133206: ['Ambala', 'Haryana'],
        133207: ['Ambala', 'Haryana'],
        133301: ['Panchkula', 'Haryana'],
        133302: ['Panchkula', 'Haryana'],
        134002: ['Ambala', 'Haryana'],
        134003: ['Ambala', 'Haryana'],
        134005: ['Ambala', 'Haryana'],
        134007: ['Ambala', 'Haryana'],
        134008: ['Ambala', 'Haryana'],
        134011: ['Ambala', 'Haryana'],
        134012: ['Ambala', 'Haryana'],
        134101: ['Panchkula', 'Haryana'],
        134102: ['Panchkula', 'Haryana'],
        134103: ['Panchkula', 'Haryana'],
        134104: ['Panchkula', 'Haryana'],
        134105: ['Ambala', 'Haryana'],
        134107: ['Panchkula', 'Haryana'],
        134108: ['Panchkula', 'Haryana'],
        134109: ['Panchkula', 'Haryana'],
        134112: ['Panchkula', 'Haryana'],
        134113: ['Panchkula', 'Haryana'],
        134114: ['Panchkula', 'Haryana'],
        134116: ['Panchkula', 'Haryana'],
        134117: ['Panchkula', 'Haryana'],
        134118: ['Panchkula', 'Haryana'],
        134201: ['Ambala', 'Haryana'],
        134202: ['Panchkula', 'Haryana'],
        134203: ['Ambala', 'Haryana'],
        134204: ['Panchkula', 'Haryana'],
        134205: ['Panchkula', 'Haryana'],
        135001: ['Ambala', 'Haryana'],
        135002: ['Ambala', 'Haryana'],
        135003: ['Ambala', 'Haryana'],
        135021: ['Ambala', 'Haryana'],
        135101: ['Ambala', 'Haryana'],
        135102: ['Ambala', 'Haryana'],
        135103: ['Ambala', 'Haryana'],
        135105: ['Ambala', 'Haryana'],
        136030: ['Kurukshetra', 'Haryana'],
        136038: ['Kurukshetra', 'Haryana'],
        136118: ['Kurukshetra', 'Haryana'],
        136119: ['Kurukshetra', 'Haryana'],
        136128: ['Kurukshetra', 'Haryana'],
        136129: ['Kurukshetra', 'Haryana'],
        136130: ['Kurukshetra', 'Haryana'],
        136131: ['Kurukshetra', 'Haryana'],
        136132: ['Kurukshetra', 'Haryana'],
        136135: ['Kurukshetra', 'Haryana'],
        136136: ['Kurukshetra', 'Haryana'],
        136156: ['Kurukshetra', 'Haryana'],
        140103: ['Mohali', 'Punjab'],
        140109: ['Mohali', 'Punjab'],
        140110: ['Mohali', 'Punjab'],
        140112: ['Mohali', 'Punjab'],
        140119: ['Chandigarh', 'Chandigarh'],
        140125: ['Chandigarh', 'Chandigarh'],
        140201: ['Patiala', 'Punjab'],
        140307: ['Mohali', 'Punjab'],
        140401: ['Patiala', 'Punjab'],
        140402: ['Patiala', 'Punjab'],
        140405: ['Patiala', 'Punjab'],
        140406: ['Patiala', 'Punjab'],
        140407: ['Patiala', 'Punjab'],
        140408: ['Chandigarh', 'Chandigarh'],
        140412: ['Patiala', 'Punjab'],
        140413: ['Mohali', 'Punjab'],
        140417: ['Patiala', 'Punjab'],
        140501: ['Mohali', 'Punjab'],
        140506: ['Patiala', 'Punjab'],
        140507: ['Mohali', 'Punjab'],
        140601: ['Patiala', 'Punjab'],
        140602: ['Patiala', 'Punjab'],
        140603: ['Patiala', 'Punjab'],
        140604: ['Mohali', 'Punjab'],
        140701: ['Patiala', 'Punjab'],
        140702: ['Patiala', 'Punjab'],
        140801: ['Ludhiana', 'Punjab'],
        140802: ['Patiala', 'Punjab'],
        140901: ['Mohali', 'Punjab'],
        141001: ['Ludhiana', 'Punjab'],
        141002: ['Ludhiana', 'Punjab'],
        141003: ['Ludhiana', 'Punjab'],
        141004: ['Ludhiana', 'Punjab'],
        141005: ['Ludhiana', 'Punjab'],
        141006: ['Ludhiana', 'Punjab'],
        141007: ['Ludhiana', 'Punjab'],
        141008: ['Ludhiana', 'Punjab'],
        141010: ['Ludhiana', 'Punjab'],
        141012: ['Ludhiana', 'Punjab'],
        141013: ['Ludhiana', 'Punjab'],
        141014: ['Ludhiana', 'Punjab'],
        141015: ['Ludhiana', 'Punjab'],
        141016: ['Ludhiana', 'Punjab'],
        141017: ['Ludhiana', 'Punjab'],
        141101: ['Ludhiana', 'Punjab'],
        141102: ['Ludhiana', 'Punjab'],
        141103: ['Ludhiana', 'Punjab'],
        141104: ['Ludhiana', 'Punjab'],
        141105: ['Ludhiana', 'Punjab'],
        141106: ['Ludhiana', 'Punjab'],
        141107: ['Ludhiana', 'Punjab'],
        141108: ['Ludhiana', 'Punjab'],
        141109: ['Ludhiana', 'Punjab'],
        141110: ['Ludhiana', 'Punjab'],
        141112: ['Ludhiana', 'Punjab'],
        141113: ['Ludhiana', 'Punjab'],
        141114: ['Ludhiana', 'Punjab'],
        141115: ['Ludhiana', 'Punjab'],
        141116: ['Ludhiana', 'Punjab'],
        141117: ['Ludhiana', 'Punjab'],
        141118: ['Ludhiana', 'Punjab'],
        141119: ['Ludhiana', 'Punjab'],
        141120: ['Ludhiana', 'Punjab'],
        141121: ['Ludhiana', 'Punjab'],
        141122: ['Ludhiana', 'Punjab'],
        141123: ['Ludhiana', 'Punjab'],
        141124: ['Ludhiana', 'Punjab'],
        141125: ['Ludhiana', 'Punjab'],
        141126: ['Ludhiana', 'Punjab'],
        141127: ['Ludhiana', 'Punjab'],
        141201: ['Ludhiana', 'Punjab'],
        141202: ['Ludhiana', 'Punjab'],
        141203: ['Ludhiana', 'Punjab'],
        141204: ['Ludhiana', 'Punjab'],
        141205: ['Ludhiana', 'Punjab'],
        141206: ['Ludhiana', 'Punjab'],
        141401: ['Ludhiana', 'Punjab'],
        141402: ['Ludhiana', 'Punjab'],
        141411: ['Patiala', 'Punjab'],
        141412: ['Ludhiana', 'Punjab'],
        141413: ['Ludhiana', 'Punjab'],
        141414: ['Ludhiana', 'Punjab'],
        141415: ['Ludhiana', 'Punjab'],
        141416: ['Ludhiana', 'Punjab'],
        141417: ['Ludhiana', 'Punjab'],
        141418: ['Ludhiana', 'Punjab'],
        141419: ['Ludhiana', 'Punjab'],
        141421: ['Ludhiana', 'Punjab'],
        141422: ['Ludhiana', 'Punjab'],
        142021: ['Ludhiana', 'Punjab'],
        142022: ['Ludhiana', 'Punjab'],
        142023: ['Ludhiana', 'Punjab'],
        142024: ['Ludhiana', 'Punjab'],
        142025: ['Ludhiana', 'Punjab'],
        142026: ['Ludhiana', 'Punjab'],
        142027: ['Ludhiana', 'Punjab'],
        142028: ['Ludhiana', 'Punjab'],
        142029: ['Ludhiana', 'Punjab'],
        142030: ['Ludhiana', 'Punjab'],
        142031: ['Ludhiana', 'Punjab'],
        142032: ['Ludhiana', 'Punjab'],
        142033: ['Ludhiana', 'Punjab'],
        142034: ['Ludhiana', 'Punjab'],
        142035: ['Ludhiana', 'Punjab'],
        142036: ['Ludhiana', 'Punjab'],
        143001: ['Amritsar', 'Punjab'],
        143002: ['Amritsar', 'Punjab'],
        143003: ['Amritsar', 'Punjab'],
        143004: ['Amritsar', 'Punjab'],
        143005: ['Amritsar', 'Punjab'],
        143006: ['Amritsar', 'Punjab'],
        143008: ['Amritsar', 'Punjab'],
        143009: ['Amritsar', 'Punjab'],
        143021: ['Amritsar', 'Punjab'],
        143022: ['Amritsar', 'Punjab'],
        143036: ['Amritsar', 'Punjab'],
        143101: ['Amritsar', 'Punjab'],
        143102: ['Amritsar', 'Punjab'],
        143103: ['Amritsar', 'Punjab'],
        143104: ['Amritsar', 'Punjab'],
        143105: ['Amritsar', 'Punjab'],
        143106: ['Amritsar', 'Punjab'],
        143107: ['Amritsar', 'Punjab'],
        143108: ['Amritsar', 'Punjab'],
        143109: ['Amritsar', 'Punjab'],
        143110: ['Amritsar', 'Punjab'],
        143111: ['Amritsar', 'Punjab'],
        143112: ['Amritsar', 'Punjab'],
        143113: ['Amritsar', 'Punjab'],
        143114: ['Amritsar', 'Punjab'],
        143115: ['Amritsar', 'Punjab'],
        143116: ['Amritsar', 'Punjab'],
        143117: ['Amritsar', 'Punjab'],
        143118: ['Amritsar', 'Punjab'],
        143119: ['Amritsar', 'Punjab'],
        143149: ['Amritsar', 'Punjab'],
        143201: ['Amritsar', 'Punjab'],
        143202: ['Amritsar', 'Punjab'],
        143203: ['Amritsar', 'Punjab'],
        143204: ['Amritsar', 'Punjab'],
        143205: ['Amritsar', 'Punjab'],
        143301: ['Amritsar', 'Punjab'],
        143302: ['Amritsar', 'Punjab'],
        143303: ['Amritsar', 'Punjab'],
        143304: ['Amritsar', 'Punjab'],
        143305: ['Amritsar', 'Punjab'],
        143401: ['Amritsar', 'Punjab'],
        143402: ['Amritsar', 'Punjab'],
        143406: ['Amritsar', 'Punjab'],
        143407: ['Amritsar', 'Punjab'],
        143408: ['Amritsar', 'Punjab'],
        143409: ['Amritsar', 'Punjab'],
        143410: ['Amritsar', 'Punjab'],
        143411: ['Amritsar', 'Punjab'],
        143412: ['Amritsar', 'Punjab'],
        143413: ['Amritsar', 'Punjab'],
        143414: ['Amritsar', 'Punjab'],
        143415: ['Amritsar', 'Punjab'],
        143416: ['Amritsar', 'Punjab'],
        143417: ['Amritsar', 'Punjab'],
        143418: ['Amritsar', 'Punjab'],
        143419: ['Amritsar', 'Punjab'],
        143422: ['Amritsar', 'Punjab'],
        143501: ['Amritsar', 'Punjab'],
        143502: ['Amritsar', 'Punjab'],
        143504: ['Amritsar', 'Punjab'],
        143601: ['Amritsar', 'Punjab'],
        143603: ['Amritsar', 'Punjab'],
        143606: ['Amritsar', 'Punjab'],
        144001: ['Jalandhar', 'Punjab'],
        144002: ['Jalandhar', 'Punjab'],
        144003: ['Jalandhar', 'Punjab'],
        144004: ['Jalandhar', 'Punjab'],
        144005: ['Jalandhar', 'Punjab'],
        144006: ['Jalandhar', 'Punjab'],
        144007: ['Jalandhar', 'Punjab'],
        144008: ['Jalandhar', 'Punjab'],
        144009: ['Jalandhar', 'Punjab'],
        144010: ['Jalandhar', 'Punjab'],
        144011: ['Jalandhar', 'Punjab'],
        144012: ['Jalandhar', 'Punjab'],
        144013: ['Jalandhar', 'Punjab'],
        144014: ['Jalandhar', 'Punjab'],
        144020: ['Jalandhar', 'Punjab'],
        144021: ['Jalandhar', 'Punjab'],
        144022: ['Jalandhar', 'Punjab'],
        144023: ['Jalandhar', 'Punjab'],
        144024: ['Jalandhar', 'Punjab'],
        144025: ['Jalandhar', 'Punjab'],
        144026: ['Jalandhar', 'Punjab'],
        144027: ['Jalandhar', 'Punjab'],
        144028: ['Jalandhar', 'Punjab'],
        144029: ['Jalandhar', 'Punjab'],
        144030: ['Jalandhar', 'Punjab'],
        144031: ['Jalandhar', 'Punjab'],
        144032: ['Jalandhar', 'Punjab'],
        144033: ['Jalandhar', 'Punjab'],
        144034: ['Jalandhar', 'Punjab'],
        144035: ['Jalandhar', 'Punjab'],
        144036: ['Jalandhar', 'Punjab'],
        144037: ['Jalandhar', 'Punjab'],
        144039: ['Jalandhar', 'Punjab'],
        144040: ['Jalandhar', 'Punjab'],
        144041: ['Jalandhar', 'Punjab'],
        144042: ['Jalandhar', 'Punjab'],
        144043: ['Jalandhar', 'Punjab'],
        144044: ['Jalandhar', 'Punjab'],
        144101: ['Jalandhar', 'Punjab'],
        144102: ['Jalandhar', 'Punjab'],
        144103: ['Jalandhar', 'Punjab'],
        144104: ['Jalandhar', 'Punjab'],
        144106: ['Jalandhar', 'Punjab'],
        144201: ['Jalandhar', 'Punjab'],
        144301: ['Jalandhar', 'Punjab'],
        144302: ['Jalandhar', 'Punjab'],
        144303: ['Jalandhar', 'Punjab'],
        144311: ['Jalandhar', 'Punjab'],
        144403: ['Jalandhar', 'Punjab'],
        144405: ['Jalandhar', 'Punjab'],
        144407: ['Jalandhar', 'Punjab'],
        144409: ['Jalandhar', 'Punjab'],
        144410: ['Jalandhar', 'Punjab'],
        144415: ['Jalandhar', 'Punjab'],
        144416: ['Jalandhar', 'Punjab'],
        144417: ['Jalandhar', 'Punjab'],
        144418: ['Jalandhar', 'Punjab'],
        144419: ['Jalandhar', 'Punjab'],
        144422: ['Jalandhar', 'Punjab'],
        144502: ['Jalandhar', 'Punjab'],
        144503: ['Jalandhar', 'Punjab'],
        144504: ['Jalandhar', 'Punjab'],
        144505: ['Jalandhar', 'Punjab'],
        144506: ['Jalandhar', 'Punjab'],
        144508: ['Jalandhar', 'Punjab'],
        144509: ['Jalandhar', 'Punjab'],
        144510: ['Jalandhar', 'Punjab'],
        144511: ['Jalandhar', 'Punjab'],
        144512: ['Jalandhar', 'Punjab'],
        144513: ['Jalandhar', 'Punjab'],
        144514: ['Jalandhar', 'Punjab'],
        144515: ['Jalandhar', 'Punjab'],
        144516: ['Jalandhar', 'Punjab'],
        144517: ['Jalandhar', 'Punjab'],
        144603: ['Jalandhar', 'Punjab'],
        144623: ['Jalandhar', 'Punjab'],
        144629: ['Jalandhar', 'Punjab'],
        144630: ['Jalandhar', 'Punjab'],
        144633: ['Jalandhar', 'Punjab'],
        144701: ['Jalandhar', 'Punjab'],
        144702: ['Jalandhar', 'Punjab'],
        144703: ['Jalandhar', 'Punjab'],
        144801: ['Jalandhar', 'Punjab'],
        144805: ['Jalandhar', 'Punjab'],
        144806: ['Jalandhar', 'Punjab'],
        147001: ['Patiala', 'Punjab'],
        147002: ['Patiala', 'Punjab'],
        147003: ['Patiala', 'Punjab'],
        147004: ['Patiala', 'Punjab'],
        147005: ['Patiala', 'Punjab'],
        147006: ['Patiala', 'Punjab'],
        147007: ['Patiala', 'Punjab'],
        147021: ['Patiala', 'Punjab'],
        147101: ['Patiala', 'Punjab'],
        147102: ['Patiala', 'Punjab'],
        147103: ['Patiala', 'Punjab'],
        147104: ['Patiala', 'Punjab'],
        147105: ['Patiala', 'Punjab'],
        147111: ['Patiala', 'Punjab'],
        147201: ['Patiala', 'Punjab'],
        147202: ['Patiala', 'Punjab'],
        147203: ['Patiala', 'Punjab'],
        147301: ['Patiala', 'Punjab'],
        152020: ['Chandigarh', 'Chandigarh'],
        160001: ['Chandigarh', 'Chandigarh'],
        160002: ['Chandigarh', 'Chandigarh'],
        160003: ['Chandigarh', 'Chandigarh'],
        160004: ['Chandigarh', 'Chandigarh'],
        160005: ['Chandigarh', 'Chandigarh'],
        160008: ['Chandigarh', 'Chandigarh'],
        160009: ['Chandigarh', 'Chandigarh'],
        160010: ['Chandigarh', 'Chandigarh'],
        160011: ['Chandigarh', 'Chandigarh'],
        160012: ['Chandigarh', 'Chandigarh'],
        160014: ['Chandigarh', 'Chandigarh'],
        160015: ['Chandigarh', 'Chandigarh'],
        160016: ['Chandigarh', 'Chandigarh'],
        160017: ['Chandigarh', 'Chandigarh'],
        160018: ['Chandigarh', 'Chandigarh'],
        160019: ['Chandigarh', 'Chandigarh'],
        160020: ['Chandigarh', 'Chandigarh'],
        160022: ['Chandigarh', 'Chandigarh'],
        160023: ['Chandigarh', 'Chandigarh'],
        160025: ['Chandigarh', 'Chandigarh'],
        160026: ['Chandigarh', 'Chandigarh'],
        160030: ['Chandigarh', 'Chandigarh'],
        160031: ['Chandigarh', 'Chandigarh'],
        160036: ['Chandigarh', 'Chandigarh'],
        160043: ['Chandigarh', 'Chandigarh'],
        160047: ['Chandigarh', 'Chandigarh'],
        160055: ['Chandigarh', 'Chandigarh'],
        160059: ['Chandigarh', 'Chandigarh'],
        160061: ['Chandigarh', 'Chandigarh'],
        160101: ['Chandigarh', 'Chandigarh'],
        160102: ['Chandigarh', 'Chandigarh'],
        160103: ['Mohali', 'Punjab'],
        160104: ['Mohali', 'Punjab'],
        160106: ['Chandigarh', 'Chandigarh'],
        171001: ['Shimla', 'Himachal Pradesh'],
        171002: ['Shimla', 'Himachal Pradesh'],
        171003: ['Shimla', 'Himachal Pradesh'],
        171004: ['Shimla', 'Himachal Pradesh'],
        171005: ['Shimla', 'Himachal Pradesh'],
        171006: ['Shimla', 'Himachal Pradesh'],
        171007: ['Shimla', 'Himachal Pradesh'],
        171008: ['Shimla', 'Himachal Pradesh'],
        171009: ['Shimla', 'Himachal Pradesh'],
        171010: ['Shimla', 'Himachal Pradesh'],
        171011: ['Shimla', 'Himachal Pradesh'],
        171012: ['Shimla', 'Himachal Pradesh'],
        171013: ['Shimla', 'Himachal Pradesh'],
        171014: ['Shimla', 'Himachal Pradesh'],
        171015: ['Shimla', 'Himachal Pradesh'],
        171018: ['Shimla', 'Himachal Pradesh'],
        171019: ['Shimla', 'Himachal Pradesh'],
        171103: ['Shimla', 'Himachal Pradesh'],
        171108: ['Shimla', 'Himachal Pradesh'],
        171201: ['Shimla', 'Himachal Pradesh'],
        171202: ['Shimla', 'Himachal Pradesh'],
        171203: ['Shimla', 'Himachal Pradesh'],
        171204: ['Shimla', 'Himachal Pradesh'],
        171205: ['Shimla', 'Himachal Pradesh'],
        171206: ['Shimla', 'Himachal Pradesh'],
        171207: ['Shimla', 'Himachal Pradesh'],
        171208: ['Shimla', 'Himachal Pradesh'],
        171209: ['Shimla', 'Himachal Pradesh'],
        171210: ['Shimla', 'Himachal Pradesh'],
        171211: ['Shimla', 'Himachal Pradesh'],
        171212: ['Shimla', 'Himachal Pradesh'],
        171213: ['Shimla', 'Himachal Pradesh'],
        171214: ['Shimla', 'Himachal Pradesh'],
        171215: ['Shimla', 'Himachal Pradesh'],
        171216: ['Shimla', 'Himachal Pradesh'],
        171217: ['Shimla', 'Himachal Pradesh'],
        171218: ['Shimla', 'Himachal Pradesh'],
        171219: ['Shimla', 'Himachal Pradesh'],
        171220: ['Shimla', 'Himachal Pradesh'],
        171221: ['Shimla', 'Himachal Pradesh'],
        171222: ['Shimla', 'Himachal Pradesh'],
        171223: ['Shimla', 'Himachal Pradesh'],
        171224: ['Shimla', 'Himachal Pradesh'],
        171225: ['Shimla', 'Himachal Pradesh'],
        171226: ['Shimla', 'Himachal Pradesh'],
        171301: ['Shimla', 'Himachal Pradesh'],
        171303: ['Shimla', 'Himachal Pradesh'],
        171304: ['Shimla', 'Himachal Pradesh'],
        172001: ['Shimla', 'Himachal Pradesh'],
        172021: ['Shimla', 'Himachal Pradesh'],
        172022: ['Shimla', 'Himachal Pradesh'],
        172024: ['Shimla', 'Himachal Pradesh'],
        172027: ['Shimla', 'Himachal Pradesh'],
        172028: ['Shimla', 'Himachal Pradesh'],
        172029: ['Shimla', 'Himachal Pradesh'],
        172030: ['Shimla', 'Himachal Pradesh'],
        172031: ['Shimla', 'Himachal Pradesh'],
        172034: ['Shimla', 'Himachal Pradesh'],
        172102: ['Shimla', 'Himachal Pradesh'],
        172201: ['Shimla', 'Himachal Pradesh'],
        173219: ['Shimla', 'Himachal Pradesh'],
        173236: ['Shimla', 'Himachal Pradesh'],
        176326: ['Shimla', 'Himachal Pradesh'],
        180001: ['Jammu', 'Jammu and Kashmir'],
        180002: ['Jammu', 'Jammu and Kashmir'],
        180003: ['Jammu', 'Jammu and Kashmir'],
        180004: ['Jammu', 'Jammu and Kashmir'],
        180005: ['Jammu', 'Jammu and Kashmir'],
        180006: ['Jammu', 'Jammu and Kashmir'],
        180007: ['Jammu', 'Jammu and Kashmir'],
        180008: ['Jammu', 'Jammu and Kashmir'],
        180009: ['Jammu', 'Jammu and Kashmir'],
        180010: ['Jammu', 'Jammu and Kashmir'],
        180011: ['Jammu', 'Jammu and Kashmir'],
        180012: ['Jammu', 'Jammu and Kashmir'],
        180013: ['Jammu', 'Jammu and Kashmir'],
        180015: ['Jammu', 'Jammu and Kashmir'],
        180016: ['Jammu', 'Jammu and Kashmir'],
        180017: ['Jammu', 'Jammu and Kashmir'],
        180018: ['Jammu', 'Jammu and Kashmir'],
        180019: ['Jammu', 'Jammu and Kashmir'],
        180020: ['Jammu', 'Jammu and Kashmir'],
        181101: ['Jammu', 'Jammu and Kashmir'],
        181102: ['Jammu', 'Jammu and Kashmir'],
        181103: ['Jammu', 'Jammu and Kashmir'],
        181104: ['Jammu', 'Jammu and Kashmir'],
        181105: ['Jammu', 'Jammu and Kashmir'],
        181111: ['Jammu', 'Jammu and Kashmir'],
        181114: ['Jammu', 'Jammu and Kashmir'],
        181121: ['Jammu', 'Jammu and Kashmir'],
        181122: ['Jammu', 'Jammu and Kashmir'],
        181123: ['Jammu', 'Jammu and Kashmir'],
        181124: ['Jammu', 'Jammu and Kashmir'],
        181131: ['Jammu', 'Jammu and Kashmir'],
        181132: ['Jammu', 'Jammu and Kashmir'],
        181133: ['Jammu', 'Jammu and Kashmir'],
        181134: ['Jammu', 'Jammu and Kashmir'],
        181141: ['Jammu', 'Jammu and Kashmir'],
        181142: ['Jammu', 'Jammu and Kashmir'],
        181143: ['Jammu', 'Jammu and Kashmir'],
        181144: ['Jammu', 'Jammu and Kashmir'],
        181145: ['Jammu', 'Jammu and Kashmir'],
        181151: ['Jammu', 'Jammu and Kashmir'],
        181152: ['Jammu', 'Jammu and Kashmir'],
        181201: ['Jammu', 'Jammu and Kashmir'],
        181202: ['Jammu', 'Jammu and Kashmir'],
        181203: ['Jammu', 'Jammu and Kashmir'],
        181204: ['Jammu', 'Jammu and Kashmir'],
        181205: ['Jammu', 'Jammu and Kashmir'],
        181206: ['Jammu', 'Jammu and Kashmir'],
        181207: ['Jammu', 'Jammu and Kashmir'],
        181208: ['Jammu', 'Jammu and Kashmir'],
        181209: ['Jammu', 'Jammu and Kashmir'],
        181221: ['Jammu', 'Jammu and Kashmir'],
        181224: ['Jammu', 'Jammu and Kashmir'],
        184120: ['Jammu', 'Jammu and Kashmir'],
        184121: ['Jammu', 'Jammu and Kashmir'],
        185134: ['Jammu', 'Jammu and Kashmir'],
        185154: ['Jammu', 'Jammu and Kashmir'],
        201001: ['Ghaziabad', 'Uttar Pradesh'],
        201002: ['Ghaziabad', 'Uttar Pradesh'],
        201003: ['Ghaziabad', 'Uttar Pradesh'],
        201004: ['Ghaziabad', 'Uttar Pradesh'],
        201005: ['Ghaziabad', 'Uttar Pradesh'],
        201006: ['Ghaziabad', 'Uttar Pradesh'],
        201007: ['Ghaziabad', 'Uttar Pradesh'],
        201008: ['Noida', 'Uttar Pradesh'],
        201009: ['Ghaziabad', 'Uttar Pradesh'],
        201010: ['Ghaziabad', 'Uttar Pradesh'],
        201011: ['Ghaziabad', 'Uttar Pradesh'],
        201012: ['Ghaziabad', 'Uttar Pradesh'],
        201013: ['Ghaziabad', 'Uttar Pradesh'],
        201014: ['Ghaziabad', 'Uttar Pradesh'],
        201015: ['Ghaziabad', 'Uttar Pradesh'],
        201016: ['Ghaziabad', 'Uttar Pradesh'],
        201017: ['Ghaziabad', 'Uttar Pradesh'],
        201018: ['Lucknow', 'Uttar Pradesh'],
        201019: ['Ghaziabad', 'Uttar Pradesh'],
        201020: ['Lucknow', 'Uttar Pradesh'],
        201102: ['Ghaziabad', 'Uttar Pradesh'],
        201103: ['Ghaziabad', 'Uttar Pradesh'],
        201201: ['Ghaziabad', 'Uttar Pradesh'],
        201204: ['Ghaziabad', 'Uttar Pradesh'],
        201206: ['Ghaziabad', 'Uttar Pradesh'],
        201301: ['Ghaziabad', 'Uttar Pradesh'],
        201302: ['Ghaziabad', 'Uttar Pradesh'],
        201303: ['Ghaziabad', 'Uttar Pradesh'],
        201304: ['Ghaziabad', 'Uttar Pradesh'],
        201305: ['Noida', 'Uttar Pradesh'],
        201306: ['Noida', 'Uttar Pradesh'],
        201307: ['Noida', 'Uttar Pradesh'],
        201309: ['Noida', 'Uttar Pradesh'],
        201310: ['Noida', 'Uttar Pradesh'],
        201315: ['Lucknow', 'Uttar Pradesh'],
        201316: ['Lucknow', 'Uttar Pradesh'],
        201317: ['Lucknow', 'Uttar Pradesh'],
        201318: ['Lucknow', 'Uttar Pradesh'],
        203207: ['Ghaziabad', 'Uttar Pradesh'],
        208001: ['Kanpur', 'Uttar Pradesh'],
        208002: ['Kanpur', 'Uttar Pradesh'],
        208003: ['Kanpur', 'Uttar Pradesh'],
        208004: ['Kanpur', 'Uttar Pradesh'],
        208005: ['Kanpur', 'Uttar Pradesh'],
        208006: ['Kanpur', 'Uttar Pradesh'],
        208007: ['Kanpur', 'Uttar Pradesh'],
        208008: ['Kanpur', 'Uttar Pradesh'],
        208009: ['Kanpur', 'Uttar Pradesh'],
        208010: ['Kanpur', 'Uttar Pradesh'],
        208011: ['Kanpur', 'Uttar Pradesh'],
        208012: ['Kanpur', 'Uttar Pradesh'],
        208013: ['Kanpur', 'Uttar Pradesh'],
        208014: ['Kanpur', 'Uttar Pradesh'],
        208015: ['Kanpur', 'Uttar Pradesh'],
        208016: ['Kanpur', 'Uttar Pradesh'],
        208017: ['Kanpur', 'Uttar Pradesh'],
        208018: ['Kanpur', 'Uttar Pradesh'],
        208019: ['Kanpur', 'Uttar Pradesh'],
        208020: ['Kanpur', 'Uttar Pradesh'],
        208021: ['Kanpur', 'Uttar Pradesh'],
        208022: ['Kanpur', 'Uttar Pradesh'],
        208023: ['Kanpur', 'Uttar Pradesh'],
        208024: ['Kanpur', 'Uttar Pradesh'],
        208025: ['Kanpur', 'Uttar Pradesh'],
        208026: ['Kanpur', 'Uttar Pradesh'],
        208027: ['Kanpur', 'Uttar Pradesh'],
        209101: ['Kanpur', 'Uttar Pradesh'],
        209111: ['Kanpur', 'Uttar Pradesh'],
        209112: ['Kanpur', 'Uttar Pradesh'],
        209115: ['Kanpur', 'Uttar Pradesh'],
        209121: ['Kanpur', 'Uttar Pradesh'],
        209125: ['Kanpur', 'Uttar Pradesh'],
        209201: ['Kanpur', 'Uttar Pradesh'],
        209202: ['Kanpur', 'Uttar Pradesh'],
        209203: ['Kanpur', 'Uttar Pradesh'],
        209204: ['Kanpur', 'Uttar Pradesh'],
        209205: ['Kanpur', 'Uttar Pradesh'],
        209206: ['Kanpur', 'Uttar Pradesh'],
        209207: ['Kanpur', 'Uttar Pradesh'],
        209208: ['Kanpur', 'Uttar Pradesh'],
        209209: ['Kanpur', 'Uttar Pradesh'],
        209210: ['Kanpur', 'Uttar Pradesh'],
        209214: ['Kanpur', 'Uttar Pradesh'],
        209217: ['Kanpur', 'Uttar Pradesh'],
        209301: ['Kanpur', 'Uttar Pradesh'],
        209302: ['Kanpur', 'Uttar Pradesh'],
        209303: ['Kanpur', 'Uttar Pradesh'],
        209304: ['Kanpur', 'Uttar Pradesh'],
        209305: ['Kanpur', 'Uttar Pradesh'],
        209306: ['Kanpur', 'Uttar Pradesh'],
        209307: ['Kanpur', 'Uttar Pradesh'],
        209308: ['Kanpur', 'Uttar Pradesh'],
        209310: ['Kanpur', 'Uttar Pradesh'],
        209311: ['Kanpur', 'Uttar Pradesh'],
        209312: ['Kanpur', 'Uttar Pradesh'],
        209401: ['Kanpur', 'Uttar Pradesh'],
        209402: ['Kanpur', 'Uttar Pradesh'],
        209860: ['Kanpur', 'Uttar Pradesh'],
        211001: ['Allahabad', 'Uttar Pradesh'],
        211002: ['Allahabad', 'Uttar Pradesh'],
        211003: ['Allahabad', 'Uttar Pradesh'],
        211004: ['Allahabad', 'Uttar Pradesh'],
        211005: ['Allahabad', 'Uttar Pradesh'],
        211006: ['Allahabad', 'Uttar Pradesh'],
        211007: ['Allahabad', 'Uttar Pradesh'],
        211008: ['Allahabad', 'Uttar Pradesh'],
        211009: ['Allahabad', 'Uttar Pradesh'],
        211010: ['Allahabad', 'Uttar Pradesh'],
        211011: ['Allahabad', 'Uttar Pradesh'],
        211012: ['Allahabad', 'Uttar Pradesh'],
        211013: ['Allahabad', 'Uttar Pradesh'],
        211014: ['Allahabad', 'Uttar Pradesh'],
        211015: ['Allahabad', 'Uttar Pradesh'],
        211016: ['Allahabad', 'Uttar Pradesh'],
        211017: ['Allahabad', 'Uttar Pradesh'],
        211018: ['Allahabad', 'Uttar Pradesh'],
        211019: ['Allahabad', 'Uttar Pradesh'],
        211021: ['Allahabad', 'Uttar Pradesh'],
        211022: ['Allahabad', 'Uttar Pradesh'],
        212104: ['Allahabad', 'Uttar Pradesh'],
        212105: ['Allahabad', 'Uttar Pradesh'],
        212106: ['Allahabad', 'Uttar Pradesh'],
        212107: ['Allahabad', 'Uttar Pradesh'],
        212108: ['Allahabad', 'Uttar Pradesh'],
        212109: ['Allahabad', 'Uttar Pradesh'],
        212111: ['Allahabad', 'Uttar Pradesh'],
        212202: ['Allahabad', 'Uttar Pradesh'],
        212203: ['Allahabad', 'Uttar Pradesh'],
        212204: ['Allahabad', 'Uttar Pradesh'],
        212205: ['Allahabad', 'Uttar Pradesh'],
        212206: ['Allahabad', 'Uttar Pradesh'],
        212207: ['Allahabad', 'Uttar Pradesh'],
        212208: ['Allahabad', 'Uttar Pradesh'],
        212212: ['Allahabad', 'Uttar Pradesh'],
        212213: ['Allahabad', 'Uttar Pradesh'],
        212214: ['Allahabad', 'Uttar Pradesh'],
        212216: ['Allahabad', 'Uttar Pradesh'],
        212217: ['Allahabad', 'Uttar Pradesh'],
        212301: ['Allahabad', 'Uttar Pradesh'],
        212302: ['Allahabad', 'Uttar Pradesh'],
        212303: ['Allahabad', 'Uttar Pradesh'],
        212305: ['Allahabad', 'Uttar Pradesh'],
        212306: ['Allahabad', 'Uttar Pradesh'],
        212307: ['Allahabad', 'Uttar Pradesh'],
        212308: ['Allahabad', 'Uttar Pradesh'],
        212401: ['Allahabad', 'Uttar Pradesh'],
        212402: ['Allahabad', 'Uttar Pradesh'],
        212404: ['Allahabad', 'Uttar Pradesh'],
        212405: ['Allahabad', 'Uttar Pradesh'],
        212502: ['Allahabad', 'Uttar Pradesh'],
        212503: ['Allahabad', 'Uttar Pradesh'],
        212507: ['Allahabad', 'Uttar Pradesh'],
        221001: ['Varanasi', 'Uttar Pradesh'],
        221002: ['Varanasi', 'Uttar Pradesh'],
        221003: ['Varanasi', 'Uttar Pradesh'],
        221004: ['Varanasi', 'Uttar Pradesh'],
        221005: ['Varanasi', 'Uttar Pradesh'],
        221006: ['Varanasi', 'Uttar Pradesh'],
        221007: ['Varanasi', 'Uttar Pradesh'],
        221008: ['Varanasi', 'Uttar Pradesh'],
        221009: ['Varanasi', 'Uttar Pradesh'],
        221010: ['Varanasi', 'Uttar Pradesh'],
        221011: ['Varanasi', 'Uttar Pradesh'],
        221012: ['Varanasi', 'Uttar Pradesh'],
        221101: ['Varanasi', 'Uttar Pradesh'],
        221103: ['Varanasi', 'Uttar Pradesh'],
        221104: ['Varanasi', 'Uttar Pradesh'],
        221105: ['Varanasi', 'Uttar Pradesh'],
        221106: ['Varanasi', 'Uttar Pradesh'],
        221107: ['Varanasi', 'Uttar Pradesh'],
        221108: ['Varanasi', 'Uttar Pradesh'],
        221109: ['Varanasi', 'Uttar Pradesh'],
        221110: ['Varanasi', 'Uttar Pradesh'],
        221111: ['Varanasi', 'Uttar Pradesh'],
        221112: ['Varanasi', 'Uttar Pradesh'],
        221113: ['Varanasi', 'Uttar Pradesh'],
        221115: ['Varanasi', 'Uttar Pradesh'],
        221116: ['Varanasi', 'Uttar Pradesh'],
        221201: ['Varanasi', 'Uttar Pradesh'],
        221202: ['Varanasi', 'Uttar Pradesh'],
        221204: ['Varanasi', 'Uttar Pradesh'],
        221205: ['Varanasi', 'Uttar Pradesh'],
        221206: ['Varanasi', 'Uttar Pradesh'],
        221207: ['Varanasi', 'Uttar Pradesh'],
        221208: ['Varanasi', 'Uttar Pradesh'],
        221209: ['Varanasi', 'Uttar Pradesh'],
        221210: ['Varanasi', 'Uttar Pradesh'],
        221301: ['Varanasi', 'Uttar Pradesh'],
        221302: ['Varanasi', 'Uttar Pradesh'],
        221303: ['Varanasi', 'Uttar Pradesh'],
        221304: ['Varanasi', 'Uttar Pradesh'],
        221305: ['Varanasi', 'Uttar Pradesh'],
        221306: ['Varanasi', 'Uttar Pradesh'],
        221307: ['Varanasi', 'Uttar Pradesh'],
        221308: ['Varanasi', 'Uttar Pradesh'],
        221309: ['Varanasi', 'Uttar Pradesh'],
        221310: ['Varanasi', 'Uttar Pradesh'],
        221311: ['Varanasi', 'Uttar Pradesh'],
        221313: ['Varanasi', 'Uttar Pradesh'],
        221314: ['Varanasi', 'Uttar Pradesh'],
        221401: ['Varanasi', 'Uttar Pradesh'],
        221402: ['Varanasi', 'Uttar Pradesh'],
        221403: ['Varanasi', 'Uttar Pradesh'],
        221404: ['Varanasi', 'Uttar Pradesh'],
        221405: ['Varanasi', 'Uttar Pradesh'],
        221406: ['Varanasi', 'Uttar Pradesh'],
        221407: ['Varanasi', 'Uttar Pradesh'],
        221409: ['Varanasi', 'Uttar Pradesh'],
        221502: ['Allahabad', 'Uttar Pradesh'],
        221503: ['Allahabad', 'Uttar Pradesh'],
        221504: ['Allahabad', 'Uttar Pradesh'],
        221505: ['Allahabad', 'Uttar Pradesh'],
        221506: ['Allahabad', 'Uttar Pradesh'],
        221507: ['Allahabad', 'Uttar Pradesh'],
        221508: ['Allahabad', 'Uttar Pradesh'],
        226001: ['Lucknow', 'Uttar Pradesh'],
        226002: ['Lucknow', 'Uttar Pradesh'],
        226003: ['Lucknow', 'Uttar Pradesh'],
        226004: ['Lucknow', 'Uttar Pradesh'],
        226005: ['Lucknow', 'Uttar Pradesh'],
        226006: ['Lucknow', 'Uttar Pradesh'],
        226007: ['Lucknow', 'Uttar Pradesh'],
        226008: ['Lucknow', 'Uttar Pradesh'],
        226009: ['Lucknow', 'Uttar Pradesh'],
        226010: ['Lucknow', 'Uttar Pradesh'],
        226011: ['Lucknow', 'Uttar Pradesh'],
        226012: ['Lucknow', 'Uttar Pradesh'],
        226013: ['Lucknow', 'Uttar Pradesh'],
        226014: ['Lucknow', 'Uttar Pradesh'],
        226015: ['Lucknow', 'Uttar Pradesh'],
        226016: ['Lucknow', 'Uttar Pradesh'],
        226017: ['Lucknow', 'Uttar Pradesh'],
        226018: ['Lucknow', 'Uttar Pradesh'],
        226019: ['Lucknow', 'Uttar Pradesh'],
        226020: ['Lucknow', 'Uttar Pradesh'],
        226021: ['Lucknow', 'Uttar Pradesh'],
        226022: ['Lucknow', 'Uttar Pradesh'],
        226023: ['Lucknow', 'Uttar Pradesh'],
        226024: ['Lucknow', 'Uttar Pradesh'],
        226025: ['Lucknow', 'Uttar Pradesh'],
        226026: ['Lucknow', 'Uttar Pradesh'],
        226027: ['Lucknow', 'Uttar Pradesh'],
        226028: ['Lucknow', 'Uttar Pradesh'],
        226029: ['Lucknow', 'Uttar Pradesh'],
        226030: ['Lucknow', 'Uttar Pradesh'],
        226031: ['Lucknow', 'Uttar Pradesh'],
        226101: ['Lucknow', 'Uttar Pradesh'],
        226102: ['Lucknow', 'Uttar Pradesh'],
        226103: ['Lucknow', 'Uttar Pradesh'],
        226104: ['Lucknow', 'Uttar Pradesh'],
        226201: ['Lucknow', 'Uttar Pradesh'],
        226202: ['Lucknow', 'Uttar Pradesh'],
        226203: ['Lucknow', 'Uttar Pradesh'],
        226301: ['Lucknow', 'Uttar Pradesh'],
        226302: ['Lucknow', 'Uttar Pradesh'],
        226303: ['Lucknow', 'Uttar Pradesh'],
        226401: ['Lucknow', 'Uttar Pradesh'],
        226501: ['Lucknow', 'Uttar Pradesh'],
        227101: ['Lucknow', 'Uttar Pradesh'],
        227105: ['Lucknow', 'Uttar Pradesh'],
        227111: ['Lucknow', 'Uttar Pradesh'],
        227115: ['Lucknow', 'Uttar Pradesh'],
        227116: ['Lucknow', 'Uttar Pradesh'],
        227120: ['Lucknow', 'Uttar Pradesh'],
        227125: ['Lucknow', 'Uttar Pradesh'],
        227132: ['Lucknow', 'Uttar Pradesh'],
        227202: ['Lucknow', 'Uttar Pradesh'],
        227305: ['Lucknow', 'Uttar Pradesh'],
        227308: ['Lucknow', 'Uttar Pradesh'],
        227309: ['Lucknow', 'Uttar Pradesh'],
        228178: ['Allahabad', 'Uttar Pradesh'],
        229411: ['Allahabad', 'Uttar Pradesh'],
        229412: ['Allahabad', 'Uttar Pradesh'],
        229413: ['Allahabad', 'Uttar Pradesh'],
        232101: ['Varanasi', 'Uttar Pradesh'],
        232102: ['Varanasi', 'Uttar Pradesh'],
        232103: ['Varanasi', 'Uttar Pradesh'],
        232104: ['Varanasi', 'Uttar Pradesh'],
        232105: ['Varanasi', 'Uttar Pradesh'],
        232106: ['Varanasi', 'Uttar Pradesh'],
        232107: ['Varanasi', 'Uttar Pradesh'],
        232108: ['Varanasi', 'Uttar Pradesh'],
        232109: ['Varanasi', 'Uttar Pradesh'],
        232110: ['Varanasi', 'Uttar Pradesh'],
        232111: ['Varanasi', 'Uttar Pradesh'],
        232114: ['Varanasi', 'Uttar Pradesh'],
        232115: ['Varanasi', 'Uttar Pradesh'],
        232116: ['Varanasi', 'Uttar Pradesh'],
        232118: ['Varanasi', 'Uttar Pradesh'],
        232119: ['Varanasi', 'Uttar Pradesh'],
        232120: ['Varanasi', 'Uttar Pradesh'],
        244225: ['Varanasi', 'Uttar Pradesh'],
        244712: ['Dehradun', 'Uttarakhand'],
        244713: ['Dehradun', 'Uttarakhand'],
        244715: ['Dehradun', 'Uttarakhand'],
        244716: ['Dehradun', 'Uttarakhand'],
        244717: ['Dehradun', 'Uttarakhand'],
        245101: ['Ghaziabad', 'Uttar Pradesh'],
        245201: ['Ghaziabad', 'Uttar Pradesh'],
        245205: ['Ghaziabad', 'Uttar Pradesh'],
        245207: ['Ghaziabad', 'Uttar Pradesh'],
        245208: ['Ghaziabad', 'Uttar Pradesh'],
        245301: ['Ghaziabad', 'Uttar Pradesh'],
        245304: ['Ghaziabad', 'Uttar Pradesh'],
        246001: ['Dehradun', 'Uttarakhand'],
        246113: ['Dehradun', 'Uttarakhand'],
        246121: ['Dehradun', 'Uttarakhand'],
        246123: ['Dehradun', 'Uttarakhand'],
        246124: ['Dehradun', 'Uttarakhand'],
        246125: ['Dehradun', 'Uttarakhand'],
        246127: ['Dehradun', 'Uttarakhand'],
        246128: ['Dehradun', 'Uttarakhand'],
        246129: ['Dehradun', 'Uttarakhand'],
        246130: ['Dehradun', 'Uttarakhand'],
        246131: ['Dehradun', 'Uttarakhand'],
        246141: ['Dehradun', 'Uttarakhand'],
        246142: ['Dehradun', 'Uttarakhand'],
        246144: ['Dehradun', 'Uttarakhand'],
        246146: ['Dehradun', 'Uttarakhand'],
        246147: ['Dehradun', 'Uttarakhand'],
        246148: ['Dehradun', 'Uttarakhand'],
        246149: ['Dehradun', 'Uttarakhand'],
        246155: ['Dehradun', 'Uttarakhand'],
        246159: ['Dehradun', 'Uttarakhand'],
        246161: ['Dehradun', 'Uttarakhand'],
        246162: ['Dehradun', 'Uttarakhand'],
        246163: ['Dehradun', 'Uttarakhand'],
        246164: ['Dehradun', 'Uttarakhand'],
        246165: ['Dehradun', 'Uttarakhand'],
        246166: ['Dehradun', 'Uttarakhand'],
        246167: ['Dehradun', 'Uttarakhand'],
        246169: ['Dehradun', 'Uttarakhand'],
        246171: ['Dehradun', 'Uttarakhand'],
        246172: ['Dehradun', 'Uttarakhand'],
        246173: ['Dehradun', 'Uttarakhand'],
        246174: ['Dehradun', 'Uttarakhand'],
        246175: ['Dehradun', 'Uttarakhand'],
        246176: ['Dehradun', 'Uttarakhand'],
        246177: ['Dehradun', 'Uttarakhand'],
        246179: ['Dehradun', 'Uttarakhand'],
        246193: ['Dehradun', 'Uttarakhand'],
        246194: ['Dehradun', 'Uttarakhand'],
        246275: ['Dehradun', 'Uttarakhand'],
        246276: ['Dehradun', 'Uttarakhand'],
        246277: ['Dehradun', 'Uttarakhand'],
        246278: ['Dehradun', 'Uttarakhand'],
        246279: ['Dehradun', 'Uttarakhand'],
        246285: ['Dehradun', 'Uttarakhand'],
        246401: ['Dehradun', 'Uttarakhand'],
        246419: ['Dehradun', 'Uttarakhand'],
        246421: ['Dehradun', 'Uttarakhand'],
        246422: ['Dehradun', 'Uttarakhand'],
        246424: ['Dehradun', 'Uttarakhand'],
        246425: ['Dehradun', 'Uttarakhand'],
        246426: ['Dehradun', 'Uttarakhand'],
        246427: ['Dehradun', 'Uttarakhand'],
        246428: ['Dehradun', 'Uttarakhand'],
        246429: ['Dehradun', 'Uttarakhand'],
        246431: ['Dehradun', 'Uttarakhand'],
        246435: ['Dehradun', 'Uttarakhand'],
        246439: ['Dehradun', 'Uttarakhand'],
        246440: ['Dehradun', 'Uttarakhand'],
        246441: ['Dehradun', 'Uttarakhand'],
        246442: ['Dehradun', 'Uttarakhand'],
        246443: ['Dehradun', 'Uttarakhand'],
        246444: ['Dehradun', 'Uttarakhand'],
        246445: ['Dehradun', 'Uttarakhand'],
        246446: ['Dehradun', 'Uttarakhand'],
        246448: ['Dehradun', 'Uttarakhand'],
        246449: ['Dehradun', 'Uttarakhand'],
        246453: ['Dehradun', 'Uttarakhand'],
        246455: ['Dehradun', 'Uttarakhand'],
        246469: ['Dehradun', 'Uttarakhand'],
        246471: ['Dehradun', 'Uttarakhand'],
        246472: ['Dehradun', 'Uttarakhand'],
        246473: ['Dehradun', 'Uttarakhand'],
        246474: ['Dehradun', 'Uttarakhand'],
        246475: ['Dehradun', 'Uttarakhand'],
        246481: ['Dehradun', 'Uttarakhand'],
        246482: ['Dehradun', 'Uttarakhand'],
        246483: ['Dehradun', 'Uttarakhand'],
        246486: ['Dehradun', 'Uttarakhand'],
        246487: ['Dehradun', 'Uttarakhand'],
        246488: ['Dehradun', 'Uttarakhand'],
        246495: ['Dehradun', 'Uttarakhand'],
        247656: ['Dehradun', 'Uttarakhand'],
        247661: ['Haridwar', 'Uttarakhand'],
        247663: ['Haridwar', 'Uttarakhand'],
        247664: ['Haridwar', 'Uttarakhand'],
        247665: ['Haridwar', 'Uttarakhand'],
        247666: ['Dehradun', 'Uttarakhand'],
        247667: ['Haridwar', 'Uttarakhand'],
        247668: ['Haridwar', 'Uttarakhand'],
        247670: ['Dehradun', 'Uttarakhand'],
        247671: ['Haridwar', 'Uttarakhand'],
        248001: ['Dehradun', 'Uttarakhand'],
        248002: ['Dehradun', 'Uttarakhand'],
        248003: ['Dehradun', 'Uttarakhand'],
        248005: ['Dehradun', 'Uttarakhand'],
        248006: ['Dehradun', 'Uttarakhand'],
        248007: ['Dehradun', 'Uttarakhand'],
        248008: ['Dehradun', 'Uttarakhand'],
        248009: ['Dehradun', 'Uttarakhand'],
        248011: ['Dehradun', 'Uttarakhand'],
        248012: ['Dehradun', 'Uttarakhand'],
        248013: ['Dehradun', 'Uttarakhand'],
        248014: ['Dehradun', 'Uttarakhand'],
        248015: ['Dehradun', 'Uttarakhand'],
        248102: ['Dehradun', 'Uttar Pradesh'],
        248110: ['Dehradun', 'Uttar Pradesh'],
        248115: ['Dehradun', 'Uttar Pradesh'],
        248116: ['Dehradun', 'Uttar Pradesh'],
        248119: ['Dehradun', 'Uttar Pradesh'],
        248121: ['Dehradun', 'Uttarakhand'],
        248122: ['Dehradun', 'Uttarakhand'],
        248123: ['Dehradun', 'Uttarakhand'],
        248124: ['Dehradun', 'Uttarakhand'],
        248125: ['Dehradun', 'Uttarakhand'],
        248140: ['Dehradun', 'Uttarakhand'],
        248141: ['Dehradun', 'Uttarakhand'],
        248142: ['Dehradun', 'Uttarakhand'],
        248143: ['Dehradun', 'Uttarakhand'],
        248144: ['Dehradun', 'Uttar Pradesh'],
        248145: ['Dehradun', 'Uttarakhand'],
        248146: ['Dehradun', 'Uttarakhand'],
        248147: ['Dehradun', 'Uttar Pradesh'],
        248148: ['Dehradun', 'Uttar Pradesh'],
        248152: ['Dehradun', 'Uttar Pradesh'],
        248158: ['Dehradun', 'Uttarakhand'],
        248159: ['Dehradun', 'Uttarakhand'],
        248160: ['Dehradun', 'Uttar Pradesh'],
        248161: ['Dehradun', 'Uttar Pradesh'],
        248164: ['Dehradun', 'Uttar Pradesh'],
        248165: ['Dehradun', 'Uttarakhand'],
        248171: ['Dehradun', 'Uttarakhand'],
        248179: ['Dehradun', 'Uttarakhand'],
        248195: ['Dehradun', 'Uttarakhand'],
        248196: ['Dehradun', 'Uttarakhand'],
        248197: ['Dehradun', 'Uttarakhand'],
        248198: ['Dehradun', 'Uttarakhand'],
        248199: ['Dehradun', 'Uttarakhand'],
        249001: ['Dehradun', 'Uttarakhand'],
        249121: ['Dehradun', 'Uttarakhand'],
        249122: ['Dehradun', 'Uttarakhand'],
        249123: ['Dehradun', 'Uttarakhand'],
        249124: ['Dehradun', 'Uttarakhand'],
        249125: ['Dehradun', 'Uttarakhand'],
        249126: ['Dehradun', 'Uttarakhand'],
        249128: ['Dehradun', 'Uttarakhand'],
        249130: ['Dehradun', 'Uttarakhand'],
        249131: ['Dehradun', 'Uttarakhand'],
        249132: ['Dehradun', 'Uttarakhand'],
        249135: ['Dehradun', 'Uttarakhand'],
        249137: ['Dehradun', 'Uttarakhand'],
        249141: ['Dehradun', 'Uttarakhand'],
        249145: ['Dehradun', 'Uttarakhand'],
        249146: ['Dehradun', 'Uttarakhand'],
        249151: ['Dehradun', 'Uttarakhand'],
        249152: ['Dehradun', 'Uttarakhand'],
        249155: ['Dehradun', 'Uttarakhand'],
        249161: ['Dehradun', 'Uttarakhand'],
        249165: ['Dehradun', 'Uttarakhand'],
        249171: ['Dehradun', 'Uttarakhand'],
        249175: ['Dehradun', 'Uttarakhand'],
        249180: ['Dehradun', 'Uttarakhand'],
        249181: ['Dehradun', 'Uttarakhand'],
        249185: ['Dehradun', 'Uttarakhand'],
        249186: ['Dehradun', 'Uttarakhand'],
        249192: ['Dehradun', 'Uttarakhand'],
        249193: ['Dehradun', 'Uttarakhand'],
        249194: ['Dehradun', 'Uttarakhand'],
        249195: ['Dehradun', 'Uttarakhand'],
        249196: ['Dehradun', 'Uttarakhand'],
        249199: ['Dehradun', 'Uttarakhand'],
        249201: ['Dehradun', 'Uttarakhand'],
        249202: ['Dehradun', 'Uttarakhand'],
        249203: ['Dehradun', 'Uttarakhand'],
        249204: ['Dehradun', 'Uttarakhand'],
        249205: ['Dehradun', 'Uttarakhand'],
        249301: ['Dehradun', 'Uttarakhand'],
        249302: ['Dehradun', 'Uttarakhand'],
        249304: ['Dehradun', 'Uttarakhand'],
        249306: ['Dehradun', 'Uttarakhand'],
        249401: ['Haridwar', 'Uttarakhand'],
        249402: ['Haridwar', 'Uttarakhand'],
        249403: ['Haridwar', 'Uttarakhand'],
        249404: ['Haridwar', 'Uttarakhand'],
        249405: ['Haridwar', 'Uttarakhand'],
        249407: ['Haridwar', 'Uttarakhand'],
        249408: ['Haridwar', 'Uttarakhand'],
        249410: ['Haridwar', 'Uttarakhand'],
        249411: ['Dehradun', 'Uttarakhand'],
        262308: ['Dehradun', 'Uttarakhand'],
        262309: ['Dehradun', 'Uttarakhand'],
        262310: ['Dehradun', 'Uttarakhand'],
        262311: ['Dehradun', 'Uttarakhand'],
        262401: ['Dehradun', 'Uttarakhand'],
        262402: ['Dehradun', 'Uttarakhand'],
        262405: ['Dehradun', 'Uttarakhand'],
        262501: ['Dehradun', 'Uttarakhand'],
        262502: ['Dehradun', 'Uttarakhand'],
        262520: ['Dehradun', 'Uttarakhand'],
        262521: ['Dehradun', 'Uttarakhand'],
        262522: ['Dehradun', 'Uttarakhand'],
        262523: ['Dehradun', 'Uttarakhand'],
        262524: ['Dehradun', 'Uttarakhand'],
        262525: ['Dehradun', 'Uttarakhand'],
        262526: ['Dehradun', 'Uttarakhand'],
        262527: ['Dehradun', 'Uttarakhand'],
        262528: ['Dehradun', 'Uttarakhand'],
        262529: ['Dehradun', 'Uttarakhand'],
        262530: ['Dehradun', 'Uttarakhand'],
        262531: ['Dehradun', 'Uttarakhand'],
        262532: ['Dehradun', 'Uttarakhand'],
        262533: ['Dehradun', 'Uttarakhand'],
        262534: ['Dehradun', 'Uttarakhand'],
        262540: ['Dehradun', 'Uttarakhand'],
        262541: ['Dehradun', 'Uttarakhand'],
        262542: ['Dehradun', 'Uttarakhand'],
        262543: ['Dehradun', 'Uttarakhand'],
        262544: ['Dehradun', 'Uttarakhand'],
        262545: ['Dehradun', 'Uttarakhand'],
        262546: ['Dehradun', 'Uttarakhand'],
        262547: ['Dehradun', 'Uttarakhand'],
        262550: ['Dehradun', 'Uttarakhand'],
        262551: ['Dehradun', 'Uttarakhand'],
        262552: ['Dehradun', 'Uttarakhand'],
        262553: ['Dehradun', 'Uttarakhand'],
        262554: ['Dehradun', 'Uttarakhand'],
        262555: ['Dehradun', 'Uttarakhand'],
        262561: ['Dehradun', 'Uttarakhand'],
        262576: ['Dehradun', 'Uttarakhand'],
        262580: ['Dehradun', 'Uttarakhand'],
        263001: ['Dehradun', 'Uttarakhand'],
        263126: ['Dehradun', 'Uttarakhand'],
        263127: ['Dehradun', 'Uttarakhand'],
        263128: ['Dehradun', 'Uttarakhand'],
        263132: ['Dehradun', 'Uttarakhand'],
        263134: ['Dehradun', 'Uttarakhand'],
        263135: ['Dehradun', 'Uttarakhand'],
        263136: ['Dehradun', 'Uttarakhand'],
        263137: ['Dehradun', 'Uttarakhand'],
        263138: ['Dehradun', 'Uttarakhand'],
        263139: ['Dehradun', 'Uttarakhand'],
        263140: ['Dehradun', 'Uttarakhand'],
        263145: ['Dehradun', 'Uttarakhand'],
        263148: ['Dehradun', 'Uttarakhand'],
        263149: ['Dehradun', 'Uttarakhand'],
        263150: ['Dehradun', 'Uttarakhand'],
        263151: ['Dehradun', 'Uttarakhand'],
        263152: ['Dehradun', 'Uttarakhand'],
        263153: ['Rudrapur', 'Uttarakhand'],
        263154: ['Rudrapur', 'Uttarakhand'],
        263156: ['Dehradun', 'Uttarakhand'],
        263157: ['Dehradun', 'Uttarakhand'],
        263158: ['Dehradun', 'Uttarakhand'],
        263159: ['Dehradun', 'Uttarakhand'],
        263160: ['Dehradun', 'Uttarakhand'],
        263601: ['Dehradun', 'Uttarakhand'],
        263619: ['Dehradun', 'Uttarakhand'],
        263620: ['Dehradun', 'Uttarakhand'],
        263621: ['Dehradun', 'Uttarakhand'],
        263622: ['Dehradun', 'Uttarakhand'],
        263623: ['Dehradun', 'Uttarakhand'],
        263624: ['Dehradun', 'Uttarakhand'],
        263625: ['Dehradun', 'Uttarakhand'],
        263626: ['Dehradun', 'Uttarakhand'],
        263628: ['Dehradun', 'Uttarakhand'],
        263629: ['Dehradun', 'Uttarakhand'],
        263630: ['Dehradun', 'Uttarakhand'],
        263631: ['Dehradun', 'Uttarakhand'],
        263632: ['Dehradun', 'Uttarakhand'],
        263633: ['Dehradun', 'Uttarakhand'],
        263634: ['Dehradun', 'Uttarakhand'],
        263635: ['Dehradun', 'Uttarakhand'],
        263636: ['Dehradun', 'Uttarakhand'],
        263637: ['Dehradun', 'Uttarakhand'],
        263638: ['Dehradun', 'Uttarakhand'],
        263639: ['Dehradun', 'Uttarakhand'],
        263640: ['Dehradun', 'Uttarakhand'],
        263641: ['Dehradun', 'Uttarakhand'],
        263642: ['Dehradun', 'Uttarakhand'],
        263643: ['Dehradun', 'Uttarakhand'],
        263645: ['Dehradun', 'Uttarakhand'],
        263646: ['Dehradun', 'Uttarakhand'],
        263651: ['Dehradun', 'Uttarakhand'],
        263652: ['Dehradun', 'Uttarakhand'],
        263653: ['Dehradun', 'Uttarakhand'],
        263655: ['Dehradun', 'Uttarakhand'],
        263656: ['Dehradun', 'Uttarakhand'],
        263658: ['Dehradun', 'Uttarakhand'],
        263659: ['Dehradun', 'Uttarakhand'],
        263660: ['Dehradun', 'Uttarakhand'],
        263661: ['Dehradun', 'Uttarakhand'],
        263663: ['Dehradun', 'Uttarakhand'],
        263664: ['Dehradun', 'Uttarakhand'],
        263665: ['Dehradun', 'Uttarakhand'],
        263667: ['Dehradun', 'Uttarakhand'],
        263676: ['Dehradun', 'Uttarakhand'],
        263678: ['Dehradun', 'Uttarakhand'],
        263679: ['Dehradun', 'Uttarakhand'],
        263680: ['Dehradun', 'Uttarakhand'],
        274204: ['Rudrapur', 'Uttarakhand'],
        281001: ['Mathura', 'Uttar Pradesh'],
        281003: ['Mathura', 'Uttar Pradesh'],
        281004: ['Mathura', 'Uttar Pradesh'],
        281005: ['Mathura', 'Uttar Pradesh'],
        281006: ['Mathura', 'Uttar Pradesh'],
        281112: ['Mathura', 'Uttar Pradesh'],
        281121: ['Mathura', 'Uttar Pradesh'],
        281122: ['Mathura', 'Uttar Pradesh'],
        281123: ['Mathura', 'Uttar Pradesh'],
        281201: ['Mathura', 'Uttar Pradesh'],
        281202: ['Mathura', 'Uttar Pradesh'],
        281203: ['Mathura', 'Uttar Pradesh'],
        281204: ['Mathura', 'Uttar Pradesh'],
        281205: ['Mathura', 'Uttar Pradesh'],
        281206: ['Mathura', 'Uttar Pradesh'],
        281301: ['Mathura', 'Uttar Pradesh'],
        281302: ['Mathura', 'Uttar Pradesh'],
        281303: ['Mathura', 'Uttar Pradesh'],
        281304: ['Mathura', 'Uttar Pradesh'],
        281305: ['Mathura', 'Uttar Pradesh'],
        281306: ['Mathura', 'Uttar Pradesh'],
        281307: ['Mathura', 'Uttar Pradesh'],
        281308: ['Mathura', 'Uttar Pradesh'],
        281401: ['Mathura', 'Uttar Pradesh'],
        281403: ['Mathura', 'Uttar Pradesh'],
        281404: ['Mathura', 'Uttar Pradesh'],
        281405: ['Mathura', 'Uttar Pradesh'],
        281406: ['Mathura', 'Uttar Pradesh'],
        281501: ['Mathura', 'Uttar Pradesh'],
        281502: ['Mathura', 'Uttar Pradesh'],
        281504: ['Mathura', 'Uttar Pradesh'],
        282001: ['Agra', 'Uttar Pradesh'],
        282002: ['Agra', 'Uttar Pradesh'],
        282003: ['Agra', 'Uttar Pradesh'],
        282004: ['Agra', 'Uttar Pradesh'],
        282005: ['Agra', 'Uttar Pradesh'],
        282006: ['Agra', 'Uttar Pradesh'],
        282007: ['Agra', 'Uttar Pradesh'],
        282008: ['Agra', 'Uttar Pradesh'],
        282009: ['Agra', 'Uttar Pradesh'],
        282010: ['Agra', 'Uttar Pradesh'],
        283101: ['Agra', 'Uttar Pradesh'],
        283102: ['Agra', 'Uttar Pradesh'],
        283103: ['Agra', 'Uttar Pradesh'],
        283104: ['Agra', 'Uttar Pradesh'],
        283105: ['Agra', 'Uttar Pradesh'],
        283110: ['Agra', 'Uttar Pradesh'],
        283111: ['Agra', 'Uttar Pradesh'],
        283112: ['Agra', 'Uttar Pradesh'],
        283113: ['Agra', 'Uttar Pradesh'],
        283114: ['Agra', 'Uttar Pradesh'],
        283115: ['Agra', 'Uttar Pradesh'],
        283119: ['Agra', 'Uttar Pradesh'],
        283121: ['Agra', 'Uttar Pradesh'],
        283122: ['Agra', 'Uttar Pradesh'],
        283123: ['Agra', 'Uttar Pradesh'],
        283124: ['Agra', 'Uttar Pradesh'],
        283125: ['Agra', 'Uttar Pradesh'],
        283126: ['Agra', 'Uttar Pradesh'],
        283201: ['Agra', 'Uttar Pradesh'],
        283202: ['Agra', 'Uttar Pradesh'],
        283203: ['Agra', 'Uttar Pradesh'],
        283204: ['Agra', 'Uttar Pradesh'],
        302001: ['Jaipur', 'Rajasthan'],
        302002: ['Jaipur', 'Rajasthan'],
        302003: ['Jaipur', 'Rajasthan'],
        302004: ['Jaipur', 'Rajasthan'],
        302005: ['Jaipur', 'Rajasthan'],
        302006: ['Jaipur', 'Rajasthan'],
        302010: ['Jaipur', 'Rajasthan'],
        302011: ['Jaipur', 'Rajasthan'],
        302012: ['Jaipur', 'Rajasthan'],
        302013: ['Jaipur', 'Rajasthan'],
        302014: ['Jaipur', 'Rajasthan'],
        302015: ['Jaipur', 'Rajasthan'],
        302016: ['Jaipur', 'Rajasthan'],
        302017: ['Jaipur', 'Rajasthan'],
        302018: ['Jaipur', 'Rajasthan'],
        302019: ['Jaipur', 'Rajasthan'],
        302020: ['Jaipur', 'Rajasthan'],
        302021: ['Jaipur', 'Rajasthan'],
        302022: ['Jaipur', 'Rajasthan'],
        302026: ['Jaipur', 'Rajasthan'],
        302027: ['Jaipur', 'Rajasthan'],
        302028: ['Jaipur', 'Rajasthan'],
        302029: ['Jaipur', 'Rajasthan'],
        302031: ['Jaipur', 'Rajasthan'],
        302033: ['Jaipur', 'Rajasthan'],
        302034: ['Jaipur', 'Rajasthan'],
        302036: ['Jaipur', 'Rajasthan'],
        302037: ['Jaipur', 'Rajasthan'],
        302038: ['Jaipur', 'Rajasthan'],
        302039: ['Jaipur', 'Rajasthan'],
        302040: ['Jaipur', 'Rajasthan'],
        302041: ['Jaipur', 'Rajasthan'],
        302042: ['Jaipur', 'Rajasthan'],
        302043: ['Jaipur', 'Rajasthan'],
        303001: ['Jaipur', 'Rajasthan'],
        303002: ['Jaipur', 'Rajasthan'],
        303003: ['Jaipur', 'Rajasthan'],
        303004: ['Jaipur', 'Rajasthan'],
        303005: ['Jaipur', 'Rajasthan'],
        303006: ['Jaipur', 'Rajasthan'],
        303007: ['Jaipur', 'Rajasthan'],
        303008: ['Jaipur', 'Rajasthan'],
        303009: ['Jaipur', 'Rajasthan'],
        303010: ['Jaipur', 'Rajasthan'],
        303011: ['Jaipur', 'Rajasthan'],
        303012: ['Jaipur', 'Rajasthan'],
        303101: ['Jaipur', 'Rajasthan'],
        303102: ['Jaipur', 'Rajasthan'],
        303103: ['Jaipur', 'Rajasthan'],
        303104: ['Jaipur', 'Rajasthan'],
        303105: ['Jaipur', 'Rajasthan'],
        303106: ['Jaipur', 'Rajasthan'],
        303107: ['Jaipur', 'Rajasthan'],
        303108: ['Jaipur', 'Rajasthan'],
        303109: ['Jaipur', 'Rajasthan'],
        303110: ['Jaipur', 'Rajasthan'],
        303119: ['Jaipur', 'Rajasthan'],
        303120: ['Jaipur', 'Rajasthan'],
        303121: ['Jaipur', 'Rajasthan'],
        303122: ['Jaipur', 'Rajasthan'],
        303123: ['Jaipur', 'Rajasthan'],
        303301: ['Jaipur', 'Rajasthan'],
        303302: ['Jaipur', 'Rajasthan'],
        303303: ['Jaipur', 'Rajasthan'],
        303304: ['Jaipur', 'Rajasthan'],
        303305: ['Jaipur', 'Rajasthan'],
        303313: ['Jaipur', 'Rajasthan'],
        303315: ['Jaipur', 'Rajasthan'],
        303323: ['Jaipur', 'Rajasthan'],
        303325: ['Jaipur', 'Rajasthan'],
        303326: ['Jaipur', 'Rajasthan'],
        303327: ['Jaipur', 'Rajasthan'],
        303328: ['Jaipur', 'Rajasthan'],
        303329: ['Jaipur', 'Rajasthan'],
        303331: ['Jaipur', 'Rajasthan'],
        303338: ['Jaipur', 'Rajasthan'],
        303339: ['Jaipur', 'Rajasthan'],
        303348: ['Jaipur', 'Rajasthan'],
        303501: ['Jaipur', 'Rajasthan'],
        303504: ['Jaipur', 'Rajasthan'],
        303505: ['Jaipur', 'Rajasthan'],
        303506: ['Jaipur', 'Rajasthan'],
        303507: ['Jaipur', 'Rajasthan'],
        303508: ['Jaipur', 'Rajasthan'],
        303509: ['Jaipur', 'Rajasthan'],
        303510: ['Jaipur', 'Rajasthan'],
        303601: ['Jaipur', 'Rajasthan'],
        303602: ['Jaipur', 'Rajasthan'],
        303603: ['Jaipur', 'Rajasthan'],
        303604: ['Jaipur', 'Rajasthan'],
        303701: ['Jaipur', 'Rajasthan'],
        303702: ['Jaipur', 'Rajasthan'],
        303704: ['Jaipur', 'Rajasthan'],
        303706: ['Jaipur', 'Rajasthan'],
        303712: ['Jaipur', 'Rajasthan'],
        303801: ['Jaipur', 'Rajasthan'],
        303803: ['Jaipur', 'Rajasthan'],
        303804: ['Jaipur', 'Rajasthan'],
        303805: ['Jaipur', 'Rajasthan'],
        303806: ['Jaipur', 'Rajasthan'],
        303807: ['Jaipur', 'Rajasthan'],
        303901: ['Jaipur', 'Rajasthan'],
        303902: ['Jaipur', 'Rajasthan'],
        303903: ['Jaipur', 'Rajasthan'],
        303904: ['Jaipur', 'Rajasthan'],
        303905: ['Jaipur', 'Rajasthan'],
        303908: ['Jaipur', 'Rajasthan'],
        305921: ['Udaipur', 'Rajasthan'],
        307025: ['Udaipur', 'Rajasthan'],
        313001: ['Udaipur', 'Rajasthan'],
        313002: ['Udaipur', 'Rajasthan'],
        313003: ['Udaipur', 'Rajasthan'],
        313004: ['Udaipur', 'Rajasthan'],
        313011: ['Udaipur', 'Rajasthan'],
        313015: ['Udaipur', 'Rajasthan'],
        313021: ['Udaipur', 'Rajasthan'],
        313022: ['Udaipur', 'Rajasthan'],
        313023: ['Udaipur', 'Rajasthan'],
        313024: ['Udaipur', 'Rajasthan'],
        313025: ['Udaipur', 'Rajasthan'],
        313026: ['Udaipur', 'Rajasthan'],
        313027: ['Udaipur', 'Rajasthan'],
        313031: ['Udaipur', 'Rajasthan'],
        313038: ['Udaipur', 'Rajasthan'],
        313201: ['Udaipur', 'Rajasthan'],
        313202: ['Udaipur', 'Rajasthan'],
        313203: ['Udaipur', 'Rajasthan'],
        313204: ['Udaipur', 'Rajasthan'],
        313205: ['Udaipur', 'Rajasthan'],
        313206: ['Udaipur', 'Rajasthan'],
        313207: ['Udaipur', 'Rajasthan'],
        313211: ['Udaipur', 'Rajasthan'],
        313301: ['Udaipur', 'Rajasthan'],
        313321: ['Udaipur', 'Rajasthan'],
        313322: ['Udaipur', 'Rajasthan'],
        313323: ['Udaipur', 'Rajasthan'],
        313324: ['Udaipur', 'Rajasthan'],
        313325: ['Udaipur', 'Rajasthan'],
        313326: ['Udaipur', 'Rajasthan'],
        313327: ['Udaipur', 'Rajasthan'],
        313328: ['Udaipur', 'Rajasthan'],
        313329: ['Udaipur', 'Rajasthan'],
        313330: ['Udaipur', 'Rajasthan'],
        313331: ['Udaipur', 'Rajasthan'],
        313332: ['Udaipur', 'Rajasthan'],
        313333: ['Udaipur', 'Rajasthan'],
        313337: ['Udaipur', 'Rajasthan'],
        313338: ['Udaipur', 'Rajasthan'],
        313341: ['Udaipur', 'Rajasthan'],
        313342: ['Udaipur', 'Rajasthan'],
        313601: ['Udaipur', 'Rajasthan'],
        313602: ['Udaipur', 'Rajasthan'],
        313603: ['Udaipur', 'Rajasthan'],
        313604: ['Udaipur', 'Rajasthan'],
        313605: ['Udaipur', 'Rajasthan'],
        313611: ['Udaipur', 'Rajasthan'],
        313701: ['Udaipur', 'Rajasthan'],
        313702: ['Udaipur', 'Rajasthan'],
        313703: ['Udaipur', 'Rajasthan'],
        313704: ['Udaipur', 'Rajasthan'],
        313705: ['Udaipur', 'Rajasthan'],
        313706: ['Udaipur', 'Rajasthan'],
        313708: ['Udaipur', 'Rajasthan'],
        313801: ['Udaipur', 'Rajasthan'],
        313802: ['Udaipur', 'Rajasthan'],
        313803: ['Udaipur', 'Rajasthan'],
        313804: ['Udaipur', 'Rajasthan'],
        313901: ['Udaipur', 'Rajasthan'],
        313902: ['Udaipur', 'Rajasthan'],
        313903: ['Udaipur', 'Rajasthan'],
        313904: ['Udaipur', 'Rajasthan'],
        313905: ['Udaipur', 'Rajasthan'],
        313906: ['Udaipur', 'Rajasthan'],
        313907: ['Udaipur', 'Rajasthan'],
        324001: ['Kota', 'Rajasthan'],
        324002: ['Kota', 'Rajasthan'],
        324003: ['Kota', 'Rajasthan'],
        324004: ['Kota', 'Rajasthan'],
        324005: ['Kota', 'Rajasthan'],
        324006: ['Kota', 'Rajasthan'],
        324007: ['Kota', 'Rajasthan'],
        324008: ['Kota', 'Rajasthan'],
        324009: ['Kota', 'Rajasthan'],
        324010: ['Kota', 'Rajasthan'],
        325001: ['Kota', 'Rajasthan'],
        325003: ['Kota', 'Rajasthan'],
        325004: ['Kota', 'Rajasthan'],
        325009: ['Kota', 'Rajasthan'],
        325201: ['Kota', 'Rajasthan'],
        325202: ['Kota', 'Rajasthan'],
        325203: ['Kota', 'Rajasthan'],
        325204: ['Kota', 'Rajasthan'],
        325205: ['Kota', 'Rajasthan'],
        325207: ['Kota', 'Rajasthan'],
        325208: ['Kota', 'Rajasthan'],
        325214: ['Kota', 'Rajasthan'],
        325215: ['Kota', 'Rajasthan'],
        325216: ['Kota', 'Rajasthan'],
        325217: ['Kota', 'Rajasthan'],
        325218: ['Kota', 'Rajasthan'],
        325220: ['Kota', 'Rajasthan'],
        325221: ['Kota', 'Rajasthan'],
        325222: ['Kota', 'Rajasthan'],
        325223: ['Kota', 'Rajasthan'],
        325225: ['Kota', 'Rajasthan'],
        325601: ['Kota', 'Rajasthan'],
        325602: ['Kota', 'Rajasthan'],
        326038: ['Kota', 'Rajasthan'],
        326517: ['Kota', 'Rajasthan'],
        326518: ['Kota', 'Rajasthan'],
        326519: ['Kota', 'Rajasthan'],
        326520: ['Kota', 'Rajasthan'],
        326529: ['Kota', 'Rajasthan'],
        326530: ['Kota', 'Rajasthan'],
        333502: ['Surat', 'Gujarat'],
        360001: ['Rajkot', 'Gujarat'],
        360002: ['Rajkot', 'Gujarat'],
        360003: ['Rajkot', 'Gujarat'],
        360004: ['Rajkot', 'Gujarat'],
        360005: ['Rajkot', 'Gujarat'],
        360006: ['Rajkot', 'Gujarat'],
        360007: ['Rajkot', 'Gujarat'],
        360020: ['Rajkot', 'Gujarat'],
        360021: ['Rajkot', 'Gujarat'],
        360022: ['Rajkot', 'Gujarat'],
        360023: ['Rajkot', 'Gujarat'],
        360024: ['Rajkot', 'Gujarat'],
        360025: ['Rajkot', 'Gujarat'],
        360026: ['Rajkot', 'Gujarat'],
        360030: ['Rajkot', 'Gujarat'],
        360035: ['Rajkot', 'Gujarat'],
        360040: ['Rajkot', 'Gujarat'],
        360050: ['Rajkot', 'Gujarat'],
        360055: ['Rajkot', 'Gujarat'],
        360060: ['Rajkot', 'Gujarat'],
        360070: ['Rajkot', 'Gujarat'],
        360080: ['Rajkot', 'Gujarat'],
        360110: ['Rajkot', 'Gujarat'],
        360311: ['Rajkot', 'Gujarat'],
        360320: ['Rajkot', 'Gujarat'],
        360330: ['Rajkot', 'Gujarat'],
        360335: ['Rajkot', 'Gujarat'],
        360360: ['Rajkot', 'Gujarat'],
        360370: ['Rajkot', 'Gujarat'],
        360375: ['Rajkot', 'Gujarat'],
        360380: ['Rajkot', 'Gujarat'],
        360405: ['Rajkot', 'Gujarat'],
        360410: ['Rajkot', 'Gujarat'],
        360411: ['Rajkot', 'Gujarat'],
        360421: ['Rajkot', 'Gujarat'],
        360430: ['Rajkot', 'Gujarat'],
        360440: ['Rajkot', 'Gujarat'],
        360450: ['Rajkot', 'Gujarat'],
        360452: ['Rajkot', 'Gujarat'],
        360460: ['Rajkot', 'Gujarat'],
        360470: ['Rajkot', 'Gujarat'],
        360480: ['Jamnagar', 'Gujarat'],
        360490: ['Rajkot', 'Gujarat'],
        360510: ['Jamnagar', 'Gujarat'],
        360515: ['Jamnagar', 'Gujarat'],
        360520: ['Jamnagar', 'Gujarat'],
        360530: ['Jamnagar', 'Gujarat'],
        360531: ['Jamnagar', 'Gujarat'],
        360540: ['Jamnagar', 'Gujarat'],
        361001: ['Jamnagar', 'Gujarat'],
        361002: ['Jamnagar', 'Gujarat'],
        361003: ['Jamnagar', 'Gujarat'],
        361004: ['Jamnagar', 'Gujarat'],
        361005: ['Jamnagar', 'Gujarat'],
        361006: ['Jamnagar', 'Gujarat'],
        361007: ['Jamnagar', 'Gujarat'],
        361008: ['Jamnagar', 'Gujarat'],
        361009: ['Jamnagar', 'Gujarat'],
        361010: ['Jamnagar', 'Gujarat'],
        361011: ['Jamnagar', 'Gujarat'],
        361012: ['Jamnagar', 'Gujarat'],
        361013: ['Jamnagar', 'Gujarat'],
        361110: ['Jamnagar', 'Gujarat'],
        361120: ['Jamnagar', 'Gujarat'],
        361130: ['Jamnagar', 'Gujarat'],
        361140: ['Jamnagar', 'Gujarat'],
        361141: ['Jamnagar', 'Gujarat'],
        361142: ['Jamnagar', 'Gujarat'],
        361150: ['Jamnagar', 'Gujarat'],
        361160: ['Jamnagar', 'Gujarat'],
        361162: ['Jamnagar', 'Gujarat'],
        361170: ['Jamnagar', 'Gujarat'],
        361210: ['Jamnagar', 'Gujarat'],
        361220: ['Jamnagar', 'Gujarat'],
        361230: ['Jamnagar', 'Gujarat'],
        361240: ['Jamnagar', 'Gujarat'],
        361250: ['Jamnagar', 'Gujarat'],
        361280: ['Jamnagar', 'Gujarat'],
        361305: ['Jamnagar', 'Gujarat'],
        361306: ['Jamnagar', 'Gujarat'],
        361310: ['Jamnagar', 'Gujarat'],
        361315: ['Jamnagar', 'Gujarat'],
        361320: ['Jamnagar', 'Gujarat'],
        361325: ['Jamnagar', 'Gujarat'],
        361330: ['Jamnagar', 'Gujarat'],
        361335: ['Jamnagar', 'Gujarat'],
        361336: ['Jamnagar', 'Gujarat'],
        361345: ['Jamnagar', 'Gujarat'],
        361347: ['Jamnagar', 'Gujarat'],
        361350: ['Jamnagar', 'Gujarat'],
        362530: ['Kota', 'Gujarat'],
        362720: ['Junagadh', 'Gujarat'],
        363331: ['Rajkot', 'Gujarat'],
        363610: ['Ahmedabad', 'Gujarat'],
        363621: ['Rajkot', 'Gujarat'],
        363622: ['Rajkot', 'Gujarat'],
        363630: ['Rajkot', 'Gujarat'],
        363635: ['Rajkot', 'Gujarat'],
        363636: ['Rajkot', 'Gujarat'],
        363641: ['Rajkot', 'Gujarat'],
        363642: ['Rajkot', 'Gujarat'],
        363643: ['Rajkot', 'Gujarat'],
        363650: ['Rajkot', 'Gujarat'],
        363655: ['Jamnagar', 'Gujarat'],
        363660: ['Rajkot', 'Gujarat'],
        363670: ['Rajkot', 'Gujarat'],
        364465: ['Rajkot', 'Gujarat'],
        364470: ['Rajkot', 'Gujarat'],
        364485: ['Rajkot', 'Gujarat'],
        364490: ['Rajkot', 'Gujarat'],
        380001: ['Ahmedabad', 'Gujarat'],
        380002: ['Ahmedabad', 'Gujarat'],
        380003: ['Ahmedabad', 'Gujarat'],
        380004: ['Ahmedabad', 'Gujarat'],
        380005: ['Ahmedabad', 'Gujarat'],
        380006: ['Ahmedabad', 'Gujarat'],
        380007: ['Ahmedabad', 'Gujarat'],
        380008: ['Ahmedabad', 'Gujarat'],
        380009: ['Ahmedabad', 'Gujarat'],
        380010: ['Ahmedabad', 'Gujarat'],
        380012: ['Ahmedabad', 'Gujarat'],
        380013: ['Ahmedabad', 'Gujarat'],
        380014: ['Ahmedabad', 'Gujarat'],
        380015: ['Ahmedabad', 'Gujarat'],
        380016: ['Ahmedabad', 'Gujarat'],
        380018: ['Ahmedabad', 'Gujarat'],
        380019: ['Ahmedabad', 'Gujarat'],
        380021: ['Ahmedabad', 'Gujarat'],
        380022: ['Ahmedabad', 'Gujarat'],
        380023: ['Ahmedabad', 'Gujarat'],
        380024: ['Ahmedabad', 'Gujarat'],
        380025: ['Ahmedabad', 'Gujarat'],
        380026: ['Ahmedabad', 'Gujarat'],
        380027: ['Ahmedabad', 'Gujarat'],
        380028: ['Ahmedabad', 'Gujarat'],
        380049: ['Ahmedabad', 'Gujarat'],
        380050: ['Ahmedabad', 'Gujarat'],
        380051: ['Ahmedabad', 'Gujarat'],
        380052: ['Ahmedabad', 'Gujarat'],
        380053: ['Ahmedabad', 'Gujarat'],
        380054: ['Ahmedabad', 'Gujarat'],
        380055: ['Ahmedabad', 'Gujarat'],
        380058: ['Ahmedabad', 'Gujarat'],
        380059: ['Ahmedabad', 'Gujarat'],
        380060: ['Ahmedabad', 'Gujarat'],
        380061: ['Ahmedabad', 'Gujarat'],
        380063: ['Ahmedabad', 'Gujarat'],
        382006: ['GandhiNagar', 'Gujarat'],
        382007: ['GandhiNagar', 'Gujarat'],
        382009: ['Gandhinagar', 'Gujarat'],
        382010: ['Gandhinagar', 'Gujarat'],
        382016: ['GandhiNagar', 'Gujarat'],
        382017: ['GandhiNagar', 'Gujarat'],
        382019: ['GandhiNagar', 'Gujarat'],
        382020: ['GandhiNagar', 'Gujarat'],
        382021: ['GandhiNagar', 'Gujarat'],
        382022: ['Gandhinagar', 'Gujarat'],
        382023: ['Gandhinagar', 'Gujarat'],
        382024: ['GandhiNagar', 'Gujarat'],
        382028: ['Gandhinagar', 'Gujarat'],
        382029: ['Gandhinagar', 'Gujarat'],
        382030: ['Gandhinagar', 'Gujarat'],
        382041: ['Gandhinagar', 'Gujarat'],
        382042: ['Gandhinagar', 'Gujarat'],
        382043: ['GandhiNagar', 'Gujarat'],
        382045: ['Gandhinagar', 'Gujarat'],
        382051: ['GandhiNagar', 'Gujarat'],
        382110: ['Ahmedabad', 'Gujarat'],
        382115: ['Ahmedabad', 'Gujarat'],
        382120: ['Ahmedabad', 'Gujarat'],
        382130: ['Ahmedabad', 'Gujarat'],
        382140: ['Ahmedabad', 'Gujarat'],
        382145: ['Ahmedabad', 'Gujarat'],
        382150: ['Ahmedabad', 'Gujarat'],
        382155: ['Ahmedabad', 'Gujarat'],
        382165: ['Ahmedabad', 'Gujarat'],
        382170: ['Ahmedabad', 'Gujarat'],
        382210: ['Ahmedabad', 'Gujarat'],
        382213: ['Ahmedabad', 'Gujarat'],
        382220: ['Ahmedabad', 'Gujarat'],
        382225: ['Ahmedabad', 'Gujarat'],
        382230: ['Ahmedabad', 'Gujarat'],
        382240: ['Ahmedabad', 'Gujarat'],
        382245: ['Ahmedabad', 'Gujarat'],
        382250: ['Ahmedabad', 'Gujarat'],
        382255: ['Ahmedabad', 'Gujarat'],
        382260: ['Ahmedabad', 'Gujarat'],
        382265: ['Ahmedabad', 'Gujarat'],
        382270: ['Ahmedabad', 'Gujarat'],
        382305: ['Gandhinagar', 'Gujarat'],
        382308: ['GandhiNagar', 'Gujarat'],
        382315: ['GandhiNagar', 'Gujarat'],
        382320: ['GandhiNagar', 'Gujarat'],
        382321: ['GandhiNagar', 'Gujarat'],
        382325: ['Ahmedabad', 'Gujarat'],
        382330: ['Ahmedabad', 'Gujarat'],
        382340: ['Ahmedabad', 'Gujarat'],
        382345: ['Ahmedabad', 'Gujarat'],
        382350: ['Ahmedabad', 'Gujarat'],
        382352: ['Ahmedabad', 'Gujarat'],
        382355: ['Gandhinagar', 'Gujarat'],
        382405: ['Ahmedabad', 'Gujarat'],
        382410: ['Ahmedabad', 'Gujarat'],
        382415: ['Ahmedabad', 'Gujarat'],
        382418: ['Ahmedabad', 'Gujarat'],
        382421: ['Ahmedabad', 'Gujarat'],
        382422: ['GandhiNagar', 'Gujarat'],
        382423: ['GandhiNagar', 'Gujarat'],
        382424: ['Ahmedabad', 'Gujarat'],
        382425: ['Ahmedabad', 'Gujarat'],
        382426: ['Ahmedabad', 'Gujarat'],
        382427: ['Ahmedabad', 'Gujarat'],
        382428: ['GandhiNagar', 'Gujarat'],
        382430: ['Ahmedabad', 'Gujarat'],
        382433: ['Ahmedabad', 'Gujarat'],
        382435: ['Ahmedabad', 'Gujarat'],
        382440: ['Ahmedabad', 'Gujarat'],
        382443: ['Ahmedabad', 'Gujarat'],
        382445: ['Ahmedabad', 'Gujarat'],
        382449: ['Ahmedabad', 'Gujarat'],
        382450: ['Ahmedabad', 'Gujarat'],
        382451: ['Ahmedabad', 'Gujarat'],
        382455: ['Ahmedabad', 'Gujarat'],
        382460: ['Ahmedabad', 'Gujarat'],
        382463: ['Ahmedabad', 'Gujarat'],
        382465: ['Ahmedabad', 'Gujarat'],
        382470: ['Ahmedabad', 'Gujarat'],
        382475: ['Ahmedabad', 'Gujarat'],
        382480: ['Ahmedabad', 'Gujarat'],
        382481: ['Ahmedabad', 'Gujarat'],
        382610: ['GandhiNagar', 'Gujarat'],
        382620: ['GandhiNagar', 'Gujarat'],
        382630: ['GandhiNagar', 'Gujarat'],
        382640: ['Gandhinagar', 'Gujarat'],
        382650: ['Gandhinagar', 'Gujarat'],
        382721: ['GandhiNagar', 'Gujarat'],
        382725: ['GandhiNagar', 'Gujarat'],
        382729: ['GandhiNagar', 'Gujarat'],
        382735: ['GandhiNagar', 'Gujarat'],
        382740: ['GandhiNagar', 'Gujarat'],
        382810: ['GandhiNagar', 'Gujarat'],
        382835: ['GandhiNagar', 'Gujarat'],
        382845: ['Gandhinagar', 'Gujarat'],
        382855: ['GandhiNagar', 'Gujarat'],
        383205: ['Ahmedabad', 'Gujarat'],
        383345: ['Ahmedabad', 'Gujarat'],
        387003: ['Vadodara', 'Gujarat'],
        387210: ['Anand', 'Gujarat'],
        387220: ['Anand', 'Gujarat'],
        387240: ['Anand', 'Gujarat'],
        387310: ['Anand', 'Gujarat'],
        387345: ['Anand', 'Gujarat'],
        387710: ['Anand', 'Gujarat'],
        387810: ['Ahmedabad', 'Gujarat'],
        388001: ['Anand', 'Gujarat'],
        388110: ['Anand', 'Gujarat'],
        388120: ['Anand', 'Gujarat'],
        388121: ['Anand', 'Gujarat'],
        388130: ['Anand', 'Gujarat'],
        388140: ['Anand', 'Gujarat'],
        388150: ['Anand', 'Gujarat'],
        388160: ['Anand', 'Gujarat'],
        388170: ['Anand', 'Gujarat'],
        388180: ['Anand', 'Gujarat'],
        388205: ['Anand', 'Gujarat'],
        388210: ['Anand', 'Gujarat'],
        388215: ['Anand', 'Gujarat'],
        388220: ['Anand', 'Gujarat'],
        388305: ['Anand', 'Gujarat'],
        388306: ['Anand', 'Gujarat'],
        388307: ['Anand', 'Gujarat'],
        388310: ['Anand', 'Gujarat'],
        388315: ['Anand', 'Gujarat'],
        388320: ['Anand', 'Gujarat'],
        388325: ['Anand', 'Gujarat'],
        388330: ['Anand', 'Gujarat'],
        388335: ['Anand', 'Gujarat'],
        388340: ['Anand', 'Gujarat'],
        388345: ['Anand', 'Gujarat'],
        388350: ['Anand', 'Gujarat'],
        388355: ['Anand', 'Gujarat'],
        388360: ['Anand', 'Gujarat'],
        388365: ['Anand', 'Gujarat'],
        388370: ['Anand', 'Gujarat'],
        388430: ['Anand', 'Gujarat'],
        388440: ['Anand', 'Gujarat'],
        388450: ['Anand', 'Gujarat'],
        388460: ['Anand', 'Gujarat'],
        388465: ['Anand', 'Gujarat'],
        388470: ['Anand', 'Gujarat'],
        388480: ['Anand', 'Gujarat'],
        388510: ['Anand', 'Gujarat'],
        388520: ['Anand', 'Gujarat'],
        388530: ['Anand', 'Gujarat'],
        388540: ['Anand', 'Gujarat'],
        388543: ['Anand', 'Gujarat'],
        388545: ['Anand', 'Gujarat'],
        388550: ['Anand', 'Gujarat'],
        388560: ['Anand', 'Gujarat'],
        388570: ['Anand', 'Gujarat'],
        388580: ['Anand', 'Gujarat'],
        388590: ['Anand', 'Gujarat'],
        388610: ['Anand', 'Gujarat'],
        388620: ['Anand', 'Gujarat'],
        388625: ['Anand', 'Gujarat'],
        388630: ['Anand', 'Gujarat'],
        388640: ['Anand', 'Gujarat'],
        390001: ['Vadodara', 'Gujarat'],
        390002: ['Vadodara', 'Gujarat'],
        390003: ['Vadodara', 'Gujarat'],
        390004: ['Vadodara', 'Gujarat'],
        390005: ['Vadodara', 'Gujarat'],
        390006: ['Vadodara', 'Gujarat'],
        390007: ['Vadodara', 'Gujarat'],
        390008: ['Vadodara', 'Gujarat'],
        390009: ['Vadodara', 'Gujarat'],
        390010: ['Vadodara', 'Gujarat'],
        390011: ['Vadodara', 'Gujarat'],
        390012: ['Vadodara', 'Gujarat'],
        390013: ['Vadodara', 'Gujarat'],
        390014: ['Vadodara', 'Gujarat'],
        390015: ['Vadodara', 'Gujarat'],
        390016: ['Vadodara', 'Gujarat'],
        390017: ['Vadodara', 'Gujarat'],
        390018: ['Vadodara', 'Gujarat'],
        390019: ['Vadodara', 'Gujarat'],
        390020: ['Vadodara', 'Gujarat'],
        390021: ['Vadodara', 'Gujarat'],
        390022: ['Vadodara', 'Gujarat'],
        390023: ['Vadodara', 'Gujarat'],
        390024: ['Vadodara', 'Gujarat'],
        390025: ['Vadodara', 'Gujarat'],
        391101: ['Vadodara', 'Gujarat'],
        391105: ['Vadodara', 'Gujarat'],
        391107: ['Vadodara', 'Gujarat'],
        391110: ['Vadodara', 'Gujarat'],
        391111: ['Vadodara', 'Gujarat'],
        391115: ['Vadodara', 'Gujarat'],
        391120: ['Vadodara', 'Gujarat'],
        391121: ['Vadodara', 'Gujarat'],
        391125: ['Vadodara', 'Gujarat'],
        391126: ['Vadodara', 'Gujarat'],
        391130: ['Vadodara', 'Gujarat'],
        391135: ['Vadodara', 'Gujarat'],
        391136: ['Vadodara', 'Gujarat'],
        391137: ['Vadodara', 'Gujarat'],
        391140: ['Vadodara', 'Gujarat'],
        391142: ['Vadodara', 'Gujarat'],
        391145: ['Vadodara', 'Gujarat'],
        391146: ['Vadodara', 'Gujarat'],
        391150: ['Vadodara', 'Gujarat'],
        391151: ['Vadodara', 'Gujarat'],
        391152: ['Vadodara', 'Gujarat'],
        391155: ['Vadodara', 'Gujarat'],
        391156: ['Vadodara', 'Gujarat'],
        391157: ['Vadodara', 'Gujarat'],
        391160: ['Vadodara', 'Gujarat'],
        391165: ['Vadodara', 'Gujarat'],
        391168: ['Vadodara', 'Gujarat'],
        391169: ['Vadodara', 'Gujarat'],
        391170: ['Vadodara', 'Gujarat'],
        391175: ['Vadodara', 'Gujarat'],
        391210: ['Vadodara', 'Gujarat'],
        391220: ['Vadodara', 'Gujarat'],
        391230: ['Vadodara', 'Gujarat'],
        391240: ['Vadodara', 'Gujarat'],
        391243: ['Vadodara', 'Gujarat'],
        391244: ['Vadodara', 'Gujarat'],
        391250: ['Vadodara', 'Gujarat'],
        391310: ['Vadodara', 'Gujarat'],
        391320: ['Vadodara', 'Gujarat'],
        391330: ['Vadodara', 'Gujarat'],
        391340: ['Vadodara', 'Gujarat'],
        391345: ['Vadodara', 'Gujarat'],
        391346: ['Vadodara', 'Gujarat'],
        391347: ['Vadodara', 'Gujarat'],
        391350: ['Vadodara', 'Gujarat'],
        391410: ['Vadodara', 'Gujarat'],
        391421: ['Vadodara', 'Gujarat'],
        391430: ['Vadodara', 'Gujarat'],
        391440: ['Vadodara', 'Gujarat'],
        391445: ['Vadodara', 'Gujarat'],
        391450: ['Vadodara', 'Gujarat'],
        391460: ['Vadodara', 'Gujarat'],
        391510: ['Vadodara', 'Gujarat'],
        391520: ['Vadodara', 'Gujarat'],
        391530: ['Vadodara', 'Gujarat'],
        391535: ['Vadodara', 'Gujarat'],
        391740: ['Vadodara', 'Gujarat'],
        391745: ['Vadodara', 'Gujarat'],
        391750: ['Vadodara', 'Gujarat'],
        391760: ['Vadodara', 'Gujarat'],
        391761: ['Vadodara', 'Gujarat'],
        391770: ['Vadodara', 'Gujarat'],
        391774: ['Vadodara', 'Gujarat'],
        391775: ['Vadodara', 'Gujarat'],
        391776: ['Vadodara', 'Gujarat'],
        391780: ['Vadodara', 'Gujarat'],
        392030: ['Vadodara', 'Gujarat'],
        392110: ['Vadodara', 'Gujarat'],
        392130: ['Vadodara', 'Gujarat'],
        392150: ['Vadodara', 'Gujarat'],
        392160: ['Vadodara', 'Gujarat'],
        392170: ['Vadodara', 'Gujarat'],
        392220: ['Vadodara', 'Gujarat'],
        392310: ['Vadodara', 'Gujarat'],
        393002: ['Vadodara', 'Gujarat'],
        393010: ['Vadodara', 'Gujarat'],
        393050: ['Surat', 'Gujarat'],
        393105: ['Vadodara', 'Gujarat'],
        393130: ['Surat', 'Gujarat'],
        394101: ['Surat', 'Gujarat'],
        394105: ['Surat', 'Gujarat'],
        394107: ['Surat', 'Gujarat'],
        394110: ['Surat', 'Gujarat'],
        394111: ['Surat', 'Gujarat'],
        394120: ['Surat', 'Gujarat'],
        394125: ['Surat', 'Gujarat'],
        394130: ['Surat', 'Gujarat'],
        394140: ['Surat', 'Gujarat'],
        394150: ['Surat', 'Gujarat'],
        394155: ['Surat', 'Gujarat'],
        394160: ['Surat', 'Gujarat'],
        394163: ['Surat', 'Gujarat'],
        394170: ['Surat', 'Gujarat'],
        394180: ['Surat', 'Gujarat'],
        394185: ['Surat', 'Gujarat'],
        394190: ['Surat', 'Gujarat'],
        394210: ['Surat', 'Gujarat'],
        394220: ['Surat', 'Gujarat'],
        394221: ['Surat', 'Gujarat'],
        394230: ['Surat', 'Gujarat'],
        394235: ['Surat', 'Gujarat'],
        394240: ['Surat', 'Gujarat'],
        394245: ['Surat', 'Gujarat'],
        394246: ['Surat', 'Gujarat'],
        394248: ['Surat', 'Gujarat'],
        394250: ['Surat', 'Gujarat'],
        394270: ['Surat', 'Gujarat'],
        394305: ['Surat', 'Gujarat'],
        394310: ['Surat', 'Gujarat'],
        394315: ['Surat', 'Gujarat'],
        394317: ['Surat', 'Gujarat'],
        394320: ['Surat', 'Gujarat'],
        394325: ['Surat', 'Gujarat'],
        394326: ['Surat', 'Gujarat'],
        394327: ['Surat', 'Gujarat'],
        394330: ['Surat', 'Gujarat'],
        394335: ['Surat', 'Gujarat'],
        394340: ['Surat', 'Gujarat'],
        394345: ['Surat', 'Gujarat'],
        394350: ['Surat', 'Gujarat'],
        394352: ['Surat', 'Gujarat'],
        394355: ['Surat', 'Gujarat'],
        394360: ['Surat', 'Gujarat'],
        394365: ['Surat', 'Gujarat'],
        394370: ['Surat', 'Gujarat'],
        394375: ['Surat', 'Gujarat'],
        394380: ['Surat', 'Gujarat'],
        394405: ['Surat', 'Gujarat'],
        394410: ['Surat', 'Gujarat'],
        394421: ['Surat', 'Gujarat'],
        394430: ['Surat', 'Gujarat'],
        394440: ['Surat', 'Gujarat'],
        394445: ['Surat', 'Gujarat'],
        394510: ['Surat', 'Gujarat'],
        394515: ['Surat', 'Gujarat'],
        394516: ['Surat', 'Gujarat'],
        394517: ['Surat', 'Gujarat'],
        394518: ['Surat', 'Gujarat'],
        394520: ['Surat', 'Gujarat'],
        394530: ['Surat', 'Gujarat'],
        394540: ['Surat', 'Gujarat'],
        394541: ['Vadodara', 'Gujarat'],
        394550: ['Surat', 'Gujarat'],
        394601: ['Surat', 'Gujarat'],
        394620: ['Surat', 'Gujarat'],
        394630: ['Surat', 'Gujarat'],
        394633: ['Surat', 'Gujarat'],
        394635: ['Surat', 'Gujarat'],
        394640: ['Surat', 'Gujarat'],
        394641: ['Surat', 'Gujarat'],
        394650: ['Surat', 'Gujarat'],
        394651: ['Surat', 'Gujarat'],
        394652: ['Vadodara', 'Gujarat'],
        394655: ['Surat', 'Gujarat'],
        394660: ['Surat', 'Gujarat'],
        394670: ['Surat', 'Gujarat'],
        394680: ['Surat', 'Gujarat'],
        394690: ['Surat', 'Gujarat'],
        394715: ['Surat', 'Gujarat'],
        395001: ['Surat', 'Gujarat'],
        395002: ['Surat', 'Gujarat'],
        395003: ['Surat', 'Gujarat'],
        395004: ['Surat', 'Gujarat'],
        395005: ['Surat', 'Gujarat'],
        395006: ['Surat', 'Gujarat'],
        395007: ['Surat', 'Gujarat'],
        395008: ['Surat', 'Gujarat'],
        395009: ['Surat', 'Gujarat'],
        395010: ['Surat', 'Gujarat'],
        395011: ['Surat', 'Gujarat'],
        395012: ['Surat', 'Gujarat'],
        395013: ['Surat', 'Gujarat'],
        395017: ['Surat', 'Gujarat'],
        395023: ['Surat', 'Gujarat'],
        396001: ['Valsad', 'Gujarat'],
        396002: ['Valsad', 'Gujarat'],
        396007: ['Valsad', 'Gujarat'],
        396020: ['Valsad', 'Gujarat'],
        396030: ['Valsad', 'Gujarat'],
        396035: ['Valsad', 'Gujarat'],
        396040: ['Valsad', 'Gujarat'],
        396045: ['Valsad', 'Gujarat'],
        396050: ['Valsad', 'Gujarat'],
        396051: ['Valsad', 'Gujarat'],
        396055: ['Valsad', 'Gujarat'],
        396060: ['Valsad', 'Gujarat'],
        396065: ['Valsad', 'Gujarat'],
        396067: ['Vadodara', 'Gujarat'],
        396105: ['Valsad', 'Gujarat'],
        396110: ['Valsad', 'Gujarat'],
        396115: ['Valsad', 'Gujarat'],
        396120: ['Valsad', 'Gujarat'],
        396125: ['Valsad', 'Gujarat'],
        396126: ['Valsad', 'Gujarat'],
        396130: ['Valsad', 'Gujarat'],
        396135: ['Valsad', 'Gujarat'],
        396140: ['Valsad', 'Gujarat'],
        396145: ['Valsad', 'Gujarat'],
        396150: ['Valsad', 'Gujarat'],
        396155: ['Valsad', 'Gujarat'],
        396165: ['Valsad', 'Gujarat'],
        396170: ['Valsad', 'Gujarat'],
        396171: ['Valsad', 'Gujarat'],
        396175: ['Valsad', 'Gujarat'],
        396180: ['Valsad', 'Gujarat'],
        396185: ['Valsad', 'Gujarat'],
        396191: ['Valsad', 'Gujarat'],
        396193: ['Vapi', 'Gujarat'],
        396195: ['Valsad', 'Gujarat'],
        396215: ['Vapi', 'Gujarat'],
        396220: ['Vapi', 'Gujarat'],
        396230: ['Valsad', 'Gujarat'],
        396235: ['Valsad', 'Gujarat'],
        396310: ['Valsad', 'Gujarat'],
        396321: ['Valsad', 'Gujarat'],
        396325: ['Valsad', 'Gujarat'],
        396340: ['Valsad', 'Gujarat'],
        396350: ['Valsad', 'Gujarat'],
        396360: ['Valsad', 'Gujarat'],
        396370: ['Valsad', 'Gujarat'],
        396375: ['Valsad', 'Gujarat'],
        396385: ['Valsad', 'Gujarat'],
        396403: ['Valsad', 'Gujarat'],
        396406: ['Valsad', 'Gujarat'],
        396409: ['Valsad', 'Gujarat'],
        396412: ['Valsad', 'Gujarat'],
        396415: ['Valsad', 'Gujarat'],
        396418: ['Valsad', 'Gujarat'],
        396421: ['Valsad', 'Gujarat'],
        396424: ['Valsad', 'Gujarat'],
        396427: ['Valsad', 'Gujarat'],
        396430: ['Valsad', 'Gujarat'],
        396436: ['Valsad', 'Gujarat'],
        396439: ['Valsad', 'Gujarat'],
        396440: ['Valsad', 'Gujarat'],
        396445: ['Valsad', 'Gujarat'],
        396450: ['Valsad', 'Gujarat'],
        396460: ['Valsad', 'Gujarat'],
        396463: ['Valsad', 'Gujarat'],
        396466: ['Valsad', 'Gujarat'],
        396469: ['Valsad', 'Gujarat'],
        396472: ['Valsad', 'Gujarat'],
        396475: ['Valsad', 'Gujarat'],
        396510: ['Surat', 'Gujarat'],
        396521: ['Valsad', 'Gujarat'],
        396530: ['Valsad', 'Gujarat'],
        396540: ['Valsad', 'Gujarat'],
        396560: ['Valsad', 'Gujarat'],
        396570: ['Valsad', 'Gujarat'],
        396590: ['Valsad', 'Gujarat'],
        400001: ['Mumbai', 'Maharashtra'],
        400002: ['Mumbai', 'Maharashtra'],
        400003: ['Mumbai', 'Maharashtra'],
        400004: ['Mumbai', 'Maharashtra'],
        400005: ['Mumbai', 'Maharashtra'],
        400006: ['Mumbai', 'Maharashtra'],
        400007: ['Mumbai', 'Maharashtra'],
        400008: ['Mumbai', 'Maharashtra'],
        400009: ['Mumbai', 'Maharashtra'],
        400010: ['Mumbai', 'Maharashtra'],
        400011: ['Mumbai', 'Maharashtra'],
        400012: ['Mumbai', 'Maharashtra'],
        400013: ['Mumbai', 'Maharashtra'],
        400014: ['Mumbai', 'Maharashtra'],
        400015: ['Mumbai', 'Maharashtra'],
        400016: ['Mumbai', 'Maharashtra'],
        400017: ['Mumbai', 'Maharashtra'],
        400018: ['Mumbai', 'Maharashtra'],
        400019: ['Mumbai', 'Maharashtra'],
        400020: ['Mumbai', 'Maharashtra'],
        400021: ['Mumbai', 'Maharashtra'],
        400022: ['Mumbai', 'Maharashtra'],
        400024: ['Mumbai', 'Maharashtra'],
        400025: ['Mumbai', 'Maharashtra'],
        400026: ['Mumbai', 'Maharashtra'],
        400027: ['Mumbai', 'Maharashtra'],
        400028: ['Mumbai', 'Maharashtra'],
        400029: ['Mumbai', 'Maharashtra'],
        400030: ['Mumbai', 'Maharashtra'],
        400031: ['Mumbai', 'Maharashtra'],
        400032: ['Mumbai', 'Maharashtra'],
        400033: ['Mumbai', 'Maharashtra'],
        400034: ['Mumbai', 'Maharashtra'],
        400035: ['Mumbai', 'Maharashtra'],
        400036: ['Mumbai', 'Maharashtra'],
        400037: ['Mumbai', 'Maharashtra'],
        400039: ['Mumbai', 'Maharashtra'],
        400042: ['Mumbai', 'Maharashtra'],
        400043: ['Mumbai', 'Maharashtra'],
        400048: ['Mumbai', 'Maharashtra'],
        400049: ['Mumbai', 'Maharashtra'],
        400050: ['Mumbai', 'Maharashtra'],
        400051: ['Mumbai', 'Maharashtra'],
        400052: ['Mumbai', 'Maharashtra'],
        400053: ['Mumbai', 'Maharashtra'],
        400054: ['Mumbai', 'Maharashtra'],
        400055: ['Mumbai', 'Maharashtra'],
        400056: ['Mumbai', 'Maharashtra'],
        400057: ['Mumbai', 'Maharashtra'],
        400058: ['Mumbai', 'Maharashtra'],
        400059: ['Mumbai', 'Maharashtra'],
        400060: ['Mumbai', 'Maharashtra'],
        400061: ['Mumbai', 'Maharashtra'],
        400062: ['Mumbai', 'Maharashtra'],
        400063: ['Mumbai', 'Maharashtra'],
        400064: ['Mumbai', 'Maharashtra'],
        400065: ['Mumbai', 'Maharashtra'],
        400066: ['Mumbai', 'Maharashtra'],
        400067: ['Mumbai', 'Maharashtra'],
        400068: ['Mumbai', 'Maharashtra'],
        400069: ['Mumbai', 'Maharashtra'],
        400070: ['Mumbai', 'Maharashtra'],
        400071: ['Mumbai', 'Maharashtra'],
        400072: ['Mumbai', 'Maharashtra'],
        400074: ['Mumbai', 'Maharashtra'],
        400075: ['Mumbai', 'Maharashtra'],
        400076: ['Mumbai', 'Maharashtra'],
        400077: ['Mumbai', 'Maharashtra'],
        400078: ['Mumbai', 'Maharashtra'],
        400079: ['Mumbai', 'Maharashtra'],
        400080: ['Mumbai', 'Maharashtra'],
        400081: ['Mumbai', 'Maharashtra'],
        400082: ['Mumbai', 'Maharashtra'],
        400083: ['Mumbai', 'Maharashtra'],
        400084: ['Mumbai', 'Maharashtra'],
        400085: ['Mumbai', 'Maharashtra'],
        400086: ['Mumbai', 'Maharashtra'],
        400087: ['Mumbai', 'Maharashtra'],
        400088: ['Mumbai', 'Maharashtra'],
        400089: ['Mumbai', 'Maharashtra'],
        400090: ['Mumbai', 'Maharashtra'],
        400091: ['Mumbai', 'Maharashtra'],
        400092: ['Mumbai', 'Maharashtra'],
        400093: ['Mumbai', 'Maharashtra'],
        400094: ['Mumbai', 'Maharashtra'],
        400095: ['Mumbai', 'Maharashtra'],
        400096: ['Mumbai', 'Maharashtra'],
        400097: ['Mumbai', 'Maharashtra'],
        400098: ['Mumbai', 'Maharashtra'],
        400099: ['Mumbai', 'Maharashtra'],
        400101: ['Mumbai', 'Maharashtra'],
        400102: ['Mumbai', 'Maharashtra'],
        400103: ['Mumbai', 'Maharashtra'],
        400104: ['Mumbai', 'Maharashtra'],
        400601: ['Thane', 'Maharashtra'],
        400602: ['Thane', 'Maharashtra'],
        400603: ['Thane', 'Maharashtra'],
        400604: ['Thane', 'Maharashtra'],
        400605: ['Navi Mumbai', 'Maharashtra'],
        400606: ['Thane', 'Maharashtra'],
        400607: ['Thane', 'Maharashtra'],
        400608: ['Thane', 'Maharashtra'],
        400610: ['Thane', 'Maharashtra'],
        400612: ['Thane', 'Maharashtra'],
        400613: ['Thane', 'Maharashtra'],
        400614: ['Navi Mumbai', 'Maharashtra'],
        400615: ['Thane', 'Maharashtra'],
        400701: ['Navi Mumbai', 'Maharashtra'],
        400702: ['Navi Mumbai', 'Maharashtra'],
        400703: ['Navi Mumbai', 'Maharashtra'],
        400704: ['Navi Mumbai', 'Maharashtra'],
        400705: ['Navi Mumbai', 'Maharashtra'],
        400706: ['Navi Mumbai', 'Maharashtra'],
        400707: ['Navi Mumbai', 'Maharashtra'],
        400708: ['Navi Mumbai', 'Maharashtra'],
        400709: ['Navi Mumbai', 'Maharashtra'],
        400710: ['Navi Mumbai', 'Maharashtra'],
        401101: ['Thane', 'Maharashtra'],
        401102: ['Thane', 'Maharashtra'],
        401103: ['Thane', 'Maharashtra'],
        401104: ['Thane', 'Maharashtra'],
        401105: ['Thane', 'Maharashtra'],
        401106: ['Thane', 'Maharashtra'],
        401107: ['Thane', 'Maharashtra'],
        401201: ['Thane', 'Maharashtra'],
        401202: ['Thane', 'Maharashtra'],
        401203: ['Thane', 'Maharashtra'],
        401204: ['Thane', 'Maharashtra'],
        401206: ['Thane', 'Maharashtra'],
        401207: ['Thane', 'Maharashtra'],
        401208: ['Thane', 'Maharashtra'],
        401209: ['Thane', 'Maharashtra'],
        401301: ['Thane', 'Maharashtra'],
        401302: ['Thane', 'Maharashtra'],
        401303: ['Thane', 'Maharashtra'],
        401304: ['Thane', 'Maharashtra'],
        401305: ['Thane', 'Maharashtra'],
        401401: ['Thane', 'Maharashtra'],
        401402: ['Thane', 'Maharashtra'],
        401403: ['Thane', 'Maharashtra'],
        401404: ['Thane', 'Maharashtra'],
        401405: ['Thane', 'Maharashtra'],
        401501: ['Thane', 'Maharashtra'],
        401502: ['Thane', 'Maharashtra'],
        401503: ['Thane', 'Maharashtra'],
        401504: ['Thane', 'Maharashtra'],
        401506: ['Thane', 'Maharashtra'],
        401601: ['Thane', 'Maharashtra'],
        401602: ['Thane', 'Maharashtra'],
        401603: ['Thane', 'Maharashtra'],
        401604: ['Thane', 'Maharashtra'],
        401605: ['Thane', 'Maharashtra'],
        401606: ['Thane', 'Maharashtra'],
        401607: ['Thane', 'Maharashtra'],
        401608: ['Thane', 'Maharashtra'],
        401609: ['Thane', 'Maharashtra'],
        401610: ['Thane', 'Maharashtra'],
        401701: ['Thane', 'Maharashtra'],
        401702: ['Thane', 'Maharashtra'],
        401703: ['Thane', 'Maharashtra'],
        403001: ['Pananji', 'Goa'],
        403002: ['Pananji', 'Goa'],
        403003: ['Pananji', 'Goa'],
        403004: ['Pananji', 'Goa'],
        403005: ['Goa', 'Goa'],
        403006: ['Goa', 'Goa'],
        403101: ['Goa', 'Goa'],
        403102: ['Goa', 'Goa'],
        403103: ['Goa', 'Goa'],
        403104: ['Goa', 'Goa'],
        403105: ['Goa', 'Goa'],
        403106: ['Goa', 'Goa'],
        403107: ['Goa', 'Goa'],
        403108: ['Goa', 'Goa'],
        403109: ['Goa', 'Goa'],
        403110: ['Goa', 'Goa'],
        403111: ['Goa', 'Goa'],
        403114: ['Goa', 'Goa'],
        403115: ['Goa', 'Goa'],
        403201: ['Goa', 'Goa'],
        403202: ['Goa', 'Goa'],
        403203: ['Goa', 'Goa'],
        403204: ['Goa', 'Goa'],
        403206: ['Pananji', 'Goa'],
        403401: ['Goa', 'Goa'],
        403402: ['Goa', 'Goa'],
        403403: ['Goa', 'Goa'],
        403404: ['Goa', 'Goa'],
        403406: ['Goa', 'Goa'],
        403407: ['Goa', 'Goa'],
        403409: ['Goa', 'Goa'],
        403410: ['Goa', 'Goa'],
        403501: ['Goa', 'Goa'],
        403502: ['Goa', 'Goa'],
        403503: ['Goa', 'Goa'],
        403504: ['Goa', 'Goa'],
        403505: ['Goa', 'Goa'],
        403506: ['Goa', 'Goa'],
        403507: ['Goa', 'Goa'],
        403508: ['Goa', 'Goa'],
        403509: ['Goa', 'Goa'],
        403510: ['Goa', 'Goa'],
        403511: ['Goa', 'Goa'],
        403512: ['Goa', 'Goa'],
        403513: ['Goa', 'Goa'],
        403515: ['Goa', 'Goa'],
        403516: ['Goa', 'Goa'],
        403517: ['Goa', 'Goa'],
        403518: ['Goa', 'Goa'],
        403519: ['Goa', 'Goa'],
        403521: ['Pananji', 'Goa'],
        403523: ['Goa', 'Goa'],
        403524: ['Goa', 'Goa'],
        403526: ['Goa', 'Goa'],
        403527: ['Goa', 'Goa'],
        403529: ['Goa', 'Goa'],
        403530: ['Goa', 'Goa'],
        403601: ['Margao', 'Goa'],
        403602: ['Margao', 'Goa'],
        403603: ['Goa', 'Goa'],
        403604: ['Goa', 'Goa'],
        403701: ['Goa', 'Goa'],
        403702: ['Goa', 'Goa'],
        403703: ['Goa', 'Goa'],
        403704: ['Goa', 'Goa'],
        403705: ['Goa', 'Goa'],
        403706: ['Goa', 'Goa'],
        403707: ['Margao', 'Goa'],
        403708: ['Goa', 'Goa'],
        403709: ['Margao', 'Goa'],
        403710: ['Goa', 'Goa'],
        403711: ['Goa', 'Goa'],
        403712: ['Goa', 'Goa'],
        403713: ['Goa', 'Goa'],
        403714: ['Margao', 'Goa'],
        403715: ['Goa', 'Goa'],
        403716: ['Goa', 'Goa'],
        403717: ['Goa', 'Goa'],
        403718: ['Goa', 'Goa'],
        403719: ['Goa', 'Goa'],
        403720: ['Margao', 'Goa'],
        403721: ['Goa', 'Goa'],
        403722: ['Goa', 'Goa'],
        403723: ['Goa', 'Goa'],
        403724: ['Goa', 'Goa'],
        403725: ['Goa', 'Goa'],
        403726: ['Goa', 'Goa'],
        403727: ['Goa', 'Goa'],
        403728: ['Goa', 'Goa'],
        403729: ['Goa', 'Goa'],
        403730: ['Goa', 'Goa'],
        403731: ['Goa', 'Goa'],
        403801: ['Goa', 'Goa'],
        403802: ['Goa', 'Goa'],
        403803: ['Goa', 'Goa'],
        403804: ['Goa', 'Goa'],
        403806: ['Goa', 'Goa'],
        410106: ['Navi Mumbai', 'Maharashtra'],
        410206: ['Navi Mumbai', 'Maharashtra'],
        410208: ['Navi Mumbai', 'Maharashtra'],
        410209: ['Navi Mumbai', 'Maharashtra'],
        410210: ['Navi Mumbai', 'Maharashtra'],
        410218: ['Navi Mumbai', 'Maharashtra'],
        410221: ['Navi Mumbai', 'Maharashtra'],
        410301: ['Pune', 'Maharashtra'],
        410302: ['Pune', 'Maharashtra'],
        410401: ['Pune', 'Maharashtra'],
        410402: ['Pune', 'Maharashtra'],
        410403: ['Pune', 'Maharashtra'],
        410405: ['Pune', 'Maharashtra'],
        410406: ['Pune', 'Maharashtra'],
        410501: ['Pune', 'Maharashtra'],
        410502: ['Pune', 'Maharashtra'],
        410503: ['Pune', 'Maharashtra'],
        410504: ['Pune', 'Maharashtra'],
        410505: ['Pune', 'Maharashtra'],
        410506: ['Pune', 'Maharashtra'],
        410507: ['Pune', 'Maharashtra'],
        410508: ['Pune', 'Maharashtra'],
        410509: ['Pune', 'Maharashtra'],
        410510: ['Pune', 'Maharashtra'],
        410511: ['Pune', 'Maharashtra'],
        410512: ['Pune', 'Maharashtra'],
        410513: ['Pune', 'Maharashtra'],
        410515: ['Pune', 'Maharashtra'],
        410516: ['Pune', 'Maharashtra'],
        411001: ['Pune', 'Maharashtra'],
        411002: ['Pune', 'Maharashtra'],
        411003: ['Pune', 'Maharashtra'],
        411004: ['Pune', 'Maharashtra'],
        411005: ['Pune', 'Maharashtra'],
        411006: ['Pune', 'Maharashtra'],
        411007: ['Pune', 'Maharashtra'],
        411008: ['Pune', 'Maharashtra'],
        411009: ['Pune', 'Maharashtra'],
        411010: ['Pune', 'Maharashtra'],
        411011: ['Pune', 'Maharashtra'],
        411012: ['Pune', 'Maharashtra'],
        411013: ['Pune', 'Maharashtra'],
        411014: ['Pune', 'Maharashtra'],
        411015: ['Pune', 'Maharashtra'],
        411016: ['Pune', 'Maharashtra'],
        411017: ['Pune', 'Maharashtra'],
        411018: ['Pune', 'Maharashtra'],
        411019: ['Pune', 'Maharashtra'],
        411020: ['Pune', 'Maharashtra'],
        411021: ['Pune', 'Maharashtra'],
        411022: ['Pune', 'Maharashtra'],
        411023: ['Pune', 'Maharashtra'],
        411024: ['Pune', 'Maharashtra'],
        411025: ['Pune', 'Maharashtra'],
        411026: ['Pune', 'Maharashtra'],
        411027: ['Pune', 'Maharashtra'],
        411028: ['Pune', 'Maharashtra'],
        411029: ['Pune', 'Maharashtra'],
        411030: ['Pune', 'Maharashtra'],
        411031: ['Pune', 'Maharashtra'],
        411032: ['Pune', 'Maharashtra'],
        411033: ['Pune', 'Maharashtra'],
        411034: ['Pune', 'Maharashtra'],
        411035: ['Pune', 'Maharashtra'],
        411036: ['Pune', 'Maharashtra'],
        411037: ['Pune', 'Maharashtra'],
        411038: ['Pune', 'Maharashtra'],
        411039: ['Pune', 'Maharashtra'],
        411040: ['Pune', 'Maharashtra'],
        411041: ['Pune', 'Maharashtra'],
        411042: ['Pune', 'Maharashtra'],
        411043: ['Pune', 'Maharashtra'],
        411044: ['Pune', 'Maharashtra'],
        411045: ['Pune', 'Maharashtra'],
        411046: ['Pune', 'Maharashtra'],
        411047: ['Pune', 'Maharashtra'],
        411048: ['Pune', 'Maharashtra'],
        411051: ['Pune', 'Maharashtra'],
        411052: ['Pune', 'Maharashtra'],
        411057: ['Pune', 'Maharashtra'],
        411058: ['Pune', 'Maharashtra'],
        411060: ['Pune', 'Maharashtra'],
        411061: ['Pune', 'Maharashtra'],
        411062: ['Pune', 'Maharashtra'],
        411067: ['Pune', 'Maharashtra'],
        411068: ['Pune', 'Maharashtra'],
        412101: ['Pune', 'Maharashtra'],
        412102: ['Pune', 'Maharashtra'],
        412103: ['Pune', 'Maharashtra'],
        412104: ['Pune', 'Maharashtra'],
        412105: ['Pune', 'Maharashtra'],
        412106: ['Pune', 'Maharashtra'],
        412107: ['Pune', 'Maharashtra'],
        412108: ['Pune', 'Maharashtra'],
        412109: ['Pune', 'Maharashtra'],
        412110: ['Pune', 'Maharashtra'],
        412112: ['Pune', 'Maharashtra'],
        412113: ['Pune', 'Maharashtra'],
        412115: ['Pune', 'Maharashtra'],
        412201: ['Pune', 'Maharashtra'],
        412202: ['Pune', 'Maharashtra'],
        412203: ['Pune', 'Maharashtra'],
        412204: ['Pune', 'Maharashtra'],
        412205: ['Pune', 'Maharashtra'],
        412206: ['Pune', 'Maharashtra'],
        412207: ['Pune', 'Maharashtra'],
        412208: ['Pune', 'Maharashtra'],
        412209: ['Pune', 'Maharashtra'],
        412210: ['Pune', 'Maharashtra'],
        412211: ['Pune', 'Maharashtra'],
        412212: ['Pune', 'Maharashtra'],
        412213: ['Pune', 'Maharashtra'],
        412214: ['Pune', 'Maharashtra'],
        412215: ['Pune', 'Maharashtra'],
        412216: ['Pune', 'Maharashtra'],
        412218: ['Pune', 'Maharashtra'],
        412219: ['Pune', 'Maharashtra'],
        412220: ['Pune', 'Maharashtra'],
        412301: ['Pune', 'Maharashtra'],
        412303: ['Pune', 'Maharashtra'],
        412304: ['Pune', 'Maharashtra'],
        412305: ['Pune', 'Maharashtra'],
        412306: ['Pune', 'Maharashtra'],
        412307: ['Pune', 'Maharashtra'],
        412308: ['Pune', 'Maharashtra'],
        412311: ['Pune', 'Maharashtra'],
        412312: ['Pune', 'Maharashtra'],
        412401: ['Pune', 'Maharashtra'],
        412402: ['Pune', 'Maharashtra'],
        412403: ['Pune', 'Maharashtra'],
        412404: ['Pune', 'Maharashtra'],
        412405: ['Pune', 'Maharashtra'],
        412406: ['Pune', 'Maharashtra'],
        412407: ['Pune', 'Maharashtra'],
        412408: ['Pune', 'Maharashtra'],
        412409: ['Pune', 'Maharashtra'],
        412410: ['Pune', 'Maharashtra'],
        412411: ['Pune', 'Maharashtra'],
        412412: ['Pune', 'Maharashtra'],
        412415: ['Pune', 'Maharashtra'],
        413001: ['Solapur', 'Maharashtra'],
        413002: ['Solapur', 'Maharashtra'],
        413003: ['Solapur', 'Maharashtra'],
        413004: ['Solapur', 'Maharashtra'],
        413005: ['Solapur', 'Maharashtra'],
        413006: ['Solapur', 'Maharashtra'],
        413007: ['Solapur', 'Maharashtra'],
        413008: ['Solapur', 'Maharashtra'],
        413101: ['Solapur', 'Maharashtra'],
        413102: ['Pune', 'Maharashtra'],
        413103: ['Pune', 'Maharashtra'],
        413104: ['Pune', 'Maharashtra'],
        413105: ['Pune', 'Maharashtra'],
        413106: ['Pune', 'Maharashtra'],
        413107: ['Solapur', 'Maharashtra'],
        413108: ['Solapur', 'Maharashtra'],
        413109: ['Solapur', 'Maharashtra'],
        413110: ['Pune', 'Maharashtra'],
        413111: ['Solapur', 'Maharashtra'],
        413112: ['Solapur', 'Maharashtra'],
        413113: ['Solapur', 'Maharashtra'],
        413114: ['Pune', 'Maharashtra'],
        413115: ['Pune', 'Maharashtra'],
        413116: ['Pune', 'Maharashtra'],
        413117: ['Pune', 'Maharashtra'],
        413118: ['Solapur', 'Maharashtra'],
        413120: ['Pune', 'Maharashtra'],
        413124: ['Pune', 'Maharashtra'],
        413130: ['Pune', 'Maharashtra'],
        413132: ['Pune', 'Maharashtra'],
        413133: ['Pune', 'Maharashtra'],
        413201: ['AhmedNagar', 'Maharashtra'],
        413202: ['Solapur', 'Maharashtra'],
        413203: ['Solapur', 'Maharashtra'],
        413205: ['AhmedNagar', 'Maharashtra'],
        413206: ['Solapur', 'Maharashtra'],
        413208: ['Solapur', 'Maharashtra'],
        413209: ['Solapur', 'Maharashtra'],
        413210: ['Solapur', 'Maharashtra'],
        413211: ['Solapur', 'Maharashtra'],
        413212: ['Solapur', 'Maharashtra'],
        413213: ['Solapur', 'Maharashtra'],
        413214: ['Solapur', 'Maharashtra'],
        413215: ['Solapur', 'Maharashtra'],
        413216: ['Solapur', 'Maharashtra'],
        413217: ['Solapur', 'Maharashtra'],
        413218: ['Solapur', 'Maharashtra'],
        413219: ['Solapur', 'Maharashtra'],
        413220: ['Solapur', 'Maharashtra'],
        413221: ['Solapur', 'Maharashtra'],
        413222: ['Solapur', 'Maharashtra'],
        413223: ['Solapur', 'Maharashtra'],
        413224: ['Solapur', 'Maharashtra'],
        413226: ['Solapur', 'Maharashtra'],
        413227: ['Solapur', 'Maharashtra'],
        413228: ['Solapur', 'Maharashtra'],
        413248: ['Solapur', 'Maharashtra'],
        413250: ['Solapur', 'Maharashtra'],
        413251: ['Solapur', 'Maharashtra'],
        413252: ['Solapur', 'Maharashtra'],
        413253: ['Solapur', 'Maharashtra'],
        413255: ['Solapur', 'Maharashtra'],
        413301: ['Solapur', 'Maharashtra'],
        413302: ['Solapur', 'Maharashtra'],
        413303: ['Solapur', 'Maharashtra'],
        413304: ['Solapur', 'Maharashtra'],
        413305: ['Solapur', 'Maharashtra'],
        413306: ['Solapur', 'Maharashtra'],
        413307: ['Solapur', 'Maharashtra'],
        413308: ['Solapur', 'Maharashtra'],
        413309: ['Solapur', 'Maharashtra'],
        413310: ['Solapur', 'Maharashtra'],
        413314: ['Solapur', 'Maharashtra'],
        413315: ['Solapur', 'Maharashtra'],
        413317: ['Solapur', 'Maharashtra'],
        413318: ['Sangli', 'Maharashtra'],
        413319: ['Solapur', 'Maharashtra'],
        413322: ['Solapur', 'Maharashtra'],
        413324: ['Solapur', 'Maharashtra'],
        413401: ['Solapur', 'Maharashtra'],
        413402: ['Solapur', 'Maharashtra'],
        413403: ['Solapur', 'Maharashtra'],
        413404: ['Solapur', 'Maharashtra'],
        413406: ['Solapur', 'Maharashtra'],
        413409: ['Solapur', 'Maharashtra'],
        413410: ['Solapur', 'Maharashtra'],
        413411: ['Solapur', 'Maharashtra'],
        413412: ['Solapur', 'Maharashtra'],
        413701: ['AhmedNagar', 'Maharashtra'],
        413702: ['AhmedNagar', 'Maharashtra'],
        413703: ['Ahmednagar', 'Maharashtra'],
        413704: ['AhmedNagar', 'Maharashtra'],
        413706: ['Ahmednagar', 'Maharashtra'],
        413708: ['AhmedNagar', 'Maharashtra'],
        413709: ['AhmedNagar', 'Maharashtra'],
        413710: ['AhmedNagar', 'Maharashtra'],
        413711: ['AhmedNagar', 'Maharashtra'],
        413712: ['AhmedNagar', 'Maharashtra'],
        413713: ['Ahmednagar', 'Maharashtra'],
        413714: ['AhmedNagar', 'Maharashtra'],
        413715: ['Ahmednagar', 'Maharashtra'],
        413716: ['Ahmednagar', 'Maharashtra'],
        413718: ['Ahmednagar', 'Maharashtra'],
        413719: ['Ahmednagar', 'Maharashtra'],
        413720: ['Ahmednagar', 'Maharashtra'],
        413721: ['AhmedNagar', 'Maharashtra'],
        413722: ['AhmedNagar', 'Maharashtra'],
        413723: ['AhmedNagar', 'Maharashtra'],
        413724: ['AhmedNagar', 'Maharashtra'],
        413725: ['Ahmednagar', 'Maharashtra'],
        413728: ['AhmedNagar', 'Maharashtra'],
        413736: ['Ahmednagar', 'Maharashtra'],
        413737: ['AhmedNagar', 'Maharashtra'],
        413739: ['AhmedNagar', 'Maharashtra'],
        413801: ['Pune', 'Maharashtra'],
        413802: ['Pune', 'Maharashtra'],
        414001: ['Ahmednagar', 'Maharashtra'],
        414002: ['Ahmednagar', 'Maharashtra'],
        414005: ['AhmedNagar', 'Maharashtra'],
        414101: ['AhmedNagar', 'Maharashtra'],
        414102: ['AhmedNagar', 'Maharashtra'],
        414103: ['Ahmednagar', 'Maharashtra'],
        414106: ['Ahmednagar', 'Maharashtra'],
        414201: ['Ahmednagar', 'Maharashtra'],
        414208: ['Pune', 'Maharashtra'],
        414301: ['AhmedNagar', 'Maharashtra'],
        414302: ['Ahmednagar', 'Maharashtra'],
        414303: ['AhmedNagar', 'Maharashtra'],
        414304: ['AhmedNagar', 'Maharashtra'],
        414305: ['AhmedNagar', 'Maharashtra'],
        414306: ['AhmedNagar', 'Maharashtra'],
        414402: ['AhmedNagar', 'Maharashtra'],
        414403: ['AhmedNagar', 'Maharashtra'],
        414405: ['AhmedNagar', 'Maharashtra'],
        414501: ['Ahmednagar', 'Maharashtra'],
        414502: ['AhmedNagar', 'Maharashtra'],
        414504: ['AhmedNagar', 'Maharashtra'],
        414505: ['AhmedNagar', 'Maharashtra'],
        414601: ['AhmedNagar', 'Maharashtra'],
        414602: ['AhmedNagar', 'Maharashtra'],
        414603: ['AhmedNagar', 'Maharashtra'],
        414604: ['Ahmednagar', 'Maharashtra'],
        414605: ['Ahmednagar', 'Maharashtra'],
        414606: ['Ahmednagar', 'Maharashtra'],
        414607: ['AhmedNagar', 'Maharashtra'],
        414701: ['AhmedNagar', 'Maharashtra'],
        414702: ['Ahmednagar', 'Maharashtra'],
        415101: ['Kolhapur', 'Maharashtra'],
        415301: ['Sangli', 'Maharashtra'],
        415302: ['Sangli', 'Maharashtra'],
        415303: ['Sangli', 'Maharashtra'],
        415304: ['Sangli', 'Maharashtra'],
        415305: ['Sangli', 'Maharashtra'],
        415306: ['Sangli', 'Maharashtra'],
        415307: ['Sangli', 'Maharashtra'],
        415308: ['Sangli', 'Maharashtra'],
        415309: ['Sangli', 'Maharashtra'],
        415310: ['Sangli', 'Maharashtra'],
        415311: ['Sangli', 'Maharashtra'],
        415313: ['Sangli', 'Maharashtra'],
        415315: ['Sangli', 'Maharashtra'],
        415401: ['Sangli', 'Maharashtra'],
        415402: ['Sangli', 'Maharashtra'],
        415403: ['Sangli', 'Maharashtra'],
        415404: ['Sangli', 'Maharashtra'],
        415405: ['Sangli', 'Maharashtra'],
        415406: ['Sangli', 'Maharashtra'],
        415407: ['Sangli', 'Maharashtra'],
        415408: ['Sangli', 'Maharashtra'],
        415409: ['Sangli', 'Maharashtra'],
        415410: ['Sangli', 'Maharashtra'],
        415411: ['Sangli', 'Maharashtra'],
        415412: ['Sangli', 'Maharashtra'],
        415413: ['Sangli', 'Maharashtra'],
        415414: ['Sangli', 'Maharashtra'],
        415415: ['Sangli', 'Maharashtra'],
        415416: ['Sangli', 'Maharashtra'],
        415417: ['Sangli', 'Maharashtra'],
        415418: ['Sangli', 'Maharashtra'],
        415705: ['AhmedNagar', 'Maharashtra'],
        416001: ['Kolhapur', 'Maharashtra'],
        416002: ['Kolhapur', 'Maharashtra'],
        416003: ['Kolhapur', 'Maharashtra'],
        416004: ['Kolhapur', 'Maharashtra'],
        416005: ['Kolhapur', 'Maharashtra'],
        416006: ['Kolhapur', 'Maharashtra'],
        416007: ['Kolhapur', 'Maharashtra'],
        416008: ['Kolhapur', 'Maharashtra'],
        416010: ['Kolhapur', 'Maharashtra'],
        416011: ['Kolhapur', 'Maharashtra'],
        416012: ['Kolhapur', 'Maharashtra'],
        416013: ['Kolhapur', 'Maharashtra'],
        416101: ['Kolhapur', 'Maharashtra'],
        416102: ['Kolhapur', 'Maharashtra'],
        416103: ['Kolhapur', 'Maharashtra'],
        416104: ['Kolhapur', 'Maharashtra'],
        416105: ['Kolhapur', 'Maharashtra'],
        416106: ['Kolhapur', 'Maharashtra'],
        416107: ['Kolhapur', 'Maharashtra'],
        416108: ['Kolhapur', 'Maharashtra'],
        416109: ['Kolhapur', 'Maharashtra'],
        416110: ['Kolhapur', 'Maharashtra'],
        416111: ['Kolhapur', 'Maharashtra'],
        416112: ['Kolhapur', 'Maharashtra'],
        416113: ['Kolhapur', 'Maharashtra'],
        416114: ['Kolhapur', 'Maharashtra'],
        416115: ['Kolhapur', 'Maharashtra'],
        416116: ['Kolhapur', 'Maharashtra'],
        416117: ['Kolhapur', 'Maharashtra'],
        416118: ['Kolhapur', 'Maharashtra'],
        416119: ['Kolhapur', 'Maharashtra'],
        416120: ['Kolhapur', 'Maharashtra'],
        416121: ['Kolhapur', 'Maharashtra'],
        416122: ['Kolhapur', 'Maharashtra'],
        416129: ['Kolhapur', 'Maharashtra'],
        416130: ['Kolhapur', 'Maharashtra'],
        416132: ['Kolhapur', 'Maharashtra'],
        416134: ['Kolhapur', 'Maharashtra'],
        416137: ['Kolhapur', 'Maharashtra'],
        416138: ['Goa', 'Goa'],
        416143: ['Kolhapur', 'Maharashtra'],
        416144: ['Kolhapur', 'Maharashtra'],
        416146: ['Kolhapur', 'Maharashtra'],
        416201: ['Kolhapur', 'Maharashtra'],
        416202: ['Kolhapur', 'Maharashtra'],
        416203: ['Kolhapur', 'Maharashtra'],
        416204: ['Kolhapur', 'Maharashtra'],
        416205: ['Kolhapur', 'Maharashtra'],
        416206: ['Kolhapur', 'Maharashtra'],
        416207: ['Kolhapur', 'Maharashtra'],
        416208: ['Kolhapur', 'Maharashtra'],
        416209: ['Kolhapur', 'Maharashtra'],
        416210: ['Kolhapur', 'Maharashtra'],
        416211: ['Kolhapur', 'Maharashtra'],
        416212: ['Kolhapur', 'Maharashtra'],
        416213: ['Kolhapur', 'Maharashtra'],
        416214: ['Kolhapur', 'Maharashtra'],
        416215: ['Kolhapur', 'Maharashtra'],
        416216: ['Kolhapur', 'Maharashtra'],
        416217: ['Kolhapur', 'Maharashtra'],
        416218: ['Kolhapur', 'Maharashtra'],
        416219: ['Kolhapur', 'Maharashtra'],
        416220: ['Kolhapur', 'Maharashtra'],
        416221: ['Kolhapur', 'Maharashtra'],
        416222: ['Kolhapur', 'Maharashtra'],
        416223: ['Kolhapur', 'Maharashtra'],
        416224: ['Kolhapur', 'Maharashtra'],
        416227: ['Kolhapur', 'Maharashtra'],
        416229: ['Kolhapur', 'Maharashtra'],
        416230: ['Kolhapur', 'Maharashtra'],
        416231: ['Kolhapur', 'Maharashtra'],
        416232: ['Kolhapur', 'Maharashtra'],
        416234: ['Kolhapur', 'Maharashtra'],
        416235: ['Kolhapur', 'Maharashtra'],
        416236: ['Kolhapur', 'Maharashtra'],
        416301: ['Sangli', 'Maharashtra'],
        416302: ['Sangli', 'Maharashtra'],
        416303: ['Sangli', 'Maharashtra'],
        416304: ['Sangli', 'Maharashtra'],
        416305: ['Sangli', 'Maharashtra'],
        416306: ['Sangli', 'Maharashtra'],
        416307: ['Sangli', 'Maharashtra'],
        416308: ['Sangli', 'Maharashtra'],
        416309: ['Sangli', 'Maharashtra'],
        416310: ['Sangli', 'Maharashtra'],
        416311: ['Sangli', 'Maharashtra'],
        416312: ['Sangli', 'Maharashtra'],
        416313: ['Sangli', 'Maharashtra'],
        416314: ['Sangli', 'Maharashtra'],
        416315: ['Sangli', 'Maharashtra'],
        416316: ['Sangli', 'Maharashtra'],
        416317: ['Sangli', 'Maharashtra'],
        416319: ['Sangli', 'Maharashtra'],
        416320: ['Sangli', 'Maharashtra'],
        416322: ['Sangli', 'Maharashtra'],
        416401: ['Sangli', 'Maharashtra'],
        416402: ['Sangli', 'Maharashtra'],
        416403: ['Sangli', 'Maharashtra'],
        416404: ['Sangli', 'Maharashtra'],
        416405: ['Sangli', 'Maharashtra'],
        416406: ['Sangli', 'Maharashtra'],
        416407: ['Sangli', 'Maharashtra'],
        416408: ['Sangli', 'Maharashtra'],
        416409: ['Sangli', 'Maharashtra'],
        416410: ['Sangli', 'Maharashtra'],
        416411: ['Sangli', 'Maharashtra'],
        416412: ['Sangli', 'Maharashtra'],
        416413: ['Sangli', 'Maharashtra'],
        416414: ['Sangli', 'Maharashtra'],
        416415: ['Sangli', 'Maharashtra'],
        416416: ['Sangli', 'Maharashtra'],
        416417: ['Sangli', 'Maharashtra'],
        416418: ['Sangli', 'Maharashtra'],
        416419: ['Sangli', 'Maharashtra'],
        416420: ['Sangli', 'Maharashtra'],
        416422: ['Sangli', 'Maharashtra'],
        416423: ['Sangli', 'Maharashtra'],
        416425: ['Sangli', 'Maharashtra'],
        416426: ['Sangli', 'Maharashtra'],
        416428: ['Sangli', 'Maharashtra'],
        416436: ['Sangli', 'Maharashtra'],
        416437: ['Sangli', 'Maharashtra'],
        416501: ['Kolhapur', 'Maharashtra'],
        416502: ['Kolhapur', 'Maharashtra'],
        416503: ['Kolhapur', 'Maharashtra'],
        416504: ['Kolhapur', 'Maharashtra'],
        416505: ['Kolhapur', 'Maharashtra'],
        416506: ['Kolhapur', 'Maharashtra'],
        416507: ['Kolhapur', 'Maharashtra'],
        416508: ['Kolhapur', 'Maharashtra'],
        416509: ['Kolhapur', 'Maharashtra'],
        416526: ['Kolhapur', 'Maharashtra'],
        416527: ['Kolhapur', 'Maharashtra'],
        416551: ['Kolhapur', 'Maharashtra'],
        416552: ['Kolhapur', 'Maharashtra'],
        421001: ['Thane', 'Maharashtra'],
        421002: ['Thane', 'Maharashtra'],
        421003: ['Thane', 'Maharashtra'],
        421004: ['Thane', 'Maharashtra'],
        421005: ['Thane', 'Maharashtra'],
        421101: ['Thane', 'Maharashtra'],
        421102: ['Thane', 'Maharashtra'],
        421103: ['Thane', 'Maharashtra'],
        421201: ['Thane', 'Maharashtra'],
        421202: ['Thane', 'Maharashtra'],
        421203: ['Thane', 'Maharashtra'],
        421204: ['Navi Mumbai', 'Maharashtra'],
        421206: ['Thane', 'Maharashtra'],
        421301: ['Thane', 'Maharashtra'],
        421302: ['Thane', 'Maharashtra'],
        421303: ['Thane', 'Maharashtra'],
        421305: ['Thane', 'Maharashtra'],
        421306: ['Thane', 'Maharashtra'],
        421308: ['Thane', 'Maharashtra'],
        421311: ['Thane', 'Maharashtra'],
        421312: ['Thane', 'Maharashtra'],
        421401: ['Thane', 'Maharashtra'],
        421402: ['Thane', 'Maharashtra'],
        421403: ['Thane', 'Maharashtra'],
        421501: ['Thane', 'Maharashtra'],
        421502: ['Thane', 'Maharashtra'],
        421503: ['Thane', 'Maharashtra'],
        421505: ['Thane', 'Maharashtra'],
        421506: ['Thane', 'Maharashtra'],
        421601: ['Thane', 'Maharashtra'],
        421602: ['Thane', 'Maharashtra'],
        421603: ['Thane', 'Maharashtra'],
        421604: ['Thane', 'Maharashtra'],
        421605: ['Thane', 'Maharashtra'],
        422001: ['Nashik', 'Maharashtra'],
        422002: ['Nashik', 'Maharashtra'],
        422003: ['Nashik', 'Maharashtra'],
        422004: ['Nashik', 'Maharashtra'],
        422005: ['Nashik', 'Maharashtra'],
        422006: ['Nashik', 'Maharashtra'],
        422007: ['Nashik', 'Maharashtra'],
        422008: ['Nashik', 'Maharashtra'],
        422009: ['Nashik', 'Maharashtra'],
        422010: ['Nashik', 'Maharashtra'],
        422011: ['Nashik', 'Maharashtra'],
        422012: ['Nashik', 'Maharashtra'],
        422013: ['Nashik', 'Maharashtra'],
        422101: ['Nashik', 'Maharashtra'],
        422102: ['Nashik', 'Maharashtra'],
        422103: ['Nashik', 'Maharashtra'],
        422104: ['Nashik', 'Maharashtra'],
        422105: ['Nashik', 'Maharashtra'],
        422112: ['Nashik', 'Maharashtra'],
        422113: ['Nashik', 'Maharashtra'],
        422201: ['Nashik', 'Maharashtra'],
        422202: ['Nashik', 'Maharashtra'],
        422203: ['Nashik', 'Maharashtra'],
        422204: ['Nashik', 'Maharashtra'],
        422205: ['Nashik', 'Maharashtra'],
        422206: ['Nashik', 'Maharashtra'],
        422207: ['Nashik', 'Maharashtra'],
        422208: ['Nashik', 'Maharashtra'],
        422209: ['Nashik', 'Maharashtra'],
        422210: ['Nashik', 'Maharashtra'],
        422211: ['Nashik', 'Maharashtra'],
        422212: ['Nashik', 'Maharashtra'],
        422213: ['Nashik', 'Maharashtra'],
        422214: ['Nashik', 'Maharashtra'],
        422215: ['Nashik', 'Maharashtra'],
        422221: ['Nashik', 'Maharashtra'],
        422222: ['Nashik', 'Maharashtra'],
        422301: ['Nashik', 'Maharashtra'],
        422302: ['Nashik', 'Maharashtra'],
        422303: ['Nashik', 'Maharashtra'],
        422304: ['Nashik', 'Maharashtra'],
        422305: ['Nashik', 'Maharashtra'],
        422306: ['Nashik', 'Maharashtra'],
        422308: ['Nashik', 'Maharashtra'],
        422401: ['Nashik', 'Maharashtra'],
        422402: ['Nashik', 'Maharashtra'],
        422403: ['Nashik', 'Maharashtra'],
        422501: ['Nashik', 'Maharashtra'],
        422502: ['Nashik', 'Maharashtra'],
        422601: ['AhmedNagar', 'Maharashtra'],
        422603: ['Ahmednagar', 'Maharashtra'],
        422605: ['AhmedNagar', 'Maharashtra'],
        422606: ['Nashik', 'Maharashtra'],
        422610: ['AhmedNagar', 'Maharashtra'],
        422611: ['AhmedNagar', 'Maharashtra'],
        422620: ['AhmedNagar', 'Maharashtra'],
        423101: ['Nashik', 'Maharashtra'],
        423102: ['Nashik', 'Maharashtra'],
        423103: ['Ahmednagar', 'Maharashtra'],
        423104: ['Nashik', 'Maharashtra'],
        423105: ['Nashik', 'Maharashtra'],
        423106: ['Nashik', 'Maharashtra'],
        423107: ['Ahmednagar', 'Maharashtra'],
        423108: ['Nashik', 'Maharashtra'],
        423109: ['AhmedNagar', 'Maharashtra'],
        423110: ['Nashik', 'Maharashtra'],
        423111: ['Nashik', 'Maharashtra'],
        423117: ['Nashik', 'Maharashtra'],
        423201: ['Nashik', 'Maharashtra'],
        423202: ['Nashik', 'Maharashtra'],
        423203: ['Nashik', 'Maharashtra'],
        423204: ['Nashik', 'Maharashtra'],
        423205: ['Nashik', 'Maharashtra'],
        423206: ['Nashik', 'Maharashtra'],
        423208: ['Nashik', 'Maharashtra'],
        423212: ['Nashik', 'Maharashtra'],
        423213: ['Nashik', 'Maharashtra'],
        423301: ['Nashik', 'Maharashtra'],
        423302: ['Nashik', 'Maharashtra'],
        423303: ['Nashik', 'Maharashtra'],
        423401: ['Nashik', 'Maharashtra'],
        423402: ['Nashik', 'Maharashtra'],
        423403: ['Nashik', 'Maharashtra'],
        423501: ['Nashik', 'Maharashtra'],
        423502: ['Nashik', 'Maharashtra'],
        423601: ['Ahmednagar', 'Maharashtra'],
        423602: ['AhmedNagar', 'Maharashtra'],
        423603: ['Ahmednagar', 'Maharashtra'],
        423605: ['AhmedNagar', 'Maharashtra'],
        423607: ['AhmedNagar', 'Maharashtra'],
        423701: ['Aurangabad', 'Maharashtra'],
        423702: ['Aurangabad', 'Maharashtra'],
        423703: ['Aurangabad', 'Maharashtra'],
        424109: ['Nashik', 'Maharashtra'],
        431001: ['Aurangabad', 'Maharashtra'],
        431002: ['Aurangabad', 'Maharashtra'],
        431003: ['Aurangabad', 'Maharashtra'],
        431004: ['Aurangabad', 'Maharashtra'],
        431005: ['Aurangabad', 'Maharashtra'],
        431006: ['Aurangabad', 'Maharashtra'],
        431007: ['Aurangabad', 'Maharashtra'],
        431008: ['Aurangabad', 'Maharashtra'],
        431009: ['Aurangabad', 'Maharashtra'],
        431010: ['Aurangabad', 'Maharashtra'],
        431011: ['Aurangabad', 'Maharashtra'],
        431101: ['Aurangabad', 'Maharashtra'],
        431102: ['Aurangabad', 'Maharashtra'],
        431103: ['Aurangabad', 'Maharashtra'],
        431104: ['Aurangabad', 'Maharashtra'],
        431105: ['Aurangabad', 'Maharashtra'],
        431106: ['Aurangabad', 'Maharashtra'],
        431107: ['Aurangabad', 'Maharashtra'],
        431108: ['Aurangabad', 'Maharashtra'],
        431109: ['Aurangabad', 'Maharashtra'],
        431110: ['Aurangabad', 'Maharashtra'],
        431111: ['Aurangabad', 'Maharashtra'],
        431112: ['Aurangabad', 'Maharashtra'],
        431113: ['Aurangabad', 'Maharashtra'],
        431115: ['Aurangabad', 'Maharashtra'],
        431116: ['Aurangabad', 'Maharashtra'],
        431117: ['Aurangabad', 'Maharashtra'],
        431118: ['Aurangabad', 'Maharashtra'],
        431119: ['Aurangabad', 'Maharashtra'],
        431120: ['Aurangabad', 'Maharashtra'],
        431121: ['Aurangabad', 'Maharashtra'],
        431132: ['Aurangabad', 'Maharashtra'],
        431133: ['Aurangabad', 'Maharashtra'],
        431134: ['Aurangabad', 'Maharashtra'],
        431135: ['Aurangabad', 'Maharashtra'],
        431136: ['Aurangabad', 'Maharashtra'],
        431137: ['Aurangabad', 'Maharashtra'],
        431140: ['Aurangabad', 'Maharashtra'],
        431147: ['Aurangabad', 'Maharashtra'],
        431148: ['Aurangabad', 'Maharashtra'],
        431150: ['Aurangabad', 'Maharashtra'],
        431151: ['Aurangabad', 'Maharashtra'],
        431152: ['Aurangabad', 'Maharashtra'],
        431154: ['Aurangabad', 'Maharashtra'],
        431201: ['Aurangabad', 'Maharashtra'],
        431210: ['Aurangabad', 'Maharashtra'],
        431212: ['Aurangabad', 'Maharashtra'],
        431213: ['Aurangabad', 'Maharashtra'],
        431501: ['Aurangabad', 'Maharashtra'],
        440001: ['Nagpur', 'Maharashtra'],
        440002: ['Nagpur', 'Maharashtra'],
        440003: ['Nagpur', 'Maharashtra'],
        440004: ['Nagpur', 'Maharashtra'],
        440005: ['Nagpur', 'Maharashtra'],
        440006: ['Nagpur', 'Maharashtra'],
        440007: ['Nagpur', 'Maharashtra'],
        440008: ['Nagpur', 'Maharashtra'],
        440009: ['Nagpur', 'Maharashtra'],
        440010: ['Nagpur', 'Maharashtra'],
        440011: ['Nagpur', 'Maharashtra'],
        440012: ['Nagpur', 'Maharashtra'],
        440013: ['Nagpur', 'Maharashtra'],
        440014: ['Nagpur', 'Maharashtra'],
        440015: ['Nagpur', 'Maharashtra'],
        440016: ['Nagpur', 'Maharashtra'],
        440017: ['Nagpur', 'Maharashtra'],
        440018: ['Nagpur', 'Maharashtra'],
        440019: ['Nagpur', 'Maharashtra'],
        440020: ['Nagpur', 'Maharashtra'],
        440021: ['Nagpur', 'Maharashtra'],
        440022: ['Nagpur', 'Maharashtra'],
        440023: ['Nagpur', 'Maharashtra'],
        440024: ['Nagpur', 'Maharashtra'],
        440025: ['Nagpur', 'Maharashtra'],
        440026: ['Nagpur', 'Maharashtra'],
        440027: ['Nagpur', 'Maharashtra'],
        440029: ['Nagpur', 'Maharashtra'],
        440030: ['Nagpur', 'Maharashtra'],
        440032: ['Nagpur', 'Maharashtra'],
        440033: ['Nagpur', 'Maharashtra'],
        440034: ['Nagpur', 'Maharashtra'],
        440035: ['Nagpur', 'Maharashtra'],
        440036: ['Nagpur', 'Maharashtra'],
        440037: ['Nagpur', 'Maharashtra'],
        441001: ['Nagpur', 'Maharashtra'],
        441101: ['Nagpur', 'Maharashtra'],
        441102: ['Nagpur', 'Maharashtra'],
        441103: ['Nagpur', 'Maharashtra'],
        441104: ['Nagpur', 'Maharashtra'],
        441105: ['Nagpur', 'Maharashtra'],
        441106: ['Nagpur', 'Maharashtra'],
        441107: ['Nagpur', 'Maharashtra'],
        441108: ['Nagpur', 'Maharashtra'],
        441109: ['Nagpur', 'Maharashtra'],
        441110: ['Nagpur', 'Maharashtra'],
        441111: ['Nagpur', 'Maharashtra'],
        441112: ['Nagpur', 'Maharashtra'],
        441113: ['Nagpur', 'Maharashtra'],
        441114: ['Nagpur', 'Maharashtra'],
        441115: ['Nagpur', 'Maharashtra'],
        441116: ['Nagpur', 'Maharashtra'],
        441117: ['Nagpur', 'Maharashtra'],
        441122: ['Nagpur', 'Maharashtra'],
        441123: ['Nagpur', 'Maharashtra'],
        441201: ['Nagpur', 'Maharashtra'],
        441202: ['Nagpur', 'Maharashtra'],
        441203: ['Nagpur', 'Maharashtra'],
        441204: ['Nagpur', 'Maharashtra'],
        441205: ['Chandrapur', 'Maharashtra'],
        441206: ['Chandrapur', 'Maharashtra'],
        441207: ['Chandrapur', 'Maharashtra'],
        441210: ['Nagpur', 'Maharashtra'],
        441212: ['Chandrapur', 'Maharashtra'],
        441214: ['Nagpur', 'Maharashtra'],
        441215: ['Chandrapur', 'Maharashtra'],
        441216: ['Nagpur', 'Maharashtra'],
        441221: ['Chandrapur', 'Maharashtra'],
        441222: ['Chandrapur', 'Maharashtra'],
        441223: ['Chandrapur', 'Maharashtra'],
        441224: ['Chandrapur', 'Maharashtra'],
        441225: ['Chandrapur', 'Maharashtra'],
        441226: ['Chandrapur', 'Maharashtra'],
        441228: ['Chandrapur', 'Maharashtra'],
        441301: ['Nagpur', 'Maharashtra'],
        441302: ['Nagpur', 'Maharashtra'],
        441303: ['Nagpur', 'Maharashtra'],
        441304: ['Nagpur', 'Maharashtra'],
        441305: ['Nagpur', 'Maharashtra'],
        441306: ['Nagpur', 'Maharashtra'],
        441401: ['Nagpur', 'Maharashtra'],
        441402: ['Nagpur', 'Maharashtra'],
        441403: ['Nagpur', 'Maharashtra'],
        441404: ['Nagpur', 'Maharashtra'],
        441406: ['Nagpur', 'Maharashtra'],
        441408: ['Nagpur', 'Maharashtra'],
        441409: ['Nagpur', 'Maharashtra'],
        441501: ['Nagpur', 'Maharashtra'],
        441502: ['Nagpur', 'Maharashtra'],
        441503: ['Nagpur', 'Maharashtra'],
        441806: ['Nagpur', 'Maharashtra'],
        441807: ['Nagpur', 'Maharashtra'],
        441911: ['Nagpur', 'Maharashtra'],
        441912: ['Nagpur', 'Maharashtra'],
        441913: ['Nagpur', 'Maharashtra'],
        441915: ['Chandrapur', 'Maharashtra'],
        442107: ['Nagpur', 'Maharashtra'],
        442202: ['Nagpur', 'Maharashtra'],
        442302: ['Amravati', 'Maharashtra'],
        442401: ['Chandrapur', 'Maharashtra'],
        442402: ['Chandrapur', 'Maharashtra'],
        442403: ['Chandrapur', 'Maharashtra'],
        442404: ['Chandrapur', 'Maharashtra'],
        442406: ['Chandrapur', 'Maharashtra'],
        442501: ['Chandrapur', 'Maharashtra'],
        442502: ['Chandrapur', 'Maharashtra'],
        442503: ['Chandrapur', 'Maharashtra'],
        442504: ['Chandrapur', 'Maharashtra'],
        442505: ['Chandrapur', 'Maharashtra'],
        442506: ['Chandrapur', 'Maharashtra'],
        442507: ['Chandrapur', 'Maharashtra'],
        442701: ['Chandrapur', 'Maharashtra'],
        442702: ['Chandrapur', 'Maharashtra'],
        442706: ['Chandrapur', 'Maharashtra'],
        442901: ['Chandrapur', 'Maharashtra'],
        442902: ['Chandrapur', 'Maharashtra'],
        442903: ['Chandrapur', 'Maharashtra'],
        442904: ['Chandrapur', 'Maharashtra'],
        442905: ['Chandrapur', 'Maharashtra'],
        442906: ['Chandrapur', 'Maharashtra'],
        442907: ['Chandrapur', 'Maharashtra'],
        442908: ['Chandrapur', 'Maharashtra'],
        442910: ['Chandrapur', 'Maharashtra'],
        442912: ['Chandrapur', 'Maharashtra'],
        442913: ['Chandrapur', 'Maharashtra'],
        442914: ['Chandrapur', 'Maharashtra'],
        442916: ['Chandrapur', 'Maharashtra'],
        442917: ['Chandrapur', 'Maharashtra'],
        442918: ['Chandrapur', 'Maharashtra'],
        442919: ['Nagpur', 'Maharashtra'],
        444110: ['Nagpur', 'Maharashtra'],
        444123: ['Amravati', 'Maharashtra'],
        444402: ['Chandrapur', 'Maharashtra'],
        444601: ['Amravati', 'Maharashtra'],
        444602: ['Amravati', 'Maharashtra'],
        444603: ['Amravati', 'Maharashtra'],
        444604: ['Amravati', 'Maharashtra'],
        444605: ['Amravati', 'Maharashtra'],
        444606: ['Amravati', 'Maharashtra'],
        444607: ['Amravati', 'Maharashtra'],
        444701: ['Amravati', 'Maharashtra'],
        444702: ['Amravati', 'Maharashtra'],
        444704: ['Amravati', 'Maharashtra'],
        444705: ['Amravati', 'Maharashtra'],
        444706: ['Amravati', 'Maharashtra'],
        444707: ['Amravati', 'Maharashtra'],
        444708: ['Amravati', 'Maharashtra'],
        444709: ['Amravati', 'Maharashtra'],
        444710: ['Amravati', 'Maharashtra'],
        444711: ['Amravati', 'Maharashtra'],
        444713: ['Amravati', 'Maharashtra'],
        444716: ['Amravati', 'Maharashtra'],
        444717: ['Amravati', 'Maharashtra'],
        444718: ['Amravati', 'Maharashtra'],
        444719: ['Amravati', 'Maharashtra'],
        444720: ['Amravati', 'Maharashtra'],
        444723: ['Amravati', 'Maharashtra'],
        444801: ['Amravati', 'Maharashtra'],
        444802: ['Amravati', 'Maharashtra'],
        444803: ['Amravati', 'Maharashtra'],
        444804: ['Amravati', 'Maharashtra'],
        444805: ['Amravati', 'Maharashtra'],
        444806: ['Amravati', 'Maharashtra'],
        444807: ['Amravati', 'Maharashtra'],
        444808: ['Amravati', 'Maharashtra'],
        444809: ['Amravati', 'Maharashtra'],
        444810: ['Amravati', 'Maharashtra'],
        444811: ['Amravati', 'Maharashtra'],
        444812: ['Amravati', 'Maharashtra'],
        444813: ['Amravati', 'Maharashtra'],
        444814: ['Amravati', 'Maharashtra'],
        444815: ['Amravati', 'Maharashtra'],
        444816: ['Amravati', 'Maharashtra'],
        444818: ['Amravati', 'Maharashtra'],
        444819: ['Amravati', 'Maharashtra'],
        444821: ['Amravati', 'Maharashtra'],
        444826: ['Amravati', 'Maharashtra'],
        444827: ['Amravati', 'Maharashtra'],
        444901: ['Amravati', 'Maharashtra'],
        444902: ['Amravati', 'Maharashtra'],
        444903: ['Amravati', 'Maharashtra'],
        444904: ['Amravati', 'Maharashtra'],
        444905: ['Amravati', 'Maharashtra'],
        444906: ['Amravati', 'Maharashtra'],
        444907: ['Amravati', 'Maharashtra'],
        444908: ['Amravati', 'Maharashtra'],
        444909: ['Amravati', 'Maharashtra'],
        444914: ['Amravati', 'Maharashtra'],
        444915: ['Amravati', 'Maharashtra'],
        445324: ['Nagpur', 'Maharashtra'],
        452001: ['Indore', 'Madhya Pradesh'],
        452002: ['Indore', 'Madhya Pradesh'],
        452003: ['Indore', 'Madhya Pradesh'],
        452004: ['Indore', 'Madhya Pradesh'],
        452005: ['Indore', 'Madhya Pradesh'],
        452006: ['Indore', 'Madhya Pradesh'],
        452007: ['Indore', 'Madhya Pradesh'],
        452008: ['Indore', 'Madhya Pradesh'],
        452009: ['Indore', 'Madhya Pradesh'],
        452010: ['Indore', 'Madhya Pradesh'],
        452011: ['Indore', 'Madhya Pradesh'],
        452012: ['Indore', 'Madhya Pradesh'],
        452013: ['Indore', 'Madhya Pradesh'],
        452014: ['Indore', 'Madhya Pradesh'],
        452015: ['Indore', 'Madhya Pradesh'],
        452016: ['Indore', 'Madhya Pradesh'],
        452018: ['Indore', 'Madhya Pradesh'],
        452020: ['Indore', 'Madhya Pradesh'],
        453001: ['Indore', 'Madhya Pradesh'],
        453111: ['Indore', 'Madhya Pradesh'],
        453112: ['Indore', 'Madhya Pradesh'],
        453115: ['Indore', 'Madhya Pradesh'],
        453220: ['Indore', 'Madhya Pradesh'],
        453235: ['Indore', 'Madhya Pradesh'],
        453331: ['Indore', 'Madhya Pradesh'],
        453332: ['Indore', 'Madhya Pradesh'],
        453441: ['Indore', 'Madhya Pradesh'],
        453446: ['Indore', 'Madhya Pradesh'],
        453463: ['Indore', 'Madhya Pradesh'],
        453551: ['Indore', 'Madhya Pradesh'],
        453552: ['Indore', 'Madhya Pradesh'],
        453555: ['Indore', 'Madhya Pradesh'],
        453556: ['Indore', 'Madhya Pradesh'],
        453661: ['Indore', 'Madhya Pradesh'],
        453771: ['Indore', 'Madhya Pradesh'],
        455115: ['Indore', 'Madhya Pradesh'],
        456001: ['Ujjain', 'Madhya Pradesh'],
        456003: ['Ujjain', 'Madhya Pradesh'],
        456006: ['Ujjain', 'Madhya Pradesh'],
        456010: ['Ujjain', 'Madhya Pradesh'],
        456221: ['Ujjain', 'Madhya Pradesh'],
        456222: ['Ujjain', 'Madhya Pradesh'],
        456224: ['Ujjain', 'Madhya Pradesh'],
        456313: ['Ujjain', 'Madhya Pradesh'],
        456331: ['Ujjain', 'Madhya Pradesh'],
        456335: ['Ujjain', 'Madhya Pradesh'],
        456337: ['Ujjain', 'Madhya Pradesh'],
        456440: ['Ujjain', 'Madhya Pradesh'],
        456441: ['Ujjain', 'Madhya Pradesh'],
        456443: ['Ujjain', 'Madhya Pradesh'],
        456457: ['Ujjain', 'Madhya Pradesh'],
        456550: ['Ujjain', 'Madhya Pradesh'],
        456661: ['Ujjain', 'Madhya Pradesh'],
        456664: ['Ujjain', 'Madhya Pradesh'],
        456665: ['Ujjain', 'Madhya Pradesh'],
        456668: ['Ujjain', 'Madhya Pradesh'],
        456770: ['Ujjain', 'Madhya Pradesh'],
        456771: ['Ujjain', 'Madhya Pradesh'],
        456776: ['Ujjain', 'Madhya Pradesh'],
        462001: ['Bhopal', 'Madhya Pradesh'],
        462002: ['Bhopal', 'Madhya Pradesh'],
        462003: ['Bhopal', 'Madhya Pradesh'],
        462004: ['Bhopal', 'Madhya Pradesh'],
        462007: ['Bhopal', 'Madhya Pradesh'],
        462008: ['Bhopal', 'Madhya Pradesh'],
        462010: ['Bhopal', 'Madhya Pradesh'],
        462011: ['Bhopal', 'Madhya Pradesh'],
        462012: ['Bhopal', 'Madhya Pradesh'],
        462013: ['Bhopal', 'Madhya Pradesh'],
        462016: ['Bhopal', 'Madhya Pradesh'],
        462018: ['Bhopal', 'Madhya Pradesh'],
        462020: ['Bhopal', 'Madhya Pradesh'],
        462021: ['Bhopal', 'Madhya Pradesh'],
        462022: ['Bhopal', 'Madhya Pradesh'],
        462023: ['Bhopal', 'Madhya Pradesh'],
        462024: ['Bhopal', 'Madhya Pradesh'],
        462026: ['Bhopal', 'Madhya Pradesh'],
        462027: ['Bhopal', 'Madhya Pradesh'],
        462030: ['Bhopal', 'Madhya Pradesh'],
        462031: ['Bhopal', 'Madhya Pradesh'],
        462032: ['Bhopal', 'Madhya Pradesh'],
        462033: ['Bhopal', 'Madhya Pradesh'],
        462036: ['Bhopal', 'Madhya Pradesh'],
        462037: ['Bhopal', 'Madhya Pradesh'],
        462038: ['Bhopal', 'Madhya Pradesh'],
        462039: ['Bhopal', 'Madhya Pradesh'],
        462040: ['Bhopal', 'Madhya Pradesh'],
        462041: ['Bhopal', 'Madhya Pradesh'],
        462042: ['Bhopal', 'Madhya Pradesh'],
        462043: ['Bhopal', 'Madhya Pradesh'],
        462044: ['Bhopal', 'Madhya Pradesh'],
        462045: ['Bhopal', 'Madhya Pradesh'],
        462046: ['Bhopal', 'Madhya Pradesh'],
        462047: ['Bhopal', 'Madhya Pradesh'],
        462066: ['Bhopal', 'Madhya Pradesh'],
        462100: ['Bhopal', 'Madhya Pradesh'],
        462101: ['Bhopal', 'Madhya Pradesh'],
        462120: ['Bhopal', 'Madhya Pradesh'],
        463106: ['Bhopal', 'Madhya Pradesh'],
        463111: ['Bhopal', 'Madhya Pradesh'],
        463120: ['Bhopal', 'Madhya Pradesh'],
        464334: ['Bhopal', 'Madhya Pradesh'],
        464570: ['Bhopal', 'Madhya Pradesh'],
        473445: ['Indore', 'Madhya Pradesh'],
        481883: ['Bhopal', 'Madhya Pradesh'],
        482001: ['Jabalpur', 'Madhya Pradesh'],
        483225: ['Raipur', 'Madhya Pradesh'],
        484447: ['Bhopal', 'Madhya Pradesh'],
        486004: ['Bhopal', 'Madhya Pradesh'],
        490011: ['Bhilai', 'Madhya Pradesh'],
        490022: ['Bhilai', 'Madhya Pradesh'],
        490023: ['Bhilai', 'Madhya Pradesh'],
        490036: ['Bhilai', 'Madhya Pradesh'],
        490042: ['Bhilai', 'Madhya Pradesh'],
        492001: ['Raipur', 'Chhattisgarh'],
        492002: ['Raipur', 'Chhattisgarh'],
        492003: ['Raipur', 'Chhattisgarh'],
        492004: ['Raipur', 'Chhattisgarh'],
        492005: ['Raipur', 'Chhattisgarh'],
        492006: ['Raipur', 'Madhya Pradesh'],
        492007: ['Raipur', 'Madhya Pradesh'],
        492008: ['Raipur', 'Chhattisgarh'],
        492009: ['Raipur', 'Chhattisgarh'],
        492010: ['Raipur', 'Chhattisgarh'],
        492012: ['Raipur', 'Chhattisgarh'],
        492013: ['Raipur', 'Chhattisgarh'],
        492014: ['Raipur', 'Chhattisgarh'],
        492015: ['Raipur', 'Chhattisgarh'],
        492099: ['Raipur', 'Chhattisgarh'],
        492101: ['Raipur', 'Chhattisgarh'],
        492109: ['Raipur', 'Chhattisgarh'],
        492112: ['Raipur', 'Madhya Pradesh'],
        493101: ['Raipur', 'Chhattisgarh'],
        493111: ['Raipur', 'Chhattisgarh'],
        493113: ['Raipur', 'Chhattisgarh'],
        493114: ['Raipur', 'Chhattisgarh'],
        493116: ['Raipur', 'Chhattisgarh'],
        493118: ['Raipur', 'Chhattisgarh'],
        493195: ['Raipur', 'Chhattisgarh'],
        493196: ['Raipur', 'Chhattisgarh'],
        493221: ['Raipur', 'Chhattisgarh'],
        493222: ['Raipur', 'Chhattisgarh'],
        493225: ['Raipur', 'Chhattisgarh'],
        493228: ['Raipur', 'Chhattisgarh'],
        493229: ['Raipur', 'Chhattisgarh'],
        493330: ['Raipur', 'Madhya Pradesh'],
        493331: ['Raipur', 'Chhattisgarh'],
        493332: ['Raipur', 'Chhattisgarh'],
        493335: ['Raipur', 'Chhattisgarh'],
        493338: ['Raipur', 'Chhattisgarh'],
        493344: ['Raipur', 'Chhattisgarh'],
        493441: ['Raipur', 'Chhattisgarh'],
        493445: ['Raipur', 'Madhya Pradesh'],
        493449: ['Raipur', 'Madhya Pradesh'],
        493526: ['Raipur', 'Chhattisgarh'],
        493551: ['Raipur', 'Madhya Pradesh'],
        493555: ['Raipur', 'Madhya Pradesh'],
        493558: ['Raipur', 'Madhya Pradesh'],
        493559: ['Raipur', 'Chhattisgarh'],
        493562: ['Raipur', 'Madhya Pradesh'],
        493661: ['Raipur', 'Chhattisgarh'],
        493663: ['Raipur', 'Madhya Pradesh'],
        493773: ['Raipur', 'Madhya Pradesh'],
        493778: ['Raipur', 'Madhya Pradesh'],
        493881: ['Raipur', 'Chhattisgarh'],
        493885: ['Raipur', 'Chhattisgarh'],
        493888: ['Raipur', 'Chhattisgarh'],
        493889: ['Raipur', 'Chhattisgarh'],
        493890: ['Raipur', 'Chhattisgarh'],
        493895: ['Raipur', 'Madhya Pradesh'],
        493992: ['Raipur', 'Chhattisgarh'],
        493996: ['Raipur', 'Chhattisgarh'],
        500001: ['Hyderabad', 'Telangana'],
        500002: ['Hyderabad', 'Telangana'],
        500003: ['Hyderabad', 'Telangana'],
        500004: ['Hyderabad', 'Telangana'],
        500005: ['Hyderabad', 'Telangana'],
        500006: ['Hyderabad', 'Telangana'],
        500007: ['Hyderabad', 'Telangana'],
        500008: ['Hyderabad', 'Telangana'],
        500009: ['Hyderabad', 'Telangana'],
        500010: ['Hyderabad', 'Telangana'],
        500011: ['Hyderabad', 'Telangana'],
        500012: ['Hyderabad', 'Telangana'],
        500013: ['Hyderabad', 'Telangana'],
        500014: ['Hyderabad', 'Telangana'],
        500015: ['Hyderabad', 'Telangana'],
        500016: ['Hyderabad', 'Telangana'],
        500017: ['Hyderabad', 'Telangana'],
        500018: ['Hyderabad', 'Telangana'],
        500020: ['Hyderabad', 'Telangana'],
        500021: ['Hyderabad', 'Telangana'],
        500022: ['Hyderabad', 'Telangana'],
        500023: ['Hyderabad', 'Telangana'],
        500024: ['Hyderabad', 'Telangana'],
        500025: ['Hyderabad', 'Telangana'],
        500026: ['Hyderabad', 'Telangana'],
        500027: ['Hyderabad', 'Telangana'],
        500028: ['Hyderabad', 'Telangana'],
        500029: ['Hyderabad', 'Telangana'],
        500031: ['Hyderabad', 'Telangana'],
        500033: ['Hyderabad', 'Telangana'],
        500034: ['Hyderabad', 'Telangana'],
        500036: ['Hyderabad', 'Telangana'],
        500037: ['Hyderabad', 'Telangana'],
        500038: ['Hyderabad', 'Telangana'],
        500039: ['Hyderabad', 'Telangana'],
        500040: ['Hyderabad', 'Telangana'],
        500041: ['Hyderabad', 'Telangana'],
        500042: ['Hyderabad', 'Telangana'],
        500043: ['Hyderabad', 'Telangana'],
        500044: ['Hyderabad', 'Telangana'],
        500045: ['Hyderabad', 'Telangana'],
        500046: ['Hyderabad', 'Telangana'],
        500047: ['Hyderabad', 'Telangana'],
        500048: ['Hyderabad', 'Telangana'],
        500049: ['Hyderabad', 'Telangana'],
        500050: ['Hyderabad', 'Telangana'],
        500051: ['Hyderabad', 'Telangana'],
        500053: ['Hyderabad', 'Telangana'],
        500054: ['Hyderabad', 'Telangana'],
        500055: ['Hyderabad', 'Telangana'],
        500056: ['Hyderabad', 'Telangana'],
        500057: ['Hyderabad', 'Telangana'],
        500058: ['Hyderabad', 'Telangana'],
        500059: ['Hyderabad', 'Telangana'],
        500061: ['Hyderabad', 'Telangana'],
        500062: ['Hyderabad', 'Telangana'],
        500063: ['Hyderabad', 'Telangana'],
        500064: ['Hyderabad', 'Telangana'],
        500065: ['Hyderabad', 'Telangana'],
        500066: ['Hyderabad', 'Telangana'],
        500067: ['Hyderabad', 'Telangana'],
        500068: ['Hyderabad', 'Telangana'],
        500069: ['Hyderabad', 'Telangana'],
        500072: ['Hyderabad', 'Telangana'],
        500073: ['Hyderabad', 'Telangana'],
        500074: ['Hyderabad', 'Telangana'],
        500076: ['Hyderabad', 'Telangana'],
        500077: ['Hyderabad', 'Telangana'],
        500078: ['Hyderabad', 'Telangana'],
        500080: ['Hyderabad', 'Telangana'],
        500081: ['Hyderabad', 'Telangana'],
        500082: ['Hyderabad', 'Telangana'],
        500083: ['Hyderabad', 'Telangana'],
        500085: ['Hyderabad', 'Telangana'],
        500086: ['Hyderabad', 'Telangana'],
        500087: ['Hyderabad', 'Telangana'],
        500088: ['Hyderabad', 'Telangana'],
        500089: ['Hyderabad', 'Telangana'],
        500091: ['Hyderabad', 'Telangana'],
        500092: ['Hyderabad', 'Telangana'],
        500093: ['Hyderabad', 'Telangana'],
        500094: ['Hyderabad', 'Telangana'],
        500095: ['Hyderabad', 'Telangana'],
        500096: ['Hyderabad', 'Telangana'],
        500097: ['Hyderabad', 'Telangana'],
        500098: ['Hyderabad', 'Telangana'],
        500100: ['Secunderabad', 'Telangana'],
        500101: ['Hyderabad', 'Telangana'],
        500103: ['Hyderabad', 'Telangana'],
        500104: ['Hyderabad', 'Telangana'],
        500105: ['Hyderabad', 'Telangana'],
        500107: ['Hyderabad', 'Telangana'],
        500108: ['Hyderabad', 'Telangana'],
        500110: ['Hyderabad', 'Telangana'],
        500111: ['Hyderabad', 'Telangana'],
        500133: ['Hyderabad', 'Telangana'],
        500253: ['Hyderabad', 'Telangana'],
        500258: ['Hyderabad', 'Telangana'],
        500264: ['Hyderabad', 'Telangana'],
        500265: ['Hyderabad', 'Telangana'],
        500266: ['Hyderabad', 'Telangana'],
        500267: ['Hyderabad', 'Telangana'],
        500361: ['Hyderabad', 'Telangana'],
        500380: ['Hyderabad', 'Telangana'],
        500457: ['Hyderabad', 'Telangana'],
        500463: ['Hyderabad', 'Telangana'],
        500482: ['Hyderabad', 'Telangana'],
        500486: ['Hyderabad', 'Telangana'],
        500556: ['Hyderabad', 'Telangana'],
        500587: ['Hyderabad', 'Telangana'],
        500593: ['Hyderabad', 'Telangana'],
        500594: ['Hyderabad', 'Telangana'],
        500660: ['Hyderabad', 'Telangana'],
        500762: ['Hyderabad', 'Telangana'],
        500855: ['Hyderabad', 'Telangana'],
        500872: ['Hyderabad', 'Telangana'],
        500873: ['Hyderabad', 'Telangana'],
        500890: ['Hyderabad', 'Telangana'],
        501101: ['Hyderabad', 'Telangana'],
        501102: ['Hyderabad', 'Telangana'],
        501301: ['Hyderabad', 'Telangana'],
        501401: ['Secunderabad', 'Telangana'],
        501505: ['Hyderabad', 'Telangana'],
        506103: ['Hyderabad', 'Telangana'],
        506146: ['Hyderabad', 'Telangana'],
        509133: ['Hyderabad', 'Telangana'],
        516228: ['Belgaum', 'Karnataka'],
        517101: ['Tirupati', 'Andhra Pradesh'],
        524347: ['Vijayawada', 'Andhra Pradesh'],
        530001: ['Visakhapatnam', 'Andhra Pradesh'],
        530002: ['Visakhapatnam', 'Andhra Pradesh'],
        530003: ['Visakhapatnam', 'Andhra Pradesh'],
        530004: ['Visakhapatnam', 'Andhra Pradesh'],
        530005: ['Visakhapatnam', 'Andhra Pradesh'],
        530006: ['Visakhapatnam', 'Andhra Pradesh'],
        530007: ['Visakhapatnam', 'Andhra Pradesh'],
        530008: ['Visakhapatnam', 'Andhra Pradesh'],
        530009: ['Visakhapatnam', 'Andhra Pradesh'],
        530010: ['Visakhapatnam', 'Andhra Pradesh'],
        530011: ['Visakhapatnam', 'Andhra Pradesh'],
        530012: ['Visakhapatnam', 'Andhra Pradesh'],
        530013: ['Visakhapatnam', 'Andhra Pradesh'],
        530014: ['Visakhapatnam', 'Andhra Pradesh'],
        530015: ['Visakhapatnam', 'Andhra Pradesh'],
        530016: ['Visakhapatnam', 'Andhra Pradesh'],
        530017: ['Visakhapatnam', 'Andhra Pradesh'],
        530018: ['Visakhapatnam', 'Andhra Pradesh'],
        530020: ['Visakhapatnam', 'Andhra Pradesh'],
        530021: ['Visakhapatnam', 'Andhra Pradesh'],
        530022: ['Visakhapatnam', 'Andhra Pradesh'],
        530023: ['Visakhapatnam', 'Andhra Pradesh'],
        530024: ['Visakhapatnam', 'Andhra Pradesh'],
        530026: ['Visakhapatnam', 'Andhra Pradesh'],
        530027: ['Visakhapatnam', 'Andhra Pradesh'],
        530028: ['Visakhapatnam', 'Andhra Pradesh'],
        530029: ['Visakhapatnam', 'Andhra Pradesh'],
        530031: ['Visakhapatnam', 'Andhra Pradesh'],
        530032: ['Visakhapatnam', 'Andhra Pradesh'],
        530035: ['Visakhapatnam', 'Andhra Pradesh'],
        530040: ['Visakhapatnam', 'Andhra Pradesh'],
        530041: ['Visakhapatnam', 'Andhra Pradesh'],
        530042: ['Visakhapatnam', 'Andhra Pradesh'],
        530043: ['Visakhapatnam', 'Andhra Pradesh'],
        530044: ['Visakhapatnam', 'Andhra Pradesh'],
        530045: ['Visakhapatnam', 'Andhra Pradesh'],
        530046: ['Visakhapatnam', 'Andhra Pradesh'],
        530047: ['Visakhapatnam', 'Andhra Pradesh'],
        530048: ['Visakhapatnam', 'Andhra Pradesh'],
        530049: ['Visakhapatnam', 'Andhra Pradesh'],
        530051: ['Visakhapatnam', 'Andhra Pradesh'],
        530052: ['Visakhapatnam', 'Andhra Pradesh'],
        530053: ['Visakhapatnam', 'Andhra Pradesh'],
        531001: ['Visakhapatnam', 'Andhra Pradesh'],
        531002: ['Visakhapatnam', 'Andhra Pradesh'],
        531011: ['Visakhapatnam', 'Andhra Pradesh'],
        531019: ['Visakhapatnam', 'Andhra Pradesh'],
        531020: ['Visakhapatnam', 'Andhra Pradesh'],
        531021: ['Visakhapatnam', 'Andhra Pradesh'],
        531022: ['Visakhapatnam', 'Andhra Pradesh'],
        531023: ['Visakhapatnam', 'Andhra Pradesh'],
        531024: ['Visakhapatnam', 'Andhra Pradesh'],
        531025: ['Visakhapatnam', 'Andhra Pradesh'],
        531026: ['Visakhapatnam', 'Andhra Pradesh'],
        531027: ['Visakhapatnam', 'Andhra Pradesh'],
        531028: ['Visakhapatnam', 'Andhra Pradesh'],
        531029: ['Visakhapatnam', 'Andhra Pradesh'],
        531030: ['Visakhapatnam', 'Andhra Pradesh'],
        531031: ['Visakhapatnam', 'Andhra Pradesh'],
        531032: ['Visakhapatnam', 'Andhra Pradesh'],
        531033: ['Visakhapatnam', 'Andhra Pradesh'],
        531034: ['Visakhapatnam', 'Andhra Pradesh'],
        531035: ['Visakhapatnam', 'Andhra Pradesh'],
        531036: ['Visakhapatnam', 'Andhra Pradesh'],
        531040: ['Visakhapatnam', 'Andhra Pradesh'],
        531055: ['Visakhapatnam', 'Andhra Pradesh'],
        531060: ['Visakhapatnam', 'Andhra Pradesh'],
        531061: ['Visakhapatnam', 'Andhra Pradesh'],
        531075: ['Visakhapatnam', 'Andhra Pradesh'],
        531077: ['Visakhapatnam', 'Andhra Pradesh'],
        531081: ['Visakhapatnam', 'Andhra Pradesh'],
        531082: ['Visakhapatnam', 'Andhra Pradesh'],
        531083: ['Visakhapatnam', 'Andhra Pradesh'],
        531084: ['Visakhapatnam', 'Andhra Pradesh'],
        531085: ['Visakhapatnam', 'Andhra Pradesh'],
        531087: ['Visakhapatnam', 'Andhra Pradesh'],
        531105: ['Visakhapatnam', 'Andhra Pradesh'],
        531110: ['Visakhapatnam', 'Andhra Pradesh'],
        531111: ['Visakhapatnam', 'Andhra Pradesh'],
        531113: ['Visakhapatnam', 'Andhra Pradesh'],
        531114: ['Visakhapatnam', 'Andhra Pradesh'],
        531115: ['Visakhapatnam', 'Andhra Pradesh'],
        531116: ['Visakhapatnam', 'Andhra Pradesh'],
        531117: ['Visakhapatnam', 'Andhra Pradesh'],
        531118: ['Visakhapatnam', 'Andhra Pradesh'],
        531126: ['Visakhapatnam', 'Andhra Pradesh'],
        531127: ['Visakhapatnam', 'Andhra Pradesh'],
        531133: ['Visakhapatnam', 'Andhra Pradesh'],
        531145: ['Visakhapatnam', 'Andhra Pradesh'],
        531148: ['Visakhapatnam', 'Andhra Pradesh'],
        531149: ['Visakhapatnam', 'Andhra Pradesh'],
        531151: ['Visakhapatnam', 'Andhra Pradesh'],
        531152: ['Visakhapatnam', 'Andhra Pradesh'],
        531160: ['Visakhapatnam', 'Andhra Pradesh'],
        531161: ['Visakhapatnam', 'Andhra Pradesh'],
        531162: ['Visakhapatnam', 'Andhra Pradesh'],
        531163: ['Visakhapatnam', 'Andhra Pradesh'],
        531172: ['Visakhapatnam', 'Andhra Pradesh'],
        531173: ['Visakhapatnam', 'Andhra Pradesh'],
        531219: ['Visakhapatnam', 'Andhra Pradesh'],
        531240: ['Visakhapatnam', 'Andhra Pradesh'],
        531281: ['Visakhapatnam', 'Andhra Pradesh'],
        533347: ['Visakhapatnam', 'Andhra Pradesh'],
        533348: ['Visakhapatnam', 'Andhra Pradesh'],
        533349: ['Visakhapatnam', 'Andhra Pradesh'],
        533350: ['Visakhapatnam', 'Andhra Pradesh'],
        533351: ['Visakhapatnam', 'Andhra Pradesh'],
        533352: ['Visakhapatnam', 'Andhra Pradesh'],
        534435: ['Vijayawada', 'Andhra Pradesh'],
        534444: ['Vijayawada', 'Andhra Pradesh'],
        535005: ['Visakhapatnam', 'Andhra Pradesh'],
        535145: ['Visakhapatnam', 'Andhra Pradesh'],
        560001: ['Bangalore', 'Karnataka'],
        560002: ['Bangalore', 'Karnataka'],
        560003: ['Bangalore', 'Karnataka'],
        560004: ['Bangalore', 'Karnataka'],
        560005: ['Bangalore', 'Karnataka'],
        560006: ['Bangalore', 'Karnataka'],
        560007: ['Bangalore', 'Karnataka'],
        560008: ['Bangalore', 'Karnataka'],
        560009: ['Bangalore', 'Karnataka'],
        560010: ['Bangalore', 'Karnataka'],
        560011: ['Bangalore', 'Karnataka'],
        560012: ['Bangalore', 'Karnataka'],
        560013: ['Bangalore', 'Karnataka'],
        560014: ['Bangalore', 'Karnataka'],
        560015: ['Bangalore', 'Karnataka'],
        560016: ['Bangalore', 'Karnataka'],
        560017: ['Bangalore', 'Karnataka'],
        560018: ['Bangalore', 'Karnataka'],
        560019: ['Bangalore', 'Karnataka'],
        560020: ['Bangalore', 'Karnataka'],
        560021: ['Bangalore', 'Karnataka'],
        560022: ['Bangalore', 'Karnataka'],
        560023: ['Bangalore', 'Karnataka'],
        560024: ['Bangalore', 'Karnataka'],
        560025: ['Bangalore', 'Karnataka'],
        560026: ['Bangalore', 'Karnataka'],
        560027: ['Bangalore', 'Karnataka'],
        560028: ['Bangalore', 'Karnataka'],
        560029: ['Bangalore', 'Karnataka'],
        560030: ['Bangalore', 'Karnataka'],
        560031: ['Bangalore', 'Karnataka'],
        560032: ['Bangalore', 'Karnataka'],
        560033: ['Bangalore', 'Karnataka'],
        560034: ['Bangalore', 'Karnataka'],
        560035: ['Bangalore', 'Karnataka'],
        560036: ['Bangalore', 'Karnataka'],
        560037: ['Bangalore', 'Karnataka'],
        560038: ['Bangalore', 'Karnataka'],
        560039: ['Bangalore', 'Karnataka'],
        560040: ['Bangalore', 'Karnataka'],
        560041: ['Bangalore', 'Karnataka'],
        560042: ['Bangalore', 'Karnataka'],
        560043: ['Bangalore', 'Karnataka'],
        560044: ['Bangalore', 'Karnataka'],
        560045: ['Bangalore', 'Karnataka'],
        560046: ['Bangalore', 'Karnataka'],
        560047: ['Bangalore', 'Karnataka'],
        560048: ['Bangalore', 'Karnataka'],
        560049: ['Bangalore', 'Karnataka'],
        560050: ['Bangalore', 'Karnataka'],
        560051: ['Bangalore', 'Karnataka'],
        560052: ['Bangalore', 'Karnataka'],
        560053: ['Bangalore', 'Karnataka'],
        560054: ['Bangalore', 'Karnataka'],
        560055: ['Bangalore', 'Karnataka'],
        560056: ['Bangalore', 'Karnataka'],
        560057: ['Bangalore', 'Karnataka'],
        560058: ['Bangalore', 'Karnataka'],
        560059: ['Bangalore', 'Karnataka'],
        560060: ['Bangalore', 'Karnataka'],
        560061: ['Bangalore', 'Karnataka'],
        560062: ['Bangalore', 'Karnataka'],
        560063: ['Bangalore', 'Karnataka'],
        560064: ['Bangalore', 'Karnataka'],
        560065: ['Bangalore', 'Karnataka'],
        560066: ['Bangalore', 'Karnataka'],
        560067: ['Bangalore', 'Karnataka'],
        560068: ['Bangalore', 'Karnataka'],
        560069: ['Bangalore', 'Karnataka'],
        560070: ['Bangalore', 'Karnataka'],
        560071: ['Bangalore', 'Karnataka'],
        560072: ['Bangalore', 'Karnataka'],
        560073: ['Bangalore', 'Karnataka'],
        560074: ['Bangalore', 'Karnataka'],
        560075: ['Bangalore', 'Karnataka'],
        560076: ['Bangalore', 'Karnataka'],
        560077: ['Bangalore', 'Karnataka'],
        560078: ['Bangalore', 'Karnataka'],
        560079: ['Bangalore', 'Karnataka'],
        560080: ['Bangalore', 'Karnataka'],
        560081: ['Bangalore', 'Karnataka'],
        560082: ['Bangalore', 'Karnataka'],
        560083: ['Bangalore', 'Karnataka'],
        560084: ['Bangalore', 'Karnataka'],
        560085: ['Bangalore', 'Karnataka'],
        560086: ['Bangalore', 'Karnataka'],
        560087: ['Bangalore', 'Karnataka'],
        560088: ['Bangalore', 'Karnataka'],
        560089: ['Bangalore', 'Karnataka'],
        560090: ['Bangalore', 'Karnataka'],
        560091: ['Bangalore', 'Karnataka'],
        560092: ['Bangalore', 'Karnataka'],
        560093: ['Bangalore', 'Karnataka'],
        560094: ['Bangalore', 'Karnataka'],
        560095: ['Bangalore', 'Karnataka'],
        560096: ['Bangalore', 'Karnataka'],
        560097: ['Bangalore', 'Karnataka'],
        560098: ['Bangalore', 'Karnataka'],
        560099: ['Bangalore', 'Karnataka'],
        560100: ['Bangalore', 'Karnataka'],
        560102: ['Bangalore', 'Karnataka'],
        560103: ['Bangalore', 'Karnataka'],
        560104: ['Bangalore', 'Karnataka'],
        560105: ['Bangalore', 'Karnataka'],
        560107: ['Bangalore', 'Karnataka'],
        560108: ['Bangalore', 'Karnataka'],
        560109: ['Bangalore', 'Karnataka'],
        560110: ['Bangalore', 'Karnataka'],
        560111: ['Bangalore', 'Karnataka'],
        560112: ['Bangalore', 'Karnataka'],
        560113: ['Bangalore', 'Karnataka'],
        560114: ['Bangalore', 'Karnataka'],
        560115: ['Bangalore', 'Karnataka'],
        560116: ['Bangalore', 'Karnataka'],
        560117: ['Bangalore', 'Karnataka'],
        560300: ['Bangalore', 'Karnataka'],
        560500: ['Bangalore', 'Karnataka'],
        561101: ['Bangalore', 'Karnataka'],
        561201: ['Bangalore', 'Karnataka'],
        561203: ['Bangalore', 'Karnataka'],
        561204: ['Bangalore', 'Karnataka'],
        561205: ['Bangalore', 'Karnataka'],
        561214: ['Bangalore', 'Karnataka'],
        561215: ['Bangalore', 'Karnataka'],
        561216: ['Bangalore', 'Karnataka'],
        561226: ['Bangalore', 'Karnataka'],
        561227: ['Bangalore', 'Karnataka'],
        562101: ['Bangalore', 'Karnataka'],
        562106: ['Bangalore', 'Karnataka'],
        562107: ['Bangalore', 'Karnataka'],
        562108: ['Bangalore', 'Karnataka'],
        562109: ['Bangalore', 'Karnataka'],
        562110: ['Bangalore', 'Karnataka'],
        562111: ['Bangalore', 'Karnataka'],
        562112: ['Bangalore', 'Karnataka'],
        562114: ['Bangalore', 'Karnataka'],
        562115: ['Bangalore', 'Karnataka'],
        562117: ['Bangalore', 'Karnataka'],
        562119: ['Bangalore', 'Karnataka'],
        562120: ['Bangalore', 'Karnataka'],
        562121: ['Bangalore', 'Karnataka'],
        562122: ['Bangalore', 'Karnataka'],
        562123: ['Bangalore', 'Karnataka'],
        562124: ['Bangalore', 'Karnataka'],
        562125: ['Bangalore', 'Karnataka'],
        562126: ['Bangalore', 'Karnataka'],
        562127: ['Bangalore', 'Karnataka'],
        562128: ['Bangalore', 'Karnataka'],
        562129: ['Bangalore', 'Karnataka'],
        562130: ['Bangalore', 'Karnataka'],
        562131: ['Bangalore', 'Karnataka'],
        562132: ['Bangalore', 'Karnataka'],
        562134: ['Bangalore', 'Karnataka'],
        562135: ['Bangalore', 'Karnataka'],
        562136: ['Bangalore', 'Karnataka'],
        562137: ['Bangalore', 'Karnataka'],
        562138: ['Bangalore', 'Karnataka'],
        562139: ['Bangalore', 'Karnataka'],
        562140: ['Bangalore', 'Karnataka'],
        562142: ['Bangalore', 'Karnataka'],
        562143: ['Bangalore', 'Karnataka'],
        562144: ['Bangalore', 'Karnataka'],
        562145: ['Bangalore', 'Karnataka'],
        562147: ['Bangalore', 'Karnataka'],
        562148: ['Bangalore', 'Karnataka'],
        562149: ['Bangalore', 'Karnataka'],
        562150: ['Bangalore', 'Karnataka'],
        562151: ['Bangalore', 'Karnataka'],
        562152: ['Bangalore', 'Karnataka'],
        562153: ['Bangalore', 'Karnataka'],
        562154: ['Bangalore', 'Karnataka'],
        562157: ['Bangalore', 'Karnataka'],
        562162: ['Bangalore', 'Karnataka'],
        562163: ['Bangalore', 'Karnataka'],
        562164: ['Bangalore', 'Karnataka'],
        570001: ['Mysore', 'Karnataka'],
        570002: ['Mysore', 'Karnataka'],
        570003: ['Mysore', 'Karnataka'],
        570004: ['Mysore', 'Karnataka'],
        570005: ['Mysore', 'Karnataka'],
        570006: ['Mysore', 'Karnataka'],
        570007: ['Mysore', 'Karnataka'],
        570008: ['Mysore', 'Karnataka'],
        570009: ['Mysore', 'Karnataka'],
        570010: ['Mysore', 'Karnataka'],
        570011: ['Mysore', 'Karnataka'],
        570012: ['Mysore', 'Karnataka'],
        570014: ['Mysore', 'Karnataka'],
        570015: ['Mysore', 'Karnataka'],
        570016: ['Mysore', 'Karnataka'],
        570017: ['Mysore', 'Karnataka'],
        570018: ['Mysore', 'Karnataka'],
        570019: ['Mysore', 'Karnataka'],
        570020: ['Mysore', 'Karnataka'],
        570021: ['Mysore', 'Karnataka'],
        570022: ['Mysore', 'Karnataka'],
        570023: ['Mysore', 'Karnataka'],
        570024: ['Mysore', 'Karnataka'],
        570025: ['Mysore', 'Karnataka'],
        570026: ['Mysore', 'Karnataka'],
        570027: ['Mysore', 'Karnataka'],
        570028: ['Mysore', 'Karnataka'],
        570029: ['Mysore', 'Karnataka'],
        570030: ['Mysore', 'Karnataka'],
        570031: ['Mysore', 'Karnataka'],
        571101: ['Mysore', 'Karnataka'],
        571102: ['Mysore', 'Karnataka'],
        571103: ['Mysore', 'Karnataka'],
        571104: ['Mysore', 'Karnataka'],
        571105: ['Mysore', 'Karnataka'],
        571106: ['Mysore', 'Karnataka'],
        571107: ['Mysore', 'Karnataka'],
        571108: ['Mysore', 'Karnataka'],
        571109: ['Mysore', 'Karnataka'],
        571110: ['Mysore', 'Karnataka'],
        571111: ['Mysore', 'Karnataka'],
        571114: ['Mysore', 'Karnataka'],
        571115: ['Mysore', 'Karnataka'],
        571116: ['Mysore', 'Karnataka'],
        571117: ['Mysore', 'Karnataka'],
        571118: ['Mysore', 'Karnataka'],
        571119: ['Mysore', 'Karnataka'],
        571120: ['Mysore', 'Karnataka'],
        571121: ['Mysore', 'Karnataka'],
        571122: ['Mysore', 'Karnataka'],
        571123: ['Mysore', 'Karnataka'],
        571124: ['Mysore', 'Karnataka'],
        571125: ['Mysore', 'Karnataka'],
        571126: ['Mysore', 'Karnataka'],
        571127: ['Mysore', 'Karnataka'],
        571128: ['Mysore', 'Karnataka'],
        571129: ['Mysore', 'Karnataka'],
        571130: ['Mysore', 'Karnataka'],
        571131: ['Mysore', 'Karnataka'],
        571132: ['Mysore', 'Karnataka'],
        571134: ['Mysore', 'Karnataka'],
        571135: ['Mysore', 'Karnataka'],
        571136: ['Mysore', 'Karnataka'],
        571137: ['Mysore', 'Karnataka'],
        571138: ['Mysore', 'Karnataka'],
        571139: ['Mysore', 'Karnataka'],
        571140: ['Mysore', 'Karnataka'],
        571141: ['Mysore', 'Karnataka'],
        571142: ['Mysore', 'Karnataka'],
        571143: ['Mysore', 'Karnataka'],
        571145: ['Mysore', 'Karnataka'],
        571146: ['Mysore', 'Karnataka'],
        571147: ['Mysore', 'Karnataka'],
        571148: ['Mysore', 'Karnataka'],
        571149: ['Mysore', 'Karnataka'],
        571150: ['Mysore', 'Karnataka'],
        571151: ['Mysore', 'Karnataka'],
        571152: ['Mysore', 'Karnataka'],
        571153: ['Mysore', 'Karnataka'],
        571154: ['Mysore', 'Karnataka'],
        571155: ['Mysore', 'Karnataka'],
        571156: ['Mysore', 'Karnataka'],
        571158: ['Mysore', 'Karnataka'],
        571159: ['Mysore', 'Karnataka'],
        571162: ['Mysore', 'Karnataka'],
        571163: ['Mysore', 'Karnataka'],
        571164: ['Mysore', 'Karnataka'],
        571165: ['Mysore', 'Karnataka'],
        571166: ['Mysore', 'Karnataka'],
        571167: ['Mysore', 'Karnataka'],
        571168: ['Mysore', 'Karnataka'],
        571169: ['Mysore', 'Karnataka'],
        571170: ['Mysore', 'Karnataka'],
        571171: ['Mysore', 'Karnataka'],
        571172: ['Mysore', 'Karnataka'],
        571173: ['Mysore', 'Karnataka'],
        571174: ['Mysore', 'Karnataka'],
        571175: ['Mysore', 'Karnataka'],
        571176: ['Mysore', 'Karnataka'],
        571177: ['Mysore', 'Karnataka'],
        571178: ['Mysore', 'Karnataka'],
        571180: ['Mysore', 'Karnataka'],
        571181: ['Mysore', 'Karnataka'],
        571182: ['Mysore', 'Karnataka'],
        571183: ['Mysore', 'Karnataka'],
        571184: ['Mysore', 'Karnataka'],
        571185: ['Mysore', 'Karnataka'],
        571187: ['Mysore', 'Karnataka'],
        571189: ['Mysore', 'Karnataka'],
        571246: ['Mysore', 'Karnataka'],
        571264: ['Mysore', 'Karnataka'],
        571301: ['Mysore', 'Karnataka'],
        571302: ['Mysore', 'Karnataka'],
        571311: ['Mysore', 'Karnataka'],
        571312: ['Mysore', 'Karnataka'],
        571313: ['Mysore', 'Karnataka'],
        571314: ['Mysore', 'Karnataka'],
        571315: ['Mysore', 'Karnataka'],
        571316: ['Mysore', 'Karnataka'],
        571317: ['Mysore', 'Karnataka'],
        571318: ['Mysore', 'Karnataka'],
        571319: ['Mysore', 'Karnataka'],
        571321: ['Mysore', 'Karnataka'],
        571322: ['Mysore', 'Karnataka'],
        571323: ['Mysore', 'Karnataka'],
        571324: ['Mysore', 'Karnataka'],
        571325: ['Mysore', 'Karnataka'],
        571326: ['Mysore', 'Karnataka'],
        571327: ['Mysore', 'Karnataka'],
        571328: ['Mysore', 'Karnataka'],
        571331: ['Mysore', 'Karnataka'],
        571332: ['Mysore', 'Karnataka'],
        571333: ['Mysore', 'Karnataka'],
        571334: ['Mysore', 'Karnataka'],
        571335: ['Mysore', 'Karnataka'],
        571336: ['Mysore', 'Karnataka'],
        571337: ['Mysore', 'Karnataka'],
        571339: ['Mysore', 'Karnataka'],
        571340: ['Mysore', 'Karnataka'],
        571341: ['Mysore', 'Karnataka'],
        571415: ['Mysore', 'Karnataka'],
        571417: ['Mysore', 'Karnataka'],
        571426: ['Mysore', 'Karnataka'],
        571436: ['Mysore', 'Karnataka'],
        571439: ['Mysore', 'Karnataka'],
        571440: ['Mysore', 'Karnataka'],
        571441: ['Mysore', 'Karnataka'],
        571442: ['Mysore', 'Karnataka'],
        571444: ['Mysore', 'Karnataka'],
        571457: ['Mysore', 'Karnataka'],
        571460: ['Mysore', 'Karnataka'],
        571467: ['Mysore', 'Karnataka'],
        571476: ['Mysore', 'Karnataka'],
        571477: ['Mysore', 'Karnataka'],
        571478: ['Mysore', 'Karnataka'],
        571479: ['Mysore', 'Karnataka'],
        571480: ['Mysore', 'Karnataka'],
        571484: ['Mysore', 'Karnataka'],
        571488: ['Mysore', 'Karnataka'],
        571489: ['Mysore', 'Karnataka'],
        571490: ['Mysore', 'Karnataka'],
        571491: ['Mysore', 'Karnataka'],
        571492: ['Mysore', 'Karnataka'],
        571493: ['Mysore', 'Karnataka'],
        571494: ['Mysore', 'Karnataka'],
        571495: ['Mysore', 'Karnataka'],
        571498: ['Mysore', 'Karnataka'],
        571499: ['Mysore', 'Karnataka'],
        571501: ['Bangalore', 'Karnataka'],
        571502: ['Bangalore', 'Karnataka'],
        571511: ['Bangalore', 'Karnataka'],
        571512: ['Bangalore', 'Karnataka'],
        571601: ['Mysore', 'Karnataka'],
        571602: ['Mysore', 'Karnataka'],
        571603: ['Mysore', 'Karnataka'],
        571604: ['Mysore', 'Karnataka'],
        571605: ['Mysore', 'Karnataka'],
        571607: ['Mysore', 'Karnataka'],
        571608: ['Mysore', 'Karnataka'],
        571609: ['Mysore', 'Karnataka'],
        571610: ['Mysore', 'Karnataka'],
        571611: ['Mysore', 'Karnataka'],
        571613: ['Mysore', 'Karnataka'],
        571614: ['Mysore', 'Karnataka'],
        571615: ['Mysore', 'Karnataka'],
        571616: ['Mysore', 'Karnataka'],
        571617: ['Mysore', 'Karnataka'],
        571801: ['Mysore', 'Karnataka'],
        571803: ['Mysore', 'Karnataka'],
        571804: ['Mysore', 'Karnataka'],
        571805: ['Mysore', 'Karnataka'],
        571808: ['Mysore', 'Karnataka'],
        571809: ['Mysore', 'Karnataka'],
        571811: ['Mysore', 'Karnataka'],
        571812: ['Mysore', 'Karnataka'],
        572157: ['Mysore', 'Karnataka'],
        574101: ['Udupi', 'Karnataka'],
        574102: ['Udupi', 'Karnataka'],
        574103: ['Udupi', 'Karnataka'],
        574104: ['Udupi', 'Karnataka'],
        574105: ['Udupi', 'Karnataka'],
        574106: ['Udupi', 'Karnataka'],
        574107: ['Udupi', 'Karnataka'],
        574108: ['Udupi', 'Karnataka'],
        574109: ['Udupi', 'Karnataka'],
        574110: ['Udupi', 'Karnataka'],
        574111: ['Udupi', 'Karnataka'],
        574112: ['Udupi', 'Karnataka'],
        574113: ['Udupi', 'Karnataka'],
        574114: ['Udupi', 'Karnataka'],
        574115: ['Udupi', 'Karnataka'],
        574116: ['Udupi', 'Karnataka'],
        574117: ['Udupi', 'Karnataka'],
        574118: ['Udupi', 'Karnataka'],
        574119: ['Udupi', 'Karnataka'],
        574122: ['Udupi', 'Karnataka'],
        574129: ['Udupi', 'Karnataka'],
        574142: ['Mangalore', 'Karnataka'],
        574144: ['Udupi', 'Karnataka'],
        574173: ['Mangalore', 'Karnataka'],
        574244: ['Udupi', 'Karnataka'],
        575001: ['Mangalore', 'Karnataka'],
        575002: ['Mangalore', 'Karnataka'],
        575003: ['Mangalore', 'Karnataka'],
        575004: ['Mangalore', 'Karnataka'],
        575005: ['Mangalore', 'Karnataka'],
        575006: ['Mangalore', 'Karnataka'],
        575008: ['Mangalore', 'Karnataka'],
        575010: ['Mangalore', 'Karnataka'],
        575011: ['Mangalore', 'Karnataka'],
        575013: ['Mangalore', 'Karnataka'],
        575014: ['Mangalore', 'Karnataka'],
        575015: ['Mangalore', 'Karnataka'],
        575016: ['Mangalore', 'Karnataka'],
        575019: ['Mangalore', 'Karnataka'],
        575028: ['Mangalore', 'Karnataka'],
        575029: ['Mangalore', 'Karnataka'],
        575030: ['Mangalore', 'Karnataka'],
        576101: ['Udupi', 'Karnataka'],
        576102: ['Udupi', 'Karnataka'],
        576103: ['Udupi', 'Karnataka'],
        576104: ['Udupi', 'Karnataka'],
        576105: ['Udupi', 'Karnataka'],
        576106: ['Udupi', 'Karnataka'],
        576107: ['Udupi', 'Karnataka'],
        576108: ['Udupi', 'Karnataka'],
        576111: ['Udupi', 'Karnataka'],
        576112: ['Udupi', 'Karnataka'],
        576113: ['Udupi', 'Karnataka'],
        576114: ['Udupi', 'Karnataka'],
        576115: ['Udupi', 'Karnataka'],
        576117: ['Udupi', 'Karnataka'],
        576120: ['Udupi', 'Karnataka'],
        576121: ['Udupi', 'Karnataka'],
        576122: ['Udupi', 'Karnataka'],
        576124: ['Udupi', 'Karnataka'],
        576201: ['Udupi', 'Karnataka'],
        576210: ['Udupi', 'Karnataka'],
        576211: ['Udupi', 'Karnataka'],
        576212: ['Udupi', 'Karnataka'],
        576213: ['Udupi', 'Karnataka'],
        576214: ['Udupi', 'Karnataka'],
        576215: ['Udupi', 'Karnataka'],
        576216: ['Udupi', 'Karnataka'],
        576217: ['Udupi', 'Karnataka'],
        576218: ['Udupi', 'Karnataka'],
        576219: ['Udupi', 'Karnataka'],
        576220: ['Udupi', 'Karnataka'],
        576221: ['Udupi', 'Karnataka'],
        576222: ['Udupi', 'Karnataka'],
        576223: ['Udupi', 'Karnataka'],
        576224: ['Udupi', 'Karnataka'],
        576225: ['Udupi', 'Karnataka'],
        576226: ['Udupi', 'Karnataka'],
        576227: ['Udupi', 'Karnataka'],
        576228: ['Udupi', 'Karnataka'],
        576229: ['Udupi', 'Karnataka'],
        576230: ['Udupi', 'Karnataka'],
        576231: ['Udupi', 'Karnataka'],
        576232: ['Udupi', 'Karnataka'],
        576233: ['Udupi', 'Karnataka'],
        576234: ['Udupi', 'Karnataka'],
        576235: ['Udupi', 'Karnataka'],
        576247: ['Udupi', 'Karnataka'],
        576257: ['Udupi', 'Karnataka'],
        576282: ['Udupi', 'Karnataka'],
        576283: ['Udupi', 'Karnataka'],
        577001: ['DAVANGERE', 'Karnataka'],
        577002: ['DAVANGERE', 'Karnataka'],
        577003: ['DAVANGERE', 'Karnataka'],
        577154: ['Mysore', 'Karnataka'],
        577173: ['Mysore', 'Karnataka'],
        580003: ['Belgaum', 'Karnataka'],
        581229: ['Belgaum', 'Karnataka'],
        586154: ['Belgaum', 'Karnataka'],
        586226: ['Raipur', 'Karnataka'],
        587124: ['Belgaum', 'Karnataka'],
        590001: ['Belgaum', 'Karnataka'],
        590003: ['Belgaum', 'Karnataka'],
        590004: ['Belgaum', 'Karnataka'],
        590005: ['Belgaum', 'Karnataka'],
        590006: ['Belgaum', 'Karnataka'],
        590007: ['Belgaum', 'Karnataka'],
        590008: ['Belgaum', 'Karnataka'],
        590009: ['Belgaum', 'Karnataka'],
        590010: ['Belgaum', 'Karnataka'],
        590011: ['Belgaum', 'Karnataka'],
        590012: ['Belgaum', 'Karnataka'],
        590013: ['Belgaum', 'Karnataka'],
        590014: ['Belgaum', 'Karnataka'],
        590015: ['Belgaum', 'Karnataka'],
        590016: ['Belgaum', 'Karnataka'],
        590017: ['Belgaum', 'Karnataka'],
        590018: ['Belgaum', 'Karnataka'],
        590019: ['Belgaum', 'Karnataka'],
        590020: ['Belgaum', 'Karnataka'],
        591101: ['Belgaum', 'Karnataka'],
        591102: ['Belgaum', 'Karnataka'],
        591103: ['Belgaum', 'Karnataka'],
        591104: ['Belgaum', 'Karnataka'],
        591106: ['Belgaum', 'Karnataka'],
        591107: ['Belgaum', 'Karnataka'],
        591108: ['Belgaum', 'Karnataka'],
        591109: ['Belgaum', 'Karnataka'],
        591110: ['Belgaum', 'Karnataka'],
        591111: ['Belgaum', 'Karnataka'],
        591112: ['Belgaum', 'Karnataka'],
        591113: ['Belgaum', 'Karnataka'],
        591114: ['Belgaum', 'Karnataka'],
        591115: ['Belgaum', 'Karnataka'],
        591116: ['Belgaum', 'Karnataka'],
        591117: ['Belgaum', 'Karnataka'],
        591118: ['Belgaum', 'Karnataka'],
        591119: ['Belgaum', 'Karnataka'],
        591120: ['Belgaum', 'Karnataka'],
        591121: ['Belgaum', 'Karnataka'],
        591122: ['Belgaum', 'Karnataka'],
        591123: ['Belgaum', 'Karnataka'],
        591124: ['Belgaum', 'Karnataka'],
        591125: ['Belgaum', 'Karnataka'],
        591126: ['Belgaum', 'Karnataka'],
        591127: ['Belgaum', 'Karnataka'],
        591128: ['Belgaum', 'Karnataka'],
        591129: ['Belgaum', 'Karnataka'],
        591130: ['Belgaum', 'Karnataka'],
        591131: ['Belgaum', 'Karnataka'],
        591132: ['Belgaum', 'Karnataka'],
        591134: ['Belgaum', 'Karnataka'],
        591135: ['Belgaum', 'Karnataka'],
        591136: ['Belgaum', 'Karnataka'],
        591137: ['Belgaum', 'Karnataka'],
        591138: ['Belgaum', 'Karnataka'],
        591139: ['Belgaum', 'Karnataka'],
        591140: ['Belgaum', 'Karnataka'],
        591141: ['Belgaum', 'Karnataka'],
        591142: ['Belgaum', 'Karnataka'],
        591143: ['Belgaum', 'Karnataka'],
        591144: ['Belgaum', 'Karnataka'],
        591145: ['Belgaum', 'Karnataka'],
        591146: ['Belgaum', 'Karnataka'],
        591147: ['Belgaum', 'Karnataka'],
        591148: ['Belgaum', 'Karnataka'],
        591149: ['Belgaum', 'Karnataka'],
        591150: ['Belgaum', 'Karnataka'],
        591152: ['Belgaum', 'Karnataka'],
        591153: ['Belgaum', 'Karnataka'],
        591154: ['Belgaum', 'Karnataka'],
        591155: ['Belgaum', 'Karnataka'],
        591156: ['Belgaum', 'Karnataka'],
        591157: ['Belgaum', 'Karnataka'],
        591158: ['Belgaum', 'Karnataka'],
        591159: ['Belgaum', 'Karnataka'],
        591160: ['Belgaum', 'Karnataka'],
        591161: ['Belgaum', 'Karnataka'],
        591162: ['Belgaum', 'Karnataka'],
        591163: ['Belgaum', 'Karnataka'],
        591164: ['Belgaum', 'Karnataka'],
        591167: ['Belgaum', 'Karnataka'],
        591168: ['Belgaum', 'Karnataka'],
        591171: ['Belgaum', 'Karnataka'],
        591173: ['Belgaum', 'Karnataka'],
        591175: ['Belgaum', 'Karnataka'],
        591176: ['Belgaum', 'Karnataka'],
        591177: ['Belgaum', 'Karnataka'],
        591178: ['Belgaum', 'Karnataka'],
        591180: ['Belgaum', 'Karnataka'],
        591181: ['Belgaum', 'Karnataka'],
        591182: ['Belgaum', 'Karnataka'],
        591183: ['Belgaum', 'Karnataka'],
        591184: ['Belgaum', 'Karnataka'],
        591185: ['Belgaum', 'Karnataka'],
        591186: ['Belgaum', 'Karnataka'],
        591187: ['Belgaum', 'Karnataka'],
        591188: ['Belgaum', 'Karnataka'],
        591189: ['Belgaum', 'Karnataka'],
        591190: ['Belgaum', 'Karnataka'],
        591191: ['Belgaum', 'Karnataka'],
        591192: ['Belgaum', 'Karnataka'],
        591193: ['Belgaum', 'Karnataka'],
        591194: ['Belgaum', 'Karnataka'],
        591195: ['Belgaum', 'Karnataka'],
        591196: ['Belgaum', 'Karnataka'],
        591197: ['Belgaum', 'Karnataka'],
        591198: ['Belgaum', 'Karnataka'],
        591201: ['Belgaum', 'Karnataka'],
        591211: ['Belgaum', 'Karnataka'],
        591212: ['Belgaum', 'Karnataka'],
        591213: ['Belgaum', 'Karnataka'],
        591214: ['Belgaum', 'Karnataka'],
        591215: ['Belgaum', 'Karnataka'],
        591216: ['Belgaum', 'Karnataka'],
        591217: ['Belgaum', 'Karnataka'],
        591218: ['Belgaum', 'Karnataka'],
        591219: ['Belgaum', 'Karnataka'],
        591220: ['Belgaum', 'Karnataka'],
        591221: ['Belgaum', 'Karnataka'],
        591222: ['Belgaum', 'Karnataka'],
        591223: ['Belgaum', 'Karnataka'],
        591224: ['Belgaum', 'Karnataka'],
        591225: ['Belgaum', 'Karnataka'],
        591226: ['Belgaum', 'Karnataka'],
        591227: ['Belgaum', 'Karnataka'],
        591228: ['Belgaum', 'Karnataka'],
        591229: ['Belgaum', 'Karnataka'],
        591230: ['Belgaum', 'Karnataka'],
        591231: ['Belgaum', 'Karnataka'],
        591232: ['Belgaum', 'Karnataka'],
        591233: ['Belgaum', 'Karnataka'],
        591234: ['Belgaum', 'Karnataka'],
        591235: ['Belgaum', 'Karnataka'],
        591236: ['Belgaum', 'Karnataka'],
        591237: ['Belgaum', 'Karnataka'],
        591238: ['Belgaum', 'Karnataka'],
        591239: ['Belgaum', 'Karnataka'],
        591240: ['Belgaum', 'Karnataka'],
        591241: ['Belgaum', 'Karnataka'],
        591242: ['Belgaum', 'Karnataka'],
        591243: ['Belgaum', 'Karnataka'],
        591244: ['Belgaum', 'Karnataka'],
        591245: ['Belgaum', 'Karnataka'],
        591246: ['Belgaum', 'Karnataka'],
        591247: ['Belgaum', 'Karnataka'],
        591248: ['Belgaum', 'Karnataka'],
        591249: ['Belgaum', 'Karnataka'],
        591250: ['Belgaum', 'Karnataka'],
        591251: ['Belgaum', 'Karnataka'],
        591252: ['Belgaum', 'Karnataka'],
        591253: ['Belgaum', 'Karnataka'],
        591254: ['Belgaum', 'Karnataka'],
        591255: ['Belgaum', 'Karnataka'],
        591256: ['Belgaum', 'Karnataka'],
        591257: ['Belgaum', 'Karnataka'],
        591258: ['Belgaum', 'Karnataka'],
        591259: ['Belgaum', 'Karnataka'],
        591260: ['Belgaum', 'Karnataka'],
        591261: ['Belgaum', 'Karnataka'],
        591262: ['Belgaum', 'Karnataka'],
        591263: ['Belgaum', 'Karnataka'],
        591264: ['Belgaum', 'Karnataka'],
        591265: ['Belgaum', 'Karnataka'],
        591266: ['Belgaum', 'Karnataka'],
        591267: ['Belgaum', 'Karnataka'],
        591268: ['Belgaum', 'Karnataka'],
        591269: ['Belgaum', 'Karnataka'],
        591270: ['Belgaum', 'Karnataka'],
        591271: ['Belgaum', 'Karnataka'],
        591272: ['Belgaum', 'Karnataka'],
        591273: ['Belgaum', 'Karnataka'],
        591274: ['Belgaum', 'Karnataka'],
        591275: ['Belgaum', 'Karnataka'],
        591276: ['Belgaum', 'Karnataka'],
        591278: ['Belgaum', 'Karnataka'],
        591279: ['Belgaum', 'Karnataka'],
        591280: ['Belgaum', 'Karnataka'],
        591281: ['Belgaum', 'Karnataka'],
        591282: ['Belgaum', 'Karnataka'],
        591283: ['Belgaum', 'Karnataka'],
        591284: ['Belgaum', 'Karnataka'],
        591285: ['Belgaum', 'Karnataka'],
        591287: ['Belgaum', 'Karnataka'],
        591288: ['Belgaum', 'Karnataka'],
        591289: ['Belgaum', 'Karnataka'],
        591290: ['Belgaum', 'Karnataka'],
        591291: ['Belgaum', 'Karnataka'],
        591292: ['Belgaum', 'Karnataka'],
        591293: ['Belgaum', 'Karnataka'],
        591295: ['Belgaum', 'Karnataka'],
        591296: ['Belgaum', 'Karnataka'],
        591297: ['Belgaum', 'Karnataka'],
        591298: ['Belgaum', 'Karnataka'],
        591299: ['Belgaum', 'Karnataka'],
        591301: ['Belgaum', 'Karnataka'],
        591302: ['Belgaum', 'Karnataka'],
        591303: ['Belgaum', 'Karnataka'],
        591304: ['Belgaum', 'Karnataka'],
        591305: ['Belgaum', 'Karnataka'],
        591306: ['Belgaum', 'Karnataka'],
        591307: ['Belgaum', 'Karnataka'],
        591308: ['Belgaum', 'Karnataka'],
        591309: ['Belgaum', 'Karnataka'],
        591310: ['Belgaum', 'Karnataka'],
        591311: ['Belgaum', 'Karnataka'],
        591312: ['Belgaum', 'Karnataka'],
        591313: ['Belgaum', 'Karnataka'],
        591314: ['Belgaum', 'Karnataka'],
        591315: ['Belgaum', 'Karnataka'],
        591316: ['Belgaum', 'Karnataka'],
        591317: ['Belgaum', 'Karnataka'],
        591318: ['Belgaum', 'Karnataka'],
        591320: ['Belgaum', 'Karnataka'],
        591321: ['Belgaum', 'Karnataka'],
        591322: ['Belgaum', 'Karnataka'],
        591323: ['Belgaum', 'Karnataka'],
        591324: ['Belgaum', 'Karnataka'],
        591327: ['Belgaum', 'Karnataka'],
        591328: ['Belgaum', 'Karnataka'],
        591329: ['Belgaum', 'Karnataka'],
        591330: ['Belgaum', 'Karnataka'],
        591331: ['Belgaum', 'Karnataka'],
        591333: ['Belgaum', 'Karnataka'],
        591334: ['Belgaum', 'Karnataka'],
        591337: ['Belgaum', 'Karnataka'],
        591339: ['Belgaum', 'Karnataka'],
        591340: ['Belgaum', 'Karnataka'],
        591341: ['Belgaum', 'Karnataka'],
        591342: ['Belgaum', 'Karnataka'],
        591343: ['Belgaum', 'Karnataka'],
        591344: ['Belgaum', 'Karnataka'],
        591345: ['Belgaum', 'Karnataka'],
        591346: ['Belgaum', 'Karnataka'],
        591501: ['Belgaum', 'Karnataka'],
        591502: ['Belgaum', 'Karnataka'],
        591503: ['Belgaum', 'Karnataka'],
        591504: ['Belgaum', 'Karnataka'],
        591505: ['Belgaum', 'Karnataka'],
        591506: ['Belgaum', 'Karnataka'],
        591507: ['Belgaum', 'Karnataka'],
        591508: ['Belgaum', 'Karnataka'],
        591509: ['Belgaum', 'Karnataka'],
        591510: ['Belgaum', 'Karnataka'],
        591512: ['Belgaum', 'Karnataka'],
        591515: ['Belgaum', 'Karnataka'],
        591516: ['Belgaum', 'Karnataka'],
        591517: ['Belgaum', 'Karnataka'],
        591701: ['Belgaum', 'Karnataka'],
        591702: ['Belgaum', 'Karnataka'],
        591703: ['Belgaum', 'Karnataka'],
        591802: ['Belgaum', 'Karnataka'],
        591812: ['Belgaum', 'Karnataka'],
        600001: ['Chennai', 'Tamil Nadu'],
        600002: ['Chennai', 'Tamil Nadu'],
        600003: ['Chennai', 'Tamil Nadu'],
        600004: ['Chennai', 'Tamil Nadu'],
        600005: ['Chennai', 'Tamil Nadu'],
        600006: ['Chennai', 'Tamil Nadu'],
        600007: ['Chennai', 'Tamil Nadu'],
        600008: ['Chennai', 'Tamil Nadu'],
        600009: ['Chennai', 'Tamil Nadu'],
        600010: ['Chennai', 'Tamil Nadu'],
        600011: ['Chennai', 'Tamil Nadu'],
        600012: ['Chennai', 'Tamil Nadu'],
        600013: ['Chennai', 'Tamil Nadu'],
        600014: ['Chennai', 'Tamil Nadu'],
        600015: ['Chennai', 'Tamil Nadu'],
        600017: ['Chennai', 'Tamil Nadu'],
        600018: ['Chennai', 'Tamil Nadu'],
        600020: ['Chennai', 'Tamil Nadu'],
        600021: ['Chennai', 'Tamil Nadu'],
        600022: ['Chennai', 'Tamil Nadu'],
        600023: ['Chennai', 'Tamil Nadu'],
        600024: ['Chennai', 'Tamil Nadu'],
        600025: ['Chennai', 'Tamil Nadu'],
        600026: ['Chennai', 'Tamil Nadu'],
        600028: ['Chennai', 'Tamil Nadu'],
        600029: ['Chennai', 'Tamil Nadu'],
        600030: ['Chennai', 'Tamil Nadu'],
        600031: ['Chennai', 'Tamil Nadu'],
        600032: ['Chennai', 'Tamil Nadu'],
        600033: ['Chennai', 'Tamil Nadu'],
        600034: ['Chennai', 'Tamil Nadu'],
        600035: ['Chennai', 'Tamil Nadu'],
        600036: ['Chennai', 'Tamil Nadu'],
        600037: ['Chennai', 'Tamil Nadu'],
        600038: ['Chennai', 'Tamil Nadu'],
        600039: ['Chennai', 'Tamil Nadu'],
        600040: ['Chennai', 'Tamil Nadu'],
        600041: ['Chennai', 'Tamil Nadu'],
        600042: ['Chennai', 'Tamil Nadu'],
        600057: ['Chennai', 'Tamil Nadu'],
        600076: ['Chennai', 'Tamil Nadu'],
        600078: ['Chennai', 'Tamil Nadu'],
        600079: ['Chennai', 'Tamil Nadu'],
        600081: ['Chennai', 'Tamil Nadu'],
        600082: ['Chennai', 'Tamil Nadu'],
        600083: ['Chennai', 'Tamil Nadu'],
        600084: ['Chennai', 'Tamil Nadu'],
        600085: ['Chennai', 'Tamil Nadu'],
        600086: ['Chennai', 'Tamil Nadu'],
        600090: ['Chennai', 'Tamil Nadu'],
        600092: ['Chennai', 'Tamil Nadu'],
        600093: ['Chennai', 'Tamil Nadu'],
        600094: ['Chennai', 'Tamil Nadu'],
        600095: ['Chennai', 'Tamil Nadu'],
        600096: ['Chennai', 'Tamil Nadu'],
        600097: ['Chennai', 'Tamil Nadu'],
        600101: ['Chennai', 'Tamil Nadu'],
        600102: ['Chennai', 'Tamil Nadu'],
        600104: ['Chennai', 'Tamil Nadu'],
        600105: ['Chennai', 'Tamil Nadu'],
        600106: ['Chennai', 'Tamil Nadu'],
        600107: ['Chennai', 'Tamil Nadu'],
        600108: ['Chennai', 'Tamil Nadu'],
        600110: ['Chennai', 'Tamil Nadu'],
        600111: ['Chennai', 'Tamil Nadu'],
        600112: ['Chennai', 'Tamil Nadu'],
        600113: ['Chennai', 'Tamil Nadu'],
        600118: ['Chennai', 'Tamil Nadu'],
        600131: ['Chennai', 'Tamil Nadu'],
        600132: ['Chennai', 'Tamil Nadu'],
        603004: ['Chennai', 'Tamil Nadu'],
        605001: ['Puducherry', 'Puducherry'],
        605002: ['Puducherry', 'Puducherry'],
        605003: ['Puducherry', 'Puducherry'],
        605004: ['Puducherry', 'Puducherry'],
        605005: ['Puducherry', 'Puducherry'],
        605006: ['Puducherry', 'Puducherry'],
        605007: ['Puducherry', 'Puducherry'],
        605008: ['Puducherry', 'Puducherry'],
        605009: ['Puducherry', 'Puducherry'],
        605010: ['Puducherry', 'Puducherry'],
        605011: ['Puducherry', 'Puducherry'],
        605012: ['Puducherry', 'Tamil Nadu'],
        605013: ['Puducherry', 'Puducherry'],
        605014: ['Puducherry', 'Puducherry'],
        605101: ['Puducherry', 'Puducherry'],
        605102: ['Puducherry', 'Puducherry'],
        605104: ['Puducherry', 'Puducherry'],
        605105: ['Puducherry', 'Puducherry'],
        605106: ['Puducherry', 'Tamil Nadu'],
        605110: ['Puducherry', 'Puducherry'],
        605501: ['Puducherry', 'Puducherry'],
        605502: ['Puducherry', 'Puducherry'],
        607402: ['Puducherry', 'Puducherry'],
        607403: ['Chennai', 'Puducherry'],
        614201: ['Coimbatore', 'Tamil Nadu'],
        620001: ['Trichy', 'Tamil Nadu'],
        620002: ['Trichy', 'Tamil Nadu'],
        620003: ['Trichy', 'Tamil Nadu'],
        620004: ['Trichy', 'Tamil Nadu'],
        620005: ['Trichy', 'Tamil Nadu'],
        620006: ['Trichy', 'Tamil Nadu'],
        620007: ['Trichy', 'Tamil Nadu'],
        620008: ['Trichy', 'Tamil Nadu'],
        620009: ['Trichy', 'Tamil Nadu'],
        620010: ['Trichy', 'Tamil Nadu'],
        620011: ['Trichy', 'Tamil Nadu'],
        620012: ['Trichy', 'Tamil Nadu'],
        620013: ['Trichy', 'Tamil Nadu'],
        620014: ['Trichy', 'Tamil Nadu'],
        620015: ['Trichy', 'Tamil Nadu'],
        620016: ['Trichy', 'Tamil Nadu'],
        620017: ['Trichy', 'Tamil Nadu'],
        620018: ['Trichy', 'Tamil Nadu'],
        620019: ['Trichy', 'Tamil Nadu'],
        620020: ['Trichy', 'Tamil Nadu'],
        620021: ['Trichy', 'Tamil Nadu'],
        620022: ['Trichy', 'Tamil Nadu'],
        620051: ['Trichy', 'Tamil Nadu'],
        620101: ['Trichy', 'Tamil Nadu'],
        620102: ['Trichy', 'Tamil Nadu'],
        621001: ['Trichy', 'Tamil Nadu'],
        621002: ['Trichy', 'Tamil Nadu'],
        621003: ['Trichy', 'Tamil Nadu'],
        621004: ['Trichy', 'Tamil Nadu'],
        621005: ['Trichy', 'Tamil Nadu'],
        621006: ['Trichy', 'Tamil Nadu'],
        621007: ['Trichy', 'Tamil Nadu'],
        621008: ['Trichy', 'Tamil Nadu'],
        621009: ['Trichy', 'Tamil Nadu'],
        621010: ['Trichy', 'Tamil Nadu'],
        621012: ['Trichy', 'Tamil Nadu'],
        621014: ['Trichy', 'Tamil Nadu'],
        621015: ['Trichy', 'Tamil Nadu'],
        621016: ['Trichy', 'Tamil Nadu'],
        621017: ['Trichy', 'Tamil Nadu'],
        621018: ['Trichy', 'Tamil Nadu'],
        621021: ['Trichy', 'Tamil Nadu'],
        621022: ['Trichy', 'Tamil Nadu'],
        621101: ['Trichy', 'Tamil Nadu'],
        621103: ['Trichy', 'Tamil Nadu'],
        621104: ['Trichy', 'Tamil Nadu'],
        621105: ['Trichy', 'Tamil Nadu'],
        621106: ['Trichy', 'Tamil Nadu'],
        621107: ['Trichy', 'Tamil Nadu'],
        621108: ['Trichy', 'Tamil Nadu'],
        621109: ['Trichy', 'Tamil Nadu'],
        621110: ['Trichy', 'Tamil Nadu'],
        621112: ['Trichy', 'Tamil Nadu'],
        621113: ['Trichy', 'Tamil Nadu'],
        621114: ['Trichy', 'Tamil Nadu'],
        621115: ['Trichy', 'Tamil Nadu'],
        621116: ['Trichy', 'Tamil Nadu'],
        621117: ['Trichy', 'Tamil Nadu'],
        621118: ['Trichy', 'Tamil Nadu'],
        621121: ['Trichy', 'Tamil Nadu'],
        621126: ['Trichy', 'Tamil Nadu'],
        621127: ['Trichy', 'Tamil Nadu'],
        621129: ['Trichy', 'Tamil Nadu'],
        621132: ['Trichy', 'Tamil Nadu'],
        621201: ['Trichy', 'Tamil Nadu'],
        621202: ['Trichy', 'Tamil Nadu'],
        621203: ['Trichy', 'Tamil Nadu'],
        621205: ['Trichy', 'Tamil Nadu'],
        621206: ['Trichy', 'Tamil Nadu'],
        621208: ['Trichy', 'Tamil Nadu'],
        621209: ['Trichy', 'Tamil Nadu'],
        621210: ['Trichy', 'Tamil Nadu'],
        621211: ['Trichy', 'Tamil Nadu'],
        621212: ['Trichy', 'Tamil Nadu'],
        621213: ['Trichy', 'Tamil Nadu'],
        621214: ['Trichy', 'Tamil Nadu'],
        621215: ['Trichy', 'Tamil Nadu'],
        621216: ['Trichy', 'Tamil Nadu'],
        621217: ['Trichy', 'Tamil Nadu'],
        621218: ['Trichy', 'Tamil Nadu'],
        621219: ['Trichy', 'Tamil Nadu'],
        621220: ['Trichy', 'Tamil Nadu'],
        621221: ['Trichy', 'Tamil Nadu'],
        621224: ['Trichy', 'Tamil Nadu'],
        621229: ['Trichy', 'Tamil Nadu'],
        621302: ['Trichy', 'Tamil Nadu'],
        621303: ['Trichy', 'Tamil Nadu'],
        621304: ['Trichy', 'Tamil Nadu'],
        621305: ['Trichy', 'Tamil Nadu'],
        621306: ['Trichy', 'Tamil Nadu'],
        621307: ['Trichy', 'Tamil Nadu'],
        621309: ['Trichy', 'Tamil Nadu'],
        621310: ['Trichy', 'Tamil Nadu'],
        621311: ['Trichy', 'Tamil Nadu'],
        621312: ['Trichy', 'Tamil Nadu'],
        621313: ['Trichy', 'Tamil Nadu'],
        621314: ['Trichy', 'Tamil Nadu'],
        621315: ['Trichy', 'Tamil Nadu'],
        621317: ['Trichy', 'Tamil Nadu'],
        621601: ['Trichy', 'Tamil Nadu'],
        621651: ['Trichy', 'Tamil Nadu'],
        621652: ['Trichy', 'Tamil Nadu'],
        621653: ['Trichy', 'Tamil Nadu'],
        621701: ['Trichy', 'Tamil Nadu'],
        621702: ['Trichy', 'Tamil Nadu'],
        621703: ['Trichy', 'Tamil Nadu'],
        621704: ['Trichy', 'Tamil Nadu'],
        621705: ['Trichy', 'Tamil Nadu'],
        621707: ['Trichy', 'Tamil Nadu'],
        621708: ['Trichy', 'Tamil Nadu'],
        621709: ['Trichy', 'Tamil Nadu'],
        621710: ['Trichy', 'Tamil Nadu'],
        621711: ['Trichy', 'Tamil Nadu'],
        621712: ['Trichy', 'Tamil Nadu'],
        621713: ['Trichy', 'Tamil Nadu'],
        621714: ['Trichy', 'Tamil Nadu'],
        621715: ['Trichy', 'Tamil Nadu'],
        621716: ['Trichy', 'Tamil Nadu'],
        621717: ['Trichy', 'Tamil Nadu'],
        621718: ['Trichy', 'Tamil Nadu'],
        621719: ['Trichy', 'Tamil Nadu'],
        621722: ['Trichy', 'Tamil Nadu'],
        621729: ['Trichy', 'Tamil Nadu'],
        621801: ['Trichy', 'Tamil Nadu'],
        621802: ['Trichy', 'Tamil Nadu'],
        621803: ['Trichy', 'Tamil Nadu'],
        621805: ['Trichy', 'Tamil Nadu'],
        621806: ['Trichy', 'Tamil Nadu'],
        621851: ['Trichy', 'Tamil Nadu'],
        621853: ['Trichy', 'Tamil Nadu'],
        624215: ['Madurai', 'Tamil Nadu'],
        624401: ['Madurai', 'Tamil Nadu'],
        624402: ['Madurai', 'Tamil Nadu'],
        624403: ['Madurai', 'Tamil Nadu'],
        625001: ['Madurai', 'Tamil Nadu'],
        625002: ['Madurai', 'Tamil Nadu'],
        625003: ['Madurai', 'Tamil Nadu'],
        625004: ['Madurai', 'Tamil Nadu'],
        625005: ['Madurai', 'Tamil Nadu'],
        625006: ['Madurai', 'Tamil Nadu'],
        625007: ['Madurai', 'Tamil Nadu'],
        625008: ['Madurai', 'Tamil Nadu'],
        625009: ['Madurai', 'Tamil Nadu'],
        625010: ['Madurai', 'Tamil Nadu'],
        625011: ['Madurai', 'Tamil Nadu'],
        625012: ['Madurai', 'Tamil Nadu'],
        625014: ['Madurai', 'Tamil Nadu'],
        625015: ['Madurai', 'Tamil Nadu'],
        625016: ['Madurai', 'Tamil Nadu'],
        625017: ['Madurai', 'Tamil Nadu'],
        625018: ['Madurai', 'Tamil Nadu'],
        625019: ['Madurai', 'Tamil Nadu'],
        625020: ['Madurai', 'Tamil Nadu'],
        625021: ['Madurai', 'Tamil Nadu'],
        625022: ['Madurai', 'Tamil Nadu'],
        625023: ['Madurai', 'Tamil Nadu'],
        625051: ['Madurai', 'Tamil Nadu'],
        625052: ['Madurai', 'Tamil Nadu'],
        625101: ['Madurai', 'Tamil Nadu'],
        625102: ['Madurai', 'Tamil Nadu'],
        625103: ['Madurai', 'Tamil Nadu'],
        625104: ['Madurai', 'Tamil Nadu'],
        625105: ['Madurai', 'Tamil Nadu'],
        625106: ['Madurai', 'Tamil Nadu'],
        625107: ['Madurai', 'Tamil Nadu'],
        625108: ['Madurai', 'Tamil Nadu'],
        625109: ['Madurai', 'Tamil Nadu'],
        625110: ['Madurai', 'Tamil Nadu'],
        625111: ['Madurai', 'Tamil Nadu'],
        625115: ['Madurai', 'Tamil Nadu'],
        625119: ['Madurai', 'Tamil Nadu'],
        625121: ['Madurai', 'Tamil Nadu'],
        625122: ['Madurai', 'Tamil Nadu'],
        625125: ['Madurai', 'Tamil Nadu'],
        625201: ['Madurai', 'Tamil Nadu'],
        625205: ['Madurai', 'Tamil Nadu'],
        625207: ['Madurai', 'Tamil Nadu'],
        625214: ['Madurai', 'Tamil Nadu'],
        625218: ['Madurai', 'Tamil Nadu'],
        625221: ['Madurai', 'Tamil Nadu'],
        625234: ['Madurai', 'Tamil Nadu'],
        625301: ['Madurai', 'Tamil Nadu'],
        625303: ['Madurai', 'Tamil Nadu'],
        625304: ['Madurai', 'Tamil Nadu'],
        625402: ['Madurai', 'Tamil Nadu'],
        625403: ['Madurai', 'Tamil Nadu'],
        625501: ['Madurai', 'Tamil Nadu'],
        625503: ['Madurai', 'Tamil Nadu'],
        625504: ['Madurai', 'Tamil Nadu'],
        625514: ['Madurai', 'Tamil Nadu'],
        625527: ['Madurai', 'Tamil Nadu'],
        625529: ['Madurai', 'Tamil Nadu'],
        625532: ['Madurai', 'Tamil Nadu'],
        625535: ['Madurai', 'Tamil Nadu'],
        625537: ['Madurai', 'Tamil Nadu'],
        625577: ['Madurai', 'Tamil Nadu'],
        625701: ['Madurai', 'Tamil Nadu'],
        625702: ['Madurai', 'Tamil Nadu'],
        625703: ['Madurai', 'Tamil Nadu'],
        625704: ['Madurai', 'Tamil Nadu'],
        625705: ['Madurai', 'Tamil Nadu'],
        625706: ['Madurai', 'Tamil Nadu'],
        625707: ['Madurai', 'Tamil Nadu'],
        625708: ['Madurai', 'Tamil Nadu'],
        626161: ['Madurai', 'Tamil Nadu'],
        626501: ['Madurai', 'Tamil Nadu'],
        626512: ['Madurai', 'Tamil Nadu'],
        626513: ['Madurai', 'Tamil Nadu'],
        626514: ['Madurai', 'Tamil Nadu'],
        626515: ['Madurai', 'Tamil Nadu'],
        626516: ['Madurai', 'Tamil Nadu'],
        626517: ['Madurai', 'Tamil Nadu'],
        626518: ['Madurai', 'Tamil Nadu'],
        626519: ['Madurai', 'Tamil Nadu'],
        626520: ['Madurai', 'Tamil Nadu'],
        626521: ['Madurai', 'Tamil Nadu'],
        626522: ['Madurai', 'Tamil Nadu'],
        626523: ['Madurai', 'Tamil Nadu'],
        626524: ['Madurai', 'Tamil Nadu'],
        626526: ['Madurai', 'Tamil Nadu'],
        626527: ['Madurai', 'Tamil Nadu'],
        626528: ['Madurai', 'Tamil Nadu'],
        626529: ['Madurai', 'Tamil Nadu'],
        626530: ['Madurai', 'Tamil Nadu'],
        626531: ['Madurai', 'Tamil Nadu'],
        626532: ['Madurai', 'Tamil Nadu'],
        626533: ['Madurai', 'Tamil Nadu'],
        626534: ['Madurai', 'Tamil Nadu'],
        626535: ['Madurai', 'Tamil Nadu'],
        626536: ['Madurai', 'Tamil Nadu'],
        626537: ['Madurai', 'Tamil Nadu'],
        626538: ['Madurai', 'Tamil Nadu'],
        626539: ['Madurai', 'Tamil Nadu'],
        626540: ['Madurai', 'Tamil Nadu'],
        626541: ['Madurai', 'Tamil Nadu'],
        626544: ['Madurai', 'Tamil Nadu'],
        626547: ['Madurai', 'Tamil Nadu'],
        626553: ['Madurai', 'Tamil Nadu'],
        626555: ['Madurai', 'Tamil Nadu'],
        626556: ['Madurai', 'Tamil Nadu'],
        626562: ['Madurai', 'Tamil Nadu'],
        626565: ['Madurai', 'Tamil Nadu'],
        626566: ['Madurai', 'Tamil Nadu'],
        626569: ['Madurai', 'Tamil Nadu'],
        626571: ['Madurai', 'Tamil Nadu'],
        626572: ['Madurai', 'Tamil Nadu'],
        626579: ['Madurai', 'Tamil Nadu'],
        626582: ['Madurai', 'Tamil Nadu'],
        626601: ['Madurai', 'Tamil Nadu'],
        626701: ['Madurai', 'Tamil Nadu'],
        626702: ['Madurai', 'Tamil Nadu'],
        626704: ['Madurai', 'Tamil Nadu'],
        626705: ['Madurai', 'Tamil Nadu'],
        626706: ['Madurai', 'Tamil Nadu'],
        626709: ['Madurai', 'Tamil Nadu'],
        626712: ['Madurai', 'Tamil Nadu'],
        626717: ['Madurai', 'Tamil Nadu'],
        626719: ['Madurai', 'Tamil Nadu'],
        628207: ['Trichy', 'Tamil Nadu'],
        631001: ['Vellore', 'Tamil Nadu'],
        631002: ['Vellore', 'Tamil Nadu'],
        631003: ['Vellore', 'Tamil Nadu'],
        631004: ['Vellore', 'Tamil Nadu'],
        631005: ['Vellore', 'Tamil Nadu'],
        631006: ['Vellore', 'Tamil Nadu'],
        631051: ['Vellore', 'Tamil Nadu'],
        631052: ['Vellore', 'Tamil Nadu'],
        631101: ['Vellore', 'Tamil Nadu'],
        631102: ['Vellore', 'Tamil Nadu'],
        631151: ['Vellore', 'Tamil Nadu'],
        631152: ['Vellore', 'Tamil Nadu'],
        632001: ['Vellore', 'Tamil Nadu'],
        632002: ['Vellore', 'Tamil Nadu'],
        632004: ['Vellore', 'Tamil Nadu'],
        632006: ['Vellore', 'Tamil Nadu'],
        632007: ['Vellore', 'Tamil Nadu'],
        632008: ['Vellore', 'Tamil Nadu'],
        632009: ['Vellore', 'Tamil Nadu'],
        632010: ['Vellore', 'Tamil Nadu'],
        632011: ['Vellore', 'Tamil Nadu'],
        632012: ['Vellore', 'Tamil Nadu'],
        632013: ['Vellore', 'Tamil Nadu'],
        632014: ['Vellore', 'Tamil Nadu'],
        632055: ['Vellore', 'Tamil Nadu'],
        632057: ['Vellore', 'Tamil Nadu'],
        632058: ['Vellore', 'Tamil Nadu'],
        632059: ['Vellore', 'Tamil Nadu'],
        632101: ['Vellore', 'Tamil Nadu'],
        632102: ['Vellore', 'Tamil Nadu'],
        632103: ['Vellore', 'Tamil Nadu'],
        632104: ['Vellore', 'Tamil Nadu'],
        632105: ['Vellore', 'Tamil Nadu'],
        632106: ['Vellore', 'Tamil Nadu'],
        632107: ['Vellore', 'Tamil Nadu'],
        632113: ['Vellore', 'Tamil Nadu'],
        632114: ['Vellore', 'Tamil Nadu'],
        632115: ['Vellore', 'Tamil Nadu'],
        632201: ['Vellore', 'Tamil Nadu'],
        632202: ['Vellore', 'Tamil Nadu'],
        632203: ['Vellore', 'Tamil Nadu'],
        632204: ['Vellore', 'Tamil Nadu'],
        632209: ['Vellore', 'Tamil Nadu'],
        632312: ['Vellore', 'Tamil Nadu'],
        632318: ['Vellore', 'Tamil Nadu'],
        632319: ['Vellore', 'Tamil Nadu'],
        632401: ['Vellore', 'Tamil Nadu'],
        632403: ['Vellore', 'Tamil Nadu'],
        632404: ['Vellore', 'Tamil Nadu'],
        632405: ['Vellore', 'Tamil Nadu'],
        632406: ['Vellore', 'Tamil Nadu'],
        632501: ['Vellore', 'Tamil Nadu'],
        632502: ['Vellore', 'Tamil Nadu'],
        632503: ['Vellore', 'Tamil Nadu'],
        632504: ['Vellore', 'Tamil Nadu'],
        632505: ['Vellore', 'Tamil Nadu'],
        632506: ['Vellore', 'Tamil Nadu'],
        632507: ['Vellore', 'Tamil Nadu'],
        632508: ['Vellore', 'Tamil Nadu'],
        632509: ['Vellore', 'Tamil Nadu'],
        632510: ['Vellore', 'Tamil Nadu'],
        632511: ['Vellore', 'Tamil Nadu'],
        632512: ['Vellore', 'Tamil Nadu'],
        632513: ['Vellore', 'Tamil Nadu'],
        632514: ['Vellore', 'Tamil Nadu'],
        632515: ['Vellore', 'Tamil Nadu'],
        632516: ['Vellore', 'Tamil Nadu'],
        632517: ['Vellore', 'Tamil Nadu'],
        632518: ['Vellore', 'Tamil Nadu'],
        632519: ['Vellore', 'Tamil Nadu'],
        632520: ['Vellore', 'Tamil Nadu'],
        632521: ['Vellore', 'Tamil Nadu'],
        632531: ['Vellore', 'Tamil Nadu'],
        632601: ['Vellore', 'Tamil Nadu'],
        632602: ['Vellore', 'Tamil Nadu'],
        632603: ['Vellore', 'Tamil Nadu'],
        632604: ['Vellore', 'Tamil Nadu'],
        635130: ['Coimbatore', 'Tamil Nadu'],
        635601: ['Vellore', 'Tamil Nadu'],
        635602: ['Vellore', 'Tamil Nadu'],
        635651: ['Vellore', 'Tamil Nadu'],
        635652: ['Vellore', 'Tamil Nadu'],
        635653: ['Vellore', 'Tamil Nadu'],
        635654: ['Vellore', 'Tamil Nadu'],
        635655: ['Vellore', 'Tamil Nadu'],
        635701: ['Vellore', 'Tamil Nadu'],
        635702: ['Vellore', 'Tamil Nadu'],
        635703: ['Vellore', 'Tamil Nadu'],
        635710: ['Vellore', 'Tamil Nadu'],
        635751: ['Vellore', 'Tamil Nadu'],
        635752: ['Vellore', 'Tamil Nadu'],
        635754: ['Vellore', 'Tamil Nadu'],
        635801: ['Vellore', 'Tamil Nadu'],
        635802: ['Vellore', 'Tamil Nadu'],
        635803: ['Vellore', 'Tamil Nadu'],
        635804: ['Vellore', 'Tamil Nadu'],
        635805: ['Vellore', 'Tamil Nadu'],
        635806: ['Vellore', 'Tamil Nadu'],
        635807: ['Vellore', 'Tamil Nadu'],
        635808: ['Vellore', 'Tamil Nadu'],
        635809: ['Vellore', 'Tamil Nadu'],
        635810: ['Vellore', 'Tamil Nadu'],
        635811: ['Vellore', 'Tamil Nadu'],
        635812: ['Vellore', 'Tamil Nadu'],
        635813: ['Vellore', 'Tamil Nadu'],
        635814: ['Vellore', 'Tamil Nadu'],
        635815: ['Vellore', 'Tamil Nadu'],
        635851: ['Vellore', 'Tamil Nadu'],
        635852: ['Vellore', 'Tamil Nadu'],
        635853: ['Vellore', 'Tamil Nadu'],
        635854: ['Vellore', 'Tamil Nadu'],
        635901: ['Vellore', 'Tamil Nadu'],
        636017: ['Coimbatore', 'Tamil Nadu'],
        638001: ['Erode', 'Tamil Nadu'],
        638002: ['Erode', 'Tamil Nadu'],
        638003: ['Erode', 'Tamil Nadu'],
        638004: ['Erode', 'Tamil Nadu'],
        638005: ['Erode', 'Tamil Nadu'],
        638007: ['Erode', 'Tamil Nadu'],
        638009: ['Erode', 'Tamil Nadu'],
        638010: ['Erode', 'Tamil Nadu'],
        638011: ['Erode', 'Tamil Nadu'],
        638012: ['Erode', 'Tamil Nadu'],
        638051: ['Erode', 'Tamil Nadu'],
        638052: ['Erode', 'Tamil Nadu'],
        638053: ['Erode', 'Tamil Nadu'],
        638054: ['Erode', 'Tamil Nadu'],
        638055: ['Erode', 'Tamil Nadu'],
        638056: ['Erode', 'Tamil Nadu'],
        638057: ['Erode', 'Tamil Nadu'],
        638060: ['Coimbatore', 'Tamil Nadu'],
        638101: ['Erode', 'Tamil Nadu'],
        638102: ['Erode', 'Tamil Nadu'],
        638103: ['Coimbatore', 'Tamil Nadu'],
        638104: ['Erode', 'Tamil Nadu'],
        638105: ['Erode', 'Tamil Nadu'],
        638106: ['Erode', 'Tamil Nadu'],
        638107: ['Erode', 'Tamil Nadu'],
        638108: ['Erode', 'Tamil Nadu'],
        638109: ['Erode', 'Tamil Nadu'],
        638110: ['Erode', 'Tamil Nadu'],
        638111: ['Erode', 'Tamil Nadu'],
        638112: ['Erode', 'Tamil Nadu'],
        638115: ['Erode', 'Tamil Nadu'],
        638116: ['Erode', 'Tamil Nadu'],
        638151: ['Erode', 'Tamil Nadu'],
        638152: ['Erode', 'Tamil Nadu'],
        638153: ['Erode', 'Tamil Nadu'],
        638154: ['Erode', 'Tamil Nadu'],
        638201: ['Coimbatore', 'Tamil Nadu'],
        638203: ['Coimbatore', 'Tamil Nadu'],
        638211: ['Coimbatore', 'Tamil Nadu'],
        638301: ['Erode', 'Tamil Nadu'],
        638311: ['Erode', 'Tamil Nadu'],
        638312: ['Erode', 'Tamil Nadu'],
        638313: ['Erode', 'Tamil Nadu'],
        638314: ['Erode', 'Tamil Nadu'],
        638315: ['Erode', 'Tamil Nadu'],
        638316: ['Erode', 'Tamil Nadu'],
        638401: ['Erode', 'Tamil Nadu'],
        638402: ['Erode', 'Tamil Nadu'],
        638406: ['Coimbatore', 'Tamil Nadu'],
        638451: ['Erode', 'Tamil Nadu'],
        638452: ['Erode', 'Tamil Nadu'],
        638453: ['Erode', 'Tamil Nadu'],
        638454: ['Erode', 'Tamil Nadu'],
        638455: ['Erode', 'Tamil Nadu'],
        638456: ['Erode', 'Tamil Nadu'],
        638457: ['Erode', 'Tamil Nadu'],
        638458: ['Erode', 'Tamil Nadu'],
        638459: ['Erode', 'Tamil Nadu'],
        638460: ['Erode', 'Tamil Nadu'],
        638461: ['Erode', 'Tamil Nadu'],
        638462: ['Erode', 'Tamil Nadu'],
        638476: ['Erode', 'Tamil Nadu'],
        638501: ['Erode', 'Tamil Nadu'],
        638502: ['Erode', 'Tamil Nadu'],
        638503: ['Erode', 'Tamil Nadu'],
        638504: ['Erode', 'Tamil Nadu'],
        638505: ['Erode', 'Tamil Nadu'],
        638506: ['Erode', 'Tamil Nadu'],
        638656: ['Erode', 'Tamil Nadu'],
        638657: ['Erode', 'Tamil Nadu'],
        638660: ['Erode', 'Tamil Nadu'],
        638661: ['Erode', 'Tamil Nadu'],
        638672: ['Erode', 'Tamil Nadu'],
        638673: ['Erode', 'Tamil Nadu'],
        638701: ['Erode', 'Tamil Nadu'],
        638702: ['Erode', 'Tamil Nadu'],
        638703: ['Erode', 'Tamil Nadu'],
        638706: ['Erode', 'Tamil Nadu'],
        638751: ['Erode', 'Tamil Nadu'],
        638752: ['Erode', 'Tamil Nadu'],
        638812: ['Erode', 'Tamil Nadu'],
        639005: ['Trichy', 'Tamil Nadu'],
        639102: ['Trichy', 'Tamil Nadu'],
        639103: ['Trichy', 'Tamil Nadu'],
        639104: ['Trichy', 'Tamil Nadu'],
        639106: ['Trichy', 'Tamil Nadu'],
        639111: ['Trichy', 'Tamil Nadu'],
        639112: ['Trichy', 'Tamil Nadu'],
        639115: ['Trichy', 'Tamil Nadu'],
        639116: ['Trichy', 'Tamil Nadu'],
        639117: ['Trichy', 'Tamil Nadu'],
        639121: ['Trichy', 'Tamil Nadu'],
        639202: ['Erode', 'Tamil Nadu'],
        641001: ['Coimbatore', 'Tamil Nadu'],
        641002: ['Coimbatore', 'Tamil Nadu'],
        641003: ['Coimbatore', 'Tamil Nadu'],
        641004: ['Coimbatore', 'Tamil Nadu'],
        641005: ['Coimbatore', 'Tamil Nadu'],
        641006: ['Coimbatore', 'Tamil Nadu'],
        641007: ['Coimbatore', 'Tamil Nadu'],
        641008: ['Coimbatore', 'Tamil Nadu'],
        641009: ['Coimbatore', 'Tamil Nadu'],
        641010: ['Coimbatore', 'Tamil Nadu'],
        641011: ['Coimbatore', 'Tamil Nadu'],
        641012: ['Coimbatore', 'Tamil Nadu'],
        641013: ['Coimbatore', 'Tamil Nadu'],
        641014: ['Coimbatore', 'Tamil Nadu'],
        641015: ['Coimbatore', 'Tamil Nadu'],
        641016: ['Coimbatore', 'Tamil Nadu'],
        641017: ['Coimbatore', 'Tamil Nadu'],
        641018: ['Coimbatore', 'Tamil Nadu'],
        641019: ['Coimbatore', 'Tamil Nadu'],
        641020: ['Coimbatore', 'Tamil Nadu'],
        641021: ['Coimbatore', 'Tamil Nadu'],
        641022: ['Coimbatore', 'Tamil Nadu'],
        641023: ['Coimbatore', 'Tamil Nadu'],
        641024: ['Coimbatore', 'Tamil Nadu'],
        641025: ['Coimbatore', 'Tamil Nadu'],
        641026: ['Coimbatore', 'Tamil Nadu'],
        641027: ['Coimbatore', 'Tamil Nadu'],
        641028: ['Coimbatore', 'Tamil Nadu'],
        641029: ['Coimbatore', 'Tamil Nadu'],
        641030: ['Coimbatore', 'Tamil Nadu'],
        641031: ['Coimbatore', 'Tamil Nadu'],
        641032: ['Coimbatore', 'Tamil Nadu'],
        641033: ['Coimbatore', 'Tamil Nadu'],
        641034: ['Coimbatore', 'Tamil Nadu'],
        641035: ['Coimbatore', 'Tamil Nadu'],
        641036: ['Coimbatore', 'Tamil Nadu'],
        641037: ['Coimbatore', 'Tamil Nadu'],
        641038: ['Coimbatore', 'Tamil Nadu'],
        641039: ['Coimbatore', 'Tamil Nadu'],
        641040: ['Coimbatore', 'Tamil Nadu'],
        641041: ['Coimbatore', 'Tamil Nadu'],
        641042: ['Coimbatore', 'Tamil Nadu'],
        641043: ['Coimbatore', 'Tamil Nadu'],
        641044: ['Coimbatore', 'Tamil Nadu'],
        641045: ['Coimbatore', 'Tamil Nadu'],
        641046: ['Coimbatore', 'Tamil Nadu'],
        641047: ['Coimbatore', 'Tamil Nadu'],
        641048: ['Coimbatore', 'Tamil Nadu'],
        641049: ['Coimbatore', 'Tamil Nadu'],
        641050: ['Coimbatore', 'Tamil Nadu'],
        641062: ['Coimbatore', 'Tamil Nadu'],
        641101: ['Coimbatore', 'Tamil Nadu'],
        641103: ['Coimbatore', 'Tamil Nadu'],
        641104: ['Coimbatore', 'Tamil Nadu'],
        641105: ['Coimbatore', 'Tamil Nadu'],
        641106: ['Coimbatore', 'Tamil Nadu'],
        641107: ['Coimbatore', 'Tamil Nadu'],
        641108: ['Coimbatore', 'Tamil Nadu'],
        641109: ['Coimbatore', 'Tamil Nadu'],
        641110: ['Coimbatore', 'Tamil Nadu'],
        641111: ['Coimbatore', 'Tamil Nadu'],
        641112: ['Coimbatore', 'Tamil Nadu'],
        641113: ['Coimbatore', 'Tamil Nadu'],
        641114: ['Coimbatore', 'Tamil Nadu'],
        641115: ['Coimbatore', 'Tamil Nadu'],
        641118: ['Coimbatore', 'Tamil Nadu'],
        641120: ['Coimbatore', 'Tamil Nadu'],
        641201: ['Coimbatore', 'Tamil Nadu'],
        641202: ['Coimbatore', 'Tamil Nadu'],
        641301: ['Coimbatore', 'Tamil Nadu'],
        641302: ['Coimbatore', 'Tamil Nadu'],
        641305: ['Coimbatore', 'Tamil Nadu'],
        641401: ['Coimbatore', 'Tamil Nadu'],
        641402: ['Coimbatore', 'Tamil Nadu'],
        641407: ['Coimbatore', 'Tamil Nadu'],
        641601: ['Coimbatore', 'Tamil Nadu'],
        641602: ['Coimbatore', 'Tamil Nadu'],
        641603: ['Coimbatore', 'Tamil Nadu'],
        641604: ['Coimbatore', 'Tamil Nadu'],
        641605: ['Erode', 'Tamil Nadu'],
        641606: ['Coimbatore', 'Tamil Nadu'],
        641607: ['Coimbatore', 'Tamil Nadu'],
        641652: ['Coimbatore', 'Tamil Nadu'],
        641653: ['Coimbatore', 'Tamil Nadu'],
        641654: ['Coimbatore', 'Tamil Nadu'],
        641655: ['Coimbatore', 'Tamil Nadu'],
        641658: ['Coimbatore', 'Tamil Nadu'],
        641659: ['Coimbatore', 'Tamil Nadu'],
        641662: ['Coimbatore', 'Tamil Nadu'],
        641663: ['Coimbatore', 'Tamil Nadu'],
        641664: ['Coimbatore', 'Tamil Nadu'],
        641665: ['Coimbatore', 'Tamil Nadu'],
        641666: ['Coimbatore', 'Tamil Nadu'],
        641667: ['Coimbatore', 'Tamil Nadu'],
        641668: ['Coimbatore', 'Tamil Nadu'],
        641669: ['Coimbatore', 'Tamil Nadu'],
        641670: ['Coimbatore', 'Tamil Nadu'],
        641671: ['Coimbatore', 'Tamil Nadu'],
        641687: ['Coimbatore', 'Tamil Nadu'],
        641697: ['Coimbatore', 'Tamil Nadu'],
        642001: ['Coimbatore', 'Tamil Nadu'],
        642002: ['Coimbatore', 'Tamil Nadu'],
        642003: ['Coimbatore', 'Tamil Nadu'],
        642004: ['Coimbatore', 'Tamil Nadu'],
        642005: ['Coimbatore', 'Tamil Nadu'],
        642006: ['Coimbatore', 'Tamil Nadu'],
        642007: ['Coimbatore', 'Tamil Nadu'],
        642051: ['Coimbatore', 'Tamil Nadu'],
        642101: ['Coimbatore', 'Tamil Nadu'],
        642102: ['Coimbatore', 'Tamil Nadu'],
        642103: ['Coimbatore', 'Tamil Nadu'],
        642104: ['Coimbatore', 'Tamil Nadu'],
        642105: ['Coimbatore', 'Tamil Nadu'],
        642106: ['Coimbatore', 'Tamil Nadu'],
        642107: ['Coimbatore', 'Tamil Nadu'],
        642108: ['Coimbatore', 'Tamil Nadu'],
        642109: ['Coimbatore', 'Tamil Nadu'],
        642110: ['Coimbatore', 'Tamil Nadu'],
        642111: ['Coimbatore', 'Tamil Nadu'],
        642112: ['Coimbatore', 'Tamil Nadu'],
        642113: ['Coimbatore', 'Tamil Nadu'],
        642114: ['Coimbatore', 'Tamil Nadu'],
        642115: ['Coimbatore', 'Tamil Nadu'],
        642116: ['Coimbatore', 'Tamil Nadu'],
        642117: ['Coimbatore', 'Tamil Nadu'],
        642118: ['Coimbatore', 'Tamil Nadu'],
        642119: ['Coimbatore', 'Tamil Nadu'],
        642120: ['Coimbatore', 'Tamil Nadu'],
        642122: ['Coimbatore', 'Tamil Nadu'],
        642123: ['Coimbatore', 'Tamil Nadu'],
        642124: ['Coimbatore', 'Tamil Nadu'],
        642125: ['Coimbatore', 'Tamil Nadu'],
        642126: ['Coimbatore', 'Tamil Nadu'],
        642127: ['Coimbatore', 'Tamil Nadu'],
        642128: ['Coimbatore', 'Tamil Nadu'],
        642129: ['Coimbatore', 'Tamil Nadu'],
        642130: ['Coimbatore', 'Tamil Nadu'],
        642131: ['Coimbatore', 'Tamil Nadu'],
        642132: ['Coimbatore', 'Tamil Nadu'],
        642133: ['Coimbatore', 'Tamil Nadu'],
        642134: ['Coimbatore', 'Tamil Nadu'],
        642135: ['Coimbatore', 'Tamil Nadu'],
        642136: ['Coimbatore', 'Tamil Nadu'],
        642137: ['Coimbatore', 'Tamil Nadu'],
        642139: ['Coimbatore', 'Tamil Nadu'],
        642152: ['Coimbatore', 'Tamil Nadu'],
        642153: ['Coimbatore', 'Tamil Nadu'],
        642154: ['Coimbatore', 'Tamil Nadu'],
        642201: ['Coimbatore', 'Tamil Nadu'],
        642202: ['Coimbatore', 'Tamil Nadu'],
        642203: ['Coimbatore', 'Tamil Nadu'],
        642204: ['Coimbatore', 'Tamil Nadu'],
        642205: ['Coimbatore', 'Tamil Nadu'],
        642206: ['Coimbatore', 'Tamil Nadu'],
        642207: ['Coimbatore', 'Tamil Nadu'],
        670001: ['Kannur', 'Kerala'],
        670002: ['Kannur', 'Kerala'],
        670003: ['Kannur', 'Kerala'],
        670004: ['Kannur', 'Kerala'],
        670005: ['Kannur', 'Kerala'],
        670006: ['Kannur', 'Kerala'],
        670007: ['Kannur', 'Kerala'],
        670008: ['Kannur', 'Kerala'],
        670009: ['Kannur', 'Kerala'],
        670010: ['Kannur', 'Kerala'],
        670011: ['Kannur', 'Kerala'],
        670012: ['Kannur', 'Kerala'],
        670013: ['Kannur', 'Kerala'],
        670014: ['Kannur', 'Kerala'],
        670017: ['Kannur', 'Kerala'],
        670018: ['Kannur', 'Kerala'],
        670101: ['Kannur', 'Kerala'],
        670102: ['Kannur', 'Kerala'],
        670103: ['Kannur', 'Kerala'],
        670104: ['Kannur', 'Kerala'],
        670105: ['Kannur', 'Kerala'],
        670106: ['Kannur', 'Kerala'],
        670107: ['Kannur', 'Kerala'],
        670141: ['Kannur', 'Kerala'],
        670142: ['Kannur', 'Kerala'],
        670143: ['Kannur', 'Kerala'],
        670301: ['Kannur', 'Kerala'],
        670302: ['Kannur', 'Kerala'],
        670303: ['Kannur', 'Kerala'],
        670304: ['Kannur', 'Kerala'],
        670305: ['Kannur', 'Kerala'],
        670306: ['Kannur', 'Kerala'],
        670307: ['Kannur', 'Kerala'],
        670308: ['Kannur', 'Kerala'],
        670309: ['Kannur', 'Kerala'],
        670310: ['Kannur', 'Kerala'],
        670325: ['Kannur', 'Kerala'],
        670327: ['Kannur', 'Kerala'],
        670330: ['Calicut', 'Kerala'],
        670331: ['Kannur', 'Kerala'],
        670334: ['Kannur', 'Kerala'],
        670353: ['Kannur', 'Kerala'],
        670358: ['Kannur', 'Kerala'],
        670501: ['Kannur', 'Kerala'],
        670502: ['Kannur', 'Kerala'],
        670503: ['Kannur', 'Kerala'],
        670504: ['Kannur', 'Kerala'],
        670511: ['Kannur', 'Kerala'],
        670521: ['Kannur', 'Kerala'],
        670561: ['Kannur', 'Kerala'],
        670562: ['Kannur', 'Kerala'],
        670563: ['Kannur', 'Kerala'],
        670567: ['Kannur', 'Kerala'],
        670571: ['Kannur', 'Kerala'],
        670581: ['Kannur', 'Kerala'],
        670582: ['Kannur', 'Kerala'],
        670591: ['Kannur', 'Kerala'],
        670592: ['Kannur', 'Kerala'],
        670593: ['Kannur', 'Kerala'],
        670594: ['Kannur', 'Kerala'],
        670595: ['Kannur', 'Kerala'],
        670601: ['Kannur', 'Kerala'],
        670602: ['Kannur', 'Kerala'],
        670604: ['Kannur', 'Kerala'],
        670611: ['Kannur', 'Kerala'],
        670612: ['Kannur', 'Kerala'],
        670613: ['Kannur', 'Kerala'],
        670621: ['Kannur', 'Kerala'],
        670622: ['Kannur', 'Kerala'],
        670631: ['Kannur', 'Kerala'],
        670632: ['Kannur', 'Kerala'],
        670633: ['Kannur', 'Kerala'],
        670641: ['Kannur', 'Kerala'],
        670642: ['Kannur', 'Kerala'],
        670643: ['Kannur', 'Kerala'],
        670649: ['Kannur', 'Kerala'],
        670650: ['Kannur', 'Kerala'],
        670651: ['Kannur', 'Kerala'],
        670661: ['Kannur', 'Kerala'],
        670662: ['Kannur', 'Kerala'],
        670663: ['Kannur', 'Kerala'],
        670671: ['Kannur', 'Kerala'],
        670672: ['Kannur', 'Kerala'],
        670673: ['Kannur', 'Kerala'],
        670674: ['Kannur', 'Kerala'],
        670675: ['Kannur', 'Kerala'],
        670676: ['Kannur', 'Kerala'],
        670691: ['Kannur', 'Kerala'],
        670692: ['Kannur', 'Kerala'],
        670693: ['Kannur', 'Kerala'],
        670694: ['Kannur', 'Kerala'],
        670701: ['Kannur', 'Kerala'],
        670702: ['Kannur', 'Kerala'],
        670703: ['Kannur', 'Kerala'],
        670704: ['Kannur', 'Kerala'],
        670705: ['Kannur', 'Kerala'],
        670706: ['Kannur', 'Kerala'],
        670741: ['Kannur', 'Kerala'],
        671534: ['Calicut', 'Kerala'],
        673001: ['Calicut', 'Kerala'],
        673002: ['Calicut', 'Kerala'],
        673003: ['Calicut', 'Kerala'],
        673004: ['Calicut', 'Kerala'],
        673005: ['Calicut', 'Kerala'],
        673006: ['Calicut', 'Kerala'],
        673007: ['Calicut', 'Kerala'],
        673008: ['Calicut', 'Kerala'],
        673009: ['Calicut', 'Kerala'],
        673010: ['Calicut', 'Kerala'],
        673011: ['Calicut', 'Kerala'],
        673012: ['Calicut', 'Kerala'],
        673013: ['Calicut', 'Kerala'],
        673014: ['Calicut', 'Kerala'],
        673015: ['Calicut', 'Kerala'],
        673016: ['Calicut', 'Kerala'],
        673017: ['Calicut', 'Kerala'],
        673018: ['Calicut', 'Kerala'],
        673019: ['Calicut', 'Kerala'],
        673020: ['Calicut', 'Kerala'],
        673021: ['Calicut', 'Kerala'],
        673023: ['Calicut', 'Kerala'],
        673025: ['Calicut', 'Kerala'],
        673026: ['Calicut', 'Kerala'],
        673027: ['Calicut', 'Kerala'],
        673028: ['Calicut', 'Kerala'],
        673029: ['Calicut', 'Kerala'],
        673032: ['Calicut', 'Kerala'],
        673051: ['Calicut', 'Kerala'],
        673101: ['Calicut', 'Kerala'],
        673102: ['Calicut', 'Kerala'],
        673103: ['Calicut', 'Kerala'],
        673104: ['Calicut', 'Kerala'],
        673105: ['Calicut', 'Kerala'],
        673106: ['Calicut', 'Kerala'],
        673107: ['Calicut', 'Kerala'],
        673108: ['Calicut', 'Kerala'],
        673109: ['Calicut', 'Kerala'],
        673110: ['Calicut', 'Kerala'],
        673112: ['Calicut', 'Kerala'],
        673301: ['Calicut', 'Kerala'],
        673302: ['Calicut', 'Kerala'],
        673303: ['Calicut', 'Kerala'],
        673304: ['Calicut', 'Kerala'],
        673305: ['Calicut', 'Kerala'],
        673306: ['Calicut', 'Kerala'],
        673307: ['Calicut', 'Kerala'],
        673308: ['Calicut', 'Kerala'],
        673309: ['Calicut', 'Kerala'],
        673310: ['Calicut', 'Kerala'],
        673311: ['Kannur', 'Kerala'],
        673312: ['Kannur', 'Kerala'],
        673313: ['Kannur', 'Kerala'],
        673315: ['Calicut', 'Kerala'],
        673316: ['Kannur', 'Kerala'],
        673317: ['Calicut', 'Kerala'],
        673318: ['Calicut', 'Kerala'],
        673319: ['Calicut', 'Kerala'],
        673320: ['Calicut', 'Kerala'],
        673321: ['Calicut', 'Kerala'],
        673322: ['Calicut', 'Kerala'],
        673323: ['Calicut', 'Kerala'],
        673324: ['Calicut', 'Kerala'],
        673326: ['Calicut', 'Kerala'],
        673327: ['Calicut', 'Kerala'],
        673328: ['Calicut', 'Kerala'],
        673333: ['Calicut', 'Kerala'],
        673501: ['Calicut', 'Kerala'],
        673502: ['Calicut', 'Kerala'],
        673503: ['Calicut', 'Kerala'],
        673504: ['Calicut', 'Kerala'],
        673505: ['Calicut', 'Kerala'],
        673506: ['Calicut', 'Kerala'],
        673508: ['Calicut', 'Kerala'],
        673509: ['Calicut', 'Kerala'],
        673510: ['Calicut', 'Kerala'],
        673511: ['Calicut', 'Kerala'],
        673512: ['Calicut', 'Kerala'],
        673513: ['Calicut', 'Kerala'],
        673514: ['Calicut', 'Kerala'],
        673515: ['Calicut', 'Kerala'],
        673517: ['Calicut', 'Kerala'],
        673518: ['Calicut', 'Kerala'],
        673519: ['Calicut', 'Kerala'],
        673520: ['Calicut', 'Kerala'],
        673521: ['Calicut', 'Kerala'],
        673522: ['Calicut', 'Kerala'],
        673523: ['Calicut', 'Kerala'],
        673524: ['Calicut', 'Kerala'],
        673525: ['Calicut', 'Kerala'],
        673526: ['Calicut', 'Kerala'],
        673527: ['Calicut', 'Kerala'],
        673528: ['Calicut', 'Kerala'],
        673529: ['Calicut', 'Kerala'],
        673530: ['Calicut', 'Kerala'],
        673531: ['Calicut', 'Kerala'],
        673532: ['Calicut', 'Kerala'],
        673534: ['Calicut', 'Kerala'],
        673541: ['Calicut', 'Kerala'],
        673542: ['Calicut', 'Kerala'],
        673544: ['Calicut', 'Kerala'],
        673571: ['Calicut', 'Kerala'],
        673572: ['Calicut', 'Kerala'],
        673574: ['Calicut', 'Kerala'],
        673580: ['Calicut', 'Kerala'],
        673583: ['Calicut', 'Kerala'],
        673586: ['Calicut', 'Kerala'],
        673587: ['Calicut', 'Kerala'],
        673591: ['Calicut', 'Kerala'],
        673593: ['Calicut', 'Kerala'],
        673602: ['Calicut', 'Kerala'],
        673604: ['Calicut', 'Kerala'],
        673611: ['Calicut', 'Kerala'],
        673612: ['Calicut', 'Kerala'],
        673613: ['Calicut', 'Kerala'],
        673614: ['Calicut', 'Kerala'],
        673615: ['Calicut', 'Kerala'],
        673616: ['Calicut', 'Kerala'],
        673617: ['Calicut', 'Kerala'],
        673618: ['Calicut', 'Kerala'],
        673619: ['Calicut', 'Kerala'],
        673620: ['Calicut', 'Kerala'],
        673621: ['Calicut', 'Kerala'],
        673624: ['Calicut', 'Kerala'],
        673631: ['Calicut', 'Kerala'],
        673632: ['Calicut', 'Kerala'],
        673633: ['Calicut', 'Kerala'],
        673635: ['Calicut', 'Kerala'],
        673643: ['Calicut', 'Kerala'],
        673654: ['Calicut', 'Kerala'],
        673655: ['Calicut', 'Kerala'],
        673661: ['Calicut', 'Kerala'],
        678001: ['Palakkad', 'Kerala'],
        678002: ['Palakkad', 'Kerala'],
        678003: ['Palakkad', 'Kerala'],
        678004: ['Palakkad', 'Kerala'],
        678005: ['Palakkad', 'Kerala'],
        678006: ['Palakkad', 'Kerala'],
        678007: ['Palakkad', 'Kerala'],
        678008: ['Palakkad', 'Kerala'],
        678009: ['Palakkad', 'Kerala'],
        678010: ['Palakkad', 'Kerala'],
        678011: ['Palakkad', 'Kerala'],
        678012: ['Palakkad', 'Kerala'],
        678013: ['Palakkad', 'Kerala'],
        678014: ['Palakkad', 'Kerala'],
        678101: ['Palakkad', 'Kerala'],
        678102: ['Palakkad', 'Kerala'],
        678103: ['Palakkad', 'Kerala'],
        678104: ['Palakkad', 'Kerala'],
        678501: ['Palakkad', 'Kerala'],
        678502: ['Palakkad', 'Kerala'],
        678503: ['Palakkad', 'Kerala'],
        678504: ['Palakkad', 'Kerala'],
        678505: ['Palakkad', 'Kerala'],
        678506: ['Palakkad', 'Kerala'],
        678507: ['Palakkad', 'Kerala'],
        678508: ['Palakkad', 'Kerala'],
        678510: ['Palakkad', 'Kerala'],
        678512: ['Palakkad', 'Kerala'],
        678531: ['Palakkad', 'Kerala'],
        678532: ['Palakkad', 'Kerala'],
        678533: ['Palakkad', 'Kerala'],
        678534: ['Palakkad', 'Kerala'],
        678541: ['Palakkad', 'Kerala'],
        678542: ['Palakkad', 'Kerala'],
        678543: ['Palakkad', 'Kerala'],
        678544: ['Palakkad', 'Kerala'],
        678545: ['Palakkad', 'Kerala'],
        678546: ['Palakkad', 'Kerala'],
        678551: ['Palakkad', 'Kerala'],
        678552: ['Palakkad', 'Kerala'],
        678553: ['Palakkad', 'Kerala'],
        678554: ['Palakkad', 'Kerala'],
        678555: ['Palakkad', 'Kerala'],
        678556: ['Palakkad', 'Kerala'],
        678557: ['Palakkad', 'Kerala'],
        678571: ['Palakkad', 'Kerala'],
        678572: ['Palakkad', 'Kerala'],
        678573: ['Palakkad', 'Kerala'],
        678574: ['Palakkad', 'Kerala'],
        678581: ['Palakkad', 'Kerala'],
        678582: ['Palakkad', 'Kerala'],
        678583: ['Palakkad', 'Kerala'],
        678591: ['Palakkad', 'Kerala'],
        678592: ['Palakkad', 'Kerala'],
        678593: ['Palakkad', 'Kerala'],
        678594: ['Palakkad', 'Kerala'],
        678595: ['Palakkad', 'Kerala'],
        678596: ['Palakkad', 'Kerala'],
        678597: ['Palakkad', 'Kerala'],
        678598: ['Palakkad', 'Kerala'],
        678601: ['Palakkad', 'Kerala'],
        678611: ['Palakkad', 'Kerala'],
        678612: ['Palakkad', 'Kerala'],
        678613: ['Palakkad', 'Kerala'],
        678621: ['Palakkad', 'Kerala'],
        678622: ['Palakkad', 'Kerala'],
        678623: ['Palakkad', 'Kerala'],
        678624: ['Palakkad', 'Kerala'],
        678631: ['Palakkad', 'Kerala'],
        678632: ['Palakkad', 'Kerala'],
        678633: ['Palakkad', 'Kerala'],
        678641: ['Palakkad', 'Kerala'],
        678642: ['Palakkad', 'Kerala'],
        678651: ['Palakkad', 'Kerala'],
        678661: ['Palakkad', 'Kerala'],
        678671: ['Palakkad', 'Kerala'],
        678681: ['Palakkad', 'Kerala'],
        678682: ['Palakkad', 'Kerala'],
        678683: ['Palakkad', 'Kerala'],
        678684: ['Palakkad', 'Kerala'],
        678685: ['Palakkad', 'Kerala'],
        678686: ['Palakkad', 'Kerala'],
        678687: ['Palakkad', 'Kerala'],
        678688: ['Palakkad', 'Kerala'],
        678701: ['Palakkad', 'Kerala'],
        678702: ['Palakkad', 'Kerala'],
        678703: ['Palakkad', 'Kerala'],
        678704: ['Palakkad', 'Kerala'],
        678705: ['Palakkad', 'Kerala'],
        678706: ['Palakkad', 'Kerala'],
        678721: ['Palakkad', 'Kerala'],
        678722: ['Palakkad', 'Kerala'],
        678731: ['Palakkad', 'Kerala'],
        678732: ['Palakkad', 'Kerala'],
        678762: ['Palakkad', 'Kerala'],
        679101: ['Palakkad', 'Kerala'],
        679102: ['Palakkad', 'Kerala'],
        679103: ['Palakkad', 'Kerala'],
        679104: ['Palakkad', 'Kerala'],
        679105: ['Thrissur', 'Kerala'],
        679106: ['Thrissur', 'Kerala'],
        679121: ['Palakkad', 'Kerala'],
        679122: ['Palakkad', 'Kerala'],
        679123: ['Palakkad', 'Kerala'],
        679301: ['Palakkad', 'Kerala'],
        679302: ['Palakkad', 'Kerala'],
        679303: ['Palakkad', 'Kerala'],
        679304: ['Palakkad', 'Kerala'],
        679305: ['Palakkad', 'Kerala'],
        679306: ['Palakkad', 'Kerala'],
        679307: ['Palakkad', 'Kerala'],
        679308: ['Palakkad', 'Kerala'],
        679309: ['Palakkad', 'Kerala'],
        679313: ['Palakkad', 'Kerala'],
        679335: ['Palakkad', 'Kerala'],
        679336: ['Palakkad', 'Kerala'],
        679337: ['Palakkad', 'Kerala'],
        679501: ['Palakkad', 'Kerala'],
        679502: ['Palakkad', 'Kerala'],
        679503: ['Palakkad', 'Kerala'],
        679504: ['Palakkad', 'Kerala'],
        679505: ['Palakkad', 'Kerala'],
        679506: ['Palakkad', 'Kerala'],
        679511: ['Palakkad', 'Kerala'],
        679512: ['Palakkad', 'Kerala'],
        679513: ['Palakkad', 'Kerala'],
        679514: ['Palakkad', 'Kerala'],
        679515: ['Palakkad', 'Kerala'],
        679516: ['Palakkad', 'Kerala'],
        679521: ['Palakkad', 'Kerala'],
        679522: ['Palakkad', 'Kerala'],
        679523: ['Palakkad', 'Kerala'],
        679531: ['Thrissur', 'Kerala'],
        679532: ['Thrissur', 'Kerala'],
        679533: ['Palakkad', 'Kerala'],
        679534: ['Palakkad', 'Kerala'],
        679535: ['Palakkad', 'Kerala'],
        679536: ['Palakkad', 'Kerala'],
        679551: ['Palakkad', 'Kerala'],
        679552: ['Palakkad', 'Kerala'],
        679553: ['Palakkad', 'Kerala'],
        679554: ['Palakkad', 'Kerala'],
        679561: ['Thrissur', 'Kerala'],
        679562: ['Thrissur', 'Kerala'],
        679563: ['Thrissur', 'Kerala'],
        679564: ['Thrissur', 'Kerala'],
        680001: ['Thrissur', 'Kerala'],
        680002: ['Thrissur', 'Kerala'],
        680003: ['Thrissur', 'Kerala'],
        680004: ['Thrissur', 'Kerala'],
        680005: ['Thrissur', 'Kerala'],
        680006: ['Thrissur', 'Kerala'],
        680007: ['Thrissur', 'Kerala'],
        680008: ['Thrissur', 'Kerala'],
        680009: ['Thrissur', 'Kerala'],
        680010: ['Thrissur', 'Kerala'],
        680011: ['Thrissur', 'Kerala'],
        680012: ['Thrissur', 'Kerala'],
        680013: ['Thrissur', 'Kerala'],
        680014: ['Thrissur', 'Kerala'],
        680020: ['Thrissur', 'Kerala'],
        680021: ['Thrissur', 'Kerala'],
        680022: ['Thrissur', 'Kerala'],
        680026: ['Thrissur', 'Kerala'],
        680027: ['Thrissur', 'Kerala'],
        680028: ['Thrissur', 'Kerala'],
        680101: ['Thrissur', 'Kerala'],
        680102: ['Thrissur', 'Kerala'],
        680103: ['Thrissur', 'Kerala'],
        680104: ['Thrissur', 'Kerala'],
        680121: ['Thrissur', 'Kerala'],
        680122: ['Thrissur', 'Kerala'],
        680123: ['Thrissur', 'Kerala'],
        680125: ['Thrissur', 'Kerala'],
        680301: ['Thrissur', 'Kerala'],
        680302: ['Thrissur', 'Kerala'],
        680303: ['Thrissur', 'Kerala'],
        680304: ['Thrissur', 'Kerala'],
        680305: ['Thrissur', 'Kerala'],
        680306: ['Thrissur', 'Kerala'],
        680307: ['Thrissur', 'Kerala'],
        680308: ['Thrissur', 'Kerala'],
        680309: ['Thrissur', 'Kerala'],
        680310: ['Thrissur', 'Kerala'],
        680311: ['Thrissur', 'Kerala'],
        680312: ['Thrissur', 'Kerala'],
        680317: ['Thrissur', 'Kerala'],
        680501: ['Thrissur', 'Kerala'],
        680502: ['Thrissur', 'Kerala'],
        680503: ['Thrissur', 'Kerala'],
        680504: ['Thrissur', 'Kerala'],
        680505: ['Thrissur', 'Kerala'],
        680506: ['Thrissur', 'Kerala'],
        680507: ['Thrissur', 'Kerala'],
        680508: ['Thrissur', 'Kerala'],
        680509: ['Thrissur', 'Kerala'],
        680510: ['Thrissur', 'Kerala'],
        680511: ['Thrissur', 'Kerala'],
        680512: ['Thrissur', 'Kerala'],
        680513: ['Thrissur', 'Kerala'],
        680514: ['Thrissur', 'Kerala'],
        680515: ['Thrissur', 'Kerala'],
        680516: ['Thrissur', 'Kerala'],
        680517: ['Thrissur', 'Kerala'],
        680518: ['Thrissur', 'Kerala'],
        680519: ['Thrissur', 'Kerala'],
        680520: ['Thrissur', 'Kerala'],
        680521: ['Thrissur', 'Kerala'],
        680522: ['Thrissur', 'Kerala'],
        680523: ['Thrissur', 'Kerala'],
        680524: ['Thrissur', 'Kerala'],
        680541: ['Thrissur', 'Kerala'],
        680542: ['Thrissur', 'Kerala'],
        680543: ['Thrissur', 'Kerala'],
        680544: ['Thrissur', 'Kerala'],
        680545: ['Thrissur', 'Kerala'],
        680546: ['Thrissur', 'Kerala'],
        680551: ['Thrissur', 'Kerala'],
        680552: ['Thrissur', 'Kerala'],
        680553: ['Thrissur', 'Kerala'],
        680555: ['Thrissur', 'Kerala'],
        680561: ['Thrissur', 'Kerala'],
        680562: ['Thrissur', 'Kerala'],
        680563: ['Thrissur', 'Kerala'],
        680564: ['Thrissur', 'Kerala'],
        680565: ['Thrissur', 'Kerala'],
        680566: ['Thrissur', 'Kerala'],
        680567: ['Thrissur', 'Kerala'],
        680568: ['Thrissur', 'Kerala'],
        680569: ['Thrissur', 'Kerala'],
        680570: ['Thrissur', 'Kerala'],
        680571: ['Thrissur', 'Kerala'],
        680581: ['Thrissur', 'Kerala'],
        680582: ['Thrissur', 'Kerala'],
        680583: ['Thrissur', 'Kerala'],
        680584: ['Thrissur', 'Kerala'],
        680585: ['Thrissur', 'Kerala'],
        680586: ['Thrissur', 'Kerala'],
        680587: ['Thrissur', 'Kerala'],
        680588: ['Thrissur', 'Kerala'],
        680589: ['Thrissur', 'Kerala'],
        680590: ['Thrissur', 'Kerala'],
        680591: ['Thrissur', 'Kerala'],
        680594: ['Thrissur', 'Kerala'],
        680596: ['Thrissur', 'Kerala'],
        680601: ['Thrissur', 'Kerala'],
        680602: ['Thrissur', 'Kerala'],
        680604: ['Thrissur', 'Kerala'],
        680611: ['Thrissur', 'Kerala'],
        680612: ['Thrissur', 'Kerala'],
        680613: ['Thrissur', 'Kerala'],
        680614: ['Thrissur', 'Kerala'],
        680615: ['Thrissur', 'Kerala'],
        680616: ['Thrissur', 'Kerala'],
        680617: ['Thrissur', 'Kerala'],
        680618: ['Thrissur', 'Kerala'],
        680619: ['Thrissur', 'Kerala'],
        680620: ['Thrissur', 'Kerala'],
        680623: ['Thrissur', 'Kerala'],
        680631: ['Thrissur', 'Kerala'],
        680641: ['Thrissur', 'Kerala'],
        680642: ['Thrissur', 'Kerala'],
        680651: ['Thrissur', 'Kerala'],
        680652: ['Thrissur', 'Kerala'],
        680653: ['Thrissur', 'Kerala'],
        680654: ['Thrissur', 'Kerala'],
        680655: ['Thrissur', 'Kerala'],
        680656: ['Thrissur', 'Kerala'],
        680661: ['Thrissur', 'Kerala'],
        680662: ['Thrissur', 'Kerala'],
        680663: ['Thrissur', 'Kerala'],
        680664: ['Thrissur', 'Kerala'],
        680665: ['Thrissur', 'Kerala'],
        680666: ['Thrissur', 'Kerala'],
        680667: ['Thrissur', 'Kerala'],
        680668: ['Thrissur', 'Kerala'],
        680669: ['Thrissur', 'Kerala'],
        680670: ['Thrissur', 'Kerala'],
        680671: ['Thrissur', 'Kerala'],
        680681: ['Thrissur', 'Kerala'],
        680682: ['Thrissur', 'Kerala'],
        680683: ['Thrissur', 'Kerala'],
        680684: ['Thrissur', 'Kerala'],
        680685: ['Thrissur', 'Kerala'],
        680686: ['Thrissur', 'Kerala'],
        680687: ['Thrissur', 'Kerala'],
        680688: ['Thrissur', 'Kerala'],
        680689: ['Thrissur', 'Kerala'],
        680691: ['Thrissur', 'Kerala'],
        680697: ['Thrissur', 'Kerala'],
        680699: ['Thrissur', 'Kerala'],
        680701: ['Thrissur', 'Kerala'],
        680702: ['Thrissur', 'Kerala'],
        680703: ['Thrissur', 'Kerala'],
        680711: ['Thrissur', 'Kerala'],
        680712: ['Thrissur', 'Kerala'],
        680721: ['Thrissur', 'Kerala'],
        680722: ['Thrissur', 'Kerala'],
        680724: ['Thrissur', 'Kerala'],
        680731: ['Thrissur', 'Kerala'],
        680732: ['Thrissur', 'Kerala'],
        680733: ['Thrissur', 'Kerala'],
        680734: ['Thrissur', 'Kerala'],
        680741: ['Thrissur', 'Kerala'],
        680751: ['Thrissur', 'Kerala'],
        682050: ['Kochi', 'Kerala'],
        682051: ['Kochi', 'Kerala'],
        685509: ['Trivandrum', 'Kerala'],
        685591: ['Kottayam', 'Kerala'],
        686001: ['Kottayam', 'Kerala'],
        686002: ['Kottayam', 'Kerala'],
        686003: ['Kottayam', 'Kerala'],
        686004: ['Kottayam', 'Kerala'],
        686005: ['Kottayam', 'Kerala'],
        686006: ['Kottayam', 'Kerala'],
        686007: ['Kottayam', 'Kerala'],
        686008: ['Kottayam', 'Kerala'],
        686009: ['Kottayam', 'Kerala'],
        686010: ['Kottayam', 'Kerala'],
        686011: ['Kottayam', 'Kerala'],
        686012: ['Kottayam', 'Kerala'],
        686013: ['Kottayam', 'Kerala'],
        686014: ['Kottayam', 'Kerala'],
        686015: ['Kottayam', 'Kerala'],
        686016: ['Kottayam', 'Kerala'],
        686017: ['Kottayam', 'Kerala'],
        686018: ['Kottayam', 'Kerala'],
        686019: ['Kottayam', 'Kerala'],
        686020: ['Kottayam', 'Kerala'],
        686021: ['Kottayam', 'Kerala'],
        686022: ['Kottayam', 'Kerala'],
        686023: ['Kottayam', 'Kerala'],
        686024: ['Kottayam', 'Kerala'],
        686025: ['Kottayam', 'Kerala'],
        686026: ['Kottayam', 'Kerala'],
        686028: ['Kottayam', 'Kerala'],
        686029: ['Kottayam', 'Kerala'],
        686030: ['Kottayam', 'Kerala'],
        686032: ['Kottayam', 'Kerala'],
        686033: ['Kottayam', 'Kerala'],
        686034: ['Kottayam', 'Kerala'],
        686035: ['Kottayam', 'Kerala'],
        686036: ['Kottayam', 'Kerala'],
        686037: ['Kottayam', 'Kerala'],
        686038: ['Kottayam', 'Kerala'],
        686041: ['Kottayam', 'Kerala'],
        686101: ['Kottayam', 'Kerala'],
        686102: ['Kottayam', 'Kerala'],
        686103: ['Kottayam', 'Kerala'],
        686104: ['Kottayam', 'Kerala'],
        686105: ['Kottayam', 'Kerala'],
        686106: ['Kottayam', 'Kerala'],
        686121: ['Kottayam', 'Kerala'],
        686122: ['Kottayam', 'Kerala'],
        686123: ['Kottayam', 'Kerala'],
        686124: ['Kottayam', 'Kerala'],
        686141: ['Kottayam', 'Kerala'],
        686142: ['Kottayam', 'Kerala'],
        686143: ['Kottayam', 'Kerala'],
        686144: ['Kottayam', 'Kerala'],
        686145: ['Kottayam', 'Kerala'],
        686146: ['Kottayam', 'Kerala'],
        686501: ['Kottayam', 'Kerala'],
        686502: ['Kottayam', 'Kerala'],
        686503: ['Kottayam', 'Kerala'],
        686504: ['Kottayam', 'Kerala'],
        686505: ['Kottayam', 'Kerala'],
        686506: ['Kottayam', 'Kerala'],
        686507: ['Kottayam', 'Kerala'],
        686508: ['Kottayam', 'Kerala'],
        686509: ['Kottayam', 'Kerala'],
        686510: ['Kottayam', 'Kerala'],
        686511: ['Kottayam', 'Kerala'],
        686512: ['Kottayam', 'Kerala'],
        686513: ['Kottayam', 'Kerala'],
        686514: ['Kottayam', 'Kerala'],
        686515: ['Kottayam', 'Kerala'],
        686516: ['Kottayam', 'Kerala'],
        686517: ['Kottayam', 'Kerala'],
        686518: ['Kottayam', 'Kerala'],
        686519: ['Kottayam', 'Kerala'],
        686520: ['Kottayam', 'Kerala'],
        686521: ['Kottayam', 'Kerala'],
        686522: ['Kottayam', 'Kerala'],
        686524: ['Kottayam', 'Kerala'],
        686527: ['Kottayam', 'Kerala'],
        686529: ['Kottayam', 'Kerala'],
        686531: ['Kottayam', 'Kerala'],
        686532: ['Kottayam', 'Kerala'],
        686533: ['Kottayam', 'Kerala'],
        686534: ['Kottayam', 'Kerala'],
        686535: ['Kottayam', 'Kerala'],
        686536: ['Kottayam', 'Kerala'],
        686537: ['Kottayam', 'Kerala'],
        686538: ['Kottayam', 'Kerala'],
        686539: ['Kottayam', 'Kerala'],
        686540: ['Kottayam', 'Kerala'],
        686541: ['Kottayam', 'Kerala'],
        686542: ['Kottayam', 'Kerala'],
        686543: ['Kottayam', 'Kerala'],
        686544: ['Kottayam', 'Kerala'],
        686545: ['Kottayam', 'Kerala'],
        686546: ['Kottayam', 'Kerala'],
        686548: ['Kottayam', 'Kerala'],
        686549: ['Kottayam', 'Kerala'],
        686550: ['Kottayam', 'Kerala'],
        686553: ['Kottayam', 'Kerala'],
        686555: ['Kottayam', 'Kerala'],
        686560: ['Kottayam', 'Kerala'],
        686561: ['Kottayam', 'Kerala'],
        686562: ['Kottayam', 'Kerala'],
        686563: ['Kottayam', 'Kerala'],
        686564: ['Kottayam', 'Kerala'],
        686566: ['Kottayam', 'Kerala'],
        686568: ['Kottayam', 'Kerala'],
        686571: ['Kottayam', 'Kerala'],
        686572: ['Kottayam', 'Kerala'],
        686573: ['Kottayam', 'Kerala'],
        686574: ['Kottayam', 'Kerala'],
        686575: ['Kottayam', 'Kerala'],
        686576: ['Kottayam', 'Kerala'],
        686577: ['Kottayam', 'Kerala'],
        686578: ['Kottayam', 'Kerala'],
        686579: ['Kottayam', 'Kerala'],
        686580: ['Kottayam', 'Kerala'],
        686581: ['Kottayam', 'Kerala'],
        686582: ['Kottayam', 'Kerala'],
        686583: ['Kottayam', 'Kerala'],
        686584: ['Kottayam', 'Kerala'],
        686585: ['Kottayam', 'Kerala'],
        686586: ['Kottayam', 'Kerala'],
        686587: ['Kottayam', 'Kerala'],
        686588: ['Kottayam', 'Kerala'],
        686589: ['Kottayam', 'Kerala'],
        686590: ['Kottayam', 'Kerala'],
        686592: ['Kottayam', 'Kerala'],
        686594: ['Kottayam', 'Kerala'],
        686595: ['Kottayam', 'Kerala'],
        686596: ['Kottayam', 'Kerala'],
        686597: ['Kottayam', 'Kerala'],
        686600: ['Kottayam', 'Kerala'],
        686601: ['Kottayam', 'Kerala'],
        686602: ['Kottayam', 'Kerala'],
        686603: ['Kottayam', 'Kerala'],
        686604: ['Kottayam', 'Kerala'],
        686605: ['Kottayam', 'Kerala'],
        686606: ['Kottayam', 'Kerala'],
        686607: ['Kottayam', 'Kerala'],
        686608: ['Kottayam', 'Kerala'],
        686609: ['Kottayam', 'Kerala'],
        686610: ['Kottayam', 'Kerala'],
        686611: ['Kottayam', 'Kerala'],
        686612: ['Kottayam', 'Kerala'],
        686613: ['Kottayam', 'Kerala'],
        686615: ['Kottayam', 'Kerala'],
        686616: ['Kottayam', 'Kerala'],
        686617: ['Kottayam', 'Kerala'],
        686630: ['Kottayam', 'Kerala'],
        686631: ['Kottayam', 'Kerala'],
        686632: ['Kottayam', 'Kerala'],
        686633: ['Kottayam', 'Kerala'],
        686634: ['Kottayam', 'Kerala'],
        686635: ['Kottayam', 'Kerala'],
        686636: ['Kottayam', 'Kerala'],
        686637: ['Kottayam', 'Kerala'],
        686638: ['Kottayam', 'Kerala'],
        686640: ['Kottayam', 'Kerala'],
        686641: ['Kottayam', 'Kerala'],
        686642: ['Kottayam', 'Kerala'],
        686651: ['Kottayam', 'Kerala'],
        686652: ['Kottayam', 'Kerala'],
        686653: ['Kottayam', 'Kerala'],
        686654: ['Kottayam', 'Kerala'],
        686655: ['Kottayam', 'Kerala'],
        689695: ['Kollam', 'Kerala'],
        689696: ['Kollam', 'Kerala'],
        690518: ['Kollam', 'Kerala'],
        690519: ['Kollam', 'Kerala'],
        690520: ['Kollam', 'Kerala'],
        690521: ['Kollam', 'Kerala'],
        690522: ['Kollam', 'Kerala'],
        690523: ['Kollam', 'Kerala'],
        690524: ['Kollam', 'Kerala'],
        690525: ['Kollam', 'Kerala'],
        690526: ['Kollam', 'Kerala'],
        690528: ['Kollam', 'Kerala'],
        690536: ['Kollam', 'Kerala'],
        690538: ['Kollam', 'Kerala'],
        690539: ['Kollam', 'Kerala'],
        690540: ['Kollam', 'Kerala'],
        690542: ['Kollam', 'Kerala'],
        690544: ['Kollam', 'Kerala'],
        690547: ['Kollam', 'Kerala'],
        690561: ['Kollam', 'Kerala'],
        690573: ['Kollam', 'Kerala'],
        690574: ['Kollam', 'Kerala'],
        691001: ['Kollam', 'Kerala'],
        691002: ['Kollam', 'Kerala'],
        691003: ['Kollam', 'Kerala'],
        691004: ['Kollam', 'Kerala'],
        691005: ['Kollam', 'Kerala'],
        691006: ['Kollam', 'Kerala'],
        691007: ['Kollam', 'Kerala'],
        691008: ['Kollam', 'Kerala'],
        691009: ['Kollam', 'Kerala'],
        691010: ['Kollam', 'Kerala'],
        691011: ['Kollam', 'Kerala'],
        691012: ['Kollam', 'Kerala'],
        691013: ['Kollam', 'Kerala'],
        691014: ['Kollam', 'Kerala'],
        691015: ['Kollam', 'Kerala'],
        691016: ['Kollam', 'Kerala'],
        691019: ['Kollam', 'Kerala'],
        691020: ['Kollam', 'Kerala'],
        691021: ['Kollam', 'Kerala'],
        691301: ['Kollam', 'Kerala'],
        691302: ['Kollam', 'Kerala'],
        691303: ['Kollam', 'Kerala'],
        691304: ['Kollam', 'Kerala'],
        691305: ['Kollam', 'Kerala'],
        691306: ['Kollam', 'Kerala'],
        691307: ['Kollam', 'Kerala'],
        691308: ['Kollam', 'Kerala'],
        691309: ['Kollam', 'Kerala'],
        691310: ['Kollam', 'Kerala'],
        691311: ['Kollam', 'Kerala'],
        691312: ['Kollam', 'Kerala'],
        691319: ['Kollam', 'Kerala'],
        691322: ['Kollam', 'Kerala'],
        691331: ['Kollam', 'Kerala'],
        691332: ['Kollam', 'Kerala'],
        691333: ['Kollam', 'Kerala'],
        691334: ['Kollam', 'Kerala'],
        691500: ['Kollam', 'Kerala'],
        691501: ['Kollam', 'Kerala'],
        691502: ['Kollam', 'Kerala'],
        691503: ['Kollam', 'Kerala'],
        691504: ['Kollam', 'Kerala'],
        691505: ['Kollam', 'Kerala'],
        691506: ['Kollam', 'Kerala'],
        691507: ['Kollam', 'Kerala'],
        691508: ['Kollam', 'Kerala'],
        691509: ['Kollam', 'Kerala'],
        691510: ['Kollam', 'Kerala'],
        691511: ['Kollam', 'Kerala'],
        691512: ['Kollam', 'Kerala'],
        691515: ['Kollam', 'Kerala'],
        691516: ['Kollam', 'Kerala'],
        691520: ['Kollam', 'Kerala'],
        691521: ['Kollam', 'Kerala'],
        691522: ['Kollam', 'Kerala'],
        691526: ['Kollam', 'Kerala'],
        691531: ['Kollam', 'Kerala'],
        691532: ['Kollam', 'Kerala'],
        691533: ['Kollam', 'Kerala'],
        691534: ['Kollam', 'Kerala'],
        691535: ['Kollam', 'Kerala'],
        691536: ['Kollam', 'Kerala'],
        691537: ['Kollam', 'Kerala'],
        691538: ['Kollam', 'Kerala'],
        691540: ['Kollam', 'Kerala'],
        691541: ['Kollam', 'Kerala'],
        691543: ['Kollam', 'Kerala'],
        691552: ['Kollam', 'Kerala'],
        691553: ['Kollam', 'Kerala'],
        691557: ['Kollam', 'Kerala'],
        691559: ['Kollam', 'Kerala'],
        691560: ['Kollam', 'Kerala'],
        691566: ['Kollam', 'Kerala'],
        691571: ['Kollam', 'Kerala'],
        691572: ['Kollam', 'Kerala'],
        691573: ['Kollam', 'Kerala'],
        691574: ['Kollam', 'Kerala'],
        691576: ['Kollam', 'Kerala'],
        691577: ['Kollam', 'Kerala'],
        691578: ['Kollam', 'Kerala'],
        691579: ['Kollam', 'Kerala'],
        691581: ['Kollam', 'Kerala'],
        691582: ['Kollam', 'Kerala'],
        691583: ['Kollam', 'Kerala'],
        691584: ['Kollam', 'Kerala'],
        691585: ['Kollam', 'Kerala'],
        691589: ['Kollam', 'Kerala'],
        691590: ['Kollam', 'Kerala'],
        691601: ['Kollam', 'Kerala'],
        691602: ['Kollam', 'Kerala'],
        695001: ['Trivandrum', 'Kerala'],
        695002: ['Trivandrum', 'Kerala'],
        695003: ['Trivandrum', 'Kerala'],
        695004: ['Trivandrum', 'Kerala'],
        695005: ['Trivandrum', 'Kerala'],
        695006: ['Trivandrum', 'Kerala'],
        695007: ['Trivandrum', 'Kerala'],
        695008: ['Trivandrum', 'Kerala'],
        695009: ['Trivandrum', 'Kerala'],
        695010: ['Trivandrum', 'Kerala'],
        695011: ['Trivandrum', 'Kerala'],
        695012: ['Trivandrum', 'Kerala'],
        695013: ['Trivandrum', 'Kerala'],
        695014: ['Trivandrum', 'Kerala'],
        695015: ['Trivandrum', 'Kerala'],
        695016: ['Trivandrum', 'Kerala'],
        695017: ['Trivandrum', 'Kerala'],
        695018: ['Trivandrum', 'Kerala'],
        695019: ['Trivandrum', 'Kerala'],
        695020: ['Trivandrum', 'Kerala'],
        695021: ['Trivandrum', 'Kerala'],
        695022: ['Trivandrum', 'Kerala'],
        695023: ['Trivandrum', 'Kerala'],
        695024: ['Trivandrum', 'Kerala'],
        695025: ['Trivandrum', 'Kerala'],
        695026: ['Trivandrum', 'Kerala'],
        695027: ['Trivandrum', 'Kerala'],
        695028: ['Trivandrum', 'Kerala'],
        695029: ['Trivandrum', 'Kerala'],
        695030: ['Trivandrum', 'Kerala'],
        695031: ['Trivandrum', 'Kerala'],
        695032: ['Trivandrum', 'Kerala'],
        695033: ['Trivandrum', 'Kerala'],
        695034: ['Trivandrum', 'Kerala'],
        695035: ['Trivandrum', 'Kerala'],
        695036: ['Trivandrum', 'Kerala'],
        695037: ['Trivandrum', 'Kerala'],
        695038: ['Trivandrum', 'Kerala'],
        695039: ['Trivandrum', 'Kerala'],
        695040: ['Trivandrum', 'Kerala'],
        695041: ['Trivandrum', 'Kerala'],
        695042: ['Trivandrum', 'Kerala'],
        695043: ['Trivandrum', 'Kerala'],
        695099: ['Trivandrum', 'Kerala'],
        695101: ['Trivandrum', 'Kerala'],
        695102: ['Trivandrum', 'Kerala'],
        695103: ['Trivandrum', 'Kerala'],
        695104: ['Trivandrum', 'Kerala'],
        695121: ['Trivandrum', 'Kerala'],
        695122: ['Trivandrum', 'Kerala'],
        695123: ['Trivandrum', 'Kerala'],
        695124: ['Trivandrum', 'Kerala'],
        695125: ['Trivandrum', 'Kerala'],
        695126: ['Trivandrum', 'Kerala'],
        695127: ['Trivandrum', 'Kerala'],
        695131: ['Trivandrum', 'Kerala'],
        695132: ['Trivandrum', 'Kerala'],
        695133: ['Trivandrum', 'Kerala'],
        695134: ['Trivandrum', 'Kerala'],
        695141: ['Trivandrum', 'Kerala'],
        695142: ['Trivandrum', 'Kerala'],
        695143: ['Trivandrum', 'Kerala'],
        695144: ['Trivandrum', 'Kerala'],
        695145: ['Trivandrum', 'Kerala'],
        695146: ['Trivandrum', 'Kerala'],
        695301: ['Trivandrum', 'Kerala'],
        695302: ['Trivandrum', 'Kerala'],
        695303: ['Trivandrum', 'Kerala'],
        695304: ['Trivandrum', 'Kerala'],
        695305: ['Trivandrum', 'Kerala'],
        695306: ['Trivandrum', 'Kerala'],
        695307: ['Trivandrum', 'Kerala'],
        695308: ['Trivandrum', 'Kerala'],
        695309: ['Trivandrum', 'Kerala'],
        695310: ['Trivandrum', 'Kerala'],
        695311: ['Trivandrum', 'Kerala'],
        695312: ['Trivandrum', 'Kerala'],
        695313: ['Trivandrum', 'Kerala'],
        695314: ['Trivandrum', 'Kerala'],
        695316: ['Trivandrum', 'Kerala'],
        695317: ['Trivandrum', 'Kerala'],
        695318: ['Trivandrum', 'Kerala'],
        695501: ['Trivandrum', 'Kerala'],
        695502: ['Trivandrum', 'Kerala'],
        695503: ['Trivandrum', 'Kerala'],
        695504: ['Trivandrum', 'Kerala'],
        695505: ['Trivandrum', 'Kerala'],
        695506: ['Trivandrum', 'Kerala'],
        695507: ['Trivandrum', 'Kerala'],
        695508: ['Trivandrum', 'Kerala'],
        695510: ['Trivandrum', 'Kerala'],
        695512: ['Trivandrum', 'Kerala'],
        695513: ['Trivandrum', 'Kerala'],
        695521: ['Trivandrum', 'Kerala'],
        695522: ['Trivandrum', 'Kerala'],
        695523: ['Trivandrum', 'Kerala'],
        695524: ['Trivandrum', 'Kerala'],
        695525: ['Trivandrum', 'Kerala'],
        695526: ['Trivandrum', 'Kerala'],
        695527: ['Trivandrum', 'Kerala'],
        695528: ['Trivandrum', 'Kerala'],
        695541: ['Trivandrum', 'Kerala'],
        695542: ['Trivandrum', 'Kerala'],
        695543: ['Trivandrum', 'Kerala'],
        695544: ['Trivandrum', 'Kerala'],
        695547: ['Trivandrum', 'Kerala'],
        695551: ['Trivandrum', 'Kerala'],
        695561: ['Trivandrum', 'Kerala'],
        695562: ['Trivandrum', 'Kerala'],
        695563: ['Trivandrum', 'Kerala'],
        695564: ['Trivandrum', 'Kerala'],
        695568: ['Trivandrum', 'Kerala'],
        695570: ['Trivandrum', 'Kerala'],
        695572: ['Trivandrum', 'Kerala'],
        695573: ['Trivandrum', 'Kerala'],
        695574: ['Trivandrum', 'Kerala'],
        695575: ['Trivandrum', 'Kerala'],
        695581: ['Trivandrum', 'Kerala'],
        695582: ['Trivandrum', 'Kerala'],
        695583: ['Trivandrum', 'Kerala'],
        695584: ['Trivandrum', 'Kerala'],
        695585: ['Trivandrum', 'Kerala'],
        695586: ['Trivandrum', 'Kerala'],
        695587: ['Trivandrum', 'Kerala'],
        695588: ['Trivandrum', 'Kerala'],
        695589: ['Trivandrum', 'Kerala'],
        695601: ['Trivandrum', 'Kerala'],
        695602: ['Trivandrum', 'Kerala'],
        695603: ['Trivandrum', 'Kerala'],
        695604: ['Trivandrum', 'Kerala'],
        695605: ['Trivandrum', 'Kerala'],
        695606: ['Trivandrum', 'Kerala'],
        695607: ['Trivandrum', 'Kerala'],
        695608: ['Trivandrum', 'Kerala'],
        695609: ['Trivandrum', 'Kerala'],
        695610: ['Trivandrum', 'Kerala'],
        695611: ['Trivandrum', 'Kerala'],
        695612: ['Trivandrum', 'Kerala'],
        695614: ['Trivandrum', 'Kerala'],
        695615: ['Trivandrum', 'Kerala'],
        695616: ['Trivandrum', 'Kerala'],
        695618: ['Trivandrum', 'Kerala'],
        700001: ['Kolkata', 'West Bengal'],
        700002: ['Kolkata', 'West Bengal'],
        700003: ['Kolkata', 'West Bengal'],
        700004: ['Kolkata', 'West Bengal'],
        700005: ['Kolkata', 'West Bengal'],
        700006: ['Kolkata', 'West Bengal'],
        700007: ['Kolkata', 'West Bengal'],
        700008: ['Kolkata', 'West Bengal'],
        700009: ['Kolkata', 'West Bengal'],
        700010: ['Kolkata', 'West Bengal'],
        700011: ['Kolkata', 'West Bengal'],
        700012: ['Kolkata', 'West Bengal'],
        700013: ['Kolkata', 'West Bengal'],
        700014: ['Kolkata', 'West Bengal'],
        700015: ['Kolkata', 'West Bengal'],
        700016: ['Kolkata', 'West Bengal'],
        700017: ['Kolkata', 'West Bengal'],
        700018: ['Kolkata', 'West Bengal'],
        700019: ['Kolkata', 'West Bengal'],
        700020: ['Kolkata', 'West Bengal'],
        700021: ['Kolkata', 'West Bengal'],
        700022: ['Kolkata', 'West Bengal'],
        700023: ['Kolkata', 'West Bengal'],
        700024: ['Kolkata', 'West Bengal'],
        700025: ['Kolkata', 'West Bengal'],
        700026: ['Kolkata', 'West Bengal'],
        700027: ['Kolkata', 'West Bengal'],
        700028: ['Kolkata', 'West Bengal'],
        700029: ['Kolkata', 'West Bengal'],
        700030: ['Kolkata', 'West Bengal'],
        700031: ['Kolkata', 'West Bengal'],
        700032: ['Kolkata', 'West Bengal'],
        700033: ['Kolkata', 'West Bengal'],
        700034: ['Kolkata', 'West Bengal'],
        700035: ['Kolkata', 'West Bengal'],
        700036: ['Kolkata', 'West Bengal'],
        700037: ['Kolkata', 'West Bengal'],
        700038: ['Kolkata', 'West Bengal'],
        700040: ['Kolkata', 'West Bengal'],
        700041: ['Kolkata', 'West Bengal'],
        700042: ['Kolkata', 'West Bengal'],
        700043: ['Kolkata', 'West Bengal'],
        700044: ['Kolkata', 'West Bengal'],
        700045: ['Kolkata', 'West Bengal'],
        700046: ['Kolkata', 'West Bengal'],
        700047: ['Kolkata', 'West Bengal'],
        700050: ['Kolkata', 'West Bengal'],
        700052: ['Kolkata', 'West Bengal'],
        700053: ['Kolkata', 'West Bengal'],
        700054: ['Kolkata', 'West Bengal'],
        700060: ['Kolkata', 'West Bengal'],
        700061: ['Kolkata', 'West Bengal'],
        700062: ['Kolkata', 'West Bengal'],
        700063: ['Kolkata', 'West Bengal'],
        700065: ['Kolkata', 'West Bengal'],
        700066: ['Kolkata', 'West Bengal'],
        700067: ['Kolkata', 'West Bengal'],
        700068: ['Kolkata', 'West Bengal'],
        700069: ['Kolkata', 'West Bengal'],
        700071: ['Kolkata', 'West Bengal'],
        700072: ['Kolkata', 'West Bengal'],
        700073: ['Kolkata', 'West Bengal'],
        700074: ['Kolkata', 'West Bengal'],
        700075: ['Kolkata', 'West Bengal'],
        700077: ['Kolkata', 'West Bengal'],
        700078: ['Kolkata', 'West Bengal'],
        700080: ['Kolkata', 'West Bengal'],
        700082: ['Kolkata', 'West Bengal'],
        700085: ['Kolkata', 'West Bengal'],
        700086: ['Kolkata', 'West Bengal'],
        700087: ['Kolkata', 'West Bengal'],
        700088: ['Kolkata', 'West Bengal'],
        700089: ['Kolkata', 'West Bengal'],
        700090: ['Kolkata', 'West Bengal'],
        700092: ['Kolkata', 'West Bengal'],
        700094: ['Kolkata', 'West Bengal'],
        700095: ['Kolkata', 'West Bengal'],
        700099: ['Kolkata', 'West Bengal'],
        700107: ['Kolkata', 'West Bengal'],
        700108: ['Kolkata', 'West Bengal'],
        711101: ['Howrah', 'West Bengal'],
        711102: ['Howrah', 'West Bengal'],
        711103: ['Howrah', 'West Bengal'],
        711104: ['Howrah', 'West Bengal'],
        711105: ['Howrah', 'West Bengal'],
        711106: ['Howrah', 'West Bengal'],
        711107: ['Howrah', 'West Bengal'],
        711108: ['Howrah', 'West Bengal'],
        711109: ['Howrah', 'West Bengal'],
        711110: ['Howrah', 'West Bengal'],
        711111: ['Howrah', 'West Bengal'],
        711112: ['Howrah', 'West Bengal'],
        711113: ['Howrah', 'West Bengal'],
        711114: ['Howrah', 'West Bengal'],
        711201: ['Howrah', 'West Bengal'],
        711202: ['Howrah', 'West Bengal'],
        711203: ['Howrah', 'West Bengal'],
        711204: ['Howrah', 'West Bengal'],
        711205: ['Howrah', 'West Bengal'],
        711224: ['Howrah', 'West Bengal'],
        711225: ['Howrah', 'West Bengal'],
        711226: ['Howrah', 'West Bengal'],
        711227: ['Howrah', 'West Bengal'],
        711301: ['Howrah', 'West Bengal'],
        711302: ['Howrah', 'West Bengal'],
        711303: ['Howrah', 'West Bengal'],
        711304: ['Howrah', 'West Bengal'],
        711305: ['Howrah', 'West Bengal'],
        711306: ['Howrah', 'West Bengal'],
        711307: ['Howrah', 'West Bengal'],
        711308: ['Howrah', 'West Bengal'],
        711309: ['Howrah', 'West Bengal'],
        711310: ['Howrah', 'West Bengal'],
        711311: ['Howrah', 'West Bengal'],
        711312: ['Howrah', 'West Bengal'],
        711313: ['Howrah', 'West Bengal'],
        711314: ['Howrah', 'West Bengal'],
        711315: ['Howrah', 'West Bengal'],
        711316: ['Howrah', 'West Bengal'],
        711317: ['Howrah', 'West Bengal'],
        711321: ['Howrah', 'West Bengal'],
        711322: ['Howrah', 'West Bengal'],
        711323: ['Howrah', 'West Bengal'],
        711324: ['Howrah', 'West Bengal'],
        711325: ['Howrah', 'West Bengal'],
        711326: ['Howrah', 'West Bengal'],
        711327: ['Howrah', 'West Bengal'],
        711401: ['Howrah', 'West Bengal'],
        711402: ['Howrah', 'West Bengal'],
        711403: ['Howrah', 'West Bengal'],
        711404: ['Howrah', 'West Bengal'],
        711405: ['Howrah', 'West Bengal'],
        711406: ['Howrah', 'West Bengal'],
        711408: ['Howrah', 'West Bengal'],
        711409: ['Howrah', 'West Bengal'],
        711410: ['Howrah', 'West Bengal'],
        711411: ['Howrah', 'West Bengal'],
        711412: ['Howrah', 'West Bengal'],
        711413: ['Howrah', 'West Bengal'],
        711414: ['Howrah', 'West Bengal'],
        711415: ['Howrah', 'West Bengal'],
        711416: ['Howrah', 'West Bengal'],
        712101: ['Chinsurah', 'West Bengal'],
        712102: ['Chinsurah', 'West Bengal'],
        712136: ['BARASAT', 'West Bengal'],
        712221: ['Durgapur', 'West Bengal'],
        712408: ['Howrah', 'West Bengal'],
        713201: ['Durgapur', 'West Bengal'],
        713202: ['Durgapur', 'West Bengal'],
        713203: ['Durgapur', 'West Bengal'],
        713204: ['Durgapur', 'West Bengal'],
        713205: ['Durgapur', 'West Bengal'],
        713206: ['Durgapur', 'West Bengal'],
        713207: ['Durgapur', 'West Bengal'],
        713209: ['Durgapur', 'West Bengal'],
        713210: ['Durgapur', 'West Bengal'],
        713214: ['Durgapur', 'West Bengal'],
        713215: ['Durgapur', 'West Bengal'],
        734001: ['Darjeeling', 'West Bengal'],
        734002: ['Darjeeling', 'West Bengal'],
        734003: ['Darjeeling', 'West Bengal'],
        734005: ['Darjeeling', 'West Bengal'],
        734008: ['Darjeeling', 'West Bengal'],
        734009: ['Darjeeling', 'West Bengal'],
        734010: ['Darjeeling', 'West Bengal'],
        734011: ['Darjeeling', 'West Bengal'],
        734012: ['Darjeeling', 'West Bengal'],
        734013: ['Darjeeling', 'West Bengal'],
        734014: ['Darjeeling', 'West Bengal'],
        734101: ['Darjeeling', 'West Bengal'],
        734102: ['Darjeeling', 'West Bengal'],
        734103: ['Darjeeling', 'West Bengal'],
        734104: ['Darjeeling', 'West Bengal'],
        734105: ['Darjeeling', 'West Bengal'],
        734121: ['Darjeeling', 'West Bengal'],
        734122: ['Darjeeling', 'West Bengal'],
        734123: ['Darjeeling', 'West Bengal'],
        734124: ['Darjeeling', 'West Bengal'],
        734201: ['Darjeeling', 'West Bengal'],
        734203: ['Darjeeling', 'West Bengal'],
        734204: ['Darjeeling', 'West Bengal'],
        734209: ['Darjeeling', 'West Bengal'],
        734213: ['Darjeeling', 'West Bengal'],
        734214: ['Darjeeling', 'West Bengal'],
        734215: ['Darjeeling', 'West Bengal'],
        734216: ['Darjeeling', 'West Bengal'],
        734217: ['Darjeeling', 'West Bengal'],
        734218: ['Darjeeling', 'West Bengal'],
        734219: ['Darjeeling', 'West Bengal'],
        734220: ['Darjeeling', 'West Bengal'],
        734221: ['Darjeeling', 'West Bengal'],
        734222: ['Darjeeling', 'West Bengal'],
        734223: ['Darjeeling', 'West Bengal'],
        734224: ['Darjeeling', 'West Bengal'],
        734225: ['Darjeeling', 'West Bengal'],
        734226: ['Darjeeling', 'West Bengal'],
        734227: ['Darjeeling', 'West Bengal'],
        734301: ['Darjeeling', 'West Bengal'],
        734311: ['Darjeeling', 'West Bengal'],
        734312: ['Darjeeling', 'West Bengal'],
        734313: ['Darjeeling', 'West Bengal'],
        734314: ['Darjeeling', 'West Bengal'],
        734315: ['Darjeeling', 'West Bengal'],
        734316: ['Darjeeling', 'West Bengal'],
        734317: ['Darjeeling', 'West Bengal'],
        734319: ['Darjeeling', 'West Bengal'],
        734320: ['Darjeeling', 'West Bengal'],
        734321: ['Darjeeling', 'West Bengal'],
        734401: ['Darjeeling', 'West Bengal'],
        734403: ['Darjeeling', 'West Bengal'],
        734404: ['Darjeeling', 'West Bengal'],
        734405: ['Siluguri', 'West Bengal'],
        734406: ['Darjeeling', 'West Bengal'],
        734421: ['Darjeeling', 'West Bengal'],
        734422: ['Darjeeling', 'West Bengal'],
        734423: ['Darjeeling', 'West Bengal'],
        734424: ['Darjeeling', 'West Bengal'],
        734425: ['Darjeeling', 'West Bengal'],
        734426: ['Darjeeling', 'West Bengal'],
        734427: ['Darjeeling', 'West Bengal'],
        734428: ['Darjeeling', 'West Bengal'],
        734429: ['Darjeeling', 'West Bengal'],
        734430: ['Darjeeling', 'West Bengal'],
        734431: ['Darjeeling', 'West Bengal'],
        734432: ['Darjeeling', 'West Bengal'],
        734433: ['Darjeeling', 'West Bengal'],
        734434: ['Darjeeling', 'West Bengal'],
        734435: ['Darjeeling', 'West Bengal'],
        734436: ['Darjeeling', 'West Bengal'],
        734501: ['Darjeeling', 'West Bengal'],
        734502: ['Darjeeling', 'West Bengal'],
        734503: ['Darjeeling', 'West Bengal'],
        735231: ['Darjeeling', 'West Bengal'],
        742102: ['Kolkata', 'West Bengal'],
        743201: ['BARASAT', 'West Bengal'],
        743262: ['BARASAT', 'West Bengal'],
        743372: ['BARASAT', 'West Bengal'],
        751001: ['Bhubaneswar', 'Orissa'],
        751002: ['Bhubaneswar', 'Orissa'],
        751009: ['Bhubaneswar', 'Orissa'],
        753001: ['Cuttack', 'Orissa'],
        753002: ['Cuttack', 'Orissa'],
        753003: ['Cuttack', 'Orissa'],
        753004: ['Cuttack', 'Orissa'],
        753005: ['Cuttack', 'Orissa'],
        753006: ['Cuttack', 'Orissa'],
        753007: ['Cuttack', 'Orissa'],
        753008: ['Cuttack', 'Orissa'],
        753009: ['Cuttack', 'Orissa'],
        753010: ['Cuttack', 'Orissa'],
        753011: ['Cuttack', 'Orissa'],
        753012: ['Cuttack', 'Orissa'],
        753013: ['Cuttack', 'Orissa'],
        753014: ['Cuttack', 'Orissa'],
        753015: ['Cuttack', 'Orissa'],
        753051: ['Cuttack', 'Orissa'],
        753052: ['Cuttack', 'Orissa'],
        754001: ['Cuttack', 'Orissa'],
        754002: ['Cuttack', 'Orissa'],
        754003: ['Cuttack', 'Orissa'],
        754004: ['Cuttack', 'Orissa'],
        754005: ['Cuttack', 'Orissa'],
        754006: ['Cuttack', 'Orissa'],
        754007: ['Cuttack', 'Orissa'],
        754008: ['Cuttack', 'Orissa'],
        754009: ['Cuttack', 'Orissa'],
        754010: ['Cuttack', 'Orissa'],
        754011: ['Cuttack', 'Orissa'],
        754012: ['Cuttack', 'Orissa'],
        754013: ['Cuttack', 'Orissa'],
        754014: ['Cuttack', 'Orissa'],
        754015: ['Cuttack', 'Orissa'],
        754016: ['Cuttack', 'Orissa'],
        754017: ['Cuttack', 'Orissa'],
        754018: ['Cuttack', 'Orissa'],
        754021: ['Cuttack', 'Orissa'],
        754022: ['Cuttack', 'Orissa'],
        754023: ['Cuttack', 'Orissa'],
        754024: ['Cuttack', 'Orissa'],
        754025: ['Cuttack', 'Orissa'],
        754026: ['Cuttack', 'Orissa'],
        754027: ['Cuttack', 'Orissa'],
        754028: ['Cuttack', 'Orissa'],
        754029: ['Cuttack', 'Orissa'],
        754030: ['Cuttack', 'Orissa'],
        754031: ['Cuttack', 'Orissa'],
        754032: ['Cuttack', 'Orissa'],
        754033: ['Cuttack', 'Orissa'],
        754034: ['Cuttack', 'Orissa'],
        754035: ['Cuttack', 'Orissa'],
        754036: ['Cuttack', 'Orissa'],
        754037: ['Cuttack', 'Orissa'],
        754070: ['Cuttack', 'Orissa'],
        754071: ['Cuttack', 'Orissa'],
        754080: ['Cuttack', 'Orissa'],
        754081: ['Cuttack', 'Orissa'],
        754082: ['Cuttack', 'Orissa'],
        754100: ['Cuttack', 'Orissa'],
        754101: ['Cuttack', 'Orissa'],
        754102: ['Cuttack', 'Orissa'],
        754103: ['Cuttack', 'Orissa'],
        754104: ['Cuttack', 'Orissa'],
        754105: ['Cuttack', 'Orissa'],
        754106: ['Cuttack', 'Orissa'],
        754107: ['Cuttack', 'Orissa'],
        754108: ['Cuttack', 'Orissa'],
        754109: ['Cuttack', 'Orissa'],
        754110: ['Cuttack', 'Orissa'],
        754111: ['Cuttack', 'Orissa'],
        754112: ['Cuttack', 'Orissa'],
        754113: ['Cuttack', 'Orissa'],
        754114: ['Cuttack', 'Orissa'],
        754115: ['Cuttack', 'Orissa'],
        754116: ['Cuttack', 'Orissa'],
        754117: ['Cuttack', 'Orissa'],
        754118: ['Cuttack', 'Orissa'],
        754119: ['Cuttack', 'Orissa'],
        754131: ['Cuttack', 'Orissa'],
        754132: ['Cuttack', 'Orissa'],
        754133: ['Cuttack', 'Orissa'],
        754134: ['Cuttack', 'Orissa'],
        754135: ['Cuttack', 'Orissa'],
        754136: ['Cuttack', 'Orissa'],
        754137: ['Cuttack', 'Orissa'],
        754139: ['Cuttack', 'Orissa'],
        754140: ['Cuttack', 'Orissa'],
        754141: ['Cuttack', 'Orissa'],
        754142: ['Cuttack', 'Orissa'],
        754143: ['Cuttack', 'Orissa'],
        754144: ['Cuttack', 'Orissa'],
        754153: ['Cuttack', 'Orissa'],
        754154: ['Cuttack', 'Orissa'],
        754155: ['Cuttack', 'Orissa'],
        754156: ['Cuttack', 'Orissa'],
        754157: ['Cuttack', 'Orissa'],
        754158: ['Cuttack', 'Orissa'],
        754159: ['Cuttack', 'Orissa'],
        754160: ['Cuttack', 'Orissa'],
        754161: ['Cuttack', 'Orissa'],
        754162: ['Cuttack', 'Orissa'],
        754200: ['Cuttack', 'Orissa'],
        754201: ['Cuttack', 'Orissa'],
        754202: ['Cuttack', 'Orissa'],
        754203: ['Cuttack', 'Orissa'],
        754204: ['Cuttack', 'Orissa'],
        754205: ['Cuttack', 'Orissa'],
        754206: ['Cuttack', 'Orissa'],
        754207: ['Cuttack', 'Orissa'],
        754208: ['Cuttack', 'Orissa'],
        754209: ['Cuttack', 'Orissa'],
        754210: ['Cuttack', 'Orissa'],
        754211: ['Cuttack', 'Orissa'],
        754212: ['Cuttack', 'Orissa'],
        754213: ['Cuttack', 'Orissa'],
        754214: ['Cuttack', 'Orissa'],
        754215: ['Cuttack', 'Orissa'],
        754216: ['Cuttack', 'Orissa'],
        754217: ['Cuttack', 'Orissa'],
        754218: ['Cuttack', 'Orissa'],
        754219: ['Cuttack', 'Orissa'],
        754220: ['Cuttack', 'Orissa'],
        754221: ['Cuttack', 'Orissa'],
        754222: ['Cuttack', 'Orissa'],
        754223: ['Cuttack', 'Orissa'],
        754224: ['Cuttack', 'Orissa'],
        754225: ['Cuttack', 'Orissa'],
        754226: ['Cuttack', 'Orissa'],
        754227: ['Cuttack', 'Orissa'],
        754228: ['Cuttack', 'Orissa'],
        754229: ['Cuttack', 'Orissa'],
        754230: ['Cuttack', 'Orissa'],
        754231: ['Cuttack', 'Orissa'],
        754232: ['Cuttack', 'Orissa'],
        754234: ['Cuttack', 'Orissa'],
        754235: ['Cuttack', 'Orissa'],
        754236: ['Cuttack', 'Orissa'],
        754237: ['Cuttack', 'Orissa'],
        754238: ['Cuttack', 'Orissa'],
        754239: ['Cuttack', 'Orissa'],
        754240: ['Cuttack', 'Orissa'],
        754241: ['Cuttack', 'Orissa'],
        754242: ['Cuttack', 'Orissa'],
        754243: ['Cuttack', 'Orissa'],
        754244: ['Cuttack', 'Orissa'],
        754246: ['Cuttack', 'Orissa'],
        754247: ['Cuttack', 'Orissa'],
        754248: ['Cuttack', 'Orissa'],
        754249: ['Cuttack', 'Orissa'],
        754250: ['Cuttack', 'Orissa'],
        754251: ['Cuttack', 'Orissa'],
        754252: ['Cuttack', 'Orissa'],
        754253: ['Cuttack', 'Orissa'],
        754260: ['Cuttack', 'Orissa'],
        754280: ['Cuttack', 'Orissa'],
        754281: ['Cuttack', 'Orissa'],
        754282: ['Cuttack', 'Orissa'],
        754284: ['Cuttack', 'Orissa'],
        754285: ['Cuttack', 'Orissa'],
        754286: ['Cuttack', 'Orissa'],
        754287: ['Cuttack', 'Orissa'],
        754288: ['Cuttack', 'Orissa'],
        754289: ['Cuttack', 'Orissa'],
        754290: ['Cuttack', 'Orissa'],
        754291: ['Cuttack', 'Orissa'],
        754292: ['Cuttack', 'Orissa'],
        754293: ['Cuttack', 'Orissa'],
        754295: ['Cuttack', 'Orissa'],
        755001: ['Cuttack', 'Orissa'],
        755003: ['Cuttack', 'Orissa'],
        755004: ['Cuttack', 'Orissa'],
        755005: ['Cuttack', 'Orissa'],
        755006: ['Cuttack', 'Orissa'],
        755007: ['Cuttack', 'Orissa'],
        755008: ['Cuttack', 'Orissa'],
        755009: ['Cuttack', 'Orissa'],
        755010: ['Cuttack', 'Orissa'],
        755011: ['Cuttack', 'Orissa'],
        755012: ['Cuttack', 'Orissa'],
        755013: ['Cuttack', 'Orissa'],
        755014: ['Cuttack', 'Orissa'],
        755015: ['Cuttack', 'Orissa'],
        755016: ['Cuttack', 'Orissa'],
        755017: ['Cuttack', 'Orissa'],
        755018: ['Cuttack', 'Orissa'],
        755019: ['Cuttack', 'Orissa'],
        755021: ['Cuttack', 'Orissa'],
        755022: ['Cuttack', 'Orissa'],
        755023: ['Cuttack', 'Orissa'],
        755024: ['Cuttack', 'Orissa'],
        755025: ['Cuttack', 'Orissa'],
        755026: ['Cuttack', 'Orissa'],
        755027: ['Cuttack', 'Orissa'],
        755028: ['Cuttack', 'Orissa'],
        755029: ['Cuttack', 'Orissa'],
        755030: ['Cuttack', 'Orissa'],
        755031: ['Cuttack', 'Orissa'],
        755032: ['Cuttack', 'Orissa'],
        755033: ['Cuttack', 'Orissa'],
        755034: ['Cuttack', 'Orissa'],
        755035: ['Cuttack', 'Orissa'],
        755036: ['Cuttack', 'Orissa'],
        755037: ['Cuttack', 'Orissa'],
        755038: ['Cuttack', 'Orissa'],
        755039: ['Cuttack', 'Orissa'],
        755041: ['Cuttack', 'Orissa'],
        755042: ['Cuttack', 'Orissa'],
        755043: ['Cuttack', 'Orissa'],
        755044: ['Cuttack', 'Orissa'],
        755045: ['Cuttack', 'Orissa'],
        755046: ['Cuttack', 'Orissa'],
        755047: ['Cuttack', 'Orissa'],
        755048: ['Cuttack', 'Orissa'],
        755049: ['Cuttack', 'Orissa'],
        755061: ['Cuttack', 'Orissa'],
        764027: ['Cuttack', 'Orissa'],
        769001: ['Rourkela', 'Orissa'],
        769002: ['Rourkela', 'Orissa'],
        769003: ['Rourkela', 'Orissa'],
        769004: ['Rourkela', 'Orissa'],
        769005: ['Rourkela', 'Orissa'],
        769006: ['Rourkela', 'Orissa'],
        769007: ['Rourkela', 'Orissa'],
        769008: ['Rourkela', 'Orissa'],
        769009: ['Rourkela', 'Orissa'],
        769012: ['Rourkela', 'Orissa'],
        769014: ['Rourkela', 'Orissa'],
        769016: ['Rourkela', 'Orissa'],
        781002: ['Guwahati', 'Assam'],
        800001: ['Patna', 'Bihar'],
        800002: ['Patna', 'Bihar'],
        800003: ['Patna', 'Bihar'],
        800004: ['Patna', 'Bihar'],
        800005: ['Patna', 'Bihar'],
        800006: ['Patna', 'Bihar'],
        800007: ['Patna', 'Bihar'],
        800008: ['Patna', 'Bihar'],
        800009: ['Patna', 'Bihar'],
        800010: ['Patna', 'Bihar'],
        800011: ['Patna', 'Bihar'],
        800012: ['Patna', 'Bihar'],
        800013: ['Patna', 'Bihar'],
        800014: ['Patna', 'Bihar'],
        800015: ['Patna', 'Bihar'],
        800016: ['Patna', 'Bihar'],
        800017: ['Patna', 'Bihar'],
        800018: ['Patna', 'Bihar'],
        800019: ['Patna', 'Bihar'],
        800020: ['Patna', 'Bihar'],
        800021: ['Patna', 'Bihar'],
        800022: ['Patna', 'Bihar'],
        800023: ['Patna', 'Bihar'],
        800024: ['Patna', 'Bihar'],
        800025: ['Patna', 'Bihar'],
        800026: ['Patna', 'Bihar'],
        800027: ['Patna', 'Bihar'],
        800028: ['Patna', 'Bihar'],
        800029: ['Patna', 'Bihar'],
        800030: ['Patna', 'Bihar'],
        801101: ['Patna', 'Bihar'],
        801102: ['Patna', 'Bihar'],
        801103: ['Patna', 'Bihar'],
        801104: ['Patna', 'Bihar'],
        801105: ['Patna', 'Bihar'],
        801106: ['Patna', 'Bihar'],
        801108: ['Patna', 'Bihar'],
        801109: ['Patna', 'Bihar'],
        801110: ['Patna', 'Bihar'],
        801111: ['Patna', 'Bihar'],
        801112: ['Patna', 'Bihar'],
        801113: ['Patna', 'Bihar'],
        801114: ['Patna', 'Bihar'],
        801115: ['Patna', 'Bihar'],
        801117: ['Patna', 'Bihar'],
        801118: ['Patna', 'Bihar'],
        801304: ['Patna', 'Bihar'],
        801501: ['Patna', 'Bihar'],
        801502: ['Patna', 'Bihar'],
        801503: ['Patna', 'Bihar'],
        801505: ['Patna', 'Bihar'],
        801506: ['Patna', 'Bihar'],
        801507: ['Patna', 'Bihar'],
        803201: ['Patna', 'Bihar'],
        803202: ['Patna', 'Bihar'],
        803203: ['Patna', 'Bihar'],
        803204: ['Patna', 'Bihar'],
        803205: ['Patna', 'Bihar'],
        803211: ['Patna', 'Bihar'],
        803212: ['Patna', 'Bihar'],
        803213: ['Patna', 'Bihar'],
        803214: ['Patna', 'Bihar'],
        803221: ['Patna', 'Bihar'],
        803232: ['Patna', 'Bihar'],
        803301: ['Patna', 'Bihar'],
        803302: ['Patna', 'Bihar'],
        803303: ['Patna', 'Bihar'],
        803306: ['Patna', 'Bihar'],
        803307: ['Patna', 'Bihar'],
        804451: ['Patna', 'Bihar'],
        804452: ['Patna', 'Bihar'],
        804453: ['Patna', 'Bihar'],
        804454: ['Patna', 'Bihar'],
        804457: ['Patna', 'Bihar'],
        813202: ['Patna', 'Bihar'],
        826001: ['Dhanbad', 'Jharkhand'],
        826003: ['Dhanbad', 'Jharkhand'],
        826004: ['Dhanbad', 'Jharkhand'],
        826005: ['Dhanbad', 'Jharkhand'],
        827302: ['Dhanbad', 'Jharkhand'],
        828101: ['Dhanbad', 'Jharkhand'],
        828102: ['Dhanbad', 'Jharkhand'],
        828103: ['Dhanbad', 'Jharkhand'],
        828104: ['Dhanbad', 'Jharkhand'],
        828105: ['Dhanbad', 'Jharkhand'],
        828106: ['Dhanbad', 'Jharkhand'],
        828107: ['Dhanbad', 'Jharkhand'],
        828108: ['Dhanbad', 'Jharkhand'],
        828109: ['Dhanbad', 'Jharkhand'],
        828110: ['Dhanbad', 'Jharkhand'],
        828111: ['Dhanbad', 'Jharkhand'],
        828112: ['Dhanbad', 'Jharkhand'],
        828113: ['Dhanbad', 'Jharkhand'],
        828114: ['Dhanbad', 'Jharkhand'],
        828115: ['Dhanbad', 'Jharkhand'],
        828116: ['Dhanbad', 'Jharkhand'],
        828117: ['Dhanbad', 'Jharkhand'],
        828119: ['Dhanbad', 'Jharkhand'],
        828120: ['Dhanbad', 'Jharkhand'],
        828121: ['Dhanbad', 'Jharkhand'],
        828122: ['Dhanbad', 'Jharkhand'],
        828123: ['Dhanbad', 'Jharkhand'],
        828124: ['Dhanbad', 'Jharkhand'],
        828125: ['Dhanbad', 'Jharkhand'],
        828126: ['Dhanbad', 'Jharkhand'],
        828127: ['Dhanbad', 'Jharkhand'],
        828128: ['Dhanbad', 'Jharkhand'],
        828129: ['Dhanbad', 'Jharkhand'],
        828130: ['Dhanbad', 'Jharkhand'],
        828131: ['Dhanbad', 'Jharkhand'],
        828132: ['Dhanbad', 'Jharkhand'],
        828133: ['Dhanbad', 'Jharkhand'],
        828135: ['Dhanbad', 'Jharkhand'],
        828142: ['Dhanbad', 'Jharkhand'],
        828144: ['Dhanbad', 'Jharkhand'],
        828201: ['Dhanbad', 'Jharkhand'],
        828202: ['Dhanbad', 'Jharkhand'],
        828203: ['Dhanbad', 'Jharkhand'],
        828204: ['Dhanbad', 'Jharkhand'],
        828205: ['Dhanbad', 'Jharkhand'],
        828206: ['Dhanbad', 'Jharkhand'],
        828207: ['Dhanbad', 'Jharkhand'],
        828301: ['Dhanbad', 'Jharkhand'],
        828304: ['Dhanbad', 'Jharkhand'],
        828305: ['Dhanbad', 'Jharkhand'],
        828306: ['Dhanbad', 'Jharkhand'],
        828307: ['Dhanbad', 'Jharkhand'],
        828308: ['Dhanbad', 'Jharkhand'],
        828309: ['Dhanbad', 'Jharkhand'],
        828311: ['Dhanbad', 'Jharkhand'],
        828401: ['Dhanbad', 'Jharkhand'],
        828402: ['Dhanbad', 'Jharkhand'],
        831001: ['Jamshedpur', 'Bihar'],
        854105: ['Drugapur', 'Bihar'],
    }
}
