import React from 'react';
import NotificationAlert from "react-notification-alert";

import {connect} from "react-redux";
import * as actions from '../../../Store/actions';

class Notify extends React.Component {

    notify = (place, title, message, type) => {
        let options = {
            place: place,
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {title}
          </span>
                    <span data-notify="message">
            {message}
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 10
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.notification) {
            this.notify("tr", nextProps.notification.title, nextProps.notification.message, nextProps.notification.type)
        }
    }

    render() {

        return <>
            <div className="rna-wrapper">
                <NotificationAlert ref="notificationAlert" />
            </div>
        </>
    }
}


const mapStateToProps = state => {
    return {
        notification: state.common.notification,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notify);