import * as actionTypes from '../actions/actionTypes/partnerTypes';
import {
    updateObject
} from '../utility';



const initialState = {
    error: null,

    policiesListLoading: false,
    policyCardsLoading: false,

    policiesList: null,
    policiesListCount: null,
    policyCardsData: null,
};

//  START 
const partnerPoliciesListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policiesListLoading: true
    });
};
const partnerPolicyCardsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        policyCardsLoading: true
    });
};

//  SUCCESS 
const partnerPoliciesListSuccess = (state, action) => {
    return updateObject(state, {
        policiesList: action.data,
        policiesListCount: action.count,
        error: null,
        policiesListLoading: false
    });
};
const partnerPolicyCardsSuccess = (state, action) => {
    return updateObject(state, {
        policyCardsData: action.data,
        error: null,
        policyCardsLoading: false
    });
};

//  FAIL 
const partnerPoliciesListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policiesListLoading: false
    });
};
const partnerPolicyCardsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        policyCardsLoading: false
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PARTNER_POLICIES_LIST_START:
            return partnerPoliciesListStart(state, action);
        case actionTypes.GET_PARTNER_POLICIES_CARDS_START:
            return partnerPolicyCardsStart(state, action);


        case actionTypes.GET_PARTNER_POLICIES_LIST_SUCCESS:
            return partnerPoliciesListSuccess(state, action);
        case actionTypes.GET_PARTNER_POLICIES_CARDS_SUCCESS:
            return partnerPolicyCardsSuccess(state, action);


        case actionTypes.GET_PARTNER_POLICIES_LIST_FAIL:
            return partnerPoliciesListFail(state, action);
        case actionTypes.GET_PARTNER_POLICIES_CARDS_FAIL:
            return partnerPolicyCardsFail(state, action);


        default:
            return state;
    }
};

export default reducer;