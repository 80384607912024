import moment from "moment";
import { Utils } from "../constants/utils";
import { convertDateToString } from "./utils";

const formFields = {
  date: ["expiry_date", "issued_date", "next_payment_date", "renewal_date"],
  number: [],
  // number: ['age', 'annual_income', 'dependents', 'nominee_mobile', 'pincode', 'policy_premium', 'policy_tenure', 'premium_tenure', 'sum_insured']
};

export const buildSubmitDetailsPayload = (rest) => {
  const { covered, not_covered } = rest;
  const payload = castToFields(rest);

  return {
    ...payload,
    covered: covered.length ? filterList(covered) : [],
    not_covered: not_covered.length ? filterList(not_covered) : [],
  };
};

export const castToFields = ({ ...object }) => {
  for (var key in object) {
    if (object[key] === "") {
      object[key] = null;
    } else if (formFields.date.indexOf(key) > -1) {
      object[key] = convertDateToString(object[key]);
    } else if (formFields.number.indexOf(key) > -1) {
      object[key] = parseInt(object[key], 10);
    }
  }

  return object;
};

export const filterList = (list) => list.filter((item) => item.title);

export const getOptionsList = (item) => {
  return [
    {
      value: item.responses ? "editPolicyDetails" : "submitPolicyDetails",
      text: item.responses ? "Edit Policy Details" : "Submit Policy Details",
    },
    {
      value: "downloadPolicyDocument",
      text: "Download Policy Document",
    },
  ];
};

export const renderResponse = (item, keyname) => {
  return item.responses
    ? item.responses[keyname]
      ? item.responses[keyname]
      : "-"
    : "-";
};

export const extractTypeAndUrl = (list) => {
  if (!list.length) {
    return null;
  }

  const object = list[0];
  const { type, url } = object;

  return { type, url };
};

export const rebuildAPIResponse = (list) => {
  let response = [];

  list.map((item, index) =>
    response.push({
      ...item,
      policy_holder_name: item.responses
        ? item.responses.policy_holder_name
        : "",
      expiry_date: item.responses ? item.responses.expiry_date : "",
      expiry_date_timestamp: item.responses
        ? item.responses.expiry_date
          ? moment(item.responses.expiry_date).unix()
          : 0
        : "",
      issued_date: item.responses ? item.responses.issued_date : "",
      issued_date_timestamp: item.responses
        ? item.responses.issued_date
          ? moment(item.responses.issued_date).unix()
          : 0
        : "",
      policy_number: item.responses ? item.responses.policy_number : "",
      insurance_provider: item.responses
        ? item.responses.insurance_provider
        : "",
      policy_premium: item.responses ? item.responses.policy_premium : "",
      policy_premium_sortby: item.responses
        ? item.responses.policy_premium
          ? parseFloat(item.responses.policy_premium)
          : 0.0
        : "",
      product: Utils.otherPolicies.text.lifeInsurance,
      sum_insured: item.responses ? item.responses.sum_insured : "",
      sum_insured_sortby: item.responses
        ? item.responses.sum_insured
          ? parseFloat(item.responses.sum_insured)
          : 0.0
        : "",
      policy_status_text: item.responses
        ? item.responses.policy_status === "policy_read"
          ? Utils.otherPolicies.status.policyReadManual
          : Utils.otherPolicies.status.policyDetailsSubmitted
        : Utils.otherPolicies.status.policyDocumentsUploaded,
    })
  );

  return response;
};

export const buildCsvHeaders = () => {
  return [
    { label: "Policy Holder Name", key: "policy_holder_name" },
    { label: "Policy Number", key: "policy_number" },
    { label: "Product", key: "product" },
    { label: "Insurance Provider", key: "insurance_provider" },
    { label: "Sum Assured", key: "sum_insured" },
    { label: "Policy Premium", key: "policy_premium" },
    { label: "Issued Date", key: "issued_date" },
    { label: "Expiry Date", key: "expiry_date" },
    { label: "Status", key: "policy_status_text" },
  ];
};

export const isValidDate = (current, field) => {
  if (field === "issued_date") {
    return current.isBefore(moment());
  }
};

export const buildUnifiedPoliciesCsvHeaders = () => {
  return [
    { label: "ID", key: "id" },

    { label: "Policy No", key: "Policy_No" },
    { label: "Policy No Identifier", key: "Policy_No_Identifier" },
    { label: "Risk Start Date", key: "Risk_start_date" },
    { label: "Risk_End_Date", key: "Risk_End_Date" },
    { label: "Policy Status", key: "Policy_Status" },
    { label: "Policy Issue Date", key: "Policy_issue_date" },
    { label: "Policy Term", key: "Policy_term" },

    { label: "Insurer Name", key: "Insurer_name" },
    { label: "IC", key: "IC" },
    { label: "Business Type", key: "Business_Type" },
    { label: "Sum Assured", key: "Sum_Assured" },
    { label: "IMD", key: "IMD" },
    { label: "IRDAI", key: "IRDAI" },
    { label: "Business Type", key: "Insurance_Assurance_Loan" },
    { label: "Insured Name", key: "Insured_Name" },
    { label: "Channel", key: "Channel_Online_Offline" },

    { label: "Dealer Id", key: "Dealer_Id" },
    { label: "Dealer Name", key: "Dealer_Name" },

    { label: "Basic OD Premium", key: "Basic_OD_Premium" },
    { label: "Net OD Premium", key: "Net_OD_Premium" },
    { label: "BasicTP Premium", key: "Basic_TP_Premium" },
    { label: "TP Premium", key: "TP_Premium" },
    { label: "Tax", key: "Tax" },
    { label: "Discounting", key: "Discounting" },
    { label: "Gross Premium", key: "Gross_Premium" },
    { label: "GVW", key: "GVW" },
    { label: "Net Premium", key: "Net_Premium" },

    { label: "Make", key: "Make" },
    { label: "Model", key: "Model" },
    { label: "Chassis No", key: "Chassis_No" },
    { label: "Engine No", key: "Engine_No" },
    { label: "Vehicle CC", key: "CC" },
    { label: "Fuel Type", key: "Fuel_type" },
    { label: "Vehicle Age", key: "Vehicle_Age" },
    { label: "RTO", key: "RTO" },
    { label: "RTO Location", key: "RTO_Location" },
    { label: "Pincode", key: "Pincode" },
    { label: "State", key: "State" },
    { label: "City", key: "City" },

    { label: "Product Name", key: "Product_Name" },
    { label: "Product Category", key: "Product_category" },
    { label: "Product Sub Category", key: "Product_sub_category" },
    { label: "Product Sub Category_2", key: "Product_sub_category_2" },
    { label: "CPA Without CPA", key: "CPA_Without_CPA" },

    { label: "ORC", key: "ORC" },
    { label: "Payout", key: "Payout" },
    { label: "Period", key: "Period" },
    { label: "Revenue Period", key: "Revenue_Period" },
  ];
};

export const buildUnifiedPoliciesTableHeaders = () => {
  return [
    { label: "ID", key: "id" },
    { label: "Policy No", key: "Policy_No" },
    { label: "Start Date", key: "Risk_start_date" },
    { label: "End Date", key: "Risk_End_Date" },
    { label: "Status", key: "Policy_Status" },
    { label: "Issue Date", key: "Policy_issue_date" },
    { label: "Insurance Company", key: "Insurer_name" },
    { label: "Channel", key: "Channel_Online_Offline" },
    { label: "Dealer Id", key: "Dealer_Id" },
    { label: "Dealer Name", key: "Dealer_Name" },
    { label: "Premium", key: "Net_Premium" },
    { label: "Category", key: "Product_category" },
    { label: "Sub Category", key: "Product_sub_category" },
    // { label: "Business Type", key: "Business_Type" },
    // { label: "Make", key: "Make" },
    // { label: "Model", key: "Model" },
    // { label: "Chassis No", key: "Chassis_No" },
    // { label: "Engine No", key: "Engine_No" },
    // { label: "Product Name", key: "Product_Name" },
  ];
};

// Function to export data as a file
export const exportData = (data, fileName, type) => {
  // Create a link and download the file
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const isUnifiedPoliciesFilterSelected = ({
  selectedInsurerName = [],
  selectedProductCategory = [],
  selectedProductSubCategory = [],
  selectedChannel = [],
  selectedPlatform = [],
  selectedFromDate = null,
  selectedToDate = null,
  defaultFromDate = null,
  defaultToDate = null,
}) => {
  return (
    selectedInsurerName.length ||
    selectedProductCategory.length ||
    selectedProductSubCategory.length ||
    selectedChannel.length ||
    selectedPlatform.length ||
    selectedFromDate !== defaultFromDate ||
    selectedToDate !== defaultToDate
  );
};

export const extractValuesFromList = (list = []) => {
  let values = [];

  list.forEach((item) => values.push(item.value));

  return values;
};

export const buildSearchUnifiedPoliciesPayload = ({
  selectedFromDate = null,
  selectedToDate = null,
  selectedInsurerName = [],
  selectedProductCategory = [],
  selectedProductSubCategory = [],
  selectedChannel = [],
  selectedPlatform = [],
}, sendEmail = 0) => {
  return {
    from_date: selectedFromDate
      ? moment(selectedFromDate).format("YYYY-MM-DD")
      : null,
    to_date: selectedToDate
      ? moment(selectedToDate).format("YYYY-MM-DD")
      : null,
    insurer_name: extractValuesFromList(selectedInsurerName),
    product_category: extractValuesFromList(selectedProductCategory),
    product_sub_category: extractValuesFromList(selectedProductSubCategory),
    channel: extractValuesFromList(selectedChannel),
    bussiness_type: extractValuesFromList(selectedPlatform),
    export_to: sendEmail
  };
};

export const calculateUpdatedToDate = (filter) => {
  const newToDate = moment(filter.selectedFromDate).add(3, 'months').subtract(1, 'days');
  const currentDate = moment();
  
  return newToDate.isAfter(currentDate) ? currentDate.format("YYYY-MM-DD") : newToDate.format("YYYY-MM-DD");
};

export const getCurrentFormattedDate = (format = "YYYY-MM-DD") => {
  return moment().format(format);
};

export const getFormattedDateBeforeXPreviousDuration = (duration = 3, unit = "months", format = "YYYY-MM-DD") => {
  return moment()
    .subtract(duration, unit)
    .add(1, "days")
    .format(format);
};

export const getUnifiedPoliciesCSVFileName = ({ selectedFromDate = null, selectedToDate = null }) => {
  if (!selectedFromDate || !selectedToDate) {
    return null;
  }

  return `unified-policies ( From ${moment(selectedFromDate).format("Do MMM YYYY")} To ${moment(selectedToDate).format("Do MMM YYYY")}).csv`;
};

export const initialUnifiledPoliciesFilter = {
  searchText: "",
  sortBy: "",
  sortType: "desc",
  selectedInsurerName: [],
  selectedProductCategory: [],
  selectedProductSubCategory: [],
  selectedChannel: [],
  selectedPlatform: [],
  selectedFromDate: getFormattedDateBeforeXPreviousDuration(),
  selectedToDate: getCurrentFormattedDate(),
  defaultFromDate: getFormattedDateBeforeXPreviousDuration(),
  defaultToDate: getCurrentFormattedDate(),
  clear: false,
};

export const getAppliedFiltersCount = ({
  selectedFromDate = null,
  selectedToDate = null,
  selectedInsurerName = [],
  selectedProductCategory = [],
  selectedProductSubCategory = [],
  selectedChannel = [],
  selectedPlatform = [],
}) => {
  let count = 0;

  if (selectedFromDate && selectedToDate) {
    ++count;
  }

  if (selectedInsurerName.length) {
    ++count;
  }
  
  if (selectedProductCategory.length) {
    ++count;
  }

  if (selectedProductSubCategory.length) {
    ++count;
  }

  if (selectedChannel.length) {
    ++count;
  }

  if (selectedPlatform.length) {
    ++count;
  }

  return count;
};

export const getAppliedFilters = ({
  selectedFromDate = null,
  selectedToDate = null,
  selectedInsurerName = [],
  selectedProductCategory = [],
  selectedProductSubCategory = [],
  selectedChannel = [],
  selectedPlatform = [],
}) => {
  let filters = "";

  if (selectedFromDate && selectedToDate) {
    filters = `${filters} Issue Date Range`;
  }

  if (selectedInsurerName.length) {
    filters = `${filters}, Insurance Company`;
  }
  
  if (selectedProductCategory.length) {
    filters = `${filters}, Product Category`;
  }

  if (selectedProductSubCategory.length) {
    filters = `${filters}, Product Sub Category`;
  }

  if (selectedChannel.length) {
    filters = `${filters}, Channel`;
  }

  if (selectedPlatform.length) {
    filters = `${filters}, Business Type`;
  }

  return filters;
};

export const rebuildListExcludingNullableValues = (list = [], keyname = "value") => {
  if (!list.length) {
    return list;
  }

  return list.filter((item) => item[keyname] && item[keyname] !== "0");
};