export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';



export const GET_PRODUCT_FILTER_LIST_START = 'GET_PRODUCT_FILTER_LIST_START';
export const GET_PARTNER_FILTER_LIST_START = 'GET_PARTNER_FILTER_LIST_START';


export const GET_PRODUCT_FILTER_LIST_SUCCESS = 'GET_PRODUCT_FILTER_LIST_SUCCESS';
export const GET_PARTNER_FILTER_LIST_SUCCESS = 'GET_PARTNER_FILTER_LIST_SUCCESS';


export const GET_PRODUCT_FILTER_LIST_FAIL = 'GET_PRODUCT_FILTER_LIST_FAIL';
export const GET_PARTNER_FILTER_LIST_FAIL = 'GET_PARTNER_FILTER_LIST_FAIL';
