export const options = {
  fields: [
    {
      reference_key: "policy_number",
      title: "Loan Number",
    },
    {
      reference_key: "loan_status",
      title: "Loan Status",
    },
    {
      reference_key: "start_date",
      title: "Loan Start Date",
    },
    {
      reference_key: "end_date",
      title: "Loan End Date",
    },
    {
      reference_key: "premium",
      title: "Loan Amount",
    },
    {
      reference_key: "loan_tenure",
      title: "Loan Tenure",
      suffix: " Months"
    },
    {
      reference_key: "loan_interest_rate",
      title: "Loan Interest Rate",
      suffix: "% p.a."
    },
    {
      reference_key: "response_pan_number",
      title: "PAN Number",
    },
    {
      reference_key: "response_aadhaar_number",
      title: "Aadhar Number",
    },
    {
      reference_key: "response_residence_type",
      title: "Residence Type",
    },
    {
      reference_key: "response_employment_type",
      title: "Employment Type",
    },
    {
      reference_key: "response_employer_name",
      title: "Employer Name",
    },
    {
      reference_key: "response_work_email",
      title: "Employee Work Email",
    },
    {
      reference_key: "response_monthly_salary",
      title: "Monthly Salary",
    },
    {
      reference_key: "response_salary_deposit_tenure",
      title: "Salary Deposit Tenure",
      suffix: " Months"
    },
    {
      reference_key: "response_annual_income",
      title: "Annual Income",
    },
    {
      reference_key: "response_applicant_type",
      title: "Applicant Type",
    },
    {
      reference_key: "response_business_name",
      title: "Business Name",
    },
    {
      reference_key: "response_vehicle_category",
      title: "Vehicle Category",
    },
    {
      reference_key: "response_vehicle_type",
      title: "Vehicle Type",
    },
    {
      reference_key: "response_vehicle_manufacturer",
      title: "Make",
    },
    {
      reference_key: "response_vehicle_model",
      title: "Model",
    },
    {
      reference_key: "response_vehicle_variant",
      title: "Variant",
    },
    {
      reference_key: "response_ex_showroom_price",
      title: "Ex-showroom Price",
    },
    {
      reference_key: "response_on_road_price",
      title: "On-road Price",
    },
  ],
};
