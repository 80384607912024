
// ADMIN-NAVBAR ACTION TYPES
export const GET_USER_DATA_START = 'GET_USER_DATA_START';

export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';

export const GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL';



