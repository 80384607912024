export const GET_POLICY_INPUTS_START = 'GET_POLICY_INPUTS_START';
export const GET_POLICY_PRODUCT_LIST_START = 'GET_POLICY_PRODUCT_LIST_START';
export const GET_POLICY_PROVIDER_LIST_START = 'GET_POLICY_PROVIDER_LIST_START';
export const GET_POLICY_STATUS_LIST_START = 'GET_POLICY_STATUS_LIST_START';
export const GET_POLICY_DEALER_LIST_START = 'GET_POLICY_DEALER_LIST_START';
export const GET_POLICY_CITY_LIST_START = 'GET_POLICY_CITY_LIST_START';
export const GET_POLICY_DEALER_CREDIT_START = 'GET_POLICY_DEALER_CREDIT_START';
export const GET_POLICY_BRANDS_START = 'GET_POLICY_BRANDS_START';
export const GET_POLICY_LIST_START = 'GET_POLICY_LIST_START';
export const GET_TRANSACTION_LIST_START = 'GET_TRANSACTION_LIST_START';
export const GET_POLICY_HOLDER_DETAILS_START = 'GET_POLICY_HOLDER_DETAILS_START';
export const POST_RETRIEVE_QUOTATION_DATA_START = "POST_RETRIEVE_QUOTATION_DATA_START";
export const GET_POLICY_CANCELLATION_START = 'GET_POLICY_CANCELLATION_START';
export const GET_POLICY_PAYMENT_DUE_CARD_START = 'GET_POLICY_PAYMENT_DUE_CARD_START';
export const GET_POLICY_RESUBSCRIPTION_DUE_CARD_START = 'GET_POLICY_RESUBSCRIPTION_DUE_CARD_START';

export const GET_QUOTATION_LIST_START = 'GET_QUOTATION_LIST_START';
export const GET_CYCLE_BRANDS_START = 'GET_CYCLE_BRANDS_START';

export const GET_POLICY_INPUTS_SUCCESS = 'GET_POLICY_INPUTS_SUCCESS';
export const GET_POLICY_PRODUCT_LIST_SUCCESS = 'GET_POLICY_PRODUCT_LIST_SUCCESS';
export const GET_POLICY_PROVIDER_LIST_SUCCESS = 'GET_POLICY_PROVIDER_LIST_SUCCESS';
export const GET_POLICY_STATUS_LIST_SUCCESS = 'GET_POLICY_STATUS_LIST_SUCCESS';
export const GET_POLICY_DEALER_LIST_SUCCESS = 'GET_POLICY_DEALER_LIST_SUCCESS';
export const GET_POLICY_CITY_LIST_SUCCESS = 'GET_POLICY_CITY_LIST_SUCCESS';
export const GET_POLICY_DEALER_CREDIT_SUCCESS = 'GET_POLICY_DEALER_CREDIT_SUCCESS';
export const GET_POLICY_BRANDS_SUCCESS = 'GET_POLICY_BRANDS_SUCCESS';
export const GET_POLICY_LIST_SUCCESS = 'GET_POLICY_LIST_SUCCESS';
export const GET_TRANSACTION_LIST_SUCCESS = 'GET_TRANSACTION_LIST_SUCCESS';
export const GET_POLICY_HOLDER_DETAILS_SUCCESS = 'GET_POLICY_HOLDER_DETAILS_SUCCESS';
export const POST_RETRIEVE_QUOTATION_DATA_SUCCESS = "POST_RETRIEVE_QUOTATION_DATA_SUCCESS";
export const GET_POLICY_CANCELLATION_SUCCESS = 'GET_POLICY_CANCELLATION_SUCCESS';
export const GET_QUOTATION_LIST_SUCCESS = 'GET_QUOTATION_LIST_SUCCESS';
export const GET_CYCLE_BRANDS_SUCCESS = 'GET_CYCLE_BRANDS_SUCCESS';
export const GET_POLICY_PAYMENT_DUE_CARD_SUCCESS = 'GET_POLICY_PAYMENT_DUE_CARD_SUCCESS';
export const GET_POLICY_RESUBSCRIPTION_DUE_CARD_SUCCESS = 'GET_POLICY_RESUBSCRIPTION_DUE_CARD_SUCCESS';


export const GET_POLICY_INPUTS_FAIL = 'GET_POLICY_INPUTS_FAIL';
export const GET_POLICY_PRODUCT_LIST_FAIL = 'GET_POLICY_PRODUCT_LIST_FAIL';
export const GET_POLICY_PROVIDER_LIST_FAIL = 'GET_POLICY_PROVIDER_LIST_FAIL';
export const GET_POLICY_STATUS_LIST_FAIL = 'GET_POLICY_STATUS_LIST_FAIL';
export const GET_POLICY_DEALER_LIST_FAIL = 'GET_POLICY_DEALER_LIST_FAIL';
export const GET_POLICY_CITY_LIST_FAIL = 'GET_POLICY_CITY_LIST_FAIL';
export const GET_POLICY_DEALER_CREDIT_FAIL = 'GET_POLICY_DEALER_CREDIT_FAIL';
export const GET_POLICY_BRANDS_FAIL = 'GET_POLICY_BRANDS_FAIL';
export const GET_POLICY_LIST_FAIL = 'GET_POLICY_LIST_FAIL';
export const GET_TRANSACTION_LIST_FAIL = 'GET_TRANSACTION_LIST_FAIL';
export const GET_POLICY_HOLDER_DETAILS_FAIL = 'GET_POLICY_HOLDER_DETAILS_FAIL';
export const POST_RETRIEVE_QUOTATION_DATA_FAIL = "POST_RETRIEVE_QUOTATION_DATA_FAIL";
export const GET_POLICY_CANCELLATION_FAIL = 'GET_POLICY_CANCELLATION_FAIL';
export const GET_POLICY_PAYMENT_DUE_CARD_FAIL = 'GET_POLICY_PAYMENT_DUE_CARD_FAIL';
export const GET_POLICY_RESUBSCRIPTION_DUE_CARD_FAIL = 'GET_POLICY_RESUBSCRIPTION_DUE_CARD_FAIL';

export const GET_QUOTATION_LIST_FAIL = 'GET_QUOTATION_LIST_FAIL';
export const GET_CYCLE_BRANDS_FAIL = 'GET_CYCLE_BRANDS_FAIL';

// for custom brand fix
export const CUSTOM_BRAND_SHOW = 'CUSTOM_BRAND_SHOW';
export const CUSTOM_CYCLE_BRAND_SHOW = 'CUSTOM_CYCLE_BRAND_SHOW';
