import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Shimmer from "../../../../components/CustomUi/Shimmer/Shimmer"
import * as actions from '../../../../Store/actions';
import AxiosReactClient from '../../../../utilities/AxiosRestClient'
import {
    Card,
    CardBody,
    CardTitle,
    Input,
    Row,
    Col,
    FormGroup,
    Container,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
} from "reactstrap";
import Loader from '../../../../components/CustomUi/Loader/Loader';
class EditRole extends Component {
    state = {
        roleId: '',
        roleName: "",
        permissionIdsList: [],
        groupNameArray: [],
        showLoader: false
    };
    role = {
        name: "",
        roleIdList: []
    }
    permissionObjectArray = [];
    componentDidMount() {
        this.setState({
            roleId: this.props.match.params.id
        })
        this.props.onGetPermissionsData();
        this.props.onGetRoleIdData(this.props.match.params.id);
    }
    removePermissionElement = (permission) => {
        this.setState({
            permissionIdsList: this.spliceArray(this.state.permissionIdsList, [permission.id])
        });
    }
    addPermissionElement = (permission) => {
        this.setState({
            permissionIdsList: this.concatArray([permission.id], this.state.permissionIdsList)
        })
    }
    concatArray(arr1, arr2) {
        var uniqueArray = [];
        let tempArr = arr1.concat(arr2);
        // Loop through array values
        for (var value of tempArr) {
            if (uniqueArray.indexOf(value) === -1) {
                uniqueArray.push(value);
            }
        }
        return uniqueArray;
    }
    spliceArray(original, remove) {
        return original.filter(value => !remove.includes(value));
    }
    addGroupPermissions = (permissionArray, groupName) => {
        this.setState({
            permissionIdsList: this.concatArray(this.state.permissionIdsList, permissionArray.map(({ id }) => { return id })),
            groupNameArray: this.concatArray(this.state.groupNameArray, [groupName])
        })
    }
    removeGroupPermissions = (permissionArray, groupName) => {
        this.setState({
            permissionIdsList: this.spliceArray(this.state.permissionIdsList, permissionArray.map(({ id }) => { return id })),
            groupNameArray: this.spliceArray(this.state.groupNameArray, [groupName])
        })
    }
    updateName = (e) => {
        this.setState({
            roleName: e.target.value
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            roleName: nextProps.roleIdData ? nextProps.roleIdData.name : ""
        })
        let idArray = [];
        nextProps.roleIdData && nextProps.roleIdData.permissions.forEach(group => {
            group.permissions.forEach(permission => {
                idArray.push(permission.id)
            });
        })
        this.setState({
            permissionIdsList: []
        }, () => {
            this.setState({
                permissionIdsList: idArray
            })
        })
    }
    onclickUpdateRole = () => {
        if (this.state.roleName === "") {
            this.showMsg("Please enter role name", 'danger');
            return
        }
        if (this.state.permissionIdsList.length < 1) {
            this.showMsg("Please select at least one permission", 'danger');
            return
        }
        this.setState({
            showLoader: true
        })
        AxiosReactClient.putRequest('roles/' + this.state.roleId, {
            role_name: this.state.roleName,
            permission_id: this.state.permissionIdsList
        }).then(response => {
            this.showMsg("Role updated.", 'success');
            this.props.history.push('/admin/roles/' + this.state.roleId)
            this.setState({
                showLoader: false
            })
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                })
                if (err.response) {
                    this.showMsg(err.response.data.error.message, 'danger');
                }
                else {
                    this.showMsg(err.message, 'danger');
                }
            });
    }
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    }
    changeString = (str) => {
        var a = str.indexOf(" ");
        var temp = str.substring(a + 1);
        return temp;
    }
    render() {
        if (!this.props.permissionsDataLoading && this.props.permissionsData) {
            this.permissionObjectArray = [].concat(this.props.permissionsData.permissions);
        }
        return (
            <Fragment>
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="4" xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-2"
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-user" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Edit Role
                                    </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="8" xs="6">
                            </Col>
                        </Row>
                    </div>
                    <Card className="m-2">
                        <CardBody className="position-relative">
                            {this.state.showLoader && <Loader msg={"Loading"} />}
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example3cols1Input"
                                        >
                                            Role Name
                                    </label>
                                        <Input
                                            id="example3cols1Input"
                                            placeholder="Type here"
                                            type="text"

                                            value={this.state.roleName}
                                            onChange={e =>
                                                this.updateName(e)
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col className="text-right">
                                    <Button
                                        disabled={this.state.roleName.trim() === ""}
                                        color="primary"
                                        type="button"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.onclickUpdateRole()
                                        }}
                                    >
                                        Update
                                </Button>
                                </Col>
                            </Row>
                            <hr />
                            <CardTitle className="mb-3" tag="h3">
                                Permissions
                        </CardTitle>
                            {this.permissionObjectArray.map((group, index) => {
                                return <div key={Math.random(100)} className="mb-4">
                                    <label
                                        className="form-control-label cursor-pointer"
                                        htmlFor="example4cols1Input" >
                                        <span
                                            onClick={e => {
                                                e.preventDefault()
                                                this.state.groupNameArray.includes(group.groupName) ? this.removeGroupPermissions(group.permissions, group.groupName) : this.addGroupPermissions(group.permissions, group.groupName);
                                            }}
                                        >
                                            {group.groupName}
                                        </span>
                                    </label>
                                    <br />
                                    {group.permissions && group.permissions.map((permission, index) => {
                                        return <Badge key={Math.random(100)} id={"career-gray" + index}
                                            className={`badge-md  cursor-pointer tag-behaviour m-1 ` + (this.state.permissionIdsList.includes(permission.id) ? " background-primary text-white" : " opacity-7 ")}
                                            pill color="secondary"
                                            onClick={e => {
                                                e.preventDefault()
                                                this.state.permissionIdsList.includes(permission.id) ? this.removePermissionElement(permission) : this.addPermissionElement(permission)
                                            }}
                                        >{this.changeString(permission.display_name)}
                                            <span className="btn-inner--icon ml-1">
                                                {(this.state.permissionIdsList && this.state.permissionIdsList.includes(permission.id)) ?
                                                    <i className="fas fa-times" />
                                                    :
                                                    <i className="fas fa-plus" />
                                                }
                                            </span>
                                        </Badge>
                                    })}
                                </div>
                            })}
                        </CardBody>
                    </Card>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        permissionsDataLoading: state.roles.permissionsDataLoading,
        roleIdDataLoading: state.roles.roleIdDataLoading,

        permissionsData: state.roles.permissionsData,
        roleIdData: state.roles.roleIdData,

        error: state.roles.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetPermissionsData: () => dispatch(actions.getPermissionsData()),
        onGetRoleIdData: (id) => dispatch(actions.getRoleIdData(id)),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditRole);