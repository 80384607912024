export const  GET_DEALER_DEALER_LIST_START = 'GET_DEALER_DEALER_LIST_START';
export const  GET_DEALER_DEALER_CARDS_START = 'GET_DEALER_DEALER_CARDS_START';
export const  GET_DEALER_DETAILS_VIEW_START = 'GET_DEALER_DETAILS_VIEW_START';
export const  GET_DEALER_DETAILS_VIEW_CARD_START = 'GET_DEALER_DETAILS_VIEW_CARD_START';
export const  GET_KAM_LIST_START = 'GET_KAM_LIST_START';
export const  GET_DEALER_VIEW_PRODUCT_LIST_START = 'GET_DEALER_VIEW_PRODUCT_LIST_START';
export const  GET_BRAND_LIST_START = 'GET_BRAND_LIST_START';
export const  GET_DEALER_BRAND_LIST_START = 'GET_DEALER_BRAND_LIST_START';
export const  GET_ADDITIONAL_CONTACT_LIST_START = 'GET_ADDITIONAL_CONTACT_LIST_START';
export const  GET_DEALER_DETAILS_HISTORY_START = 'GET_DEALER_DETAILS_HISTORY_START';
export const  GET_DEALER_INVOICING_MODEL_START = 'GET_DEALER_INVOICING_MODEL_START';


export const  GET_DEALER_DEALER_LIST_SUCCESS = 'GET_DEALER_DEALER_LIST_SUCCESS';
export const  GET_DEALER_DEALER_CARDS_SUCCESS = 'GET_DEALER_DEALER_CARDS_SUCCESS';
export const  GET_DEALER_DETAILS_VIEW_SUCCESS = 'GET_DEALER_DETAILS_VIEW_SUCCESS';
export const  GET_FILTER_VIEW_SUCCESS = 'GET_FILTER_VIEW_SUCCESS';
export const  GET_DEALER_DETAILS_VIEW_CARD_SUCCESS = 'GET_DEALER_DETAILS_VIEW_CARD_SUCCESS';
export const  GET_KAM_LIST_SUCCESS = 'GET_KAM_LIST_SUCCESS';
export const  GET_DEALER_VIEW_PRODUCT_LIST_SUCCESS = 'GET_DEALER_VIEW_PRODUCT_LIST_SUCCESS';
export const  GET_BRAND_LIST_SUCCESS = 'GET_BRAND_LIST_SUCCESS';
export const  GET_DEALER_BRAND_LIST_SUCCESS = 'GET_DEALER_BRAND_LIST_SUCCESS';
export const  GET_ADDITIONAL_CONTACT_LIST_SUCCESS = 'GET_ADDITIONAL_CONTACT_LIST_SUCCESS';
export const  GET_DEALER_DETAILS_HISTORY_SUCCESS = 'GET_DEALER_DETAILS_HISTORY_SUCCESS';
export const  GET_DEALER_INVOICING_MODEL_SUCCESS = 'GET_DEALER_INVOICING_MODEL_SUCCESS';


export const  GET_DEALER_DEALER_LIST_FAIL = 'GET_DEALER_DEALER_LIST_FAIL';
export const  GET_DEALER_DEALER_CARDS_FAIL = 'GET_DEALER_DEALER_CARDS_FAIL';
export const  GET_DEALER_DETAILS_VIEW_FAIL = 'GET_DEALER_DETAILS_VIEW_FAIL';
export const  GET_DEALER_DETAILS_VIEW_CARD_FAIL = 'GET_DEALER_DETAILS_VIEW_CARD_FAIL';
export const  GET_KAM_LIST_FAIL = 'GET_KAM_LIST_FAIL';
export const  GET_DEALER_VIEW_PRODUCT_LIST_FAIL = 'GET_DEALER_VIEW_PRODUCT_LIST_FAIL';
export const  GET_BRAND_LIST_FAIL = 'GET_BRAND_LIST_FAIL';
export const  GET_DEALER_BRAND_LIST_FAIL = 'GET_DEALER_BRAND_LIST_FAIL';
export const  GET_ADDITIONAL_CONTACT_LIST_FAIL = 'GET_ADDITIONAL_CONTACT_LIST_FAIL';
export const  GET_DEALER_DETAILS_HISTORY_FAIL = 'GET_DEALER_DETAILS_HISTORY_FAIL';
export const  GET_DEALER_INVOICING_MODEL_FAIL = 'GET_DEALER_INVOICING_MODEL_FAIL';

export const  REMOVE_PRODUCT_FROM_DEALER = 'REMOVE_PRODUCT_FROM_DEALER';



