import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import {
    FormGroup,
    Input,
} from "reactstrap";
class IssuePolicyInputs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pincode: '',
            stateName: '',
            city: '',
            value: '',
            otherSelected: false,
            address_enable: true
        }
    }
    onChangePinInput = (e, name) => {
        if (e.target.value.length < 7) {
            this.setState({
                pincode: e.target.value,
                value: e.target.value
            })
            this.props.getInputData({
                name: name,
                value: e.target.value
            })
        }
    }
    onChange = (e, name) => {
        this.setState({
            value: e.target.value
        })
        this.props.getInputData({
            name: name,
            value: e.target.value
        })
    }
    onBrandChange = (e, name) => {
        this.setState({
            value: e.target.value
        })

        this.props.getInputData({
            name: name,
            value: e.target.value
        })
    }
    onChangeDate = (e, name) => {
        let tmp = e.target.value.split('-').reverse().join('/');
        this.setState({
            value: tmp
        })
        this.props.getInputData({
            name: name,
            value: tmp
        })
    }
    onChangePhoto = (e, name) => {
        e.target.files && this.setState({
            value: e.target.files[0]
        })
        this.props.getInputData({
            name: name,
            value: e.target.files[0],
        })
    }
    changeDateFormatToHyphen(dateString) {
        return dateString.split('/').reverse().join('-');
    }
    onOptionChanged = (option) => {
        this.props.getInputData({
            name: 'title',
            value: option
        })
    }
    render() {
        return (
            <Fragment>
                <FormGroup>
                    {(this.props.data.name === 'response_custom_brand') && this.props.customBrand &&
                        <label className="form-control-label" htmlFor={this.props.display_name}>
                            {"Type Custom " + this.props.d_name}
                        </label>
                    }
                    {(this.props.data.name !== 'response_custom_brand' && (this.props.data.name && this.props.data.name !== 'title') && ((this.props.product === 19 || this.props.product === 22 || this.props.product === 31 || this.props.product === 40) ? (this.props.data.name !== 'pincode' && this.props.data.name !== 'state' && this.props.data.name !== 'city' && this.props.data.name !== 'address_line_1' && this.props.data.name !== 'address_line_2') : this.state.address_enable)) &&
                        <label className="form-control-label" htmlFor={this.props.display_name}>
                            {this.props.d_name}
                        </label>
                    }
                    {(this.props.data.display_name === 'Name') && <div className="d-flex">
                        <div className="custom-control custom-radio mb-2 mr-3">
                            <input
                                className="custom-control-input"
                                id="customRadio1"
                                name="mrs_radio"
                                type="radio"
                                onChange={e => {
                                    this.onOptionChanged(e.target.checked ? 'Mr' : 'Ms')
                                }}
                            />
                            <label className="custom-control-label" htmlFor="customRadio1">
                                Mr
                            </label>
                        </div>
                        <div className="custom-control custom-radio mb-2">
                            <input
                                className="custom-control-input"
                                id="customRadio2"
                                name="mrs_radio"
                                type="radio"
                                onChange={e => {
                                    this.onOptionChanged(e.target.checked ? 'Ms' : 'Mr')
                                }}
                            />
                            <label className="custom-control-label" htmlFor="customRadio2">
                                Ms
                            </label>
                        </div>
                    </div>}
                    {((this.props.product === 19 || this.props.product === 22 || this.props.product === 31 || this.props.product === 40) ? (this.props.data.type && this.props.data.type === 'pincode') : this.state.address_enable) && <Input
                        id={this.props.id && this.props.id}
                        type={'text'}
                        placeholder={this.props.placeholder && this.props.placeholder}
                        required={this.props.required && this.props.required}
                        value={this.props.inputs[this.props.data.name] ? this.props.inputs[this.props.data.name] : this.state.value}
                        onChange={e => this.onChangePinInput(e, this.props.data.name)}
                        onKeyDown={(evt) => {
                            if (evt.key === 'e' || evt.key === "-" || evt.key === "." || evt.key === "+") evt.preventDefault()
                        }}
                    />}
                    {(this.props.data.type === 'dropdown' && this.props.data.name !== 'response_model' && this.props.data.name !== 'state' && this.props.data.name !== 'city' && this.props.data.name !== 'response_brand_id') && <Input
                        id={this.props.id && this.props.id}
                        type='select'
                        placeholder={this.props.placeholder && this.props.placeholder}
                        required={this.props.required && this.props.required}
                        value={this.props.inputs[this.props.data.name] ? this.props.inputs[this.props.data.name] : this.state.value}
                        onChange={e => this.onChange(e, this.props.data.name)}
                    >
                        <option value='' disabled>Choose here</option>
                        {(this.props.product === 19 || this.props.product === 22 || this.props.product === 31 || this.props.product === 40) ?
                            (this.props.d_name === 'City' ?
                                (this.props.policyCityListData && this.props.policyCityListData.map((element, index) => {
                                    return <option key={'city_' + index} value={element.id}>
                                        {element.name}
                                    </option>
                                }))
                                :
                                (this.props.data.inner_elements && this.props.data.inner_elements.map((element, index) => {
                                    return <option key={'xyz_' + index} value={element.value}>
                                        {element.name}
                                    </option>
                                }))
                            ) :
                            (this.props.data.inner_elements && this.props.data.inner_elements.map((element, index) => {
                                return <option key={'xyz_' + index} value={element.value}>
                                    {element.name}
                                </option>
                            }))
                        }
                    </Input>}
                    {/* Pre selected Brand */}
                    {(this.props.data.type === 'dropdown' && this.props.data.name !== 'response_model' && this.props.data.name !== 'state' && this.props.data.name !== 'city' && this.props.data.name === 'response_brand_id') && <Input
                        id={this.props.id && this.props.id}
                        type='select'
                        placeholder={this.props.brand}
                        required={this.props.required && this.props.required}
                        defaultValue={this.props.inputs[this.props.data.name] ? this.props.inputs[this.props.data.name] : (this.props.brand)}
                    >
                        <option value='' disabled>Choose here</option>
                        {(this.props.product === 19 || this.props.product === 22 || this.props.product === 31 || this.props.product === 40) &&
                            (this.props.data.inner_elements && this.props.data.inner_elements.map((element, index) => {
                                return <option key={'xyz_' + index} value={element.value} disabled={this.props.brand != element.value && 'disabled'}>
                                    {element.name}
                                </option>
                            }))
                        }
                    </Input>}
                    {this.props.data.type === 'text' && (this.props.data.name === 'response_custom_brand') && this.props.customBrand && <Input
                        id={this.props.id && this.props.id}
                        type='text'
                        placeholder={this.props.placeholder && this.props.placeholder}
                        required={this.props.required && this.props.required}
                        value={this.props.customBrand}
                        disabled
                        onChange={e => this.onChange(e, this.props.customBrand)}
                    />
                    }
                    {this.props.data.type === 'dropdown' && this.props.data.name === 'response_model' && <Input
                        id={this.props.id && this.props.id}
                        type='text'
                        placeholder={this.props.placeholder && this.props.placeholder}
                        required={this.props.required && this.props.required}
                        value={this.props.inputs[this.props.data.name] ? this.props.inputs[this.props.data.name] : this.state.value}
                        onChange={e => this.onChange(e, this.props.data.name)}
                    />
                    }
                    {this.props.data.type && this.props.data.type === 'calendar' && this.props.data.name === 'dob' && <Input
                        id={this.props.id && this.props.id}
                        type='date'
                        placeholder={this.props.placeholder && this.props.placeholder}
                        required={this.props.required && this.props.required}
                        value={this.props.inputs[this.props.data.name] ? this.changeDateFormatToHyphen(this.props.inputs[this.props.data.name]) : this.changeDateFormatToHyphen(this.state.value)}
                        onChange={e => this.onChangeDate(e, this.props.data.name)}
                    />
                    }
                    {/* Pre Selected Invoice Date */}
                    {this.props.data.type && this.props.data.type === 'calendar' && this.props.data.name === 'response_invoice_date' && <Input
                        id={this.props.id && this.props.id}
                        type='text'
                        placeholder={this.props.invoice}
                        required={this.props.required && this.props.required}
                        value={this.props.invoice}
                        disabled
                    />
                    }
                    {this.props.data.name && this.props.data.name === 'mobile' && this.props.data.type === 'number' && <Input
                        id={this.props.id && this.props.id}
                        type='text'
                        placeholder={this.props.placeholder && this.props.placeholder}
                        required={this.props.required && this.props.required}
                        value={this.props.inputs[this.props.data.name] ? this.props.inputs[this.props.data.name] : this.state.value}
                        onChange={e => this.onChange(e, this.props.data.name)}
                    />
                    }
                    {this.props.data.type && this.props.data.type === 'file' && <><Input
                        id={this.props.id && this.props.id}
                        type='file'
                        accept="image/*"
                        placeholder={this.props.placeholder && this.props.placeholder}
                        required={this.props.required && this.props.required}
                        onChange={e => this.onChangePhoto(e, this.props.data.name)}
                    />
                        <p>{this.props.data.name && this.props.inputs[this.props.data.name] && this.props.inputs[this.props.data.name].name}</p>
                    </>
                    }
                    {((this.props.data.name && this.props.data.name !== 'title') && (this.props.data.type && this.props.data.type !== 'pincode') && (this.props.data.type && this.props.data.type !== 'file') && (this.props.data.type && this.props.data.type !== 'dropdown') && (this.props.data.type && this.props.data.type !== 'calendar') && (this.props.data.name !== 'response_custom_brand') && (this.props.data.name && this.props.data.name !== 'mobile') && (this.props.data.name && this.props.data.name !== 'response_invoice_date') && ((this.props.product === 19 || this.props.product === 22 || this.props.product === 31 || this.props.product === 40) ? (this.props.data.name && this.props.data.name !== 'address_line_1') && (this.props.data.name && this.props.data.name !== 'address_line_2') : "")) && <Input
                        id={this.props.id ? this.props.id : this.props.data.name}
                        type={this.props.type ? this.props.type : 'text'}
                        placeholder={this.props.placeholder && this.props.placeholder}
                        required={this.props.required && this.props.required}
                        value={this.props.inputs[this.props.data.name] ? this.props.inputs[this.props.data.name] : this.state.value}
                        onChange={e => this.onChange(e, this.props.data.name)}
                    />}
                </FormGroup>
            </Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        policyCityListLoading: state.policies.policyCityListLoading,
        policyCityListData: state.policies.policyCityListData,
        showCustomBrands: state.policies.showCustomBrands
    }
}
export default connect(mapStateToProps, {})(IssuePolicyInputs);