import * as actionTypes from '../actions/actionTypes/settingTypes';
import {
    updateObject
} from '../utility';


const initialState = {
    error: null,
    permissionsDataLoading: false,
    permissionsData: null,

    rolesDataLoading: false,
    rolesData: null,

    roleIdDataLoading: false,
    roleIdData: null,

    usersListLoading: false,
    usersList: null,
    usersCount: null,

    userInfoLoading: false,
    userInfo: null,

    userPermissionLoading: false,
    userPermissions: null,
}


//  START 
const permissionDataStart = (state, action) => {
    return updateObject(state, {
        error: null,
        permissionsDataLoading: true
    });
};
const rolesDataStart = (state, action) => {
    return updateObject(state, {
        error: null,
        rolesDataLoading: true
    });
};
const roleIdDataStart = (state, action) => {
    return updateObject(state, {
        error: null,
        roleIdDataLoading: true
    });
};
const usersListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        usersListLoading: true
    });
};
const userInfoStart = (state, action) => {
    return updateObject(state, {
        error: null,
        userInfoLoading: true
    });
};
const userPermissionsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        userPermissionLoading: true
    });
};


//  SUCCESS 

const permissionDataSuccess = (state, action) => {
    return updateObject(state, {
        permissionsData: action.data,
        error: null,
        permissionsDataLoading: false
    });
};
const rolesDataSuccess = (state, action) => {
    return updateObject(state, {
        rolesData: action.data,
        error: null,
        rolesDataLoading: false
    });
};
const roleIdDataSuccess = (state, action) => {
    return updateObject(state, {
        roleIdData: action.data,
        error: null,
        roleIdDataLoading: false
    });
};
const usersListSuccess = (state, action) => {
    return updateObject(state, {
        usersList: action.data,
        usersCount: action.count,
        error: null,
        usersListLoading: false
    });
};
const userInfoSuccess = (state, action) => {
    return updateObject(state, {
        userInfo: action.data,
        error: null,
        userInfoLoading: false
    });
};
const userPermissionsSuccess = (state, action) => {
    return updateObject(state, {
        userPermissions: action.data,
        error: null,
        userPermissionLoading: false
    });
};

//  FAIL 
const permissionsDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        permissionsDataLoading: false
    });
};
const rolesDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        rolesDataLoading: false
    });
};
const roleIdDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        roleIdDataLoading: false
    });
};
const usersListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        usersListLoading: false
    });
};
const userInfoFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        userInfoLoading: false
    });
};
const userPermissionsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        userPermissionLoading: false
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PERMISSIONS_START:
            return permissionDataStart(state, action);

        case actionTypes.GET_ROLES_START:
            return rolesDataStart(state, action);

        case actionTypes.GET_ROLE_ID_START:
            return roleIdDataStart(state, action);

        case actionTypes.GET_USERS_START:
            return usersListStart(state, action);

        case actionTypes.GET_USERS_INFO_START:
            return userInfoStart(state, action);

        case actionTypes.GET_USERS_PERMISSIONS_START:
            return userPermissionsStart(state, action);



        case actionTypes.GET_PERMISSIONS_SUCCESS:
            return permissionDataSuccess(state, action);

        case actionTypes.GET_ROLES_SUCCESS:
            return rolesDataSuccess(state, action);

        case actionTypes.GET_ROLE_ID_SUCCESS:
            return roleIdDataSuccess(state, action);

        case actionTypes.GET_USERS_SUCCESS:
            return usersListSuccess(state, action);

        case actionTypes.GET_USERS_INFO_SUCCESS:
            return userInfoSuccess(state, action);

        case actionTypes.GET_USERS_PERMISSIONS_SUCCESS:
            return userPermissionsSuccess(state, action);



        case actionTypes.GET_PERMISSIONS_FAIL:
            return permissionsDataFail(state, action);

        case actionTypes.GET_ROLES_FAIL:
            return rolesDataFail(state, action);

        case actionTypes.GET_ROLE_ID_FAIL:
            return roleIdDataFail(state, action);

        case actionTypes.GET_USERS_FAIL:
            return usersListFail(state, action);

        case actionTypes.GET_USERS_INFO_FAIL:
            return userInfoFail(state, action);

        case actionTypes.GET_USERS_PERMISSIONS_FAIL:
            return userPermissionsFail(state, action);


        default:
            return state;
    }
};

export default reducer;