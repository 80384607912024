import * as actionTypes from './actionTypes/settingTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'


// START

export const getConfigStart = () => {
    return {
        type: actionTypes.GET_CONFIG_START
    };
};
export const getConfigIdStart = () => {
    return {
        type: actionTypes.GET_CONFIG_ID_START
    };
};

// SUCCESS
export const getConfigSuccess = (data) => {
    return {
        type: actionTypes.GET_CONFIG_SUCCESS,
        data: data
    };
};
export const getConfigIdSuccess = (data) => {
    return {
        type: actionTypes.GET_CONFIG_ID_SUCCESS,
        data: data
    };
};
export const createConfigSuccess = (data) => {
    return {
        type: actionTypes.CREATE_CONFIG_SUCCESS,
        data: data
    };
};


// FAIL
export const getConfigFail = (error) => {
    return {
        type: actionTypes.GET_CONFIG_FAIL,
        error: error
    };
};
export const getConfigIdFail = (error) => {
    return {
        type: actionTypes.GET_CONFIG_ID_FAIL,
        error: error
    };
};

export const getRolesFail = (error) => {
    return {
        type: actionTypes.GET_ROLES_FAIL,
        error: error
    };
};


// ACTION
export const getConfigData = (query) => {
    return dispatch => {
        dispatch(getConfigStart());
        let params = {...query}
        AxiosReactClient.getRequest('search_config', params).then(response => {
            if(Object.keys(query).length > 0) {
                console.log(response)
                dispatch(getConfigSuccess(response.data.data.appConfigData || response.data.data));
            }else{
                dispatch(getConfigSuccess([]));
            }
            }).catch(err => {
                if (err.response)
                    dispatch(getConfigFail(err.response.data.error));
                else
                    dispatch(getConfigFail({
                        code: 502,
                        message: err.message
                    }));
            }); 
    };
};

export const getConfigIdData = (query) => {
    return dispatch => {
        dispatch(getConfigIdStart());
        let params = {}
        AxiosReactClient.getRequest('get_app_config', query).then(response => {
                dispatch(getConfigSuccess([{data:response.data.data,...query}]));
            })
            .catch(err => {
                if (err.response)
                    dispatch(getConfigFail(err.response.data.error));
                else
                    dispatch(getConfigFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};

export const removeConfig = (data,id) => {
    return dispatch => {
        if(data instanceof Array){
            const filterArray = data.filter(e=>id!=e.id);
            console.log(filterArray)
            dispatch(getConfigSuccess(filterArray));
        }
    };
};

export const clearListConfig = () => {
    return dispatch => {
       
            dispatch(getConfigSuccess([]));
        
    };
};

export const createConfigData = (body) => {
    return dispatch => {
        dispatch(getConfigIdStart());
        let params = {}
        AxiosReactClient.postRequest('create_app_config', body).then(response => {
                dispatch(getConfigIdSuccess(response.data.data));
            })
            .catch(err => {
                if (err.response)
                    dispatch(getConfigIdFail(err.response.data.error));
                else
                    dispatch(getConfigIdFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};