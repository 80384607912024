import React, {Component} from "react";
import OtpInput from "react-otp-input";
import {Button} from "reactstrap";

class Otp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            numInputs: 6,
            separator: "-",
            isDisabled: false,
            hasErrored: false,
            isInputNum: false,
            time: {},
            seconds: 60,
            disabled: false
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    handleOtpChange = (otp) => {
        this.setState({otp: otp}, () => {
            this.props.otpCallBack(otp);
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        alert(this.state.otp);
    };

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({time: timeLeftVar});

    }

    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.setState({
                disabled: true
            })
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
        if (seconds === 0) {
            clearInterval(this.timer);
            this.setState({
                disabled: false,
                seconds:60
            })
            this.timer = 0;
        }
    }
    componentWillMount=() =>{
        this.startTimer();
    }

    render() {
        const {
            otp,
            numInputs,
            separator,
            isDisabled,
            hasErrored,
            isInputNum,
        } = this.state;
        return (
            <div className={"d-flex justify-content-center"}>
                <form onSubmit={this.handleSubmit}>
                    <h5 >Enter OTP</h5>
                    <OtpInput
                        inputStyle={'inputStyle'}
                        numInputs={numInputs}
                        isDisabled={isDisabled}
                        hasErrored={hasErrored}
                        errorStyle="error"
                        onChange={this.handleOtpChange}
                        // separator={<span>{separator}</span>}
                        isInputNum={isInputNum}
                        shouldAutoFocus
                        value={otp}
                    />
                    {this.props.resend &&
                    <div className={'d-flex justify-content-center p-3'}>
                        <Button
                            variant="text"
                            size="large"
                            color="primary"
                            onClick={(e) => {
                                this.startTimer();
                                this.props.resendOtp();

                            }}
                            disabled={this.state.disabled}
                        >
                            RESEND OTP
                        </Button>
                        {this.state.disabled &&
                        <h6 className={'seconds'}>
                            in {this.state.seconds} Seconds
                        </h6>
                        }
                    </div>
                    }
                </form>
            </div>
        );
    }
}

export default Otp;
