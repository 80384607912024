const running_url   = `${process.env.REACT_APP_API_URL}`,
    http_url        = `${process.env.REACT_APP_HTTP_SCHEME}://${running_url}`,
    socket_url      = `ws://${running_url}/websocket`,
    apiBase_url     = `${process.env.REACT_APP_HTTP_SCHEME}://${running_url}/api/`,
    staticPagesUrl  = `${process.env.REACT_APP_HTTP_SCHEME}://${running_url}/`,
    mediaBase_url   = `${process.env.REACT_APP_HTTP_SCHEME}://${running_url}/store/files/uploads/`;

export default class Connection {
    static getResturl() {
        return apiBase_url;
    };

    static getSocketResturl() {
        return socket_url;
    };

    static getBaseUrl() {
        return http_url;
    };

    static getMedia(_id) {
        return mediaBase_url + _id;
    }

    static getStaticPage(url){
        return staticPagesUrl + url;
    }
}