import React from "react";
import NotificationAlert from "react-notification-alert";
import {connect} from "react-redux";
import {Offline} from "react-detect-offline";
import {
    UncontrolledAlert,
} from "reactstrap"

class Notification extends React.Component {
    notify = (place, title, message, type) => {
        let options = {
            place: place,
            message: (
                <div className="alert-text">
                  <span className="alert-title" data-notify="title">
                    {" "}
                      {title}
                  </span>
                    <span data-notify="message">
                    {message}
                  </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 10
        };
        this.refs.notificationAlert.notificationAlert(options);
    };

    snackbarStyle = {position: "fixed", bottom: "3%", left: "50%", transform: "translate(-50%,0)", zIndex: "20",}

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.error) {
            this.notify("tr", "Error", nextProps.error.message, "danger")
        }
    }

    render() {
        return (
            <>
                <div style={this.snackbarStyle}>
                    <Offline>
                        <UncontrolledAlert color="warning">
              <span className="alert-icon">
                <i className="ni ni-sound-wave"/>
              </span>
                            <span className="alert-text ml-1">
                <strong>Warning!</strong> You are disconnected from internet
                    </span>
                        </UncontrolledAlert>
                    </Offline>

                </div>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert" />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        // error: state.auth.error,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath,

    };
};
export default connect(mapStateToProps, null)(Notification);
