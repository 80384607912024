import * as actionTypes from '../actions/actionTypes/settingTypes';
import {
    updateObject
} from '../utility';


const initialState = {
    error: null,
    configDataLoading: false,
    configData: null,
    // configIdData:null
}


//  START 
const configDataStart = (state, action) => {
    return updateObject(state, {
        error: null,
        configDataLoading: true
    });
};


//  SUCCESS 

const configDataSuccess = (state, action) => {
    return updateObject(state, {
        configData: action.data,
        error: null,
        configDataLoading: false
    });
};


//  FAIL 
const configDataFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        configDataLoading: false
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONFIG_START:
            return configDataStart(state, action);

        case actionTypes.GET_CONFIG_SUCCESS:
            return configDataSuccess(state, action);

        case actionTypes.GET_CONFIG_FAIL:
            return configDataFail(state, action);

        default:
            return state;
    }
};

export default reducer;