import React, { useRef, useEffect } from "react";
import { Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Dimmer, Loader } from 'semantic-ui-react'
function AlertModal({ loading, onCancel, onConfirm, show = true, msg, title = "Are you sure?", confirmBtnBsStyle = "danger", confirmBtnText = "Yes, delete it!", showCancel = true, cancelText = "Cancel", confirmText = "Delete", isDisabled = false }) {
    const modalRef = useRef(null)
    return (<div>
        <SweetAlert
            showCancel={showCancel}
            title={title}
            show={show}
            ref={modalRef}
            onConfirm={() => { }}
            customButtons={
                <div>
                    <Button
                        className="btn min-width mr-3"
                        disabled={loading || isDisabled}
                        size="large"
                        onClick={onCancel}
                    >
                        {cancelText}
                    </Button>

                    <Button
                        className="btn btn-danger"
                        size="large"
                        loading={loading}
                        disabled={isDisabled}
                        onClick={onConfirm}
                    >
                        {(loading || isDisabled) ? <Loader active inline size='tiny' /> : confirmText}
                    </Button>
                </div>
            }
        />

    </div>)


}

export default AlertModal;